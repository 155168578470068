import { errorToast } from "../utils/Toast";
import { Patient } from "../domain/OpinionRequest";

export enum InsiSex {
  UNKNOWN,
  MALE,
  FEMALE,
}

export enum InsiError {
  /*Delete all error type from 1 to 5 (=IcanopeeError*/
  // Invalid CPS card, or unavailable
  SERVER_ERROR = "SERVER_ERROR",
  SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
  CARD_ERROR = "CARD_ERROR",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  MISSING_RIGHTS = "MISSING_RIGHTS",
  NO_CONNECTOR = 5,
  NOT_ENOUGH_DIGIT_IN_CODE = 6,
  COMPONENT_INITIAL_CODE = "COMPONENT_INITIAL_CODE",
  WRONG_PARAMETERS = "WRONG_PARAMETERS",
}

export function showInsiError(e: InsiError) {
  console.error("INSi service error", e);
  if (e === InsiError.CARD_ERROR) {
    errorToast("Erreur de lecture de la CPS");
  } else if (e === InsiError.SERVICE_UNAVAILABLE) {
    errorToast("Service indisponible, veuillez réessayer ultérieurement");
  } else if (e === InsiError.MISSING_RIGHTS) {
    errorToast(
      "Vous ne disposez pas des droits suffisants pour accéder à ce service"
    );
  } else if (e === InsiError.SERVER_ERROR) {
    errorToast(
      "Service de lecture de CPS non accessible sur votre environnement"
    );
  } else if (e === InsiError.NOT_ENOUGH_DIGIT_IN_CODE) {
    errorToast("Pas assez de chiffres dans le code");
  } else if (e === InsiError.COMPONENT_INITIAL_CODE) {
    // Initial state of the component
  } else if (e === InsiError.WRONG_PARAMETERS) {
    errorToast("Veuillez entrer des données valides");
  } else if (e === InsiError.NO_CONNECTOR) {
    errorToast("Aucun lecteur CPS repéré");
  } else {
    errorToast("Erreur technique, veuillez réessayer ultérieurement");
  }
}

export interface InsiInfo {
  name: string;
  given: string;
  sex: InsiSex;
  // Format YYYY-MM-DD
  date: string;
  ins?: string;
  key?: string;
  // Production INS oid: 1.2.250.1.213.1.4.8, Demo INS oid: 1.2.250.1.213.1.4.11, Test INS oid: 1.2.250.1.213.1.4.10
  // Will default to production oid
  oid?: string;
  // Place of birth
  place?: string;
}

export interface InsiService {
  checkInsi(
    patientInfo: InsiInfo,
    pinCode: string,
    getCpsPinCode: () => Promise<number>
  ): Promise<boolean>;
  getPatientInfoIns(
    patientInfo: InsiInfo,
    pinCode: string
  ): Promise<Patient | string>;
}
