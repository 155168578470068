import {
  DmpDocumentAuthors,
  DmpError,
  HealthcareSettings,
} from "../DmpService";
import { getCertifiedIdentity, setDmpAccessMode } from "./icanopeeWrapper";
import {
  DmpDocument,
  DmpDocumentContent,
  DmpInfo,
  DmpService,
} from "../DmpService";
import { IcanopeeService } from "./IcanopeeService";

type IcanopeeDocument = {
  Authors: IcanopeeAuthors[];
  s_typeCode: string;
  s_title: string;
  s_description: string;
  s_uniqueId: string;
  s_creationDate: string;
  s_submissionDate: string;
};

type IcanopeeAuthors = {
  s_hpGiven: string;
  s_hpName: string;
  s_hpProfessionDescription: string;
  s_hpSpecialityDescription: string;
};

type IcanopeeDocumentContent = {
  s_fileContentInBase64: string;
  i_documentFormat: number;
  s_documentFormatCode: string;
};

type IcanopeeHealthcareSettings = {
  s_code: string;
  s_codeSystem: string;
  s_displayName: string;
};

export class IcanopeeDmpService extends IcanopeeService implements DmpService {
  async uploadPatientDocuments(
    patient: DmpInfo,
    fileInBase64: string,
    fileDescription: string,
    healthcareSetting: string,
    cpsPinCode: string
  ): Promise<string> {
    const session = await this.getSession(cpsPinCode);
    return new Promise<string>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const dmpError: DmpError = IcanopeeDmpService.parseDmpError(e);
          return reject(dmpError);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_sendDocument(
          //TODO: à changer pour dmp
          patient.ins + "T",
          fileInBase64,
          "CR de téléexpertise", //documentTitle
          fileDescription,
          "85208-7", //documentCategory for TLM-CR
          1, //documentVsibility is 1 by default (document is visible from everyone)
          5, //5 for pdf
          healthcareSetting, //cadre de soins : jeux de valeurs v1.7
          "",
          "",
          "",
          "",
          {},
          function (e: any) {
            if (e.error) {
              return reject(e);
            } else {
              return resolve(e.s_uniqueId);
            }
          }
        );
      } catch (e) {
        console.error("Failed to send document to DMP", e);
        throw e;
      }
    });
  }

  async getPatientDocuments(
    patient: DmpInfo,
    cpsPinCode: string
  ): Promise<DmpDocument[]> {
    const session = await this.getSession(cpsPinCode);
    await getCertifiedIdentity(session, patient);
    //await getDirectAuthentificationDMPStatus(session); //TODO: check if user has the authorization to access DMP
    await setDmpAccessMode(session, 2, "Patient en état de choc."); //1: NormalAccess, 2: BrisDeGlace, 3: Centre15

    return new Promise<DmpDocument[]>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const dmpError: DmpError = IcanopeeDmpService.parseDmpError(e);
          return reject(dmpError);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_findDocuments(patient.ins + "T", {}, function (e: any) {
          if (e.error) {
            return reject(e);
          } else {
            return resolve(e.Documents.map(icanopeeToDmpDocument));
          }
        });
      } catch (e) {
        console.error("Failed to get DMP documents", e);
        throw e;
      }
    });
  }

  async getDocumentByUniqueId(
    patient: DmpInfo,
    documentUniqueId: string,
    cpsPinCode: string
  ): Promise<DmpDocumentContent> {
    const session = await this.getSession(cpsPinCode);
    await setDmpAccessMode(session, 2, "Patient en état de choc."); //1: NormalAccess, 2: BrisDeGlace, 3: Centre15

    return new Promise<DmpDocumentContent>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const dmpError: DmpError = IcanopeeDmpService.parseDmpError(e);
          return reject(dmpError);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_getDocumentContentByUniqueId(
          patient.ins + "T",
          documentUniqueId,
          "", //uuid
          {},
          function (e: any) {
            if (e.error) {
              return reject(e);
            } else {
              return resolve(icanopeeToDmpDocumentContent(e));
            }
          }
        );
      } catch (e) {
        console.error("Failed to get DMP documents", e);
        throw e;
      }
    });
  }

  async deleteDocumentByUniqueId(
    patient: DmpInfo,
    documentUniqueId: string,
    cpsPinCode: string
  ): Promise<DmpDocumentContent> {
    const session = await this.getSession(cpsPinCode);
    return new Promise<DmpDocumentContent>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const dmpError: DmpError = IcanopeeDmpService.parseDmpError(e);
          return reject(dmpError);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_deleteDocument(
          documentUniqueId,
          patient.ins + "T",
          "SA05", //TODO: get the healthcareSettings of the connected user
          {},
          function (e: any) {
            if (e.error) {
              return reject(e);
            } else {
              return resolve(icanopeeToDmpDocumentContent(e));
            }
          }
        );
      } catch (e) {
        console.error("Failed to delete DMP documents", e);
        throw e;
      }
    });
  }

  async getHealthcareSettings(
    cpsPinCode: string
  ): Promise<HealthcareSettings[]> {
    try {
      const res: any = await this.getInteropCode(
        ["healthcareSettings"],
        cpsPinCode
      );
      return res.healthcareSettings
        .map(icanopeeToHealthcareSettings)
        .sort((a: HealthcareSettings, b: HealthcareSettings) =>
          a.displayName.localeCompare(b.displayName)
        )
        .filter(
          (hcSetting: HealthcareSettings) =>
            hcSetting.codeSystem == "1.2.250.1.213.2.2" ||
            hcSetting.codeSystem == "1.2.250.1.71.4.2.4"
        );
    } catch (e) {
      console.error("Failed to get HealthcareSettings", e);
      throw e;
    }
  }

  private async getInteropCode(
    codeIds: string[],
    cpsPinCode: string
  ): Promise<any> {
    const session = await this.getSession(cpsPinCode);
    return new Promise<any>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const dmpError: DmpError = IcanopeeDmpService.parseDmpError(e);
          return reject(dmpError);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_getInteropCodes({ s_codeIds: codeIds }, function (e: any) {
          if (e.error) {
            return reject(e);
          } else {
            return resolve(e);
          }
        });
      } catch (e) {
        console.error("Failed to get IteropCode", e);
        throw e;
      }
    });
  }

  private static parseDmpError(e: any): DmpError {
    console.error("getting error from", e);
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 9) {
      // CPX_MISSING error: No CPS found in the smartcard reader.
      return DmpError.CARD_ERROR;
    }
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 2) {
      return DmpError.SERVER_ERROR;
    }
    if (e.i_apiErrorType === 2 && e.i_apiErrorCode === 7) {
      return DmpError.DMP_ACCESS_FORBIDDEN;
    }
    if (e.s_apiErrorExtendedInformations === "Invalid pincode size") {
      return DmpError.NOT_ENOUGH_DIGIT_IN_CODE;
    }
    return DmpError.UNEXPECTED_ERROR;
  }
}

function icanopeeToDmpDocument(document: IcanopeeDocument): DmpDocument {
  return {
    type: document.s_typeCode,
    title: document.s_title,
    authors: document.Authors.map(icanopeeToDmpDocumentAuthors),
    creationDate: document.s_creationDate,
    submissionDate: document.s_submissionDate,
    description: document.s_description,
    uniqueId: document.s_uniqueId,
  };
}

function icanopeeToDmpDocumentAuthors(
  authors: IcanopeeAuthors
): DmpDocumentAuthors {
  return {
    given: authors.s_hpGiven,
    name: authors.s_hpName,
    professionDescription: authors.s_hpProfessionDescription,
    specialityDescription: authors.s_hpSpecialityDescription,
  };
}

function icanopeeToDmpDocumentContent(
  documentContent: IcanopeeDocumentContent
): DmpDocumentContent {
  return {
    fileContentInBase64: documentContent.s_fileContentInBase64,
    documentFormat: documentContent.i_documentFormat,
    documentFormatCode: documentContent.s_documentFormatCode,
  };
}

function icanopeeToHealthcareSettings(
  healthcareSettings: IcanopeeHealthcareSettings
): HealthcareSettings {
  return {
    code: healthcareSettings.s_code,
    codeSystem: healthcareSettings.s_codeSystem,
    displayName: healthcareSettings.s_displayName,
  };
}
