import React, { useState } from "react";
import Modal from "../../Modal";
import styles from "./ModalDocumentationCps.module.css";
import { IOSDocumentation } from "./IOSDocumentation";
import { WindowsDocumentation } from "./WindowsDocumentation";
import { LinuxDocumentation } from "./LinuxDocumentation";

enum Tab {
  windows = "Windows",
  iOS = "iOS",
  linux = "Linux",
}

interface Props {
  onClose: () => void;
}

export const ModalDocumentationCps: React.FC<Props> = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.windows);

  const closeModal = (): void => {
    props.onClose();
  };

  return (
    <Modal show onClose={closeModal} title={"Connecteur CPS"}>
      <div className="text-left">
        <button onClick={closeModal} className="back-btn">
          <i className="icon icon-arrow-left neuro-primary" /> Retour
        </button>
      </div>
      <div className="text-centered">
        <div
          className={`text-bold text-black margin-bottom-2 ${styles.modalTitle}`}
        >
          Les étapes à suivre pour installer le logiciel
        </div>
        <div className="text-small mr-4 ml-4 text-grey">
          <div className="mb-1">
            Il semblerait que vous n&apos;ayez pas de lecteur de CPS installé.{" "}
          </div>
          Pour accéder aux fonctionnalités liées à la CPS, vous devez installer
          le connecteur CPS et le lancer.
        </div>

        <div className="row space-content pt-2">
          <button
            className={
              "request-menu " +
              (selectedTab === Tab.windows ? "request-menu-active" : "")
            }
            onClick={(): any => setSelectedTab(Tab.windows)}
          >
            <i className={`fab fa-windows ${styles.icon}`} />
          </button>
          <button
            className={
              "request-menu " +
              (selectedTab === Tab.iOS ? "request-menu-active" : "")
            }
            onClick={(): any => setSelectedTab(Tab.iOS)}
          >
            <i className={`fab fa-apple ${styles.icon}`} />
          </button>
          <button
            className={
              "request-menu " +
              (selectedTab === Tab.linux ? "request-menu-active" : "")
            }
            onClick={(): any => setSelectedTab(Tab.linux)}
          >
            <i className={`fab fa-linux ${styles.icon}`} />
          </button>
        </div>
        {selectedTab === Tab.windows && (
          <div className="flex-1">
            <WindowsDocumentation />
          </div>
        )}
        {selectedTab === Tab.iOS && (
          <div className="flex-1">
            <IOSDocumentation />
          </div>
        )}
        {selectedTab === Tab.linux && (
          <div className="flex-1">
            <LinuxDocumentation />
          </div>
        )}
      </div>
    </Modal>
  );
};
