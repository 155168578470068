import React from "react";
import AlertIcon from "../../assets/alert.svg";
import { Label } from "./Label";
import styles from "./Label.module.css";

export const LabelAlert: React.FC = () => {
  return (
    <Label color="alert" type="">
      <>
        <img
          className={"mr-2 icon-xsmall " + styles.icon}
          alt="icon-alert"
          src={AlertIcon}
        />
        Demande en alerte
      </>
    </Label>
  );
};
