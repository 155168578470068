import React from "react";
import { TherapeuticEffortLimitationDetails } from "../../../domain/Subject/Subject";
import TherapeuticEffortLimitationEnum, {
  therapeuticEffortLimitationStyleClass,
  therapeuticEffortLimitationTranslate,
} from "../../../domain/Subject/TherapeuticEffortLimitation.enum";
import ArrayUtils from "../../../utils/array.utils";
import { CheckBox } from "./CheckBox";
import styles from "./SubjectForm.module.css";

interface Props {
  value: TherapeuticEffortLimitationDetails | undefined;
  onChange: (details: TherapeuticEffortLimitationDetails) => any;
  readOnly?: boolean;
}

export const TherapeuticEffortLimitationForm: React.FC<Props> = (
  props: Props
) => {
  const handleChange = (option: TherapeuticEffortLimitationEnum): void => {
    const newOptions = ArrayUtils.toggleValueInArray(
      props.value?.options || [],
      option
    );
    props.onChange({ options: newOptions });
  };

  return (
    <div className={styles.requestContainer}>
      <p className="text-bold mt-4 mb-2">
        Sélectionnez une ou plusieurs options
      </p>
      {Object.values(TherapeuticEffortLimitationEnum).map(
        (item: TherapeuticEffortLimitationEnum, index) => {
          return (
            <CheckBox
              key={index}
              label={therapeuticEffortLimitationTranslate[item]}
              onChange={() => handleChange(item)}
              disabled={props.readOnly}
              checked={props.value?.options?.includes(item)}
              labelClassName={
                styles[therapeuticEffortLimitationStyleClass[item]]
              }
            />
          );
        }
      )}
    </div>
  );
};
