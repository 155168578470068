import { get } from "./ApiClient";
import { AdminStatistics } from "../domain/AdminStatistics";
import { UserStatistics } from "../domain/UserStatistics";

export async function getUserStatistics(): Promise<UserStatistics | Error> {
  try {
    return await get(`/statistics/me`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getAdminStatistics(): Promise<AdminStatistics | Error> {
  try {
    return await get("/statistics/admin");
  } catch (err) {
    console.error(err);
    return err;
  }
}
