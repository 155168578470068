const toggleValueInArray = <T>(array: T[], valueToToggle: T): T[] => {
  const newArray: any[] = array || [];
  if (newArray.includes(valueToToggle)) {
    return newArray.filter((value) => value !== valueToToggle);
  } else {
    return [...newArray, valueToToggle];
  }
};

export default {
  toggleValueInArray,
};
