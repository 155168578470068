export const selectCustomStyle = {
  option: (provided: any, state: any): Record<string, any> => ({
    ...provided,
    backgroundColor: state.isFocused ? "var(--secondary-blue)" : "white",
    ":hover": {
      backgroundColor: "var(--secondary-blue)",
    },
    color: "var(--dark-grey)",
    whiteSpace: "pre-wrap",
  }),
  control: (): Record<string, any> => ({
    borderRadius: "6px",
    border: "solid 2px var(--primary-blue)",
    boxShadow: "var(--primary-blue)",
    display: "flex",
  }),
};
