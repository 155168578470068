import React, { useEffect, useState } from "react";
import { User } from "../../domain/User";
import { ToValidateUser } from "./ToValidateUser";
import history from "../../utils/history";

interface Props {
  users: User[];
  refreshUsers: () => any;
}

export const ToValidateUsersList: React.FC<Props> = (props: Props) => {
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setFilteredUsers(props.users);
  }, [props.users]);

  const search = async (event: any): Promise<any> => {
    const searchVal = event.target.value;
    setFilteredUsers([]);
    if (searchVal) {
      setFilteredUsers(
        props.users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchVal.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchVal.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(props.users);
    }
    setSearchValue(searchVal);
  };

  return (
    <>
      <div className="users-list-container">
        <input
          type="text"
          value={searchValue}
          onChange={search}
          className="search-bar"
          placeholder="Rechercher un utilisateur..."
        />
        <button
          className="btn neuro-btn rounded-btn"
          onClick={() => history.push("/create-user")}
        >
          Ajouter un utilisateur
        </button>
        {filteredUsers.length > 0 &&
          filteredUsers.map((user, index) => {
            return (
              <ToValidateUser
                user={user}
                refreshUsers={(): any => props.refreshUsers()}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};
