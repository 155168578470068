import React, { useRef } from "react";
import OpinionRequest, {
  Attachment,
  ImagingStudy,
} from "../../../../domain/OpinionRequest";
import { ImagingCard } from "./ImagingCard";
import { DetailsRequestCard } from "../../DetailsRequestCard";
import { UploadBar } from "../UploadBar";
import { DocumentCard } from "../DocumentCard";
import { UploadProgress } from "./ImagingDocumentUpload";

interface Props {
  documents: ImagingStudy[] | Attachment[];
  request: OpinionRequest;
  onDelete: (documentId: string) => any;
  onChangeFileHandler: (event: any) => any;
  disabledButton: boolean;
  sectionTitle: string;
  uploadProgress?: Map<string, UploadProgress>;
  hasFolderInput?: boolean;
  fileInputLabel?: JSX.Element;
  folderInputLabel?: JSX.Element;
  inputAccept?: string;
  id: string;
}

const instanceOfImaginStudy = (document: any): document is ImagingStudy => {
  return "StudyInstanceId" in document;
};

export const CardDisplay: React.FC<Props> = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const fileButtonHandler = (): any => {
    fileInputRef?.current?.click();
  };
  const folderButtonHandler = (): any => {
    folderInputRef?.current?.click();
  };

  const getProgressBars = () => {
    const progressBars: JSX.Element[] = [];
    props.uploadProgress?.forEach((value, key) => {
      progressBars.push(
        <div key={key} className="mb-2">
          <UploadBar actual={value.cpt} total={value.total} />
        </div>
      );
    });
    return progressBars;
  };

  return (
    <DetailsRequestCard request={props.request} cardTitle={props.sectionTitle}>
      {getProgressBars()}
      <div className="flex-row imaging-container">
        <div className="documents-container">
          {!props.documents && <i>Aucune image médicale...</i>}
          {props.documents &&
            props.documents.map((document: any, index) => {
              if (instanceOfImaginStudy(document)) {
                return (
                  <React.Fragment key={index}>
                    <ImagingCard
                      imagingStudy={document}
                      requestId={props.request?.id ?? ""}
                      onDeleteImagingStudy={props.onDelete}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <DocumentCard
                      document={document}
                      requestId={props.request?.id ?? ""}
                      onDeleteDocument={props.onDelete}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div className="add-imaging-container">
          {props.hasFolderInput && (
            <>
              <button
                className="document-card add-document-btn"
                onClick={folderButtonHandler}
                disabled={props.disabledButton}
              >
                <span className="add-btn-text text-bold">
                  {props.folderInputLabel ?? "Ajouter un dossier"}
                </span>
                <span className="circle plus-icon-container">
                  <i className="icon icon-plus" />
                </span>
              </button>
              <input
                id={`${props.id}-folder`}
                className="display-none"
                ref={folderInputRef}
                type="file"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-expect-error */
                directory="true"
                webkitdirectory="true"
                onChange={props.onChangeFileHandler}
              />
            </>
          )}
          <input
            id={props.id}
            className="display-none"
            ref={fileInputRef}
            type="file"
            accept={props.inputAccept ?? ""}
            onChange={props.onChangeFileHandler}
          />
          <button
            className="document-card add-document-btn"
            onClick={fileButtonHandler}
            disabled={props.disabledButton}
          >
            <span className="add-btn-text text-bold">
              {props.fileInputLabel ?? "Ajouter un document"}
            </span>
            <span className="circle plus-icon-container">
              <i className="icon icon-plus" />
            </span>
          </button>
        </div>
      </div>
    </DetailsRequestCard>
  );
};
