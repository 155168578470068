import React from "react";
import { TimePicker } from "./TimePicker";
import { Availability } from "../../../domain/Availability";
import { Facility } from "../../../domain/Facility";

interface Props {
  onChange: (upatedAvailability: Availability) => any;
  onDelete: () => any;
  availability: Availability;
  facilities?: Facility[];
  error?: string;
}

export const AvailabilityLine: React.FC<Props> = (props: Props) => {
  const updateAvailability = (
    startTime: number,
    endTime: number,
    facilityId: string
  ): any => {
    const upatedAvailability = {
      id: props.availability.id,
      startTime: isNaN(startTime) ? props.availability.startTime : startTime,
      endTime: isNaN(endTime) ? props.availability.endTime : endTime,
      day: props.availability.day,
      facilityId:
        facilityId === "" ? props.availability.facilityId : facilityId,
    };
    props.onChange(upatedAvailability);
  };

  const getFacilityName = (facilityId: string): any => {
    const savedFacility = props.facilities?.find(
      (facility) => facility.id === facilityId
    );
    return savedFacility?.name;
  };

  return (
    <>
      <div className="space-between-content row w-100">
        <div className="row time-boxes">
          <TimePicker
            hourTime={props.availability.startTime}
            valid={!props.error}
            onChange={(updatedStartTime): any => {
              updateAvailability(updatedStartTime, NaN, "");
            }}
          />
          <span className="mr-1 ml-1"> - </span>
          <TimePicker
            hourTime={props.availability.endTime}
            valid={!props.error}
            onChange={(updatedEndTime): any => {
              updateAvailability(NaN, updatedEndTime, "");
            }}
          />
        </div>
        <div>
          <select
            className="form-select w-100"
            onChange={(e): any => {
              updateAvailability(NaN, NaN, e.target.value);
            }}
            defaultValue=""
          >
            {props.availability.facilityId ? (
              <option hidden value={props.availability.facilityId}>
                {getFacilityName(props.availability.facilityId)}
              </option>
            ) : (
              <option hidden value="">
                Sélectionner un centre...
              </option>
            )}
            {props.facilities?.map((facility) => {
              return (
                <option key={facility.id} value={facility.id}>
                  {facility.name}
                </option>
              );
            })}
          </select>
        </div>
        <button type="button" className="btn-modify" onClick={props.onDelete}>
          <i className="icon icon-delete neuro-primary" />
        </button>
      </div>
      {props.error && (
        <ul className="error-availability">
          <li>{props.error}</li>
        </ul>
      )}
    </>
  );
};
