import React, { useContext, useEffect, useState } from "react";
import { useToggleClickOutside } from "../../utils/useToggleClickOutside.hook";
import styles from "./FormOpinionRequestBurgerMenu.module.css";
import OpinionRequest, { SharePermission } from "../../domain/OpinionRequest";
import { AppContext } from "../../utils/state";
import TrashIcon from "../../assets/trash-darker.png";
import { ModalInfo } from "../Shared/ModalInfo";
import { ExpertStatus } from "../../domain/ExpertStatus";
import { UserRoles } from "../../domain/UserRoles";
import { RequestStatus } from "../../domain/RequestStatus";

interface Props {
  readOnly: boolean;
  request?: OpinionRequest;
  addParticipant: () => void;
  openChat: () => void;
  saveAndQuit: () => void;
  delete: () => void;
}

export const FormOpinionRequestBurgerMenu: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState<boolean>(false);
  const [showDropDown, toggleDropdown] = useToggleClickOutside(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    props.request?.assignedExperts?.forEach((expert) => {
      if (expert.status === ExpertStatus.ACCEPTED) {
        setDisabledDeleteBtn(true);
      }
    });
  }, [props.request]);

  return (
    <div className={"col-2 " + styles.dropdown}>
      <button
        className={"btn dropdown-toggle " + styles.dropdownBtn}
        onClick={toggleDropdown}
      >
        <i
          id="icon-more-horiz"
          className={"icon icon-more-horiz " + styles.iconSize}
        />
      </button>
      {showDropDown && (
        <div className={styles.dropdownContent + " " + styles.btnContainer}>
          {props.request?.participants &&
            props.request.id !== "" &&
            !props.readOnly &&
            props.request.status === RequestStatus.VALIDATED && (
              <button
                className={"space-between-content " + styles.menuItem}
                onClick={props.addParticipant}
              >
                <i className={"icon icon-people " + styles.iconColor} />
                <div>Ajouter un intervenant</div>
              </button>
            )}
          {props.request?.participants && props.request.id !== "" && (
            <button
              className={"space-between-content " + styles.menuItem}
              onClick={props.openChat}
            >
              <i className={"icon icon-message " + styles.iconColor} />
              <div>Envoyer un message</div>
            </button>
          )}
          {((props.request?.participants &&
            props.request?.participants.some(
              (p) =>
                p.userId === appContext.state.user.id &&
                p.permission === SharePermission.ADMIN
            )) ||
            appContext.state.user.roles.includes(
              UserRoles.GLOBAL_DISPATCHER
            )) && (
            <button
              className={"space-between-content " + styles.menuItem}
              onClick={(): any => setShowModal(true)}
              disabled={disabledDeleteBtn}
            >
              <img
                className={"icon-disabled mobile-view-icon " + styles.iconColor}
                alt="icon-trash"
                src={TrashIcon}
              />
              <div>Supprimer la demande</div>
            </button>
          )}
          <button
            className={"space-between-content " + styles.menuItem}
            onClick={props.saveAndQuit}
          >
            <i className={"icon icon-bookmark " + styles.iconColor} />
            <div>Enregistrer et quitter</div>
          </button>
        </div>
      )}
      {showModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowModal(false)}
          rightButtonAction={props.delete}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer cette demande ?</>
        </ModalInfo>
      )}
    </div>
  );
};
