enum RequestPrimaryType {
  ADVICE_ON_PATHOLOGY = "ADVICE_ON_PATHOLOGY",
  EEG_INTERPRETATION = "EEG_INTERPRETATION",
  EEG_INTERPRETATION_ONLY = "EEG_INTERPRETATION_ONLY",
  IMAGING_INTERPRETATION = "IMAGING_INTERPRETATION",
  THERAPEUTIC_EFFORT_LIMITATION = "THERAPEUTIC_EFFORT_LIMITATION",
  PATIENT_FILE_CREATION = "PATIENT_FILE_CREATION",
}

export const requestPrimaryTypeTranslate: Record<RequestPrimaryType, string> = {
  ADVICE_ON_PATHOLOGY: "Avis sur une pathologie",
  EEG_INTERPRETATION: "Interprétation d’EEG",
  EEG_INTERPRETATION_ONLY: "Interprétation d’EEG seule",
  IMAGING_INTERPRETATION: "Interprétation d’imagerie",
  THERAPEUTIC_EFFORT_LIMITATION:
    "Procédure de Limitation et d’Arrêt des Thérapeutiques", // Limitation of therapeutic effort, LAT in French
  PATIENT_FILE_CREATION: "Aide à la constitution d'un dossier patient",
};

export default RequestPrimaryType;
