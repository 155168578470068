import React, { PropsWithChildren } from "react";
import dashboardStyles from "./Dashboards/Dashboards.module.css";

interface Props {
  title: string;
  actions: Action[];
  className?: string;
}

interface Action {
  id: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

export const HomeCard: React.FC<Props> = ({
  title,
  children,
  actions,
  className = "",
}: PropsWithChildren<Props>) => {
  return (
    <div className={`white-card w-100 ${className}`}>
      <div
        className={`margin-bottom-2 text-medium ${dashboardStyles.boldTitle}`}
      >
        {title}
      </div>
      <div className={dashboardStyles.darkDivider} />
      {children}
      <div
        className={`flex-row space-around-content mr-4 ml-4 ${dashboardStyles.mobileFlexCol}`}
      >
        {actions.map((action) => (
          <button
            id={action.id}
            key={action.id}
            className="btn secondary-btn margin-top-2"
            disabled={action.disabled}
            onClick={action.onClick}
          >
            {action.label}
          </button>
        ))}
      </div>
    </div>
  );
};
