import React, { useState } from "react";
import PathologyEnum, {
  pathologyStyleClass,
  pathologyTranslate,
} from "../../../domain/Subject/Pathology.enum";
import styles from "./PathologyTags.module.css";

interface Props {
  checkedTags: PathologyEnum[];
  removeItem: (tag: PathologyEnum) => any;
}

export const PathologyTags: React.FC<Props> = (props: Props) => {
  const [showingAllChoices, setShowingAllChoices] = useState<boolean>(false);

  const tags = props.checkedTags || [];

  const displayTag = (tag: PathologyEnum, index: number) => (
    <label
      key={index}
      className={`${styles.optionTag} ${styles[pathologyStyleClass[tag]]}`}
    >
      {pathologyTranslate[tag]}
      <i
        className={`fas fa-times ml-2 clickable ${styles.crossIcon}`}
        onClick={(): any => props.removeItem(tag)}
      />
    </label>
  );

  return (
    <div className="mt-2 mb-2">
      {tags.length > 0 && (
        <>{showingAllChoices ? tags.map(displayTag) : displayTag(tags[0], 0)}</>
      )}
      {tags.length > 1 && (
        <div
          className={`w-90 centered-content clickable ${styles.seeMoreBtn}`}
          onClick={(): any => setShowingAllChoices(!showingAllChoices)}
        >
          {showingAllChoices ? (
            <>Voir moins de tags</>
          ) : (
            <>
              Voir plus de tags
              <div className={`ml-2 ${styles.moreTags}`}>
                +{tags.length - 1}
              </div>
            </>
          )}
          <i className="fas fa-chevron-up ml-2" />
        </div>
      )}
    </div>
  );
};
