import React from "react";

interface PropsCB {
  id: string;
  isChecked: boolean;
  value: any;
  handleCheckElement: any;
  checkboxId: string;
}

export const CheckBox: React.FC<PropsCB> = (props: PropsCB) => {
  return (
    <input
      key={props.id}
      id={props.checkboxId}
      data-cy={props.checkboxId}
      onClick={props.handleCheckElement}
      type="checkbox"
      checked={props.isChecked}
      value={props.value}
    />
  );
};
