import React, { useContext } from "react";
import Modal from "../Modal";
import styles from "./ModalMissingMandatoryFields.module.css";
import { OpinionRequestMandatoryFieldsStatus } from "../../utils/OpinionRequestMandatoryFieldsStatus";
import { OpinionRequestFormPage } from "../../domain/OpinionRequest";
import { AppContext } from "../../utils/state";
import { HealthProfessionalTitle } from "../../domain/User";

interface Props {
  onClose: () => void;
  requestId: string;
  isEmergencyRequest: boolean;
  mandatoryFieldsStatus: OpinionRequestMandatoryFieldsStatus;
  goToPage: (index: OpinionRequestFormPage) => void;
}

export const ModalMissingMandatoryFields: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);

  const closeModal = (): void => {
    props.onClose();
  };

  const renderMandatoryFieldStatus = (
    goToPage: number,
    fieldStatus: boolean,
    label: string
  ) => {
    return (
      <button
        onClick={() => props.goToPage(goToPage)}
        className={`d-flex w-90 mb-4 ${styles.flatButton} ${
          styles.underline
        } clickable space-between-content text-bold ${
          fieldStatus ? "red-text" : "green-text"
        }`}
      >
        {label}
        {fieldStatus ? (
          <i className="icon icon-cross mt-auto mb-auto" />
        ) : (
          <i className="icon icon-check mt-auto mb-auto" />
        )}
      </button>
    );
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Impossible de soumettre la demande"}
      unsetHeight={true}
    >
      <div className={styles.modalContainer}>
        <div className="mb-4">
          Vous ne pouvez pas soumettre votre demande car des champs obligatoires
          n&apos;ont pas été remplis. Cliquez sur les liens ci-dessous pour être
          redirigé vers les pages correspondantes du formulaire.
        </div>
        <h6 className={`mb-4 ${styles.title}`}>
          Liste des champs obligatoires :
        </h6>
        {[
          renderMandatoryFieldStatus(
            1,
            props.mandatoryFieldsStatus.emergencyLevel,
            "Degré d'urgence"
          ),
          renderMandatoryFieldStatus(
            1,
            props.mandatoryFieldsStatus.patientConsent,
            "Consentement du patient"
          ),
          renderMandatoryFieldStatus(
            2,
            props.mandatoryFieldsStatus.patientBirthday,
            "Date de naissance du patient"
          ),
        ]}
        {!props.isEmergencyRequest &&
          renderMandatoryFieldStatus(
            4,
            props.mandatoryFieldsStatus.primaryDiseases,
            "Motif d'admission"
          )}
        {appContext.state.user.professionalTitle !==
          HealthProfessionalTitle.DOCTOR &&
          !props.isEmergencyRequest &&
          renderMandatoryFieldStatus(
            6,
            props.mandatoryFieldsStatus.healthPathwayContacts,
            "Médecin du parcours de santé"
          )}
        {renderMandatoryFieldStatus(
          8,
          props.mandatoryFieldsStatus.motive,
          "Motif de la demande"
        )}
        <button onClick={closeModal} className="btn rectangular-btn close-btn">
          Retour
        </button>
      </div>
    </Modal>
  );
};
