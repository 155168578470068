import React from "react";
import styles from "./DispatcherDashboard.module.css";
import dashboardStyles from "../Dashboards.module.css";
import WaitingIcon from "../../../assets/waiting.svg";
import EmergencyIcon from "../../../assets/emergency.svg";
import AlertIcon from "../../../assets/alert.svg";

interface Props {
  iconName: string;
  requestNumber: number;
  children: any;
  goToDetails?: () => any;
  seeMore?: () => any;
}

export const OpinionRequestCategoryCard: React.FC<Props> = (props: Props) => {
  const { iconName, requestNumber, children, goToDetails, seeMore } = props;

  const showStatusIcon = (): any => {
    switch (iconName) {
      case "WaitingIcon":
        return (
          <div className={dashboardStyles.primaryBlueCard}>
            <img alt="icon-waiting" src={WaitingIcon} />{" "}
          </div>
        );
      case "EmergencyIcon":
        return (
          <div className={dashboardStyles.blueCard}>
            <img alt="icon-emergency" src={EmergencyIcon} />
          </div>
        );
      case "ToValidateRequestsIcon":
        return (
          <div className={dashboardStyles.redCard}>
            <img alt="icon-to-validate-requests" src={AlertIcon} />
          </div>
        );
      case "OngoingRequestsIcon":
        return (
          <div className={dashboardStyles.blueCard}>
            <img alt="icon-ongoing-requests" src={EmergencyIcon} />
          </div>
        );
      case "ClosedRequestsIcon":
        return (
          <div className={dashboardStyles.orangeCard}>
            <img alt="icon-closed-requests" src={WaitingIcon} />
          </div>
        );
      default:
        return (
          <div className={dashboardStyles.redCard}>
            <img alt="icon-alert" src={AlertIcon} />
          </div>
        );
    }
  };

  return (
    <>
      <div id={props.iconName} className={dashboardStyles.categoryCard}>
        <div className="row centered-content">{showStatusIcon()}</div>
        <div
          className={`mt-2 mb-0 text-centered ${dashboardStyles.requestNumber}`}
        >
          {requestNumber}
        </div>
        <div
          className={`h-20 text-bold text-centered text-small ${styles.cardText}`}
        >
          {children}
        </div>
        {goToDetails ? (
          <div className={`h-20 ${styles.cardText}`}>
            {" "}
            <button className="btn secondary-btn" onClick={goToDetails}>
              Voir le détail
            </button>
          </div>
        ) : (
          seeMore &&
          requestNumber > 0 && (
            <div className={`h-20 ${styles.cardText}`}>
              {" "}
              <button
                id="see-more-btn"
                className="btn secondary-btn mb-2"
                onClick={seeMore}
              >
                Voir plus
              </button>
            </div>
          )
        )}
      </div>
    </>
  );
};
