import React, { useCallback, useEffect, useState } from "react";
import { Figure } from "../../../Shared/Figure";
import { expertiseLevelToString } from "../../../../domain/ExpertiseLevels";
import "./Cards.css";
import {
  getPermissionLabel,
  Participant,
} from "../../../../domain/OpinionRequest";
import { getUser } from "../../../../services/UserService";
import { errorToast } from "../../../../utils/Toast";
import { getMinimizedTitleLabel, User } from "../../../../domain/User";
import { Label } from "../../../Shared/Label";
import { ModalInfo } from "../../../Shared/ModalInfo";

interface Props {
  participant: Participant;
  onClick?: (event: any) => any;
  removeParticipant?: (p: Participant) => any;
  canEditPermission: boolean;
}

/*Intervenant card : not expert*/
export const ParticipantNotExpertCard: React.FC<any> = (props: Props) => {
  const [participantUser, setParticipantUser] = useState<User>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const getParticipantInfo = useCallback(async (): Promise<any> => {
    const res = await getUser(props.participant.userId);
    if (res instanceof Error) {
      setParticipantUser(undefined);
      errorToast("Erreur lors de la récupération de l'intervenant");
    } else {
      setParticipantUser(res);
    }
  }, [props.participant.userId]);

  useEffect(() => {
    getParticipantInfo();
  }, [getParticipantInfo]);

  return (
    <div className="p-relative w-100 mb-2 partipant-card">
      <div className="participant-legend">INTERVENANT</div>
      <div className="card-body participant-card-body">
        <div className="container">
          <div className="flex-row mb-1 mt-2 ">
            <div className="figure-size">
              <Figure
                firstName={participantUser?.firstName || ""}
                lastName={participantUser?.lastName || ""}
                fontSize={"text-medium"}
              />
            </div>
            {props.onClick !== undefined && props.canEditPermission && (
              <button
                className="row centered-content remove-participant-button"
                onClick={(): void => setShowModal(true)}
              >
                ×
              </button>
            )}
            <div className="participant-header">
              <div className="card-patient">
                {getMinimizedTitleLabel(participantUser?.professionalTitle) +
                  " " +
                  participantUser?.firstName +
                  " " +
                  participantUser?.lastName}
              </div>
              <div className="text-bold profession">
                {participantUser?.profession}
              </div>
            </div>
            {props.onClick === undefined && (
              <div className="ml-auto">
                <Label color="" type="">
                  Requérant
                </Label>
              </div>
            )}
          </div>
          <div className="participant-grid-container">
            <div className="specialty">
              <label className="text-bold mr-2">Spécialité</label>
              {participantUser?.specialty ? (
                <div>{participantUser.specialty}</div>
              ) : (
                <div>Non renseignée</div>
              )}
            </div>
            <div className="sub-specialty">
              <label className="text-bold mr-2">Sous-spécialités</label>
              {participantUser?.subSpecialties?.length === 0 && (
                <div>Non renseignées</div>
              )}
              {participantUser?.subSpecialties?.map((subSpeciality, index) => {
                return (
                  <div className="row w-100" key={index}>
                    <div className="sub-spe">
                      {subSpeciality.name}
                      {" - "}
                      {expertiseLevelToString(
                        subSpeciality.expertiseLevel
                      ).toLocaleUpperCase()}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="work-place">
              <label className="text-bold mr-2">
                Lieu
                {participantUser?.facilities &&
                  participantUser?.facilities?.length > 1 &&
                  "x"}{" "}
                d&apos;exercice
              </label>
              {!participantUser?.facilities ? (
                <div>Non renseigné</div>
              ) : (
                participantUser?.facilities?.map((facility, index) => {
                  return (
                    <div className="row w-100" key={index}>
                      <div className="sub-spe">
                        {facility.name} - {facility.addressRoutingLabel}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div className="permission">
              <label className="text-bold mr-2">
                Droit :{" "}
                {props.participant.permission ? (
                  <label className="text-normal">
                    {getPermissionLabel(props.participant.permission)}
                  </label>
                ) : (
                  <label>Non renseignées</label>
                )}
              </label>
            </div>
          </div>
          {props.onClick !== undefined && (
            <div className="row centered-content">
              <button
                className="btn permission-button"
                onClick={props.onClick}
                disabled={!props.canEditPermission}
              >
                Modifier les droits
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowModal(false)}
          rightButtonAction={() => {
            props.removeParticipant &&
              props.removeParticipant(props.participant);
          }}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>
            Êtes-vous sûr de vouloir supprimer {props.participant.firstName}{" "}
            {props.participant.lastName} des participants ?
          </>
        </ModalInfo>
      )}
    </div>
  );
};
