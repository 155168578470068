import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/state";
import {
  HealthPathwayContact,
  HealthPathwayContactType,
} from "../../domain/OpinionRequest";
import styles from "./ReferralDoctorForm.module.css";
import { HealthPathwayContactCard } from "./HealthPathwayContactCard";
import { ProfessionCategory, HealthProfessionalUser } from "../../domain/User";
import { SearchHealthProfessional } from "./SearchHealthProfessional";
import useHealthPathwayContact from "./useHealthPathwayContact";
import { AddNewUser } from "./Participants/AddNewUser";

interface Props {
  healthPathwayContacts: HealthPathwayContact[];
  onChange: (newState?: HealthPathwayContact[]) => any;
  readOnly: boolean;
}

export const ParamedicalHealthPathwayForm: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [checked, setChecked] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();
  const hpContacts = props.healthPathwayContacts || [];

  const {
    addUserFromDirectory,
    addToHealthPathway,
    removeFromHealthPathway,
    setSearchInDirectory,
    searchInDirectory,
  } = useHealthPathwayContact(
    HealthPathwayContactType.PARAMEDICAL,
    hpContacts,
    props.onChange
  );

  useEffect(() => {
    const currentUser = hpContacts.find(
      (user) => user.userId === appContext.state.user.id
    );
    setChecked(currentUser?.category === HealthPathwayContactType.PARAMEDICAL);
    setDisabled(currentUser?.category === HealthPathwayContactType.DOCTOR);
  }, [hpContacts, appContext.state.user.id]);

  const handleCheck = (e: any): void => {
    const hPUser: HealthProfessionalUser = {
      ...appContext.state.user,
      userId: appContext.state.user.id,
      rpps: appContext.state.user.rpps || "",
      profession: appContext.state.user.profession || "",
    };
    if (e.target.checked) {
      addToHealthPathway(hPUser);
    } else {
      removeFromHealthPathway(hPUser);
    }
    setChecked(!checked);
  };

  return (
    <>
      <p className={styles.description}>
        Vous pouvez indiquer l&apos;équipe paramédicale faisant partie du
        parcours de santé du patient.
      </p>
      {!props.readOnly && (
        <>
          <label className="form-checkbox c-hand">
            <input
              onChange={handleCheck}
              type="checkbox"
              checked={checked}
              disabled={disabled}
            />
            <i className={"form-icon " + styles.checkBox} />
            <div className="row">
              <div>Vous faites partie de l&apos;équipe paramédicale</div>
            </div>
            {disabled && (
              <i className="text-primary-blue">
                Vous êtes déjà ajouté aux médecins du parcours de santé.
              </i>
            )}
          </label>

          <SearchHealthProfessional
            onSelected={(newDoctor?: HealthProfessionalUser): any => {
              if (newDoctor?.userId === "") {
                setHpToAdd(newDoctor);
                setSearchInDirectory(true);
              } else {
                addToHealthPathway(newDoctor);
              }
            }}
            healthPathwayContacts={hpContacts}
            professionFilter={ProfessionCategory.PARAMEDICAL}
          />
          <AddNewUser
            userToAdd={hpToAdd}
            show={searchInDirectory}
            onClose={(): any => setSearchInDirectory(false)}
            onAddUser={addUserFromDirectory}
          />
        </>
      )}

      <div>
        {hpContacts
          .filter((hp) => hp.category === HealthPathwayContactType.PARAMEDICAL)
          .map((referralDoctor) => (
            <HealthPathwayContactCard
              key={referralDoctor.userId}
              doctor={referralDoctor}
              handleRemove={removeFromHealthPathway}
              readOnly={props.readOnly}
              setDoctor={(hpContact): any => {
                const newHpContacts = hpContacts.map((v) => {
                  if (v.userId === hpContact.userId) {
                    return hpContact;
                  }
                  return v;
                });
                props.onChange(newHpContacts);
              }}
            />
          ))}
      </div>
    </>
  );
};
