enum RequestEEGReason {
  SEARCH_EPILIPTICAL_ANOMALY = "SEARCH_EPILIPTICAL_ANOMALY",
  OTHER_REASON = "OTHER_REASON",
}

export const RequestEEGReasonTranslate: Record<RequestEEGReason, string> = {
  SEARCH_EPILIPTICAL_ANOMALY: "Recherche d'anomalies épileptiques",
  OTHER_REASON: "Autres motifs",
};

export default RequestEEGReason;
