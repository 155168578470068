import React from "react";
import styles from "./ChoiceSwitcher.module.css";

interface Props {
  onClick: (changeEvent: any) => any;
  choiceName: string;
  currentChoice: any;
  firstChoice: any;
  firstChoiceName: string;
  secondChoice: any;
  secondChoiceName: string;
  disabled: boolean;
}

export const ChoiceSwitcher: React.FC<Props> = (props: Props) => {
  return (
    <label
      className={styles.choiceSwitcherBtnContainer}
      onClick={props.onClick}
    >
      <label className={styles.choiceSwitcherBtn}>
        <input
          type="radio"
          value={props.firstChoice}
          name={props.choiceName}
          checked={props.currentChoice === props.firstChoice}
          disabled={props.disabled}
          readOnly
        />
        <span className={styles.title}>{props.firstChoiceName}</span>
      </label>
      <label className={styles.choiceSwitcherBtn}>
        <input
          type="radio"
          value={props.secondChoice}
          name={props.choiceName}
          checked={props.currentChoice === props.secondChoice}
          disabled={props.disabled}
          readOnly
        />
        <span className={styles.title}>{props.secondChoiceName}</span>
      </label>
    </label>
  );
};
