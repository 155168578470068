import React from "react";
import styles from "./HomeFooter.module.css";
import history from "../utils/history";

export const HomeFooter: React.FC = () => {
  return (
    <div className={`w-100 text-centered ${styles.container}`}>
      <button
        className={styles.buttonLink}
        id="contact-btn"
        onClick={(): any => history.push("/contact")}
      >
        Contact
      </button>
      <button
        className={styles.buttonLink}
        id="contact-btn"
        onClick={(): any => history.push("/general-terms-of-use")}
      >
        CGU
      </button>
    </div>
  );
};
