import React, { useContext, useEffect, useState } from "react";
import history from "../../utils/history";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import styles from "./Invoicing.module.css";
import { InvoiceInfoCard } from "./InvoiceInfoCard";
import { AppContext } from "../../utils/state";
import {
  listFacilitiesToInvoiceByMonth,
  sendInvoicingInfoByEmail,
} from "../../services/FacilityService";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { toast } from "react-toastify";
import { InvoicingInfo } from "../../domain/InvoicingInfo";
import Select from "react-select";
import { errorToast, successToast } from "../../utils/Toast";
import { getCurrentYear } from "../../utils/time";
import { getMonthName, getCurrentMonth, Month } from "../../domain/Month";
import { Spinner } from "../Shared/Spinner";

enum FilterByStatus {
  ALL = "Toutes les demandes",
  WAITING = "En attente de facturation",
  SENT = "Eléments de facturation envoyés",
}

const options = [
  { value: FilterByStatus.ALL, label: "Toutes les demandes" },
  { value: FilterByStatus.WAITING, label: "En attente de facturation" },
  { value: FilterByStatus.SENT, label: "Eléments de facturation envoyés" },
];

export const InvoicingAssistance: React.FC<any> = () => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [allInvoicingInfo, setAllInvoicingInfo] = useState<InvoicingInfo[]>([]);
  const [searchedInvoicingInfo, setSearchedInvoicingInfo] = useState<
    InvoicingInfo[]
  >([]);
  const [selectedInvoicingInfo, setSelectedInvoicingInfo] = useState<
    InvoicingInfo[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<any>(options[0]);
  const [selectAllInvoicingInfo, setSelectAllInvoicingInfo] = useState<boolean>(
    false
  );
  const [stringInvoicingInfo, setStringInvoicingInfo] = useState<string>();
  const [sendAllButtonDisabled, setSendAllButtonDisabled] = useState<boolean>(
    false
  );
  const [isInvoicingInfosChecked, setIsInvoicingInfosChecked] = useState<
    boolean[]
  >([]);

  const appContext = useContext(AppContext);

  const [selectedMonth, setSelectedMonth] = useState<Month>(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState<number>(getCurrentYear);

  const previousMonth = (): void => {
    // if current month is january, selectedMonth = 1
    if (selectedMonth == 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
    setSelectAllInvoicingInfo(false);
    setSelectedInvoicingInfo([]);
  };

  const nextMonth = (): void => {
    // if current month is december, selectedMonth = 12
    if (selectedMonth == 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
    setSelectAllInvoicingInfo(false);
    setSelectedInvoicingInfo([]);
  };

  const filterByStatus = (inputValue: any): void => {
    setSelectedValue(inputValue);

    if (inputValue !== null) {
      switch (inputValue.value) {
        case FilterByStatus.WAITING:
          setSearchedInvoicingInfo(
            allInvoicingInfo?.filter(
              (invoicingInfo) => !invoicingInfo.invoiceInfoSent
            )
          );
          break;
        case FilterByStatus.SENT:
          setSearchedInvoicingInfo(
            allInvoicingInfo?.filter(
              (invoicingInfo) => invoicingInfo.invoiceInfoSent
            )
          );
          break;
        default:
          setSearchedInvoicingInfo(allInvoicingInfo);
      }
    }
  };

  const checkAllCheckboxDisabled = (): boolean => {
    return searchedInvoicingInfo.some(
      (invoicingInfo) => invoicingInfo.email === ""
    );
  };

  useEffect(() => {
    setSendAllButtonDisabled(false);
    if (selectedInvoicingInfo.length === 0) {
      setSendAllButtonDisabled(true);
    }
    selectedInvoicingInfo.forEach((invoicingInfo) => {
      if (invoicingInfo.email === "") {
        setSendAllButtonDisabled(true);
      }
    });
    if (
      (selectedMonth >= getCurrentMonth() &&
        selectedYear === getCurrentYear()) ||
      selectedYear > getCurrentYear()
    ) {
      setSendAllButtonDisabled(true);
    }
  }, [
    stringInvoicingInfo,
    selectAllInvoicingInfo,
    selectedInvoicingInfo,
    selectedMonth,
    selectedYear,
  ]);

  useEffect(() => {
    if (!hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
      history.push("/forbidden");
    } else {
      const getFacilitiesToInvoiceByMonth = async (): Promise<any> => {
        await listFacilitiesToInvoiceByMonth(selectedMonth, selectedYear).then(
          (res: InvoicingInfo[] | Error) => {
            if (res instanceof Error) {
              setStatus(LoadingStatus.ERROR);
              toast.error("Erreur durant le chargement des centres de santé");
            } else {
              if (res !== null) {
                setAllInvoicingInfo(res);
                setSearchedInvoicingInfo(res);
                const newIsInvoicingInfosChecked: boolean[] = [];
                res?.forEach(() => {
                  newIsInvoicingInfosChecked.push(false);
                });
                setIsInvoicingInfosChecked(newIsInvoicingInfosChecked);
              } else {
                setAllInvoicingInfo([]);
                setSearchedInvoicingInfo([]);
                setIsInvoicingInfosChecked([]);
              }
              setStatus(LoadingStatus.SUCCESS);
            }
          }
        );
      };
      getFacilitiesToInvoiceByMonth();
      setSelectedValue(options[0]);
    }
  }, [appContext.state.user.roles, selectedMonth, selectedYear]);

  const search = (event: any): any => {
    const searchVal = event.target.value;
    setSearchedInvoicingInfo(allInvoicingInfo);
    if (searchVal && allInvoicingInfo !== undefined) {
      setSearchedInvoicingInfo(
        allInvoicingInfo.filter(
          (invoicingInfo) =>
            invoicingInfo.facility.name
              ?.toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            invoicingInfo.facility.facilityKind
              ?.toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            invoicingInfo.facility.addressRoutingLabel
              ?.toLowerCase()
              .includes(searchVal.toLowerCase())
        )
      );
    } else {
      setSearchedInvoicingInfo(allInvoicingInfo);
    }
    setSearchValue(searchVal);
  };

  const formatOptionLabel = ({ label }: { label: string }): any => (
    <div>{label}</div>
  );

  const addOrRemoveInvoiceToSelect = (invoicingInfo: InvoicingInfo): any => {
    if (selectedInvoicingInfo?.includes(invoicingInfo)) {
      setSelectedInvoicingInfo(
        selectedInvoicingInfo.filter(
          (invoicing: InvoicingInfo) => invoicing.id !== invoicingInfo.id
        )
      );
      setStringInvoicingInfo(
        JSON.stringify(
          selectedInvoicingInfo.filter(
            (invoicing: InvoicingInfo) => invoicing.id !== invoicingInfo.id
          )
        )
      );
    } else {
      setSelectedInvoicingInfo([...selectedInvoicingInfo, invoicingInfo]);
      setStringInvoicingInfo(
        JSON.stringify([...selectedInvoicingInfo, invoicingInfo])
      );
    }
    const newIsInvoicingInfosChecked: boolean[] = isInvoicingInfosChecked;

    searchedInvoicingInfo.forEach((invoicing: InvoicingInfo, index: number) => {
      if (invoicing.id === invoicingInfo.id) {
        newIsInvoicingInfosChecked[index] = !newIsInvoicingInfosChecked[index];
      }
    });
    setIsInvoicingInfosChecked(newIsInvoicingInfosChecked);
    if (newIsInvoicingInfosChecked.includes(false)) {
      setSelectAllInvoicingInfo(false);
    } else {
      setSelectAllInvoicingInfo(true);
    }
  };

  const updateSendedStatus = (invoicingInfoId: string): any => {
    const toUpdateInvoicing = allInvoicingInfo.find(
      (invoicing) => invoicing.id === invoicingInfoId
    );

    if (toUpdateInvoicing !== undefined) {
      toUpdateInvoicing.invoiceInfoSent = true;
      let newInvoicings = allInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setAllInvoicingInfo(newInvoicings);
      newInvoicings = searchedInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setSearchedInvoicingInfo(newInvoicings);
      newInvoicings = selectedInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setSelectedInvoicingInfo(newInvoicings);
      setStringInvoicingInfo(JSON.stringify(newInvoicings));
    }
  };

  const sendMultipleInvoicingInfo = async (): Promise<void> => {
    let isError = false;
    const promises = selectedInvoicingInfo.map(
      async (invoicingInfo): Promise<void> => {
        await sendInvoicingInfoByEmail(
          invoicingInfo.id,
          invoicingInfo.facility.id,
          selectedMonth,
          selectedYear
        ).then((res: any | Error) => {
          if (res instanceof Error) {
            isError = true;
          } else {
            updateSendedStatus(invoicingInfo.id);
          }
        });
      }
    );
    await Promise.all(promises);
    if (isError) {
      errorToast(
        "Au moins un e-mail de ceux séléctionnés n'a pas pu être envoyé"
      );
    } else {
      successToast(
        "Vous avez bien envoyé les informations de facturation aux centres sélectionnés"
      );
    }
  };

  const updateInvoicingEmail = (
    email: string,
    invoicingInfoId: string
  ): any => {
    const toUpdateInvoicing = allInvoicingInfo.find(
      (invoicing) => invoicing.id === invoicingInfoId
    );

    if (toUpdateInvoicing !== undefined) {
      toUpdateInvoicing.email = email;
      let newInvoicings = allInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setAllInvoicingInfo(newInvoicings);
      newInvoicings = searchedInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setSearchedInvoicingInfo(newInvoicings);
      newInvoicings = selectedInvoicingInfo.map((invoicing: InvoicingInfo) => {
        if (invoicing.id === invoicingInfoId) {
          return toUpdateInvoicing;
        } else {
          return invoicing;
        }
      });
      setSelectedInvoicingInfo(newInvoicings);
      setStringInvoicingInfo(JSON.stringify(newInvoicings));
    }
  };

  return (
    <>
      <div className={styles.margins}>
        <div className={"row " + styles.monthContainer}>
          <button onClick={previousMonth} className={styles.arrowButton}>
            <i className="icon icon-arrow-left neuro-primary" />
          </button>
          <div className={styles.monthAndYear}>
            {getMonthName(selectedMonth).toUpperCase()} {selectedYear}
          </div>
          <button onClick={nextMonth} className={styles.arrowButton}>
            <i className="icon icon-arrow-right neuro-primary" />
          </button>
        </div>
        <div
          className={`col-10 col-sm-auto col-mx-auto w-70 ${styles.invoicingPageContainer}`}
        >
          <div className={`${styles.container} ${styles.margins}`}>
            <input
              type="text"
              value={searchValue}
              onChange={search}
              className={styles.searchBar}
              placeholder="Rechercher un centre ou un nom..."
            />
          </div>
          <div className={`${styles.container} ${styles.margins}`}>
            {searchedInvoicingInfo.length > 0 && (
              <div
                className={
                  "flex-row space-between-content " + styles.marginBottom10
                }
              >
                <label className="form-checkbox c-hand">
                  <input
                    key="selectAllInvoicingInfo"
                    value="selectAllInvoicingInfo"
                    checked={selectAllInvoicingInfo}
                    onClick={(event: any): void => {
                      const newIsInvoicingInfosChecked: boolean[] = [];
                      if (event.target.checked) {
                        setSelectedInvoicingInfo(searchedInvoicingInfo);
                        setStringInvoicingInfo(
                          JSON.stringify(searchedInvoicingInfo)
                        );
                        searchedInvoicingInfo.forEach((): any => {
                          newIsInvoicingInfosChecked.push(true);
                        });
                      } else {
                        setSelectedInvoicingInfo([]);
                        setStringInvoicingInfo(JSON.stringify([]));
                        searchedInvoicingInfo.forEach((): any => {
                          newIsInvoicingInfosChecked.push(false);
                        });
                      }
                      setIsInvoicingInfosChecked(newIsInvoicingInfosChecked);
                      setSelectAllInvoicingInfo(event.target.checked);
                    }}
                    type="checkbox"
                    disabled={checkAllCheckboxDisabled()}
                  />
                  <i className={"form-icon " + styles.checkBox} />
                  <div className={"text-left " + styles.secondaryGrey}>
                    Sélectionner tous les centres
                  </div>
                </label>
                <button
                  className={`btn neuro-btn ml-1 ${styles.sendButton} ${styles.sendAllButton}`}
                  onClick={sendMultipleInvoicingInfo}
                  disabled={sendAllButtonDisabled}
                >
                  Faire un envoi groupé
                </button>
              </div>
            )}
            <div className={styles.horizontalLine} />
            {status === LoadingStatus.LOADING && (
              <div className="mt-4 mb-4">
                <Spinner size={"xl-spinner"} />
              </div>
            )}
            {searchedInvoicingInfo && status === LoadingStatus.SUCCESS && (
              <div className="row space-between-content">
                <div className={"text-left text-bold " + styles.resultNumber}>
                  {searchedInvoicingInfo.length === 0
                    ? "Aucun résultat"
                    : searchedInvoicingInfo.length +
                      " résultat" +
                      (searchedInvoicingInfo.length > 1 ? "s" : "")}
                </div>
                <Select
                  options={options}
                  value={selectedValue}
                  formatOptionLabel={formatOptionLabel}
                  onChange={filterByStatus}
                  isClearable={true}
                  className={styles.filterStatus}
                  styles={{
                    option: (provided, state): Record<string, any> => ({
                      ...provided,
                      backgroundColor: state.isFocused
                        ? "var(--secondary-blue)"
                        : "white",
                      ":hover": {
                        backgroundColor: "var(--secondary-blue)",
                      },
                      color: "var(--secondary-grey)",
                      whiteSpace: "pre-wrap",
                      textAlign: "right",
                    }),
                    valueContainer: (): Record<string, any> => ({
                      justifyContent: "right",
                      display: "flex",
                      flex: 1,
                      padding: "2px 8px",
                      position: "relative",
                    }),
                    singleValue: (): Record<string, any> => ({
                      color: "var(--secondary-grey)",
                      cursor: "default",
                    }),
                    dropdownIndicator: (): Record<string, any> => ({
                      color: "var(--primary-blue)",
                      alignItems: "baseline",
                      alignSelf: "center",
                      paddingTop: "5px",
                      cursor: "pointer",
                    }),
                    indicatorSeparator: (): Record<string, any> => ({
                      display: "none",
                    }),
                    clearIndicator: (): Record<string, any> => ({
                      display: "none",
                    }),
                    control: (): Record<string, any> => ({
                      border: "none",
                      display: "flex",
                    }),
                    input: (): Record<string, any> => ({
                      color: "transparent",
                    }),
                  }}
                />
              </div>
            )}
            {searchedInvoicingInfo &&
              status === LoadingStatus.SUCCESS &&
              searchedInvoicingInfo.map((invoicingInfo, index) => {
                if (isInvoicingInfosChecked[index] !== undefined) {
                  return (
                    <InvoiceInfoCard
                      key={invoicingInfo.facility.finess}
                      invoicingInfo={invoicingInfo}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                      isChecked={isInvoicingInfosChecked[index]}
                      addOrRemoveInvoiceToSelect={addOrRemoveInvoiceToSelect}
                      updateInvoicingEmail={updateInvoicingEmail}
                      updateSendedStatus={updateSendedStatus}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};
