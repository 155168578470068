import React, { useContext, useState } from "react";
import "./Account.css";
import Webcam from "react-webcam";
import { User } from "../../domain/User";
import { getProfile, updateCurrentUser } from "../../services/UserService";
import { errorToast } from "../../utils/Toast";
import { ActionTypes, AppContext } from "../../utils/state";

interface Props {
  next: () => any;
}

export const UserPicture: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [takenPicture, setTakenPicture] = useState<string>("");

  const webcamRef = React.useRef<any>(null);
  const capture = React.useCallback(() => {
    if (webcamRef !== null && webcamRef.current !== null) {
      setTakenPicture(webcamRef.current.getScreenshot());
    }
  }, [webcamRef]);

  const onSave = async (): Promise<void> => {
    const user: User = { ...appContext.state.user, selfie: takenPicture };
    const res = await updateCurrentUser(user);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'ajout d'une photo par l'utilisateur");
    } else {
      getProfile().then((updatedUser) => {
        if (!(updatedUser instanceof Error)) {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
          props.next();
        }
      });
    }
  };

  return (
    <div className={"user-picture-container"}>
      <div className={"user-picture-header"}>
        {takenPicture !== "" ? (
          <span
            className={"picture-icon trash"}
            onClick={(): any => setTakenPicture("")}
          />
        ) : (
          <span className={"picture-icon trash-disabled"} />
        )}
        <span className="title-account">Photo d&apos;identité</span>
        {takenPicture !== "" ? (
          <span className={"picture-icon camera-disabled"} />
        ) : (
          <span className={"picture-icon camera"} onClick={capture} />
        )}
      </div>
      <span className="text-primary-blue mb-2 text-centered">
        Il est indispensable que vous activiez votre caméra et que vous vous
        preniez en photo, muni de votre carte CPS pour vous inscrire
      </span>
      <div>
        {takenPicture === null && (
          <div className="text-centered w-100 error-msg camera-denied-access">
            Autorisez l&apos;accès à votre caméra afin de prendre la photo
          </div>
        )}
        {takenPicture === "" && (
          <Webcam width={"100%"} ref={webcamRef} audio={false} />
        )}
        {takenPicture && takenPicture !== "" && (
          <img className="w-100" src={takenPicture} alt={"takenPicture"} />
        )}
      </div>
      <span className="text-centered w-70">
        Veuillez prendre une photo de face avec votre carte professionnelle
        visible
      </span>
      <div className="user-picture-info">
        <div className="photo-example-container error-msg">
          <span className="example-picture wrong-picture" />
          <span className="result-icon error-icon" />
          Non
        </div>
        <div className="photo-example-container" style={{ color: "green" }}>
          <span className="example-picture correct-picture" />
          <span className="result-icon correct-icon" />
          Oui
        </div>
      </div>
      <button
        onClick={onSave}
        disabled={takenPicture === "" || !takenPicture}
        className="btn btn-block neuro-btn mr-2"
      >
        Valider la photo
      </button>
    </div>
  );
};
