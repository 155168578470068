import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/state";
import styles from "./AdminDashboard.module.css";
import dashboardStyles from "../Dashboards.module.css";
import { PageHeaderWithTitle } from "../../Shared/PageHeaderWithTitle";
import { formatDate, today } from "../../../utils/time";
import { OpinionRequestCategoryCard } from "../DispatcherDashboard/OpinionRequestCategoryCard";
import { hasRole } from "../../../utils/authorization";
import { UserRoles } from "../../../domain/UserRoles";
import history from "../../../utils/history";
import { getAdminStatistics } from "../../../services/StatisticsService";
import { errorToast } from "../../../utils/Toast";
import { AdminStatistics } from "../../../domain/AdminStatistics";

export const AdminDashboard: React.FC = () => {
  const appContext = useContext(AppContext);
  const [showMoreIndex, setShowMoreshowMoreIndex] = useState<number>(0);
  const defaultStatistics: AdminStatistics = {
    draftRequests: [],
    toValidateRequests: [],
    ongoingRequests: [],
    closedRequests: [],
  };
  const [statistics, setStatistics] = useState<AdminStatistics>(
    defaultStatistics
  );

  const showMoreDetails = (index: number): void => {
    setShowMoreshowMoreIndex(index);
  };

  useEffect(() => {
    if (
      appContext.state.user.id === "" ||
      !hasRole(appContext.state.user.roles, UserRoles.ADMIN)
    ) {
      history.push("/forbidden");
    } else {
      getStats();
    }
  }, []);

  const getStats = (): void => {
    getAdminStatistics().then((res: AdminStatistics | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur lors de la récupération des informations");
        setStatistics(defaultStatistics);
      } else {
        setStatistics(res);
      }
    });
  };

  const detailsCardText = (): JSX.Element => {
    switch (showMoreIndex) {
      case 1:
        return (
          <>
            <span className="row text-bold">Demandes brouillon</span>
            <ul className="mb-0">
              {statistics.draftRequests.length > 0 &&
                statistics.draftRequests.map((r, index) => {
                  return (
                    <li key={index}>
                      Créée par{" "}
                      <a href={`admin/users/${r.requesterId}/view`}>
                        {r.requesterName}
                      </a>{" "}
                      le {formatDate(r.creationDate)}
                    </li>
                  );
                })}
            </ul>
          </>
        );
      case 2:
        return (
          <>
            <span className="text-bold">Demandes en attente de validation</span>
            <ul className="mb-0">
              {statistics.toValidateRequests.length > 0 &&
                statistics.toValidateRequests.map((r, index) => {
                  return (
                    <li key={index}>
                      Soumise par{" "}
                      <a href={`admin/users/${r.requesterId}/view`}>
                        {r.requesterName}
                      </a>{" "}
                      le {r.submitDate && formatDate(r.submitDate)}
                    </li>
                  );
                })}
            </ul>
          </>
        );
      case 3:
        return (
          <>
            <span className="text-bold">
              Demandes en attente d&apos;assignation
            </span>
            <ul className="mb-0">
              {statistics.ongoingRequests.length > 0 &&
                statistics.ongoingRequests.map((r, index) => {
                  return (
                    <li key={index}>
                      Soumise par{" "}
                      <a href={`admin/users/${r.requesterId}/view`}>
                        {r.requesterName}
                      </a>{" "}
                      - dernière mise à jour le{" "}
                      {r.lastUpdateDate && formatDate(r.lastUpdateDate)}
                    </li>
                  );
                })}
            </ul>
          </>
        );
      case 4:
        return (
          <>
            <span className="text-bold">Demandes terminées</span>
            <ul className="mb-0">
              {statistics.closedRequests.length > 0 &&
                statistics.closedRequests.map((r, index) => {
                  return (
                    <li key={index}>
                      Créée par{" "}
                      <a href={`admin/users/${r.requesterId}/view`}>
                        {r.requesterName}
                      </a>{" "}
                      - clôturée le {r.closedDate && formatDate(r.closedDate)}
                    </li>
                  );
                })}
            </ul>
          </>
        );
      default:
        return <></>;
    }
  };

  const getSubmitPercentage = (): string => {
    const total =
      statistics.draftRequests.length +
      statistics.toValidateRequests.length +
      statistics.ongoingRequests.length +
      statistics.closedRequests.length;
    const submittedRequests = total - statistics.draftRequests.length;
    if (submittedRequests === 0) {
      return "0";
    }
    return ((submittedRequests / total) * 100).toFixed(0);
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Administration"}
        backButtonRedirectsTo={`/`}
      />
      <div className="page-container">
        <div className="col-10 container-70 col-sm-auto col-mx-auto">
          <div
            className={`flex-row space-between-content ${dashboardStyles.mobileFlexCol}`}
          >
            <div className={`primary-blue-card ${styles.card}`}>
              <div className={"margin-bottom-2 text-medium"}>{today()}</div>
              <span className={dashboardStyles.bigText}>
                Bonjour
                <span className={styles.underline}>
                  {appContext.state.user.firstName}{" "}
                  {appContext.state.user.lastName}
                </span>
                !
              </span>
              <div className="margin-top-2">
                &Agrave; ce jour, {getSubmitPercentage()}% des brouillons ont
                donné lieu à une demande d&apos;avis.
              </div>
            </div>
            <div
              className={`white-card w-30 aligned-centered ${styles.adminCard} ${dashboardStyles.cardsMargin}`}
            >
              <div className="row flex-col centered-content">
                <button
                  id="go-to-requests"
                  className="btn secondary-btn"
                  onClick={(): void => history.push("/admin/users/waiting")}
                >
                  Gérer les utilisateurs
                </button>
                <button
                  id="go-to-requests"
                  className="btn secondary-btn mt-4"
                  onClick={(): void => history.push("/admin/organizations")}
                >
                  Gérer les organisations
                </button>
              </div>
            </div>
          </div>
          <div className={styles.adminGridContainer}>
            <OpinionRequestCategoryCard
              iconName={"WaitingIcon"}
              requestNumber={statistics.draftRequests.length}
              seeMore={(): any => showMoreDetails(1)}
            >
              Demandes brouillon
            </OpinionRequestCategoryCard>
            <OpinionRequestCategoryCard
              iconName={"ToValidateRequestsIcon"}
              requestNumber={statistics.toValidateRequests.length}
              seeMore={(): any => showMoreDetails(2)}
            >
              Demandes en attente de validation
            </OpinionRequestCategoryCard>
            <OpinionRequestCategoryCard
              iconName={"OngoingRequestsIcon"}
              requestNumber={statistics.ongoingRequests.length}
              seeMore={(): any => showMoreDetails(3)}
            >
              Demandes en cours de traitement
            </OpinionRequestCategoryCard>
            <OpinionRequestCategoryCard
              iconName={"ClosedRequestsIcon"}
              requestNumber={statistics.closedRequests.length}
              seeMore={(): any => showMoreDetails(4)}
            >
              Demandes terminées
            </OpinionRequestCategoryCard>
          </div>
          {showMoreIndex !== 0 && (
            <div
              id="show-more-card"
              className={`text-left margin-top-2 primary-blue-card`}
            >
              <button
                id="close-details-card-btn"
                className={styles.closeRequestDetailsButton}
                onClick={(): void => setShowMoreshowMoreIndex(0)}
              >
                ×
              </button>
              {detailsCardText()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
