import React from "react";
import { getMinimizedTitleLabel, User } from "../../../domain/User";
import stylesParticipant from "./BadgeParticipantUser.module.css";
import styles from "../../Shared/UserChip.module.css";
import { Figure } from "../../Shared/Figure";

interface Props {
  user: User;
  permission: string;
}

export const BadgeUserParticipant: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={`row space-between-content ml-4 mr-4 ${stylesParticipant.badgeParticipant}`}
    >
      <div
        className={`text-left flex-row text-dark-grey ${stylesParticipant.userInfo}`}
      >
        <div className={styles.figure}>
          <Figure
            firstName={props.user.firstName}
            lastName={props.user.lastName}
            fontSize={"text-xsmall"}
          />
        </div>
        <div>
          <div>
            <div className="ml-2">
              {getMinimizedTitleLabel(props.user.professionalTitle)}{" "}
              {props.user.firstName} {props.user.lastName} (vous)
            </div>
          </div>
          <div className={stylesParticipant.userEmail}>
            <div className="ml-2">{props.user.email}</div>
          </div>
        </div>
      </div>
      <div className="text-primary-blue">{props.permission}</div>
    </div>
  );
};
