export interface DataImport {
  importName: string;
  lastUpdateStart: string;
  lastUpdateEnd: string;
  status: ImportStatus;
}

export enum ImportStatus {
  UNKNOWN = "UNKNOWN",
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
}

export function importStatusLabel(dataImportStatus: ImportStatus): string {
  switch (dataImportStatus) {
    case ImportStatus.FAIL:
      return "Echec";
    case ImportStatus.SUCCESS:
      return "Succès";
    case ImportStatus.LOADING:
      return "En cours de chargement...";
    default:
      return "Inconnu";
  }
}
