import React, { useContext } from "react";
import { AppContext } from "../../../utils/state";
import styles from "./DispatcherDashboard.module.css";
import dashboardStyles from "../Dashboards.module.css";
import { OpinionRequestCategoryCard } from "./OpinionRequestCategoryCard";
import { today } from "../../../utils/time";
import { getMinimizedTitleLabel } from "../../../domain/User";

interface Props {
  allRequestsNumber: number;
  emergencyRequestsNumber: number;
  alertRequestsNumber: number;
  goToDetails: (filter: string) => any;
}

export const InBriefTab: React.FC<Props> = (props: Props) => {
  const {
    allRequestsNumber,
    emergencyRequestsNumber,
    alertRequestsNumber,
    goToDetails,
  } = props;
  const appContext = useContext(AppContext);

  return (
    <div className="bg-light-blue-1 flex-1">
      <div
        className={`col-10 col-sm-auto col-mx-auto container-70 margin-top-2 ${styles.container}`}
      >
        <div
          className={`row ${styles.rowGravityTop} ${styles.inBriefGridContainer}`}
        >
          <div className="text-left primary-blue-card">
            <div className={"margin-bottom-2 text-medium"}>{today()}</div>
            <div className={`margin-bottom-2 ${dashboardStyles.bigText}`}>
              Bonjour{" "}
              <span className={styles.underline}>
                {appContext.state.user.professionalTitle &&
                  getMinimizedTitleLabel(
                    appContext.state.user.professionalTitle
                  ) + " "}
                {appContext.state.user.firstName}{" "}
                {appContext.state.user.lastName}
              </span>{" "}
              ! <br />
            </div>
            {allRequestsNumber > 0 ? (
              <>
                Vous avez des demandes en attente d&apos;assignation. <br />
                N&apos;oubliez pas de les examiner afin d&apos;indiquer
                l&apos;expert le plus apte à y répondre.
              </>
            ) : (
              <>
                Vous n&apos;avez aucune demande en attente d&apos;assignation.
              </>
            )}
          </div>
          <div className={styles.categories}>
            <OpinionRequestCategoryCard
              iconName={"WaitingIcon"}
              requestNumber={allRequestsNumber}
              goToDetails={(): any => goToDetails("ALL")}
            >
              Demandes en attente <br />
              d&apos;assignation
            </OpinionRequestCategoryCard>
            <OpinionRequestCategoryCard
              iconName={"EmergencyIcon"}
              requestNumber={emergencyRequestsNumber}
              goToDetails={(): any => goToDetails("EMERGENCY")}
            >
              Demandes <br />
              urgentes
            </OpinionRequestCategoryCard>
            <OpinionRequestCategoryCard
              iconName={"AlertIcon"}
              requestNumber={alertRequestsNumber}
              goToDetails={(): any => goToDetails("ALERT")}
            >
              Demandes <br />
              en alerte
            </OpinionRequestCategoryCard>
          </div>
        </div>
      </div>
    </div>
  );
};
