import React, { useEffect, useReducer, useState } from "react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import "./App.css";
import { initLogin, initRegister } from "./utils/auth";
import { Disconnected } from "./components/Disconnected";
import history from "./utils/history";
import { Requests } from "./components/RequestsList/Requests";
import { FormOpinionRequest } from "./components/OpinionRequestForm/FormOpinionRequest";
// React Toastify
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import { getProfile } from "./services/UserService";
import { Navbar } from "./components/Navbar";
import {
  ActionTypes,
  AppContext,
  AppContextReducer,
  AppInitialState,
} from "./utils/state";
import { ImportPage } from "./components/ImportPage";
import { Components } from "./components/Components";
import { Admin } from "./components/Admin/Admin";
import { AdminViewProfile } from "./components/Admin/AdminViewProfile";
import { AdminUpdateProfile } from "./components/Admin/AdminUpdateProfile";
import { Register } from "./components/Account/Register";
import { PhoneNumberConfirmation } from "./components/Account/PhoneNumberConfirmation";
import { Forbidden } from "./components/Forbidden";
import { AboutUs } from "./components/Account/AboutUs";
import { LoadingStatus } from "./utils/LoadingStatus";
import Deleted from "./components/Deleted";
import { CondensedOpinionRequestContainer } from "./components/Request/CondensedOpinionRequestContainer";
import { Profile } from "./components/Profile/Profile";
import { ProfilePersonalDetails } from "./components/Profile/ProfilePersonalDetails";
import { ProfileProfessionalDetails } from "./components/Profile/ProfileProfessionalDetails";
import { ProfileAvailability } from "./components/Profile/Availability/ProfileAvailability";
import { ProfileFacilitiesDetails } from "./components/Profile/Facility/ProfileFacilitiesDetails";
import { HomeInvoicing } from "./components/Invoicing/HomeInvoicing";
import { ProfilePreferences } from "./components/Profile/ProfilePreferences";
import { printServerVersion } from "./services/ApiClient";
import config from "./utils/config";
import { Brands, getWebsiteTitle } from "./utils/Brands";
import { Helmet } from "react-helmet";
import { ContactPage } from "./components/ContactPage";
import { GeneralTermsOfUse } from "./components/LegalInformation/GeneralTermsOfUse";
import { UserGuidesIndex } from "./components/UserGuides/UserGuidesIndex";
import { RequesterGuide } from "./components/UserGuides/RequesterGuide";
import { ExpertGuide } from "./components/UserGuides/ExpertGuide";
import { DispatcherGuide } from "./components/UserGuides/DispatcherGuide";
import { AdminUpdateOrganization } from "./components/Admin/AdminUpdateOrganization";
import { PersonalDataProtection } from "./components/LegalInformation/PersonalDataProtection";
import { CreateUser } from "./components/Admin/CreateUser";
import { GeneralTermsOfUseModal } from "./components/LegalInformation/GeneralTermsOfUseModal";
import { Home } from "./components/Home";
import { AdminDashboard } from "./components/Dashboards/AdminDashboard/AdminDashboard";
import { RegistrationGuide } from "./components/UserGuides/RegistrationGuide";
import { hasStatus } from "./utils/authorization";
import { UserStatus } from "./domain/UserStatus";
import { AdminViewOrganizations } from "./components/Admin/AdminViewOrganizations";
import { AdminUpdateOrganizationDetail } from "./components/Admin/AdminUpdateOrganizationDetail";

const DefaultRouter = withRouter((props: any): any => {
  const getLogoUri = (): string => {
    switch (config.brand) {
      case Brands.HANDI_NEURO:
        return `./assets/logo/logo-handineuro.svg`;
      case Brands.TELE_GHU_PARIS:
        return `./assets/logo/logo-teleghuparis.svg`;
      default:
        return `./assets/logo/logo-dopenclinics.png`;
    }
  };

  return (
    <>
      <Helmet>
        <title>{getWebsiteTitle(config.brand)}</title>
        <meta property="og:url" content={config.apiUrl} />
        <meta property="og:image" content={getLogoUri()} />
      </Helmet>
      {props.location.pathname !== "/home" && <Navbar />}
      <Switch>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route path="/profile/availability">
          <ProfileAvailability />
        </Route>
        <Route path="/invoicing">
          <HomeInvoicing />
        </Route>
        <Route path="/profile/professional">
          <ProfileProfessionalDetails />
        </Route>
        <Route path="/profile/personal">
          <ProfilePersonalDetails />
        </Route>
        <Route path="/profile/preferences">
          <ProfilePreferences />
        </Route>
        <Route path="/profile/facilities">
          <ProfileFacilitiesDetails />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/requests/create">
          <FormOpinionRequest />
        </Route>
        <Route path="/requests/:id/view">
          <CondensedOpinionRequestContainer />
        </Route>
        <Route path="/requests/:id">
          <FormOpinionRequest />
        </Route>
        <Route path="/requests">
          <Requests />
        </Route>
        <Route path="/components">
          <Components />
        </Route>
        <Route path="/import">
          <ImportPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/general-terms-of-use">
          <GeneralTermsOfUse />
        </Route>
        <Route path="/personal-data-protection">
          <PersonalDataProtection />
        </Route>
        <Route path="/help">
          <UserGuidesIndex />
        </Route>
        <Route path="/register-guide">
          <RegistrationGuide />
        </Route>
        <Route path="/requester-guide">
          <RequesterGuide />
        </Route>
        <Route path="/dispatcher-guide">
          <DispatcherGuide />
        </Route>
        <Route path="/expert-guide">
          <ExpertGuide />
        </Route>
        <Route path="/admin/users/:id/view">
          <AdminViewProfile />
        </Route>
        <Route path="/admin/users/:id/edit">
          <AdminUpdateProfile />
        </Route>
        <Route path="/admin/organizations/:id/view">
          <AdminViewOrganizations />
        </Route>
        <Route path="/admin/organizations/:id/edit">
          <AdminUpdateOrganization />
        </Route>
        <Route path="/admin/organization-details/:id/edit">
          <AdminUpdateOrganizationDetail />
        </Route>
        <Route path="/admin">
          <Admin user={props.state.user} />
        </Route>
        <Route path="/create-user">
          <CreateUser />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/2fa">
          <PhoneNumberConfirmation />
        </Route>
        <Route path="/terms-of-use-agreement">
          <GeneralTermsOfUseModal showCheckbox={true} />
        </Route>
        <Route path="/forbidden">
          <Forbidden />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/home">
          <Disconnected />
        </Route>
        <Route path="/account-disabled">
          <Deleted />
        </Route>
        <Route path="/admin-dashboard">
          <AdminDashboard />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        limit={1}
      />
    </>
  );
});

function App(): any {
  const [state, dispatch] = useReducer(AppContextReducer, AppInitialState);
  const [loadingStatus, setStatus] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  );

  useEffect(() => {
    setStatus(LoadingStatus.LOADING);
    printServerVersion();
    // Bypass the /me call to avoid fetching the user because he is not connected
    if (
      window.location.href.includes("/account-disabled") ||
      window.location.href.includes("/register-guide") ||
      window.location.href.includes("/contact") ||
      window.location.href.includes("/general-terms-of-use")
    ) {
      setStatus(LoadingStatus.SUCCESS);
      return;
    }
    getProfile().then((res) => {
      if (res instanceof Error) {
        setStatus(LoadingStatus.ERROR);
        dispatch({
          type: ActionTypes.UserDisconnected,
        });
      } else {
        res.roles = res.roles || [];
        dispatch({
          type: ActionTypes.UserConnected,
          payload: res,
        });
        setStatus(LoadingStatus.SUCCESS);
        if (hasStatus(res.status, UserStatus.CREATED)) {
          history.push("/register");
        }
      }
    });
  }, []);

  if (
    loadingStatus === LoadingStatus.LOADING ||
    loadingStatus === LoadingStatus.IDLE
  ) {
    return <div />;
  }
  return (
    <div className="App">
      <AppContext.Provider value={{ state, dispatch }}>
        <Router history={history}>
          <DefaultRouter state={state} />
        </Router>
      </AppContext.Provider>
    </div>
  );
}

function Login(): any {
  useEffect(() => {
    initLogin(window.location.origin);
  }, []);

  return <></>;
}

function SignUp(): any {
  initRegister();
  return <></>;
}

export default App;
