enum SubSpecialtyEnum {
  NEURO_DISEASES = "NEURO_DISEASES",
  NEURO_SYMPTOMS = "NEURO_SYMPTOMS",
  MPR_DISEASES = "MPR_DISEASES",
  MPR_SYMPTOMS = "MPR_SYMPTOMS",
}

export const subSpecialtyTranslate: Record<SubSpecialtyEnum, string> = {
  NEURO_DISEASES: "Maladies neuro",
  NEURO_SYMPTOMS: "Symptômes neuro",
  MPR_DISEASES: "Maladies MPR",
  MPR_SYMPTOMS: "Symptômes MPR",
};

export const subSpecialtyStyleClass: Record<SubSpecialtyEnum, string> = {
  NEURO_DISEASES: "neuroLabel",
  NEURO_SYMPTOMS: "neuroLabel",
  MPR_DISEASES: "mprLabel",
  MPR_SYMPTOMS: "mprLabel",
};

export default SubSpecialtyEnum;
