import React, { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../utils/Toast";
import {
  getRolesLabel,
  Participant,
  SharePermission,
} from "../../../domain/OpinionRequest";
import "./EditParticipant.css";
import { UserChip } from "../../Shared/UserChip";
import {
  HealthProfessionalTitle,
  CivilTitle,
  User,
} from "../../../domain/User";
import { getUser } from "../../../services/UserService";
import { LoadingStatus } from "../../../utils/LoadingStatus";
import { UserStatus } from "../../../domain/UserStatus";
import Modal from "../../Modal";
import { updateParticipant } from "../../../services/OpinionRequestService";

interface Props {
  requestId: string;
  participant: Participant;
  onClose: () => void;
  onEdit: (p: Participant) => void;
  onDelete: (p: Participant) => void;
  hasPermissionEdit: boolean;
}

export const EditParticipant: React.FC<Props> = (props: Props) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.LOADING
  );
  const [user, setUser] = useState<User>({
    id: "",
    email: "",
    professionalTitle: HealthProfessionalTitle.NONE,
    civilTitle: CivilTitle.NONE,
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    specialty: "",
    status: UserStatus.DELETED,
    organizations: [],
  });
  const [selectedPermission, setSelectedPermission] = useState<SharePermission>(
    props.participant.permission ?? SharePermission.VIEW
  );
  const options = [
    { value: SharePermission.VIEW, label: "Lecture" },
    { value: SharePermission.EDIT, label: "Edition" },
    { value: SharePermission.ADMIN, label: "Admin" },
  ];

  useEffect(() => {
    getUser(props.participant.userId).then((res: User | Error) => {
      if (res instanceof Error) {
        setLoadingStatus(LoadingStatus.ERROR);
      } else {
        setUser(res);
        setLoadingStatus(LoadingStatus.SUCCESS);
      }
    });
  }, [props.participant.userId]);

  const editParticipant = async (event: any): Promise<void> => {
    event.preventDefault();
    if (!props.participant.userId) {
      return;
    }
    const res = await updateParticipant(
      props.requestId,
      props.participant.userId,
      { userId: props.participant.userId, permission: selectedPermission }
    );
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition du participant");
    } else {
      successToast("Participant édité");
      if (props.participant.userId) {
        props.onEdit({
          permission: selectedPermission,
          userId: props.participant.userId,
          lastViewedDate: props.participant.lastViewedDate,
        });
      }
      props.onClose();
    }
  };

  const onSelectSharPermission = (selectedValue: any): void => {
    setSelectedPermission(selectedValue.target.value);
  };

  return (
    <>
      {loadingStatus === LoadingStatus.SUCCESS && (
        <Modal
          show
          onClose={props.onClose}
          title={"Éditer un participant"}
          footer={
            <div className={"ml-4 mr-4 text-centered"}>
              <button
                className="btn rectangular-btn neuro-btn"
                onClick={editParticipant}
              >
                Valider les modifications
              </button>
            </div>
          }
          containerStyle={{ height: "35vh" }}
        >
          <div className="content">
            <div>
              <div className="modal-label">Participant</div>
              <div
                className="row mb-2 mt-2 space-between-content"
                key={props.participant.userId}
              >
                <UserChip
                  professionalTitle={user.professionalTitle}
                  firstName={user.firstName}
                  lastName={user.lastName}
                />
                <select
                  className="permission-select"
                  onChange={onSelectSharPermission}
                  defaultValue={selectedPermission}
                  disabled={props.hasPermissionEdit}
                >
                  {options.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              {user.roles && (
                <div className="text-dark-grey caption-edit-participant">
                  Rôle : {getRolesLabel(user.roles)}
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
