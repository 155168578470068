import { toast, ToastOptions } from "react-toastify";

const defaultOptions: ToastOptions = {
  className: "text-centered",
};

export function successToast(message: string, options?: ToastOptions): void {
  toast.success(message, { ...defaultOptions, ...options });
}

export function infoToast(message: string, options?: ToastOptions): void {
  toast.info(message, { ...defaultOptions, ...options });
}

export function errorToast(message: string, options?: ToastOptions): void {
  toast.clearWaitingQueue();
  toast.error(message, { ...defaultOptions, ...options });
}
