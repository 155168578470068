import React, { useContext, useState } from "react";
import "./Admin.css";
import { User } from "../../domain/User";
import { AdminUserTab } from "./AdminUserTab";
import { AdminOrganizationTab } from "./AdminOrganizationTab";
import { BackButton } from "../Shared/BackButton";
import history from "../../utils/history";
import { AppContext } from "../../utils/state";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

interface Props {
  user: User;
}

enum AdminTab {
  USERS = "users",
  ORGANIZATIONS = "organizations",
}

export const Admin: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState<AdminTab>(
    history.location.pathname.split("/").pop() === AdminTab.ORGANIZATIONS
      ? AdminTab.ORGANIZATIONS
      : AdminTab.USERS
  );

  return (
    <>
      <div>
        {appContext.state.user.roles.includes("admin") ? (
          <BackButton />
        ) : (
          <PageHeaderWithTitle title={"Organisations"} />
        )}
      </div>
      <div className="row space-content">
        {appContext.state.user.roles.includes("admin") && (
          <button
            className={
              "request-menu " +
              (selectedTab === AdminTab.USERS ? "request-menu-active" : "")
            }
            onClick={(): any => {
              setSelectedTab(AdminTab.USERS);
              history.push("/admin/users/waiting");
            }}
          >
            Utilisateurs
          </button>
        )}
        {appContext.state.user.roles.includes("admin") && (
          <button
            className={
              "request-menu " +
              (selectedTab === AdminTab.ORGANIZATIONS
                ? "request-menu-active"
                : "")
            }
            onClick={(): any => {
              setSelectedTab(AdminTab.ORGANIZATIONS);
              history.push("/admin/organizations");
            }}
          >
            Organisations
          </button>
        )}
      </div>
      {selectedTab === AdminTab.USERS && (
        <div className="bg-light-blue-1 flex-1">
          <AdminUserTab user={props.user} />
        </div>
      )}
      {selectedTab === AdminTab.ORGANIZATIONS && (
        <div className="bg-light-blue-1 flex-1">
          <AdminOrganizationTab />
        </div>
      )}
    </>
  );
};
