import React from "react";

export const WarnningIcon: React.FC = () => {
  return (
    <div className="icon-warning icon-small">
      <svg width="19px" height="17px" viewBox="0 0 19 17" version="1.1">
        <title>Shape</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="sign"
            transform="translate(-11.000000, -24.000000)"
            fillRule="nonzero"
          >
            <path
              d="M28.995293,36.305738 L22.3620792,25.1994059 C21.9224137,24.4538862 21.1386622,24.0142207 20.2593312,24.0142207 C19.3800003,24.0142207 18.5962488,24.4538862 18.1565833,25.1994059 L11.5233695,36.305738 C11.0645881,37.0703736 11.0645881,37.9879364 11.5042536,38.7716878 C11.9439191,39.5554393 12.7467864,40.0142207 13.6261174,40.0142207 L26.8925451,40.0142207 C27.7909919,40.0142207 28.5747434,39.5554393 29.0144089,38.7716878 C29.4540744,37.9879364 29.4540744,37.0703736 28.995293,36.305738 Z M20.2593312,37.4144597 C19.6858545,37.4144597 19.2270732,36.9556783 19.2270732,36.3822016 C19.2270732,35.8087249 19.6858545,35.3499435 20.2593312,35.3499435 C20.832808,35.3499435 21.2915893,35.8087249 21.2915893,36.3822016 C21.2915893,36.9556783 20.832808,37.4144597 20.2593312,37.4144597 Z M21.3680529,29.5578288 L21.004851,33.8206723 C20.9666192,34.222106 20.6034173,34.5279603 20.2019836,34.4897285 C19.8387817,34.4514967 19.5520433,34.1647583 19.5329274,33.8206723 L19.1697255,29.5578288 C19.1123778,28.9461203 19.5711592,28.4108754 20.1828677,28.3535278 C20.7945762,28.2961801 21.3298211,28.7549614 21.3871688,29.3666699 C21.3680529,29.4240176 21.3680529,29.5004812 21.3680529,29.5578288 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
