import React from "react";
import styles from "./Deleted.module.css";
import { forceLogout } from "../utils/auth";

const Deleted: React.FC = () => (
  <div className={styles.deletedContainer}>
    <h2 className={styles.deletedTitle}>Votre compte a été supprimé</h2>
    <div className={styles.deletedTitle}>
      Veuillez contacter le support en utilisant l&apos;adresse suivante:{" "}
      <b>support@aiintense.eu</b>
    </div>
    <div className={"row " + styles.button}>
      <button
        type="submit"
        className="btn rectangular-btn neuro-btn mt-2 "
        onClick={forceLogout}
      >
        Se déconnecter
      </button>
    </div>
  </div>
);

export default Deleted;
