import React, { useContext, useEffect, useState } from "react";
import OpinionRequest, {
  canUserEditOpinionRequest,
} from "../../domain/OpinionRequest";
import history from "../../utils/history";
import EditIcon from "../../assets/edit-icon.svg";
import { RequestStatus } from "../../domain/RequestStatus";
import { AppContext } from "../../utils/state";
import requestPrimaryTypeEnum from "../../domain/Subject/RequestPrimaryType.enum";

interface Props {
  request: OpinionRequest;
  cardTitle: string;
  pageNumber?: number;
  children: any;
}

export const DetailsRequestCard: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [disabledEditBtn, setDisabledEditBtn] = useState<boolean>(true);

  useEffect(() => {
    setDisabledEditBtn(
      !(
        props.request?.status !== RequestStatus.CLOSED &&
        props.request?.status !== RequestStatus.VALIDATED &&
        canUserEditOpinionRequest(appContext.state.user, props.request)
      )
    );
  }, [props.request, appContext.state.user]);

  return (
    <div className="field-detail-card flex-col">
      <div className="title-and-edit-btn-container">
        <span className="condensed-title">{props.cardTitle}</span>
        {props.pageNumber && (
          <button
            className="edit-detail-btn mb-2"
            onClick={(): void => {
              if (
                props.request?.subject?.requestType ===
                requestPrimaryTypeEnum.EEG_INTERPRETATION_ONLY
              ) {
                history.push(`/requests/${props.request.id}?page=3`);
              } else {
                history.push(
                  `/requests/${props.request.id}?page=${props.pageNumber}`
                );
              }
            }}
            disabled={disabledEditBtn}
          >
            <img
              className="icon-disabled mobile-view-icon"
              alt="icon-edit"
              src={EditIcon}
            />
          </button>
        )}
      </div>
      {props.children}
    </div>
  );
};
