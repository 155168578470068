import React from "react";
import history from "../../utils/history";
import "./Account.css";
import styles from "./Done.module.css";

export const Done: React.FC<any> = () => {
  const goToAboutUs = (): any => {
    history.push("/about-us");
  };

  return (
    <>
      <div className={styles.validationContainer}>
        <div className={`row ${styles.validationRow}`}>
          <div className={styles.validationIcons}>
            <i className="icon icon-check" />
          </div>
        </div>
        <h2 className={`form-title text-center ${styles.marginTop15}`}>
          Merci pour votre inscription.
        </h2>
        <div className="text-centered margin-top-2">
          Vous recevrez un email lorsque votre compte sera validé par un de nos
          collaborateurs
        </div>
        <div className="tile-subtitle text-muted text-center mt-2">
          Le délai de traitement est généralement de 24 heures.
        </div>
        <div className={`row centered-content ${styles.marginTop25}`}>
          <button
            className="btn btn-block neuro-btn w-70"
            onClick={goToAboutUs}
          >
            Aller sur la page d’accueil
          </button>
        </div>
      </div>
    </>
  );
};
