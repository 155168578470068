import config from "../utils/config";
import history from "../utils/history";
import { errorToast } from "../utils/Toast";
import { ErrorResponse } from "../domain/ErrorManagement";
import { sendErrorMessage } from "./ErrorService";

const apiUrl = config.apiUrl;

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

/**
 * Sends an Http request, and checks for Errors in the response.
 * See doc/adr.md for explanations about error handling and return type.
 */
export async function send<T>(
  endpoint: string,
  method: HttpMethod,
  body?: any,
  customConfig?: any
): Promise<T | Error | ErrorResponse> {
  const requestConfig = {
    method: method,
    credentials: "include",
    headers: {
      ...customConfig?.headers,
    },
    ...customConfig,
  };
  if (body) {
    requestConfig.headers["Content-Type"] = "application/json";
    requestConfig.body = JSON.stringify(body);
  }
  try {
    const response = await fetch(`${apiUrl}${endpoint}`, requestConfig).catch(
      (err) => {
        errorToast(
          "Web service inaccessible, vérifier votre connexion ou réessayer dans quelques minutes",
          { autoClose: 10000 }
        );
        return err;
      }
    );
    if (response.ok) {
      return response.json();
    }
    const errResponse = await response.json();
    if (response.status === 401) {
      if (errResponse?.code === "DOUBLE_AUTHENT_REQUIRED") {
        history.push("/2fa");
        return new Error(errResponse?.message);
      } else if (errResponse?.code === "TOU_AGREEMENT_REQUIRED") {
        // TOU stands for Terms Of Use
        history.push("/terms-of-use-agreement");
        return new Error(errResponse?.message);
      } else if (errResponse?.message === "User deleted") {
        history.push("/account-disabled");
      } else if (!window.location.href.includes("/invite")) {
        history.push("/home");
      }
    }
    // Do not send error message on 401 errors (this may start an infinite loop...)
    sendErrorMessage(errResponse);
    if (response.status === 403) {
      if (errResponse?.message === "User is not validated") {
        return new Error(errResponse?.message);
      } else {
        history.push("/forbidden");
      }
    }
    if (response.status === 400 || response.status === 409) {
      if (errResponse.payload) {
        return new ErrorResponse(
          errResponse.code,
          errResponse.message,
          errResponse.payload
        );
      }
      return new ErrorResponse(errResponse.code, errResponse.message);
    }
    if (response.status === 405) {
      return new Error(errResponse.message);
    }
    console.error("Error response during fetch", response);
    return new Error("Failed to fetch, see console logs");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function get(
  endpoint: string,
  requestConfig?: any
): Promise<any | Error | ErrorResponse> {
  return send(endpoint, "GET", null, requestConfig);
}

export async function printServerVersion(): Promise<void> {
  try {
    const response = await fetch(`${apiUrl}/health`),
      healthResponse = await response.json();
    const version = healthResponse.version;
    console.info("Server version: " + version);
    if (config.version !== version) {
      if (config.environment === "prod") {
        console.error(
          "Wrong version numbers : backend may not have been deployed successfully. \n Please retry later or fix server deploy :)"
        );
      } else {
        errorToast(
          "Wrong version numbers : backend may not have been deployed successfully. \n Please retry later or fix server deploy :)",
          { type: "dark", autoClose: 2000, position: "bottom-right" }
        );
      }
    }
  } catch (error) {
    console.error(
      "Error while getting backend version, please check server status",
      error
    );
  }
}
