import React, { useEffect, useState } from "react";
import {
  importHealthProfessionals,
  importFacilities,
  getLastImport,
} from "../services/DirectoryService";
import styles from "./ImportPage.module.css";
import { checkHasExpired, formatDate } from "../utils/time";
import {
  DataImport,
  ImportStatus,
  importStatusLabel,
} from "../domain/DataImport";
import { PageHeaderWithTitle } from "./Shared/PageHeaderWithTitle";
import { ErrorResponse, ErrorType } from "../domain/ErrorManagement";
import { errorToast } from "../utils/Toast";

export const ImportPage: React.FC = () => {
  const [lastFacilityImport, setLastFacilityImport] = useState<DataImport>();
  const [
    lastHealthProfessionalImport,
    setLastHealthProfessionalImport,
  ] = useState<DataImport>();
  const [importDirectoryStatus, setImportDirectoryStatus] = useState<
    ImportStatus
  >(ImportStatus.UNKNOWN);
  const [importFacilitiesStatus, setImportFacilitiesStatus] = useState<
    ImportStatus
  >(ImportStatus.UNKNOWN);

  const startDirectoryImport = async (): Promise<any> => {
    const res = await importHealthProfessionals();
    if (res instanceof Error) {
      setImportDirectoryStatus(ImportStatus.FAIL);
    } else {
      setImportDirectoryStatus(ImportStatus.LOADING);
    }
    await lastImport("HEALTH_PROFESSIONALS");
  };

  const startFacilitiesImport = async (): Promise<any> => {
    setImportFacilitiesStatus(ImportStatus.LOADING);
    const res = await importFacilities();
    if (res instanceof ErrorResponse) {
      if (res.code === ErrorType.LINK_IS_DEAD) {
        errorToast(
          "Le lien d'import n'est plus disponible. Veuillez contacter le support pour le mettre à jour."
        );
      } else {
        errorToast("Erreur lors de l'import des centres de santé");
      }
      setImportFacilitiesStatus(ImportStatus.FAIL);
    } else {
      setImportFacilitiesStatus(ImportStatus.LOADING);
    }
    await lastImport("FACILITIES");
  };

  const lastImport = async (importName: string): Promise<void> => {
    const res = await getLastImport(importName);
    if (res instanceof Error) {
      if (importName === "FACILITIES") {
        setLastFacilityImport(undefined);
      } else {
        setLastHealthProfessionalImport(undefined);
      }
    } else {
      if (importName === "FACILITIES") {
        setLastFacilityImport(res);
        if (checkHasExpired(res)) {
          setImportFacilitiesStatus(ImportStatus.UNKNOWN);
        } else {
          setImportFacilitiesStatus(res.status);
        }
      } else {
        setLastHealthProfessionalImport(res);
        if (checkHasExpired(res)) {
          setImportDirectoryStatus(ImportStatus.UNKNOWN);
        } else {
          setImportDirectoryStatus(res.status);
        }
      }
    }
  };

  useEffect(() => {
    lastImport("HEALTH_PROFESSIONALS");
    lastImport("FACILITIES");
    const interval = setInterval(() => {
      lastImport("HEALTH_PROFESSIONALS");
      lastImport("FACILITIES");
    }, 1800000); // refresh every 30 min
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <PageHeaderWithTitle
        title={"Import des référentiels"}
        backButtonRedirectsTo={`/`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div className={`row ${styles.gridContainer}`}>
            <div className={styles.importCard}>
              <div className={`text-large ${styles.extraBoldTitle}`}>
                Importer l&apos;annuaire des professionnels de santé
              </div>
              <div className={styles.darkDivider} />
              <div className="text-grey">
                {lastHealthProfessionalImport &&
                lastHealthProfessionalImport.lastUpdateEnd ? (
                  <>
                    Le dernier import date du{" "}
                    {formatDate(lastHealthProfessionalImport.lastUpdateEnd)}.
                  </>
                ) : (
                  <>Date du dernier import inconnue</>
                )}
                {lastHealthProfessionalImport &&
                  lastHealthProfessionalImport.status && (
                    <>
                      <br />
                      Etat du dernier import :{" "}
                      {importStatusLabel(importDirectoryStatus)}.
                    </>
                  )}

                <div className="row centered-content mt-4">
                  <button
                    disabled={importDirectoryStatus === ImportStatus.LOADING}
                    onClick={(): any => startDirectoryImport()}
                    className={
                      styles.importButton +
                      " w-50 btn neuro-btn rectangular-btn mt-2 " +
                      (importDirectoryStatus === ImportStatus.LOADING
                        ? "loading"
                        : "")
                    }
                  >
                    Démarrer l&apos;import
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.importCard}>
              <div className={`text-large ${styles.extraBoldTitle}`}>
                Importer la liste des centres de santé
              </div>
              <div className={styles.darkDivider} />
              <div className="text-grey">
                {lastFacilityImport && lastFacilityImport.lastUpdateEnd ? (
                  <>
                    Le dernier import date du{" "}
                    {formatDate(lastFacilityImport.lastUpdateEnd)}.
                  </>
                ) : (
                  <>Date du dernier import inconnue</>
                )}
                {lastFacilityImport && lastFacilityImport.status && (
                  <>
                    <br />
                    Etat du dernier import :{" "}
                    {importStatusLabel(importFacilitiesStatus)}.
                  </>
                )}
                <div className="row centered-content mt-4">
                  <button
                    disabled={importFacilitiesStatus === ImportStatus.LOADING}
                    onClick={(): any => startFacilitiesImport()}
                    className={
                      styles.importButton +
                      " w-50 btn neuro-btn rectangular-btn mt-2 " +
                      (importFacilitiesStatus === ImportStatus.LOADING
                        ? "loading"
                        : "")
                    }
                  >
                    Démarrer l&apos;import
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
