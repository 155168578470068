/* eslint-disable */

function checkUserLicenseRight(dmpConnectInstance) {
  console.log("get license rights");
  return new Promise((resolve, reject) => {
    dmpConnectInstance.hl_checkUserLicenseRight(function (a) {
      dmpConnectInstance._errorHandler = (e) => {
        reject(e);
      };
      if (a.error) {
        console.log("-- hl_checkUserLicenseRight failed: ", a);
      } else {
        console.log("-- hl_checkUserLicenseRight succeeded.", a);
        resolve(true);
      }
    });
  });
}

export function openSessionWithCustomDcParam(dmpConnectInstance, dcparam) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_openSessionWithCustomDcParam(
      dcparam,
      3600,
      { i_getDcparamInformations: 1 },
      function (a) {
        if (a.error) {
          console.info("-- hl_openSessionWithCustomDcParam failed: ", a);
          reject();
        } else {
          console.info("-- hl_openSessionWithCustomDcParam succeeded.", a);
          resolve();
        }
      }
    );
  });
}

export function listPcscSmartcardReaders(dmpConnectInstance) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_getPcscReaders(function (a) {
      console.info("Got PC/SC readers", a);
      resolve(a.Readers);
    });
  });
}

export function readCPxCard(dmpConnectInstance, readerNumber, pinCode) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_getCpxCard(readerNumber, "", function () {
      dmpConnectInstance.hl_readCpxCard(pinCode, function (result) {
        if (result.error) {
          console.info("-- readCpxCard failed: ", result);
          reject(result);
        } else {
          console.info("-- readCpxCard succeeded.", result);
          resolve(result);
        }
      });
    });
  });
}

export function createInsiConnector(dmpConnectInstance) {
  const connectionDomain = "";
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_createInsiConnector(
      "1234",
      connectionDomain,
      60,
      "AMBULATOIRE",
      0,
      null,
      function (a) {
        if (a.error) {
          console.info("-- hl_createInsiConnector failed: ", a);
          reject(a);
        } else {
          console.info("-- hl_createInsiConnector succeeded.", a);
          resolve(a);
        }
      }
    );
  });
}

export function createDmpConnector(dmpConnectInstance) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_createDmpConnector(
      "1234",
      "formation1",
      60,
      "AMBULATOIRE",
      0,
      null,
      function (a) {
        if (a.error) {
          console.info("-- hl_createDmpConnector failed: ", a);
          reject(a);
        } else {
          console.info("-- hl_createDmpConnector succeeded.", a);
          resolve(a);
        }
      }
    );
  });
}

export function getCertifiedIdentity(dmpConnectInstance, patientInfo) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_getCertifiedIdentity(
      //TODO: à changer pour dmp
      patientInfo.ins + "T",
      patientInfo.birthday.replaceAll("-", ""),
      1,
      "",
      function (a) {
        if (a.error) {
          console.info("-- hl_getCertifiedIdentity failed: ", a);
          reject(a);
        } else {
          console.info("-- hl_getCertifiedIdentity succeeded.", a);
          resolve(a);
        }
      }
    );
  });
}

export function getDirectAuthentificationDMPStatus(dmpConnectInstance) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_getDirectAuthenticationDMPStatus(
      //TODO: a changer
      "188102B17295264T", //ins patient
      function (a) {
        if (a.error) {
          console.info("-- hl_getDirectAuthenticationDMPStatus failed: ", a);
          reject(a);
        } else {
          console.info("-- hl_getDirectAuthenticationDMPStatus succeeded.", a);
          resolve(a);
        }
      }
    );
  });
}

export function setDmpAccessMode(dmpConnectInstance, accessMode, reason) {
  return new Promise((resolve, reject) => {
    dmpConnectInstance._errorHandler = (e) => {
      reject(e);
    };
    dmpConnectInstance.hl_setDmpAccessMode(
      //TODO: should always be Bris de glace ?
      accessMode,
      reason,
      {},
      function (a) {
        if (a.error) {
          console.info("-- hl_setDmpAccessMode failed: ", a);
          reject(a);
        } else {
          console.info("-- hl_setDmpAccessMode succeeded.", a);
          resolve(a);
        }
      }
    );
  });
}
