import React, { useContext, useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { ToggleSwitch } from "../Shared/ToggleSwitch";
import { ActionTypes, AppContext } from "../../utils/state";
import { updateNotificationsPreferences } from "../../services/UserService";
import { errorToast, successToast } from "../../utils/Toast";
import { User } from "../../domain/User";
import { CompletedProfileCard } from "./CompletedProfileCard";
import { getWebsiteTitle } from "../../utils/Brands";
import config from "../../utils/config";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const ProfilePreferences: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const user = appContext.state.user;

  const [showCompleteProfileCard] = useState<boolean>(false); // TODO use it later to show or hide the CompleteProfileCard component
  const [showMoreNotifications, setShowMoreNotifications] = useState<boolean>(
    false
  );

  const [notifAuthorized, setNotifAuthorized] = useState<boolean>(
    user.allowNotifications ? user.allowNotifications : false
  );
  const [smsNotifAuthorized, setSmsNotifAuthorized] = useState<boolean>(
    user.allowSmsNotifications ? user.allowSmsNotifications : false
  );
  const [emailNotifAuthorized, setEmailNotifAuthorized] = useState<boolean>(
    user.allowEmailNotifications ? user.allowEmailNotifications : false
  );
  const [expertNotifAuthorized, setExpertNotifAuthorized] = useState<boolean>(
    user.allowExpertNotifications ? user.allowExpertNotifications : false
  );

  const updatePreferences = async (): Promise<void> => {
    const userUpdated: User = {
      ...user,
      allowNotifications: notifAuthorized,
      allowSmsNotifications: smsNotifAuthorized,
      allowEmailNotifications: emailNotifAuthorized,
      allowExpertNotifications: expertNotifAuthorized,
    };

    const res = await updateNotificationsPreferences(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de la mise à jour des préférences");
    } else {
      successToast("Préférences mises à jour");
      appContext.dispatch({
        type: ActionTypes.UserUpdated,
        payload: userUpdated,
      });
    }
  };

  useEffect(() => {
    if (!notifAuthorized) {
      setSmsNotifAuthorized(false);
      setEmailNotifAuthorized(false);
      setExpertNotifAuthorized(false);
      setShowMoreNotifications(false);
    } else {
      setShowMoreNotifications(true);
    }
  }, [notifAuthorized, smsNotifAuthorized, emailNotifAuthorized]);

  return (
    <>
      <PageHeaderWithTitle
        title={"Préférences"}
        backButtonRedirectsTo={`/profile`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div className={"row " + styles.rowGravityTop}>
            {showCompleteProfileCard && <CompletedProfileCard />}
            <div className={"text-left " + styles.preferencesCard}>
              <div className={styles.boldTitle}>Préférences</div>
              <div className={styles.darkDivider} />
              <div className={"text-grey " + styles.marginBottom}>
                Vous recevrez uniquement des notifications concernant vos
                demandes.
              </div>
              <div className="row">
                <div className={"col w-70 " + styles.inline}>
                  J&apos;accepte de recevoir des notifications de la part de{" "}
                  {getWebsiteTitle(config.brand)}
                </div>
                <div id="toggle-switch-notif-all" className="col w-30">
                  <ToggleSwitch
                    key={1}
                    onToggleChange={(): void => {
                      setNotifAuthorized(!notifAuthorized);
                    }}
                    toggled={notifAuthorized}
                  />
                </div>
              </div>
              <div className="row">
                <div className={"w-100 " + styles.divider} />
              </div>
              {showMoreNotifications && (
                <>
                  <div className="row">
                    <div className={"col w-70 " + styles.inline}>
                      J&apos;accepte d&apos;être contacté en tant qu&apos;expert
                      sur une demande d&apos;avis
                    </div>
                    <div id="toggle-switch-notif-expert" className="col w-30">
                      <ToggleSwitch
                        key={2}
                        onToggleChange={(): void => {
                          setExpertNotifAuthorized(!expertNotifAuthorized);
                        }}
                        toggled={expertNotifAuthorized}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className={"w-100 " + styles.divider} />
                  </div>
                  <div className="row">
                    <div className={"col w-70 " + styles.inline}>
                      Je souhaite recevoir les notifications
                      <span className="primary-blue-text"> par sms</span>
                    </div>
                    <div id="toggle-switch-notif-sms" className="col w-30">
                      <ToggleSwitch
                        key={3}
                        onToggleChange={(): void => {
                          setSmsNotifAuthorized(!smsNotifAuthorized);
                        }}
                        toggled={smsNotifAuthorized}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className={"w-100 " + styles.divider} />
                  </div>
                  <div className="row">
                    <div className={"col w-70 " + styles.inline}>
                      Je souhaite recevoir les notifications
                      <span className="primary-blue-text"> par e-mail</span>
                    </div>
                    <div id="toggle-switch-notif-email" className="col w-30">
                      <ToggleSwitch
                        key={4}
                        onToggleChange={(): void => {
                          setEmailNotifAuthorized(!emailNotifAuthorized);
                        }}
                        toggled={emailNotifAuthorized}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={"row centered-content mt-2"}>
                <button
                  className={"btn rectangular-btn neuro-btn mt-2"}
                  onClick={updatePreferences}
                >
                  Sauvegarder les modifications
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
