import React, { useEffect, useState } from "react";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { toast } from "react-toastify";
import { listAllUsers } from "../../services/UserService";
import { ToValidateUsersList } from "./ToValidateUsersList";
import history from "../../utils/history";
import "./Admin.css";
import { AllUsers } from "./AllUsers";
import { UserStatus } from "../../domain/UserStatus";
import { User } from "../../domain/User";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";

interface Props {
  user: User;
}

enum UserTab {
  WAITING = "waiting",
  ALL = "all",
}

export const AdminUserTab: React.FC<Props> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [selectedUserTab, setSelectedUserTab] = useState<UserTab>(
    history.location.pathname.split("/").pop() === UserTab.ALL
      ? UserTab.ALL
      : UserTab.WAITING
  );
  const [usersToValidate, setUsersToValidate] = useState<User[]>([]);

  const refreshUsers = (): any => {
    listAllUsers().then((res: User[] | Error) => {
      if (res instanceof Error) {
        setStatus(LoadingStatus.ERROR);
        toast.error("Erreur durant le chargement des utilisateurs");
      } else {
        setAllUsers(res);
        setUsersToValidate(
          res.filter((user) => user.status === UserStatus.REGISTERED)
        );
        setStatus(LoadingStatus.SUCCESS);
      }
    });
  };

  useEffect(() => {
    if (!hasRole(props.user.roles, UserRoles.ADMIN)) {
      history.push("/forbidden");
    } else {
      refreshUsers();
    }
  }, [props.user, selectedUserTab]);

  return (
    <div className="margin-top-2">
      <div className="row mr-auto ml-auto admin-white-container">
        <button
          id="waiting"
          className={
            "admin-menu-btn " +
            (selectedUserTab === UserTab.WAITING &&
              "admin-menu-btn-active admin-menu-btn-active-left")
          }
          onClick={(): any => {
            setSelectedUserTab(UserTab.WAITING);
            history.push("/admin/users/waiting");
          }}
        >
          Inscriptions en attente ({usersToValidate.length})
        </button>
        <button
          id="validated"
          className={
            "admin-menu-btn " +
            (selectedUserTab === UserTab.ALL &&
              "admin-menu-btn-active admin-menu-btn-active-right")
          }
          onClick={(): any => {
            setSelectedUserTab(UserTab.ALL);
            history.push("/admin/users/all");
          }}
        >
          Tous les utilisateurs
        </button>
      </div>
      {selectedUserTab === UserTab.WAITING && (
        <div className="mt-2 admin-white-container">
          {status !== LoadingStatus.ERROR && (
            <ToValidateUsersList
              users={usersToValidate}
              refreshUsers={(): any => refreshUsers()}
            />
          )}
        </div>
      )}
      {selectedUserTab === UserTab.ALL && <AllUsers users={allUsers} />}
    </div>
  );
};
