enum SpecialtyEnum {
  SPECIALTY_NEURO = "SPECIALTY_NEURO",
  SPECIALTY_MPR = "SPECIALTY_MPR",
  SPECIALTY_REANIMATION = "SPECIALTY_REANIMATION",
  SPECIALTY_EMERGENCY = "SPECIALTY_EMERGENCY",
  SPECIALTY_DERMATOLOGY = "SPECIALTY_DERMATOLOGY",
  SPECIALTY_OPHTHALMOLOGY = "SPECIALTY_OPHTHALMOLOGY",
  SPECIALTY_ONCOLOGY = "SPECIALTY_ONCOLOGY",
  SPECIALTY_PSYCHIATRY = "SPECIALTY_PSYCHIATRY",
}

export const specialtyTranslate: Record<SpecialtyEnum, string> = {
  SPECIALTY_NEURO: "Neurologie",
  SPECIALTY_MPR: "MPR",
  SPECIALTY_REANIMATION: "Réanimation",
  SPECIALTY_EMERGENCY: "Médecine d'urgence",
  SPECIALTY_DERMATOLOGY: "Dermatologie",
  SPECIALTY_OPHTHALMOLOGY: "Ophthalmologie",
  SPECIALTY_ONCOLOGY: "Oncologie",
  SPECIALTY_PSYCHIATRY: "Psychiatrie",
};
export const specialtyStyleClass: Record<SpecialtyEnum, string> = {
  SPECIALTY_NEURO: "neuroLabel",
  SPECIALTY_MPR: "mprLabel",
  SPECIALTY_REANIMATION: "reanimationLabel",
  SPECIALTY_EMERGENCY: "emergencyLabel",
  SPECIALTY_DERMATOLOGY: "dermaLabel",
  SPECIALTY_OPHTHALMOLOGY: "ophthalmoLabel",
  SPECIALTY_ONCOLOGY: "oncologyLabel",
  SPECIALTY_PSYCHIATRY: "psychiatryLabel",
};

export default SpecialtyEnum;
