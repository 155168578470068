import React from "react";
import { OpinionRequestFormPage } from "../../../domain/OpinionRequest";
import RequestPrimaryType from "../../../domain/Subject/RequestPrimaryType.enum";
import {
  AdviceOnPathologyDetails,
  Subject,
  TherapeuticEffortLimitationDetails,
} from "../../../domain/Subject/Subject";
import { AdviceOnPathologyForm } from "./AdviceOnPathologyForm";
import { EegInterpretationForm } from "./EegInterpretationForm";
import { RequestTypeSelect } from "./RequestTypeSelect";
import { TherapeuticEffortLimitationForm } from "./TherapeuticEffortLimitationForm";

interface Props {
  selectedSubject: Subject;
  hasPatientFacilityId: boolean;
  onChange: (newState: Subject) => any;
  readOnly?: boolean;
}

export interface LabelValue<T = string> {
  value: T;
  label: string;
}

type Ennum<T> = { [s: string]: T };
export const enumAndTranslationToItemElement = <T extends keyof any>(
  ennum: Ennum<T>,
  translationMap: Record<T, string>
): LabelValue<T>[] => {
  return Object.values(ennum).map((element: T) => ({
    value: element,
    label: translationMap[element],
  }));
};

export const SubjectForm: React.FC<Props> = (props: Props) => {
  const onSelectPrimaryType = (
    requestType: RequestPrimaryType | null
  ): void => {
    const subject: Subject = {
      ...props.selectedSubject,
      requestType: requestType,
    };
    if (requestType !== RequestPrimaryType.ADVICE_ON_PATHOLOGY) {
      subject.adviceOnPathologyDetails = undefined;
    }
    if (requestType !== RequestPrimaryType.THERAPEUTIC_EFFORT_LIMITATION) {
      subject.therapeuticEffortLimitationDetails = undefined;
    }
    props.onChange(subject);
  };

  const onSelectTherapeuticEffortLimitationDetails = (
    details: TherapeuticEffortLimitationDetails
  ) => {
    props.onChange({
      ...props.selectedSubject,
      therapeuticEffortLimitationDetails: details,
    });
  };
  const onSelectAdviceOnPatholofyDetails = (
    details: AdviceOnPathologyDetails
  ) => {
    props.onChange({
      ...props.selectedSubject,
      adviceOnPathologyDetails: details,
    });
  };

  return (
    <div>
      <div className="form-group">
        <h4 className="form-title">Quel est l&apos;objet de votre demande ?</h4>
        <p>
          Précisez nous votre demande afin que nous puissions assigner
          l&apos;expert idéal. Si votre demande porte sur plusieurs objets,
          merci de soumettre une demande d&apos;avis par sujet.
          {!props.hasPatientFacilityId && (
            <label className="row mt-2 text-grey text-small">
              Si vous souhaitez sélectionner l&apos;option &quot;Procédure de
              Limitation et d’Arrêt des Thérapeutiques&quot;, veuillez
              renseigner le centre de soin du patient à la page{" "}
              {OpinionRequestFormPage.patientInformation}.
            </label>
          )}
        </p>

        <RequestTypeSelect
          value={props.selectedSubject.requestType}
          onChange={onSelectPrimaryType}
        />

        {props.selectedSubject.requestType ===
          RequestPrimaryType.THERAPEUTIC_EFFORT_LIMITATION && (
          <TherapeuticEffortLimitationForm
            value={props.selectedSubject.therapeuticEffortLimitationDetails}
            onChange={onSelectTherapeuticEffortLimitationDetails}
          />
        )}
        {props.selectedSubject.requestType ===
          RequestPrimaryType.EEG_INTERPRETATION && <EegInterpretationForm />}
        {props.selectedSubject.requestType ===
          RequestPrimaryType.EEG_INTERPRETATION_ONLY && (
          <EegInterpretationForm />
        )}
        {props.selectedSubject.requestType ===
          RequestPrimaryType.ADVICE_ON_PATHOLOGY && (
          <AdviceOnPathologyForm
            value={props.selectedSubject.adviceOnPathologyDetails}
            onChange={onSelectAdviceOnPatholofyDetails}
          />
        )}
      </div>
    </div>
  );
};
