import React, { useEffect } from "react";
import history from "../utils/history";
import styles from "./Forbidden.module.css";

export const Forbidden: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 5000);
  }, []);

  return (
    <header className={styles.Header}>
      <p>
        Vous n&apos;avez pas accès à cette page et allez être redirigé vers la
        page d&apos;accueil de l&apos;application.
      </p>
    </header>
  );
};
