import { get, send } from "./ApiClient";
import { DataImport } from "../domain/DataImport";
import { HealthProfessional } from "../domain/HealthProfessionnal";

export async function searchHealthProfessionalByNationalId(
  id: string
): Promise<HealthProfessional[] | Error> {
  try {
    return await get(`/directory/search?rpps=${id}`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function importHealthProfessionals(): Promise<void | Error> {
  try {
    return await send(`/directory/import`, "POST");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function importFacilities(): Promise<void | Error> {
  return send(`/facilities/import`, "POST");
}

export async function getLastImport(
  importName: string
): Promise<DataImport | Error> {
  try {
    return await get(`/import-info?name=${importName}`, "GET");
  } catch (err) {
    console.error(err);
    return err;
  }
}
