import React, { useContext } from "react";
import "./Admin.css";
import styles from "./AdminViewUpdateOrganization.module.css";
import { Organization, OrganizationMember } from "../../domain/Organization";
import { useParams } from "react-router-dom";
import { updateOrganization } from "../../services/OrganizationService";
import history from "../../utils/history";
import { ActionTypes, AppContext } from "../../utils/state";
import { errorToast, successToast } from "../../utils/Toast";
import { User } from "../../domain/User";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import { getProfile } from "../../services/UserService";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import { EditOrganization } from "./EditOrganization";

interface ParamTypes {
  id: string;
}

export const AdminUpdateOrganizationDetail: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const { id } = useParams<ParamTypes>();

  const onSubmit = async (org: Organization): Promise<void> => {
    updateOrganization(id, org).then((res: any | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur lors de l'édition du détail de l'organisation");
      } else {
        successToast("Détail de l'organisation mise à jour");
        if (!hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
          const isAdminOfOrganization = res.admins.some(
            (orgAdmin: OrganizationMember) =>
              orgAdmin.userId === appContext.state.user.id
          );
          if (!isAdminOfOrganization) {
            getProfile().then((updatedUser: User | Error) => {
              if (updatedUser instanceof Error) {
                errorToast("Erreur lors de la récupération des disponibilités");
              } else {
                updatedUser.organizations = updatedUser.organizations || [];
                appContext.dispatch({
                  type: ActionTypes.UserUpdated,
                  payload: updatedUser,
                });
                history.push("/admin/organizations");
              }
            });
          } else {
            history.push(`/admin/organizations/${id}/view`);
          }
        } else {
          history.push(`/admin/organizations/${id}/view`);
        }
      }
    });
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Mise à jour du détail de l'organisation"}
        backButtonRedirectsTo={`/admin/organizations/${id}/view`}
      />
      <div className="page-container">
        <div
          className={`col-8 col-sm-auto col-mx-auto ${styles.whiteBackground}`}
        >
          <EditOrganization
            id={id}
            onEditOrganization={onSubmit}
            onCancel={(): void =>
              history.push(`/admin/organization-details/${id}/view`)
            }
            saveString={"Sauvegarder les modifications"}
            saveShortString={"Sauvegarder"}
            detail={true}
          />
        </div>
      </div>
    </>
  );
};
