import React, { useEffect, useState } from "react";
import { errorToast } from "../../../utils/Toast";
import { searchClients } from "../../../services/InvoicingService";
import { InvoiceClient } from "../../../domain/InvoiceClient";
import AsyncCreatable from "react-select/async-creatable";
import { Controller } from "react-hook-form";
import { errorContainer, ErrorMessage } from "../InvoicingForm";

interface Props {
  register: any;
  errors: any;
  control: any;
  reset: any;
  savedInvoicingClientForm: any;
  selectedClient: any;
  onSelectClient: (existingClient?: InvoiceClient) => any;
  onCreate: boolean;
  setOnCreate: (onCreate: boolean) => any;
}

export const InvoicingFormPage1: React.FC<Props> = (props: Props) => {
  const [showSelectByName, setShowSelectByName] = useState<boolean>(true);
  const [showSelectByCompanyName, setShowSelectByCompanyName] = useState<
    boolean
  >(true);
  const [disableInputName, setDisableInputName] = useState<boolean>(false);
  const [disableInputCompanyName, setDisableInputCompanyName] = useState<
    boolean
  >(false);

  const selectCustomStyle = {
    control: (provided: any, state: any): Record<string, any> => ({
      borderBottom: state.isFocused
        ? "solid 2px var(--primary-blue)"
        : "0.05rem solid #bcc3ce",
      boxShadow: "var(--primary-blue)",
      display: "flex",
    }),
    placeholder: (): Record<string, any> => ({
      color: "var(--placeholder-grey)",
    }),
    option: (provided: any, state: any): Record<string, any> => ({
      ...provided,
      backgroundColor: state.isFocused ? "var(--secondary-blue)" : "white",
      ":hover": {
        backgroundColor: "var(--secondary-blue)",
      },
      color: "black",
      whiteSpace: "pre-wrap",
      textAlign: "left",
    }),
    singleValue: (): Record<string, any> => ({
      width: "100%",
    }),
    input: (): Record<string, any> => ({
      position: "absolute",
    }),
  };

  const inputCheck = (object: any): any => {
    if (object.target.value !== "") {
      if (isNaN(Number(object.target.value[object.target.value.length - 1]))) {
        object.target.value = object.target.value.slice(0, -1);
      }
    }
  };

  const formatOptionLabel = (client: InvoiceClient, label: any): any => (
    <>
      {client && client.name ? (
        <div className="text-black">
          {client.name}{" "}
          {client.code && (
            <>
              - <span className="text-grey">{client.code}</span>
            </>
          )}
        </div>
      ) : (
        <div className="text-grey">
          Créer nouveau client :{" "}
          <span className="text-black">{label.inputValue}</span>
        </div>
      )}
    </>
  );

  const formatOptionLabelCompanyName = (
    client: InvoiceClient,
    label: any
  ): any => (
    <>
      {client.companyName ? (
        <div className="text-black">
          {client.companyName}{" "}
          {client.code && (
            <>
              - <span className="text-grey">{client.code}</span>
            </>
          )}
        </div>
      ) : (
        <div className="text-grey">
          Créer nouveau client :{" "}
          <span className="text-black">{label.inputValue}</span>
        </div>
      )}
    </>
  );

  const onSelectClient = (selectedValue: any): void => {
    if (selectedValue === null) {
      props.onSelectClient(undefined);
      setShowSelectByName(true);
      setDisableInputName(false);
      setShowSelectByCompanyName(true);
      setDisableInputCompanyName(false);
      props.setOnCreate(true);
    } else {
      props.onSelectClient(selectedValue);
      props.setOnCreate(false);
    }
  };

  const onCreateClientByName = (inputValue: any): void => {
    const newClient: InvoiceClient = {
      addressCity: "",
      addressPostCode: "",
      addressStreet: "",
      code: "",
      companyName: "",
      email: "",
      id: "",
      name: inputValue,
      vatNumber: "",
    };
    props.onSelectClient(newClient);
    setShowSelectByCompanyName(false);
    setDisableInputCompanyName(false);
    props.setOnCreate(true);
  };

  const onCreateClientByCompanyName = (inputValue: any): void => {
    const newClient: InvoiceClient = {
      addressCity: "",
      addressPostCode: "",
      addressStreet: "",
      code: "",
      companyName: inputValue,
      email: "",
      id: "",
      name: "",
      vatNumber: "",
    };
    props.onSelectClient(newClient);
    setShowSelectByName(false);
    setDisableInputName(false);
    props.setOnCreate(true);
  };

  const onSearch = async (query: string, filter: string): Promise<any> => {
    const res = await searchClients(query);
    if (res instanceof Error) {
      errorToast("Une erreur est survenue lors de la recherche");
    } else {
      return filter === "name"
        ? res.filter((client) => client.name)
        : res.filter((client) => client.companyName);
    }
  };

  useEffect(() => {
    if (props.selectedClient !== undefined) {
      if (
        props.selectedClient.name === "" ||
        props.selectedClient.name === null
      ) {
        setShowSelectByName(false);
        !props.onCreate && setDisableInputName(true);
      } else {
        setShowSelectByName(true);
      }

      if (
        props.selectedClient.companyName === "" ||
        props.selectedClient.companyName === null
      ) {
        setShowSelectByCompanyName(false);
        !props.onCreate && setDisableInputCompanyName(true);
      } else {
        setShowSelectByCompanyName(true);
      }

      props.reset({
        vatNumber: props.selectedClient.vatNumber,
        email: props.selectedClient.email,
        addressStreet: props.selectedClient.addressStreet,
        addressPostCode: props.selectedClient.addressPostCode,
        addressCity: props.selectedClient.addressCity,
      });
    } else {
      props.reset({
        vatNumber: "",
        email: "",
        addressStreet: "",
        addressPostCode: "",
        addressCity: "",
      });
    }
  }, [props.selectedClient, props.onCreate]);

  return (
    <div className="text-left">
      <div className="text-small text-grey">
        Indiquez le nom du client et/ou la raison sociale.
      </div>
      <div className="form-group">
        <label className="form-label">Nom du client</label>
        {showSelectByName ? (
          <Controller
            name="name"
            control={props.control}
            defaultValue={
              props.selectedClient !== undefined ? props.selectedClient : null
            }
            rules={props.register("name", {
              validate: () => {
                return (
                  props.selectedClient !== undefined &&
                  (props.selectedClient.name !== "" ||
                    props.selectedClient.companyName !== "")
                );
              },
            })}
            render={(): any => (
              <AsyncCreatable
                id="client-name-input"
                isClearable={true}
                onChange={(option: any): void => onSelectClient(option)}
                loadOptions={(query): any => onSearch(query, "name")}
                formatOptionLabel={(option: any, label): any =>
                  formatOptionLabel(option, label)
                }
                openMenuOnClick={false}
                placeholder="Entrer le nom du client"
                noOptionsMessage={(): any => ""}
                onCreateOption={onCreateClientByName}
                value={props.selectedClient || ""}
                styles={selectCustomStyle}
              />
            )}
          />
        ) : (
          <input
            readOnly={disableInputName}
            className="form-input w-100"
            type="text"
            placeholder="Entrer le nom du client"
            {...props.register("name", {
              validate: () => {
                return (
                  props.selectedClient !== undefined &&
                  (props.selectedClient.name !== "" ||
                    props.selectedClient.companyName !== "")
                );
              },
            })}
            defaultValue={props.savedInvoicingClientForm.name}
          />
        )}
      </div>
      <div className="form-group">
        <label className="form-label">Raison sociale</label>
        {showSelectByCompanyName ? (
          <Controller
            name="companyName"
            control={props.control}
            defaultValue={
              props.selectedClient !== undefined ? props.selectedClient : null
            }
            rules={props.register("companyName", {
              validate: () => {
                return (
                  props.selectedClient !== undefined &&
                  (props.selectedClient.name !== "" ||
                    props.selectedClient.companyName !== "")
                );
              },
            })}
            render={(): any => (
              <AsyncCreatable
                name={"companyName"}
                isClearable={true}
                onChange={(option: any): void => onSelectClient(option)}
                loadOptions={(query): any => onSearch(query, "companyName")}
                formatOptionLabel={(option: any, label): any =>
                  formatOptionLabelCompanyName(option, label)
                }
                openMenuOnClick={false}
                placeholder="Entrer la raison sociale"
                noOptionsMessage={(): any => ""}
                onCreateOption={onCreateClientByCompanyName}
                value={props.selectedClient || ""}
                styles={selectCustomStyle}
              />
            )}
          />
        ) : (
          <input
            id={"clientCompanyName"}
            readOnly={disableInputCompanyName}
            className="form-input w-100"
            type="text"
            placeholder="Entrer la raison sociale"
            {...props.register("companyName", {
              validate: () => {
                return (
                  props.selectedClient !== undefined &&
                  (props.selectedClient.name !== "" ||
                    props.selectedClient.companyName !== "")
                );
              },
            })}
            defaultValue={props.savedInvoicingClientForm.companyName}
          />
        )}
        {props.errors.name &&
          props.errors.companyName &&
          errorContainer(
            "Veuillez renseigner le nom du client ou de la raison sociale."
          )}
      </div>
      <div className="form-group">
        <label className="form-label">Numéro de TVA intracommunautaire</label>
        <input
          id="vat-number-input"
          readOnly={props.selectedClient && !props.onCreate}
          className="form-input w-100"
          type="text"
          placeholder="Entrer le numéro de TVA"
          {...props.register("vatNumber", {
            pattern: {
              value: /(FR)?[0-9A-Z ]{2}\s*(\d\s*){9}/,
              message: ErrorMessage.VAT_NUMBER_ERROR,
            },
          })}
          defaultValue={props.savedInvoicingClientForm.vatNumber}
        />
        {props.errors.vatNumber &&
          errorContainer(props.errors.vatNumber.message)}
      </div>
      <div className="form-group mt-2">
        <label className="form-label">
          Courriel<span className="error-msg text-large ml-1 mt-1">*</span>
        </label>
        <input
          id="email-input"
          className="form-input w-100"
          type="email"
          placeholder="Entrer l'adresse email"
          {...props.register("email", {
            required: { value: true, message: ErrorMessage.REQUIRED_FIELD },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: ErrorMessage.EMAIL_ERROR,
            },
          })}
          defaultValue={props.savedInvoicingClientForm.email}
        />
        {props.errors.email && errorContainer(props.errors.email.message)}
      </div>
      <div className="form-group">
        <label className="form-label">
          N° et nom de rue
          <span className="error-msg text-large ml-1 mt-1">*</span>
        </label>
        <input
          id="street-input"
          className="form-input w-100"
          type="text"
          placeholder="Entrer le numéro et le nom de la rue"
          {...props.register("addressStreet", { required: true })}
          defaultValue={props.savedInvoicingClientForm.addressStreet}
        />
        {props.errors.addressStreet &&
          errorContainer(ErrorMessage.REQUIRED_FIELD)}
      </div>
      <div className="form-group row">
        <div className="col-6">
          <label className="form-label">
            Code Postal<span className="error-msg text-large ml-1 mt-1">*</span>
          </label>
          <input
            id="post-code-input"
            className="form-input w-90"
            type="text"
            placeholder="Code postal"
            minLength={5}
            maxLength={5}
            onInput={inputCheck}
            {...props.register("addressPostCode", {
              required: { value: true, message: ErrorMessage.REQUIRED_FIELD },
              pattern: {
                value: /^[0-9]{5}$/i,
                message: ErrorMessage.POSTAL_CODE_ERROR,
              },
            })}
            defaultValue={props.savedInvoicingClientForm.addressPostCode}
          />
          {props.errors.addressPostCode &&
            errorContainer(props.errors.addressPostCode.message)}
        </div>
        <div className="col-6">
          <label className="form-label">
            Ville<span className="error-msg text-large ml-1 mt-1">*</span>
          </label>
          <input
            id="city-input"
            className="form-input w-90"
            type="text"
            placeholder="Ville"
            {...props.register("addressCity", { required: true })}
            defaultValue={props.savedInvoicingClientForm.addressCity}
          />
          {props.errors.addressCity &&
            errorContainer(ErrorMessage.REQUIRED_FIELD)}
        </div>
      </div>
    </div>
  );
};
