import React, { useRef } from "react";
import { Attachment, ImagingStudy } from "../../../../domain/OpinionRequest";
import config from "../../../../utils/config";
import { UploadBar } from "../UploadBar";
import { getAttachmentUrl } from "../../../../services/OpinionRequestService";
import { UploadProgress } from "./ImagingDocumentUpload";

interface Props {
  documents: ImagingStudy[] | Attachment[];
  requestId: string;
  onDelete: (documentId: string) => any;
  onChangeFileHandler: (event: any) => any;
  disabledButton: boolean;
  mainTitle: string;
  subTitle: string;
  hasFolderInput?: boolean;
  uploadProgress?: Map<string, UploadProgress>;
  inputAccept?: string;
  id: string;
}

const instanceOfImaginStudy = (document: any): document is ImagingStudy => {
  return "StudyInstanceId" in document;
};

export const LineDisplay: React.FC<Props> = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const fileButtonHandler = (): any => {
    fileInputRef?.current?.click();
  };
  const folderButtonHandler = (): any => {
    folderInputRef?.current?.click();
  };

  const getProgressBars = () => {
    const progressBars: JSX.Element[] = [];
    props.uploadProgress?.forEach((value, key) => {
      progressBars.push(
        <div key={key} className="mb-2">
          <UploadBar actual={value.cpt} total={value.total} />
        </div>
      );
    });
    return progressBars;
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <div>
      <h4 className="form-title">{props.mainTitle}</h4>
      {!props.disabledButton && (
        <>
          <div className="row w-100 text-small">{props.subTitle}</div>
          {getProgressBars()}
          <div className="row">
            <div className="cr-upload mt-2">
              <button
                className="btn btn-sm neuro-icons-btn ml-2 "
                onClick={fileButtonHandler}
              >
                <i className="icon icon-plus mr-2" />
                Parcourir...
              </button>
              <input
                id={props.id}
                ref={fileInputRef}
                type="file"
                accept={props.inputAccept ?? ""}
                onChange={props.onChangeFileHandler}
                hidden={props.disabledButton}
              />
            </div>
            {props.hasFolderInput && (
              <div className="cr-upload mt-2">
                <button
                  className="btn btn-sm neuro-icons-btn ml-2 "
                  onClick={folderButtonHandler}
                >
                  <i className="icon icon-plus mr-2" />
                  Importer un dossier
                </button>
                <input
                  id={`${props.id}-folder`}
                  ref={folderInputRef}
                  type="file"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-expect-error */
                  directory=""
                  webkitdirectory=""
                  onChange={props.onChangeFileHandler}
                  hidden={props.disabledButton}
                />
              </div>
            )}
          </div>
        </>
      )}
      <div className="flex-col">
        {props.documents?.map(
          (document: ImagingStudy | Attachment, index: number) => {
            return (
              <div className="mt-2" key={index}>
                <div className="row space-between-content">
                  {instanceOfImaginStudy(document) ? (
                    <>
                      <a
                        className="file-name"
                        href={`${config.orthancUrl}/stone-webviewer/index.html?patient=${document.PatientId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Voir l'image"
                        key={document.Id}
                      >
                        {document.StudyDescription} - {document.PatientName} -{" "}
                        {document.StudyDate}
                      </a>
                      {document.Size && <div>{formatBytes(document.Size)}</div>}
                    </>
                  ) : (
                    <>
                      <a
                        className="file-name"
                        href={getAttachmentUrl(props.requestId, document.Id)}
                        key={document.Id}
                      >
                        {document.Filename}
                      </a>
                      {document.Size && <div>{formatBytes(document.Size)}</div>}
                    </>
                  )}
                  {!props.disabledButton && (
                    <button
                      style={{ backgroundColor: "transparent" }}
                      className="btn btn-sm neuro-icons-btn mx-2"
                      onClick={(): void => {
                        props.onDelete(
                          instanceOfImaginStudy(document)
                            ? document.StudyInstanceId
                            : document.Id
                        );
                      }}
                    >
                      <i className="icon icon-cross" aria-label="Supprimer" />
                    </button>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
