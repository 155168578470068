import { UIEvent, useState } from "react";

export const useToggleClickOutside = (
  initialValue = false
): readonly [boolean, (e?: UIEvent) => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  // NOTE: this event listener listen for all click on document, it's not a true 'outside',
  //  but not a problem here since we use it to display a floating menu.
  //  also, note that this event is fired before the click (if you click again on the button to call 'toggleValue' fn
  const handleClickOutside = () => {
    setValue(false);
    document.removeEventListener("click", handleClickOutside);
  };

  const toggleValue = (e?: UIEvent) => {
    if (!value) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    setValue(!value);
    e?.preventDefault();
  };
  // `as const` makes sure that your values are constant, and not changeable
  return [value, toggleValue] as const;
};
