import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Profile.module.css";
import UserVerifiedIcon from "../../assets/user-verified-icon.svg";
import { UserStatus } from "../../domain/UserStatus";
import { AppContext } from "../../utils/state";
import { UserRoles } from "../../domain/UserRoles";
import { Figure } from "../Shared/Figure";
import { getMinimizedTitleLabel } from "../../domain/User";
import { hasRole } from "../../utils/authorization";
import { BackButton } from "../Shared/BackButton";

export const Profile: React.FC = () => {
  const appContext = useContext(AppContext);

  const hasName =
    appContext.state.user.firstName || appContext.state.user.lastName;

  const name = `${getMinimizedTitleLabel(
    appContext.state.user?.professionalTitle
  )} ${appContext.state.user?.firstName} ${appContext.state.user?.lastName}`;

  return (
    <>
      <div>
        <BackButton redirectsTo={`/`} />
      </div>
      <div className={styles.profileContainer}>
        {hasName && (
          <div className={styles.figure}>
            <Figure
              firstName={appContext.state.user?.firstName}
              lastName={appContext.state.user?.lastName}
              fontSize={"text-medium"}
            />
          </div>
        )}
        <div className="mt-2">
          <span>Mon compte</span>
          <div className={styles.nameContainer}>
            <span className="text-bold">
              {hasName ? name : appContext.state.user.email}
            </span>
            {appContext.state.user?.status === UserStatus.VALIDATED && (
              <img className="ml-1" alt="icon-chat" src={UserVerifiedIcon} />
            )}
          </div>
        </div>
        <div className={styles.marginTop}>
          <Link to="/profile/personal">
            <span className={styles.labelButton + " " + styles.colorDarkGrey}>
              Informations personnelles
            </span>
          </Link>
          <div className={"w-60 " + styles.divider} />
          <Link to="/profile/professional">
            <span className={styles.labelButton + " " + styles.colorDarkGrey}>
              Informations professionnelles
            </span>
          </Link>
          <>
            <div className={"w-60 " + styles.divider} />
            <Link to="/profile/facilities">
              <span className={styles.labelButton + " " + styles.colorDarkGrey}>
                Lieux d&apos;exercice
              </span>
            </Link>
          </>
          <>
            <div className={"w-60 " + styles.divider} />
            <Link to="/profile/availability">
              <span className={styles.labelButton + " " + styles.colorDarkGrey}>
                Disponibilités
              </span>
            </Link>
          </>
          <div className={"w-60 " + styles.divider} />
          <Link to="/profile/preferences">
            <span className={styles.labelButton + " " + styles.colorDarkGrey}>
              Préférences
            </span>
          </Link>
          {hasRole(appContext.state.user?.roles, UserRoles.ADMIN) && (
            <>
              <div className={"w-60 " + styles.divider} />
              <Link to="/admin/users/waiting">
                <span
                  className={
                    styles.labelButton +
                    " primary-blue-text " +
                    styles.adminAccess
                  }
                >
                  Accès administrateur
                </span>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};
