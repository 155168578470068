import { errorToast } from "../utils/Toast";

export enum ErrorType {
  USER_ALREADY_CREATED = "USER_ALREADY_CREATED",
  PARTICIPANT_ALREADY_ADDED = "PARTICIPANT_ALREADY_ADDED",
  ACCOUNT_ALREADY_CHECKED = "ACCOUNT_ALREADY_CHECKED",
  INVALID_CODE = "INVALID_CODE",
  STILL_VALID_CODE = "STILL_VALID_CODE",
  INVALID_CODE_TOO_MANY_TRIES = "INVALID_CODE_TOO_MANY_TRIES",
  MISSING_MANDATORY_FIELDS = "MISSING_MANDATORY_FIELDS",
  LINK_IS_DEAD = "LINK_IS_DEAD",
  ERR_USER_IS_PARTICIPANT_OF_OPINION_REQUEST = "ERR_USER_IS_PARTICIPANT_OF_OPINION_REQUEST",
  UPDATE_NOT_ALLOWED = "UPDATE_NOT_ALLOWED",
}

export class ErrorResponse extends Error {
  code: ErrorType;
  payload?: string;

  constructor(code: ErrorType, message: string, payload?: string) {
    super();
    this.code = code;
    this.message = message;
    this.payload = payload;
  }
}

export const showNewSelfieAskedErrorMessage = (
  res: Error | ErrorResponse
): void => {
  //TODO Re-checker la gestion des erreurs pour renvoyer les bons toasts (refacto à faire dans ApiClient.ts pour gérer les messages d'erreurs dans les composants et pas dans le fichier)
  if (res.message === "User is not validated") {
    errorToast("Vous devez être validé pour effectuer cette action");
  } else if (
    res instanceof ErrorResponse &&
    res.code === ErrorType.UPDATE_NOT_ALLOWED
  ) {
    errorToast("Vous n'êtes pas autorisé à demander la reprise de photo");
  } else if (res.message === "user is not an admin") {
    errorToast("Vous n'avez pas les droits pour effectuer cette action");
  } else {
    errorToast("Erreur lors de la demande de reprise de photo");
  }
};
