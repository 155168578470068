import React from "react";
import "../../../App.css";
import OpinionRequest, {
  OpinionRequestFormPage,
} from "../../../domain/OpinionRequest";
import { requestPrimaryTypeTranslate } from "../../../domain/Subject/RequestPrimaryType.enum";
import { therapeuticEffortLimitationTranslate } from "../../../domain/Subject/TherapeuticEffortLimitation.enum";
import { isNotProd } from "../../../utils/config";
import { AttachmentSection } from "../../OpinionRequestForm/AttachmentSection";
import { DetailsRequestCard } from "../DetailsRequestCard";
import { DmpDocuments } from "./DmpDocuments";
import {
  DisplayType,
  ImagingDocumentUpload,
} from "./ImagingDocumentUpload/ImagingDocumentUpload";
import { PathologyTree } from "./PathologyTree";
import styles from "./RequestDetails.module.css";

interface Props {
  request: OpinionRequest;
  updateRequest: () => any;
}

export const RequestDetails: React.FC<Props> = (props: Props) => {
  return (
    <div className="text-left request-details-container pt-2 flex-col">
      <div className="flex-row motive-and-type-container">
        <div className={styles.subjectCard} id="request-type-detail-card">
          <DetailsRequestCard
            request={props.request}
            cardTitle={"Objet de la demande"}
            pageNumber={OpinionRequestFormPage.requestObject}
          >
            <>
              {props.request.subject?.requestType ? (
                <div className="flex-row text-bold">
                  <div className="motive-line">
                    {
                      requestPrimaryTypeTranslate[
                        props.request.subject?.requestType
                      ]
                    }
                  </div>
                </div>
              ) : (
                <div className="flex-row pl-2">
                  <div className="line-figure">
                    <div className="green-point" />
                  </div>
                  <div className="motive-line text-primary-blue">N/A</div>
                </div>
              )}
              {props.request.subject?.adviceOnPathologyDetails && (
                <PathologyTree
                  details={props.request.subject.adviceOnPathologyDetails}
                />
              )}
              {props.request.subject?.therapeuticEffortLimitationDetails
                ?.options && (
                <>
                  {props.request.subject.therapeuticEffortLimitationDetails.options.map(
                    (value) => (
                      <div className="flex-row pl-2" key={value}>
                        <div className="line-figure">
                          <div className="green-point" />
                        </div>
                        <div className="motive-line">
                          {therapeuticEffortLimitationTranslate[value]}
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </>
          </DetailsRequestCard>
        </div>
        <div className={styles.motiveCard}>
          <DetailsRequestCard
            request={props.request}
            cardTitle={"Demande"}
            pageNumber={OpinionRequestFormPage.requestMotive}
          >
            <div className="flex-row pl-2">
              <div className="motive-line">{props.request?.motive}</div>
            </div>
          </DetailsRequestCard>
        </div>
      </div>
      <ImagingDocumentUpload
        request={props.request}
        updateRequest={props.updateRequest}
        displayType={DisplayType.CARD_DISPLAY}
      />
      <AttachmentSection
        id="input-electroencephalogramme"
        mainTitle="Électroencéphalogramme"
        subTitle="Ajouter un EEG (au format .edf)"
        fileTypeFilter=".edf"
        attachments={props.request?.attachments?.filter(
          (attachments) => attachments.Filename.slice(-4) === ".edf"
        )}
        request={props.request}
        displayType={DisplayType.CARD_DISPLAY}
        updateRequest={props.updateRequest}
      />
      <AttachmentSection
        id="input-documentation"
        mainTitle="Documentation"
        request={props.request}
        attachments={props.request?.attachments?.filter(
          (attachments) => attachments.Filename.slice(-4) !== ".edf"
        )}
        updateRequest={props.updateRequest}
        displayType={DisplayType.CARD_DISPLAY}
      />
      {isNotProd() && (
        <DetailsRequestCard cardTitle={"Dossier Médical Partagé"}>
          <DmpDocuments
            patientBirthdate={`${props.request.patient.birthdate.year}-${props.request.patient.birthdate.month}-${props.request.patient.birthdate.day}`}
            patientIns={props.request.patient.socialSecurityNumber}
            request={props.request}
          />
        </DetailsRequestCard>
      )}
    </div>
  );
};
