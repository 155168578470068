import React from "react";
import { getMinimizedTitleLabel, User } from "../../domain/User";
import history from "../../utils/history";
import styles from "./UserCard.module.css";
import { UserStatus } from "../../domain/UserStatus";
import { Label } from "../Shared/Label";
import { UserRoles } from "../../domain/UserRoles";
import { hasStatus } from "../../utils/authorization";

interface Props {
  user: User;
}

export const UserCard: React.FC<Props> = (props: Props) => {
  return (
    <button
      className={
        "user-card user-btn " +
        (props.user.status === UserStatus.DELETED ? styles.deletedUser : "")
      }
      onClick={(): void => {
        if (props.user.status !== UserStatus.DELETED) {
          history.push(`/admin/users/${props.user.id}/view`);
        }
      }}
    >
      <div className={styles.figureStatusContainer}>
        <div className="user-card-name text-left">
          {props.user.firstName !== "" && props.user.lastName !== ""
            ? getMinimizedTitleLabel(props.user.professionalTitle) +
              " " +
              props.user.firstName +
              " " +
              props.user.lastName
            : props.user.email}
        </div>
        {hasStatus(props.user.status, UserStatus.CREATED) && (
          <Label color="created" type="userStatus">
            Nouvel utilisateur
          </Label>
        )}
        {hasStatus(props.user.status, UserStatus.REGISTERED) && (
          <Label color="registered" type="userStatus">
            Utilisateur à valider
          </Label>
        )}
        {hasStatus(props.user.status, UserStatus.VALIDATED) && (
          <Label color="validated" type="userStatus">
            Utilisateur validé
          </Label>
        )}
        {hasStatus(props.user.status, UserStatus.DELETED) && (
          <Label color="deleted" type="userStatus">
            Utilisateur supprimé
          </Label>
        )}
      </div>
      <div className="user-profession text-left">
        {props.user.profession} {props.user.specialty}
      </div>
      <div className={"row " + styles.userRoles}>
        {props.user.roles?.includes(UserRoles.ADMIN) && (
          <Label color="admin" type="userRole">
            Administrateur
          </Label>
        )}
        {props.user.roles?.includes(UserRoles.GLOBAL_DISPATCHER) && (
          <Label color="dispatcher" type="userRole">
            Régulateur
          </Label>
        )}
      </div>
    </button>
  );
};
