import React from "react";

interface Props {
  firstName: string;
  lastName: string;
  fontSize: string;
}

export const Figure: React.FC<Props> = (props: Props) => {
  return (
    <figure
      className={"avatar " + props.fontSize}
      data-initial={props.firstName.charAt(0) + props.lastName.charAt(0)}
    />
  );
};
