import { send } from "./ApiClient";

export interface VerificationMessage {
  code: string;
}

export interface RequestResponse {
  Status: string;
}

export async function getConfirmCode(): Promise<RequestResponse | Error> {
  return send(`/get-confirm-code`, "GET");
}

export async function confirmCode(
  verifMessage: VerificationMessage
): Promise<RequestResponse | Error> {
  return send(`/confirm-code`, "POST", verifMessage);
}
