import React, { useEffect, useMemo, useRef, useState } from "react";
import { errorToast } from "../utils/Toast";
import { searchFacility } from "../services/FacilityService";
import AsyncSelect from "react-select/async";
import { FilterIcon } from "./Shared/FilterIcon";
import { ActionMeta, components, InputActionMeta } from "react-select";
import { Facility } from "../domain/Facility";
import { debouncePromise } from "../utils/debounce";
import "./SearchFacilities.css";

interface Props {
  onSelect?: (value: any) => any;
  autoFocus: boolean;
  className?: string;
}

const LoadingMessage: any = (lmProps: any) => (
  <components.LoadingMessage {...lmProps}>
    Recherche en cours ...
  </components.LoadingMessage>
);

const NoOptionsMessage: any = (props: any) => (
  <components.NoOptionsMessage {...props}>
    Aucun résultat
  </components.NoOptionsMessage>
);

const Option: any = (oProps: any) => {
  const facility: Facility = oProps.data;
  return (
    <components.Option {...oProps}>
      <div>
        <div>{facility.name}</div>
        <div style={{ color: "grey" }}>{facility.addressRoutingLabel}</div>
      </div>
    </components.Option>
  );
};

const ValueContainer: any = ({ children, ...props }: any) => (
  <>
    <span className={"pl-2 pt-2"}>
      <FilterIcon />
    </span>
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </>
);

const SingleValue: any = (svProps: any) => {
  const facility: Facility = svProps.data;
  return (
    <components.SingleValue {...svProps}>
      <div>{facility.name}</div>
    </components.SingleValue>
  );
};

export const SearchFacilities: React.FC<Props> = (props: Props) => {
  const [leftInputValue, setLeftInputValue] = useState<string>("");
  const [rightInputValue, setRightInputValue] = useState<string>("");
  const [defaultOptions, setDefaultOptions] = useState<any>([]);
  const [isLeftInputFocused, setIsLeftInputFocused] = useState<any>(
    props.autoFocus
  );
  const [isRightInputFocused, setIsRightInputFocused] = useState<any>(false);
  const [pageOfResult, setPageOfResult] = useState(0);
  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const rightSelectRef = useRef(null);
  const leftSelectRef = useRef(null);

  const resetComponent = () => {
    setLeftInputValue("");
    setRightInputValue("");
    setDefaultOptions([]);
    setIsLeftInputFocused(false);
    setIsRightInputFocused(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    rightSelectRef.current?.blur();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    leftSelectRef.current?.blur();
  };

  const search = async (
    name: string,
    area: string,
    page?: number
  ): Promise<any> => {
    const res = await searchFacility(name, area, page ? page : 0);
    if (res instanceof Error) {
      errorToast("Une erreur est survenue lors de la recherche");
    } else {
      if (page && page > 0) {
        let menuList = document.getElementsByClassName("menuList")[0];
        const scrollPosition = menuList.scrollHeight - menuList.clientHeight;
        setDefaultOptions((previousState: any) => {
          previousState.push(...res);
          return previousState;
        });
        setIsLoadingResult(false);
        menuList = document.getElementsByClassName("menuList")[0];
        if (menuList) {
          menuList.scrollTo(0, scrollPosition);
        }
      } else {
        setDefaultOptions(res);
      }
    }
    return res;
  };

  enum Position {
    Left,
    Right,
  }

  const debouncedPromiseSearch = useMemo(
    () =>
      debouncePromise(
        (name: string, area: string, page?: number) => search(name, area, page),
        500
      ),
    []
  );

  const isOneInputFocused = isLeftInputFocused || isRightInputFocused;

  const handleInputChange = (
    value: string,
    action: InputActionMeta,
    position: Position
  ) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      position === Position.Left
        ? setLeftInputValue(value)
        : setRightInputValue(value);
    }
    if (action.action === "input-change" && value === "") {
      position === Position.Left
        ? debouncedPromiseSearch(value, rightInputValue)
        : debouncedPromiseSearch(leftInputValue, value);
    }
    setPageOfResult(0);
  };

  const handleFocus = (position: Position) => {
    position === Position.Left
      ? setIsLeftInputFocused(true)
      : setIsRightInputFocused(true);
    debouncedPromiseSearch(leftInputValue, rightInputValue);
  };

  const getAsyncSelectPlaceholder = (position: Position) =>
    position === Position.Left
      ? "Nom ou numéro finess..."
      : "Ville ou code postal...";

  const getAsyncSelectId = (position: Position) =>
    "facilities-searchbar" + (position === Position.Left ? "-left" : "-right");

  const getAsyncSelectRef = (position: Position) =>
    position === Position.Left ? leftSelectRef : rightSelectRef;

  const isAsyncSelectMenuOpen = (position: Position) =>
    position === Position.Left ? isLeftInputFocused : isRightInputFocused;

  const handleOnBlur = (position: Position) => {
    position === Position.Left
      ? setIsLeftInputFocused(false)
      : setIsRightInputFocused(false);
  };

  const handleOnChange = (value: any, e: ActionMeta<any>): any => {
    if (e.action === "select-option" && props.onSelect) {
      props.onSelect(value);
      resetComponent();
    }
  };

  const handleLoadOptions = (inputValue: string, position: Position) =>
    position === Position.Left
      ? debouncedPromiseSearch(inputValue, rightInputValue)
      : debouncedPromiseSearch(leftInputValue, inputValue);

  const getInputValue = (position: Position) =>
    position === Position.Left ? leftInputValue : rightInputValue;

  const getAutoFocus = (position: Position) =>
    position === Position.Left ? props.autoFocus : false;

  const changePageOfResult = () => {
    setIsLoadingResult(true);
    setPageOfResult(pageOfResult + 1);
  };

  const MenuList = (props: any) => {
    return (
      <components.MenuList className="menuList" {...props}>
        {props.children}
        {defaultOptions &&
          defaultOptions.length > 0 &&
          defaultOptions.length % 20 === 0 &&
          (isLoadingResult ? (
            <button
              disabled={true}
              id={"moreResultButton"}
              style={{ width: "100%", height: "3rem" }}
              className="btn btn-sm neuro-icons-btn"
            >
              Recherche en cours...
            </button>
          ) : (
            <button
              id={"moreResultButton"}
              style={{ width: "100%", height: "3rem" }}
              className="btn btn-sm neuro-icons-btn"
              title={"Voir plus"}
              onClick={changePageOfResult}
            >
              Voir plus <i className="icon icon-plus" />
            </button>
          ))}
      </components.MenuList>
    );
  };

  useEffect(() => {
    debouncedPromiseSearch(leftInputValue, rightInputValue, pageOfResult);

    const menuList = document.getElementsByClassName("menuList")[0];
    if (menuList) {
      menuList.scrollTo(0, menuList.scrollHeight);
    }
  }, [pageOfResult]);

  const renderAsyncSelect = (position: Position) => {
    return (
      <AsyncSelect
        value={null}
        ref={() => getAsyncSelectRef(position)}
        onBlur={() => handleOnBlur(position)}
        menuIsOpen={isAsyncSelectMenuOpen(position)}
        className={"w-50"}
        tabSelectsValue={false}
        loadOptions={(inputValue) => handleLoadOptions(inputValue, position)}
        isClearable
        placeholder={getAsyncSelectPlaceholder(position)}
        id={getAsyncSelectId(position)}
        key={getAsyncSelectId(position)}
        components={{
          LoadingMessage,
          NoOptionsMessage,
          Option,
          SingleValue,
          DropdownIndicator: null,
          ValueContainer,
          MenuList,
        }}
        onFocus={() => handleFocus(position)}
        defaultOptions={defaultOptions}
        autoFocus={getAutoFocus(position)}
        onInputChange={(value, action) =>
          handleInputChange(value, action, position)
        }
        inputValue={getInputValue(position)}
        onChange={(value, e) => handleOnChange(value, e)}
        styles={{
          control: (providedControlStyle): Record<string, any> => {
            const controlStyle = {
              ...providedControlStyle,
              border: isOneInputFocused && "solid 2px var(--primary-blue)",
              boxShadow: isOneInputFocused && "var(--primary-blue)",
              display: "flex",
              ":hover": {
                border: isOneInputFocused && "solid 2px var(--primary-blue)",
                boxShadow: isOneInputFocused && "var(--primary-blue)",
              },
            };

            if (position === Position.Left) {
              return {
                ...controlStyle,
                borderRadius: "6px 0px 0px 6px",
                borderRight: "0px",
                ":hover": { ...controlStyle[":hover"], borderRight: "0px" },
              };
            } else {
              return {
                ...controlStyle,
                borderRadius: "0px 6px 6px 0px",
              };
            }
          },
          option: (providedOptionStyle, optionState): Record<string, any> => ({
            ...providedOptionStyle,
            backgroundColor: optionState.isFocused
              ? "var(--secondary-blue)"
              : "white",
            ":hover": {
              backgroundColor: "var(--secondary-blue)",
            },
            color: "black",
            whiteSpace: "pre-wrap",
            textAlign: "left",
          }),
          singleValue: (): Record<string, any> => ({
            width: "100%",
          }),
          menu: (providedMenuStyle): Record<string, any> => {
            let menuStyle = {
              ...providedMenuStyle,
              width: "200%",
            };
            if (position === Position.Right) {
              menuStyle = { ...menuStyle, transform: "translateX(-50%)" };
            }
            return menuStyle;
          },
        }}
      />
    );
  };

  return (
    <div className={"row"}>
      {[renderAsyncSelect(Position.Left), renderAsyncSelect(Position.Right)]}
    </div>
  );
};
