import React from "react";
import { CheckBox } from "./CheckBox";
import styles from "./SubjectForm.module.css";

export interface CheckBoxOption<T> {
  label: string;
  value: T;
  className?: string;
}

interface Props<T> {
  readOnly?: boolean;
  options: CheckBoxOption<T>[];
  onChange: (value: T) => any;
  value: T[];
  labelClassName?: string;
  hideCheck?: boolean;
}

export const CheckBoxGroup = <T,>(props: Props<T>) => (
  <>
    {props.options.map((item: CheckBoxOption<T>, index: number) => (
      <CheckBox
        key={index}
        label={item.label}
        onChange={() => props.onChange(item.value)}
        disabled={props.readOnly}
        checked={props.value?.includes(item.value)}
        labelClassName={[
          styles.subOptionsChecked,
          item.className,
          props.labelClassName,
        ].join(" ")}
        hideCheck={props.hideCheck}
      />
    ))}
  </>
);
