import React from "react";
import { BackButton } from "./BackButton";

interface HeaderProps {
  title: string;
  backButtonRedirectsTo?: string;
}

export const PageHeaderWithTitle: React.FC<HeaderProps> = (
  props: HeaderProps
) => {
  return (
    <div className={"row text-centered separator"}>
      <BackButton redirectsTo={props.backButtonRedirectsTo} />
      <div className="m-auto page-title">{props.title}</div>
    </div>
  );
};
