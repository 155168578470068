import config from "./config";

interface LoginResult {
  auth_url: string;
}

interface LogoutResult {
  logout_url: string;
}

export async function initLogin(
  redirectUrl?: string,
  queryParam = ""
): Promise<void> {
  const redirectQueryParam = redirectUrl ? `?redirect=${redirectUrl}` : "";
  await fetch(`${config.apiUrl}/login${redirectQueryParam}`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then(
      (result: LoginResult) => {
        window.location.href = result.auth_url + queryParam;
      },
      (error) => {
        console.error("login error", error);
      }
    );
}

export async function initLoginWithPsConnect(): Promise<void> {
  return initLogin("", "&kc_idp_hint=psconnect");
}

export async function initRegister(
  redirectUrl?: string,
  queryParam = ""
): Promise<void> {
  const redirectQueryParam = redirectUrl ? `?redirect=${redirectUrl}` : "";
  await fetch(`${config.apiUrl}/register${redirectQueryParam}`)
    .then((res) => res.json())
    .then(
      (result: LoginResult) => {
        window.location.href = result.auth_url + queryParam;
      },
      (error) => {
        console.error("login error", error);
      }
    );
}

function cleanupAfterLogout(result: LogoutResult) {
  window.location.href = result.logout_url;
  localStorage.removeItem("chosenRole");
  localStorage.removeItem("selectedTab");
}

export async function initLogout(): Promise<void> {
  await fetch(`${config.apiUrl}/logout`, { credentials: "include" })
    .then((res) => res.json())
    .then(
      (result: LogoutResult) => {
        cleanupAfterLogout(result);
      },
      (error) => {
        console.error("logout error", error);
      }
    );
}

// logout unauthenticated user
export async function forceLogout(): Promise<void> {
  await fetch(`${config.apiUrl}/forceLogout`)
    .then((res) => res.json())
    .then(
      (result: LogoutResult) => {
        cleanupAfterLogout(result);
      },
      (error) => {
        console.error("logout error", error);
      }
    );
}
