import React from "react";

interface Props {
  titleButtonRight?: string;
  onClickButtonRight?: (event: any) => any;
  disableButtonRight?: boolean;
  titleButtonLeft?: string;
  onClickButtonLeft?: (event: any) => any;
}

export const ModalFooter: React.FC<Props> = (props: Props) => {
  return (
    <div className="row space-between-content ml-4 mr-4">
      <button
        className="btn rectangular-btn close-btn"
        id="cancel-btn"
        onClick={props.onClickButtonLeft}
      >
        {props.titleButtonLeft}
      </button>
      <button
        className="btn rectangular-btn neuro-btn"
        id="validate-button"
        disabled={props.disableButtonRight}
        onClick={props.onClickButtonRight}
      >
        {props.titleButtonRight}
      </button>
    </div>
  );
};
