import React from "react";
import { Controller } from "react-hook-form";
import { PatientBirthPlaceForm } from "./PatientBirthPlaceForm";
import { Patient } from "../../domain/OpinionRequest";

interface Props {
  register: any;
  control: any;
  readOnly: any;
  isRequired: boolean;
  patientInfos: () => Patient;
}

export const PatientDetailsForm: React.FC<Props> = (props: Props) => {
  const register = props.register;
  const readOnly = props.readOnly;

  const inputCheck = (object: any): any => {
    if (object.target.value !== "") {
      if (isNaN(Number(object.target.value[object.target.value.length - 1]))) {
        object.target.value = object.target.value.slice(0, -1);
      }
    }
  };

  const inputChange = (object: any, inputName: string): any => {
    inputCheck(object);
    if (object.target.value.length === 2) {
      if (inputName === "day") {
        document.getElementById("birthdate-month")?.focus();
      } else {
        document.getElementById("birthdate-year")?.focus();
      }
    }
  };

  return (
    <>
      <div className="form-horizontal pt-0">
        <div className="form-group flex-col">
          <label className="form-label" htmlFor="patient-name-input">
            Nom
          </label>
          <input
            className="form-input w-50"
            type="text"
            id="patient-lastname-input"
            placeholder="Ex: Dupond"
            {...register("patient.lastName")}
            readOnly={readOnly}
          />
        </div>
        <div className="form-group flex-col">
          <label className="form-label" htmlFor="input-example-1">
            Prénom
          </label>
          <input
            className="form-input w-50"
            type="text"
            id="patient-firstname-input"
            placeholder="Ex: Pierre"
            {...register("patient.firstName")}
            readOnly={readOnly}
          />
        </div>
        <div className="form-group flex-col">
          <label className="form-label" htmlFor="input-example-1">
            Identifiant local
          </label>
          <input
            className="form-input w-50"
            type="text"
            id="patient-local-identifier-input"
            placeholder="Numéro de dossier, ..."
            {...register("patient.localIdentifier")}
            readOnly={readOnly}
          />
        </div>
        <div className="form-group mt-2 flex-col">
          <label className="form-label pb-0">Sexe</label>
          <div className="row space-around-content">
            <label className="form-radio mb-0 mt-0">
              <input
                type="radio"
                value={"MALE"}
                {...register("patient.sex")}
                disabled={readOnly}
              />
              <i className="form-icon" id="male-checkbox" /> Homme
            </label>
            <label className="form-radio mb-0 mt-0">
              <input
                type="radio"
                value={"FEMALE"}
                {...register("patient.sex")}
                disabled={readOnly}
              />
              <i className="form-icon" id="female-checkbox" /> Femme
            </label>
          </div>
        </div>
        <div className="form-group flex-col">
          <div className="row w-100">
            <label className="form-label">Date de naissance</label>
          </div>
          <div className="columns form-birthday no-margin">
            <div className="dob-column">
              <input
                className="form-input text-centered"
                type="text"
                id="birthdate-day"
                placeholder="jj"
                pattern="([1-9])|(0[1-9])|(1[0-9])|2[0-9]|3[0-1]"
                onInput={(e: any): void => {
                  inputChange(e, "day");
                }}
                maxLength={2}
                title="Entrer un jour entre 1 et 31"
                {...register("patient.birthdate.day")}
                readOnly={readOnly}
              />
            </div>
            <div className="dob-column dob-column-separator">/</div>
            <div className="dob-column">
              <input
                className="form-input text-centered"
                type="text"
                id="birthdate-month"
                placeholder="mm"
                pattern="([1-9])|(0[1-9])|1[0-2]"
                onInput={(e: any): void => {
                  inputChange(e, "month");
                }}
                maxLength={2}
                title="Entrer un mois entre 1 et 12"
                {...register("patient.birthdate.month")}
                readOnly={readOnly}
              />
            </div>
            <div className="dob-column dob-column-separator">/</div>
            <div className="dob-column">
              <input
                className={`form-input text-centered`}
                type="text"
                id="birthdate-year"
                placeholder="aaaa"
                pattern="(19|20)\d{2}"
                onInput={inputCheck}
                maxLength={4}
                title="Entrer une année valide"
                {...register("patient.birthdate.year")}
                readOnly={readOnly}
                required={props.isRequired}
              />
            </div>
            <span className="error-msg text-large ml-2">*</span>
          </div>
          {props.isRequired && (
            <div className="error-msg mt-2">
              Veuillez entrer l&apos;année de naissance du patient avant de
              continuer.
            </div>
          )}
        </div>
        <Controller
          control={props.control}
          name="patient.birthPlace"
          shouldUnregister={true}
          render={({ field: { onChange } }) => (
            <PatientBirthPlaceForm
              onChange={onChange}
              patientBirthPlace={props.patientInfos().birthPlace}
            />
          )}
        />
        <div className="form-group">
          <label
            className="form-label"
            htmlFor="patient-social-security-number-input"
          >
            Numéro de sécurité sociale
          </label>
          <input
            className="form-input w-90"
            type="text"
            id="patient-social-security-number-input"
            placeholder="Ex: 2913948903903"
            {...register("patient.socialSecurityNumber")}
            readOnly={readOnly}
          />
        </div>
      </div>
    </>
  );
};
