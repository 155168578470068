import { format, parseISO } from "date-fns";
import { DataImport } from "../domain/DataImport";

export function getDate(date: any, dateFormat: string): string {
  return format(new Date(date), dateFormat);
}

export function formatDate(dateString: string): string {
  try {
    const date = parseISO(dateString);
    return (
      date.getDate() +
      " " +
      getMonthName(date.getMonth()) +
      " " +
      date.getFullYear()
    );
  } catch (e) {
    console.error("could not parse or format date", e);
    return "";
  }
}

export const getCurrentYear = (): number => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export const getStringDay = (dayOfTheWeek: number): string => {
  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  return days[dayOfTheWeek - 1];
};

export const getMonthName = (month: number): string => {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  return months[month];
};

export function checkHasExpired(dataImport?: DataImport): boolean {
  if (!dataImport || !dataImport.lastUpdateStart || !dataImport.lastUpdateEnd)
    return true;
  const startDate = new Date(dataImport.lastUpdateStart);
  const endDate = new Date(dataImport.lastUpdateEnd);
  if (startDate < endDate) {
    return false;
  } else {
    // 86400000 is the number of miliseconds in one day
    return new Date(Date.now()).getTime() - startDate.getTime() > 86400000;
  }
}

export const today = (): string => {
  const date = new Date();
  return (
    getStringDay(date.getDay()) +
    " " +
    date.getDate() +
    " " +
    getMonthName(date.getMonth())
  );
};
