import { errorToast } from "../utils/Toast";

export interface DmpInfo {
  // Format YYYYMMDD
  birthday: string;
  ins: string;
}

export interface DmpDocument {
  type: string; //not used for now, might need it
  authors: DmpDocumentAuthors[];
  title: string;
  description: string;
  uniqueId: string;
  creationDate: string;
  submissionDate: string;
}

export interface DmpDocumentAuthors {
  given: string; //prenom
  name: string; //nom
  professionDescription: string;
  specialityDescription: string;
}

export interface DmpDocumentContent {
  fileContentInBase64: string;
  documentFormat: DmpDocumentFormat; //TODO : see after meeting, isn't used yet
  documentFormatCode: string; //TODO : see after meeting, isn't used yet
}

export interface HealthcareSettings {
  code: string;
  codeSystem: string;
  displayName: string;
}

export enum DmpError {
  /*Delete all error type from 1 to 5 (=IcanopeeError*/
  // Invalid CPS card, or unavailable
  SERVER_ERROR = "SERVER_ERROR",
  SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
  CARD_ERROR = "CARD_ERROR",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  MISSING_RIGHTS = "MISSING_RIGHTS",
  DMP_ACCESS_FORBIDDEN = "DMP_ACCESS_FORBIDDEN",
  NO_CARD_READER_INSTALLED = 5,
  NOT_ENOUGH_DIGIT_IN_CODE = 6,
  COMPONENT_INITIAL_CODE = 7,
}

export function showDmpError(e: DmpError) {
  console.error("DMP service error", e);
  if (e === DmpError.CARD_ERROR) {
    errorToast("Erreur de lecture de la CPS");
  } else if (e === DmpError.SERVICE_UNAVAILABLE) {
    errorToast("Service indisponible, veuillez réessayer ultérieurement");
  } else if (e === DmpError.MISSING_RIGHTS) {
    errorToast(
      "Vous ne disposez pas des droits suffisants pour accéder à ce service"
    );
  } else if (e === DmpError.SERVER_ERROR) {
    errorToast(
      "Service de lecture de CPS non accessible sur votre environnement"
    );
  } else if (e === DmpError.DMP_ACCESS_FORBIDDEN) {
    errorToast("Vous n'avez pas les droits pour effectuer cette action");
  } else if (e === DmpError.COMPONENT_INITIAL_CODE) {
    // Error encountered when not enough digits in the CPS pin code
  } else {
    errorToast("Erreur technique, veuillez réessayer ultérieurement");
  }
}

enum DmpDocumentFormat {
  PlainText = 1,
  RtfText,
  JpgImage,
  TiffImage,
  PdfApplication,
  // Structured documents.
  VsmDocument,
  CrBiologie,
  DluDocument = 40, // 'DLU - Admission en EHPAD
  DluFludtDocument, // 'DLU - Fiche de liaison d'urgence - transfert de l'EHPAD vers les urgences'
  DluFludrDocument, // 'DLU - Fiche de liaison d'urgence - retour des urgences vers l'EHPAD'
}

//TODO: find new name
export function formatDateWithSlash(date: string): string {
  date = date.slice(0, 8);
  return date.replace(/(\d{4})(\d{2})(\d{2})/g, "$3/$2/$1");
}

//convert YYYYmmDD to Date
export function convertStringToDate(dateStr: string) {
  const year = Number(dateStr.slice(0, 4));
  const month = Number(dateStr.slice(4, 6));
  const day = Number(dateStr.slice(6, 8));

  return new Date(year, month, day);
}

const signatures = new Map();
signatures
  .set("JVBERi0", "application/pdf")
  .set("R0lGODdh", "image/gif")
  .set("R0lGODlh", "image/gif")
  .set("iVBORw0KGgo", "image/png")
  .set("/9j/", "image/jpg");

export function detectMimeType(b64: string): string {
  for (const [key, value] of Array.from(signatures.entries())) {
    if (b64.indexOf(key) === 0) {
      return value;
    }
  }
  return "";
}

/*export const getFormatFileLabel = (formatNumber: number): string => {
  switch (formatNumber) {
    case 1:
      return "text/plain";
    case 2:
      return "text/rtf";
    case 3:
      return "image/jpeg";
    case 4:
      return "image/tiff";
    case 5:
      return "application/pdf";
    case 6:
      return ""; //VsmDocument
    case 7:
      return ""; //Crbiologie
    case 40:
      return ""; //DluDocument
    case 41:
      return ""; //DluFludtDocument
    case 42:
      return ""; //DluFludrDocument
    default:
      return "";
  }
};*/

export interface DmpService {
  getPatientDocuments(
    patient: DmpInfo,
    cpsPinCode: string
  ): Promise<DmpDocument[]>;

  getDocumentByUniqueId(
    patient: DmpInfo,
    documentUniqueId: string,
    cpsPinCode: string
  ): Promise<DmpDocumentContent>;

  uploadPatientDocuments(
    patient: DmpInfo,
    fileInBase64: string,
    documentDescription: string,
    healthcareSetting: string,
    cpsPinCode: string
  ): Promise<string>;

  getHealthcareSettings(cpsPinCode: string): Promise<HealthcareSettings[]>;
}
