import React from "react";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import { GeneralTermsOfUseText } from "./GeneralTermsOfUseText";

export const GeneralTermsOfUse: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle
        title={"Conditions Générales d'Utilisation"}
        backButtonRedirectsTo={`/`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <GeneralTermsOfUseText />
        </div>
      </div>
    </>
  );
};
