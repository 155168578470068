import React from "react";
import RequestEEGReason from "../../../domain/EEGReason/RequestEEGReason.enum";
import { EEGReasonSelect } from "../EEGReasonSelect";
import { EegReason } from "../../../domain/EEGReason/EegReason";

interface Props {
  selectedEEGReason: EegReason;
  onChange: (newState: EegReason) => any;
}

export interface LabelValue<T = string> {
  value: T;
  label: string;
}

type Ennum<T> = { [s: string]: T };
export const enumAndTranslationToItemElement = <T extends keyof any>(
  ennum: Ennum<T>,
  translationMap: Record<T, string>
): LabelValue<T>[] => {
  return Object.values(ennum).map((element: T) => ({
    value: element,
    label: translationMap[element],
  }));
};

export const EEGForm: React.FC<Props> = (props: Props) => {
  const onSelectEEGReason = (eegReason: RequestEEGReason | null): void => {
    props.onChange({ ...props.selectedEEGReason, requestEEGType: eegReason });
  };

  return (
    <div>
      <div className="form-group">
        <h4 className="form-title">Quel est le motif de lecture EEG ?</h4>
        <EEGReasonSelect
          value={props.selectedEEGReason.requestEEGType}
          onChange={onSelectEEGReason}
        />
      </div>
    </div>
  );
};
