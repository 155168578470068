import {
  getMinimizedTitleLabel,
  HealthProfessionalUser,
  User,
} from "../../domain/User";
import React, { useEffect, useState } from "react";
import { getUser } from "../../services/UserService";
import SpecialtyIcon from "../../assets/specialty-icon.svg";
import SubSpecialtyIcon from "../../assets/subSpecialty-icon.svg";
import AvailabilityIcon from "../../assets/availability-icon.svg";
import LocationIcon from "../../assets/location-icon.svg";
import styles from "./ExpertCard.module.css";
import {
  dayToString,
  getSortedAvailabilities,
} from "../../domain/Availability";
import { Figure } from "../Shared/Figure";
import { expertiseLevelToString } from "../../domain/ExpertiseLevels";
import { Facility } from "../../domain/Facility";

interface Props {
  expert: HealthProfessionalUser;
}

export const ExpertCard: React.FC<any> = (props: Props) => {
  const [facilities, setFacilities] = useState<Facility[]>();
  const [showAvailabilities, setShowAvailabilities] = useState<boolean>(false);
  const [expert, setExpert] = useState<User>();

  useEffect(() => {
    if (props.expert.userId != "") {
      getUser(props.expert.userId).then((res) => {
        if (res instanceof Error) {
          console.error(res);
          return;
        }
        setExpert(res);
        setFacilities(res.facilities);
      });
    } else {
      setFacilities([]);
    }
  }, [props.expert.userId]);

  const getFacilityName = (facilityId: string | undefined): any => {
    const savedFacility = facilities?.find(
      (facility) => facility.finess === facilityId
    );
    return savedFacility?.name;
  };

  return (
    <>
      <div
        className={`p-relative w-100 mb-2 ${styles.roleLabel} ${styles.expertCard}`}
        onClick={(event): void => {
          event.preventDefault();
        }}
      >
        <div className={styles.legend}>EXPERT</div>
        <div className={`card-body ${styles.cardBody}`}>
          <div className="container">
            <div className="flex-row mb-1 mt-2 ">
              <div className={`${styles.figure} ${styles.figureSize}`}>
                <Figure
                  firstName={props.expert.firstName}
                  lastName={props.expert.lastName}
                  fontSize={"text-medium"}
                />
              </div>
              <div className={styles.expertHeader}>
                <div className="card-patient">
                  {`${getMinimizedTitleLabel(props.expert.professionalTitle)} ${
                    props.expert.firstName
                  } ${props.expert.lastName}`}
                </div>
                <div className={"text-bold " + styles.profession}>
                  {props.expert.profession}
                </div>
              </div>
            </div>
            <div className="row w-100">
              <img
                className={styles.icons}
                alt="icon-specialite"
                src={SpecialtyIcon}
              />
              <label className="text-bold mr-2">Spécialité</label>
              {!props.expert.specialty && <div>Non renseignée</div>}
            </div>
            <div>{props.expert.specialty}</div>
            <div className={styles.dividerLine} />
            <div className="row w-100">
              <img
                className={styles.icons}
                alt="icone-sous-specialites"
                src={SubSpecialtyIcon}
              />
              <label className="text-bold mr-2">Sous-spécialités</label>
              {expert?.subSpecialties?.length === 0 && (
                <div>Non renseignées</div>
              )}
            </div>
            {expert?.subSpecialties?.map((subSpeciality, index) => {
              return (
                <div className={"row w-100"} key={index}>
                  <div className={`${styles.notShrink} ${styles.subSpe}`}>
                    {subSpeciality.name}
                  </div>
                  <div className={styles.line} />
                  <span className={`${styles.dot} ${styles.notShrink}`} />
                  <div className={`${styles.notShrink} ${styles.subSpeLevel}`}>
                    Niveau{" "}
                    {expertiseLevelToString(subSpeciality.expertiseLevel)}
                  </div>
                </div>
              );
            })}
            <div className={styles.dividerLine} />
            {showAvailabilities && (
              <div className={styles.content}>
                <div className="row w-100">
                  <img
                    className={styles.icons}
                    alt="icone-disponibilites"
                    src={AvailabilityIcon}
                  />
                  <label className="text-bold mr-2">
                    Disponibilités et localisation
                  </label>
                  {!expert?.calendar?.availabilities && (
                    <div>Non renseignées</div>
                  )}
                </div>
                {expert?.calendar?.availabilities &&
                  getSortedAvailabilities(expert?.calendar?.availabilities).map(
                    (availability, index) => {
                      return (
                        <div className="row w-100" key={index}>
                          <div className="column col-4">
                            <div className="row w-100">
                              <div className="text-bold">
                                {dayToString(availability.day).slice(0, 3) +
                                  " "}
                              </div>
                              <div className={styles.hours}>
                                {`${availability.startTime}h - ${availability.endTime}h`}
                              </div>
                            </div>
                          </div>
                          <div className={styles.line} />
                          <span
                            className={`${styles.dot} ${styles.notShrink}`}
                          />
                          <div className="column col-6">
                            <div className="row w-100">
                              <img
                                className={styles.iconsNoMargin}
                                alt="icone-localisation"
                                src={LocationIcon}
                              />
                              {getFacilityName(availability.facilityId)}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            )}
            <button
              type="button"
              className={styles.collapsible}
              onClick={(event): void => {
                event.preventDefault();
                setShowAvailabilities(!showAvailabilities);
              }}
            >
              {showAvailabilities ? (
                <>
                  Voir moins
                  <i className="icon icon-arrow-up neuro-primary ml-2" />
                </>
              ) : (
                <>
                  Voir plus
                  <i className="icon icon-arrow-down neuro-primary ml-2" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
