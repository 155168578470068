import React, { useCallback, useEffect, useState } from "react";
import {
  getMinimizedTitleLabel,
  ProfessionCategory,
  HealthProfessionalUser,
} from "../../domain/User";
import { errorToast } from "../../utils/Toast";
import { searchHealthProfessionalsAndUsers } from "../../services/UserService";
import AsyncSelect from "react-select/async";
import styles from "./ReferralDoctorForm.module.css";
import { HealthPathwayContact, Participant } from "../../domain/OpinionRequest";

interface Props {
  onSelected: (newDoctor: HealthProfessionalUser) => any;
  healthPathwayContacts?: HealthPathwayContact[];
  participants?: Participant[];
  selectedHealthProfessionals?: HealthProfessionalUser[];
  isMenuRelative?: boolean;
  usersListToFilter?: HealthProfessionalUser[];
  professionFilter: ProfessionCategory;
}

export const SearchHealthProfessional: React.FC<Props> = (props: Props) => {
  const [allUsers, setAllUsers] = useState<HealthProfessionalUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<HealthProfessionalUser[]>(
    []
  );
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const getAllUsers = useCallback(
    async (query: string): Promise<HealthProfessionalUser[]> => {
      if (menuIsOpen) {
        const res = await searchHealthProfessionalsAndUsers(
          query,
          props.professionFilter,
          "",
          "",
          ""
        );
        if (res instanceof Error) {
          errorToast("Erreur lors de la récupération des médecins");
          return [];
        } else if (res === null) {
          return [];
        } else {
          return res.filter(
            (user, index, self) =>
              index === self.findIndex((t) => t.rpps === user.rpps)
          );
        }
      }
      return [];
    },
    [menuIsOpen]
  );

  useEffect(() => {
    async function setUsers() {
      const allHpUsers = await getAllUsers("");
      setAllUsers(allHpUsers);
    }

    setUsers();
  }, [getAllUsers]);

  function hasNoHealthPathwayContactType(
    healthProfessionalUser: HealthProfessionalUser
  ): boolean {
    return !props.healthPathwayContacts?.some(
      (contact: HealthPathwayContact) =>
        contact.userId === healthProfessionalUser.userId
    );
  }

  function isNotAParticipant(
    healthProfessionalUser: HealthProfessionalUser
  ): boolean {
    if (props.participants) {
      return !props.participants?.some(
        (participant) => participant.userId === healthProfessionalUser.userId
      );
    }
    return true;
  }

  function isNotAlreadySelected(
    healthProfessionalUser: HealthProfessionalUser
  ): boolean {
    if (props.selectedHealthProfessionals) {
      return !(
        props.selectedHealthProfessionals?.some(
          (dispatcher) => dispatcher.rpps === healthProfessionalUser.rpps
        ) ||
        props.selectedHealthProfessionals?.some(
          (dispatcher) => dispatcher.userId === healthProfessionalUser.userId
        )
      );
    }
    return true;
  }

  function hasNoUsersListToFilter(
    healthProfessionalUser: HealthProfessionalUser
  ): boolean {
    if (props.usersListToFilter) {
      return !props.usersListToFilter?.some(
        (user) => user.rpps === healthProfessionalUser.rpps
      );
    }
    return true;
  }

  const filterUsers = (
    users: HealthProfessionalUser[]
  ): HealthProfessionalUser[] => {
    return users.filter(
      (user) =>
        hasNoHealthPathwayContactType(user) &&
        isNotAParticipant(user) &&
        isNotAlreadySelected(user) &&
        hasNoUsersListToFilter(user)
    );
  };

  useEffect(() => {
    setFilteredUsers(filterUsers(allUsers));
  }, [
    props.healthPathwayContacts,
    props.participants,
    props.selectedHealthProfessionals,
    allUsers,
    props.usersListToFilter,
  ]);

  const search = async (
    query: string,
    callback: any
  ): Promise<HealthProfessionalUser[]> => {
    const allHPUsers = await getAllUsers(query || "");
    const filterHPUsers = filterUsers(allHPUsers);
    callback(filterHPUsers);
    return filterHPUsers;
  };

  const formatOptionLabel: any = (user: HealthProfessionalUser) => {
    return (
      <div>
        {getMinimizedTitleLabel(user.professionalTitle)}
        {user.firstName} {user.lastName}
        <span className={styles.profession}>
          {user.profession} {user.specialty}
        </span>
        {user.userId !== "" && <span className={styles.verified}> ✔</span>}
      </div>
    );
  };

  return (
    <>
      <div className="input-icons">
        <AsyncSelect<HealthProfessionalUser>
          onMenuOpen={(): any => setMenuIsOpen(true)}
          onMenuClose={(): any => setMenuIsOpen(false)}
          placeholder="Rechercher parmi les professionnels de santé"
          noOptionsMessage={(): any =>
            "Aucun professionnel de santé ne correspond à votre recherche."
          }
          loadOptions={search}
          defaultOptions={filteredUsers}
          formatOptionLabel={formatOptionLabel}
          value={null}
          onChange={(option: any): void => {
            option && props.onSelected(option);
          }}
          isClearable={true}
          styles={{
            control: (): Record<string, any> => ({
              borderRadius: "6px",
              border: "solid 2px var(--primary-blue)",
              boxShadow: "var(--primary-blue)",
              display: "flex",
            }),
            option: (provided, state): Record<string, any> => ({
              ...provided,
              backgroundColor: state.isFocused
                ? "var(--secondary-blue)"
                : "white",
              ":hover": {
                backgroundColor: "var(--secondary-blue)",
              },
              color: "black",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }),
            singleValue: (): Record<string, any> => ({
              width: "100%",
            }),
            input: (): Record<string, any> => ({
              position: "absolute",
            }),
            menu: (base): Record<string, any> => ({
              ...base,
              ...(props.isMenuRelative && {
                position: "relative",
                borderColor: "orange",
                boxShadow: "none",
              }),
            }),
          }}
        />
      </div>
    </>
  );
};
