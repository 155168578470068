import React from "react";
import styles from "./ModalDocumentationCps.module.css";

export const LinuxDocumentation: React.FC = () => {
  return (
    <div className={styles.documentationContent}>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>1</div>
        Installation du lecteur de CPS
      </div>
      <div
        className={`flex-col text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <span>
          Télécharger et extraire
          <a
            className={`${styles.downloadLink} ml-1`}
            href="https://icanopee-binary-files.cellar-c2.services.clever-cloud.com/cryptolibcps_5.1.4-2_amd64.deb"
          >
            cryptolibcps_5.1.4-2_amd64.deb
          </a>
        </span>
        <span>
          Installer cryptolib :
          <i className={`${styles.code} ml-2`}>
            sudo dpkg -i cryptolibcps_5.1.4-2_amd64.deb
          </i>
        </span>
        <span>
          Installer pcscd :
          <i className={`${styles.code} ml-2`}>sudo apt install pcscd</i>
        </span>
      </div>

      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>2</div>
        Installation du connecteur
      </div>
      <div
        className={`flex-col text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <span>
          Télécharger et extraire
          <a
            className={`${styles.downloadLink} ml-1`}
            href="https://icanopee-binary-files.cellar-c2.services.clever-cloud.com/DmpConnect-JS2-0.9.3-dev.tar.bz2"
          >
            DmpConnect-JS2-0.9.3-dev.tar.bz2
          </a>
        </span>
      </div>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>3</div>
        Lancement du lecteur de CPS
      </div>
      <div
        className={`flex-col text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <span>
          Lancer le serveur Icanopée depuis un terminal ouvert dans le dossier
          <strong className="ml-1">DmpConnect-JS2-0.9.3-MSS-INSi</strong> en
          tapant la commande suivante :
          <br />
          <i className={`${styles.code} mr-auto`}>
            LD_LIBRARY_PATH=. ./dmpconnect-js2
          </i>
        </span>
      </div>
    </div>
  );
};
