import React, { useContext, useState } from "react";
import { GeneralTermsOfUseText } from "./GeneralTermsOfUseText";
import Modal from "../Modal";
import { agreeToTermsOfUse, getProfile } from "../../services/UserService";
import { errorToast } from "../../utils/Toast";
import history from "../../utils/history";
import { ActionTypes, AppContext } from "../../utils/state";

interface Props {
  close?: () => any;
  showCheckbox: boolean;
}

export const GeneralTermsOfUseModal: React.FC<Props> = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const appContext = useContext(AppContext);

  const validateTermsOfUse = async (): Promise<void> => {
    await agreeToTermsOfUse().then((res: void | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur durant la validation des CGU");
      } else {
        getProfile().then((updatedUser) => {
          if (!(updatedUser instanceof Error)) {
            updatedUser.roles = updatedUser.roles || [];
            appContext.dispatch({
              type: ActionTypes.UserUpdated,
              payload: updatedUser,
            });
            history.push("/");
          }
        });
      }
    });
  };

  const close = (): any => {
    if (props.close !== undefined) {
      return props.close();
    } else {
      return undefined;
    }
  };

  return (
    <Modal
      show
      onClose={close}
      title={"Conditions générales d'utilisation"}
      allowToClose={false}
      footer={
        props.showCheckbox && (
          <>
            <div className="footer-line" />
            <div className="row centered-content ml-4 mr-4">
              <button
                className="btn rectangular-btn neuro-btn"
                id="validate-button"
                disabled={!checked}
                onClick={validateTermsOfUse}
              >
                Valider
              </button>
            </div>
          </>
        )
      }
    >
      <div className="pt-1 pr-2 pl-2">
        <GeneralTermsOfUseText />
        {props.showCheckbox && (
          <div className="row mb-1">
            <label className="mt-4 cgu-checkbox-container">
              <input
                type="checkbox"
                className="mr-2"
                disabled={false}
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
              <div>
                J&apos;ai lu et j&apos;accepte les conditions générales
                d&apos;utilisation et la politique de confidentialité.
              </div>
            </label>
          </div>
        )}
      </div>
    </Modal>
  );
};
