import React from "react";
import Modal from "../../Modal";
import styles from "./ModalMprTests.module.css";

interface Props {
  onClose: () => void;
}

export const ModalMprTests: React.FC<Props> = (props: Props) => {
  const closeModal = (): void => {
    props.onClose();
  };

  const mprDocumentNames = [
    "Catalogue Mictionnel",
    "BREF - Batterie rapide d'Evaluation Frontale",
    "Evaluation de Spasticité - Echelle d'Ashworth modifiée et Echelle de spasme de Penn",
    "GOAT - Test de Galveston Orientation and Amnesia",
    "MMS test",
    "MoCA- Montreal Cognitive Assessment",
    "Score ASIA",
    "SEP - EDSS",
    "USP - Questionnaire des symptômes urinaires",
  ];

  return (
    <Modal show onClose={closeModal} title={"Tests MPR"}>
      <div className={styles.modalContainer}>
        <div className="mb-4">
          Vous trouverez dans les documents ci-dessous différents tests à
          réaliser afin de préciser la pathologie de votre patient.
        </div>
        {mprDocumentNames.map((fileName, index) => {
          return (
            <ul
              key={index}
              className={`text-grey text-medium mb-2 ml-0`}
              onClick={() =>
                window.open(
                  `https://neuromonitor-mpr-files.cellar-c2.services.clever-cloud.com/${fileName}.pdf`
                )
              }
            >
              <li>
                {" "}
                <span className={styles.downloadMprTestFile}>{fileName}</span>
              </li>
            </ul>
          );
        })}
      </div>
    </Modal>
  );
};
