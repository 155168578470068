export interface Availability {
  id: string;
  startTime: number; //starting hour
  endTime: number; //ending hour
  day: number; //from 0 to 6, 0: monday and 6: sunday
  facilityId?: string;
}

export function dayToString(day: number): string {
  switch (day) {
    case 0:
      return "LUNDI";
    case 1:
      return "MARDI";
    case 2:
      return "MERCREDI";
    case 3:
      return "JEUDI";
    case 4:
      return "VENDREDI";
    case 5:
      return "SAMEDI";
    case 6:
      return "DIMANCHE";
    default:
      return "";
  }
}

// Sort availabilities by day (from monday to sunday)
// Does not sort availabilities within a day (by hour)
export function getSortedAvailabilities(
  availabilities: Availability[]
): Availability[] {
  availabilities.sort((a1, a2) => {
    if (a1.day > a2.day) {
      return 1;
    }
    if (a1.day < a2.day) {
      return -1;
    }
    return 0;
  });
  return availabilities;
}
