import React, { useEffect, useState } from "react";
import { isNotProd } from "../../utils/config";
import styles from "./Reply.module.css";
import TextareaAutosize from "react-textarea-autosize";
import { IcanopeeDmpService } from "../../services/icanopee/IcanopeeDmpService";
import { HealthcareSettings, showDmpError } from "../../services/DmpService";
import Modal from "../Modal";
import {
  sendTlmCrToDmp,
  setOpinionRequestUploadToDmpDate,
} from "../../services/OpinionRequestService";
import { errorToast, successToast } from "../../utils/Toast";
import OpinionRequest from "../../domain/OpinionRequest";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { ModalInfo } from "../Shared/ModalInfo";
import { Spinner } from "../Shared/Spinner";

interface Props {
  isSendableToDmp: boolean;
  onClose: (isSuccess: boolean) => void;
  request: OpinionRequest;
}

export const UploadToDmp: React.FC<Props> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const [isInvalidReply, setIsInvalidReply] = useState<boolean>(false);
  const [healthcareSettings, setHealthcareSettings] = useState<
    HealthcareSettings[]
  >([]);
  const [replyReportDescription, setReplyReportDescription] = useState<string>(
    ""
  );
  const [
    replyHealthcareSettingsCode,
    setReplyHealthcareSettingsCode,
  ] = useState<string>("");
  const [cpsPinCode, setCpsPinCode] = useState<number>(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);

  useEffect(() => {
    if (replyReportDescription === "" || replyHealthcareSettingsCode === "") {
      setIsInvalidReply(true);
    } else {
      setIsInvalidReply(false);
    }
  }, [replyReportDescription, replyHealthcareSettingsCode]);

  const handleReportDescriptionChange = (event: any): void => {
    setReplyReportDescription(event.target.value);
  };

  const handleHealthcareSettingsChange = (event: any): void => {
    setReplyHealthcareSettingsCode(event.target.value);
  };

  useEffect(() => {
    getInteropCodes();
  }, []);

  async function getInteropCodes() {
    const icanopeeDmpService = new IcanopeeDmpService();
    try {
      const result: HealthcareSettings[] = await icanopeeDmpService.getHealthcareSettings(
        String(cpsPinCode)
      );
      setHealthcareSettings(result);
    } catch (e) {
      showDmpError(e);
    }
  }

  const sendTlmCr = async (
    reportDescription: string,
    healthcareSetting: string
  ): Promise<void> => {
    setStatus(LoadingStatus.LOADING);
    const res = await sendTlmCrToDmp(
      props.request.id ?? "",
      reportDescription,
      healthcareSetting,
      {
        birthday: `${props.request.patient.birthdate.year}-${props.request.patient.birthdate.month}-${props.request.patient.birthdate.day}`,
        ins: props.request.patient.socialSecurityNumber ?? "",
      },
      cpsPinCode
    );
    if (res instanceof Error) {
      setStatus(LoadingStatus.ERROR);
      errorToast("Le compte-rendu n'a pas pu être envoyé au DMP.");
    } else {
      setStatus(LoadingStatus.SUCCESS);
      successToast("Le compte-rendu a été envoyé au DMP avec succès.");
      const response = await setOpinionRequestUploadToDmpDate(
        props.request.id ?? ""
      );
      if (response instanceof Error) {
        closeModal(false);
      } else {
        closeModal(true);
      }
    }
  };

  const closeModal = (isSuccess: boolean): void => {
    props.onClose(isSuccess);
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Dossier Médical Partagé"}
      footer={
        <>
          <div className="footer-line" />
          <div className="row space-between-content ml-4 mr-4">
            <button
              className="btn rectangular-btn close-btn"
              onClick={() => closeModal(false)}
            >
              Annuler
            </button>
            <button
              className="btn rectangular-btn neuro-btn "
              onClick={async (): Promise<void> => {
                await sendTlmCr(
                  replyReportDescription,
                  replyHealthcareSettingsCode
                );
              }}
              disabled={isInvalidReply || status != LoadingStatus.IDLE}
            >
              <div className="row aligned-centered">
                {status === LoadingStatus.LOADING && (
                  <Spinner size={"small-spinner"} />
                )}
                Envoyer
              </div>
            </button>
          </div>
        </>
      }
      containerStyle={{ height: "55vh" }}
    >
      <>
        {isNotProd() && (
          <>
            <div className={"text-medium mt-2 text-grey"}>
              Afin d&apos;envoyer le compte-rendu de téléexpertise dans le DMP
              du patient, veuillez saisir les informations suivantes :{" "}
            </div>
            <div className="text-left modal-label mt-4">
              Description du document
            </div>
            <div className={`row ${styles.minHeight}`}>
              <TextareaAutosize
                onChange={handleReportDescriptionChange}
                minRows={2}
                name="report-description"
                className="participant-textarea form-input margin-top-2"
                placeholder="Courte description du document pour l'affichage dans le DMP"
              />
            </div>
            <div className="text-left modal-label ">Cadre de soin</div>
            <div className="row">
              <select
                className="form-select"
                onChange={handleHealthcareSettingsChange}
                defaultValue={"DEFAULT"}
              >
                <option
                  className="text-secondary-grey"
                  value="DEFAULT"
                  disabled
                >
                  -- Sélectionnez votre cadre de soin --
                </option>
                {healthcareSettings.map((item) => {
                  return (
                    <option key={item.code} value={item.code}>
                      {item.displayName}
                    </option>
                  );
                })}
              </select>
            </div>
          </>
        )}
        {isInvalidReply && (
          <div className="error-msg mt-7 text-centered">
            <i>
              Veuillez remplir l&apos;ensemble des champs pour soumettre votre
              réponse.
            </i>
          </div>
        )}
      </>
      {showPinCodeModal && (
        <ModalInfo
          title={"Confirmation de votre identité"}
          onClose={(): any => setShowPinCodeModal(false)}
          rightButtonAction={() => window.location.reload()}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <div className="flex-col p-2">
            <p>
              Veuillez entrer ci-dessous le code correspondant à votre profil de
              professionnel de la santé:
            </p>
            <input
              type="number"
              className={styles.codeInput}
              defaultValue={""}
              onChange={(event) => setCpsPinCode(parseInt(event.target.value))}
            />
          </div>
        </ModalInfo>
      )}
    </Modal>
  );
};
