import { DMPConnect } from "./DmpConnectWrapper";
import {
  createDmpConnector,
  createInsiConnector,
  listPcscSmartcardReaders,
  openSessionWithCustomDcParam,
  readCPxCard,
} from "./icanopeeWrapper";
import config from "../../utils/config";

export type DmpSession = DMPConnect;

export enum IcanopeeError {
  // Invalid CPS card, or unavailable
  SERVER_ERROR,
  SERVICE_UNAVAILABLE,
  CARD_ERROR,
  UNEXPECTED_ERROR,
  MISSING_RIGHTS,
  ERROR_FUNCTIONAL,
  TOO_MANY_PIN_TRIES_FAILED,
  COMPONENT_INITIAL_CODE,
}

export class IcanopeeService {
  private session: DmpSession | null = null;

  public async getSession(pinCode: string): Promise<DmpSession> {
    if (this.session === null) {
      const storageSessionString = localStorage.getItem("icanopeeSession");
      if (storageSessionString) {
        const s: DmpSession = JSON.parse(storageSessionString) as DmpSession;
        const savedSession = this.createSession();
        Object.assign(savedSession, s);
        const sessionIsAlive = await this.isSessionAlive(savedSession);
        if (sessionIsAlive) {
          this.session = savedSession;
          console.debug("Found valid session in storage");
        }
      }
      if (!storageSessionString && pinCode.length !== 4) {
        throw IcanopeeError.COMPONENT_INITIAL_CODE;
      }
    }
    if (this.session === null) {
      console.debug("Opening new DMP Connect session");
      try {
        this.session = this.createSession();
        await this.openSession(this.session, pinCode);
        localStorage.setItem("icanopeeSession", JSON.stringify(this.session));
      } catch (e) {
        console.error("Error while opening DMP connector session", e);
        const icanopeeError: IcanopeeError | null = this.parseError(e);
        if (icanopeeError !== null) {
          this.clearSession();
          throw icanopeeError;
        }
      }
    }

    if (this.session == null) {
      throw new Error("Could not start iCanopee session");
    }

    return this.session;
  }

  private createSession() {
    return new DMPConnect(
      (a: any) => {
        console.debug("DMP connect log", a);
      },
      (a: any) => {
        console.debug("DMP connect refresh", a);
      },
      (e: any) => {
        console.debug("DMP connect error", e);
      },
      () => {
        console.debug("DMP connect initialized");
      },
      "9982",
      "localhost.icanopee.net"
    );
  }

  private clearSession() {
    localStorage.removeItem("icanopeeSession");
    this.session = null;
  }

  private isSessionAlive(session: DmpSession): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        session._errorHandler = (e: any) => {
          console.debug("Failed to get session state", e);
          resolve(false);
        };
        session.hl_getSessionState(null, function (a: any) {
          if (a.error) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      } catch (e) {
        console.error("Failed to check DMP connect session (icanopee)", e);
        resolve(false);
      }
    });
  }

  private async openSession(
    dmpConnectInstance: DmpSession,
    pinCode: string
  ): Promise<void> {
    console.debug("opening session");
    await openSessionWithCustomDcParam(
      dmpConnectInstance,
      config.icanopeeLicense
    );
    console.debug("session opened");

    console.debug("Listing PC/SC readers...");
    const readers = await listPcscSmartcardReaders(dmpConnectInstance);
    console.debug("Listed PC/SC readers\n");

    console.debug("Reading CPx card...");
    for (let i = 0; i < readers.length; i++) {
      try {
        console.debug("Trying PC/SC reader number ", i);
        await readCPxCard(dmpConnectInstance, i, pinCode);
        break;
      } catch (e) {
        console.debug("CPx card not found with reader ", i);
        if (
          e.s_apiErrorContext === "getCPxRemainingPinCodeInputs" ||
          i === readers.length - 1
        ) {
          console.error("error while reading CPx card", e);
          throw e;
        }
      }
    }
    console.debug("Card read\n");

    console.debug("Creating INSI connector...");
    await createInsiConnector(dmpConnectInstance);
    console.debug("Creating DMP connector...");
    await createDmpConnector(dmpConnectInstance);
    console.debug("Connectors created\n");
  }

  private parseError(e: any): IcanopeeError {
    console.error("getting error from", e);
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 9) {
      // CPX_MISSING error: No CPS found in the smartcard reader.
      this.clearSession();
      return IcanopeeError.CARD_ERROR;
    }
    if (
      e.i_apiErrorType === 1 &&
      e.i_apiErrorCode === 8 &&
      e.s_apiErrorContext === "getCPxRemainingPinCodeInputs"
    ) {
      // CPX_MISSING error: No CPS found in the smartcard reader.
      this.clearSession();
      return IcanopeeError.TOO_MANY_PIN_TRIES_FAILED;
    }
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 2) {
      this.clearSession();
      return IcanopeeError.SERVER_ERROR;
    }
    //ApiErrorType 5000 is a DMPCONNECT-JS2 error, see page 14 of specs_dmpconnect_js-socle-0.9.2.pdf
    if (e.i_apiErrorType === 5000 && e.i_apiErrorCode === 2) {
      this.clearSession();
      return IcanopeeError.ERROR_FUNCTIONAL;
    }
    if (e.i_apiErrorType === 6000 && e.i_apiErrorCode === 2) {
      this.clearSession();
      return IcanopeeError.COMPONENT_INITIAL_CODE;
    }
    return IcanopeeError.UNEXPECTED_ERROR;
  }
}
