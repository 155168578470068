import React, { useState } from "react";
import "./Admin.css";
import { useForm } from "react-hook-form";
import { SearchFacilities } from "../SearchFacilities";
import { Facility } from "../../domain/Facility";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import { errorToast, successToast } from "../../utils/Toast";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { searchHealthProfessionalByNationalId } from "../../services/DirectoryService";
import { HealthProfessional } from "../../domain/HealthProfessionnal";
import { createUserByAdmin } from "../../services/UserService";
import {
  getCivilTitleLabel,
  getProfessionalTitleLabel,
  HealthProfessionalTitle,
  HealthProfessionalUser,
} from "../../domain/User";
import { ErrorResponse, ErrorType } from "../../domain/ErrorManagement";
import history from "../../utils/history";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import { getFacilityByFiness } from "../../services/FacilityService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const CreateUser: React.FC<any> = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<HealthProfessional>();
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [facility, setFacility] = useState<Facility>();
  const [isFacilityMissing, setIsFacilityMissing] = useState<boolean>(false);
  const [rpps, setRpps] = useState<string>("");
  const [userDisplayed, setUserDisplayed] = useState<HealthProfessional>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");

  const saveUser = handleSubmit(
    async (data: HealthProfessional): Promise<any> => {
      if (facility === undefined) {
        setIsFacilityMissing(true);
        return;
      }

      const user: HealthProfessionalUser = {
        ...userDisplayed,
        userId: "",
        rpps: rpps,
        professionalTitle:
          userDisplayed?.professionalTitle || HealthProfessionalTitle.NONE,
        firstName: userDisplayed?.firstName || "",
        lastName: userDisplayed?.lastName || "",
        email: data.email,
        phone: phoneNumber,
        facilityId: facility?.id,
      };

      let userId = "";
      await createUserByAdmin(user).then(
        (creationUserResponse: any | Error) => {
          if (creationUserResponse instanceof ErrorResponse) {
            if (
              creationUserResponse.code === ErrorType.USER_ALREADY_CREATED &&
              creationUserResponse.payload
            ) {
              errorToast(
                `Un utilisateur existe déjà avec le numéro rpps ${rpps}`
              );
            } else {
              errorToast(
                `Erreur lors de la création, l'utilisateur ${user.firstName} ${user.lastName} n'a pas été créé`
              );
            }
          } else if (creationUserResponse instanceof Error) {
            errorToast(
              `Erreur lors de la création, l'utilisateur ${user.firstName} ${user.lastName} n'a pas été créé`
            );
          } else {
            userId = creationUserResponse.message;
          }
          if (userId != "") {
            successToast("Utilisateur créé");
            history.push(`/admin/users/${userId}/view`);
          }
        }
      );
    }
  );

  const searchRpps = async (): Promise<void> => {
    if (rpps === "") {
      errorToast("Vous devez renseigner un RPPS");
      return;
    }

    setStatus(LoadingStatus.LOADING);
    const searchResults = await searchHealthProfessionalByNationalId(rpps);
    if (searchResults instanceof Error) {
      errorToast("Nous n'avons pas pu trouver ce RPPS");
      setStatus(LoadingStatus.ERROR);
    } else {
      if (searchResults.length === 1) {
        setStatus(LoadingStatus.SUCCESS);
        setIsTyping(false);
        reset(searchResults[0]);
        setUserDisplayed(searchResults[0]);
        const phone = searchResults[0].phone.replace(/\D/g, ""); // remove all non-numeric values from phone number
        setPhoneNumber(phone);
        const finess = searchResults[0].finessNumber;
        if (finess !== "") {
          await getFacilityByFiness(finess).then((res: Facility | Error) => {
            if (!(res instanceof Error)) {
              setFacility(res);
            }
          });
        }
      } else if (searchResults.length < 1) {
        setStatus(LoadingStatus.ERROR);
        errorToast("Aucun résultat n'a été trouvé pour ce RPPS");
      } else {
        setStatus(LoadingStatus.ERROR);
        errorToast("Plusieurs résultats ont été trouvés pour ce RPPS");
      }
    }
  };

  const handleChange = (event: any): void => {
    setIsTyping(true);
    setRpps(event.target.value);
  };

  const handlePhoneNumberChange = (input: string): void => {
    if (input === "") {
      setPhoneNumber("+");
    } else {
      setPhoneNumber(input);
    }
    if (input.length < 11) {
      setPhoneNumberError("Numéro de téléphone incorrect");
    } else {
      setPhoneNumberError("");
    }
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Nouvel utilisateur"}
        backButtonRedirectsTo={`/admin`}
      />
      <div className="page-container">
        <div className="profile-white-container">
          <div className="p-1">
            <div className="form-group">
              <label className="form-label pb-0">
                Numéro RPPS ou Adeli
                {status === LoadingStatus.SUCCESS && (
                  <span className="error-msg text-large ml-1">*</span>
                )}
              </label>
              <input
                {...register("rpps", {
                  required: "Ce champ est obligatoire",
                })}
                className="form-input"
                type="text"
                placeholder="Ex: XXXXXXXX"
                onChange={handleChange}
              />
              {errors?.rpps && (
                <div className="text-left error-msg">{errors.rpps.message}</div>
              )}
            </div>
            {isTyping && (
              <div className="row centered-content margin-top-2">
                <button className="w-40 btn secondary-btn" onClick={searchRpps}>
                  Rechercher dans l&apos;annuaire
                </button>
              </div>
            )}
            {status === LoadingStatus.SUCCESS && (
              <div className="text-left">
                <div className="form-group">
                  <label className="form-label pb-0 pt-0">Civilité</label>
                  <input
                    className="form-input"
                    type="text"
                    placeholder="Titre inconnu"
                    defaultValue={getCivilTitleLabel(userDisplayed?.civilTitle)}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label pt-0">Titre</label>
                  <input
                    className="form-input"
                    type="text"
                    placeholder="Titre inconnu"
                    defaultValue={getProfessionalTitleLabel(
                      userDisplayed?.professionalTitle
                    )}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label pt-0">Prénom</label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={userDisplayed?.firstName}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label pb-0">Nom</label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={userDisplayed?.lastName}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label pb-0">
                    Email
                    <span className="error-msg text-large mt-1 ml-1">*</span>
                  </label>
                  <input
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Addresse email incorrecte",
                      },
                      required: "Ce champ est obligatoire",
                    })}
                    name="email"
                    className="form-input"
                    type="text"
                    placeholder="Ex: exemple@email.com"
                  />
                  {errors?.email && (
                    <span className="error-msg">{errors.email.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label pb-0">
                    Téléphone
                    <span className="error-msg text-large mt-1 ml-1">*</span>
                  </label>
                  <PhoneInput
                    country={"fr"}
                    preferredCountries={["fr"]}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                  <div className="error-msg">{phoneNumberError}</div>
                </div>
                <div className="form-group">
                  <label className="form-label pb-0">Profession</label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={userDisplayed?.profession}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label pb-0">Spécialité</label>
                  <input
                    className="form-input"
                    type="text"
                    placeholder="Aucune spécialité"
                    defaultValue={userDisplayed?.specialty}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Lieu d&apos;exercice
                    <span className="error-msg text-large ml-1 mt-1">*</span>
                  </label>
                  <SearchFacilities
                    onSelect={(searchedFacility: Facility): void => {
                      setFacility(searchedFacility);
                      setIsFacilityMissing(false);
                    }}
                    autoFocus={false}
                  />
                  {isFacilityMissing && (
                    <span className="error-msg">Ce champ est obligatoire</span>
                  )}
                  {facility && (
                    <div className="mt-2">
                      <FacilityCard
                        isAddService={false}
                        facility={facility}
                        handleRemove={(): void => {
                          setFacility(undefined);
                          setIsFacilityMissing(true);
                        }}
                        showRemoveButton={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {status === LoadingStatus.SUCCESS && (
            <>
              <div className="text-right">
                <span className="error-msg text-large">*</span>
                <span className="error-msg text-medium ml-2">
                  Champs obligatoires
                </span>
              </div>
              <div className="row centered-content">
                <button
                  className="btn rectangular-btn close-btn mr-2"
                  onClick={(): void => history.push("/admin")}
                >
                  Annuler
                </button>
                <button
                  className="btn rectangular-btn neuro-btn ml-2"
                  onClick={saveUser}
                >
                  Valider
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
