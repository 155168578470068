import React from "react";

interface Props {
  onChange: (value: boolean) => void;
  value: boolean;
}

export const IdentityVerification: React.FC<Props> = (props: Props) => {
  return (
    <label className="form-checkbox c-hand">
      <div className="row">
        <div>
          Vous avez vérifié l&apos;identité du patient à l&apos;aide d&apos;une
          pièce d&apos;identité
        </div>
      </div>
      <input
        onChange={(event: any): void => {
          props.onChange(event.target.checked);
        }}
        checked={props.value}
        type="checkbox"
      />
      <i className={"form-icon identity-checkbox"} />
    </label>
  );
};
