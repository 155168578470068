import React, { useEffect, useState } from "react";
import OpinionRequest, {
  HealthPathwayContact,
  HealthPathwayContactType,
  isEmergencyRequest,
  OpinionRequestFormPage,
} from "../../../domain/OpinionRequest";
import styles from "./DiseaseDetails.module.css";
import { getMinimizedTitleLabel, User } from "../../../domain/User";
import { DetailsRequestCard } from "../DetailsRequestCard";
import { RequestingPhysician } from "./RequestingPhysician";
import { DiseaseDescription } from "./DiseaseDescription";
import { getOpinionRequestExperts } from "../../../services/OpinionRequestService";
import { errorToast } from "../../../utils/Toast";
import { ExpertStatus } from "../../../domain/ExpertStatus";
import { DetailsEditButtonRequestCard } from "../DetailsEditButtonRequestCard";

interface Props {
  request: OpinionRequest;
}

export const DiseaseDetails: React.FC<any> = (props: Props) => {
  const [acceptedExperts, setAcceptedExperts] = useState<User[]>([]);

  const healthPathwayDoctors = props.request?.healthPathwayContacts?.filter(
    (hp) => hp.category === HealthPathwayContactType.DOCTOR
  );
  const healthPathwayParamedical = props.request?.healthPathwayContacts?.filter(
    (hp) => hp.category === HealthPathwayContactType.PARAMEDICAL
  );

  useEffect(() => {
    // TODO récupérer la liste depuis le composant parent?
    async function getAllAssignedExperts(): Promise<void> {
      if (props.request?.id) {
        const res = await getOpinionRequestExperts(props.request.id);
        if (res instanceof Error) {
          errorToast("Erreur lors de la récupération des experts");
        } else {
          const result = res || [];
          const acceptedRequestExpert = props.request?.assignedExperts?.filter(
            (expert) => expert.status === ExpertStatus.ACCEPTED
          );
          if (acceptedRequestExpert) {
            setAcceptedExperts(
              result.filter((assignExpert) =>
                acceptedRequestExpert
                  .map((expert) => expert.userId)
                  .includes(assignExpert.id)
              )
            );
          }
        }
      }
    }

    getAllAssignedExperts();
  }, [props.request]);

  const displayHealthPathwayProfessionnals = (
    healthPathwayContacts: HealthPathwayContact[]
  ): any => {
    return healthPathwayContacts.map((contact, index) => (
      <div key={index} className={styles.healthPathwayDoctorsContainer}>
        <div className={"text-bold text-primary-blue"}>
          {contact.firstName !== "" && contact.lastName !== ""
            ? getMinimizedTitleLabel(contact.professionalTitle) +
              " " +
              contact.firstName +
              " " +
              contact.lastName
            : contact.email}
        </div>
        <div className={styles.contactProfession}>{contact.profession}</div>
        {contact.role && (
          <div className={styles.contactProfession}>Rôle : {contact.role}</div>
        )}
      </div>
    ));
  };

  return (
    <div className={`flex-col ${styles.diseaseContainer} pt-2`}>
      <div className={`${styles.gridContainerDisease}`}>
        <div className="flex-col">
          <DetailsRequestCard request={props.request} cardTitle={"Requérant"}>
            <RequestingPhysician request={props.request} />
          </DetailsRequestCard>
          {!isEmergencyRequest(props.request) && (
            <>
              <DetailsRequestCard
                request={props.request}
                cardTitle={"Parcours de santé"}
                pageNumber={OpinionRequestFormPage.healthPathwayDoctorsTeam}
              >
                <span className="text-bold ">
                  {"Médecins du parcours de santé"}
                </span>
                {healthPathwayDoctors && healthPathwayDoctors.length !== 0 ? (
                  displayHealthPathwayProfessionnals(healthPathwayDoctors)
                ) : (
                  <div className="text-primary-blue">N/A</div>
                )}
                <div className="mt-2">
                  <DetailsEditButtonRequestCard
                    request={props.request}
                    cardTitle={"Equipe paramédicale"}
                    pageNumber={
                      OpinionRequestFormPage.healthPathwayParamedicalTeam
                    }
                  />
                </div>
                {healthPathwayParamedical &&
                healthPathwayParamedical.length !== 0 ? (
                  displayHealthPathwayProfessionnals(healthPathwayParamedical)
                ) : (
                  <div className="text-primary-blue">N/A</div>
                )}
              </DetailsRequestCard>
            </>
          )}
          {acceptedExperts && acceptedExperts.length !== 0 && (
            <div
              className={`field-detail-card flex-row text-coral-medium ${styles.expertInfoCard}`}
            >
              <div className="row w-100">
                <i
                  className={
                    "fas fa-user-md text-corail-pink " + styles.iconWhite
                  }
                />
                <div className="mt-2 mb-2">
                  La demande est prise en charge par :{" "}
                  {acceptedExperts.map((expert, index) => (
                    <React.Fragment key={index}>
                      {getMinimizedTitleLabel(expert.professionalTitle)}{" "}
                      {expert.firstName} {expert.lastName}
                      {index + 1 < acceptedExperts.length && ", "}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex-col">
          <DetailsRequestCard
            request={props.request}
            cardTitle={"Description de la pathologie principale"}
            pageNumber={OpinionRequestFormPage.patientDisease}
          >
            <div className="mt-2">
              <DiseaseDescription request={props.request} />
            </div>
          </DetailsRequestCard>
          <DetailsRequestCard
            request={props.request}
            cardTitle={"Antécédents"}
            pageNumber={OpinionRequestFormPage.patientCaseHistory}
          >
            {props.request?.secondaryDiseases ? (
              <div className="pre-line ">{props.request.secondaryDiseases}</div>
            ) : (
              <div className="text-primary-blue">N/A</div>
            )}
          </DetailsRequestCard>
        </div>
        <div className="flex-col">
          <DetailsRequestCard
            request={props.request}
            cardTitle={"Traitements"}
            pageNumber={OpinionRequestFormPage.patientCaseHistory}
          >
            {props.request?.treatments?.length !== 0 ? (
              props.request?.treatments?.map((treatment, index) => {
                return (
                  <div className="treatment-label" key={index}>
                    {treatment}
                  </div>
                );
              })
            ) : (
              <div className="text-primary-blue">N/A</div>
            )}
          </DetailsRequestCard>
        </div>
      </div>
    </div>
  );
};
