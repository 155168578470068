import React, { useContext, useEffect, useState } from "react";
import {
  getRequestStatusLabel,
  OpinionRequestSummary,
} from "../../domain/OpinionRequest";
import "./RequestCard.css";
import { format, parseISO } from "date-fns";
import {
  getOpinionRequestExperts,
  getPatientAge,
} from "../../services/OpinionRequestService";
import { EmergencyLevel } from "../../domain/EmergencyLevel";
import { Label } from "./Label";
import brainIcon from "../../assets/brain-icon.svg";
import styles from "../Profile/Facility/ProfileFacilitiesDetails.module.css";
import {
  getMinimizedTitleLabel,
  HealthProfessionalTitle,
  User,
} from "../../domain/User";
import { errorToast } from "../../utils/Toast";
import { UserChip } from "./UserChip";
import { PatientLocation } from "../OpinionRequestForm/PatientLocationForm";
import { LabelEmergency } from "./LabelEmergency";
import { UserRoles } from "../../domain/UserRoles";
import { LabelAlert } from "./LabelAlert";
import { ExpertStatus } from "../../domain/ExpertStatus";
import { AppContext } from "../../utils/state";
import { RequestStatus } from "../../domain/RequestStatus";

interface Props {
  r: OpinionRequestSummary;
  chosenRole: string;
  onClick: () => any;
  disabled?: boolean;
}

function formatDate(dateString: string): string {
  try {
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy"); //if we want time, add in format : "à HH:mm"
  } catch (e) {
    console.error("could not parse or format date", e);
    return "";
  }
}

/* Limit of 50 characters from primary disease
 * Limit of 100 characters from motive
 * Limit of 6 experts */
export const RequestCard: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [assignExperts, setAssignExperts] = useState<User[]>([]);
  const acceptedRequestExpert = props.r.assignedExperts?.filter(
    (expert) => expert.status === ExpertStatus.ACCEPTED
  );
  const suggestedExpert = props.r.suggestedExpert;
  const maxLengthPrimaryDisease = 50;
  const maxLengthMotive = 100;
  const maxNumberExperts = 6;

  useEffect(() => {
    function sortExpertList(list: User[]): void {
      list?.sort((item1: User, item2: User) => {
        if (item1.lastName === item2.lastName) {
          return item1.firstName > item2.firstName ? 1 : -1;
        }
        return item1.lastName > item2.lastName ? 1 : -1;
      });
    }

    async function getAllAssignedExperts(): Promise<User[]> {
      if (props.r.id) {
        const res = await getOpinionRequestExperts(props.r.id);
        if (res instanceof Error) {
          errorToast("Erreur lors de la récupération des experts");
        } else {
          sortExpertList(res);
          const result = res || [];
          setAssignExperts(result);
          return result;
        }
      }
      return [];
    }
    getAllAssignedExperts();
  }, [props.r.id]);

  const getLocation = (patientLocation: PatientLocation): string => {
    switch (patientLocation) {
      case PatientLocation.HOME:
        if (props.r.patient.address?.city) {
          return "Domicile : " + props.r.patient.address.city;
        } else {
          return "Domicile";
        }
      case PatientLocation.FACILITY:
        return "En centre de santé";
      default:
        return "";
    }
  };

  const getPatientName = (): string => {
    const firstName = props.r.patient.firstName
      ? props.r.patient.firstName
      : "";
    const lastName = props.r.patient.lastName ? props.r.patient.lastName : "";
    return firstName + " " + lastName + " ";
  };

  const expertInfo = (): JSX.Element => {
    if (
      (props.r.status === RequestStatus.DRAFT ||
        props.r.status === RequestStatus.SUBMITTED) &&
      ((appContext.state.user.roles !== null &&
        appContext.state.user.roles.includes(UserRoles.GLOBAL_DISPATCHER)) ||
        props.r.requesterUser?.id == appContext.state.user.id)
    ) {
      return (
        <div className="expert">
          <p className="card-title mb-2">Expert proposé</p>
          {suggestedExpert?.id ? (
            <UserChip
              professionalTitle={suggestedExpert.professionalTitle}
              firstName={suggestedExpert.firstName}
              lastName={suggestedExpert.lastName}
              isSmall={true}
            />
          ) : (
            "Aucun expert n'a été proposé"
          )}
        </div>
      );
    }
    return (
      <div className="expert">
        <p className="card-title mb-2">Experts assignés</p>
        {acceptedRequestExpert && acceptedRequestExpert.length > 0 ? (
          <>
            {assignExperts
              .slice(0, maxNumberExperts)
              .filter((assignExpert) =>
                acceptedRequestExpert
                  .map((expert) => expert.userId)
                  .includes(assignExpert.id)
              )
              .map((expert, index) => {
                return (
                  <div key={index}>
                    <UserChip
                      professionalTitle={expert.professionalTitle}
                      firstName={expert.firstName}
                      lastName={expert.lastName}
                      isSmall={true}
                    />
                  </div>
                );
              })}
            {assignExperts.length > maxNumberExperts && <div>...</div>}
          </>
        ) : (
          <div>Aucun expert n&apos;a été assigné pour l&apos;instant</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={`card card-shadow p-relative mb-2 ${
          props.disabled && "card-disabled"
        }`}
        onClick={props.onClick}
      >
        <div className="card-body" id="card-body">
          <div className="container">
            <div className="tag-small-screen">
              {props.r.emergencyLevel === EmergencyLevel.DELAYED_EMERGENCY && (
                <LabelEmergency />
              )}
              <div className="ml-2">
                {props.r.dispatcherAlert &&
                (props.chosenRole === UserRoles.GLOBAL_DISPATCHER ||
                  props.chosenRole === UserRoles.ORGANIZATION_DISPATCHER) ? (
                  <LabelAlert />
                ) : (
                  <Label color={""} type="">
                    {getRequestStatusLabel(props.r)}
                  </Label>
                )}
              </div>
            </div>
            <div className="flex-row mb-1 mt-2">
              <img className="card-theme" src={brainIcon} alt="icon brain" />
              <div className={styles.facilityName}>
                <div className="card-patient">
                  {props.r.patient.localIdentifier ? (
                    <>
                      {props.r.patient.firstName || props.r.patient.lastName ? (
                        <>
                          {getPatientName()}({props.r.patient.localIdentifier})
                        </>
                      ) : (
                        props.r.patient.localIdentifier
                      )}
                    </>
                  ) : (
                    <>{getPatientName()}</>
                  )}
                </div>
                <div className="text-bold">
                  {getLocation(props.r.patient.location)}
                </div>
              </div>
              <div className="tag-big-screen ml-auto">
                {props.r.emergencyLevel ===
                  EmergencyLevel.DELAYED_EMERGENCY && <LabelEmergency />}
              </div>
              <div className="tag-big-screen ml-2">
                {props.r.dispatcherAlert &&
                (props.chosenRole === UserRoles.GLOBAL_DISPATCHER ||
                  props.chosenRole === UserRoles.ORGANIZATION_DISPATCHER) ? (
                  <LabelAlert />
                ) : (
                  <Label color={""} type="">
                    {getRequestStatusLabel(props.r)}
                  </Label>
                )}
              </div>
            </div>
            <div className="grid-container">
              <div className="age">
                <p className="card-title"> Âge </p>
                <div>
                  {getPatientAge(props.r.patient.birthdate)
                    ? getPatientAge(props.r.patient.birthdate)
                    : "N/A"}
                </div>
              </div>
              <div className="maladie">
                <p className="card-title"> Maladie </p>
                <div>
                  {!props.r.primaryDisease
                    ? "N/A"
                    : props.r.primaryDisease.length > maxLengthPrimaryDisease
                    ? props.r.primaryDisease.slice(0, maxLengthPrimaryDisease) +
                      "..."
                    : props.r.primaryDisease}
                </div>
              </div>
              {expertInfo()}
              <div className="motif">
                <p className="card-title"> Motif </p>
                <div>
                  {!props.r.motive
                    ? "N/A"
                    : props.r.motive.length > maxLengthMotive
                    ? props.r.motive.slice(0, maxLengthMotive) + "..."
                    : props.r.motive}
                </div>
              </div>
              <div className="date-update">
                {props.r.submitDate && (
                  <>
                    <p className="card-title">Date de mise à jour</p>
                    <div className="submitted-date">
                      {formatDate(props.r.submitDate)}
                    </div>
                  </>
                )}
              </div>
              <div className="origine">
                <p className="card-title"> Origine de la demande </p>
                <div>
                  {props.r.requesterUser &&
                    props.r.requesterUser?.professionalTitle !==
                      HealthProfessionalTitle.NONE &&
                    getMinimizedTitleLabel(
                      props.r.requesterUser.professionalTitle
                    ) + " "}
                  {props.r.requesterUser?.firstName +
                    " " +
                    props.r.requesterUser?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
