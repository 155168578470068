//enum starts at 1 to match the enum in the backend
export enum Month {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  November,
  October,
  December,
}

export const getMonthName = (month: Month): string => {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  return months[month - 1];
};

export const getCurrentMonth = (): number => {
  const today = new Date();
  return today.getMonth() + 1;
};
