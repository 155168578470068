import React, { useContext, useEffect, useState } from "react";
import "./Account.css";
import { Done } from "./Done";
import { Rpps } from "./Rpps";
import { UserDetails } from "./UserDetails";
import { UserPicture } from "./UserPicture";
import history from "../../utils/history";
import { UserStatus } from "../../domain/UserStatus";
import { AppContext } from "../../utils/state";
import { hasStatus } from "../../utils/authorization";
import { PhoneNumberConfirmation } from "./PhoneNumberConfirmation";
import { HealthProfessional } from "../../domain/HealthProfessionnal";
import { getUser } from "../../services/UserService";
import { trackEvent } from "../../utils/plausible";

enum Step {
  RPPS,
  INFO,
  DOUBLE_AUTHENT,
  PICTURE,
  DONE,
}

export const Register: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  if (!hasStatus(appContext.state.user.status, UserStatus.CREATED)) {
    history.push("/");
  }

  const [step, setStep] = useState<Step>(0);
  const [is2FAValid, setIs2FAValid] = useState<boolean>(false);
  const [healthProfessionalData, setHealthProfessionalData] = useState<
    HealthProfessional
  >();

  useEffect(() => {
    getUser(appContext.state.user.id).then((res) => {
      if (res instanceof Error) {
        console.error(res);
        return;
      }
      setIs2FAValid(res.stillValid2FA ? res.stillValid2FA : false);
      if (
        res.rpps != "" &&
        res.firstName != "" &&
        res.lastName != "" &&
        res.profession != "" &&
        res.specialty != "" &&
        res.phone != "" &&
        res.civilTitle &&
        res.professionalTitle
      ) {
        setStep(Step.PICTURE);
      }
    });
  }, [appContext.state.user.id]);

  const nextPage = (): any => {
    if (is2FAValid && step + 1 === Step.DOUBLE_AUTHENT) {
      setStep(Step.PICTURE);
    } else {
      setStep(step + 1);
      if (step === Step.DONE) {
        trackEvent("Complete registration");
      }
    }
  };

  const previousPage = (): any => {
    if (is2FAValid && step - 1 === Step.DOUBLE_AUTHENT) {
      setStep(Step.INFO);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <>
      {step === Step.DONE ? (
        <Done />
      ) : (
        <div className="register">
          <div className="account-container">
            {step !== Step.RPPS ? (
              <label className="register-back-button" onClick={previousPage}>
                &lt; Précédent
              </label>
            ) : (
              <></>
            )}
            {step === Step.RPPS && (
              <Rpps
                next={(hpData: HealthProfessional): any => {
                  setHealthProfessionalData({
                    ...hpData,
                    phone: hpData.phone.replace(/\D/g, ""),
                  });
                  nextPage();
                }}
              />
            )}
            {step === Step.INFO && (
              <UserDetails
                healthProfessional={healthProfessionalData}
                next={nextPage}
              />
            )}
            {step === Step.DOUBLE_AUTHENT && (
              <PhoneNumberConfirmation
                next={nextPage}
                onConfirm={(valid2FA: boolean): void => {
                  setIs2FAValid(valid2FA);
                }}
              />
            )}
            {step === Step.PICTURE && <UserPicture next={nextPage} />}
          </div>
        </div>
      )}
    </>
  );
};
