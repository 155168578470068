import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { User } from "../../domain/User";
import { getProfile, updateCurrentUser } from "../../services/UserService";
import { errorToast } from "../../utils/Toast";
import "./Account.css";
import { ActionTypes, AppContext } from "../../utils/state";
import { getFacilityByFiness } from "../../services/FacilityService";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import { SearchFacilities } from "../SearchFacilities";
import { HealthProfessional } from "../../domain/HealthProfessionnal";
import { Facility } from "../../domain/Facility";
import { GeneralTermsOfUseModal } from "../LegalInformation/GeneralTermsOfUseModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface Props {
  next: () => any;
  healthProfessional: HealthProfessional;
}

export const UserDetails: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const connectedUser = appContext.state.user;
  const { register, handleSubmit, trigger, setValue, formState } = useForm<
    User
  >();
  const { errors } = formState;
  const [finessNumber, setFinessNumber] = useState<string>(
    connectedUser.facilities && connectedUser.facilities.length > 0
      ? connectedUser.facilities[0].finess
      : props.healthProfessional.finessNumber
  );
  const [facility, setFacility] = useState<Facility | null>();
  const [hasAcceptedTOU, setHasAcceptedTOU] = useState<boolean>(false);
  const [showTOUmodal, setShowTOUmodal] = useState<boolean>(false);

  const handlePhoneNumberChange = (input: string): void => {
    if (input === "") {
      setPhoneNumber("+");
    } else {
      setPhoneNumber(input);
    }
    if (input.length < 11) {
      setPhoneNumberError("Numéro de téléphone incorrect");
    } else {
      setPhoneNumberError("");
    }
  };

  const getFacility = useCallback(async (): Promise<any> => {
    if (finessNumber !== "") {
      const res = await getFacilityByFiness(finessNumber);
      if (res instanceof Error || res == null) {
        errorToast("Une erreur est survenue lors de la recherche du centre");
      } else {
        setFacility(res);
      }
    } else {
      setFacility(null);
    }
  }, [finessNumber, trigger]);

  useEffect(() => {
    async function getFacilityId() {
      if (facility != null) {
        setValue("facilityId", facility.id);
        await trigger("facilityId");
      } else {
        setValue("facilityId", "");
      }
    }
    getFacilityId();
  }, [facility]);

  useEffect(() => {
    getFacility();
  }, [getFacility]);

  const onSubmit = async (data: User): Promise<void> => {
    if (phoneNumberError !== "") {
      return;
    }
    const user: User = {
      ...connectedUser,
      ...(data.facilityId && {
        facilities: [{ id: data.facilityId, finess: finessNumber }],
      }),
      professionalTitle: userDisplayed.professionalTitle,
      civilTitle: userDisplayed.civilTitle,
      profession: userDisplayed.profession,
      specialty: userDisplayed.specialty,
      firstName: userDisplayed.firstName,
      lastName: userDisplayed.lastName,
      phone: phoneNumber || "",
      termsOfUseAgreement: hasAcceptedTOU,
    };
    const res = await updateCurrentUser(user);
    if (res instanceof Error) {
      errorToast("Erreur lors de la sauvegarde du formulaire");
    } else {
      getProfile().then((updatedUser) => {
        if (!(updatedUser instanceof Error)) {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
          props.next();
        }
      });
    }
  };

  const userDisplayed: User = {
    ...connectedUser,
    civilTitle: props.healthProfessional.civilTitle,
    professionalTitle: props.healthProfessional.professionalTitle,
    firstName: connectedUser.firstName
      ? connectedUser.firstName
      : props.healthProfessional.firstName,
    lastName: connectedUser.lastName
      ? connectedUser.lastName
      : props.healthProfessional.lastName,
    profession: connectedUser.profession
      ? connectedUser.profession
      : props.healthProfessional.profession,
    specialty: connectedUser.specialty
      ? connectedUser.specialty
      : props.healthProfessional.specialty,
    phone: connectedUser.phone
      ? connectedUser.phone
      : props.healthProfessional.phone,
  };

  const [phoneNumber, setPhoneNumber] = useState<string>(userDisplayed.phone);
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");

  const openModal = (): void => {
    setShowTOUmodal(true);
  };

  const closeModal = (): void => {
    setShowTOUmodal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="form-label text-grey">Profession</label>
        <input
          className="form-input"
          type="text"
          id="profession-input"
          defaultValue={userDisplayed?.profession}
          disabled={true}
        />
        <label className="form-label text-grey">Spécialité</label>
        <input
          className="form-input"
          type="text"
          id="specialty-input"
          placeholder="Aucune spécialité"
          defaultValue={userDisplayed?.specialty}
          disabled={true}
        />
        <label className="form-label text-grey" htmlFor="firstname-input">
          Prénom
        </label>
        <input
          className="form-input"
          type="text"
          id="firstname-input"
          defaultValue={userDisplayed?.firstName}
          disabled={true}
        />
        <label className="form-label text-grey" htmlFor="lastname-input">
          Nom
        </label>
        <input
          className="form-input"
          type="text"
          id="lastname-input"
          defaultValue={userDisplayed?.lastName}
          disabled={true}
        />
        <label className="form-label text-grey" htmlFor="facilityid-input">
          Lieu d&apos;exercice <i className="text-small">(Numéro Finess)</i>
          <span className="error-msg text-large ml-1 mt-1">*</span>
        </label>
        <input
          hidden={true}
          {...register("facilityId", {
            required: "Champ obligatoire",
          })}
          type="hidden"
          id="facilityid-input"
          value={finessNumber}
        />
        {props?.healthProfessional.finessNumber !== "" &&
          connectedUser.facilityId !== "" &&
          connectedUser.facilityId !==
            props.healthProfessional.finessNumber && (
            <i className="text-small">
              Numéro finess trouvé dans l&apos;annuaire :
              {" " + props.healthProfessional.finessNumber}
            </i>
          )}
        <SearchFacilities
          onSelect={async (searchedFacility): Promise<any> =>
            setFinessNumber(searchedFacility.finess)
          }
          autoFocus={false}
        />
        <i className="error-msg">
          {errors?.facilityId && (
            <>
              {errors.facilityId.message} <br />
            </>
          )}
        </i>
        {facility && (
          <div className="mt-4">
            <FacilityCard
              facility={facility}
              handleRemove={(): any => setFinessNumber("")}
              isAddService={false}
              showRemoveButton={true}
            />
          </div>
        )}
        <label className="form-label text-grey" htmlFor="phone-input">
          Numéro de téléphone <b>portable</b>
          <span className="error-msg text-large ml-1 mt-1">*</span>
        </label>
        <PhoneInput
          country={"fr"}
          preferredCountries={["fr"]}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <i className="text-small">
          Ce numéro doit être valide, il servira à vous envoyer un code par SMS
          afin de vérifier votre identité.
        </i>
        <br />
        <label className="mt-4 cgu-checkbox-container">
          <input
            type="checkbox"
            className="mr-2"
            disabled={false}
            onChange={() => setHasAcceptedTOU(!hasAcceptedTOU)}
            checked={hasAcceptedTOU}
          />
          <p className="mb-0">
            J&apos;ai lu et j&apos;accepte les conditions générales
            d&apos;utilisation et la politique de confidentialité.
            <span className="error-msg text-large ml-1 mt-1">*</span>
          </p>
        </label>
        <i className="text-small mt-1 mb-2">
          En cochant cette case, vous confirmez avoir lu et accepté les
          <p className="cgu-link" onClick={openModal}>
            conditions d&apos;utilisation de l&apos;application (CGU)
          </p>
        </i>
        <button
          type="submit"
          className="btn btn-block neuro-btn mt-4"
          disabled={!hasAcceptedTOU}
        >
          Valider
        </button>
      </form>
      {showTOUmodal && (
        <GeneralTermsOfUseModal close={closeModal} showCheckbox={false} />
      )}
    </>
  );
};
