import React, { useEffect, useState } from "react";
import { User } from "../../domain/User";
import Select, { components } from "react-select";
import history from "../../utils/history";
import "./Admin.css";
import { UserCard } from "./UserCard";
import { FilterIcon } from "../Shared/FilterIcon";
import { UserRoles } from "../../domain/UserRoles";

interface Props {
  users: User[];
}

export const AllUsers: React.FC<Props> = (props: Props) => {
  const [users, setUsers] = useState<User[]>(props.users);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<number>(1);
  const availableRoles = [
    { value: UserRoles.GLOBAL_DISPATCHER, label: "Régulateur" },
    { value: UserRoles.ADMIN, label: "Administrateur" },
  ];

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const handleChange = (inputValue: any): void => {
    setUsers([]);
    if (inputValue != null) {
      setSelectedSize(inputValue.length);
      const roles: string[] = inputValue.map((role: any) => role.value);
      if (roles) {
        if (searchValue !== "") {
          const searchFilteredUsers: any = props.users.filter(
            (user) =>
              user.firstName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              user.lastName.toLowerCase().includes(searchValue.toLowerCase())
          );
          searchFilteredUsers.forEach((user: User) => {
            if (user.roles) {
              if (user.roles.some((role) => roles.indexOf(role) >= 0)) {
                setUsers((oldUser) => [...oldUser, user]);
              }
            }
          });
        } else {
          props.users.forEach((user) => {
            if (user.roles) {
              if (user.roles.some((role) => roles.indexOf(role) >= 0)) {
                setUsers((oldUser) => [...oldUser, user]);
              }
            }
          });
        }
        if (inputValue.length === 0) {
          setUsers(props.users);
        }
        setSelectedRoles(roles);
      }
    } else {
      setSelectedSize(1);
      setSelectedRoles([]);
      if (searchValue === "") {
        setUsers(props.users);
      } else {
        setUsers(
          props.users.filter(
            (user) =>
              user.firstName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              user.lastName.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      }
    }
  };

  const search = async (event: any): Promise<any> => {
    const searchVal = event.target.value;
    setUsers([]);
    if (searchVal && selectedRoles.length !== 0) {
      const searchFilteredUsers: any = props.users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchVal.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchVal.toLowerCase())
      );
      searchFilteredUsers.forEach((user: User) => {
        if (user.roles) {
          if (user.roles.some((role) => selectedRoles.indexOf(role) >= 0)) {
            setUsers((oldUser) => [...oldUser, user]);
          }
        }
      });
    } else if (searchVal && selectedRoles.length === 0) {
      setUsers(
        props.users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchVal.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchVal.toLowerCase())
        )
      );
    } else {
      setUsers(props.users);
    }
    setSearchValue(searchVal);
  };

  const filterStyle = {
    menu: (): Record<string, any> => ({
      border: "1px solid lightgrey",
      borderRadius: "5px",
      backgroundColor: "white",
      position: "absolute",
      zIndex: 10,
    }),
    multiValue: (): Record<string, any> => ({
      backgroundColor: "var(--secondary-blue)",
      padding: "2px",
      marginRight: "2px",
      display: "flex",
      borderRadius: "4px",
    }),
    option: (provided: any, isSelected: any): Record<string, any> => ({
      ...provided,
      backgroundColor: isSelected === true ? "var(--secondary-blue)" : "white",
      ":hover": {
        backgroundColor: "var(--secondary-blue)",
      },
      color: "var(--secondary-grey)",
      cursor: "pointer",
    }),
    valueContainer: (): Record<string, any> => ({
      justifyContent: "space-evenly",
      display: "flex",
      flex: 1,
      padding: "2px 8px",
      position: "relative",
      cursor: "pointer",
    }),
    dropdownIndicator: (): Record<string, any> => ({
      color: "var(--primary-blue)",
      alignItems: "baseline",
      alignSelf: "center",
      paddingTop: "5px",
      cursor: "pointer",
    }),
    indicatorSeparator: (): Record<string, any> => ({
      display: "none",
    }),
    clearIndicator: (): Record<string, any> => ({
      display: "none",
    }),
    control: (): Record<string, any> => ({
      border: "1px solid var(--light-blue)",
      borderRadius: "6px",
      display: "flex",
      padding: "2px",
    }),
    input: (): Record<string, any> => ({
      color: "transparent",
    }),
    container: (): Record<string, any> => ({
      width: "100%",
    }),
  };

  const ValueContainer: any = ({ children, ...valueContainerProps }: any) => (
    <>
      <span className={"pl-2"}>
        <FilterIcon />
      </span>
      <components.ValueContainer {...valueContainerProps}>
        {children}
      </components.ValueContainer>
    </>
  );

  return (
    <div className="mt-2 admin-white-container">
      <input
        type="text"
        value={searchValue}
        onChange={search}
        className="search-bar"
        placeholder="Rechercher un utilisateur..."
      />
      <div className="row space-between-content margin-top-2">
        <button
          className="btn neuro-btn rounded-btn mb-0 mt-0"
          onClick={() => history.push("/create-user")}
        >
          Ajouter un utilisateur
        </button>
        <div
          className="row"
          style={{
            width: `${selectedSize * 18}%`,
            minWidth: "30%",
            maxWidth: "430px",
          }}
        >
          <Select
            onChange={handleChange}
            closeMenuOnSelect={true}
            isMulti
            placeholder="Filtrer les utilisateurs"
            options={availableRoles}
            components={{
              ValueContainer,
            }}
            styles={filterStyle}
          />
        </div>
      </div>
      <div className="users-list-container">
        {users.length > 0 &&
          users.map((item, index) => <UserCard user={item} key={index} />)}
      </div>
    </div>
  );
};
