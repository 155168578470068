import React, { useEffect, useState } from "react";
import { LoadingStatus } from "../../utils/LoadingStatus";
import {
  CivilTitle,
  getCivilTitleLabel,
  getProfessionalTitleLabel,
  HealthProfessionalTitle,
  User,
} from "../../domain/User";
import {
  askSelfieUpdateByAdmin,
  deleteUserByAdmin,
  getUser,
  validateUserByAdmin,
} from "../../services/UserService";
import { useParams } from "react-router-dom";
import { getRolesLabel } from "../../domain/OpinionRequest";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import EditIcon from "../../assets/edit-icon.svg";
import history from "../../utils/history";
import styles from "./AdminViewProfile.module.css";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import { UserStatus } from "../../domain/UserStatus";
import { Label } from "../Shared/Label";
import { ModalInfo } from "../Shared/ModalInfo";
import { errorToast, successToast } from "../../utils/Toast";
import { hasStatus } from "../../utils/authorization";
import {
  ErrorResponse,
  ErrorType,
  showNewSelfieAskedErrorMessage,
} from "../../domain/ErrorManagement";
import { RppsImage } from "./ToValidateUser";

interface ParamTypes {
  id: string;
}

export const AdminViewProfile: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [user, setUser] = useState<User | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [shouldUpdateUser, setShouldUpdateUser] = useState<boolean>(false);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    getUser(id).then((res: User | Error) => {
      if (res instanceof Error) {
        setStatus(LoadingStatus.ERROR);
        setErrorMessage(res.message);
      } else {
        setUser(res);
        setStatus(LoadingStatus.SUCCESS);
      }
    });
  }, [id]);

  useEffect(() => {
    if (shouldUpdateUser) {
      getUser(id).then((res: User | Error) => {
        if (res instanceof Error) {
          setStatus(LoadingStatus.ERROR);
          setErrorMessage(res.message);
        } else {
          setUser(res);
          setStatus(LoadingStatus.SUCCESS);
        }
      });
      setShouldUpdateUser(false);
    }
  }, [shouldUpdateUser]);

  const startEdit = (): void => {
    history.push(`/admin/users/${id}/edit`);
  };

  const deleteUser = async (): Promise<void> => {
    user !== null &&
      (await deleteUserByAdmin(id).then((res: void | Error | ErrorResponse) => {
        if (res instanceof ErrorResponse) {
          if (
            res.code === ErrorType.ERR_USER_IS_PARTICIPANT_OF_OPINION_REQUEST
          ) {
            errorToast("L'uilisateur est lié à une demande d'avis");
          } else {
            errorToast("Erreur lors de la suppression de l'utilisateur");
          }
        } else {
          successToast("Utilisateur supprimé");
          history.push("/admin/users/all");
        }
      }));
  };

  const askPictureChange = async (): Promise<void> => {
    if (user) {
      const res = await askSelfieUpdateByAdmin(user.id);

      if (res instanceof ErrorResponse || res instanceof Error) {
        showNewSelfieAskedErrorMessage(res);
      } else {
        successToast(
          "Votre demande de reprise de photo a été envoyée à l'adresse email de l'utilisateur"
        );
      }
      setShouldUpdateUser(true);
    }
  };

  const validateUser = async (): Promise<void> => {
    if (user) {
      const res = await validateUserByAdmin(user);
      if (res instanceof Error) {
        errorToast("Erreur lors de la validation de l'utilisateur");
      } else {
        successToast("Utilisateur validé");
        setShouldUpdateUser(true);
      }
    }
    return;
  };

  if (status === LoadingStatus.ERROR) {
    return <div>Error: {errorMessage}</div>;
  } else if (status === LoadingStatus.LOADING) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <PageHeaderWithTitle
          title={"Profil"}
          backButtonRedirectsTo={`/admin/users/all`}
        />
        <div className="page-container">
          <div className="col-10 col-sm-auto col-mx-auto">
            <div className={styles.profileGridContainer}>
              <div className={styles.whiteContainer}>
                <div className="row space-between-content">
                  <label className="form-label">Civilité</label>
                  <button
                    id="edit-profile-icon"
                    className="edit-btn-small-screen"
                    onClick={startEdit}
                  >
                    <img
                      className="icon-disabled mobile-view-icon"
                      alt="icon-edit"
                      src={EditIcon}
                    />
                  </button>
                </div>
                {user?.civilTitle && user?.civilTitle !== CivilTitle.NONE ? (
                  <div className="mb-2">
                    {getCivilTitleLabel(user?.civilTitle)}
                  </div>
                ) : (
                  <div className="mb-2 text-secondary-grey">Titre inconnu</div>
                )}
                <label className="form-label">Titre</label>
                {user?.professionalTitle &&
                user?.professionalTitle !== HealthProfessionalTitle.NONE ? (
                  <div className="mb-2">
                    {getProfessionalTitleLabel(user?.professionalTitle)}
                  </div>
                ) : (
                  <div className="mb-2 text-secondary-grey">Titre inconnu</div>
                )}
                <label className="form-label">Prénom</label>
                <div className="mb-2">{user?.firstName}</div>
                <label className="form-label">Nom</label>
                <div className="mb-2">{user?.lastName}</div>
                <label className="form-label">Téléphone</label>
                {user?.phone ? (
                  <div className="mb-2">+{user?.phone}</div>
                ) : (
                  <div className="mb-2 text-secondary-grey">
                    Aucun numéro de téléphone
                  </div>
                )}
                <label className="form-label">Email</label>
                <div className="mb-2">{user?.email}</div>
                <label className="form-label">Profession</label>
                <div className="mb-2">{user?.profession}</div>
                <label className="form-label">Spécialité</label>
                {user?.specialty ? (
                  <div className="mb-2">{user?.specialty}</div>
                ) : (
                  <div className="mb-2 text-secondary-grey">
                    Aucune spécialité
                  </div>
                )}
                <label className="form-label">Numéro RPPS</label>
                {user?.rpps ? (
                  <div className="mb-2">{user?.rpps}</div>
                ) : (
                  <div className="mb-2 text-secondary-grey">
                    Aucun numéro RPPS
                  </div>
                )}
                <label className="form-label">Rôles</label>
                {user?.roles ? (
                  <div>{getRolesLabel(user?.roles)}</div>
                ) : (
                  <div className="text-secondary-grey">Pas de rôle</div>
                )}
              </div>
              <div>
                <div
                  className={`${styles.pictureContainer} ${styles.whiteContainer} ${styles.marginBottom}`}
                >
                  <label className="form-label">Photo</label>
                  {user?.selfie ? (
                    <>
                      <div className={`d-flex flex-col`}>
                        <img
                          className={`mr-auto ${styles.picture}`}
                          alt="photo de profil"
                          src={user?.selfie}
                        />
                      </div>
                      <button
                        className={`btn ${styles.imageBtn}`}
                        onClick={(): any => setOpenImage(true)}
                      >
                        <i className="icon icon-search" />
                      </button>
                    </>
                  ) : (
                    <div className="text-secondary-grey">Aucune photo</div>
                  )}
                  {user?.status && (
                    <>
                      {(hasStatus(user.status, UserStatus.REGISTERED) ||
                        hasStatus(user.status, UserStatus.CREATED)) && (
                        <button
                          className="btn neuro-btn rounded-btn"
                          onClick={askPictureChange}
                        >
                          Demander une nouvelle photo
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`${styles.whiteContainer} ${styles.marginBottom}`}
                >
                  <label className="form-label">
                    Lieu{user?.facilities && user?.facilities.length > 1 && "x"}{" "}
                    d&apos;exercice
                  </label>
                  {user?.facilities && user?.facilities.length >= 0 ? (
                    user?.facilities?.map((facility) => (
                      <FacilityCard
                        key={facility.finess}
                        isAddService={false}
                        facility={facility}
                        showRemoveButton={false}
                      />
                    ))
                  ) : (
                    <div className="text-secondary-grey">
                      Aucun lieu d&apos;exercice
                    </div>
                  )}
                </div>
                {user?.status && hasStatus(user.status, UserStatus.REGISTERED) && (
                  <>
                    <div>
                      <div
                        className={`${styles.whiteContainer} ${styles.marginBottom} row space-between-content`}
                      >
                        <div className="text-secondary-grey">
                          <Label color="registered" type="userStatus">
                            Utilisateur à valider
                          </Label>
                          <br />
                          <span className={styles.textRemoveUser}>
                            L&apos;utilisateur étant correctement inscrit, vous
                            pouvez le valider.
                          </span>
                        </div>
                        <button
                          className="btn rectangular-btn validate-btn"
                          onClick={(event): void => {
                            event.stopPropagation();
                            validateUser();
                          }}
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={`${styles.whiteContainer} row space-between-content`}
                >
                  {user?.status &&
                    (hasStatus(user.status, UserStatus.REGISTERED) ||
                      hasStatus(user.status, UserStatus.CREATED)) && (
                      <>
                        <div className="text-secondary-grey">
                          {hasStatus(user.status, UserStatus.CREATED) && (
                            <Label color="created" type="userStatus">
                              Nouvel utilisateur
                            </Label>
                          )}
                          {hasStatus(user.status, UserStatus.REGISTERED) && (
                            <Label color="registered" type="userStatus">
                              Utilisateur à valider
                            </Label>
                          )}
                          <br />
                          <span className={styles.textRemoveUser}>
                            L&apos;utilisateur n&apos;étant pas encore validé,
                            vous pouvez le supprimer.
                          </span>
                        </div>
                        <button
                          className="btn rectangular-btn delete-btn"
                          onClick={(): void => setShowDeleteModal(true)}
                        >
                          Supprimer
                        </button>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {openImage && (
          <RppsImage
            onClose={(): any => setOpenImage(false)}
            rpps={user?.rpps}
            image={user?.selfie}
          />
        )}
        {showDeleteModal && (
          <ModalInfo
            title={"Confirmation de suppression"}
            onClose={(): any => setShowDeleteModal(false)}
            rightButtonAction={deleteUser}
            rightButtonText={"Valider"}
            leftButtonText={"Annuler"}
          >
            <>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</>
          </ModalInfo>
        )}
      </>
    );
  }
};
