import React, { useContext, useState } from "react";
import "./Account.css";
import { updateCurrentUser } from "../../services/UserService";
import { CivilTitle, HealthProfessionalTitle, User } from "../../domain/User";
import { errorToast } from "../../utils/Toast";
import { ActionTypes, AppContext } from "../../utils/state";
import { searchHealthProfessionalByNationalId } from "../../services/DirectoryService";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { HealthProfessional } from "../../domain/HealthProfessionnal";

interface Props {
  next: (healthProfessional: HealthProfessional) => any;
}

export const Rpps: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [rpps, setRPPS] = useState<string>(
    appContext.state.user.rpps ? appContext.state.user.rpps : ""
  );
  const [otherTypeNumber, setOtherTypeNumber] = useState<string>(
    rpps.split(" : ")[0] ? rpps.split(" : ")[0] : ""
  );
  const [otherNumber, setOtherNumber] = useState<string>(
    rpps.split(" : ")[1] ? rpps.split(" : ")[1] : ""
  );
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.SUCCESS);
  const [withoutRpps, setWithoutRpps] = useState<boolean>(false);

  function handleChangeRpps(event: any): any {
    setRPPS(event.target.value);
  }

  function handleChangeProNumber(event: any): any {
    setOtherNumber(event.target.value);
  }

  function handleChangeTypeProNumber(event: any): any {
    setOtherTypeNumber(event.target.value);
  }

  function isWithoutRpps(): any {
    if (!withoutRpps) {
      setWithoutRpps(true);
    } else {
      setWithoutRpps(false);
    }
  }

  const onSubmit = async (): Promise<void> => {
    if (!withoutRpps) {
      if (rpps === "") {
        errorToast("Vous devez renseigner un RPPS");
        return;
      }

      setStatus(LoadingStatus.LOADING);
      const searchResults = await searchHealthProfessionalByNationalId(rpps);
      if (searchResults instanceof Error) {
        errorToast("Nous n'avons pas pu trouver votre RPPS");
        setStatus(LoadingStatus.ERROR);
      } else {
        if (searchResults.length === 1) {
          setStatus(LoadingStatus.SUCCESS);

          const updatedUser: User = {
            ...appContext.state.user,
            rpps,
          };

          // TODO récupérer les infos et les envoyer vers la page UserDetails.tsx pour que l'utilisateur puisse choisir ou pas d'overide les infos déja rentrées

          const updateStatus = await updateCurrentUser(updatedUser);
          if (updateStatus instanceof Error) {
            if (updateStatus.message === "ERROR_RPPS_ALREADY_USED") {
              errorToast("Ce RPPS est déjà associé à un autre utilisateur");
            } else {
              errorToast("Erreur lors de la sauvegarde du RPPS");
            }
          } else {
            appContext.dispatch({
              type: ActionTypes.UserUpdated,
              payload: updatedUser,
            });

            props.next(searchResults[0]);
          }
        } else if (searchResults.length < 1) {
          setStatus(LoadingStatus.ERROR);
          errorToast("Aucun résultat n'a été trouvé pour ce RPPS");
        } else {
          setStatus(LoadingStatus.ERROR);
          errorToast("Plusieurs résultats ont été trouvés pour ce RPPS");
        }
      }
    } else {
      if (otherTypeNumber === "") {
        errorToast("Vous devez renseigner un type de numéro");
        return;
      }

      if (otherNumber === "") {
        errorToast("Vous devez renseigner numéro professionnel");
        return;
      }

      const updatedUser: User = {
        ...appContext.state.user,
        rpps: otherTypeNumber + " : " + otherNumber,
      };

      const updateStatus = await updateCurrentUser(updatedUser);
      if (updateStatus instanceof Error) {
        errorToast("Erreur lors de la sauvegarde du numéro");
      } else {
        appContext.dispatch({
          type: ActionTypes.UserUpdated,
          payload: updatedUser,
        });
        const hpWithoutRpps: HealthProfessional = {
          city: "",
          civilTitle: CivilTitle.NONE,
          email: "",
          finessNumber: "",
          firstName: "",
          lastName: "",
          phone: "",
          profession: "",
          professionalTitle: HealthProfessionalTitle.NONE,
          rpps: otherTypeNumber + " : " + otherNumber,
          siteName: "",
          specialty: "",
        };
        props.next(hpWithoutRpps);
      }
    }
  };

  function StatusLabel(): JSX.Element {
    if (status === LoadingStatus.LOADING) {
      return <label>Nous recherchons vos informations</label>;
    }
    return <></>;
  }

  return (
    <>
      {!withoutRpps ? (
        <>
          <div style={{ marginTop: "5%" }}>
            Bonjour, <br />
            Veuillez renseigner votre numéro RPPS ou Adeli. Cela nous permettra
            de vous faire gagner du temps en pré-remplissant le formulaire
            d’inscription.
          </div>
          <br />
          <label className="form-label text-grey" htmlFor="rpps-input">
            Numéro RPPS ou Adeli
          </label>
          <input
            className="form-input mb-4"
            type="text"
            id="rpps-input"
            placeholder="XXXXXX"
            defaultValue={
              appContext.state.user.rpps ? appContext.state.user.rpps : ""
            }
            onChange={handleChangeRpps}
          />
          <span
            className="ml-auto mr-auto clickable-link"
            onClick={isWithoutRpps}
          >
            Médecin sans RPPS
          </span>
        </>
      ) : (
        <>
          <div style={{ marginTop: "5%" }}>
            Bonjour, <br />
            Veuillez renseigner votre numéro professionnel.
          </div>
          <br />
          <label className="form-label text-grey" htmlFor="rpps-input">
            Type de numéro
          </label>
          <input
            className="form-input mb-4"
            type="text"
            id="type-number-input"
            placeholder="XXXXXX"
            defaultValue={rpps.split(" : ")[0] ? rpps.split(" : ")[0] : ""}
            onChange={handleChangeTypeProNumber}
          />
          <label className="form-label text-grey" htmlFor="rpps-input">
            Numéro Professionnel
          </label>
          <input
            className="form-input mb-4"
            type="text"
            id="other-number-input"
            placeholder="XXXXXX"
            defaultValue={rpps.split(" : ")[1] ? rpps.split(" : ")[1] : ""}
            onChange={handleChangeProNumber}
          />
          <span
            className="ml-auto mr-auto clickable-link"
            onClick={isWithoutRpps}
          >
            Médecin avec RPPS
          </span>
        </>
      )}
      <StatusLabel />
      <div className="row" style={{ marginTop: "10%" }}>
        <button
          className="btn btn-block neuro-btn mr-2"
          style={{ width: "100%" }}
          onClick={onSubmit}
        >
          Valider
        </button>
      </div>
    </>
  );
};
