import React, { useContext } from "react";
import { useToggleClickOutside } from "../utils/useToggleClickOutside.hook";
import styles from "./ProfileMenu.module.css";
import { AppContext } from "../utils/state";
import { UserRoles } from "../domain/UserRoles";
import { Figure } from "./Shared/Figure";

interface Props {
  accessProfile: () => void;
  disconnect: () => void;
  accessAdminPanel: () => void;
  accessInvoicingPanel: () => void;
  accessImportPanel: () => void;
  accessContactPanel: () => void;
  accessGeneralTermsOfUsePanel: () => void;
  accessDataProtectionPanel: () => void;
  accessHelpPanel: () => void;
}

export const ProfileMenu: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [showDropDown, toggleDropdown] = useToggleClickOutside(false);

  const hasName =
    appContext.state.user.firstName || appContext.state.user.lastName;
  const name = `${appContext.state.user?.firstName} ${appContext.state.user?.lastName}`;

  return (
    <div className={styles.dropdown}>
      {hasName ? (
        <button
          className={"btn btn-link dropdown-toggle " + styles.figureBtn}
          id="profile-icon"
          onClick={toggleDropdown}
        >
          <Figure
            firstName={appContext.state.user.firstName}
            lastName={appContext.state.user.lastName}
            fontSize={"text-medium text-bold"}
          />
        </button>
      ) : (
        <button
          className={"btn dropdown-toggle " + styles.emailBtn}
          id="profile-icon"
          onClick={toggleDropdown}
        >
          {appContext.state.user.email}
        </button>
      )}
      {showDropDown && (
        <div className={styles.dropdownContent + " " + styles.btnContainer}>
          <div className={"text-bold text-centered " + styles.nameContainer}>
            {hasName ? name : appContext.state.user.email}
          </div>
          <button
            className={styles.menuItem}
            id="show-profile-panel-btn"
            onClick={props.accessProfile}
          >
            Profil
          </button>
          {appContext.state.user.roles.includes(UserRoles.ADMIN) && (
            <>
              <button
                className={styles.menuItem}
                id="show-admin-panel-btn"
                onClick={props.accessAdminPanel}
              >
                Admin
              </button>
              <button
                className={styles.menuItem}
                id="show-billing-panel-btn"
                onClick={props.accessInvoicingPanel}
              >
                Facturation
              </button>
              <button
                className={"btn " + styles.menuItem}
                id="show-import-panel-btn"
                onClick={props.accessImportPanel}
              >
                Référentiels
              </button>
            </>
          )}
          <button
            className={styles.menuItem}
            id="contact-btn"
            onClick={props.accessContactPanel}
          >
            Contact
          </button>
          <button
            className={styles.menuItem}
            id="tou-btn"
            onClick={props.accessGeneralTermsOfUsePanel}
          >
            CGU
          </button>
          <button
            className={styles.menuItem}
            id="data-protection-btn"
            onClick={props.accessDataProtectionPanel}
          >
            Données personnelles
          </button>
          <button
            className={styles.menuItem}
            id="help-btn"
            onClick={props.accessHelpPanel}
          >
            Aide
          </button>
          <button
            className={styles.menuItem}
            id="disconnect-btn"
            onClick={props.disconnect}
          >
            Déconnexion
          </button>
        </div>
      )}
    </div>
  );
};
