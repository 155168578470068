import { Attachment } from "../../domain/OpinionRequest";
import React, { useRef, useState } from "react";
import { uploadDischargeSummary } from "../../services/OpinionRequestService";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { errorToast, successToast } from "../../utils/Toast";
import styles from "./UploadDischargeSummary.module.css";

interface Props {
  requestId?: string;
  onDocumentLoaded: () => void;
}

export const UploadDischargeSummary: React.FC<Props> = (props: Props) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  async function onChangeFileHandler(event: any): Promise<void> {
    if (!props.requestId) {
      return;
    }
    const data = new FormData();
    const file = event.target.files[0];

    data.append("file", file);

    setStatus(LoadingStatus.LOADING);

    const res = await uploadDischargeSummary(props.requestId, data);
    if (res instanceof Error) {
      errorToast("Echec de l'envoi du fichier");
      setStatus(LoadingStatus.ERROR);
      return;
    }
    setStatus(LoadingStatus.SUCCESS);
    props.onDocumentLoaded();
    successToast("Données chargées");
    const newAttachments: Attachment[] = [
      ...attachments,
      { Id: res, Filename: file.name },
    ];
    setAttachments(newAttachments);
  }

  const fileButtonHandler = (): any => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  if (!props.requestId) {
    return (
      <div>
        <h4 className="form-title">Importer un CRH</h4>
        <div>
          Vous devez enregistrer la demande avant de pouvoir ajouter des pièces
          jointes
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4 className="form-title">Importer un CRH</h4>
      <div>
        Si vous disposez d&apos;un compte rendu d&apos;hospitalisation, nous
        pouvons pré-remplir le formulaire avec les informations concernant le
        patient.
      </div>
      <div className="row">
        <div className="cr-upload row">
          <button
            className={"btn btn-sm neuro-icons-btn ml-2 " + styles.importButton}
            onClick={fileButtonHandler}
          >
            <i className={"icon icon-plus " + styles.importButtonIcon} />
            Importer un compte rendu
          </button>
          <input
            id="file-input"
            type="file"
            ref={hiddenFileInput}
            onChange={onChangeFileHandler}
          />
        </div>
      </div>
      <div>
        {status === LoadingStatus.LOADING && "Envoi du fichier..."}
        {status === LoadingStatus.SUCCESS && (
          <div className="text-grey">
            Merci, le formulaire a été initialisé avec les données patient
            extraites du compte rendu
          </div>
        )}
      </div>
    </div>
  );
};
