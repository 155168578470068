import React, { useEffect, useState } from "react";
import history from "../../utils/history";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { Controller, useForm } from "react-hook-form";
import { AdminProfile } from "../../domain/Profile";
import { getUser, updateUserByAdmin } from "../../services/UserService";
import { errorToast, successToast } from "../../utils/Toast";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { UserStatus } from "../../domain/UserStatus";
import {
  CivilTitle,
  getCivilTitleLabel,
  getProfessionalTitleLabel,
  HealthProfessionalTitle,
  User,
} from "../../domain/User";
import { UserRoles } from "../../domain/UserRoles";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./AdminViewProfile.module.css";

const animatedComponents = makeAnimated();

interface ParamTypes {
  id: string;
}

export const AdminUpdateProfile: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [user, setUser] = useState<User>();
  const availableRoles = [
    { value: UserRoles.ADMIN, label: "Administrateur" },
    { value: UserRoles.GLOBAL_DISPATCHER, label: "Régulateur" },
  ];
  const [selectedRole, setSelectedRole] = useState<any>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");

  useEffect(() => {
    getUser(id).then((res: User | Error) => {
      if (res instanceof Error) {
        setStatus(LoadingStatus.ERROR);
        setErrorMessage(res.message);
      } else {
        setUser(res);
        setPhoneNumber(res.phone);
        setStatus(LoadingStatus.SUCCESS);
      }
    });
  }, [id]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminProfile>();

  const onSubmit = async (data: AdminProfile): Promise<void> => {
    if (phoneNumberError !== "") {
      return;
    }

    let role: string[] = [];
    if (selectedRole != [] && selectedRole != null) {
      role = selectedRole.map((x: any) => x.value);
    }

    const userUpdated: User = {
      ...user,
      id: id,
      email: data.email,
      professionalTitle:
        user?.professionalTitle || HealthProfessionalTitle.NONE,
      civilTitle: user?.civilTitle || CivilTitle.NONE,
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: phoneNumber || "",
      specialty: user?.specialty || "",
      roles: role,
      status: UserStatus.VALIDATED,
      organizations: user?.organizations || [],
    };
    const res = await updateUserByAdmin(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition de l'utilisateur");
    } else {
      successToast("Utilisateur mis à jour");
      history.push(`/admin/users/${id}/view`);
    }
  };

  const onSelectRoles = (selectedValues: any): void => {
    setSelectedRole(selectedValues);
  };

  const handlePhoneNumberChange = (input: string): void => {
    if (input === "") {
      setPhoneNumber("+");
    } else {
      setPhoneNumber(input);
    }
    if (input.length < 11) {
      setPhoneNumberError("Numéro de téléphone incorrect");
    } else {
      setPhoneNumberError("");
    }
  };

  if (status === LoadingStatus.ERROR) {
    return <div>Error: {errorMessage}</div>;
  } else if (status === LoadingStatus.LOADING) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <PageHeaderWithTitle
          title={"Mettre à jour ce profil"}
          backButtonRedirectsTo={`/admin/users/${id}/view`}
        />
        <div className="page-container">
          <div className="profile-white-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-horizontal">
                <label className="form-label">Civilité</label>
                <input
                  id="admin-civil-title"
                  name="civilTitle"
                  className="form-input"
                  type="text"
                  placeholder="Titre inconnu"
                  defaultValue={getCivilTitleLabel(user?.civilTitle)}
                  disabled={true}
                />
                <label className="form-label">Titre</label>
                <input
                  id="admin-professional-title"
                  name="professionalTitle"
                  className="form-input"
                  type="text"
                  placeholder="Titre inconnu"
                  defaultValue={getProfessionalTitleLabel(
                    user?.professionalTitle
                  )}
                  disabled={true}
                />
                <div className="form-group mt-2">
                  <label
                    className="form-label"
                    htmlFor="admin-profile-firstName"
                  >
                    Prénom
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    id="admin-profile-firstName"
                    defaultValue={user?.firstName}
                    placeholder="Prénom"
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="admin-profile-lastName"
                  >
                    Nom
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={user?.lastName}
                    id="admin-profile-lastName"
                    placeholder="Nom"
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="admin-profile-email">
                    Email
                  </label>
                  <span className="error-msg text-large mt-1 ml-2">*</span>
                  <input
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Addresse email incorrecte",
                      },
                      required: "Email obligatoire",
                    })}
                    className="form-input"
                    type="text"
                    defaultValue={user?.email}
                    id="admin-profile-email"
                    placeholder="Email"
                  />
                  {errors?.email && (
                    <span className="error-msg">{errors.email.message}</span>
                  )}
                </div>
                <div className="form-group mb-0">
                  <label className="form-label" htmlFor="admin-profile-phone">
                    Téléphone (format: +33 6 12 34 56 78)
                  </label>
                  <span className="error-msg text-large mt-1 ml-2">*</span>
                </div>
                <div className="form-group">
                  <div id="admin-profile-phone">
                    <PhoneInput
                      country={"fr"}
                      preferredCountries={["fr"]}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="error-msg">{phoneNumberError}</div>
                </div>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="admin-profile-profession"
                  >
                    Profession
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={user?.profession}
                    id="admin-profile-profession"
                    placeholder="Profession"
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="admin-profile-specialty"
                  >
                    Spécialité
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    defaultValue={user?.specialty}
                    id="admin-profile-specialty"
                    placeholder="Aucune spécialité"
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Rôles</label>
                  <span className="error-msg text-large mt-1 ml-2">*</span>
                  <Controller
                    name="roles"
                    control={control}
                    render={(): any => (
                      <Select
                        options={availableRoles}
                        isMulti
                        components={animatedComponents}
                        closeMenuOnSelect={false}
                        placeholder="Rôles"
                        defaultValue={availableRoles.filter((role) =>
                          user?.roles?.includes(role.value)
                        )}
                        onChange={onSelectRoles}
                        styles={{
                          menu: (): Record<string, any> => ({
                            width: "100%",
                            border: "solid 1px var(--light-grey)",
                            borderRadius: "6px",
                          }),
                          container: (): Record<string, any> => ({
                            width: "100%",
                          }),
                          control: (
                            provided: any,
                            state: any
                          ): Record<string, any> => ({
                            ...provided,
                            border:
                              state.isFocused &&
                              "solid 2px var(--primary-blue)",
                            boxShadow: state.isFocused && "var(--primary-blue)",
                            ":hover": {
                              border:
                                state.isFocused &&
                                "solid 2px var(--primary-blue)",
                              boxShadow:
                                state.isFocused && "var(--primary-blue)",
                            },
                          }),
                          option: (
                            provided: any,
                            state: any
                          ): Record<string, any> => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "var(--secondary-blue)"
                              : "white",
                            ":hover": {
                              backgroundColor: "var(--secondary-blue)",
                            },
                          }),
                        }}
                      />
                    )}
                  />
                </div>
                <div className="margin-top-2 text-right">
                  <span className="error-msg text-large">*</span>
                  <span className="error-msg text-medium ml-1">
                    Champs obligatoires
                  </span>
                </div>
                <div
                  className={`d-flex m-auto text-centered mt-2 ${styles.buttonsContainer}`}
                >
                  <button
                    onClick={() => history.push(`/admin/users/${id}/view`)}
                    className={`btn rectangular-btn close-btn ${styles.firstBtn}`}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className={`btn rectangular-btn neuro-btn ${styles.secondBtn}`}
                  >
                    Sauvegarder les modifications
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};
