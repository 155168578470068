import { SearchHealthProfessional } from "../OpinionRequestForm/SearchHealthProfessional";
import { HealthProfessionalUser, ProfessionCategory } from "../../domain/User";
import { AddNewUser } from "../OpinionRequestForm/Participants/AddNewUser";
import React, { useState } from "react";
import styles from "./AdminViewUpdateOrganization.module.css";
import { UserChip } from "../Shared/UserChip";
import { createUser } from "../../services/UserService";
import { ErrorResponse } from "../../domain/ErrorManagement";
import { errorToast } from "../../utils/Toast";

interface Props {
  roleLabel: string;
  members: HealthProfessionalUser[];
  onAddMember: (g: HealthProfessionalUser) => any;
  onRemoveMember: (g: HealthProfessionalUser) => any;
}

export const SearchAndAddMembersCard: React.FC<Props> = (props: Props) => {
  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const createNewUser = async (
    user: HealthProfessionalUser
  ): Promise<HealthProfessionalUser> => {
    const creationUserResponse = await createUser(user);
    if (
      creationUserResponse instanceof ErrorResponse ||
      creationUserResponse instanceof Error
    ) {
      errorToast(
        `Erreur lors de la mise à jour de l'organisation, le membre ${user.firstName} ${user.lastName} n'a pas été ajouté`
      );
    }
    return {
      userId: creationUserResponse.message,
      professionalTitle: user?.professionalTitle,
      firstName: user?.firstName,
      lastName: user?.lastName,
    };
  };

  return (
    <>
      <label className="form-label mt-2">{props.roleLabel}</label>
      <div className="flex-row mb-2">
        <div className={styles.userSelectContainer}>
          {props.members.map((member, key): any => {
            return (
              <div className="mr-1" key={key}>
                <UserChip
                  professionalTitle={member.professionalTitle}
                  firstName={member.firstName}
                  lastName={member.lastName}
                  onDelete={(): void => props.onRemoveMember(member)}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex-row">
        <SearchHealthProfessional
          onSelected={(newMember: HealthProfessionalUser): any => {
            if (newMember?.userId === "") {
              setHpToAdd(newMember);
              setShowModal(true);
            } else {
              props.onAddMember(newMember);
            }
          }}
          selectedHealthProfessionals={props.members}
          professionFilter={ProfessionCategory.ALL}
        />
        <AddNewUser
          userToAdd={hpToAdd}
          show={showModal}
          onClose={(): any => showModal}
          filterUsersRpps={props.members.map(
            (u: HealthProfessionalUser): any => u.userId
          )}
          onAddUser={(hp: HealthProfessionalUser): any => {
            setShowModal(false);
            createNewUser(hp).then((addedMember) =>
              props.onAddMember(addedMember)
            );
          }}
        />
      </div>
    </>
  );
};
