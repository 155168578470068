import React, { useCallback, useEffect, useState } from "react";
import { SearchFacilities } from "../SearchFacilities";
import { errorToast } from "../../utils/Toast";
import Select from "react-select";
import { Address, searchAddress } from "../../services/AddressService";
import { PatientAddress } from "../../domain/OpinionRequest";
import { getFacility } from "../../services/FacilityService";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import { Facility } from "../../domain/Facility";
import { useController } from "react-hook-form";

export enum PatientLocation {
  NONE = "NONE",
  HOME = "HOME",
  FACILITY = "FACILITY",
}

export const PatientLocationForm: React.FC<any> = (props: {
  control: any;
  readOnly?: any;
}) => {
  const readOnly = props.readOnly;
  const [results, setResults] = useState<Address[]>();
  const [facility, setFacility] = useState<Facility>();

  const { field: addressField } = useController({
    name: "patient.address",
    control: props.control,
  });
  const { field: facilityField } = useController({
    name: "patient.facilityId",
    control: props.control,
  });
  const { field: locationField } = useController({
    name: "patient.location",
    control: props.control,
  });

  const removeFacility: any = () => {
    setFacility(undefined);
    facilityField.onChange(undefined);
  };

  const getFacilityById = useCallback(async (): Promise<any> => {
    const res = await getFacility(facilityField.value);
    if (res instanceof Error || res == null) {
      errorToast("Une erreur est survenue lors de la recherche");
    } else {
      setFacility(res);
    }
  }, [facilityField.value]);

  useEffect(() => {
    if (facilityField.value !== undefined && facilityField.value !== "") {
      getFacilityById();
    }
  }, [facilityField.value, getFacilityById]);

  const search = async (query: string): Promise<any> => {
    const res = await searchAddress(query);
    if (res instanceof Error) {
      setResults(undefined);
      errorToast("Une erreur est survenue lors de la recherche");
    } else {
      setResults(res);
    }
  };

  const handleChange = (query: string): void => {
    if (query !== "") {
      search(query);
    }
  };

  const formatResponse = (patientAddress: PatientAddress): Address => {
    return {
      label: patientAddress.label,
      name: patientAddress.street,
      postcode: patientAddress.postcode,
      city: patientAddress.city,
    };
  };

  const formatOptionLabel = ({
    name,
    postcode,
    city,
  }: {
    name: string;
    postcode: string;
    city: string;
  }): any => (
    <div>
      {name}
      <span className={"text-bold"}>
        {" "}
        {postcode} {city}
      </span>
    </div>
  );

  useEffect(() => {
    if (locationField.value === PatientLocation.HOME) {
      setFacility(undefined);
    }
  }, [locationField.value]);

  function clearAddress() {
    const emptyAddress: PatientAddress = {
      label: "",
      street: "",
      city: "",
      postcode: "",
    };
    addressField.onChange(emptyAddress);
  }

  return (
    <>
      <div className="form-group flex-col">
        <label className="form-label">Localisation</label>
        <div className="row space-around-content">
          <label className="form-radio mb-0 mt-0">
            <input
              type="radio"
              value={PatientLocation.HOME}
              checked={locationField.value === PatientLocation.HOME}
              onChange={() => locationField.onChange(PatientLocation.HOME)}
              disabled={readOnly}
            />
            <i className="form-icon" id="home-checkbox" /> Domicile
          </label>
          <label className="form-radio mb-0 mt-0">
            <input
              type="radio"
              checked={locationField.value === PatientLocation.FACILITY}
              value={PatientLocation.FACILITY}
              onChange={() => locationField.onChange(PatientLocation.FACILITY)}
              disabled={readOnly}
            />
            <i className="form-icon" id="facility-checkbox" /> Centre de soins
          </label>
        </div>
      </div>

      {locationField.value === PatientLocation.HOME && (
        <div className="form-group flex-col">
          <Select
            {...addressField}
            onInputChange={handleChange}
            placeholder="Rechercher une adresse"
            noOptionsMessage={(): any =>
              "Aucun résultat, veuillez compléter l'adresse"
            }
            options={results}
            value={formatResponse(addressField.value)}
            formatOptionLabel={formatOptionLabel}
            onChange={(option: any): void => {
              let selectedAddress: PatientAddress;
              if (option !== null) {
                selectedAddress = {
                  label: option?.label,
                  street: option?.name,
                  postcode: option?.postcode,
                  city: option?.city,
                };
              } else {
                selectedAddress = {
                  label: "",
                  street: "",
                  postcode: "",
                  city: "",
                };
              }
              addressField.onChange(selectedAddress);
              removeFacility();
            }}
            isClearable={true}
            className="white-background w-90"
            id="home-address-select"
            styles={{
              option: (provided): Record<string, any> => ({
                ...provided,
                backgroundColor: "white",
                ":hover": {
                  backgroundColor: "var(--secondary-blue)",
                },
                color: "black",
                whiteSpace: "pre-wrap",
                textAlign: "left",
              }),
              dropdownIndicator: (): Record<string, any> => ({
                color: "var(--primary-blue)",
                alignItems: "baseline",
                alignSelf: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }),
              clearIndicator: (): Record<string, any> => ({
                color: "var(--primary-blue)",
                alignItems: "baseline",
                alignSelf: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }),
              control: (): Record<string, any> => ({
                borderRadius: "6px",
                border: "solid 1px var(--primary-blue)",
                boxShadow: "var(--primary-blue)",
                display: "flex",
              }),
            }}
          />

          {addressField.value?.street && (
            <div className="column margin-top-2">
              <div className="text-bold">Adresse sélectionnée :</div>
              <div className="">
                <div>{addressField.value?.street}</div>
              </div>
              <div className="row">
                <div>{addressField.value?.postcode}</div>
                <div className="ml-2">{addressField.value?.city}</div>
              </div>
            </div>
          )}
        </div>
      )}
      {locationField.value === PatientLocation.FACILITY && (
        <div className="form-group flex-col w-90 ml-1">
          <SearchFacilities
            {...facilityField}
            onSelect={(facilityCenter: Facility) => {
              setFacility(facilityCenter);
              facilityField.onChange(facilityCenter.id);
              clearAddress();
            }}
            autoFocus={true}
          />

          <div className="mt-4">
            {facility && (
              <FacilityCard
                isAddService={false}
                facility={facility}
                handleRemove={removeFacility}
                showRemoveButton={true}
              />
            )}
          </div>
        </div>
      )}
      <div className="margin-top-2 text-right mr-4 mb-2">
        <span className="error-msg text-large">*</span>
        <span className="error-msg text-medium ml-1">Champ obligatoire</span>
      </div>
    </>
  );
};
