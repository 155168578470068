import React from "react";
import styles from "./ModalDocumentationCps.module.css";

export const IOSDocumentation: React.FC = () => {
  return (
    <div className={styles.documentationContent}>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>1</div>
        Téléchargez le fichier d&apos;installation
      </div>
      <div
        className={`flex-col text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <span>
          Télécharger et extraire
          <a
            className={`${styles.downloadLink} ml-1`}
            href="https://icanopee-binary-files.cellar-c2.services.clever-cloud.com/DmpConnectJS2-0.9.4-dev.dmg"
          >
            DmpConnectJS2-0.9.4-dev.dmg
          </a>
        </span>
      </div>

      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>2</div>
        Finir l&apos;installation
      </div>
      <div
        className={`flex-col text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <div
          className={`text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
        >
          Suivez l&apos;installation des composants.
        </div>
      </div>
    </div>
  );
};
