import React, { useEffect, useState } from "react";
import { LoadingStatus } from "../../../utils/LoadingStatus";
import { IcanopeeDmpService } from "../../../services/icanopee/IcanopeeDmpService";
import {
  convertStringToDate,
  DmpDocument,
  DmpError,
  showDmpError,
} from "../../../services/DmpService";
import { DmpDocumentView } from "./DmpDocumentView";
import DmpConsulter from "../../../assets/dmp/DMP-Consulter.png";
import DmpAlimenter from "../../../assets/dmp/DMP-Alimenter.png";
import styles from "./DmpDocuments.module.css";
import { ModalDocumentationCps } from "../../OpinionRequestForm/DocumentationCPS/ModalDocumentationCps";
import { UploadToDmp } from "../../OpinionRequestForm/UploadToDmp";
import OpinionRequest from "../../../domain/OpinionRequest";
import { RequestStatus } from "../../../domain/RequestStatus";
import { IcanopeeError } from "../../../services/icanopee/IcanopeeService";
import config from "../../../utils/config";
import { ModalInfo } from "../../Shared/ModalInfo";
import { errorToast } from "../../../utils/Toast";

interface Props {
  patientIns?: string;
  patientBirthdate?: string; //YYYY-MM-DD
  request?: OpinionRequest;
}

export const DmpDocuments: React.FC<any> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const [documents, setDocuments] = useState<DmpDocument[]>([]);
  const [requestUploadDmpDate, setRequestUploadDmpDate] = useState<string>("");
  const [showDocumentationCps, setShowDocumentationCps] = useState<boolean>(
    false
  );
  const [showUploadToDmpModal, setShowUploadToDmpModal] = useState<boolean>(
    false
  );
  const [cpsPinCode, setCpsPinCode] = useState<string>("");
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);

  const renderResult = () => {
    switch (status) {
      case LoadingStatus.IDLE:
      case LoadingStatus.SUCCESS:
        return "";
      case LoadingStatus.LOADING:
        return "Recherche du DMP en cours...";
      case LoadingStatus.ERROR:
        return <span className="red-text">Erreur lors de la recherche</span>;
      default:
        return "En attente";
    }
  };

  function isPropsValid(): boolean {
    return !!(
      props.patientBirthdate &&
      props.patientBirthdate.length == 10 &&
      props.patientIns
    );
  }

  async function getDmpDocuments() {
    setStatus(LoadingStatus.LOADING);
    const icanopeeDmpService = new IcanopeeDmpService();
    try {
      if (isPropsValid()) {
        const dmpDocuments = await icanopeeDmpService.getPatientDocuments(
          {
            ins: props.patientIns ?? "",
            birthday: props.patientBirthdate ?? "",
          },
          String(cpsPinCode)
        );
        dmpDocuments.sort((a, b) => {
          return (
            convertStringToDate(b.creationDate).getTime() -
            convertStringToDate(a.creationDate).getTime()
          );
        });
        setDocuments(dmpDocuments);
        setStatus(LoadingStatus.SUCCESS);
      }
    } catch (e) {
      setCpsPinCode("");
      setStatus(LoadingStatus.ERROR);
      if (e !== DmpError.NO_CARD_READER_INSTALLED) {
        showDmpError(e);
      }
      if (e === IcanopeeError.ERROR_FUNCTIONAL) {
        setShowDocumentationCps(true);
      }
      if (e === IcanopeeError.COMPONENT_INITIAL_CODE) {
        setShowPinCodeModal(true);
        setStatus(LoadingStatus.ERROR);
      }
      if (e === IcanopeeError.CARD_ERROR) {
        errorToast("Pas de carte CPS insérée");
      }
    }
  }

  useEffect(() => {
    isAlreadyUploadedToDmp();
  }, [requestUploadDmpDate]);

  function isAlreadyUploadedToDmp() {
    return !!props.request?.uploadDmpDate;
  }

  const dmpFeature = config.dmpFeature;

  return (
    <div>
      <button
        className={`btn secondary-btn ${styles.dmpBtn}`}
        onClick={getDmpDocuments}
        disabled={!isPropsValid() || !dmpFeature}
      >
        <img
          className={`mr-4 ${styles.dmpIconMedium}`}
          alt="icon-dmp-generique"
          src={DmpConsulter}
        />
        <label>Consulter le DMP</label>
      </button>
      {props.request && props.request.status == RequestStatus.CLOSED && (
        <button
          className={`btn secondary-btn ${styles.dmpBtn} tooltip`}
          onClick={() => setShowUploadToDmpModal(true)}
          disabled={!isPropsValid() || isAlreadyUploadedToDmp()}
        >
          <img
            className={`mr-4 ${styles.dmpIconMedium}`}
            alt="icon-dmp-generique"
            src={DmpAlimenter}
          />
          <label>Alimenter le DMP</label>
          {isAlreadyUploadedToDmp() && (
            <span className="tooltiptext">
              Le compte-rendu a déjà <br /> été envoyé dans le DMP
              <div className="top-triangle" />
            </span>
          )}
        </button>
      )}
      {documents.map((document, index) => (
        <DmpDocumentView
          key={index}
          document={document}
          patient={{ ins: props.patientIns, birthday: props.patientBirthdate }}
          onDelete={getDmpDocuments}
        />
      ))}
      {!dmpFeature && (
        <div>
          <i>La fonctionnalité est pour le moment désactivée</i>
        </div>
      )}
      {dmpFeature && !isPropsValid() && (
        <div>
          <i>
            La date de naissance complète et l&apos;INSi (Identifiant National
            de Santé intégré) du patient doivent être renseignés pour accéder à
            cette fonctionnalité.
          </i>
        </div>
      )}
      <div className="row ">
        <div>{renderResult()}</div>
      </div>
      {showDocumentationCps && (
        <ModalDocumentationCps
          onClose={(): void => setShowDocumentationCps(false)}
        />
      )}
      {showUploadToDmpModal && props.request && (
        <UploadToDmp
          isSendableToDmp={isPropsValid()}
          onClose={(isSuccess): any => {
            setShowUploadToDmpModal(false);
            if (isSuccess && props.request) {
              props.request.uploadDmpDate = Date.now().toString();
              setRequestUploadDmpDate(Date.now().toString());
            }
          }}
          request={props.request}
        />
      )}
      {showPinCodeModal && (
        <ModalInfo
          title={"Confirmation de votre identité"}
          onClose={(): any => setShowPinCodeModal(false)}
          rightButtonAction={getDmpDocuments}
          isRightButtonDisabled={cpsPinCode.length !== 4}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <div className="flex-col p-2">
            <p>
              Veuillez entrer ci-dessous le code correspondant à votre profil de
              professionnel de la santé:
            </p>
            <input
              type="number"
              className={styles.codeInput}
              defaultValue={""}
              min={0}
              onChange={(event) => setCpsPinCode(event.target.value)}
            />
          </div>
        </ModalInfo>
      )}
    </div>
  );
};
