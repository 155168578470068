import React from "react";
import Modal from "../../Modal";

interface Props {
  onClose: () => void;
}

export const ModalEegRate: React.FC<Props> = (props: Props) => {
  const closeModal = (): void => {
    props.onClose();
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Tarifs de lecture d'EEG par établissement"}
    ></Modal>
  );
};
