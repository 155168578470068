import OpinionRequest, {
  Attachment,
  ImagingStudy,
  OpinionRequestSummary,
} from "../domain/OpinionRequest";
import { ExpertReply } from "../domain/ExpertReply";
import { get, send } from "./ApiClient";
import config from "../utils/config";
import { User } from "../domain/User";
import { DeclineMotive } from "../components/Request/ExpertDeclineOpinionRequest";
import { ErrorResponse } from "../domain/ErrorManagement";
import { IcanopeeDmpService } from "./icanopee/IcanopeeDmpService";
import { DmpInfo } from "./DmpService";
import { Organization } from "../domain/Organization";

export async function getOpinionRequest(
  id: string
): Promise<OpinionRequest | Error> {
  return get(`/opinion-request/${id}`);
}

export async function saveOpinionRequest(
  request: OpinionRequest
): Promise<OpinionRequest | Error> {
  let result: OpinionRequest | Error;
  if (!request.id) {
    result = await send("/opinion-request", "POST", request);
  } else {
    result = await send(`/opinion-request/${request.id}`, "POST", request);
  }
  if (result instanceof Error) {
    return result;
  }
  if (!result.id) {
    return new Error("result.id n'existe pas");
  }

  return getOpinionRequest(result.id);
}

export async function submitOpinionRequest(
  request: OpinionRequest
): Promise<OpinionRequest | Error | ErrorResponse> {
  console.info("Submitting opinion request", request);
  // First, save the request
  const res = await saveOpinionRequest(request);
  if (res instanceof Error) {
    console.error("Failed to submit opinion request", res);
    return res;
  }
  // Then submit
  return send(`/opinion-request/${request.id}/submit`, "POST");
}

export async function getOpinionRequestMissingFields(
  requestId: string
): Promise<string[] | Error> {
  console.info("Get the list of missing fields of opinion request", requestId);
  return get(`/opinion-request/${requestId}/check`);
}

export async function setOpinionRequestExpertsAndOrganizations(
  experts: User[],
  organizations: Organization[],
  requestId: string
): Promise<OpinionRequest | Error> {
  return send(`/opinion-request/${requestId}/assign`, "POST", {
    experts: experts,
    organizations: organizations,
  });
}

export async function setOpinionRequestReply(
  reply: ExpertReply,
  requestId: string
): Promise<ExpertReply | Error> {
  return send(`/opinion-request/${requestId}/reply`, "POST", reply);
}

export async function setDraftOpinionRequestReply(
  draftReply: ExpertReply,
  requestId: string
): Promise<ExpertReply | Error> {
  return send(`/opinion-request/${requestId}/draftReply`, "POST", draftReply);
}

export async function setOpinionRequestUploadToDmpDate(
  requestId: string
): Promise<void | Error> {
  return send(
    `/opinion-request/${requestId}/set-report-uploaded-to-dmp`,
    "POST"
  );
}

export async function validateOpinionRequest(
  requestId: string
): Promise<OpinionRequest | Error> {
  console.info("Validating opinion request id", requestId);
  return get(`/opinion-request/${requestId}/validate`);
}

export async function acceptRequestByExpert(
  requestId: string,
  billingFacilityId: string
): Promise<OpinionRequest | Error> {
  return send(`/expert/opinion-request/${requestId}/accept`, "POST", {
    billingFacilityId,
  });
}

export async function declineRequestByExpert(
  requestId: string,
  comment: string,
  motive: DeclineMotive
): Promise<OpinionRequest | Error> {
  return send(`/expert/opinion-request/${requestId}/decline`, "POST", {
    comment: comment,
    motive: motive,
  });
}

export async function listOpinionRequests(
  role: string
): Promise<OpinionRequestSummary[] | Error> {
  return get("/opinion-request?role=" + role);
}

export function getAttachmentUrl(requestId?: string, fileId?: string): string {
  return `${config.apiUrl}/opinion-request/${requestId}/docs/${fileId}`;
}

export function getResponsePdfUrl(requestId?: string): string {
  return `${config.apiUrl}/opinion-request/${requestId}/response/pdf`;
}

/**
 * Upload a file as attachment to an opinion request
 * @param requestId Id of the request
 * @param data FormData object containing the file
 * @return Id of the new file, or error
 */
export async function uploadAttachment(
  requestId: string,
  data: FormData
): Promise<Attachment | Error> {
  const res: OpinionRequest | Error = await send(
    `/opinion-request/${requestId}/docs`,
    "POST",
    null,
    {
      body: data, // Set the body in config to bypass conversion of body to JSON
    }
  );
  if (res instanceof Error) {
    return res;
  } else {
    return await get(`/opinion-request/${requestId}/files/${res.id}`);
  }
}

/**
 * Upload a dicom image to orthanc for an opinion request (.dcm only)
 * @param requestId Id of the request
 * @param data FormData object containing the file
 * @return Id of the new file, or error
 */
export async function uploadDicomImage(
  requestId: string,
  data: FormData
): Promise<ImagingStudy | Error> {
  return send(`/opinion-request/${requestId}/dicom`, "POST", null, {
    body: data, // Set the body in config to bypass conversion of body to JSON
  });
}

/**
 * Upload a discharge summary which will be used to extract data about the patient and fill the opinion request
 * This file will be added to the attachments of the opinion request
 *
 * @param requestId Id of the request
 * @param data FormData object containing the file
 * @return Id of the new file, or error
 */
export async function uploadDischargeSummary(
  requestId: string,
  data: FormData
): Promise<string | Error> {
  const res: { fileId: string; message: string } | Error = await send(
    `/opinion-request/${requestId}/upload-summary`,
    "POST",
    null,
    {
      body: data, // Set the body in config to bypass conversion of body to JSON
    }
  );
  if (res instanceof Error) {
    return res;
  } else if (res.fileId) {
    return res.fileId;
  } else {
    return new Error("res.id is undefined");
  }
}

export async function deleteAttachment(
  requestId: string,
  fileId: string
): Promise<string | Error> {
  return send(
    `/opinion-request/${requestId}/docs/${fileId}`,
    "DELETE",
    null,
    null
  );
}

export async function deleteImagingStudy(
  requestId: string,
  studyInstanceId: string
): Promise<string | Error> {
  return send(
    `/opinion-request/${requestId}/study/${studyInstanceId}`,
    "DELETE",
    null,
    null
  );
}

export async function getOpinionRequestExperts(
  requestId: string
): Promise<User[] | Error> {
  return get(`/opinion-request/${requestId}/expert`);
}

export async function setLastViewedDate(
  requestId: string
): Promise<ExpertReply | Error> {
  return send(`/opinion-request/${requestId}/viewed`, "POST");
}

export async function addParticipant(
  requestId: string,
  body: any
): Promise<any | Error | ErrorResponse> {
  return send(`/opinion-request/${requestId}/participant`, "POST", body);
}

export async function updateParticipant(
  requestId: string,
  participantId: string,
  body: any
): Promise<any | Error | ErrorResponse> {
  return send(
    `/opinion-request/${requestId}/participant/${participantId}`,
    "PUT",
    body
  );
}

export async function deleteParticipant(
  requestId: string,
  participantId: string
): Promise<any | Error | ErrorResponse> {
  return send(
    `/opinion-request/${requestId}/participant/${participantId}`,
    "DELETE"
  );
}

export async function deleteOpinionRequest(
  requestId: string
): Promise<any | Error> {
  return send(`/opinion-request/${requestId}`, "DELETE", null, null);
}

async function getTlmCrInBase64(requestId: string): Promise<any | Error> {
  return send(`/opinion-request/${requestId}/dmp`, "GET", null, null);
}

export async function requestVisio(requestId: string): Promise<any | Error> {
  return send(`/opinion-request/${requestId}/visio`, "POST", null, null);
}

export async function sendTlmCrToDmp(
  requestId: string,
  replyDescription: string,
  healthcareSetting: string,
  patient: DmpInfo,
  cpsPinCode: number
) {
  const res = await getTlmCrInBase64(requestId);
  if (res instanceof Error) {
    console.error("Couldn't get file in base64");
    return res;
  } else {
    const icanopeeDmpService = new IcanopeeDmpService();
    try {
      await icanopeeDmpService.uploadPatientDocuments(
        patient,
        res,
        replyDescription,
        healthcareSetting,
        String(cpsPinCode)
      );
    } catch (e) {
      console.error("Unexpected error", e);
      return e;
    }
  }
}

export function getPatientAge(birthday: any): string | undefined {
  try {
    if (!birthday.year) {
      return undefined;
    }
    const today = new Date();
    let age = today.getFullYear() - birthday.year;
    if (!birthday.month) {
      if (age === 0) {
        return "0 an";
      } else if (age === 1) {
        return "0-1 an";
      } else {
        return age - 1 + "-" + age + " ans";
      }
    }

    const monthOfBirth = parseInt(birthday.month);
    const monthDiff = today.getMonth() - monthOfBirth + 1; // +1 because today's month starts at 0

    let dayDiff = 0;
    if (birthday.day) {
      const dayOfBirth = parseInt(birthday.day);
      dayDiff = today.getDate() - dayOfBirth;
    }

    // get exact age in years and months
    let month = 0;
    if (monthDiff === 0 && dayDiff < 0) {
      age--;
      month = 11;
    } else if (monthDiff < 0 && dayDiff < 0) {
      age--;
      month = 12 + monthDiff - 1;
    } else if (monthDiff < 0 && dayDiff >= 0) {
      age--;
      month = 12 + monthDiff;
    } else if (monthDiff > 0 && dayDiff < 0) {
      month = monthDiff - 1;
    } else if (monthDiff > 0 && dayDiff >= 0) {
      month = monthDiff;
    }

    // if patient is less than 2 years, return age in months, else return age in years
    if (age < 2) {
      const ageInMonths = age * 12 + month;
      return ageInMonths > 0 ? ageInMonths + " mois" : undefined;
    } else {
      return age + " ans";
    }
  } catch (e) {
    console.error("failed to parse age, birthday is ", birthday);
    return undefined;
  }
}

export function getPatientSex(sex: any): string {
  switch (sex) {
    case "MALE":
      return "Homme de ";
    case "FEMALE":
      return "Femme de ";
    default:
      return "N/A";
  }
}
