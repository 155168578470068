import React from "react";
import styles from "./Profile.module.css";

export const CompletedProfileCard: React.FC<any> = () => {
  const completePercentage = 50;

  return (
    <>
      <div className={"w-30 " + styles.completedInfoCard}>
        <div className="text-left mb-2">
          Profil complété{" "}
          <span className="text-bold"> à {completePercentage}%</span>
        </div>
        <div className={styles.progressBar}>
          <div
            className={styles.fillProgressBar}
            style={{ width: completePercentage + "%" }}
          />
        </div>
      </div>
    </>
  );
};
