import { useCallback, useEffect, useState } from "react";
import { Availability } from "../../../domain/Availability";

function useAvailabilitiesCheck(
  days: number[],
  availabilities: Map<number, Availability[]>
): any {
  const [errors, setErrors] = useState<Map<number, Map<string, string>>>(
    new Map()
  );
  const isOverlap = (
    availability: Availability,
    dayAvailabilities: Availability[]
  ): any => {
    const indexOfAvailability = dayAvailabilities.findIndex(
      (v: Availability) => availability?.id === v.id
    );
    const hoursArray = dayAvailabilities
      .slice(indexOfAvailability - 1, indexOfAvailability + 1)
      .flatMap((v: Availability) => {
        const { startTime, endTime } =
          availability?.id === v.id ? availability : v;
        return [startTime, endTime];
      });
    const isHoursSorted = hoursArray.every(
      (val: any, i: number, arr: any) => !i || val >= arr[i - 1]
    );
    return !isHoursSorted;
  };

  const runChecks = useCallback((): any => {
    const errorsList: Map<number, Map<string, string>> = new Map();
    for (const day of days) {
      const errorsMessages: Map<string, string> = new Map();
      const dayAvailabilities = availabilities.get(day) ?? [];
      for (const availability of dayAvailabilities) {
        let error = "";
        if (availability) {
          if (
            availability.startTime >= availability.endTime &&
            availability.endTime !== 0 &&
            availability.startTime !== 0
          ) {
            error = "L'heure de début doit être inférieure à l'heure de fin";
          }
          if (availability.startTime < 0 || availability.startTime > 24) {
            error = "L'heure de début est invalide";
          }
          if (availability.endTime < 0 || availability.endTime > 24) {
            error = "L'heure de fin est invalide";
          }
        }
        if (
          (error === "" &&
            isOverlap(availability, dayAvailabilities) &&
            availability.endTime !== 0) ||
          (availability.startTime === 0 &&
            availability.endTime === 0 &&
            dayAvailabilities.length > 1)
        ) {
          error = "Les horaires ne peuvent pas se superposer";
        }
        if (error !== "") {
          errorsMessages.set(availability.id, error);
        }
      }
      if (errorsMessages.size > 0) {
        errorsList.set(day, errorsMessages);
      }
    }
    setErrors(errorsList);
  }, [availabilities, days]);

  useEffect(() => {
    runChecks();
  }, [runChecks]);
  return errors;
}

export default useAvailabilitiesCheck;
