export enum ExpertiseLevels {
  GENERAL = "GENERAL",
  EXPERT = "EXPERT",
}

export function expertiseLevelToString(
  expertiseLevel: ExpertiseLevels
): string {
  return expertiseLevel === ExpertiseLevels.GENERAL ? "général" : "expert";
}
