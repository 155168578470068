enum TherapeuticEffortLimitationEnum {
  PRONOTSTIC = "PRONOTSTIC",
  APPOINTMENT_EXTERNAL_THIRD_PARTY = "APPOINTMENT_EXTERNAL_THIRD_PARTY",
  MEETING_ORGANISATION = "MEETING_ORGANISATION",
}

export const therapeuticEffortLimitationTranslate: Record<
  TherapeuticEffortLimitationEnum,
  string
> = {
  PRONOTSTIC: "Avis pronostique",
  APPOINTMENT_EXTERNAL_THIRD_PARTY: "Désignation d'un tiers extérieur",
  MEETING_ORGANISATION: "Organisation de la réunion de LAT",
};

export const therapeuticEffortLimitationStyleClass: Record<
  TherapeuticEffortLimitationEnum,
  string
> = {
  PRONOTSTIC: "pronosticLabel",
  APPOINTMENT_EXTERNAL_THIRD_PARTY: "externalThirdPartyLabel",
  MEETING_ORGANISATION: "meetingLabel",
};

export default TherapeuticEffortLimitationEnum;
