import React from "react";

export const SendButton: React.FC<any> = (props: any) => (
  <button className="column mr-1 btn send-chat-btn mt-2" {...props}>
    <img
      className="send-icon"
      alt="send-icon"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMi4wMDUgNTEyLjAwNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNTExLjY1OCA1MS42NzVjMi40OTYtMTEuNjE5LTguODk1LTIxLjQxNi0yMC4wMDctMTcuMTc2bC00ODIgMTg0Yy01LjgwMSAyLjIxNS05LjYzOCA3Ljc3NS05LjY1IDEzLjk4NC0uMDEyIDYuMjEgMy44MDMgMTEuNzg1IDkuNTk2IDE0LjAyMmwxMzUuNDAzIDUyLjI5NXYxNjQuNzEzYzAgNi45NDggNC43NzEgMTIuOTg2IDExLjUzMSAxNC41OTMgNi43MTUgMS41OTcgMTMuNzE3LTEuNTk4IDE2Ljg2NS03Ljg0M2w1Ni4wMDEtMTExLjEyOCAxMzYuNjY0IDEwMS40MjNjOC4zMTMgNi4xNyAyMC4yNjIgMi4yNDYgMjMuMjg3LTcuNjY5IDEyNy41OTktNDE4LjM1NyAxMjIuMDgzLTQwMC4xNjMgMTIyLjMxLTQwMS4yMTR6bS0xMTguOTgxIDUyLjcxOC0yMzQuODAzIDE2Ny4yMTktMTAxLjAyOC0zOS4wMTh6bS0yMTcuNjc3IDE5MS44NTIgMjA0LjY2OC0xNDUuNzU3Yy0xNzYuMTE0IDE4NS43OS0xNjYuOTE2IDE3Ni4wMTEtMTY3LjY4NCAxNzcuMDQ1LTEuMTQxIDEuNTM1IDEuOTg1LTQuNDQ4LTM2Ljk4NCA3Mi44ODJ6bTE5MS44NTggMTI3LjU0Ni0xMjAuMjk2LTg5LjI3NiAyMTcuNTExLTIyOS40NjJ6IiBmaWxsPSIjNTI5ZmE2IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIj48L3BhdGg+PC9nPjwvZz48L3N2Zz4="
    />
  </button>
);
