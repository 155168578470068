import React, { useRef, useEffect, useCallback } from "react";

export const ScrollToBottomButton: React.FC<any> = ({
  messages,
  bottom,
}: any) => {
  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = useCallback((): void => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  return (
    <>
      {!bottom && (
        <button className="btn scrolldown-btn" onClick={scrollToBottom}>
          <i className="icon icon-downward" />
        </button>
      )}
      <div ref={messagesEndRef} />
    </>
  );
};
