import React, { useEffect, useState } from "react";
import "../../../App.css";
import PathologyEnum, {
  pathologyTranslate,
} from "../../../domain/Subject/Pathology.enum";
import { pathologiesBySpecialty } from "../../../domain/Subject/PathologyTree";
import SpecialtyEnum, {
  specialtyTranslate,
} from "../../../domain/Subject/Specialty.enum";
import { AdviceOnPathologyDetails } from "../../../domain/Subject/Subject";
import styles from "./RequestDetails.module.css";

interface Props {
  details: AdviceOnPathologyDetails;
}

type PathologyTree = Partial<Record<SpecialtyEnum, PathologyEnum[]>>;
const getPathologyTree = (
  details: Partial<AdviceOnPathologyDetails>
): PathologyTree => {
  const record: PathologyTree = {};
  const specialties = details.specialties || [];
  const pathologies = details.pathologies || [];
  for (const specialty of specialties) {
    const allPathologies = pathologiesBySpecialty[specialty] || [];
    record[specialty] = pathologies.filter((p) => allPathologies.includes(p));
  }
  return record;
};

export const PathologyTree: React.FC<Props> = (props: Props) => {
  const [pathologyTree, setPathologyTree] = useState<PathologyTree>({});

  useEffect(() => {
    setPathologyTree(getPathologyTree(props.details));
  }, [props.details]);

  return (
    <>
      {Object.entries(pathologyTree).map((entry) => {
        const specialty: SpecialtyEnum = entry[0] as SpecialtyEnum;
        const pathologies: PathologyEnum[] = entry[1];
        return (
          <div key={specialty}>
            <div className="flex-row pl-2">
              <div className="line-figure">
                <div className="green-point" />
              </div>
              <div className="motive-line">{specialtyTranslate[specialty]}</div>
            </div>
            {pathologies.map((pathology, index) => {
              return (
                <div className={`flex-row ${styles.paddingLeft}`} key={index}>
                  <div className="line-figure">
                    <div className="green-point" />
                  </div>
                  <div className="motive-line text-grey">
                    {pathologyTranslate[pathology]}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
