import React from "react";
import { HealthProfessionalUser } from "../../../domain/User";
import "./EditParticipant.css";
import { UserChip } from "../../Shared/UserChip";
import { SharePermission } from "../../../domain/OpinionRequest";

interface Props {
  user: HealthProfessionalUser;
  onClickRemoveParticipant: (userId: string) => any;
  selectedPermission: string[];
  hasPermissionEdit: boolean;
  setSelectedPermission: (selectedPermission: string[]) => any;
}

export const PermissionParticipant: React.FC<Props> = (props: Props) => {
  const options = [
    { value: SharePermission.VIEW, label: "Lecture" },
    { value: SharePermission.EDIT, label: "Edition" },
    { value: SharePermission.ADMIN, label: "Admin" },
  ];

  return (
    <div className="row space-between-content">
      <UserChip
        professionalTitle={props.user.professionalTitle}
        firstName={props.user.firstName}
        lastName={props.user.lastName}
        onDelete={(): any => props.onClickRemoveParticipant(props.user.userId)}
      />
      <select
        className="permission-select"
        onChange={(e): void =>
          props.setSelectedPermission([
            ...props.selectedPermission,
            e.target.value,
          ])
        }
        defaultValue={SharePermission.VIEW}
        disabled={props.hasPermissionEdit}
      >
        {options.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
