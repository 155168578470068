import React, { useContext, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionTypes, AppContext } from "../../utils/state";
import "./ProfileDetails.css";
import { User } from "../../domain/User";
import { useForm } from "react-hook-form";
import { getProfile, updateCurrentUser } from "../../services/UserService";
import { Profile } from "../../domain/Profile";
import EditIcon from "../../assets/edit-icon.svg";
import { errorToast, successToast } from "../../utils/Toast";
import { ExpertiseLevels } from "../../domain/ExpertiseLevels";
import { UserSubSpecialties } from "../../domain/UserSubSpecialties";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const ProfileProfessionalDetails: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const { handleSubmit, reset } = useForm<Profile>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [subSpecialties, setSubSpecialties] = useState<
    Map<string, ExpertiseLevels>
  >(
    new Map(
      appContext.state.user?.subSpecialties?.map((v) => [
        v.name,
        v.expertiseLevel,
      ])
    )
  );
  const [inputValue, setInputValue] = useState<string>("");

  const cancelEdit = (): any => {
    reset(appContext.state.user);
    setSubSpecialties(
      new Map(
        appContext.state.user?.subSpecialties?.map((v) => [
          v.name,
          v.expertiseLevel,
        ])
      )
    );
    setIsReadOnly(true);
  };

  const onExpertiseLevelChange = (changeEvent: any): any => {
    if (changeEvent.target.name) {
      subSpecialties.set(changeEvent.target.name, changeEvent.target.value);
      setSubSpecialties(new Map(subSpecialties));
    }
  };

  const handleKeyDown = (event: any): any => {
    if (!inputValue) return;
    switch (event.key) {
      case "Tab":
      case "Enter":
        event.preventDefault();
        if (!subSpecialties.has(inputValue)) {
          subSpecialties.set(inputValue, ExpertiseLevels.GENERAL);
          setSubSpecialties(new Map(subSpecialties));
        }
        setInputValue("");
    }
  };

  const onSubmit = async (): Promise<void> => {
    const updatedSubSpecialties: UserSubSpecialties[] = Array.from(
      subSpecialties.entries()
    ).map((v) => ({ name: v[0], expertiseLevel: v[1] }));
    const userUpdated: User = {
      ...appContext.state.user,
      subSpecialties: updatedSubSpecialties,
    };

    const res = await updateCurrentUser(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition de l'utilisateur");
    } else {
      successToast("Utilisateur mis à jour");
      getProfile().then((updatedUser: User | Error) => {
        if (updatedUser instanceof Error) {
          errorToast("Erreur lors de la récupération de l'utilisateur");
        } else {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
        }
      });
      setIsReadOnly(true);
    }
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Informations professionnelles"}
        backButtonRedirectsTo={`/profile`}
      />
      <div className="page-container">
        <div className="profile-white-container text-left">
          <div className="row space-between-content">
            <i className="informative-text pt-2">
              Ces informations ont été récupérées depuis l&apos;Annuaire Santé,
              grâce à votre numéro RPPS.
            </i>
            {isReadOnly && (
              <button
                id="edit-profile-icon"
                className="edit-btn-small-screen"
                onClick={(): any => setIsReadOnly(false)}
              >
                <img
                  className="icon-disabled mobile-view-icon"
                  alt="icon-edit"
                  src={EditIcon}
                />
              </button>
            )}
          </div>
          <div className="form-horizontal">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-profession">
                  Profession
                </label>
                <input
                  id="profile-profession"
                  className="form-input"
                  type="text"
                  placeholder="Ex: Médecin"
                  defaultValue={appContext.state.user?.profession}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-specialty">
                  Spécialité
                </label>
                <input
                  id="profile-specialty"
                  className="form-input"
                  type="text"
                  placeholder="Aucune spécialité"
                  defaultValue={appContext.state.user?.specialty}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <label className="form-label mr-2">Sous-spécialité :</label>
                  {subSpecialties?.size > 0 ? (
                    <div>{Array.from(subSpecialties.keys()).join(", ")}</div>
                  ) : (
                    <i>
                      Pas de sous-spécialité sauvegardée pour l&apos;instant.
                    </i>
                  )}
                </div>
                {!isReadOnly && (
                  <CreatableSelect
                    id="creatable-sub-specialty"
                    components={{
                      DropdownIndicator: null,
                    }}
                    styles={{
                      multiValue: (provided): Record<string, any> => ({
                        ...provided,
                        backgroundColor: "var(--primary-blue)",
                        color: "white",
                      }),
                      multiValueLabel: (provided): Record<string, any> => ({
                        ...provided,
                        color: "white",
                      }),
                      multiValueRemove: (provided): Record<string, any> => ({
                        ...provided,
                        ":hover": {
                          backgroundColor: "#2F7D84",
                          cursor: "pointer",
                        },
                      }),
                      container: (): Record<string, any> => ({
                        width: "100%",
                      }),
                      indicatorSeparator: (): Record<string, any> => ({
                        display: "none",
                      }),
                      control: (provided): Record<string, any> => ({
                        ...provided,
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "var(--light-blue-1)",
                        display: "flex",
                        borderRadius: "5px",
                        cursor: "text",
                      }),
                    }}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(_: any, e: any): any => {
                      switch (e.action) {
                        case "remove-value":
                          subSpecialties.delete(e.removedValue.label);
                          break;
                        case "clear":
                          subSpecialties.clear();
                          break;
                      }
                      setSubSpecialties(new Map(subSpecialties));
                    }}
                    onInputChange={(newInputValue: string): any =>
                      setInputValue(newInputValue)
                    }
                    onKeyDown={handleKeyDown}
                    placeholder="Ajoutez une sous-spécialité et appuyez sur entrée..."
                    value={Array.from(subSpecialties.keys()).map(
                      (subspeName: any) => ({
                        value: subspeName,
                        label: subspeName,
                      })
                    )}
                  />
                )}
              </div>
              <div className="form-group">
                {subSpecialties?.size > 0 && (
                  <label className="form-label">
                    Niveau d&apos;expertise :
                  </label>
                )}
                {Array.from(subSpecialties.entries()).map(
                  (subSpecialty: any, index: number) => {
                    const expertiseName: string = subSpecialty[0];
                    const expertiseLevel: ExpertiseLevels = subSpecialty[1];
                    return (
                      <div
                        className="row w-100 space-between-content"
                        key={index}
                      >
                        <div>
                          <span className="dot" />
                          <span className="mr-2">{expertiseName}</span>
                        </div>
                        <label
                          className="expertise-btns-container"
                          onClick={onExpertiseLevelChange}
                        >
                          <label className="expertise-btn">
                            <input
                              id="expertise-general"
                              type="radio"
                              value={ExpertiseLevels.GENERAL}
                              name={expertiseName}
                              checked={
                                expertiseLevel === ExpertiseLevels.GENERAL
                              }
                              disabled={isReadOnly}
                              readOnly
                            />
                            <span className="title-button">Général</span>
                          </label>
                          <label className="expertise-btn">
                            <input
                              id="expertise-expert"
                              type="radio"
                              value={ExpertiseLevels.EXPERT}
                              name={expertiseName}
                              checked={
                                expertiseLevel === ExpertiseLevels.EXPERT
                              }
                              disabled={isReadOnly}
                              readOnly
                            />
                            <span className="title-button">Expert</span>
                          </label>
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="row">
                <button
                  type="button"
                  className="style-btn close-btn w-100"
                  onClick={cancelEdit}
                  hidden={isReadOnly}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="style-btn neuro-btn w-100"
                  hidden={isReadOnly}
                >
                  Sauvegarder les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
