import React, { useEffect, useState } from "react";
import { ExpertReply } from "../../domain/ExpertReply";
import TextareaAutosize from "react-textarea-autosize";
import Modal from "../Modal";

interface Props {
  isSendableToDmp: boolean;
  onClose: () => void;
  onReply: (expertReply: ExpertReply) => void;
  onDraft: (expertDraftReply: ExpertReply) => void;
  draftResponse: ExpertReply | undefined;
}

export const Reply: React.FC<Props> = (props: Props) => {
  const responseType = [
    { value: "FINAL_ANSWER", label: "Avis donné" },
    { value: "INVESTIGATION", label: "Investigation complémentaire requise" },
    {
      value: "CONSULTATION",
      label: "Consultation ou hospitalisation dans un centre expert",
    },
  ];
  const [isInvalidReply, setIsInvalidReply] = useState<boolean>(false);
  const [replyResponseType, setReplyResponseType] = useState<string>(
    responseType[0].value
  );
  const [replyComment, setReplyComment] = useState<string>("");

  useEffect(() => {
    if (
      (replyResponseType === "" || replyComment === "") &&
      props.draftResponse?.comment === "" &&
      props.draftResponse.responseType === ""
    ) {
      setIsInvalidReply(true);
    } else {
      setIsInvalidReply(false);
      if (props.draftResponse?.comment && replyComment === "") {
        setReplyComment(props.draftResponse.comment);
      }
      if (props.draftResponse?.responseType && replyComment === "") {
        setReplyResponseType(props.draftResponse.responseType);
      }
    }
  }, [replyResponseType, replyComment]);

  const closeModal = (): void => {
    props.onClose();
  };

  const handleCommentChange = (event: any): void => {
    setReplyComment(event.target.value);
  };

  const handleResponseTypeChange = (event: any): void => {
    setReplyResponseType(event.target.value);
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Répondre à la demande"}
      footer={
        <>
          <div className="footer-line" />
          <div className="row space-between-content ml-4 mr-4">
            <button
              className="btn rectangular-btn close-btn"
              onClick={closeModal}
            >
              Annuler
            </button>
            <button
              className="btn rectangular-btn neuro-btn"
              onClick={async (): Promise<void> => {
                props.onDraft({
                  responseType: replyResponseType,
                  responseStatus: "DRAFT",
                  comment: replyComment,
                });
                closeModal();
              }}
              disabled={isInvalidReply}
            >
              Enregistrer et quitter
            </button>
            <button
              className="btn rectangular-btn neuro-btn"
              onClick={async (): Promise<void> => {
                props.onReply({
                  responseType: replyResponseType,
                  responseStatus: "",
                  comment: replyComment,
                });
              }}
              disabled={isInvalidReply}
            >
              Répondre
            </button>
          </div>
        </>
      }
      containerStyle={{ height: "65vh" }}
    >
      <div className="content">
        <div className="text-left modal-label">Type de réponse</div>
        <div className="row">
          <select className="form-select" onChange={handleResponseTypeChange}>
            {responseType.map((item) => {
              return (
                <option
                  key={item.value}
                  value={item.value}
                  selected={item.value === replyResponseType}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="text-left mt-4 modal-label">Commentaire</div>
        <TextareaAutosize
          className="participant-textarea form-input margin-top-2 mb-4"
          onChange={handleCommentChange}
          minRows={2}
          name="comment"
          placeholder="Ajouter un commentaire..."
          defaultValue={replyComment}
        />
        {isInvalidReply && (
          <i className="error-msg">
            Veuillez remplir l&apos;ensemble des champs pour soumettre votre
            réponse.
          </i>
        )}
      </div>
    </Modal>
  );
};
