import React from "react";
import "./Cards.css";

interface Props {
  content: string;
  children?: any;
}

export const EmptyCard: React.FC<any> = (props: Props) => {
  return (
    <div className="p-relative w-100 mb-2 empty-card">
      <div className="card-body participant-card-body">
        <div className="container text-centered text-bold">
          <div>{props.content}</div>
          {props.children}
        </div>
      </div>
    </div>
  );
};
