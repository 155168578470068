import React from "react";

export function SecondaryDiseaseForm(props: { register: any; readOnly: any }) {
  return (
    <>
      <h4 className="form-title">
        Quels sont les antécédents de votre patient(e) ?
      </h4>
      <textarea
        rows={5}
        className="form-input w-90"
        id="secondary-disease-input"
        {...props.register}
        readOnly={props.readOnly}
      />
    </>
  );
}
