import { Patient } from "../../domain/OpinionRequest";
import React from "react";
import { DmpDocuments } from "../Request/RequestDetails/DmpDocuments";
import { isNotProd } from "../../utils/config";

interface Props {
  patient?: Patient;
}

export const DmpAttachments: React.FC<Props> = (props: Props) => {
  return (
    <div>
      {isNotProd() && (
        <>
          <div className="mt-4">
            <h4 className="form-title">Rechercher un document depuis le DMP</h4>
          </div>
          <DmpDocuments
            patientBirthdate={`${props.patient?.birthdate.year}-${props.patient?.birthdate.month}-${props.patient?.birthdate.day}`}
            patientIns={props.patient?.socialSecurityNumber}
          />
        </>
      )}
    </div>
  );
};
