import React from "react";
import styles from "./UserGuides.module.css";
import ProSanteConnectECps from "../../assets/user-guides/pro-sante-connect-e-cps.png";
import ProSanteConnectCps from "../../assets/user-guides/pro-sante-connect-cps.png";
import ProSanteConnectPinCode from "../../assets/user-guides/pro-sante-connect-pin-code.png";
import UpdateKeycloakInfo from "../../assets/user-guides/update-profile-info.png";
import SignUp from "../../assets/user-guides/sign-up-form.png";
import RppsForm from "../../assets/user-guides/rpps-form.png";
import SelectRole from "../../assets/user-guides/select-role-requester.png";
import AddRequest from "../../assets/user-guides/add-request.png";
import SelectFacility from "../../assets/user-guides/select-facility.png";
import AddParticipant1 from "../../assets/user-guides/add-participant-1.png";
import AddParticipant2 from "../../assets/user-guides/add-participant-2.png";
import ProfileRequester from "../../assets/user-guides/profile-requester.png";
import Chat from "../../assets/user-guides/chat.png";
import AccessChat from "../../assets/user-guides/access-chat.png";
import SeeExpert from "../../assets/user-guides/see-expert.png";
import OpenOrResponse from "../../assets/user-guides/open-or-response-pdf.png";
import TwoFactorAuthRequest from "../../assets/user-guides/2fa-request.png";
import TwoFactorAuthValidate from "../../assets/user-guides/2fa-validate.png";
import EmailVerification from "../../assets/user-guides/email-verification.png";
import VerifPicture from "../../assets/user-guides/verif-picture.png";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const RequesterGuide: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle
        title={"Guide du requérant"}
        backButtonRedirectsTo={`/help`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div className={`mb-2 text-bold ${styles.subTitle}`}>
            Rôle du requérant
          </div>
          <div className="justify">
            Le rôle du requérant consiste à remplir et à soumettre une demande
            d&apos;avis. Pour cela, il doit compléter les différents champs du
            formulaire de demande d&apos;avis de façon exhaustive. <br />
            Il peut ajouter des intervenants à la demande (autres médecins
            suivant le patient, personnel paramédical, ...) pour l&apos;aider,
            notamment à compléter le dossier médical du patient. <br />
            Avant de soumettre sa demande, le requérant peut choisir
            d&apos;ajouter un expert ou bien laisser le soin au régulateur
            d&apos;affecter un expert. <br />
            Le requérant peut également converser avec les intervenants ainsi
            qu&apos;avec le régulateur via une discussion privée pour chaque
            demande.
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Création de compte
          </div>
          <div className="justify">
            Tous les professionnels de santé référencés par un numéro RPPS
            peuvent créer un compte sur le site. La validité du numéro RPPS
            indiqué lors de l&apos;inscription est contrôlée par comparaison
            avec la base de données RPPS disponible{" "}
            <a
              className={styles.link}
              href=" https://mos.esante.gouv.fr/NOS/TRE_R11-CiviliteExercice/"
            >
              ici{" "}
            </a>
            et mise à jour régulièrement.
            <br />
            <br />
            Depuis la page d&apos;inscription, l&apos;utilisateur peut créer son
            compte de deux manières différentes :
            <div className="text-bold mt-2 mb-2 ml-4">
              1. Depuis Pro Santé Connect
            </div>
            L&apos;utilisateur peut créer son compte en utilisant les outils
            d&apos;authentification de l&apos;ANS (CPS ou eCPS), permettant de
            pré-remplir certains champs du processus d&apos;inscription.
            <br />
            Pour cela, l&apos;utilisateur doit cliquer sur le bouton Pro Santé
            Connect depuis la page d&apos;accueil et renseigner le numéro de sa
            carte CPS (1) ou bien connecter sa carte CPS à l&apos;aide d&apos;un
            lecteur de carte (2).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectECps}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectCps}
                className="w-30 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (1) </span>
              <span className="mr-auto ml-auto"> (2) </span>
            </div>
            Ensuite, l&apos;utilisateur doit saisir le code pin de sa carte CPS
            pour valider son identification (3) et mettre à jour ses
            informations de profil (4).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectPinCode}
                className="w-40 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={UpdateKeycloakInfo}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (3) </span>
              <span className="mr-auto ml-auto"> (4) </span>
            </div>
            <div className="text-bold mt-2 mb-2 ml-4">
              2. Depuis l&apos;application
            </div>
            Pour créer son compte depuis l&apos;application, l&apos;utilisateur
            doit renseigner son adresse e-mail ainsi qu&apos;un mot de passe
            pour créer son compte (5). Il devra confirmer la création de son
            compte en cliquant sur le lien présent dans l&apos;email reçu (6).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={SignUp}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={EmailVerification}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2 mb-2">
              <span className="mr-auto ml-auto"> (5) </span>
              <span className="mr-auto ml-auto"> (6) </span>
            </div>
            <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
              Finalisation de l&apos;inscription
            </div>
            <div className="justify">
              <div className="row mb-2">
                <div className="w-60">
                  Une fois son compte créé, l&apos;utilisateur devra renseigner
                  son numéro RPPS. Dans le cas où il a créé son compte avec Pro
                  Santé Connect, le numéro RPPS sera pré-rempli. Dans le cas
                  contraire, la complétion du numéro RPPS permettra
                  d&apos;effectuer une recherche dans le référentiel RPPS et de
                  remplir certains champs.
                </div>
                <div className="w-40">
                  <img
                    alt="rpps-form"
                    src={RppsForm}
                    className={styles.selectRoleImage}
                  />
                </div>
              </div>
              Ensuite, l&apos;utilisateur doit remplir le formulaire
              d&apos;inscription, puis suivre la procédure de double
              authentification par SMS (7) et (8).
              <div className="row space-between-content margin-top-2">
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthRequest}
                  className="w-40 m-auto"
                />
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthValidate}
                  className="w-40 m-auto"
                />
              </div>
              <div className="row mt-2 mb-2">
                <span className="mr-auto ml-auto"> (7) </span>
                <span className="mr-auto ml-auto"> (8) </span>
              </div>
              Une fois la double authentification effectuée, l&apos;utilisateur
              doit se prendre en photo avec sa carte CPS afin de prouver son
              identité.
              <div className="row centered-content margin-top-2 margin-bottom-2">
                <img alt="select-role" src={VerifPicture} className="w-40" />
              </div>
              La validation du compte par un administrateur intervient dans les
              12 heures suivant la soumission (du lundi au vendredi de 9h à
              19h).
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Modification du profil
          </div>
          <div className="justify">
            Depuis le menu en haut à droite de l&apos;écran, le requérant peut
            accéder à son profil et modifier ses informations personnelles,
            professionnelles, ses lieux d&apos;exercice et ses préférences de
            notifications.
            <div className="row centered-content margin-top-2">
              <img alt="select-role" src={ProfileRequester} className="w-30" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Création d&apos;une demande d&apos;avis
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-60">
                Pour accéder aux fonctionnalités qui lui sont disponibles, et
                notamment créer une demande d&apos;avis, le requérant doit
                sélectionner l&apos;onglet &quot;Requérant&quot;.
              </div>
              <div className="w-40">
                <img
                  alt="select-role"
                  src={SelectRole}
                  className={styles.selectRoleImage}
                />
              </div>
            </div>
            <div className="row">
              <div className={styles.w45}>
                Il peut ensuite cliquer sur le bouton &quot;Ajouter une
                demande&quot;.
              </div>
              <div className={styles.w55}>
                <img alt="add-request" src={AddRequest} />
              </div>
            </div>
            Il peut ensuite remplir toutes les pages du formulaire en veillant à
            bien remplir tous les champs obligatoires. S&apos;il n&apos;a pas
            terminé de la remplir, le requérant peut laisser sa demande en
            suspens, au statut de bouillon. <br />
            Une fois la demande d&apos;avis complétée, le requérant peut la
            soumettre depuis la dernière page du formulaire, en précisant pour
            quel centre de soins il effectue cette demande. S&apos;il n&apos;a
            pas de centre de soins enregistré, il pourra en sélectionner un dans
            cette même fenêtre.
            <div className="row centered-content margin-top-2">
              <img
                alt="select-role"
                src={SelectFacility}
                className={styles.w45}
              />
            </div>
            <div className="margin-top-2">
              Une fois la demande soumise, il doit attendre la validation par un
              régulateur, qui va assigner l&apos;expert le plus adapté.
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Ajout d&apos;intervenants
          </div>
          <div className="justify">
            Le requérant peut ajouter des participants à sa demande d&apos;avis
            de plusieurs manières : <br />
            <div className="text-bold mt-2 mb-2 ml-4">
              1. Médecins du parcours de santé
            </div>
            Depuis les pages 4 et 5 du formulaire de demande d&apos;avis, le
            requérant peut renseigner les professionnels de santé du parcours de
            soins du patient (médecins et équipe paramédicale). Pour cela, il
            peut écrire le nom d&apos;un professionnel de santé dans la barre de
            recherche.
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={AddParticipant1} className="w-70" />
            </div>
            <div className="text-bold mt-2 mb-2 ml-4">2. Intervenants</div>
            Une fois la demande soumise, le requérant peut ajouter des
            intervenants depuis l&apos;onglet &quot;Intervenants&quot; du détail
            de la demande d&apos;avis.
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={AddParticipant2} className="w-90" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Messagerie
          </div>
          <div className="justify">
            Le requérant peut communiquer avec le régulateur, les intervenants
            de la demande d&apos;avis ou encore le(s) expert(s) affecté(s) via
            la discussion. Cette discussion est disponible depuis le formulaire
            (1) ou bien depuis le pied de page du détail de la demande
            d&apos;avis (2).
            <div className="row space-between-content margin-top-2">
              <img alt="select-role" src={AccessChat} className="w-40 m-auto" />
              <img alt="select-role" src={Chat} className="w-40 m-auto" />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (1) </span>
              <span className="mr-auto ml-auto"> (2) </span>
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Voir l&apos;expert
          </div>
          <div className="justify">
            Le requérant peut voir quel expert a été ajouté à sa demande dans
            l&apos;onglet &quot;Intervenant&quot; Il peut ainsi voir si
            l&apos;expert proposé a accepté, refusé la demande ou bien n&apos;a
            pas encore répondu.
            <div className="row space-between-content margin-top-2">
              <img alt="select-role" src={SeeExpert} className="w-80 m-auto" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Réponse à la demande d&apos;avis
          </div>
          <div className="justify">
            Une fois qu&apos;un expert a répondu à sa demande, le requérant peut
            télécharger le compte rendu de téléexpertise depuis le bas de la
            page de détail de la demande d&apos;avis.
            <div className="row space-between-content">
              <img
                alt="select-role"
                src={OpenOrResponse}
                className="w-40 m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
