import React, { useEffect, useState } from "react";
import PathologyEnum, {
  pathologyTranslate,
} from "../../../domain/Subject/Pathology.enum";
import {
  pathologiesBySpecialty,
  PathologyTree,
  pathologyTreesBySubSpecialty,
} from "../../../domain/Subject/PathologyTree";
import SpecialtyEnum, {
  specialtyStyleClass,
  specialtyTranslate,
} from "../../../domain/Subject/Specialty.enum";
import { AdviceOnPathologyDetails } from "../../../domain/Subject/Subject";
import SubSpecialtyEnum, {
  subSpecialtyStyleClass,
  subSpecialtyTranslate,
} from "../../../domain/Subject/SubSpecialty.enum";
import ArrayUtils from "../../../utils/array.utils";
import { CheckBox } from "./CheckBox";
import { CheckBoxGroup, CheckBoxOption } from "./CheckBoxGroup";
import { ModalMprTests } from "./ModalMprTests";
import { PathologyTags } from "./PathologyTags";
import { PathologyTreeForm } from "./PathologyTreeForm";
import styles from "./SubjectForm.module.css";

interface Props {
  value?: AdviceOnPathologyDetails;
  onChange: (details: AdviceOnPathologyDetails) => any;
  readOnly?: boolean;
}

export const AdviceOnPathologyForm: React.FC<Props> = (props: Props) => {
  const details = props.value || {
    specialties: [],
    pathologies: [],
  };

  const specialtiesOptions: CheckBoxOption<SpecialtyEnum>[] = Object.values(
    SpecialtyEnum
  ).map((specialty: SpecialtyEnum) => ({
    value: specialty,
    label: specialtyTranslate[specialty],
    className: styles[specialtyStyleClass[specialty]],
  }));

  const [searchInput, setSearchInput] = useState<string>("");
  const [visibleSubSpecialties, setVisibleSubSpecialties] = useState<
    SubSpecialtyEnum[]
  >([]);
  const [selectedSubSpecialty, setSelectedSubSpecialty] = useState<
    SubSpecialtyEnum
  >();
  const [showMprTestModal, setShowMprTestModal] = useState<boolean>(false);

  useEffect(() => {
    const subSpecialties: SubSpecialtyEnum[] = [];
    if (details.specialties.includes(SpecialtyEnum.SPECIALTY_NEURO)) {
      subSpecialties.push(SubSpecialtyEnum.NEURO_DISEASES);
      subSpecialties.push(SubSpecialtyEnum.NEURO_SYMPTOMS);
    }
    if (details.specialties.includes(SpecialtyEnum.SPECIALTY_MPR)) {
      subSpecialties.push(SubSpecialtyEnum.MPR_DISEASES);
      subSpecialties.push(SubSpecialtyEnum.MPR_SYMPTOMS);
    }
    if (
      selectedSubSpecialty &&
      !subSpecialties.includes(selectedSubSpecialty)
    ) {
      setSelectedSubSpecialty(undefined);
    }
    setVisibleSubSpecialties(subSpecialties);
  }, [props.value?.specialties]);

  const filterPathologies = (list: PathologyEnum[]): PathologyEnum[] => {
    return list.filter((pathology) =>
      pathologyTranslate[pathology]
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );
  };

  const filterTrees = (trees?: PathologyTree[]): PathologyTree[] => {
    return (trees || [])
      .map((tree: PathologyTree) => {
        const leftTrees = filterTrees(tree.subTrees || []);
        const leftChoices = filterPathologies(tree.pathologies || []);
        return {
          ...tree,
          subTrees: leftTrees,
          pathologies: leftChoices,
        };
      })
      .filter((tree: PathologyTree) => {
        return (
          (tree.subTrees?.length || 0) + (tree.pathologies?.length || 0) > 0
        );
      });
  };

  const handleSpecialtiesChange = (specialty: SpecialtyEnum): void => {
    setSearchInput("");
    const newSpecialties = ArrayUtils.toggleValueInArray(
      details.specialties,
      specialty
    );
    let newPathologies = details.pathologies || [];
    if (newSpecialties.length < details.specialties.length) {
      // if specialty is removed
      const pathologiesToRemove = pathologiesBySpecialty[specialty] || [];
      newPathologies = newPathologies.filter(
        (p) => !pathologiesToRemove.includes(p)
      );
    }
    props.onChange({
      ...details,
      specialties: newSpecialties,
      pathologies: newPathologies,
    });
  };

  const handlePathologiesChange = (pathology: any): void => {
    const newPathologies = ArrayUtils.toggleValueInArray(
      details.pathologies,
      pathology
    );
    props.onChange({ ...details, pathologies: newPathologies });
  };

  return (
    <div className={styles.requestContainer}>
      <PathologyTags
        checkedTags={details.pathologies}
        removeItem={handlePathologiesChange}
      />
      {details.pathologies.length > 0 && <div className={styles.line} />}
      <p className="text-bold mt-2 mb-2">1. Choisir une spécialité</p>
      <p className="mt-2 mb-2">Sélectionnez une ou plusieurs options</p>

      <CheckBoxGroup
        readOnly={props.readOnly}
        options={specialtiesOptions}
        onChange={handleSpecialtiesChange}
        value={details.specialties}
      />

      {details.specialties.includes(SpecialtyEnum.SPECIALTY_MPR) && (
        <div
          className="text-grey mb-2"
          onClick={() => setShowMprTestModal(true)}
        >
          <span className={styles.seeMprTests}>
            Voir les tests MPR suggérés
          </span>
        </div>
      )}
      {visibleSubSpecialties.length > 0 && (
        <>
          <p className="text-bold mb-2">2. Choisir une sous-spécialité</p>
          <p className={"mt-2 mb-2"}>
            Recherchez parmi les maladies et symptômes
          </p>
          <div>
            {visibleSubSpecialties.map(
              (item: SubSpecialtyEnum, index: number) => (
                <CheckBox
                  key={index}
                  label={subSpecialtyTranslate[item]}
                  onChange={() => setSelectedSubSpecialty(item)}
                  disabled={props.readOnly}
                  checked={item === selectedSubSpecialty}
                  labelClassName={styles[subSpecialtyStyleClass[item]]}
                  hideCheck={true}
                />
              )
            )}
          </div>
        </>
      )}
      {selectedSubSpecialty && (
        <div className="w-100 centered">
          <div className="row mb-2">
            <input
              id="search-subSpecialty"
              type="text"
              value={searchInput}
              onChange={(event): any => setSearchInput(event.target.value)}
              className="form-input w-90"
              placeholder="Rechercher par nom de maladie/symptôme"
            />
            {searchInput !== "" && (
              <button
                className="btn btn-sm neuro-icons-btn ml-2"
                onClick={(): any => setSearchInput("")}
              >
                <i className="icon icon-cross" />
              </button>
            )}
          </div>
        </div>
      )}

      {selectedSubSpecialty && (
        <PathologyTreeForm
          pathologiTrees={filterTrees(
            pathologyTreesBySubSpecialty[selectedSubSpecialty]
          )}
          readOnly={props.readOnly}
          onChange={handlePathologiesChange}
          checkedValues={details.pathologies}
          labelClassName={styles[subSpecialtyStyleClass[selectedSubSpecialty]]}
        />
      )}

      {showMprTestModal && (
        <ModalMprTests onClose={() => setShowMprTestModal(false)} />
      )}
    </div>
  );
};
