import React from "react";
import history from "../../utils/history";

interface BackButtonProps {
  redirectsTo?: string;
}

export const BackButton: React.FC<BackButtonProps> = (
  props: BackButtonProps
) => {
  function goBack(): void {
    if (props.redirectsTo) {
      history.push(props.redirectsTo);
    } else {
      history.goBack();
    }
  }

  return (
    <button id="back-btn" onClick={goBack} className="back-btn">
      <i className="icon icon-arrow-left neuro-primary" /> Retour
    </button>
  );
};
