import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { SendButton } from "./SendButton";
import { ClearButton } from "./ClearButton";
import { VisioButton } from "./VisioButton";

interface Props {
  onSend: (message: string) => any;
  onRequestVisio: () => any;
}

export const Textbox: React.FC<any> = (props: Props) => {
  const [message, setMessage] = useState("");

  const clear = (): void => {
    setMessage("");
  };

  const send = (): void => {
    props.onSend(message);
    clear();
  };

  const handleEnter = (e: any): void => {
    if (e.key === "Enter" && e.ctrlKey) {
      send();
    }
  };

  return (
    <>
      <div className="row input-message-container">
        <div className="chat-container">
          <TextareaAutosize
            className="chat-input"
            placeholder="Ecrivez un message"
            maxRows={7}
            onChange={(event: any): void => {
              setMessage(event.target.value);
            }}
            value={message}
            onKeyDown={handleEnter}
          />
          <div className="chat-btns">
            <ClearButton onClick={clear} />
            <SendButton onClick={send} />
            <VisioButton onClick={props.onRequestVisio} />
          </div>
        </div>
      </div>
      <div className="send-message-info">
        Appuyer sur Ctrl+Entrée pour envoyer le message.
      </div>
    </>
  );
};
