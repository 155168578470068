import React, { useCallback, useEffect, useRef, useState } from "react";
import { getMinimizedTitleLabel, User } from "../../domain/User";
import styles from "./ReferralDoctorForm.module.css";
import { Figure } from "../Shared/Figure";
import { HealthPathwayContact } from "../../domain/OpinionRequest";
import { getUser } from "../../services/UserService";
import { errorToast } from "../../utils/Toast";
import EditIcon from "../../assets/edit-icon.svg";

interface Props {
  doctor: HealthPathwayContact;
  handleRemove: (doctor: User) => any;
  readOnly: boolean;
  setDoctor: (healthPathwayContact: HealthPathwayContact) => any;
}

export const HealthPathwayContactCard: React.FC<Props> = (props: Props) => {
  const { doctor, handleRemove, setDoctor } = props;
  const [user, setUser] = useState<User>();
  const [addNewRole, setAddNewRole] = useState<boolean>(false);
  const [saveNewRole, setSaveNewRole] = useState<string>(props.doctor.role);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(!!props.doctor.role);
  const inputRef = useRef<any>();

  const getDoctorInfo = useCallback(async (): Promise<any> => {
    const res = await getUser(doctor.userId);
    if (res instanceof Error) {
      setUser(undefined);
      errorToast("Erreur lors de la récupération des médecins");
    } else {
      setUser(res);
    }
  }, [doctor.userId]);

  const updateRole = (): any => {
    setIsReadOnly(true);
    if (saveNewRole === "") {
      setAddNewRole(false);
    }
    setDoctor({
      ...doctor,
      role: saveNewRole,
    });
  };

  const deleteRole = (): any => {
    setSaveNewRole("");
    setIsReadOnly(true);
    setAddNewRole(false);
    setDoctor({
      ...doctor,
      role: "",
    });
  };

  const handleKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      updateRole();
    }
  };

  useEffect(() => {
    getDoctorInfo();
  }, [getDoctorInfo]);

  function renderButton() {
    return isReadOnly ? (
      <button
        className="edit-btn-small-screen"
        onClick={(): any => setIsReadOnly(false)}
      >
        <img
          className="icon-disabled mobile-view-icon"
          alt="icon-edit"
          src={EditIcon}
        />
      </button>
    ) : (
      <>
        <button
          type="button"
          className="btn-modify"
          id="update-role-btn"
          onClick={updateRole}
        >
          <i className="icon icon-check neuro-primary" />
        </button>
        <button
          type="button"
          className="btn-modify"
          id="delete-role-btn"
          onClick={deleteRole}
        >
          <i className="icon icon-cross neuro-primary" />
        </button>
      </>
    );
  }

  return (
    <div className={styles.doctorCard} id="doctor-card">
      {user && (
        <div>
          <button
            className={styles.removeCardButton}
            id="remove-doctor-card-btn"
            onClick={(): any => handleRemove(user)}
          >
            ×
          </button>
          <div className="flex-row">
            {user.firstName && user.lastName && (
              <div className={styles.figure}>
                <Figure
                  firstName={user.firstName}
                  lastName={user.lastName}
                  fontSize={"text-medium"}
                />
              </div>
            )}
            <div className={styles.doctorName + " text-bold"}>
              {user.firstName !== "" && user.lastName !== ""
                ? getMinimizedTitleLabel(user.professionalTitle) +
                  " " +
                  user.firstName +
                  " " +
                  user.lastName
                : user.email}
            </div>
          </div>

          {user.subSpecialties && (
            <div className={"text-bold " + styles.specialtyTitle}>
              Spécialité
            </div>
          )}
          <div className={"text-bold " + styles.doctorProfession}>
            {user.profession} {user.specialty}
          </div>

          {!props.readOnly &&
          !addNewRole &&
          (saveNewRole === "" || saveNewRole === undefined) ? (
            <button
              type="button"
              id="add-role-btn"
              className={"text-bold row " + styles.addNewRole}
              onClick={(): any => {
                setAddNewRole(true);
                setIsReadOnly(false);
              }}
            >
              <div className={styles.circle}>
                <i className="icon icon-plus" />
              </div>
              Ajouter un rôle
            </button>
          ) : (
            <>
              <div className={"text-bold " + styles.specialtyTitle}>Role</div>
              <div className={"row " + styles.paddingHealthCard}>
                <input
                  ref={inputRef}
                  name="role"
                  className={"form-input"}
                  type="text"
                  placeholder="Saisir le role..."
                  onChange={(e): any => setSaveNewRole(e.target.value)}
                  disabled={isReadOnly || props.readOnly}
                  value={saveNewRole}
                  onKeyDown={handleKeyDown}
                  autoFocus={true}
                />
                {!props.readOnly && <>{renderButton()}</>}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
