import React, { useContext } from "react";
import history from "../../utils/history";
import styles from "./UserGuides.module.css";
import { AppContext } from "../../utils/state";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const UserGuidesIndex: React.FC = () => {
  const appContext = useContext(AppContext);

  function openRequesterGuide(): void {
    history.push(`/requester-guide`);
  }

  function openDispatcherGuide(): void {
    history.push(`/dispatcher-guide`);
  }

  function openExpertGuide(): void {
    history.push(`/expert-guide`);
  }

  function openRegistrationGuide(): void {
    history.push(`/register-guide`);
  }

  return (
    <>
      <PageHeaderWithTitle
        title={"Guide utilisateur"}
        backButtonRedirectsTo={`/`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div
            className={
              `row ` +
              (hasRole(
                appContext.state.user?.roles,
                UserRoles.GLOBAL_DISPATCHER
              )
                ? `${styles.gridContainerThreeCards}`
                : `${styles.gridContainerTwoCards}`)
            }
          >
            <div className={styles.userGuideCard}>
              <div className={`text-large ${styles.extraBoldTitle}`}>
                Guide du médecin requérant
              </div>
              <div className="row centered-content mt-4 pt-2">
                <button
                  id="requester-guide-btn"
                  onClick={(): any => openRequesterGuide()}
                  className={"w-50 btn neuro-btn rectangular-btn mt-2"}
                >
                  Consulter
                </button>
              </div>
            </div>
            {hasRole(
              appContext.state.user?.roles,
              UserRoles.GLOBAL_DISPATCHER
            ) && (
              <div className={styles.userGuideCard}>
                <div className={`text-large ${styles.extraBoldTitle}`}>
                  Guide du régulateur
                </div>
                <div className="row centered-content mt-4 pt-2">
                  <button
                    id="dispatcher-guide-btn"
                    onClick={(): any => openDispatcherGuide()}
                    className={"w-50 btn neuro-btn rectangular-btn mt-2"}
                  >
                    Consulter
                  </button>
                </div>
              </div>
            )}
            <div className={styles.userGuideCard}>
              <div className={`text-large ${styles.extraBoldTitle}`}>
                Guide de l&apos;expert
              </div>
              <div className="row centered-content mt-4 pt-2">
                <button
                  id="expert-guide-btn"
                  onClick={(): any => openExpertGuide()}
                  className={"w-50 btn neuro-btn rectangular-btn mt-2"}
                >
                  Consulter
                </button>
              </div>
            </div>
            <div className={styles.userGuideCard}>
              <div className={`text-large ${styles.extraBoldTitle}`}>
                Guide d&apos;inscription
              </div>
              <div className="row centered-content mt-4 pt-2">
                <button
                  id="expert-guide-btn"
                  onClick={(): any => openRegistrationGuide()}
                  className={"w-50 btn neuro-btn rectangular-btn mt-2"}
                >
                  Consulter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
