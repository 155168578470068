import React from "react";
import history from "../../utils/history";
import styles from "./AboutUs.module.css";
import { HowItWorks } from "../Shared/HowItWorks";
import { HomeFooter } from "../HomeFooter";
import { getWebsiteTitle } from "../../utils/Brands";
import config from "../../utils/config";

export const AboutUs: React.FC<any> = () => {
  const goToHome = (): any => {
    history.push("/");
  };

  return (
    <>
      <div className={styles.aboutUsContainer}>
        <div className={"mt-2 text-justify " + styles.welcomeText}>
          Bienvenue chers collègues sur {getWebsiteTitle(config.brand)}{" "}
          plateforme de <b>téléexpertise</b> et de <b>télé-RCP</b> facilitant
          vos demandes d’avis. <br />
          <br />
          D’Open Clinics permet aux spécialistes hospitaliers neurologues,
          réanimateurs, psychiatres et MPR de répondre aux demandes d’avis de
          tous les professionnels de santé et d’améliorer la prise de décision
          collégiale en hôpital en situation d’incertitude. Les experts d’autres
          spécialités peuvent être impliquées dans les demandes d’avis
          nécessitant une perspective transversale.
        </div>
        <div className={"m-auto " + styles.howItWorksContainer}>
          <HowItWorks />
        </div>
        <div className={"row w-100 " + styles.buttonContainer}>
          <button
            className={`btn neuro-btn rounded-btn ${styles.button}`}
            onClick={goToHome}
          >
            C&apos;est parti !
          </button>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};
