import React, { useContext, useEffect, useState } from "react";
import "../CondensedOpinionRequest.css";
import "../../Chat/OpinionChat.css";
import history from "../../../utils/history";
import { AppContext } from "../../../utils/state";
import OpinionRequest, {
  canUserEditOpinionRequest,
  SharePermission,
} from "../../../domain/OpinionRequest";
import { useToggleClickOutside } from "../../../utils/useToggleClickOutside.hook";
import { PatientDetails } from "./PatientDetails";
import { NotValidatedUser } from "../NotValidatedUser";
import {
  deleteOpinionRequest,
  getPatientAge,
  getPatientSex,
  setOpinionRequestReply,
} from "../../../services/OpinionRequestService";
import { errorToast, successToast } from "../../../utils/Toast";
import brainIcon from "../../../assets/brain-icon.svg";
import { ModalInfo } from "../../Shared/ModalInfo";
import { RequestStatus } from "../../../domain/RequestStatus";
import { ExpertStatus } from "../../../domain/ExpertStatus";
import { hasRole } from "../../../utils/authorization";
import { UserRoles } from "../../../domain/UserRoles";
import { ExpertReply } from "../../../domain/ExpertReply";

interface Props {
  request: OpinionRequest;
  readOnly: boolean;
  affectExpert: () => any;
  refreshRequest: () => any;
}

export const CondensedHeader: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [patientDetails, setPatientDetails] = useState<boolean>(false);
  const [showNotValidated, setShowNotValidated] = useState<boolean>(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState<boolean>(false);
  const [disabledAffectExpertBtn, setDisabledAffectExpertBtn] = useState<
    boolean
  >(false);
  const [disabledSendReplyBtn, setDisabledSendReplyBtn] = useState<boolean>(
    false
  );
  const [showMoreButtons, toggleShowMoreButtons] = useToggleClickOutside(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  function goToRequests(): void {
    history.push(`/`);
  }

  const onDelete = async (): Promise<void> => {
    if (props.request.id) {
      const res = await deleteOpinionRequest(props.request.id);
      if (res instanceof Error) {
        errorToast("La demande n'a pas pu être supprimée");
      } else {
        successToast("La demande a été supprimée");
        goToRequests();
      }
    }
  };

  const setExpertReply = async (reply: ExpertReply): Promise<void> => {
    if (props.request.id) {
      const replyRequest = await setOpinionRequestReply(
        reply,
        props.request.id
      );
      if (replyRequest instanceof Error) {
        errorToast("Erreur lors de la sauvegarde de la réponse");
      } else {
        props.refreshRequest();
        successToast(
          "Votre réponse à la demande d'avis est soumise, demande clôturée"
        );
      }
    }
  };

  useEffect(() => {
    if (props.request) {
      if (
        props.request.status !== RequestStatus.CLOSED &&
        (props.request.status === RequestStatus.SUBMITTED ||
          props.request.status === RequestStatus.DRAFT ||
          canUserEditOpinionRequest(appContext.state.user, props.request))
      ) {
        setDisabledDeleteBtn(false);
      } else {
        setDisabledDeleteBtn(true);
      }
      if (
        props.request.status === RequestStatus.VALIDATED &&
        (hasRole(appContext.state.user.roles, UserRoles.GLOBAL_DISPATCHER) ||
          hasRole(
            appContext.state.user.roles,
            UserRoles.ORGANIZATION_DISPATCHER
          ) ||
          (props.request.assignedExperts &&
            props.request.assignedExperts.some(
              (expert) => expert.userId === appContext.state.user.id
            )))
      ) {
        setDisabledAffectExpertBtn(false);
      } else {
        setDisabledAffectExpertBtn(true);
      }
      if (props.request.assignedExperts) {
        props.request.assignedExperts.forEach((expert) => {
          if (expert.status === ExpertStatus.ACCEPTED) {
            setDisabledDeleteBtn(true);
          }
        });
      }
      setDisabledSendReplyBtn(
        props.request.expertReply?.comment === "" ||
          props.request.assignedExperts?.some(
            (expert) => expert.userId !== appContext.state.user.id
          ) ||
          props.request.status !== RequestStatus.VALIDATED
      );
    }
  }, [props.request, appContext.state.user]);

  const getPatientNameInfos = (): string => {
    if (
      props.request &&
      (props.request.patient.firstName || props.request.patient.lastName)
    ) {
      return `${props.request.patient.firstName} ${props.request.patient.lastName}`;
    } else if (props.request && props.request.patient.localIdentifier) {
      return `${props.request.patient.localIdentifier}`;
    } else {
      return "";
    }
  };

  const canDeleteOpinionRequest = (): boolean => {
    if (
      props.request?.participants &&
      props.request?.participants.some(
        (p) =>
          p.userId === appContext.state.user.id &&
          p.permission === SharePermission.ADMIN
      )
    ) {
      return true;
    }
    return canUserEditOpinionRequest(appContext.state.user, props.request);
  };

  return (
    <div className="patient-information-card flex-1">
      <img className="card-theme card-icon" src={brainIcon} alt="icon brain" />
      <div className="flex-col infos-header">
        <span className="patient-firstname-and-lastname text-bold">
          {getPatientNameInfos()}
        </span>
        <span className="patient-detail-info">
          {props.request &&
            (props.request.patient.firstName ||
              props.request.patient.lastName) &&
            props.request.patient.localIdentifier}
        </span>
        <span className="patient-detail-info">
          {props.request &&
            props.request.patient.sex &&
            getPatientSex(props.request.patient.sex)}
          {props.request?.patient.birthdate !== undefined &&
            getPatientAge(props.request?.patient.birthdate) !== undefined &&
            getPatientAge(props.request?.patient.birthdate)}
        </span>
      </div>
      <i
        className="fa fa-info-circle mr-4 clickable"
        onClick={(): any => setPatientDetails(true)}
      />
      <button
        className="expand-btn h-100"
        id="expand-btn"
        onClick={toggleShowMoreButtons}
      >
        <i className="fas fa-ellipsis-v" />
        {showMoreButtons && (
          <div className="more-btn-menu space-around-content">
            {canDeleteOpinionRequest() && (
              <div
                className={
                  "delete-demand-btn flex-row space-between-content" +
                  (disabledDeleteBtn ? " btn-disabled" : "")
                }
                id="delete-demand-btn"
                onClick={(e): any => {
                  if (!disabledDeleteBtn) {
                    e.stopPropagation();
                    toggleShowMoreButtons(e);
                    setShowDeleteModal(true);
                  }
                }}
              >
                <i className="fas fa-trash-alt ml-2 mr-auto" />
                <span className="mr-auto">Supprimer la demande</span>
                {disabledDeleteBtn && (
                  <span className="delete-tooltip tooltiptext">
                    La demande a été acceptée par un expert et ne peut plus être
                    supprimée
                    <div className="top-triangle" />
                  </span>
                )}
              </div>
            )}
            <div
              className={
                "affect-expert-btn flex-row space-between-content" +
                (disabledAffectExpertBtn ? " btn-disabled" : "")
              }
              onClick={(e): any => {
                if (!disabledAffectExpertBtn) {
                  e.stopPropagation();
                  toggleShowMoreButtons(e);
                  props.affectExpert();
                }
              }}
            >
              <i className="fas fa-user-plus ml-2 mr-auto" />
              <span className="mr-auto">Affecter un expert</span>
              {disabledAffectExpertBtn && (
                <span className="delete-tooltip tooltiptext">
                  Vous ne pouvez pas réaliser cette action
                  <div className="top-triangle" />
                </span>
              )}
            </div>
            {!disabledSendReplyBtn && (
              <div
                className={
                  "affect-expert-btn flex-row space-between-content" +
                  (disabledSendReplyBtn ? " btn-disabled" : "")
                }
                onClick={(e): any => {
                  if (!disabledSendReplyBtn) {
                    e.stopPropagation();
                    toggleShowMoreButtons(e);
                    if (props.request.expertReply) {
                      setExpertReply(props.request.expertReply);
                    }
                  }
                }}
              >
                <i className="fa fa-reply ml-2 mr-auto" />
                <span className="mr-auto">
                  Envoyer définitivement la réponse
                </span>
                {disabledSendReplyBtn && (
                  <span className="delete-tooltip tooltiptext">
                    Vous ne pouvez pas réaliser cette action
                    <div className="top-triangle" />
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </button>
      {patientDetails && (
        <PatientDetails
          request={props.request}
          onClose={(): any => setPatientDetails(false)}
        />
      )}
      {showNotValidated && (
        <NotValidatedUser onClose={(): any => setShowNotValidated(false)} />
      )}
      {showDeleteModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowDeleteModal(false)}
          rightButtonAction={onDelete}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer cette demande ?</>
        </ModalInfo>
      )}
    </div>
  );
};
