import React from "react";
import Modal from "../Modal";
import styles from "./ModalInfo.module.css";

interface Props {
  title: string;
  onClose: () => any;
  rightButtonAction: () => any;
  rightButtonText: string;
  leftButtonText: string;
  children?: any;
  isRightButtonDisabled?: boolean;
}

export const ModalInfo: React.FC<Props> = (props: Props) => {
  const closeModal = (): void => {
    props.onClose();
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={props.title}
      footer={
        <>
          <div className="footer-line" />
          <div className="row space-between-content ml-4 mr-4">
            <button
              className="btn rectangular-btn close-btn"
              onClick={closeModal}
            >
              {props.leftButtonText}
            </button>
            <button
              className="btn rectangular-btn neuro-btn"
              onClick={(): any => {
                props.rightButtonAction();
                closeModal();
              }}
              disabled={props.isRightButtonDisabled}
            >
              {props.rightButtonText}
            </button>
          </div>
        </>
      }
      containerStyle={{ height: "auto" }}
    >
      <div className={"row centered-content " + styles.minHeight}>
        {props.children}
      </div>
    </Modal>
  );
};
