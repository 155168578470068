import React, { useContext, useState } from "react";
import styles from "./PhoneNumberConfirmation.module.css";
import {
  confirmCode,
  getConfirmCode,
  VerificationMessage,
} from "../../services/VerificationService";
import { errorToast, infoToast, successToast } from "../../utils/Toast";
import history from "../../utils/history";
import { ActionTypes, AppContext } from "../../utils/state";
import { getProfile } from "../../services/UserService";
import { ErrorResponse, ErrorType } from "../../domain/ErrorManagement";

interface Props {
  next?: () => any;
  onConfirm?: (newState: boolean) => any;
}

export const PhoneNumberConfirmation: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [code, setCode] = useState<string>("");
  const [codeSent, setCodeSent] = useState<boolean>(false);

  const getConfirmationCode = async (): Promise<void> => {
    const res = await getConfirmCode();
    if (res instanceof ErrorResponse) {
      if (res.code === ErrorType.STILL_VALID_CODE) {
        errorToast("Le dernier code que vous avez demandé est encore valide");
        setCodeSent(true);
      } else if (res.code === ErrorType.ACCOUNT_ALREADY_CHECKED) {
        history.push("/");
        errorToast("Le code a été vérifié il y a moins d'un mois");
      } else {
        errorToast(
          "Erreur lors de l'initialisation de la double authentification."
        );
      }
    } else {
      infoToast("Un code va vous être envoyé.");
      setCodeSent(true);
    }
  };

  const validateConfirmationCode = async (): Promise<void> => {
    const verifMessage: VerificationMessage = { code };
    const res = await confirmCode(verifMessage);
    if (res instanceof ErrorResponse) {
      if (res.code === ErrorType.INVALID_CODE) {
        errorToast("Erreur, code erroné.");
      } else if (res.code === ErrorType.INVALID_CODE_TOO_MANY_TRIES) {
        errorToast(
          "Erreur, trop d'essais érronés. Veuillez demander un nouveau code."
        );
      } else {
        errorToast(
          "Erreur technique lors de la validation. Veuillez réessayer dans quelques instants ou contacter un administrateur."
        );
      }
    } else {
      successToast("Verification réussie !");
      getProfile().then((updatedUser) => {
        if (!(updatedUser instanceof Error)) {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
          props.onConfirm && props.onConfirm(true);
          props.next ? props.next() : history.push("/");
        }
      });
    }
  };

  function isNotValid(): boolean {
    return code.length !== 4;
  }

  return (
    <>
      <div className={styles.container}>
        {!codeSent && (
          <div className={styles.accountConfirmationContainer}>
            <span className={styles.instructions}>
              Veuillez cliquer sur le bouton ci-dessous pour recevoir par SMS le
              code permettant de confirmer qu&apos;il s&apos;agit bien de vous.
            </span>
            <div className={styles.btnContainer}>
              <button
                id="send-confirmation-code"
                className={"btn btn-block neuro-btn " + styles.phoneConfirmBtn}
                onClick={getConfirmationCode}
              >
                Envoyer le code
              </button>
            </div>
          </div>
        )}
        {codeSent && (
          <div className={styles.accountConfirmationContainer}>
            <span className={styles.instructions}>
              Un code vous a été envoyé, il est valide durant 5 minutes.
            </span>
            <div className={styles.inputContainer}>
              Entrez le code ici:
              <input
                id="confirmation-code-input"
                type="text"
                value={code}
                className={styles.codeInput}
                maxLength={4}
                onChange={(event): void => {
                  setCode(event.target.value);
                }}
              />
            </div>
            <div className={styles.btnContainer}>
              <button
                className={
                  (isNotValid() ? "lockedPhoneConfirmBtn" : "neuro-btn") +
                  ` btn btn-block ${styles.phoneConfirmBtn}`
                }
                onClick={validateConfirmationCode}
                disabled={isNotValid()}
              >
                Confirmer
              </button>
              <span className={styles.resendCode} onClick={getConfirmationCode}>
                Cliquez ici pour qu&apos;un code vous soit de nouveau envoyé.
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
