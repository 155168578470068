import { Dispatch, useState } from "react";
import {
  HealthPathwayContact,
  HealthPathwayContactType,
} from "../../domain/OpinionRequest";
import { errorToast } from "../../utils/Toast";
import { createUser } from "../../services/UserService";
import { ErrorResponse, ErrorType } from "../../domain/ErrorManagement";
import { HealthProfessionalUser, User } from "../../domain/User";

export default function useHealthPathwayContact(
  healthPathwayContactType: HealthPathwayContactType,
  healthPathwayContacts: HealthPathwayContact[],
  onChange: (hpContacts?: HealthPathwayContact[]) => any
): {
  addUserFromDirectory: any;
  addToHealthPathway: any;
  removeFromHealthPathway: any;
  setSearchInDirectory: Dispatch<boolean>;
  searchInDirectory: any;
} {
  const [searchInDirectory, setSearchInDirectory] = useState<boolean>(false);

  const addToHealthPathway: any = (contactToAdd: HealthProfessionalUser) => {
    const healthPathwayOnChange = new Map();
    healthPathwayContacts.forEach((contact) => {
      healthPathwayOnChange.set(contact.userId, contact);
    });
    healthPathwayOnChange.set(contactToAdd.userId, {
      ...contactToAdd,
      userId: contactToAdd.userId,
      category: healthPathwayContactType,
    });
    const newHpContacts = Array.from(healthPathwayOnChange.values());
    onChange(newHpContacts);
  };

  const removeFromHealthPathway: any = (contactToRemove: User) => {
    const healthPathwayOnChange = new Map();
    healthPathwayContacts.forEach((contact) => {
      if (contact.userId !== contactToRemove.id) {
        healthPathwayOnChange.set(contact.userId, contact);
      }
    });
    const newHpContacts = Array.from(healthPathwayOnChange.values());
    onChange(newHpContacts);
  };

  const addUserFromDirectory = async (
    user: HealthProfessionalUser
  ): Promise<any> => {
    const isInHealthPathwayContacts = healthPathwayContacts.some(
      (contact) => contact.rpps === user.rpps
    );
    if (isInHealthPathwayContacts) {
      errorToast(
        `Erreur l'utilisateur ${user.firstName} ${user.lastName} fait déjà partie du parcours de santé ou de l'équipe médicale`
      );
      return;
    }
    const creationUserResponse = await createUser(user);
    let idAddedUser: string;
    if (creationUserResponse instanceof ErrorResponse) {
      if (
        creationUserResponse.code === ErrorType.USER_ALREADY_CREATED &&
        creationUserResponse.payload
      ) {
        idAddedUser = creationUserResponse.payload;
      } else {
        errorToast(
          `Erreur lors de l'ajout de ${user.firstName} ${user.lastName} au parcours de santé"`
        );
        return;
      }
    } else {
      idAddedUser = creationUserResponse.message;
    }
    setSearchInDirectory(false);
    addToHealthPathway({ ...user, userId: idAddedUser });
  };

  return {
    addUserFromDirectory,
    addToHealthPathway,
    removeFromHealthPathway,
    setSearchInDirectory,
    searchInDirectory,
  };
}
