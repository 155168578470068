import { get, send } from "./ApiClient";
import { Chat, Message } from "../domain/Chat";

export async function getChat(id: string): Promise<Chat | Error> {
  return get(`/opinion-request/${id}/chat`);
}

export async function saveMessage(
  id: string,
  message: Message
): Promise<Message | Error> {
  return send(`/opinion-request/${id}/chat`, "POST", message);
}
