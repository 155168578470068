import React from "react";
import visioIcon from "../../../assets/conference-video-call-icon.svg";

export const VisioButton: React.FC<any> = (props: any) => (
  <button className="column mr-1 btn send-chat-btn mt-2" {...props}>
    <img
      style={{ width: "18px", height: "18px" }}
      className="more-icon"
      alt="more-icon"
      src={visioIcon}
    />
  </button>
);
