import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { ActionTypes, AppContext } from "../../../utils/state";
import {
  getProfile,
  getUser,
  updateCurrentUser,
} from "../../../services/UserService";
import { User, UserFacility } from "../../../domain/User";
import { errorToast } from "../../../utils/Toast";
import { SearchFacilities } from "../../SearchFacilities";
import styles from "./ProfileFacilitiesDetails.module.css";
import { FacilityCard } from "./FacilityCard";
import { Facility } from "../../../domain/Facility";
import { PageHeaderWithTitle } from "../../Shared/PageHeaderWithTitle";

export const ProfileFacilitiesDetails: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const [facilities, setFacilities] = useState<Map<string, Facility>>(
    new Map()
  );
  const firstUpdate = useRef(true);
  const [searchNewFacility, setSearchNewFacility] = useState<boolean>(false);

  const onSelect: any = (facility: Facility) => {
    facilities.set(facility.finess, facility);
    setFacilities(new Map(facilities));
    setSearchNewFacility(false);
  };
  const handleRemove: any = (id: string) => {
    facilities.delete(id);
    setFacilities(new Map(facilities));
  };
  const handleService: any = (userFacility: UserFacility) => {
    const facility = facilities.get(userFacility.facilityId);
    if (facility && userFacility.service !== facility.service) {
      facility.service = userFacility.service;
      setFacilities(new Map(facilities.set(userFacility.facilityId, facility)));
    }
  };

  const update = useCallback(async () => {
    const facilitiesId: Facility[] = Array.from(facilities.values());
    const userUpdated: User = {
      ...appContext.state.user,
      facilities: facilitiesId,
      userFacilities: facilitiesId.map(
        (d): UserFacility => ({
          facilityId: d.finess,
          service: d.service,
        })
      ),
    };
    const res = await updateCurrentUser(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition de l'utilisateur");
    } else {
      getProfile().then((updatedUser: User | Error) => {
        if (updatedUser instanceof Error) {
          errorToast("Erreur lors de la récupération de l'utilisateur");
        } else {
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [facilities]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    update();
  }, [update]);

  useEffect(() => {
    getUser(appContext.state.user.id).then((res) => {
      if (res instanceof Error) {
        console.error(res);
        setFacilities(new Map());
        return;
      }
      const map = new Map();
      res.facilities?.forEach((f) => map.set(f.finess, f));
      setFacilities(map);
      firstUpdate.current = true;
    });
  }, [appContext.state.user.id]);

  return (
    <>
      <PageHeaderWithTitle title={"Lieux d'exercice"} />
      <div className="page-container">
        <div className="text-left mr-auto ml-auto profile-white-container">
          {!searchNewFacility && (
            <button
              type="button"
              className={`neuro-btn ${styles.addNewActivityLocation}`}
              onClick={(): any => setSearchNewFacility(true)}
            >
              + Ajouter un nouveau lieu
            </button>
          )}
          <div>
            {searchNewFacility && (
              <SearchFacilities onSelect={onSelect} autoFocus={true} />
            )}
            <div className={styles.facilitiesList}>
              {Array.from(facilities.values()).map((facility) => (
                <FacilityCard
                  key={facility.finess}
                  isAddService={true}
                  facility={facility}
                  handleRemove={handleRemove}
                  handleService={handleService}
                  showRemoveButton={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
