import React, { useEffect, useState } from "react";
import RequestEEGReason, {
  RequestEEGReasonTranslate,
} from "../../domain/EEGReason/RequestEEGReason.enum";
import Select from "react-select";
import { enumAndTranslationToItemElement, LabelValue } from "./Subject/EEGForm";
import styles from "./Subject/SubjectForm.module.css";
import makeAnimated from "react-select/animated";
import { Controller, useForm } from "react-hook-form";

const animatedComponents = makeAnimated();

interface Props {
  value: RequestEEGReason | null;
  onChange: (value: RequestEEGReason | null) => any;
}

export const EEGReasonSelect: React.FC<Props> = (props: Props) => {
  const { control } = useForm<any>();
  const EEGReasonOptions: LabelValue<
    RequestEEGReason
  >[] = enumAndTranslationToItemElement(
    RequestEEGReason,
    RequestEEGReasonTranslate
  );

  const [selectedOption, setSelectedOption] = useState<LabelValue<
    RequestEEGReason
  > | null>(() => {
    if (props.value) {
      return {
        value: props.value,
        label: RequestEEGReasonTranslate[props.value],
      };
    }
    return null;
  });

  useEffect(() => {
    if (selectedOption) {
      props.onChange(selectedOption.value);
    } else {
      props.onChange(null);
    }
  }, [selectedOption]);

  return (
    <Controller
      name="eegOptions"
      control={control}
      render={(): any => (
        <Select<LabelValue<RequestEEGReason>>
          id="select-request-options"
          instanceId="request-eeg-reason"
          name="eegOptions"
          options={EEGReasonOptions}
          value={selectedOption}
          onChange={setSelectedOption}
          isClearable={true}
          components={animatedComponents}
          className={`w-90 clickable ${styles.selectOptions}`}
          closeMenuOnSelect={true}
          isSearchable={false}
          placeholder="Sélectionner l'objet de votre demande"
          styles={{
            control: (): Record<string, any> => ({
              borderRadius: "6px",
              border: "solid 2px var(--primary-blue)",
              boxShadow: "var(--primary-blue)",
              display: "flex",
            }),
            option: (provided, state): Record<string, any> => ({
              ...provided,
              backgroundColor: state.isFocused
                ? "var(--secondary-blue)"
                : "white",
              cursor: state.isDisabled ? "not-allowed!important" : "",
              ":hover": {
                backgroundColor: state.isDisabled
                  ? ""
                  : "var(--secondary-blue)",
              },
              color: state.isDisabled ? "var(--placeholder-grey)" : "black",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }),
            input: (): Record<string, any> => ({
              position: "absolute",
            }),
          }}
        />
      )}
    />
  );
};
