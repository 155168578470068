import React from "react";
import PathologyEnum, {
  pathologyTranslate,
} from "../../../domain/Subject/Pathology.enum";
import { PathologyTree } from "../../../domain/Subject/PathologyTree";
import { CheckBoxGroup, CheckBoxOption } from "./CheckBoxGroup";

interface Props {
  readOnly?: boolean;
  pathologiTrees: PathologyTree[];
  onChange: (pathology: PathologyEnum) => any;
  checkedValues: PathologyEnum[];
  labelClassName?: string;
}

export const PathologyTreeForm: React.FC<Props> = (props: Props) => {
  const buildChoices = (
    data: PathologyEnum[]
  ): CheckBoxOption<PathologyEnum>[] => {
    return data.map((pathology) => ({
      label: pathologyTranslate[pathology],
      value: pathology,
    }));
  };

  const showTree = (tree: PathologyTree, key: string, prefix = "") => {
    return (
      <div key={`tree-${key}`}>
        {tree.name != "" && (
          <span className="text-bold d-block m-2">
            {prefix}
            {tree.name}
          </span>
        )}
        {tree.pathologies && (
          <CheckBoxGroup
            readOnly={props.readOnly}
            options={buildChoices(tree.pathologies)}
            onChange={props.onChange}
            value={props.checkedValues}
            labelClassName={props.labelClassName}
          />
        )}
        {tree.subTrees?.map((value, index) =>
          showTree(value, `${key}.${index}`, `${prefix}${tree.name} / `)
        )}
      </div>
    );
  };

  return (
    <>
      {props.pathologiTrees.map((value, index) =>
        showTree(value, index + "", "")
      )}
    </>
  );
};
