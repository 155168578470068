import React, { useState } from "react";
import { Patient } from "../../domain/OpinionRequest";
import { LoadingStatus } from "../../utils/LoadingStatus";
import {
  InsiInfo,
  InsiError,
  InsiSex,
  showInsiError,
} from "../../services/InsiService";
import { IcanopeeInsiService } from "../../services/icanopee/IcanopeeInsiService";
import { ModalDocumentationCps } from "./DocumentationCPS/ModalDocumentationCps";
import { ModalInfo } from "../Shared/ModalInfo";
import styles from "../Request/RequestDetails/DmpDocuments.module.css";
import { IcanopeeError } from "../../services/icanopee/IcanopeeService";

interface Props {
  getPatientInfo: () => Patient;
  onSuccess: (p: Patient) => void;
}

export const InsCheck: React.FC<Props> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const [isInfoValid, setInfoValid] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>("");
  const [showDocumentationCps, setShowDocumentationCps] = useState<boolean>(
    false
  );
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);

  const renderResult = () => {
    switch (status) {
      case LoadingStatus.IDLE:
        return "";
      case LoadingStatus.LOADING:
        return "Validation des données en cours";
      case LoadingStatus.ERROR:
        if (!isInfoValid) {
          return (
            <span className="red-text">
              Données sur le patient invalides/incomplètes
            </span>
          );
        }
        return (
          <span className="red-text">
            Erreur technique lors de la vérification
          </span>
        );
      case LoadingStatus.SUCCESS:
        return (
          <span className="green-text">Données sur le patient validées</span>
        );
      default:
        return "En attente";
    }
  };

  const mergePatientInfos = (insPatient: Patient): Patient => {
    const formPatient = props.getPatientInfo();
    return {
      firstName: insPatient.firstName,
      lastName: insPatient.lastName,
      localIdentifier: formPatient.localIdentifier,
      address: formPatient.address,
      birthPlace: insPatient.birthPlace,
      birthdate: insPatient.birthdate,
      facilityId: formPatient.facilityId,
      insiStatus: formPatient.insiStatus,
      location: formPatient.location,
      sex: insPatient.sex,
      socialSecurityNumber: formPatient.socialSecurityNumber,
    };
  };

  async function getInsPatientInfo(
    icanopeeInsiService: IcanopeeInsiService,
    p: InsiInfo,
    cpsPinCode: string
  ) {
    setStatus(LoadingStatus.LOADING);
    try {
      const insPatient = await icanopeeInsiService.getPatientInfoIns(
        p,
        cpsPinCode
      );
      if (typeof insPatient !== "string") {
        const completePatient = mergePatientInfos(insPatient);
        props.onSuccess(completePatient);
        setStatus(LoadingStatus.SUCCESS);
      }
    } catch (e) {
      setStatus(LoadingStatus.ERROR);
      setPinCode("");
      showInsiError(e);
    }
  }

  async function checkInsi(patient: Patient, cpsPinCode: string) {
    setStatus(LoadingStatus.LOADING);

    let patientSex = InsiSex.UNKNOWN;
    switch (patient.sex) {
      case "MALE":
        patientSex = InsiSex.MALE;
        break;
      case "FEMALE":
        patientSex = InsiSex.FEMALE;
        break;
    }

    const icanopeeInsiService = new IcanopeeInsiService();

    const patientInfo: InsiInfo = {
      date: `${patient.birthdate.year}-${patient.birthdate.month}-${patient.birthdate.day}`,
      given: patient.firstName?.toUpperCase() || "",
      ins: patient.socialSecurityNumber?.slice(0, 13) || "",
      key: patient.socialSecurityNumber?.slice(13, 15) || "",
      name: patient.lastName?.toUpperCase() || "",
      place: patient.birthPlace || "",
      sex: patientSex,
    };

    try {
      const isValid = await icanopeeInsiService.checkInsi(
        patientInfo,
        String(cpsPinCode)
      );
      setInfoValid(isValid);
      if (isValid) {
        await getInsPatientInfo(
          icanopeeInsiService,
          patientInfo,
          String(cpsPinCode)
        );
        setStatus(LoadingStatus.SUCCESS);
      } else {
        setStatus(LoadingStatus.ERROR);
      }
    } catch (e) {
      if (e === IcanopeeError.COMPONENT_INITIAL_CODE) {
        setShowPinCodeModal(true);
        setStatus(LoadingStatus.IDLE);
      } else if (e === InsiError.WRONG_PARAMETERS) {
        setStatus(LoadingStatus.ERROR);
        setInfoValid(false);
      } else if (e === InsiError.NO_CONNECTOR) {
        setShowDocumentationCps(true);
        setStatus(LoadingStatus.IDLE);
      } else {
        setPinCode("");
        setStatus(LoadingStatus.ERROR);
        showInsiError(e);
      }
    }
  }

  return (
    <div>
      <span>Validation des données patient INSi</span>
      <button
        className="btn btn-sm neuro-icons-btn ml-2"
        onClick={() => checkInsi(props.getPatientInfo(), pinCode)}
      >
        <i className="icon icon-check mr-2" />
        Vérifier
      </button>
      <div className="row ">
        <div>{renderResult()}</div>
      </div>
      {showDocumentationCps && (
        <ModalDocumentationCps
          onClose={(): void => setShowDocumentationCps(false)}
        />
      )}
      {showPinCodeModal && (
        <ModalInfo
          title={"Confirmation de votre identité"}
          onClose={(): any => setShowPinCodeModal(false)}
          rightButtonAction={() => checkInsi(props.getPatientInfo(), pinCode)}
          isRightButtonDisabled={pinCode.length !== 4}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <div className="flex-col p-2">
            <p>
              Veuillez entrer ci-dessous le code correspondant à votre profil de
              professionnel de la santé:
            </p>
            <input
              type="number"
              className={styles.codeInput}
              defaultValue={""}
              min={0}
              onChange={(event) => setPinCode(event.target.value)}
            />
          </div>
        </ModalInfo>
      )}
    </div>
  );
};
