import React from "react";
import { Act } from "../../../domain/Invoice";
import styles from "../InvoicingForm.module.css";
import { FormValidation } from "../InvoicingForm";

interface Props {
  index: number;
  reset: any;
  setValue: any;
  acts: Act[];
  updateActs: (newActs: Act[]) => any;
  formValidation: FormValidation[];
  setFormValidation: (newFormValidation: FormValidation[]) => any;
  bigCardIndex: number;
  setBigCardIndex: (newBigCardIndex: number) => any;
}

export const MinimizedActCard: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div
        className={`card ${styles.minimizedActCard}`}
        onClick={(): void => {
          props.setBigCardIndex(props.index);
        }}
      >
        <div className="flex-row">
          <div
            className={`flex-col text-bold m-auto ml-0 ${styles.actNumberTitle}`}
          >
            Acte {props.index + 1}
          </div>
          <div className={`mr-2 ml-2 ${styles.verticalLine}`} />
          <div className="flex-col text-small m-auto">
            <div className="text-grey">Référence accord</div>
            <div className="text-black text-bold">
              {props.acts[props.index].purchaseOrder || "A renseigner"}
            </div>
          </div>
          <div className={`mr-2 ml-2 ${styles.verticalLine}`} />
          <div className="flex-col text-small m-auto">
            <div className="text-grey">Médecin</div>
            <div className="text-black text-bold">
              {props.acts[props.index].doctorName || "A renseigner"}
            </div>
          </div>
          <div className={`mr-2 ml-2 ${styles.verticalLine}`} />
          <div className="flex-col text-small m-auto">
            <div className="text-grey">Tarif</div>
            <div className="text-black text-bold">
              {props.acts[props.index].price
                ? props.acts[props.index].price + " €"
                : "A renseigner"}
            </div>
          </div>
          <div className="flex-col m-auto mr-1">
            <div>
              <button
                className={styles.removeMinimizedCardButton}
                onClick={(event): void => {
                  event.stopPropagation();
                  const filteredActs = props.acts.filter(
                    (_: Act, i: number) => i !== props.index
                  );
                  props.updateActs(filteredActs);
                  props.reset(filteredActs);
                  props.setFormValidation(
                    props.formValidation.filter(
                      (_, item) => item !== props.index
                    )
                  );
                  if (props.index < props.bigCardIndex) {
                    props.setBigCardIndex(props.bigCardIndex - 1);
                  }
                }}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
