import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LoadingStatus } from "../../utils/LoadingStatus";
import {
  getUser,
  searchHealthProfessionalsAndUsers,
  createUser,
} from "../../services/UserService";
import {
  getMinimizedTitleLabel,
  HealthProfessionalUser,
  ProfessionCategory,
  User,
} from "../../domain/User";
import { UserChip } from "../Shared/UserChip";
import { AppContext } from "../../utils/state";
import { AddNewUser } from "./Participants/AddNewUser";
import { errorToast } from "../../utils/Toast";
import { debounce } from "../../utils/debounce";
import { searchOrganizationByName } from "../../services/OrganizationService";
import { Organization } from "../../domain/Organization";
import { Chip } from "../Shared/Chip";
import { UseFormSetValue } from "react-hook-form";
import styles from "../Admin/AdminOrganizationTab.module.css";
import { OrganizationDetailCard } from "../Dispatch/OrganizationDetailCard";

enum InputType {
  ORGANIZATION = "Organization",
  EXPERT = "Expert",
}

interface Props {
  setValue: UseFormSetValue<any>;
  onExpertSelection: (r: string) => any;
  expertId: string | undefined;
  assignedOrganization: Organization[] | undefined;
  readOnly: boolean;
}

export const RequestExpertForm: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const isMounted = useRef(false);
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [searchValue, setSearchValue] = useState<string>("");
  const [healthProfesionnalsResults, setHealthProfesionnalsResults] = useState<
    HealthProfessionalUser[]
  >([]);
  const [organizationResults, setOrganizationResults] = useState<
    Organization[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedExpert, setSelectedExpert] = useState<User | null>();
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState<Organization | null>();
  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>(InputType.EXPERT);

  const searchExpert = async (searchTerm: string): Promise<any> => {
    if (searchTerm === "") {
      setStatus(LoadingStatus.IDLE);
      setHealthProfesionnalsResults([]);
      setErrorMessage("");
      return;
    }
    setStatus(LoadingStatus.LOADING);
    const res = await searchHealthProfessionalsAndUsers(
      searchTerm,
      ProfessionCategory.DOCTOR,
      "",
      "",
      ""
    );
    if (res instanceof Error) {
      setStatus(LoadingStatus.ERROR);
      setHealthProfesionnalsResults([]);
      setErrorMessage(res.message);
    } else if (res === null) {
      setStatus(LoadingStatus.SUCCESS);
      setHealthProfesionnalsResults([]);
      setErrorMessage("");
    } else {
      setStatus(LoadingStatus.SUCCESS);
      setHealthProfesionnalsResults(
        res.filter(
          (user: HealthProfessionalUser) =>
            user.userId !== appContext.state.user.id
        )
      );
    }
  };

  const searchOrganization = async (searchTerm: string): Promise<any> => {
    if (searchTerm === "") {
      setStatus(LoadingStatus.IDLE);
      setOrganizationResults([]);
      setErrorMessage("");
      return;
    }
    setStatus(LoadingStatus.LOADING);
    const res = await searchOrganizationByName(searchTerm);

    if (res instanceof Error) {
      setStatus(LoadingStatus.ERROR);
      setOrganizationResults([]);
      setErrorMessage(res.message);
    } else if (res === null) {
      setStatus(LoadingStatus.SUCCESS);
      setOrganizationResults([]);
      setErrorMessage("");
    } else {
      setStatus(LoadingStatus.SUCCESS);
      setOrganizationResults(res);
    }
  };

  const debouncedSearchExpert = useMemo(() => debounce(searchExpert, 300), []);
  const debouncedSearchOrg = useMemo(
    () => debounce(searchOrganization, 300),
    []
  );

  useEffect(() => {
    if (isMounted.current) {
      searchType === InputType.EXPERT
        ? debouncedSearchExpert(searchValue)
        : debouncedSearchOrg(searchValue);
    } else {
      isMounted.current = true;
    }
  }, [searchValue]);

  const resetComponent = (): any => {
    setStatus(LoadingStatus.IDLE);
    setHealthProfesionnalsResults([]);
    setOrganizationResults([]);
    setSearchValue("");
    setErrorMessage("");
  };

  const addExpert = async (hp: HealthProfessionalUser): Promise<void> => {
    if (hp.userId === "") {
      const res = await createUser(hp);
      if (res instanceof Error) {
        errorToast(
          `Erreur lors de la création de l'utilisateur, l'expert ${hp.firstName} ${hp.lastName} n'a pas été ajouté`
        );
        return;
      }
      const user = await getUser(res.message);
      if (user instanceof Error) {
        errorToast(
          `Erreur lors de la récupération de l'utilisateur, l'expert ${hp.firstName} ${hp.lastName} n'a pas été ajouté`
        );
        return;
      }
      setSelectedExpert(user);
      props.onExpertSelection(user.id);
    } else {
      const res = await getUser(hp.userId);
      if (res instanceof Error) {
        errorToast(
          `Erreur lors de la récupération de l'utilisateur, l'expert ${hp.firstName} ${hp.lastName} n'a pas été ajouté`
        );
        return;
      }
      setSelectedExpert(res);
      props.onExpertSelection(res.id);
    }
    resetComponent();
  };

  const removeSelectedUser = (): void => {
    setSelectedExpert(null);
    props.onExpertSelection("");
  };

  const removeSelectedOrganization = (): void => {
    setSelectedOrganization(null);
    props.setValue("assignedOrganizations", null);
  };

  const getExpert = useCallback(async () => {
    if (props.expertId && props.expertId !== "") {
      const user = await getUser(props.expertId);
      if (user instanceof Error) {
        console.error(user);
        return;
      }
      setSelectedExpert(user);
    }
  }, [props.expertId]);

  useEffect(() => {
    if (props.assignedOrganization) {
      setSearchType(InputType.ORGANIZATION);
      setSelectedOrganization(props.assignedOrganization[0]);
    }
  }, [props.assignedOrganization]);

  const getPlacerHolder = () => {
    if (searchType === InputType.EXPERT) {
      return "Rechercher un expert";
    } else {
      return "Rechercher une organisation";
    }
  };

  useEffect(() => {
    getExpert();
  }, [getExpert]);

  return (
    <div>
      <div className="form-group">
        <h4 className="form-title">
          Souhaitez-vous nommer un expert ou une organisation à la demande ?
        </h4>
        <p>
          Nous nous chargeons de vous trouver l’expert ou l&apos;organisation
          adéquate, cependant si vous souhaitez qu’un de vos confrères en
          particulier vous donne son avis, sélectionnez-le ci-dessous
          <span className="ml-1">:</span>
        </p>
      </div>
      <div className="form-group mt-4">
        <label key={"radio-expert"} className="form-radio">
          <input
            type="radio"
            value={InputType.EXPERT}
            checked={searchType === InputType.EXPERT}
            onChange={(): any => {
              setSearchType(InputType.EXPERT);
              removeSelectedOrganization();
              resetComponent();
            }}
          />
          <i className={`form-icon`} />{" "}
          <span className="ml-1 c-hand">Expert</span>
        </label>
        <label key={"radio-org"} className="form-radio mt-2">
          <input
            type="radio"
            value={InputType.ORGANIZATION}
            checked={searchType === InputType.ORGANIZATION}
            onChange={(): any => {
              setSearchType(InputType.ORGANIZATION);
              setSelectedExpert(null);
              removeSelectedUser();
              resetComponent();
            }}
          />
          <i className={`form-icon`} />{" "}
          <span className="ml-1 c-hand">Organisation</span>
        </label>
      </div>

      <div className="input-icons">
        <input
          id="search-Users"
          type="text"
          value={searchValue}
          onChange={(event: any): any => {
            setSearchValue(event.target.value);
          }}
          className="form-input"
          placeholder={getPlacerHolder()}
          disabled={props.readOnly}
        />
      </div>
      {status === LoadingStatus.ERROR && (
        <div>Erreur lors de la recherche : {errorMessage}</div>
      )}
      <div>
        {searchType === InputType.EXPERT &&
          healthProfesionnalsResults.length > 0 &&
          healthProfesionnalsResults.map((r) => (
            <Chip
              key={r.rpps}
              figureTitle={r.firstName.charAt(0) + r.lastName.charAt(0)}
              onClick={async () => {
                if (r.userId === "") {
                  setHpToAdd(r);
                  setShowModal(true);
                } else {
                  await addExpert(r);
                }
              }}
              isFlat={true}
              title={
                getMinimizedTitleLabel(r.professionalTitle) +
                r.firstName +
                " " +
                r.lastName
              }
              subtitle={
                r.profession &&
                r.profession + (r.specialty && " (" + r.specialty + ")")
              }
            />
          ))}
      </div>
      <div>
        {searchType === InputType.ORGANIZATION &&
          organizationResults.length > 0 &&
          organizationResults.map((r) => (
            <div key={r.id}>
              <div>
                <details>
                  <summary className="d-flex space-between-content">
                    <Chip
                      key={r.id}
                      figureTitle={InputType.ORGANIZATION.substring(0, 3)}
                      figureClassName={"bg-corail-pink"}
                      onClick={() => {
                        setSelectedOrganization(r);
                        resetComponent();
                        props.setValue("assignedOrganizations", [r]);
                      }}
                      isFlat={true}
                      title={r.name}
                    />
                    <i
                      className={`fa fa-info-circle ${styles.circleIconOrg} mr-2`}
                    />
                  </summary>
                  <OrganizationDetailCard organization={r} />
                </details>
              </div>
            </div>
          ))}
      </div>
      {((searchType === InputType.EXPERT &&
        healthProfesionnalsResults.length === 0) ||
        (searchType === InputType.ORGANIZATION &&
          organizationResults.length === 0)) &&
      searchValue !== "" ? (
        <i>Aucun résultat trouvé...</i>
      ) : (
        ""
      )}
      {searchType === InputType.EXPERT && selectedExpert?.id && (
        <UserChip
          professionalTitle={selectedExpert.professionalTitle}
          firstName={selectedExpert.firstName}
          lastName={selectedExpert.lastName}
          onDelete={removeSelectedUser}
          readOnly={props.readOnly}
        />
      )}
      {searchType === InputType.ORGANIZATION && selectedOrganization?.id && (
        <Chip
          figureTitle={InputType.ORGANIZATION.substring(0, 3)}
          figureClassName={"bg-corail-pink"}
          title={selectedOrganization.name}
          onDelete={removeSelectedOrganization}
        />
      )}
      <AddNewUser
        userToAdd={hpToAdd}
        show={showModal}
        onClose={(): any => {
          // reset input
          if (hpToAdd) {
            setSelectedExpert(undefined);
          }
          setShowModal(false);
          setHpToAdd(undefined);
        }}
        onAddUser={(g: HealthProfessionalUser): any => {
          setShowModal(false);
          addExpert(g);
        }}
      />
    </div>
  );
};
