import React, { useContext, useEffect, useState } from "react";
import "./Admin.css";
import styles from "./AdminViewUpdateOrganization.module.css";
import { Organization } from "../../domain/Organization";
import { useParams } from "react-router-dom";
import {
  deleteOrganization,
  displayErrorDeletingOrganization,
  getOrganizationById,
} from "../../services/OrganizationService";
import history from "../../utils/history";
import { AppContext } from "../../utils/state";
import { errorToast, successToast } from "../../utils/Toast";
import { ModalInfo } from "../Shared/ModalInfo";
import { UserChip } from "../Shared/UserChip";
import { Facility } from "../../domain/Facility";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import { OrganizationCard } from "./OrganizationCard";
import { getFacility } from "../../services/FacilityService";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";

interface ParamTypes {
  id: string;
}

export const AdminViewOrganizationTab: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const { id } = useParams<ParamTypes>();

  const [organization, setOrganization] = useState<Organization>();
  const [facility, setFacility] = useState<Facility>();
  const [parentOrganization, setParentOrganization] = useState<Organization>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  function goBack(): void {
    if (appContext.state.user.email !== "") {
      history.push(`/admin/organizations`);
    } else {
      history.push(`/home`);
    }
  }

  const showErrorRetrievingOrganization = (): any => {
    errorToast("Erreur durant la récupération de l'organisation");
    goBack();
  };

  const getOrganization = async (): Promise<any> => {
    await getOrganizationById(id).then((res: Organization | Error) => {
      if (res instanceof Error) {
        showErrorRetrievingOrganization();
      } else {
        setOrganization(res);
        res.facilityId && getFacilityById(res.facilityId);
        res.parentOrganizationId &&
          getParentOrganization(res.parentOrganizationId);
      }
    });
  };

  const getFacilityById = async (facilityId: string): Promise<void> => {
    if (facilityId) {
      const res = await getFacility(facilityId);
      if (res instanceof Error || res == null) {
        showErrorRetrievingOrganization();
      } else {
        setFacility(res);
      }
    }
  };

  const getParentOrganization = async (parentId: string): Promise<any> => {
    await getOrganizationById(parentId).then((res: Organization | Error) => {
      if (res instanceof Error) {
        showErrorRetrievingOrganization();
      } else {
        setParentOrganization(res);
      }
    });
  };

  useEffect(() => {
    getOrganization();
  }, []);

  const onDelete = async (): Promise<void> => {
    const res = await deleteOrganization(id);
    if (res instanceof Error) {
      displayErrorDeletingOrganization(res.message);
    } else {
      successToast("Organisation supprimée");
      goBack();
    }
  };

  const dispatchersListExist = (): boolean => {
    return (
      (organization?.dispatchers && organization?.dispatchers.length > 0) ||
      false
    );
  };

  const adminsListExist = (): boolean => {
    return (organization?.admins && organization?.admins.length > 0) || false;
  };

  const expertsListExist = (): boolean => {
    return (organization?.experts && organization?.experts.length > 0) || false;
  };

  const dispatchersNb = organization?.dispatchers
    ? organization.dispatchers.length
    : 0;

  const adminsNb = organization?.admins ? organization.admins.length : 0;

  const expertsNb = organization?.experts ? organization.experts.length : 0;

  return (
    <>
      <div className="page-container">
        <div
          className={`col-8 col-sm-auto col-mx-auto ${styles.whiteBackground}`}
        >
          <label className="form-label">Nom</label>
          <div id="organization-name" className="mb-2">
            {organization?.name}
          </div>
          <label className="form-label">Localisation (centre de soins)</label>
          <div className="mt-2">
            {facility ? (
              <div className={`w-50 ${styles.organizationCardContainer}`}>
                <FacilityCard
                  isAddService={false}
                  facility={facility}
                  showRemoveButton={false}
                />
              </div>
            ) : (
              <div className="text-secondary-grey">
                Pas de centre de soins associé
              </div>
            )}
          </div>
          <label className="form-label mb-2">Organisation parente</label>
          {parentOrganization ? (
            <div className={`w-50 mb-2 ${styles.organizationCardContainer}`}>
              <OrganizationCard
                organization={parentOrganization}
                readOnly={true}
                showCardLegend={false}
                showRemoveButton={false}
              />
            </div>
          ) : (
            <div className="text-secondary-grey">
              Pas d&apos;organisation parente associée
            </div>
          )}

          <div className="flex-row space-between-content">
            <div>
              <label className="form-label">
                {adminsNb > 1
                  ? "Administrateurs d'organisations"
                  : "Administrateur d'organisation"}
              </label>
              {adminsListExist() ? (
                organization?.admins.map((admin, key): any => {
                  return (
                    <div key={key}>
                      <UserChip
                        professionalTitle={admin.title}
                        firstName={admin.firstName}
                        lastName={admin.lastName}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-secondary-grey">
                  Aucun administrateur d&apos;organisation
                </div>
              )}
            </div>
          </div>

          <div className="flex-row space-between-content">
            <div>
              <label className="form-label">
                {dispatchersNb > 1
                  ? "Régulateurs d'organisations"
                  : "Régulateur d'organisation"}
              </label>
              {dispatchersListExist() ? (
                organization?.dispatchers.map((dispatcher, key): any => {
                  return (
                    <div key={key}>
                      <UserChip
                        professionalTitle={dispatcher.title}
                        firstName={dispatcher.firstName}
                        lastName={dispatcher.lastName}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-secondary-grey">
                  Aucun régulateur d&apos;organisation
                </div>
              )}
            </div>
          </div>

          <div className="flex-row space-between-content">
            <div>
              <label className="form-label">
                {expertsNb > 1
                  ? "Experts d'organisation"
                  : "Expert d'organisation"}
              </label>
              {expertsListExist() ? (
                organization?.experts.map((expert, key): any => {
                  return (
                    <div key={key}>
                      <UserChip
                        professionalTitle={expert.title}
                        firstName={expert.firstName}
                        lastName={expert.lastName}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-secondary-grey">
                  Aucun expert d&apos;organisation
                </div>
              )}
            </div>
          </div>
          <div className="row centered-content margin-top-2">
            {hasRole(appContext.state.user.roles, UserRoles.ADMIN) && (
              <button
                className="btn rectangular-btn delete-btn mr-2"
                onClick={(): void => setShowDeleteModal(true)}
              >
                Supprimer
              </button>
            )}
            <button
              className="btn rectangular-btn neuro-btn ml-2"
              onClick={(): void => {
                history.push(`/admin/organizations/${organization?.id}/edit`);
              }}
            >
              Mettre à jour
            </button>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowDeleteModal(false)}
          rightButtonAction={onDelete}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>
            Êtes-vous sûr de vouloir supprimer cette organisation ?<br /> Cette
            opération est irréversible
          </>
        </ModalInfo>
      )}
    </>
  );
};
