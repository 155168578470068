import { send } from "./ApiClient";

/**
 * Send error message to backend. This may help to catch errors that were not correctly logged in backend.
 * This function should be removed as soon as we are confident that the backend can log all errors.
 */
export async function sendErrorMessage(message: any): Promise<void | Error> {
  console.info("Sending error message", message);
  return send(`/errors`, "POST", message);
}
