import React, { CSSProperties } from "react";

interface Props {
  onChange: (updatedStartTime: number) => any;
  hourTime: number;
  valid: boolean;
}

export const TimePicker: React.FC<Props> = (props: Props) => {
  const isValid = (): CSSProperties => {
    if (props.hourTime < 0 || props.hourTime > 24 || !props.valid) {
      return {
        borderWidth: 1,
        borderColor: "red",
        backgroundColor: "#ffcccb",
      };
    }
    return {};
  };

  return (
    <>
      <div className="columns time-container" style={isValid()}>
        <select
          name="availability"
          id="hour"
          className="time-input text-centered"
          defaultValue={props.hourTime}
          onChange={(event): any => props.onChange(Number(event.target.value))}
        >
          {Array.from(Array(24).keys()).map((hour) => (
            <option key={hour} value={hour}>
              {("0" + hour).slice(-2)}
            </option>
          ))}
        </select>
        h
      </div>
    </>
  );
};
