import React, { useCallback, useContext, useEffect, useState } from "react";
import OpinionRequest, {
  canUserEditOpinionRequest,
  OpinionRequestFormPage,
  PatientAddress,
} from "../../../domain/OpinionRequest";
import { getPatientAge } from "../../../services/OpinionRequestService";
import styles from "./PatientDetails.module.css";
import { Figure } from "../../Shared/Figure";
import Modal from "../../Modal";
import { PatientLocation } from "../../OpinionRequestForm/PatientLocationForm";
import { getFacility } from "../../../services/FacilityService";
import history from "../../../utils/history";
import EditIcon from "../../../assets/edit-icon.svg";
import { AppContext } from "../../../utils/state";
import { RequestStatus } from "../../../domain/RequestStatus";

interface Props {
  request: OpinionRequest;
  onClose: () => void;
}

export const PatientDetails: React.FC<any> = (props: Props) => {
  const [facilityName, setFacilityName] = useState<any>("");
  const appContext = useContext(AppContext);
  const [disabledEditBtn, setDisabledEditBtn] = useState<boolean>(true);

  useEffect(() => {
    setDisabledEditBtn(
      !(
        props.request?.status !== RequestStatus.CLOSED &&
        props.request?.status !== RequestStatus.VALIDATED &&
        canUserEditOpinionRequest(appContext.state.user, props.request)
      )
    );
  }, [props.request, appContext.state.user]);

  const getPatientLocation = (patientLocation: PatientLocation): any => {
    switch (patientLocation) {
      case PatientLocation.HOME:
        return "Domicile";
      case PatientLocation.FACILITY:
        return "En centre de santé";
      default:
        return "";
    }
  };

  const getFacilityById = useCallback(async (): Promise<any> => {
    if (props.request.patient.facilityId) {
      const res = await getFacility(props.request.patient.facilityId);
      if (res instanceof Error || res == null) {
        setFacilityName("");
      } else {
        setFacilityName(
          <>
            <span className="text-bold">
              {res.name} <br />
            </span>
            {res.addressRoutingNumber} {res.addressRoutingWay}{" "}
            {res.addressRoutingWayName} {res.addressRoutingLabel}
          </>
        );
      }
    }
  }, [props.request.patient.facilityId]);

  useEffect(() => {
    if (
      props.request.patient.facilityId !== undefined &&
      props.request.patient.facilityId !== ""
    ) {
      getFacilityById();
    }
  }, [props.request.patient.facilityId]);

  const getPatientAddress = (
    patientLocation: PatientLocation,
    patientAddress?: PatientAddress
  ): any => {
    switch (patientLocation) {
      case PatientLocation.HOME:
        if (patientAddress && patientAddress.city) {
          return (
            <>
              {patientAddress.street && (
                <>
                  {patientAddress.street} <br />
                </>
              )}
              {patientAddress.postcode} {patientAddress.city}
            </>
          );
        } else {
          return "Adresse inconnue";
        }
      case PatientLocation.FACILITY:
        if (facilityName !== "") {
          return facilityName;
        } else {
          return "Nom du centre de soins inconnu";
        }
      default:
        return "Non renseignée";
    }
  };

  return (
    <Modal
      show
      onClose={(): any => props.onClose()}
      title={"Informations du patient"}
      footer={<></>}
      containerStyle={{ height: "45vh" }}
    >
      <button
        className={"edit-detail-btn mb-2 " + styles.closeBtn}
        onClick={(): void => {
          history.push(
            `/requests/${props.request.id}?page=${OpinionRequestFormPage.patientInformation}`
          );
        }}
        disabled={disabledEditBtn}
      >
        <img
          className="icon-disabled mobile-view-icon"
          alt="icon-edit"
          src={EditIcon}
        />
      </button>
      <div className="text-centered">
        {props.request.patient.firstName && props.request.patient.lastName && (
          <>
            <div
              className={"mt-2 mb-2 ml-auto mr-auto avatar " + styles.figure}
            >
              <Figure
                firstName={props.request.patient.firstName}
                lastName={props.request.patient.lastName}
                fontSize={"text-large"}
              />
            </div>
            <div className={"column col-12 " + styles.name}>
              {props.request?.patient.firstName +
                " " +
                props.request?.patient.lastName}
            </div>
          </>
        )}
        <div className="text-bold">{props.request.patient.localIdentifier}</div>
        <div className="text-centered">
          {props.request?.patient.sex === "MALE" && "Homme"}
          {props.request?.patient.sex === "FEMALE" && "Femme"}
          {props.request?.patient.sex &&
            getPatientAge(props.request?.patient.birthdate) !== undefined &&
            ", "}
          {props.request?.patient.birthdate.day !== "" &&
            props.request.patient.birthdate.day + "/"}
          {props.request?.patient.birthdate.month !== "" &&
            props.request.patient.birthdate.month + "/"}
          {props.request?.patient.birthdate.year !== "" &&
            props.request.patient.birthdate.year}
          {getPatientAge(props.request?.patient.birthdate) !== undefined &&
            ", " + getPatientAge(props.request.patient.birthdate)}
        </div>
      </div>
      <div className="row margin-top-2">
        <span className={`circle ${styles.listIconContainer}`}>
          <i className={`fas fa-list ${styles.icon}`} />{" "}
        </span>
        <div className="modal-label">Numéro de sécurité sociale :</div>
      </div>
      <div className={"mt-1 " + styles.details}>
        {props.request?.patient.socialSecurityNumber
          ? props.request?.patient.socialSecurityNumber
          : "Non renseigné"}
      </div>

      <div className="row">
        <span className={`circle ${styles.userIconContainer}`}>
          <i className={`fas fa-map-marked-alt ${styles.icon}`} />{" "}
        </span>
        <div className="modal-label">
          Localisation :{" "}
          {props.request?.patient.location &&
            getPatientLocation(props.request.patient.location)}
        </div>
      </div>
      <div className={styles.details}>
        {props.request?.patient.location &&
          getPatientAddress(
            props.request.patient.location,
            props.request.patient.address
          )}
      </div>
    </Modal>
  );
};
