import React from "react";
import styles from "./ModalDocumentationCps.module.css";

export const WindowsDocumentation: React.FC = () => {
  return (
    <div className={styles.documentationContent}>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>1</div>
        Téléchargez le fichier d&apos;installation
      </div>
      <div
        className={`text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        <span>
          Télécharger et extraire
          <a
            className={`${styles.downloadLink} ml-1`}
            href="https://icanopee-binary-files.cellar-c2.services.clever-cloud.com/DmpConnect-JS2-0.9.4-dev.exe"
          >
            DmpConnect-JS2-0.9.4-dev.exe
          </a>
        </span>
      </div>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>2</div>
        Installer l&apos;application
      </div>
      <div
        className={`text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        Veuillez vous assurer que votre carte est bien insérée dans le lecteur
        CPS. Lancez le fichier d&apos;installation téléchargé précédemment.
      </div>

      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>3</div>
        Sélectionner Cryptolib (optionnel)
      </div>
      <div
        className={`text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        Sélectionnez Cryptolib si vous n&apos;avez jamais utilisé votre lecteur
        CPS sur cet ordinateur.
      </div>
      <div className={`row margin-top-2 text-bold text-left text-black`}>
        <div className={`mr-4 ${styles.categoryNumber}`}>4</div>
        Finir l&apos;installation
      </div>
      <div
        className={`text-left text-grey mt-2 mb-4 ${styles.instructionSize}`}
      >
        Suivez l&apos;installation des composants.
      </div>
    </div>
  );
};
