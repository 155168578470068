import React, { useCallback, useContext, useEffect, useState } from "react";
import { getChat, saveMessage } from "../../services/ChatService";
import { errorToast } from "../../utils/Toast";
import { MessageBubble } from "./MessageBubble";
import "./OpinionChat.css";
import { AppContext } from "../../utils/state";
import { Textbox } from "./TextBox/Textbox";
import { getDate } from "../../utils/time";
import { ScrollToBottomButton } from "./ScrollToBottomButton";
import styles from "./OpinionChatTab.module.css";
import { Chat } from "../../domain/Chat";
import { toast } from "react-toastify";
import { requestVisio } from "../../services/OpinionRequestService";

interface Props {
  requestId: string;
  requestClosed: boolean;
  onClose: () => any;
  updateRequest: () => void;
}

export const OpinionChatTab: React.FC<any> = (props: Props) => {
  const {
    state: { user, authenticated },
  }: any = useContext(AppContext);

  const [messages, setMessages] = useState<any>([]);
  const [bottom, setBottom] = useState<boolean>(true); //true, to not show scroll button when we can't scroll yet
  let messageDate: string;

  const handleScroll = (e: any): void => {
    const btm =
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 40;
    setBottom(btm);
  };

  const refresh = useCallback((): void => {
    if (authenticated) {
      getChat(props.requestId).then((res: Chat | Error) => {
        if (res instanceof Error) {
          errorToast("Échec du chargement du chat");
          return;
        }
        const newMessageNumber = res.messages.length - messages.length;
        if (newMessageNumber > 0) {
          setMessages(res.messages);
        }
      });
    }
  }, [messages.length, props.requestId, authenticated]);

  useEffect(() => {
    if (!props.requestId) {
      return;
    }
    refresh();
    const interval = setInterval(() => refresh(), 30000);
    return (): void => {
      clearInterval(interval);
    };
  }, [props.requestId, refresh]);

  const onSendMessage = (message: string): void => {
    if (message.trim().length > 0) {
      saveMessage(props.requestId, {
        senderId: user ? user.id : "",
        message: message.trim(),
      }).then((res) => {
        refresh();
        if (
          res?.message ===
          "No message can be added to discussion because the opinion request is closed"
        ) {
          props.updateRequest();
          toast.success("La demande vient d'être mise à jour");
        }
      });
    }
  };

  const onRequestVisio = async () => {
    await requestVisio(props.requestId);
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const content = messages[messages.length - 1].message;
    const url = content.match(urlRegex)[1];
    window.open(url, "_blank");
    refresh();
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.scrollContainer} onScroll={handleScroll}>
        <div>
          {messages.length === 0 && authenticated && !props.requestClosed && (
            <b>Envoyez le premier message de la discussion.</b>
          )}
          {!authenticated && (
            <b>Veuillez vous créer un compte pour pouvoir envoyer un message</b>
          )}

          {messages.map((m: any, index: number) => {
            const date = getDate(m.createdAt, "dd/MM/yyyy");
            if (messageDate === undefined || messageDate !== date) {
              messageDate = date;
              return (
                <React.Fragment key={index}>
                  <div className="row mb-2">
                    <div className="date-line" />
                    <div className="ml-2 mr-2 date">{date}</div>
                    <div className="date-line" />
                  </div>
                  <MessageBubble
                    message={m}
                    direction={
                      user && user.id === m.senderId ? "right" : "left"
                    }
                  />
                </React.Fragment>
              );
            } else {
              return (
                <MessageBubble
                  key={index}
                  message={m}
                  direction={user && user.id === m.senderId ? "right" : "left"}
                />
              );
            }
          })}
          <ScrollToBottomButton messages={messages} bottom={bottom} />
        </div>
      </div>
      <div>
        {authenticated && !props.requestClosed && (
          <Textbox onSend={onSendMessage} onRequestVisio={onRequestVisio} />
        )}
        {authenticated && props.requestClosed && (
          <>
            {messages.length > 0 && <div className="footer-line" />}
            <b>
              La demande d&apos;avis a été clôturée, vous ne pouvez plus envoyer
              de messages
            </b>
          </>
        )}
      </div>
    </div>
  );
};
