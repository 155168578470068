import React, { useEffect } from "react";
import { HealthProfessionalUser } from "../../../domain/User";
import "./EditNewUser.css";

interface Props {
  newUser: HealthProfessionalUser;
  setNewUser: (user: HealthProfessionalUser) => any;
  setIsEmailValid: (isEmailValid: boolean) => any;
}

const isEmailValid = (email?: string): boolean =>
  email !== undefined && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);

export const EditNewUser: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    props.setIsEmailValid(isEmailValid(props.newUser.email));
  }, [props]);

  return (
    <>
      <div className="row centered-content">
        <div className="icon-edit-new-user">
          <i className="icon icon-edit" />
        </div>
      </div>
      <h5 className="text-center title-edit-new-user mt-2">Pas si vite !</h5>
      <div className="tile-subtitle text-center mt-2">
        {isEmailValid(props.newUser.email)
          ? "Avant de valider, veuillez vérifier que l'adresse email est correcte."
          : "Avant de valider, veuillez rentrer une adresse email correcte."}
      </div>
      <div className="mt-2 form-edit-new-user">
        <label className="label-edit-new-user">Prénom et Nom</label>
        <input
          className="form-input mt-1 mb-2 input-edit-new-user"
          id="new-user-firstname-and-lastname-input"
          name="newUserFirstName"
          type="text"
          placeholder="Prénom et Nom"
          disabled
          value={props.newUser.firstName + " " + props.newUser.lastName}
        />
        <label className="label-edit-new-user">Adresse mail</label>
        <input
          className="form-input mt-1 input-edit-new-user"
          id="new-user-email-input"
          name="newUserEmail"
          type="email"
          placeholder="Adresse mail"
          required={false}
          value={props.newUser.email}
          onChange={(e): void => {
            props.setNewUser({ ...props.newUser, email: e.target.value });
          }}
        />
        {!isEmailValid(props.newUser.email) && (
          <i className="error-invalid-email">
            L&apos;adresse mail saisie n&apos;est pas valide.
          </i>
        )}
      </div>
    </>
  );
};
