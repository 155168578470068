import { UserRoles } from "../domain/UserRoles";
import { UserStatus } from "../domain/UserStatus";

export function isConnected(userId: string): boolean {
  return userId !== "";
}

export function hasRole(userRoles: string[], role: UserRoles): boolean {
  return userRoles?.includes(role.toString());
}

export function hasStatus(userStatus: UserStatus, status: UserStatus): boolean {
  return userStatus === status;
}
