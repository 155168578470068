import React, { useState } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import {
  BirthPlace,
  searchInseeCodeByCity,
} from "../../services/AddressService";
import { errorToast } from "../../utils/Toast";

interface Props {
  patientBirthPlace?: string;
  onChange: (newState: string | undefined) => any;
}

export const PatientBirthPlaceForm: React.FC<Props> = (props: Props) => {
  const { control } = useForm<any>();
  const [results, setResults] = useState<BirthPlace[]>();

  const getSelectedCity = (): BirthPlace | undefined => {
    if (!props.patientBirthPlace) {
      return undefined;
    } else {
      return {
        city: "",
        citycode: props.patientBirthPlace,
        context: "",
      };
    }
  };

  const [selectedCity, setSelectedCity] = useState<BirthPlace | undefined>(
    getSelectedCity
  );

  const onSearch = async (query: string): Promise<any> => {
    const res = await searchInseeCodeByCity(query);
    if (res instanceof Error) {
      setResults([]);
      errorToast("Une erreur est survenue lors de la recherche");
    } else {
      setResults(res);
    }
  };

  const handleChange = (query: string): void => {
    if (query.length > 2) {
      onSearch(query);
    }
  };

  const onSelectBirthPlace = (option: BirthPlace): void => {
    if (option === null) {
      setSelectedCity(undefined);
      props.onChange(undefined);
    } else {
      setSelectedCity({
        city: option.city,
        citycode: option.citycode,
        context: option.context,
      });
      props.onChange(option.citycode);
    }
  };

  const formatOptionLabel = (birthPlace: BirthPlace): any => (
    <>
      {birthPlace && birthPlace.city ? (
        <>
          <div className="text-black">
            {birthPlace.city} - {birthPlace.citycode}
          </div>
          <div className="text-primary-blue text-medium">
            {birthPlace.context}
          </div>
        </>
      ) : (
        birthPlace &&
        birthPlace.citycode && (
          <div className="text-black">{birthPlace.citycode}</div>
        )
      )}
    </>
  );

  const selectCustomStyle = {
    control: (provided: any, state: any): Record<string, any> => ({
      borderBottom: state.isFocused
        ? "solid 2px var(--primary-blue)"
        : "0.05rem solid #bcc3ce",
      boxShadow: "var(--primary-blue)",
      display: "flex",
    }),
    placeholder: (): Record<string, any> => ({
      color: "var(--placeholder-grey)",
    }),
    option: (provided: any, state: any): Record<string, any> => ({
      ...provided,
      backgroundColor: state.isFocused ? "var(--secondary-blue)" : "white",
      ":hover": {
        backgroundColor: "var(--secondary-blue)",
      },
      color: "black",
      whiteSpace: "pre-wrap",
      textAlign: "left",
    }),
    singleValue: (): Record<string, any> => ({
      width: "100%",
    }),
    input: (): Record<string, any> => ({
      position: "absolute",
    }),
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="patient-birth-place-input">
        Ville de naissance (code INSEE)
      </label>
      <Controller
        name="birthPlaceField"
        control={control}
        render={(): any => (
          <Select
            name="birthPlaceField"
            className="w-90"
            id="patient-birthplace-input"
            isClearable={true}
            onInputChange={handleChange}
            options={results}
            value={selectedCity}
            formatOptionLabel={formatOptionLabel}
            onChange={(option: any): void => onSelectBirthPlace(option)}
            openMenuOnClick={false}
            placeholder="Entrer la ville de naissance"
            noOptionsMessage={(): any =>
              "Aucun résultat, veuillez compléter le nom de la ville"
            }
            styles={selectCustomStyle}
          />
        )}
      />
    </div>
  );
};
