enum PathologyEnum {
  // NEURO DISEASE
  NEURO_DISEASE_ACCIDENT_ISCHEMIQUE_CEREBRAL = "NEURO_DISEASE_ACCIDENT_ISCHEMIQUE_CEREBRAL",
  NEURO_DISEASE_ACCIDENT_HEMORRAGIQUE_CEREBRAL = "NEURO_DISEASE_ACCIDENT_HEMORRAGIQUE_CEREBRAL",
  NEURO_DISEASE_HEMORRAGIE_SOUS_ARACHNOIDIENNE = "NEURO_DISEASE_HEMORRAGIE_SOUS_ARACHNOIDIENNE",
  NEURO_DISEASE_ISCHEMIE_MEDULLAIRE = "NEURO_DISEASE_ISCHEMIE_MEDULLAIRE",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROVASCULAIRE = "NEURO_DISEASE_AUTRE_MALADIE_NEUROVASCULAIRE",
  NEURO_DISEASE_MALADIE_ALZHEIMER = "NEURO_DISEASE_MALADIE_ALZHEIMER",
  NEURO_DISEASE_DEMENCE_ACORPS_DE_LEWY = "NEURO_DISEASE_DEMENCE_ACORPS_DE_LEWY",
  NEURO_DISEASE_DEMENCE_VASCULAIRE = "NEURO_DISEASE_DEMENCE_VASCULAIRE",
  NEURO_DISEASE_AUTRE_DEMENCE = "NEURO_DISEASE_AUTRE_DEMENCE",
  NEURO_DISEASE_MALADIE_DE_PARKINSON = "NEURO_DISEASE_MALADIE_DE_PARKINSON",
  NEURO_DISEASE_ATROPHIE_MULTI_SYSTEMATISEE = "NEURO_DISEASE_ATROPHIE_MULTI_SYSTEMATISEE",
  NEURO_DISEASE_AUTRE_MALADIE_EXTRAPYRAMIDALE = "NEURO_DISEASE_AUTRE_MALADIE_EXTRAPYRAMIDALE",
  NEURO_DISEASE_SCLEROSE_EN_PLAQUE = "NEURO_DISEASE_SCLEROSE_EN_PLAQUE",
  NEURO_DISEASE_ENCEPHALITE_AUTO_IMMUNE = "NEURO_DISEASE_ENCEPHALITE_AUTO_IMMUNE",
  NEURO_DISEASE_MYELITE = "NEURO_DISEASE_MYELITE",
  NEURO_DISEASE_ADEM = "NEURO_DISEASE_ADEM",
  NEURO_DISEASE_AUTRE_MALADIE_NEURO_INFLAMATOIRE = "NEURO_DISEASE_AUTRE_MALADIE_NEURO_INFLAMATOIRE",
  NEURO_DISEASE_MENINGITE_BACTERIENNE = "NEURO_DISEASE_MENINGITE_BACTERIENNE",
  NEURO_DISEASE_MENINGO_ENCEPHALITE_VIRALE = "NEURO_DISEASE_MENINGO_ENCEPHALITE_VIRALE",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_INFECTIOLOGIE = "NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_INFECTIOLOGIE",
  NEURO_DISEASE_ETAT_DE_MAL_EPILEPTIQUE = "NEURO_DISEASE_ETAT_DE_MAL_EPILEPTIQUE",
  NEURO_DISEASE_MALADIE_EPILEPTIQUE = "NEURO_DISEASE_MALADIE_EPILEPTIQUE",
  NEURO_DISEASE_CRISE_INAUGURALE = "NEURO_DISEASE_CRISE_INAUGURALE",
  NEURO_DISEASE_TUMEUR_PRIMITIVE_CEREBRALE = "NEURO_DISEASE_TUMEUR_PRIMITIVE_CEREBRALE",
  NEURO_DISEASE_METASTASE = "NEURO_DISEASE_METASTASE",
  NEURO_DISEASE_MENINGITE_CARCINOMATEUSE = "NEURO_DISEASE_MENINGITE_CARCINOMATEUSE",
  NEURO_DISEASE_ENCEPHALITE_PARANEOPLASIQUE = "NEURO_DISEASE_ENCEPHALITE_PARANEOPLASIQUE",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_ONCOLOGIE = "NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_ONCOLOGIE",
  NEURO_DISEASE_TRAUMATISME_CRANIEN = "NEURO_DISEASE_TRAUMATISME_CRANIEN",
  NEURO_DISEASE_HYPERTENSION_INTRACRANIENNE = "NEURO_DISEASE_HYPERTENSION_INTRACRANIENNE",
  NEURO_DISEASE_ENCEPHALOPATHIE_CARENTIELLE = "NEURO_DISEASE_ENCEPHALOPATHIE_CARENTIELLE",
  NEURO_DISEASE_ENCEPHALOPATHIE_TOXIQUE = "NEURO_DISEASE_ENCEPHALOPATHIE_TOXIQUE",
  NEURO_DISEASE_TROUBLE_INNE_DU_METABOLISME = "NEURO_DISEASE_TROUBLE_INNE_DU_METABOLISME",
  NEURO_DISEASE_ENCEPHALOPATHIE_HEPATIQUE = "NEURO_DISEASE_ENCEPHALOPATHIE_HEPATIQUE",
  NEURO_DISEASE_SCLEROSE_LATERALE_AMYOTROPHIQUE = "NEURO_DISEASE_SCLEROSE_LATERALE_AMYOTROPHIQUE",
  NEURO_DISEASE_POLYRADICULONEUROPATHIE = "NEURO_DISEASE_POLYRADICULONEUROPATHIE",
  NEURO_DISEASE_MYASTHENIE_AUTO_IMMUNE = "NEURO_DISEASE_MYASTHENIE_AUTO_IMMUNE",
  NEURO_DISEASE_MYOSITE = "NEURO_DISEASE_MYOSITE",
  NEURO_DISEASE_MYOPATHIE = "NEURO_DISEASE_MYOPATHIE",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROMUSCULAIRE = "NEURO_DISEASE_AUTRE_MALADIE_NEUROMUSCULAIRE",

  // NEURO SYMPTOM
  NEURO_SYMPTOM_COMA = "NEURO_SYMPTOM_COMA",
  NEURO_SYMPTOM_ETAT_VEGETATIF_OU_CONSCIENCE_MINIMALE = "NEURO_SYMPTOM_ETAT_VEGETATIF_OU_CONSCIENCE_MINIMALE",
  NEURO_SYMPTOM_CONFUSION = "NEURO_SYMPTOM_CONFUSION",
  NEURO_SYMPTOM_ABSENCE = "NEURO_SYMPTOM_ABSENCE",
  NEURO_SYMPTOM_AUTRE_TROUBLE_CONSCIENCE = "NEURO_SYMPTOM_AUTRE_TROUBLE_CONSCIENCE",
  NEURO_SYMPTOM_DEFICIT_FAIBLESS_OU_FATIGUABILITE = "NEURO_SYMPTOM_DEFICIT_FAIBLESS_OU_FATIGUABILITE",
  NEURO_SYMPTOM_MYALGIE = "NEURO_SYMPTOM_MYALGIE",
  NEURO_SYMPTOM_SYNDROME_PYRAMIDAL = "NEURO_SYMPTOM_SYNDROME_PYRAMIDAL",
  NEURO_SYMPTOM_SYNDROME_EXTRAPYRAMIDAL = "NEURO_SYMPTOM_SYNDROME_EXTRAPYRAMIDAL",
  NEURO_SYMPTOM_TROUBLE_MARCHE_OU_EQUILIBRE = "NEURO_SYMPTOM_TROUBLE_MARCHE_OU_EQUILIBRE",
  NEURO_SYMPTOM_ATAXIE = "NEURO_SYMPTOM_ATAXIE",
  NEURO_SYMPTOM_AKINESIE = "NEURO_SYMPTOM_AKINESIE",
  NEURO_SYMPTOM_HYPERTONIE_RIGIDITE = "NEURO_SYMPTOM_HYPERTONIE_RIGIDITE",
  NEURO_SYMPTOM_MYOCLONIE = "NEURO_SYMPTOM_MYOCLONIE",
  NEURO_SYMPTOM_TREMBLEMENTS = "NEURO_SYMPTOM_TREMBLEMENTS",
  NEURO_SYMPTOM_AUTRE_TROUBLE_MOTEUR = "NEURO_SYMPTOM_AUTRE_TROUBLE_MOTEUR",
  NEURO_SYMPTOM_DOULEUR_NEUROPATHIQUE = "NEURO_SYMPTOM_DOULEUR_NEUROPATHIQUE",
  NEURO_SYMPTOM_RADICULALGIE = "NEURO_SYMPTOM_RADICULALGIE",
  NEURO_SYMPTOM_PARESTHESIE = "NEURO_SYMPTOM_PARESTHESIE",
  NEURO_SYMPTOM_ATAXIE_PROPRIOCEPTIVE = "NEURO_SYMPTOM_ATAXIE_PROPRIOCEPTIVE",
  NEURO_SYMPTOM_AUTRE_TROUBLE_SENSIBILITE = "NEURO_SYMPTOM_AUTRE_TROUBLE_SENSIBILITE",
  NEURO_SYMPTOM_TROUBLE_MNESIQUE = "NEURO_SYMPTOM_TROUBLE_MNESIQUE",
  NEURO_SYMPTOM_TROUBLE_PHASIQUE = "NEURO_SYMPTOM_TROUBLE_PHASIQUE",
  NEURO_SYMPTOM_TROUBLE_PRAXIQUE = "NEURO_SYMPTOM_TROUBLE_PRAXIQUE",
  NEURO_SYMPTOM_AUTRE_TROUBLE_COGNITIF = "NEURO_SYMPTOM_AUTRE_TROUBLE_COGNITIF",
  NEURO_SYMPTOM_PARALYSIE_FACIALE = "NEURO_SYMPTOM_PARALYSIE_FACIALE",
  NEURO_SYMPTOM_TROUBLE_DEGLUTITION = "NEURO_SYMPTOM_TROUBLE_DEGLUTITION",
  NEURO_SYMPTOM_DYSARTHRIE = "NEURO_SYMPTOM_DYSARTHRIE",
  NEURO_SYMPTOM_NEVRALGIE = "NEURO_SYMPTOM_NEVRALGIE",
  NEURO_SYMPTOM_AUTRE_ATTEINTE_DES_NERFS_CRANIENS = "NEURO_SYMPTOM_AUTRE_ATTEINTE_DES_NERFS_CRANIENS",
  NEURO_SYMPTOM_DYSTONIE = "NEURO_SYMPTOM_DYSTONIE",
  NEURO_SYMPTOM_DYSPHONIE = "NEURO_SYMPTOM_DYSPHONIE",
  NEURO_SYMPTOM_DYSPNEE = "NEURO_SYMPTOM_DYSPNEE",
  NEURO_SYMPTOM_TROUBLE_URINAIRE = "NEURO_SYMPTOM_TROUBLE_URINAIRE",
  NEURO_SYMPTOM_TROUBLE_HUMEUR = "NEURO_SYMPTOM_TROUBLE_HUMEUR",
  NEURO_SYMPTOM_HALLUCINATIONS = "NEURO_SYMPTOM_HALLUCINATIONS",
  NEURO_SYMPTOM_AUTRE_SYMPTOMES_NEUROLOGIQUES = "NEURO_SYMPTOM_AUTRE_SYMPTOMES_NEUROLOGIQUES",
  NEURO_SYMPTOM_DOULEUR_MYALGIE = "NEURO_SYMPTOM_DOULEUR_MYALGIE",
  NEURO_SYMPTOM_DOULEUR_NEVRALGIE = "NEURO_SYMPTOM_DOULEUR_NEVRALGIE",
  NEURO_SYMPTOM_DOULEUR_RADICULALGIE = "NEURO_SYMPTOM_DOULEUR_RADICULALGIE",
  NEURO_SYMPTOM_DOULEUR_PARESTHESIE = "NEURO_SYMPTOM_DOULEUR_PARESTHESIE",
  NEURO_SYMPTOM_AUTRE_DOULEUR = "NEURO_SYMPTOM_AUTRE_DOULEUR",

  // MPR DISEASE NEURO
  MPR_DISEASE_NEURO_STROKE = "MPR_DISEASE_NEURO_STROKE",
  MPR_DISEASE_NEURO_MUSCULAR_MYOPATHY = "MPR_DISEASE_NEURO_MUSCULAR_MYOPATHY",
  MPR_DISEASE_NEURO_PERIF_NEURO_POLY_RADICULO_NEURITIS = "MPR_DISEASE_NEURO_PERIF_NEURO_POLY_RADICULO_NEURITIS",
  MPR_DISEASE_NEURO_HEAD_TRAUMA = "MPR_DISEASE_NEURO_HEAD_TRAUMA",
  MPR_DISEASE_NEURO_SUBARACH_HEMORRHAGE = "MPR_DISEASE_NEURO_SUBARACH_HEMORRHAGE",
  MPR_DISEASE_NEURO_PARKINSON_NEURO_DEGENERATIVE = "MPR_DISEASE_NEURO_PARKINSON_NEURO_DEGENERATIVE",
  MPR_DISEASE_NEURO_DYSTONIA = "MPR_DISEASE_NEURO_DYSTONIA",
  MPR_DISEASE_NEURO_AMYOTROPHIC_LATERAL_SCLEROSIS = "MPR_DISEASE_NEURO_AMYOTROPHIC_LATERAL_SCLEROSIS",
  MPR_DISEASE_NEURO_MUTIPLE_SCLEROSIS = "MPR_DISEASE_NEURO_MUTIPLE_SCLEROSIS",
  MPR_DISEASE_NEURO_CEREBRAL_PALSY_MULTICHILDHOOD_DISEASE = "MPR_DISEASE_NEURO_CEREBRAL_PALSY_MULTICHILDHOOD_DISEASE",
  MPR_DISEASE_NEURO_BRRAIN_TUMOUR = "MPR_DISEASE_NEURO_BRRAIN_TUMOUR",

  // MPR DISEASE LOCOMOTEUR
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_STRONG = "MPR_DISEASE_LOCOMOTIVE_NEURALGIA_STRONG",
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_CHRONIC = "MPR_DISEASE_LOCOMOTIVE_NEURALGIA_CHRONIC",
  MPR_DISEASE_LOCOMOTIVE_ARTHROSIS = "MPR_DISEASE_LOCOMOTIVE_ARTHROSIS",
  MPR_DISEASE_LOCOMOTIVE_RHEUMATOID_ARTHRITIS = "MPR_DISEASE_LOCOMOTIVE_RHEUMATOID_ARTHRITIS",
  MPR_DISEASE_LOCOMOTIVE_SPONDYLITIS = "MPR_DISEASE_LOCOMOTIVE_SPONDYLITIS",

  // MPR SYMPTOM
  MPR_SYMPTOM_SPINAL_CORD_INJURY = "MPR_SYMPTOM_SPINAL_CORD_INJURY",
  MPR_SYMPTOM_FITTINGS = "MPR_SYMPTOM_FITTINGS",
  MPR_SYMPTOM_CAUDA_EQUINA_SYNDROME = "MPR_SYMPTOM_CAUDA_EQUINA_SYNDROME",
  MPR_SYMPTOM_BEDSORES = "MPR_SYMPTOM_BEDSORES",
  MPR_SYMPTOM_GAIT_BALANCE_DISORDERS = "MPR_SYMPTOM_GAIT_BALANCE_DISORDERS",
  MPR_SYMPTOM_URINATION_DISORDERS = "MPR_SYMPTOM_URINATION_DISORDERS",
  MPR_SYMPTOM_URINARY_FUNCTIONAL_SIGNS = "MPR_SYMPTOM_URINARY_FUNCTIONAL_SIGNS",
  MPR_SYMPTOM_SPINAL_DISORDERS = "MPR_SYMPTOM_SPINAL_DISORDERS",
  MPR_SYMPTOM_COMPLEX_REGIONAL_PAIN_SYNDROME = "MPR_SYMPTOM_COMPLEX_REGIONAL_PAIN_SYNDROME",
  MPR_SYMPTOM_SHOULDER_HAND_SYNDROME = "MPR_SYMPTOM_SHOULDER_HAND_SYNDROME",
  MPR_SYMPTOM_MOTOR_DISORDERS_SPASTICITY = "MPR_SYMPTOM_MOTOR_DISORDERS_SPASTICITY",
  MPR_SYMPTOM_ORTHOPEDIC_DISORDERS = "MPR_SYMPTOM_ORTHOPEDIC_DISORDERS",
  MPR_SYMPTOM_SWALLOWING_DISORDERS = "MPR_SYMPTOM_SWALLOWING_DISORDERS",
  MPR_SYMPTOM_COGNITIVE_BEHAVIORAL_DISORDERS = "MPR_SYMPTOM_COGNITIVE_BEHAVIORAL_DISORDERS",
  MPR_SYMPTOM_TRACHEOTOMY = "MPR_SYMPTOM_TRACHEOTOMY",
  MPR_SYMPTOM_SLEEPING_DISORDERS = "MPR_SYMPTOM_SLEEPING_DISORDERS",
  MPR_SYMPTOM_CHRONIC_PAIN = "MPR_SYMPTOM_CHRONIC_PAIN",
  MPR_SYMPTOM_PATIENT_UNDER_MECHANICAL_VENTILATION = "MPR_SYMPTOM_PATIENT_UNDER_MECHANICAL_VENTILATION",
  MPR_SYMPTOM_SLEEP_APNEA = "MPR_SYMPTOM_SLEEP_APNEA",
}

export const pathologyTranslate: Record<PathologyEnum, string> = {
  // NEURO DISEASE NEW
  NEURO_DISEASE_ACCIDENT_ISCHEMIQUE_CEREBRAL: "Accident Ischémique Cérébral",
  NEURO_DISEASE_ACCIDENT_HEMORRAGIQUE_CEREBRAL:
    "Accident hémorragique Cérébral",
  NEURO_DISEASE_HEMORRAGIE_SOUS_ARACHNOIDIENNE:
    "Hémorragie sous-arachnoidienne",
  NEURO_DISEASE_ISCHEMIE_MEDULLAIRE: "Ischémie médullaire",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROVASCULAIRE: "Autre maladie neurovasculaire",
  NEURO_DISEASE_MALADIE_ALZHEIMER: "Maladie d'Alzheimer",
  NEURO_DISEASE_DEMENCE_ACORPS_DE_LEWY: "Démence à corps de Lewy",
  NEURO_DISEASE_DEMENCE_VASCULAIRE: "Démence vasculaire",
  NEURO_DISEASE_AUTRE_DEMENCE: "Autre démence",
  NEURO_DISEASE_MALADIE_DE_PARKINSON: "Maladie de Parkinson",
  NEURO_DISEASE_ATROPHIE_MULTI_SYSTEMATISEE: "Atrophie multi-systématisée",
  NEURO_DISEASE_AUTRE_MALADIE_EXTRAPYRAMIDALE: "Autre maladie extrapyramidale",
  NEURO_DISEASE_SCLEROSE_EN_PLAQUE: "Sclérose en plaque",
  NEURO_DISEASE_ENCEPHALITE_AUTO_IMMUNE: "Encéphalite auto-immune",
  NEURO_DISEASE_MYELITE: "Myélite",
  NEURO_DISEASE_ADEM: "ADEM",
  NEURO_DISEASE_AUTRE_MALADIE_NEURO_INFLAMATOIRE:
    "Autre maladie neuro-inflamatoire",
  NEURO_DISEASE_MENINGITE_BACTERIENNE: "Méningite bactérienne",
  NEURO_DISEASE_MENINGO_ENCEPHALITE_VIRALE: "Méningo-encéphalite virale",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_INFECTIOLOGIE:
    "Autre maladie de neuro-infectiologie",
  NEURO_DISEASE_ETAT_DE_MAL_EPILEPTIQUE: "Etat de mal épileptique",
  NEURO_DISEASE_MALADIE_EPILEPTIQUE: "Maladie épileptique",
  NEURO_DISEASE_CRISE_INAUGURALE: "Crise inaugurale",
  NEURO_DISEASE_TUMEUR_PRIMITIVE_CEREBRALE: "Tumeur primitive cérébrale",
  NEURO_DISEASE_METASTASE: "Métastase",
  NEURO_DISEASE_MENINGITE_CARCINOMATEUSE: "Méningite carcinomateuse",
  NEURO_DISEASE_ENCEPHALITE_PARANEOPLASIQUE: "Encéphalite paranéoplasique",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_ONCOLOGIE:
    "Autre maladie de neuro-oncologie",
  NEURO_DISEASE_TRAUMATISME_CRANIEN: "Traumatisme crânien",
  NEURO_DISEASE_HYPERTENSION_INTRACRANIENNE: "Hypertension intracranienne",
  NEURO_DISEASE_ENCEPHALOPATHIE_CARENTIELLE: "Encéphalopathie caréntielle",
  NEURO_DISEASE_ENCEPHALOPATHIE_TOXIQUE: "Encéphalopathie toxique",
  NEURO_DISEASE_TROUBLE_INNE_DU_METABOLISME: "Trouble inné du métabolisme",
  NEURO_DISEASE_ENCEPHALOPATHIE_HEPATIQUE: "Encéphalopathie hépatique",
  NEURO_DISEASE_SCLEROSE_LATERALE_AMYOTROPHIQUE:
    "Sclérose Latérale Amyotrophique",
  NEURO_DISEASE_POLYRADICULONEUROPATHIE: "Polyradiculoneuropathie",
  NEURO_DISEASE_MYASTHENIE_AUTO_IMMUNE: "Myasthénie auto-immune",
  NEURO_DISEASE_MYOSITE: "Myosite",
  NEURO_DISEASE_MYOPATHIE: "Myopathie",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROMUSCULAIRE: "Autre maladie neuromusculaire",

  // NEURO SYMPTOM
  NEURO_SYMPTOM_COMA: "Coma",
  NEURO_SYMPTOM_ETAT_VEGETATIF_OU_CONSCIENCE_MINIMALE:
    "Etat végétatif ou de conscience minimale",
  NEURO_SYMPTOM_CONFUSION: "Confusion",
  NEURO_SYMPTOM_ABSENCE: "Absence",
  NEURO_SYMPTOM_AUTRE_TROUBLE_CONSCIENCE: "Autre trouble de la conscience",
  NEURO_SYMPTOM_DEFICIT_FAIBLESS_OU_FATIGUABILITE:
    "Déficit, faibless ou fatiguabilité",
  NEURO_SYMPTOM_MYALGIE: "Myalgie",
  NEURO_SYMPTOM_SYNDROME_PYRAMIDAL: "Syndrome pyramidal",
  NEURO_SYMPTOM_SYNDROME_EXTRAPYRAMIDAL: "Syndrome extrapyramidal",
  NEURO_SYMPTOM_TROUBLE_MARCHE_OU_EQUILIBRE:
    "Trouble de la marche ou de l'équilibre",
  NEURO_SYMPTOM_ATAXIE: "Ataxie",
  NEURO_SYMPTOM_AKINESIE: "Akinésie",
  NEURO_SYMPTOM_HYPERTONIE_RIGIDITE: "Hypertonie/rigidité",
  NEURO_SYMPTOM_MYOCLONIE: "Myoclonie",
  NEURO_SYMPTOM_TREMBLEMENTS: "Tremblements",
  NEURO_SYMPTOM_AUTRE_TROUBLE_MOTEUR: "Autre trouble moteur",
  NEURO_SYMPTOM_DOULEUR_NEUROPATHIQUE: "Douleur neuropathique",
  NEURO_SYMPTOM_RADICULALGIE: "Radiculalgie",
  NEURO_SYMPTOM_PARESTHESIE: "Paresthésie",
  NEURO_SYMPTOM_ATAXIE_PROPRIOCEPTIVE: "Ataxie proprioceptive",
  NEURO_SYMPTOM_AUTRE_TROUBLE_SENSIBILITE: "Autre trouble de la sensibilité",
  NEURO_SYMPTOM_TROUBLE_MNESIQUE: "Trouble mnésique",
  NEURO_SYMPTOM_TROUBLE_PHASIQUE: "Trouble phasique",
  NEURO_SYMPTOM_TROUBLE_PRAXIQUE: "Trouble praxique",
  NEURO_SYMPTOM_AUTRE_TROUBLE_COGNITIF: "Autre trouble cognitif",
  NEURO_SYMPTOM_PARALYSIE_FACIALE: "Paralysie faciale",
  NEURO_SYMPTOM_TROUBLE_DEGLUTITION: "Trouble de la déglutition",
  NEURO_SYMPTOM_DYSARTHRIE: "Dysarthrie",
  NEURO_SYMPTOM_NEVRALGIE: "Névralgie",
  NEURO_SYMPTOM_AUTRE_ATTEINTE_DES_NERFS_CRANIENS:
    "Autre atteinte des nerfs crâniens",
  NEURO_SYMPTOM_DYSTONIE: "Dystonie",
  NEURO_SYMPTOM_DYSPHONIE: "Dysphonie",
  NEURO_SYMPTOM_DYSPNEE: "Dyspnée",
  NEURO_SYMPTOM_TROUBLE_URINAIRE: "Trouble urinaire",
  NEURO_SYMPTOM_TROUBLE_HUMEUR: "Trouble de l'humeur",
  NEURO_SYMPTOM_HALLUCINATIONS: "Hallucinations",
  NEURO_SYMPTOM_AUTRE_SYMPTOMES_NEUROLOGIQUES: "Autre symptômes neurologiques",
  NEURO_SYMPTOM_DOULEUR_MYALGIE: "Myalgie",
  NEURO_SYMPTOM_DOULEUR_NEVRALGIE: "Névralgie",
  NEURO_SYMPTOM_DOULEUR_RADICULALGIE: "Radiculalgie",
  NEURO_SYMPTOM_DOULEUR_PARESTHESIE: "Paresthésie",
  NEURO_SYMPTOM_AUTRE_DOULEUR: "Autre douleur",

  // MPR DISEASE NEURO
  MPR_DISEASE_NEURO_STROKE: "Accident vasculaire cérébral (AVC)",
  MPR_DISEASE_NEURO_MUSCULAR_MYOPATHY: "Maladie neuromusculaire, myopathie",
  MPR_DISEASE_NEURO_PERIF_NEURO_POLY_RADICULO_NEURITIS:
    "Neuropathie périphérique, polynévrite, polyradiculonévrite",
  MPR_DISEASE_NEURO_HEAD_TRAUMA: "Traumatisme crânien",
  MPR_DISEASE_NEURO_SUBARACH_HEMORRHAGE: "Hémorragie sous arachnoïdienne",
  MPR_DISEASE_NEURO_PARKINSON_NEURO_DEGENERATIVE:
    "Maladie de Parkinson, maladie neurodégénérative",
  MPR_DISEASE_NEURO_DYSTONIA: "Dystonie",
  MPR_DISEASE_NEURO_AMYOTROPHIC_LATERAL_SCLEROSIS:
    "Sclérose Latérale amyotrophique",
  MPR_DISEASE_NEURO_MUTIPLE_SCLEROSIS: "Sclérose en plaques",
  MPR_DISEASE_NEURO_CEREBRAL_PALSY_MULTICHILDHOOD_DISEASE:
    "Paralysie cérébrale, polyhandicap de l’enfance",
  MPR_DISEASE_NEURO_BRRAIN_TUMOUR: "Tumeur cérébrale",

  // MPR DISEASE LOCOMOTEUR
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_STRONG: "Rachialgie aigue",
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_CHRONIC: "Rachialgie chronique",
  MPR_DISEASE_LOCOMOTIVE_ARTHROSIS: "Arthrose",
  MPR_DISEASE_LOCOMOTIVE_RHEUMATOID_ARTHRITIS: "Polyarthrite rhumatoïde",
  MPR_DISEASE_LOCOMOTIVE_SPONDYLITIS: "Spondylarthrite",

  // MPR SYMPTOM
  MPR_SYMPTOM_SPINAL_CORD_INJURY: "Blessé médullaire/pompes à lioresal",
  MPR_SYMPTOM_FITTINGS: "Appareillage",
  MPR_SYMPTOM_CAUDA_EQUINA_SYNDROME: "Syndrome de la queue de cheval",
  MPR_SYMPTOM_BEDSORES: "Escarres",
  MPR_SYMPTOM_GAIT_BALANCE_DISORDERS: "Troubles de la marche et de l’équilibre",
  MPR_SYMPTOM_URINATION_DISORDERS: "Troubles de la miction",
  MPR_SYMPTOM_URINARY_FUNCTIONAL_SIGNS: "Signes fonctionnels urinaires",
  MPR_SYMPTOM_SPINAL_DISORDERS: "Troubles rachidiens",
  MPR_SYMPTOM_COMPLEX_REGIONAL_PAIN_SYNDROME:
    "Syndrome douloureux régional complexe",
  MPR_SYMPTOM_SHOULDER_HAND_SYNDROME: "Syndrome épaule-main",
  MPR_SYMPTOM_MOTOR_DISORDERS_SPASTICITY: "Troubles moteurs spasticité",
  MPR_SYMPTOM_ORTHOPEDIC_DISORDERS: "Troubles orthopédiques",
  MPR_SYMPTOM_SWALLOWING_DISORDERS: "Troubles de la déglutition",
  MPR_SYMPTOM_COGNITIVE_BEHAVIORAL_DISORDERS:
    "Troubles cognitifs/du comportement",
  MPR_SYMPTOM_TRACHEOTOMY: "Trachéotomie",
  MPR_SYMPTOM_SLEEPING_DISORDERS: "Troubles du sommeil",
  MPR_SYMPTOM_CHRONIC_PAIN: "Douleurs chroniques",
  MPR_SYMPTOM_PATIENT_UNDER_MECHANICAL_VENTILATION:
    "Patient sous ventilation mécanique",
  MPR_SYMPTOM_SLEEP_APNEA: "Apnée du sommeil",
};

export const pathologyStyleClass: Record<PathologyEnum, string> = {
  // NEURO DISEASE NEW
  NEURO_DISEASE_ACCIDENT_ISCHEMIQUE_CEREBRAL: "neuroDiseaseTag",
  NEURO_DISEASE_ACCIDENT_HEMORRAGIQUE_CEREBRAL: "neuroDiseaseTag",
  NEURO_DISEASE_HEMORRAGIE_SOUS_ARACHNOIDIENNE: "neuroDiseaseTag",
  NEURO_DISEASE_ISCHEMIE_MEDULLAIRE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROVASCULAIRE: "neuroDiseaseTag",
  NEURO_DISEASE_MALADIE_ALZHEIMER: "neuroDiseaseTag",
  NEURO_DISEASE_DEMENCE_ACORPS_DE_LEWY: "neuroDiseaseTag",
  NEURO_DISEASE_DEMENCE_VASCULAIRE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_DEMENCE: "neuroDiseaseTag",
  NEURO_DISEASE_MALADIE_DE_PARKINSON: "neuroDiseaseTag",
  NEURO_DISEASE_ATROPHIE_MULTI_SYSTEMATISEE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_EXTRAPYRAMIDALE: "neuroDiseaseTag",
  NEURO_DISEASE_SCLEROSE_EN_PLAQUE: "neuroDiseaseTag",
  NEURO_DISEASE_ENCEPHALITE_AUTO_IMMUNE: "neuroDiseaseTag",
  NEURO_DISEASE_MYELITE: "neuroDiseaseTag",
  NEURO_DISEASE_ADEM: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_NEURO_INFLAMATOIRE: "neuroDiseaseTag",
  NEURO_DISEASE_MENINGITE_BACTERIENNE: "neuroDiseaseTag",
  NEURO_DISEASE_MENINGO_ENCEPHALITE_VIRALE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_INFECTIOLOGIE: "neuroDiseaseTag",
  NEURO_DISEASE_ETAT_DE_MAL_EPILEPTIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_MALADIE_EPILEPTIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_CRISE_INAUGURALE: "neuroDiseaseTag",
  NEURO_DISEASE_TUMEUR_PRIMITIVE_CEREBRALE: "neuroDiseaseTag",
  NEURO_DISEASE_METASTASE: "neuroDiseaseTag",
  NEURO_DISEASE_MENINGITE_CARCINOMATEUSE: "neuroDiseaseTag",
  NEURO_DISEASE_ENCEPHALITE_PARANEOPLASIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_ONCOLOGIE: "neuroDiseaseTag",
  NEURO_DISEASE_TRAUMATISME_CRANIEN: "neuroDiseaseTag",
  NEURO_DISEASE_HYPERTENSION_INTRACRANIENNE: "neuroDiseaseTag",
  NEURO_DISEASE_ENCEPHALOPATHIE_CARENTIELLE: "neuroDiseaseTag",
  NEURO_DISEASE_ENCEPHALOPATHIE_TOXIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_TROUBLE_INNE_DU_METABOLISME: "neuroDiseaseTag",
  NEURO_DISEASE_ENCEPHALOPATHIE_HEPATIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_SCLEROSE_LATERALE_AMYOTROPHIQUE: "neuroDiseaseTag",
  NEURO_DISEASE_POLYRADICULONEUROPATHIE: "neuroDiseaseTag",
  NEURO_DISEASE_MYASTHENIE_AUTO_IMMUNE: "neuroDiseaseTag",
  NEURO_DISEASE_MYOSITE: "neuroDiseaseTag",
  NEURO_DISEASE_MYOPATHIE: "neuroDiseaseTag",
  NEURO_DISEASE_AUTRE_MALADIE_NEUROMUSCULAIRE: "neuroDiseaseTag",

  // NEURO SYMPTOM
  NEURO_SYMPTOM_COMA: "neuroSymptomsTag",
  NEURO_SYMPTOM_ETAT_VEGETATIF_OU_CONSCIENCE_MINIMALE: "neuroSymptomsTag",
  NEURO_SYMPTOM_CONFUSION: "neuroSymptomsTag",
  NEURO_SYMPTOM_ABSENCE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_TROUBLE_CONSCIENCE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DEFICIT_FAIBLESS_OU_FATIGUABILITE: "neuroSymptomsTag",
  NEURO_SYMPTOM_MYALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_SYNDROME_PYRAMIDAL: "neuroSymptomsTag",
  NEURO_SYMPTOM_SYNDROME_EXTRAPYRAMIDAL: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_MARCHE_OU_EQUILIBRE: "neuroSymptomsTag",
  NEURO_SYMPTOM_ATAXIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AKINESIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_HYPERTONIE_RIGIDITE: "neuroSymptomsTag",
  NEURO_SYMPTOM_MYOCLONIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TREMBLEMENTS: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_TROUBLE_MOTEUR: "neuroSymptomsTag",
  NEURO_SYMPTOM_DOULEUR_NEUROPATHIQUE: "neuroSymptomsTag",
  NEURO_SYMPTOM_RADICULALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_PARESTHESIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_ATAXIE_PROPRIOCEPTIVE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_TROUBLE_SENSIBILITE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_MNESIQUE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_PHASIQUE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_PRAXIQUE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_TROUBLE_COGNITIF: "neuroSymptomsTag",
  NEURO_SYMPTOM_PARALYSIE_FACIALE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_DEGLUTITION: "neuroSymptomsTag",
  NEURO_SYMPTOM_DYSARTHRIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_NEVRALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_ATTEINTE_DES_NERFS_CRANIENS: "neuroSymptomsTag",
  NEURO_SYMPTOM_DYSTONIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DYSPHONIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DYSPNEE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_URINAIRE: "neuroSymptomsTag",
  NEURO_SYMPTOM_TROUBLE_HUMEUR: "neuroSymptomsTag",
  NEURO_SYMPTOM_HALLUCINATIONS: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_SYMPTOMES_NEUROLOGIQUES: "neuroSymptomsTag",
  NEURO_SYMPTOM_DOULEUR_MYALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DOULEUR_NEVRALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DOULEUR_RADICULALGIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_DOULEUR_PARESTHESIE: "neuroSymptomsTag",
  NEURO_SYMPTOM_AUTRE_DOULEUR: "neuroSymptomsTag",

  // MPR DISEASE NEURO
  MPR_DISEASE_NEURO_STROKE: "mprDiseaseTag",
  MPR_DISEASE_NEURO_MUSCULAR_MYOPATHY: "mprDiseaseTag",
  MPR_DISEASE_NEURO_PERIF_NEURO_POLY_RADICULO_NEURITIS: "mprDiseaseTag",
  MPR_DISEASE_NEURO_HEAD_TRAUMA: "mprDiseaseTag",
  MPR_DISEASE_NEURO_SUBARACH_HEMORRHAGE: "mprDiseaseTag",
  MPR_DISEASE_NEURO_PARKINSON_NEURO_DEGENERATIVE: "mprDiseaseTag",
  MPR_DISEASE_NEURO_DYSTONIA: "mprDiseaseTag",
  MPR_DISEASE_NEURO_AMYOTROPHIC_LATERAL_SCLEROSIS: "mprDiseaseTag",
  MPR_DISEASE_NEURO_MUTIPLE_SCLEROSIS: "mprDiseaseTag",
  MPR_DISEASE_NEURO_CEREBRAL_PALSY_MULTICHILDHOOD_DISEASE: "mprDiseaseTag",
  MPR_DISEASE_NEURO_BRRAIN_TUMOUR: "mprDiseaseTag",

  // MPR DISEASE LOCOMOTEUR
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_STRONG: "mprDiseaseTag",
  MPR_DISEASE_LOCOMOTIVE_NEURALGIA_CHRONIC: "mprDiseaseTag",
  MPR_DISEASE_LOCOMOTIVE_ARTHROSIS: "mprDiseaseTag",
  MPR_DISEASE_LOCOMOTIVE_RHEUMATOID_ARTHRITIS: "mprDiseaseTag",
  MPR_DISEASE_LOCOMOTIVE_SPONDYLITIS: "mprDiseaseTag",

  // MPR SYMPTOM
  MPR_SYMPTOM_SPINAL_CORD_INJURY: "mprSymptomsTag",
  MPR_SYMPTOM_FITTINGS: "mprSymptomsTag",
  MPR_SYMPTOM_CAUDA_EQUINA_SYNDROME: "mprSymptomsTag",
  MPR_SYMPTOM_BEDSORES: "mprSymptomsTag",
  MPR_SYMPTOM_GAIT_BALANCE_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_URINATION_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_URINARY_FUNCTIONAL_SIGNS: "mprSymptomsTag",
  MPR_SYMPTOM_SPINAL_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_COMPLEX_REGIONAL_PAIN_SYNDROME: "mprSymptomsTag",
  MPR_SYMPTOM_SHOULDER_HAND_SYNDROME: "mprSymptomsTag",
  MPR_SYMPTOM_MOTOR_DISORDERS_SPASTICITY: "mprSymptomsTag",
  MPR_SYMPTOM_ORTHOPEDIC_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_SWALLOWING_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_COGNITIVE_BEHAVIORAL_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_TRACHEOTOMY: "mprSymptomsTag",
  MPR_SYMPTOM_SLEEPING_DISORDERS: "mprSymptomsTag",
  MPR_SYMPTOM_CHRONIC_PAIN: "mprSymptomsTag",
  MPR_SYMPTOM_PATIENT_UNDER_MECHANICAL_VENTILATION: "mprSymptomsTag",
  MPR_SYMPTOM_SLEEP_APNEA: "mprSymptomsTag",
};

export default PathologyEnum;
