import { get, send } from "./ApiClient";
import {
  ProfessionCategory,
  HealthProfessionalUser,
  User,
} from "../domain/User";
import { ErrorResponse } from "../domain/ErrorManagement";

/**
 * Get connected user profile, or return Error if not authenticated.
 */
export async function getProfile(): Promise<User | Error> {
  try {
    const response: User | Error = await get("/me");
    if (response instanceof Error) {
      return new Error(response?.message);
    } else {
      return response;
    }
  } catch (err) {
    return err;
  }
}

/**
 * List users
 */
export async function listUsers(): Promise<User[] | Error> {
  return get("/users");
}

/**
 * List all users that mathes the given filters
 * @param search query
 * @param professionFilter (type ProfessionCategory) filter on profession category (can be doctor, paramedical or both)
 */
export async function searchHealthProfessionalsAndUsers(
  search: string,
  professionFilter: ProfessionCategory,
  specialty: string,
  subSpecialty: string,
  facilityType: string
): Promise<HealthProfessionalUser[] | Error> {
  return get(
    `/health-professionals?search=${search}&professionFilter=${professionFilter}&specialty=${specialty}&subSpecialty=${subSpecialty}&facilityType=${facilityType}`
  );
}

/**
 * List all users
 */
export async function listAllUsers(): Promise<User[] | Error> {
  return get("/users/all");
}

/**
 * Retrieve the user matching given id
 * @param id user unique id
 */
export async function getUser(id: string): Promise<User | Error> {
  return get("/user/" + id);
}

/**
 * Create or update user availabilities
 * @param user user with its new availabilities
 */
export async function createUserAvailabilities(
  user: User
): Promise<any | Error> {
  return send(`/user/${user.id}/availabilities/update`, "POST", user);
}

export async function searchExpertBySpecialtyAndName(
  name: string,
  specialty: string,
  subSpecialty: string,
  facilityType: string
): Promise<User[] | Error> {
  try {
    return await get(
      `/users?name=${name}&specialty=${specialty}&subSpecialty=${subSpecialty}&validated=true&facilityType=${facilityType}`
    );
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function searchExpertByName(
  name: string
): Promise<User[] | Error> {
  try {
    return await get(`/users?name=${name}`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

/**
 * Validation of a user by the admin
 * @param user to validate
 */
export async function validateUserByAdmin(user: User): Promise<void | Error> {
  return send(`/user/${user.id}/validate`, "PUT");
}

export async function deleteUserByAdmin(
  id: string
): Promise<void | Error | ErrorResponse> {
  return send(`/user/${id}`, "DELETE");
}

export async function updateUserByAdmin(user: User): Promise<User | Error> {
  return send(`/user/${user.id}`, "PUT", user);
}

export async function updateCurrentUser(user: User): Promise<void | Error> {
  return send(`/me`, "PUT", user);
}

export async function askSelfieUpdateByAdmin(
  userId: string
): Promise<void | Error | ErrorResponse> {
  return send(`/user/${userId}/update-selfie`, "PUT");
}

export async function updateNotificationsPreferences(
  user: User
): Promise<void | Error> {
  return send(`/user/${user.id}/preferences`, "PUT", user);
}

export async function createUser(
  healthProfessionalUser: HealthProfessionalUser
): Promise<any | Error | ErrorResponse> {
  return send(`/user`, "POST", healthProfessionalUser);
}

export async function createUserByAdmin(
  healthProfessionalUser: HealthProfessionalUser
): Promise<any | Error | ErrorResponse> {
  return send(`/admin/create-user`, "POST", healthProfessionalUser);
}

export async function agreeToTermsOfUse(): Promise<void | Error> {
  return send(`/terms-of-use-agreement`, "PUT");
}
