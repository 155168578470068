import { Consent } from "../components/OpinionRequestForm/PatientConsent";
import { PatientLocation } from "../components/OpinionRequestForm/PatientLocationForm";
import { getOrganizationById } from "../services/OrganizationService";
import { hasRole } from "../utils/authorization";
import { EmergencyLevel } from "./EmergencyLevel";
import { ExpertReply } from "./ExpertReply";
import { ExpertStatus } from "./ExpertStatus";
import { Organization } from "./Organization";
import { RequestStatus } from "./RequestStatus";
import SpecialtyEnum from "./Subject/Specialty.enum";
import { Subject } from "./Subject/Subject";
import { HealthProfessionalTitle, User } from "./User";
import { UserRoles } from "./UserRoles";
import { EegReason } from "./EEGReason/EegReason";

/**
 * A request from a doctor to an expert. Light version
 submitDate: string;
 */
export interface OpinionRequestSummary {
  id?: string;
  emergencyLevel: EmergencyLevel;
  consent: Consent;
  patient: Patient;
  participants?: Participant[];
  primaryDisease?: string;
  motive?: string;
  status?: RequestStatus;
  requesterUser?: User;
  assignedExperts?: Expert[];
  suggestedExpert?: User;
  hasUpdate: boolean;
  submitDate: string; // Must be a string in RFC 3339 date format
  dispatcherAlert: boolean;
  assignedOrganizationsId?: string[];
}

export interface PatientAddress {
  label: string;
  street: string;
  postcode: string;
  city: string;
}

/**
 * A request from a doctor to an expert
 */
export default interface OpinionRequest extends OpinionRequestSummary {
  treatments?: string[];
  secondaryDiseases?: string;
  attachments?: Attachment[];
  eegReason?: EegReason;
  imagingStudies?: ImagingStudy[];
  subject?: Subject;
  healthPathwayContacts?: HealthPathwayContact[];
  motive?: string;
  diseaseStage?: string;
  submitDate: string;
  closedDate: string;
  lastUpdateDate: string;
  participants?: Participant[];
  experts?: User[];
  expertReply?: ExpertReply;
  requestedExpertId?: string;
  requesterFacilityId: string;
  uploadDmpDate?: string;
  assignedOrganizations?: Organization[];
}

export enum OpinionRequestFormPage {
  emergencyAndConsent = 1,
  patientInformation,
  requestObject,
  patientDisease,
  patientCaseHistory,
  healthPathwayDoctorsTeam,
  healthPathwayParamedicalTeam,
  requestMotive,
  requestAddExpert,
}

export enum HealthPathwayContactType {
  DOCTOR = "DOCTOR",
  PARAMEDICAL = "PARAMEDICAL",
  NONE = "NONE",
}

export interface HealthPathwayContact {
  userId: string;
  professionalTitle: HealthProfessionalTitle;
  firstName?: string;
  lastName?: string;
  email?: string;
  profession?: string;
  specialty?: string;
  category: HealthPathwayContactType;
  rpps?: string;
  role: string;
}

export interface Patient {
  firstName?: string;
  lastName?: string;
  localIdentifier?: string;
  sex?: "MALE" | "FEMALE";
  birthdate: {
    day: string;
    month: string;
    year: string;
  };
  birthPlace?: string;
  socialSecurityNumber?: string;
  location: PatientLocation;
  address?: PatientAddress;
  facilityId?: string;
  insiStatus?: string;
}

export interface Participant {
  userId: string;
  permission: SharePermission;
  firstName?: string;
  lastName?: string;
  lastViewedDate?: string; //FIXME: should not be sent by front
  message?: string;
}

export enum SharePermission {
  VIEW = "VIEW",
  EDIT = "EDIT",
  ADMIN = "ADMIN",
}

export interface Attachment {
  Id: string;
  Filename: string;
  InstanceId?: string;
  Size?: number;
}

export interface ImagingStudy {
  Id: string;
  StudyInstanceId: string;
  StudyDescription: string;
  StudyDate: string;
  PatientName: string;
  PatientId: string;
  Size?: number;
}

export interface Expert {
  userId: string;
  status: ExpertStatus;
}

export interface OrganizationNode extends Organization {
  opinionRequests: OpinionRequestSummary[];
  children: OrganizationNode[];
}

export function canUserEditOpinionRequest(
  user: User,
  request: OpinionRequest
): boolean {
  if (
    (user.roles !== null && user.roles.includes(UserRoles.GLOBAL_DISPATCHER)) ||
    (user.roles !== null &&
      user.roles.includes(UserRoles.ORGANIZATION_DISPATCHER) &&
      request?.assignedOrganizations &&
      request.assignedOrganizations
        .map((o) => o.id)
        .some(
          (id) =>
            id &&
            user.organizations
              .filter((o) => o.isDispatcher)
              .map((o) => o.organizationId)
              .includes(id)
        ))
  ) {
    return true;
  } else if (request?.participants) {
    return request.participants?.some(
      (participant) =>
        (participant.permission === SharePermission.ADMIN ||
          participant.permission === SharePermission.EDIT) &&
        participant.userId === user.id
    );
  }
  return false;
}

export function canUserAddDocumentToOpinionRequest(
  user: User,
  request: OpinionRequest
): boolean {
  if (hasRole(user.roles, UserRoles.GLOBAL_DISPATCHER)) {
    return true;
  }
  if (request.assignedExperts) {
    const canAddDocument = request.assignedExperts?.some(
      (expert) =>
        expert.status === ExpertStatus.ACCEPTED && expert.userId === user.id
    );
    if (canAddDocument) {
      return true;
    }
  }
  if (request.participants) {
    return request.participants.some(
      (participant) =>
        (participant.permission === SharePermission.ADMIN ||
          participant.permission === SharePermission.EDIT) &&
        user.id === participant.userId
    );
  }
  return false;
}

export function getPermissionLabel(label: SharePermission): string {
  switch (label) {
    case SharePermission.EDIT:
      return "Edition";
    case SharePermission.ADMIN:
      return "Administration";
    default:
      return "Lecture";
  }
}

export function getRolesLabel(label: string[] | undefined | null): string {
  const roles: string[] = [];
  if (label !== undefined && label !== null) {
    label.forEach((role) => {
      switch (role) {
        case UserRoles.GLOBAL_DISPATCHER:
          roles.push("Régulateur");
          break;
        case UserRoles.ADMIN:
          roles.push("Administrateur");
          break;
      }
    });
  }
  return roles.join(", ");
}

export function splitOpinionRequestsDispatcher(
  opinionRequests: OpinionRequestSummary[]
): [OpinionRequestSummary[], OpinionRequestSummary[], OpinionRequestSummary[]] {
  const toAssignOpinionRequests: OpinionRequestSummary[] = opinionRequests.filter(
    (r) =>
      r.status === RequestStatus.SUBMITTED ||
      r.dispatcherAlert ||
      (r.status === RequestStatus.VALIDATED &&
        (!r.assignedExperts || r.assignedExperts.length === 0))
  );
  const inProgressOpinionRequests: OpinionRequestSummary[] = opinionRequests.filter(
    (r) =>
      r.status === RequestStatus.VALIDATED &&
      r.assignedExperts &&
      r.assignedExperts.length > 0 &&
      !r.dispatcherAlert
  );
  const closedOpinionRequests: OpinionRequestSummary[] = opinionRequests.filter(
    (r) => r.status === RequestStatus.CLOSED
  );

  return [
    toAssignOpinionRequests,
    inProgressOpinionRequests,
    closedOpinionRequests,
  ];
}

function createTreeFromOrganizationNodeMap(
  mapOfOrgs: Map<string, OrganizationNode>
) {
  const rootNodes: OrganizationNode[] = [];

  for (const oneOrganizationWithOpinionRequest of Array.from(
    mapOfOrgs.values()
  )) {
    if (!oneOrganizationWithOpinionRequest.parentOrganizationId) {
      rootNodes.push(oneOrganizationWithOpinionRequest);
    } else {
      const parentOrganization: OrganizationNode = <OrganizationNode>(
        mapOfOrgs.get(oneOrganizationWithOpinionRequest.parentOrganizationId)
      );
      parentOrganization.children.push(oneOrganizationWithOpinionRequest);
    }
  }
  return rootNodes;
}

async function addOrganizationsAndItsParentsToMap(
  orgIdToAdd: string,
  mapOfOrgs: Map<string, OrganizationNode>,
  oneOr: OpinionRequestSummary
) {
  let org: Organization = <Organization>await getOrganizationById(orgIdToAdd);
  if (org.id != null) {
    mapOfOrgs.set(org.id, {
      ...org,
      opinionRequests: [oneOr],
      children: [],
    });
  }
  while (org.parentOrganizationId && !mapOfOrgs.has(org.parentOrganizationId)) {
    org = <Organization>await getOrganizationById(org.parentOrganizationId);
    if (org.id != null) {
      mapOfOrgs.set(org.id, {
        ...org,
        opinionRequests: [],
        children: [],
      });
    }
  }
}

async function addOpinionRequestToOrganizationNodes(
  oneOr: OpinionRequestSummary,
  mapOfOrgs: Map<string, OrganizationNode>
) {
  oneOr.assignedOrganizationsId = oneOr.assignedOrganizationsId ?? [];

  if (oneOr.assignedOrganizationsId.length > 0) {
    for (const orgId of oneOr.assignedOrganizationsId) {
      if (mapOfOrgs.has(orgId)) {
        const a: OrganizationNode = <OrganizationNode>mapOfOrgs.get(orgId);
        a.opinionRequests.push(oneOr);
      } else {
        await addOrganizationsAndItsParentsToMap(orgId, mapOfOrgs, oneOr);
      }
    }
  } else {
    const notAssignedToAnyOrganisations: OrganizationNode = <OrganizationNode>(
      mapOfOrgs.get("-1")
    );
    notAssignedToAnyOrganisations.opinionRequests.push(oneOr);
  }
}

export async function createTreeFromOpinionRequestOrganizations(
  opinionRequests: OpinionRequestSummary[]
): Promise<OrganizationNode[]> {
  const mapOfOrgs = new Map<string, OrganizationNode>();
  mapOfOrgs.set("-1", {
    id: "-1",
    name: "Affecté à aucune organisation",
    dispatchers: [],
    admins: [],
    experts: [],
    opinionRequests: [],
    children: [],
  });
  for (const oneOr of opinionRequests) {
    //Create OrganizationNode and add opinion requests in them
    await addOpinionRequestToOrganizationNodes(oneOr, mapOfOrgs);
  }
  //Create the tree from the map of organizations (add children)
  return createTreeFromOrganizationNodeMap(mapOfOrgs);
}

export function splitOpinionRequestsRequesterExpertParticipant(
  opinionRequests: OpinionRequestSummary[]
): [OpinionRequestSummary[], OpinionRequestSummary[]] {
  const inProgressOpinionRequests = opinionRequests.filter(
    (r) => r.status !== RequestStatus.CLOSED
  );
  const closedOpinionRequests = opinionRequests.filter(
    (r) => r.status === RequestStatus.CLOSED
  );

  return [inProgressOpinionRequests, closedOpinionRequests];
}

export function getRequestStatusLabel(
  opinionRequest: OpinionRequestSummary | undefined
): string {
  if (opinionRequest !== null && opinionRequest !== undefined) {
    switch (opinionRequest.status) {
      case RequestStatus.SUBMITTED:
        return "Demande envoyée";
      case RequestStatus.VALIDATED:
        if (
          opinionRequest.assignedExperts &&
          opinionRequest.assignedExperts.length > 0
        ) {
          if (
            opinionRequest.assignedExperts.some(
              (expert) => expert.status === ExpertStatus.ACCEPTED
            )
          ) {
            return "En cours de traitement par un/des expert(s)";
          } else {
            return "En attente d'acceptation d'un expert";
          }
        } else {
          return "En attente d'affectation d'un expert";
        }
      case RequestStatus.CLOSED:
        return "Terminée";
      default:
        return "Brouillon";
    }
  }
  return "";
}

export function isEmergencyRequest(
  request: OpinionRequest | undefined
): boolean {
  const specialties =
    request?.subject?.adviceOnPathologyDetails?.specialties || [];
  return (
    !specialties.includes(SpecialtyEnum.SPECIALTY_MPR) &&
    (specialties.includes(SpecialtyEnum.SPECIALTY_REANIMATION) ||
      specialties.includes(SpecialtyEnum.SPECIALTY_EMERGENCY))
  );
}
