import React from "react";
import styles from "./SubjectForm.module.css";

interface Props {
  label: string;
  disabled?: boolean;
  onChange: () => void;
  checked?: boolean;
  labelClassName?: string;
  className?: string;
  hideCheck?: boolean;
}

export const CheckBox = (props: Props) => {
  return (
    <label className={`${styles.specialtyCheckbox} ${props.className || ""}`}>
      <input
        type="checkbox"
        id={props.label}
        disabled={props.disabled || false}
        onChange={props.onChange}
        checked={props.checked || false}
      />
      <span className={`${styles.specialtyText} ${props.labelClassName || ""}`}>
        {props.label}{" "}
        {props.hideCheck !== true && (
          <i className={"icon icon-check " + styles.specialtyCheck} />
        )}
      </span>
    </label>
  );
};
