import { errorToast } from "../utils/Toast";

const apiUrl = "https://api-adresse.data.gouv.fr/search/?q=";

export async function searchAddress(query: string): Promise<Address[] | Error> {
  const config = {
    method: "GET",
  };

  try {
    const response = await fetch(`${apiUrl}${query}`, config).catch((err) => {
      errorToast(
        "Web service inaccessible, vérifier votre connexion ou réessayer dans quelques minutes",
        { autoClose: 10000 }
      );
      return err;
    });
    if (response.ok) {
      const res = await response.json();
      const features = res.features;
      return features.map((f: AddressObject) => f.properties);
    }
    console.error("Error response during fetch", response);
    return new Error("Failed to fetch, see console logs");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function searchInseeCodeByCity(
  query: string
): Promise<BirthPlace[] | Error> {
  const config = {
    method: "GET",
  };

  try {
    const response = await fetch(
      `${apiUrl}${query}&type=municipality`,
      config
    ).catch((err) => {
      errorToast(
        "Web service inaccessible, vérifier votre connexion ou réessayer dans quelques minutes",
        { autoClose: 10000 }
      );
      return err;
    });
    if (response.ok) {
      const res = await response.json();
      const features = res.features;
      return features.map((f: BirthPlaceObject) => f.properties);
    }
    console.error("Error response during fetch", response);
    return new Error("Failed to fetch, see console logs");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export interface AddressObject {
  properties: Address;
}

export interface Address {
  label: string;
  name: string;
  postcode: string;
  city: string;
}

export interface BirthPlaceObject {
  properties: BirthPlace;
}

export interface BirthPlace {
  city: string;
  citycode: string;
  context: string;
}
