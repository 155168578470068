import React from "react";
import styles from "./UserGuides.module.css";
import ValidateRequest from "../../assets/user-guides/validate-request.png";
import SelectRole from "../../assets/user-guides/select-role-dispatcher.png";
import InBriefTab from "../../assets/user-guides/brief-tab-dispatcher.png";
import FilterRequests from "../../assets/user-guides/filter-requests.png";
import AddExpert1 from "../../assets/user-guides/add-expert-1.png";
import AddExpert2 from "../../assets/user-guides/add-expert-2.png";
import AddParticipant2 from "../../assets/user-guides/add-participant-2.png";
import Chat from "../../assets/user-guides/chat.png";
import ProfileDispatcher from "../../assets/user-guides/profile-requester.png";
import ProSanteConnectECps from "../../assets/user-guides/pro-sante-connect-e-cps.png";
import ProSanteConnectCps from "../../assets/user-guides/pro-sante-connect-cps.png";
import UpdateKeycloakInfo from "../../assets/user-guides/update-profile-info.png";
import SignUp from "../../assets/user-guides/sign-up-form.png";
import RppsForm from "../../assets/user-guides/rpps-form.png";
import ProSanteConnectPinCode from "../../assets/user-guides/pro-sante-connect-pin-code.png";
import EmailVerification from "../../assets/user-guides/email-verification.png";
import TwoFactorAuthRequest from "../../assets/user-guides/2fa-request.png";
import TwoFactorAuthValidate from "../../assets/user-guides/2fa-validate.png";
import VerifPicture from "../../assets/user-guides/verif-picture.png";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const DispatcherGuide: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle
        title={"Guide du régulateur"}
        backButtonRedirectsTo={`/help`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div className={`text-bold ${styles.subTitle}`}>
            Rôle du régulateur
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-70">
                Le régulateur a accès au contenu des demandes d&apos;avis. Son
                rôle est de valider les demandes d&apos;avis et d&apos;assigner
                l&apos;expert le plus adapté (en fonction de ses compétences et
                de ses disponibilités) pour répondre à la demande du médecin
                requérant.
                <br />
                Pour accéder aux fonctionnalités qui lui sont disponibles, et
                notamment accéder aux demandes d&apos;avis, le régulateur doit
                sélectionner l&apos;onglet &quot;Régulateur&quot;.
              </div>
              <div className="w-30">
                <img
                  alt="validate-request"
                  src={SelectRole}
                  className={styles.selectRoleImage}
                />
              </div>
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Création de compte
          </div>
          <div className="justify">
            Tous les professionnels de santé référencés par un numéro RPPS
            peuvent créer un compte sur le site. La validité du numéro RPPS
            indiqué lors de l&apos;inscription est contrôlée par comparaison
            avec la base de données RPPS disponible{" "}
            <a
              className={styles.link}
              href=" https://mos.esante.gouv.fr/NOS/TRE_R11-CiviliteExercice/"
            >
              ici{" "}
            </a>
            et mise à jour régulièrement.
            <br />
            <br />
            Depuis la page d&apos;inscription, l&apos;utilisateur peut créer son
            compte de deux manières différentes :
            <div className="text-bold mt-2 mb-2 ml-4">
              1. Depuis Pro Santé Connect
            </div>
            L&apos;utilisateur peut créer son compte en utilisant les outils
            d&apos;authentification de l&apos;ANS (CPS ou eCPS), permettant de
            pré-remplir certains champs du processus d&apos;inscription.
            <br />
            Pour cela, l&apos;utilisateur doit cliquer sur le bouton Pro Santé
            Connect depuis la page d&apos;accueil et renseigner le numéro de sa
            carte CPS (1) ou bien connecter sa carte CPS à l&apos;aide d&apos;un
            lecteur de carte (2).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectECps}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectCps}
                className="w-30 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (1) </span>
              <span className="mr-auto ml-auto"> (2) </span>
            </div>
            Ensuite, l&apos;utilisateur doit saisir le code pin de sa carte CPS
            pour valider son identification (3) et mettre à jour ses
            informations de profil (4).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectPinCode}
                className="w-40 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={UpdateKeycloakInfo}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (3) </span>
              <span className="mr-auto ml-auto"> (4) </span>
            </div>
            <div className="text-bold mt-2 mb-2 ml-4">
              2. Depuis l&apos;application
            </div>
            Pour créer son compte depuis l&apos;application, l&apos;utilisateur
            doit renseigner son adresse e-mail ainsi qu&apos;un mot de passe
            pour créer son compte (5). Il devra confirmer la création de son
            compte en cliquant sur le lien présent dans l&apos;email reçu (6).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={SignUp}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={EmailVerification}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2 mb-2">
              <span className="mr-auto ml-auto"> (5) </span>
              <span className="mr-auto ml-auto"> (6) </span>
            </div>
            <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
              Finalisation de l&apos;inscription
            </div>
            <div className="justify">
              <div className="row mb-2">
                <div className="w-60">
                  Une fois son compte créé, l&apos;utilisateur devra renseigner
                  son numéro RPPS. Dans le cas où il a créé son compte avec Pro
                  Santé Connect, le numéro RPPS sera pré-rempli. Dans le cas
                  contraire, la complétion du numéro RPPS permettra
                  d&apos;effectuer une recherche dans le référentiel RPPS et de
                  remplir certains champs.
                </div>
                <div className="w-40">
                  <img
                    alt="rpps-form"
                    src={RppsForm}
                    className={styles.selectRoleImage}
                  />
                </div>
              </div>
              Ensuite, l&apos;utilisateur doit remplir le formulaire
              d&apos;inscription, puis suivre la procédure de double
              authentification par SMS (7) et (8).
              <div className="row space-between-content margin-top-2">
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthRequest}
                  className="w-40 m-auto"
                />
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthValidate}
                  className="w-40 m-auto"
                />
              </div>
              <div className="row mt-2 mb-2">
                <span className="mr-auto ml-auto"> (7) </span>
                <span className="mr-auto ml-auto"> (8) </span>
              </div>
              Une fois la double authentification effectuée, l&apos;utilisateur
              doit se prendre en photo avec sa carte CPS afin de prouver son
              identité.
              <div className="row centered-content margin-top-2 margin-bottom-2">
                <img alt="select-role" src={VerifPicture} className="w-40" />
              </div>
              La validation du compte par un administrateur intervient dans les
              12 heures suivant la soumission (du lundi au vendredi de 9h à
              19h).
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Modification du profil
          </div>
          <div className="justify">
            Depuis le menu en haut à droite de l&apos;écran, le régulateur peut
            accéder à son profil et modifier ses informations personnelles,
            professionnelles, ses lieux d&apos;exercice et ses préférences de
            notifications.
            <div className="row centered-content margin-top-2">
              <img alt="select-role" src={ProfileDispatcher} className="w-30" />
            </div>
          </div>
          <div className={`mb-2 text-bold ${styles.subTitle}`}>
            Tableau de bord du régulateur
          </div>
          <div className="justify">
            Voici la page d&apos;accueil du régulateur lorsqu&apos;il se
            connecte à l&apos;application. L&apos;onglet &quot;En bref&quot;
            résume au régulateur le nombre de demandes qu&apos;il doit traiter.
            L&apos;onglet &quot;Demandes à assigner&quot; lui présente les
            demandes qu&apos;il doit valider et pour lesquelles il doit assigner
            un expert. L&apos;onglet &quot;Demandes en cours&quot; regroupe les
            demandes d&apos;avis en cours de traitement par des experts. Enfin,
            l&apos;onglet &quot;Demandes terminées&quot; regroupe les demandes
            clôturées, ayant reçu une réponse.
            <div className="row centered-content margin-top-2">
              <img alt="select-role" src={InBriefTab} className="w-80" />
            </div>
            <div className="row">
              <div className="w-70">
                Dans l&apos;onglet &quot;Demandes à assigner&quot;, le
                régulateur peut chosir de filtrer la liste des demandes
                d&apos;avis selon plusieurs choix : toutes les demandes, les
                demandes urgentes ou encore les demandes en alerte.
              </div>
              <div className="w-20">
                <img
                  alt="filter-request"
                  src={FilterRequests}
                  className={styles.filterRequestsImage}
                />
              </div>
            </div>
          </div>
          <div className={`mb-2 text-bold ${styles.subTitle}`}>
            Validation d&apos;une demande d&apos;avis
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-60">
                Quand une nouvelle demande d&apos;avis est soumise, le
                régulateur doit la relire avant de la valider. Pour cela, il
                doit cliquer sur la demande correspondante et consulter les 3
                onglets : &quot;Pathologie principale&quot;, &quot;Demande&quot;
                et &quot;Intervenants&quot; afin de vérfier que la demande est
                complète.
              </div>
              <div className="w-40">
                <img
                  alt="validate-request"
                  src={ValidateRequest}
                  className={styles.selectRoleImage}
                />
              </div>
            </div>
            Pour valider la demande d&apos;avis, le régulateur peut cliquer sur
            la bouton &quot;Valider la demande&quot; en bas de la page de
            détail.
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Ajout d&apos;intervenants
          </div>
          <div className="justify">
            Le régulateur peut ajouter des intervenants à la demande d&apos;avis
            depuis l&apos;onglet &quot;Intervenants&quot; du détail de la
            demande d&apos;avis :
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={AddParticipant2} className="w-90" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Affectation de la demande à un (des) expert(s)
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-60">
                Une fois la demande d&apos;avis validée par le régulateur,
                celui-ci doit y affecter un expert. Si le requérant a proposé un
                expert et que le régulateur a validé la demande, alors
                l&apos;expert est affecté à la demande. Dans le cas contraire,
                c&apos;est au régulateur d&apos;affecter l&apos;expert. Pour
                cela, il doit se rendre dans la page de détail de la demande
                d&apos;avis concernée et cliquer sur le bouton &quot;Affecter la
                demande&quot; dans le bas de la page. Ensuite, une fenêtre va
                s&apos;ouvrir, dans laquelle il va pouvoir rechercher un expert
                par son nom, sa spécialité ou encore sa sous-spécialité.
              </div>
              <div className="w-40">
                <div className="row centered-content margin-top-2 margin-bottom-2">
                  <img
                    alt="select-role"
                    src={AddExpert1}
                    className={styles.selectRoleImage}
                  />
                </div>
              </div>
            </div>
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={AddExpert2} className="w-40 m-auto" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Messagerie
          </div>
          <div className="justify">
            Le régulateur peut communiquer avec le requérant, les intervenants
            de la demande d&apos;avis ou encore le(s) expert(s) affecté(s) via
            la discussion. Cette discussion est disponible depuis le pied de
            page du détail de la demande d&apos;avis.
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={Chat} className="w-40 m-auto" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
