import React from "react";
import styles from "./ContactPage.module.css";
import { getWebsiteTitle } from "../utils/Brands";
import LocationIcon from "../assets/location-icon.svg";
import config from "../utils/config";
import { PageHeaderWithTitle } from "./Shared/PageHeaderWithTitle";

export const ContactPage: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle title={"Contact"} backButtonRedirectsTo={`/`} />
      <div className="page-container">
        <div className="col-8 col-sm-auto col-mx-auto text-centered">
          <div className={`mt-4 text-bold ${styles.textContact}`}>
            Pour toute question liée au fonctionnement de{" "}
            <span className="text-bold text-primary-blue">
              {" "}
              {getWebsiteTitle(config.brand)}
            </span>
            , n&apos;hésitez pas à nous contacter.
          </div>
          <div
            className={`row mt-7 space-between-content ${styles.gridContainer}`}
          >
            <div className="row">
              <img
                className={styles.locationIcon}
                alt="icone-localisation"
                src={LocationIcon}
              />
              <div className="text-left">
                <span className="text-bold"> AiiNTENSE </span> <br />
                Pépinière Genopole Entreprises <br />
                Genopole Campus 3 <br />
                4, rue Pierre Fontaine <br />
                91058 EVRY CEDEX – France
              </div>
            </div>
            <div className="row">
              <i
                className={`fas fa-envelope ${styles.icon} ${styles.envelopeIcon}`}
              />
              <div className="text-left">
                <span className="text-bold">E-mail :</span>{" "}
                <a
                  className="text-primary-blue"
                  href="mailto:support@aiintense.eu"
                >
                  support@aiintense.eu{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="mt-7">
            <span className=" text-bold text-primary-blue">
              {getWebsiteTitle(config.brand)}
            </span>{" "}
            version {config.version}
          </div>
        </div>
      </div>
    </>
  );
};
