import React from "react";
import styles from "./UploadBar.module.css";

interface Props {
  actual: number;
  total: number;
}

export const UploadBar: React.FC<any> = (props: Props) => {
  const percent = (props.actual * 100) / props.total;

  return (
    <div
      role="progressbar"
      aria-valuenow={props.actual}
      aria-valuemin={0}
      aria-valuemax={props.total}
      className={styles.progressBarContainer}
    >
      <div className={styles.percentLabel}>
        {props.actual} / {props.total} <i className="fas fa-spinner fa-spin" />
      </div>
      <div
        className={`bg-primary-blue ${styles.progressBarFiller}`}
        style={{ width: percent + "%" }}
      ></div>
    </div>
  );
};
