import { SearchFacilities } from "../SearchFacilities";
import { Facility } from "../../domain/Facility";
import styles from "./AdminViewUpdateOrganization.module.css";
import { FacilityCard } from "../Profile/Facility/FacilityCard";
import AsyncSelect from "react-select/async";
import { Organization, OrganizationMember } from "../../domain/Organization";
import { selectCustomStyle } from "../../utils/selectCustomStyle";
import { OrganizationCard } from "./OrganizationCard";
import { SearchAndAddMembersCard } from "./SearchAndAddMembersCard";
import { HealthProfessionalUser } from "../../domain/User";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getAllOrganizations,
  getOrganizationById,
  searchOrganizationByName,
} from "../../services/OrganizationService";
import { getFacility } from "../../services/FacilityService";
import { errorToast, successToast } from "../../utils/Toast";
import history from "../../utils/history";
import { AppContext } from "../../utils/state";
import { useForm } from "react-hook-form";
import CameraDisabledIcon from "../../assets/camera-disabled-icon.svg";

interface Props {
  id?: string;
  saveString?: string;
  saveShortString: string;
  onEditOrganization: (orgEdited: Organization) => void;
  onCancel: () => void;
  detail?: boolean;
}

export const EditOrganization: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Organization>();

  const [organization, setOrganization] = useState<Organization>();
  const [name, setName] = useState<string>("");
  const [parentOrganization, setParentOrganization] = useState<Organization>();
  const [dispatchers, setDispatchers] = useState<OrganizationMember[]>([]);
  const [admins, setAdmins] = useState<OrganizationMember[]>([]);
  const [experts, setExperts] = useState<OrganizationMember[]>([]);
  const [details, setDetail] = useState<string>("");
  const [logo, setLogo] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDetails, setIsDetail] = useState<boolean>(false);
  const [allOrganizationChilrenIds, setAllOrganizationChildrenIds] = useState<
    string[]
  >([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    Organization[]
  >([]);
  const [facility, setFacility] = useState<Facility>();

  const removeFacility: any = () => {
    setFacility(undefined);
  };

  const onSubmit = handleSubmit(() => {
    props.onEditOrganization(getOrganizationToUpdate());
  });

  const getOrganizationToUpdate = (): Organization => {
    return {
      id: props.id,
      name: name,
      details: details,
      logo: logo,
      facilityId: facility?.id,
      parentOrganizationId: parentOrganization?.id || "",
      dispatchers: dispatchers,
      admins: admins,
      experts: experts,
    };
  };

  function goBack(): void {
    if (appContext.state.user.email !== "") {
      history.push(`/admin/organizations`);
    } else {
      history.push(`/home`);
    }
  }

  const showErrorRetrievingOrganization = (): any => {
    errorToast("Erreur durant la récupération de l'organisation");
    goBack();
  };

  useEffect(() => {
    if (props.id) {
      getOrganization();
      getOrganizations();
    }
    if (props.detail) {
      setIsDetail(true);
    }
    document.getElementById("organization-name")?.focus();
  }, [props.id]);

  const getOrganization = async (): Promise<any> => {
    if (props.id) {
      await getOrganizationById(props.id).then((res: Organization | Error) => {
        if (res instanceof Error) {
          showErrorRetrievingOrganization();
        } else {
          setOrganization(res);
          setDispatchers(res.dispatchers);
          setAdmins(res.admins ?? []);
          setExperts(res.experts);
          setName(res.name);
          setDetail(res.details ?? "");
          setLogo(res.logo ?? "");
          res.facilityId && getFacilityById(res.facilityId);
          res.parentOrganizationId &&
            getParentOrganization(res.parentOrganizationId);
        }
      });
    }
  };

  const getOrganizations = async (): Promise<any> => {
    await getAllOrganizations().then((res: Organization[] | Error) => {
      if (res instanceof Error) {
        showErrorRetrievingOrganization();
      } else {
        if (props.id) {
          setAllOrganizationChildrenIds(
            getAllOrganizationChildrenIds(props.id, res)
          );
        }
      }
    });
  };

  const getParentOrganization = async (parentId: string): Promise<any> => {
    await getOrganizationById(parentId).then((res: Organization | Error) => {
      if (res instanceof Error) {
        showErrorRetrievingOrganization();
      } else {
        setParentOrganization(res);
      }
    });
  };

  const getOrganizationChildrenIds = (
    organizationId: string[],
    accumulator: string[],
    allOrgs: Organization[]
  ): string[] => {
    organizationId.forEach((orgId) => {
      const directChildren =
        allOrgs
          ?.filter((org) => org.parentOrganizationId === orgId)
          .map((org) => org.id ?? "") ?? [];
      accumulator.push(...directChildren);
      getOrganizationChildrenIds(directChildren, accumulator, allOrgs);
    });
    return accumulator;
  };

  const getAllOrganizationChildrenIds = (
    organisationId: string,
    allOrgs: Organization[]
  ) => {
    let organizationChildren: string[] = [];
    if (organisationId) {
      organizationChildren = getOrganizationChildrenIds(
        [organisationId],
        organizationChildren,
        allOrgs
      );
    }
    return organizationChildren;
  };

  const getFacilityById = async (facilityId: string): Promise<void> => {
    if (facilityId) {
      const res = await getFacility(facilityId);
      if (res instanceof Error || res == null) {
        showErrorRetrievingOrganization();
      } else {
        setFacility(res);
      }
    }
  };

  const removeSelectedMember = (
    userId: string,
    selectedMemberUser: OrganizationMember[]
  ): OrganizationMember[] => {
    const indexUserToRemove = selectedMemberUser.findIndex(
      (hpU: OrganizationMember) => hpU.userId === userId
    );
    if (indexUserToRemove !== -1) {
      selectedMemberUser.splice(indexUserToRemove, 1);
      return [...selectedMemberUser];
    }
    return [...selectedMemberUser];
  };

  const search = async (
    query: string,
    callback: any
  ): Promise<Organization[]> => {
    const res = await searchOrganizationByName(query || "");
    if (res instanceof Error || res === null) {
      setFilteredOrganizations([]);
    } else {
      const filtered = res.filter(
        (o) =>
          o.id &&
          o.id !== organization?.id &&
          allOrganizationChilrenIds.indexOf(o.id) === -1
      );
      setFilteredOrganizations(filtered);
      callback(filtered);
      return filtered;
    }
    return [];
  };

  const fileButtonHandler = (): any => {
    fileInputRef?.current?.click();
  };

  const convertToBase64 = (file: Blob): Promise<any> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function onChangeFileHandler(event: any): Promise<void> {
    const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    if (typeof base64 === "string") {
      setLogo(base64);
    }
    successToast("Fichier sauvegardé");
  }

  const formatOptionLabel: any = (org: Organization) => {
    return <div className="text-bold">{org.name}</div>;
  };

  const transformOrganizationMemberToHealthProfessionnalUser = (
    members: OrganizationMember[]
  ): HealthProfessionalUser[] => {
    return members.map((member) => {
      return {
        userId: member.userId,
        professionalTitle: member.title,
        firstName: member.firstName,
        lastName: member.lastName,
      };
    });
  };

  const transformHealthProfessionnalUserToOrganizationMember = (
    hpUser: HealthProfessionalUser
  ): OrganizationMember => {
    return {
      userId: hpUser.userId,
      title: hpUser?.professionalTitle,
      firstName: hpUser?.firstName,
      lastName: hpUser?.lastName,
    };
  };

  return (
    <>
      <div className="form-group">
        <label className="form-label pt-0" htmlFor="organization-name">
          Nom<span className="error-msg text-large ml-1 mt-1">*</span>
        </label>
        <input
          {...register("name", {
            required: "Ce champ est obligatoire",
          })}
          className="form-input"
          type="text"
          defaultValue={organization?.name}
          id="organization-name"
          placeholder="Nom de l'organisation"
          onChange={(event) => setName(event.target.value)}
        />
        {errors?.name && <div className="error-msg">{errors.name.message}</div>}
      </div>
      {isDetails && (
        <>
          <div className="form-group">
            <label className="form-label pt-0" htmlFor="organization-name">
              Logo
            </label>
            <div className="logo-size mr-4">
              {organization && organization.logo != "" ? (
                <img
                  className="h-100 w-100"
                  src={logo != "" ? logo : organization.logo}
                  alt="logo organisation"
                />
              ) : (
                <img
                  className="h100"
                  src={CameraDisabledIcon}
                  alt="Logo par défaut"
                />
              )}
            </div>
            <div className="cr-upload mt-2">
              <button
                className="btn btn-sm neuro-icons-btn mb-2"
                onClick={fileButtonHandler}
              >
                <i className="icon icon-plus mr-2" />
                Parcourir...
              </button>
              <input
                id={props.id}
                ref={fileInputRef}
                type="file"
                accept={"*/jpg, .jpg, */jpeg, .jpeg, */png, .png"}
                onChange={onChangeFileHandler}
                hidden={false}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label pt-0" htmlFor="organization-name">
              Détail
            </label>
            <textarea
              {...register("details")}
              rows={5}
              className="form-input"
              defaultValue={organization?.details}
              id="organization-details"
              placeholder="Détail de l'organisation"
              onChange={(event) => setDetail(event.target.value)}
            />
          </div>
        </>
      )}
      <label className="form-label mb-2">Localisation (centre de soins)</label>
      <div className="form-group">
        <SearchFacilities
          onSelect={(facilityCenter: Facility) => {
            setFacility(facilityCenter);
          }}
          autoFocus={false}
        />
        {facility && (
          <div className={`w-50 mt-2 ${styles.organizationCardContainer}`}>
            <FacilityCard
              isAddService={false}
              facility={facility}
              handleRemove={removeFacility}
              showRemoveButton={true}
            />
          </div>
        )}
      </div>
      <label className="form-label mb-2">Organisation parente</label>
      <AsyncSelect<Organization>
        id="search-name-organization"
        loadOptions={search}
        defaultOptions={filteredOrganizations}
        formatOptionLabel={formatOptionLabel}
        value={null}
        onChange={(option: any): void => {
          option && setParentOrganization(option);
        }}
        loadingMessage={(): any => "Recherche en cours..."}
        noOptionsMessage={(): any => "Aucun résultat"}
        placeholder="Saisissez le nom ici"
        styles={selectCustomStyle}
      />
      {parentOrganization && (
        <div className={`w-50 mt-2 ${styles.organizationCardContainer}`}>
          <OrganizationCard
            organization={parentOrganization}
            readOnly={true}
            showCardLegend={false}
            showRemoveButton={true}
            handleRemove={(): void => setParentOrganization(undefined)}
          />
        </div>
      )}
      <SearchAndAddMembersCard
        roleLabel={"Administrateurs d'organisations"}
        onAddMember={(hp: HealthProfessionalUser) => {
          setAdmins((prevAdmins) => [
            ...prevAdmins,
            transformHealthProfessionnalUserToOrganizationMember(hp),
          ]);
        }}
        members={transformOrganizationMemberToHealthProfessionnalUser(admins)}
        onRemoveMember={(member) =>
          setAdmins((prevAdmin) =>
            removeSelectedMember(member.userId, prevAdmin)
          )
        }
      />
      {!isDetails && (
        <SearchAndAddMembersCard
          roleLabel={"Regulateurs d'organisations"}
          onAddMember={(hp: HealthProfessionalUser) => {
            setDispatchers((prevDispatchers) => [
              ...prevDispatchers,
              transformHealthProfessionnalUserToOrganizationMember(hp),
            ]);
          }}
          members={transformOrganizationMemberToHealthProfessionnalUser(
            dispatchers
          )}
          onRemoveMember={(member) =>
            setDispatchers((prevDispatcher) =>
              removeSelectedMember(member.userId, prevDispatcher)
            )
          }
        />
      )}
      <SearchAndAddMembersCard
        roleLabel={"Experts d'organisations"}
        onAddMember={(hp: HealthProfessionalUser) => {
          setExperts((prevExperts) => [
            ...prevExperts,
            transformHealthProfessionnalUserToOrganizationMember(hp),
          ]);
        }}
        members={transformOrganizationMemberToHealthProfessionnalUser(experts)}
        onRemoveMember={(member) =>
          setExperts((prevExpert) =>
            removeSelectedMember(member.userId, prevExpert)
          )
        }
      />
      <div className="text-right mr-4 mb-2">
        <span className="error-msg text-large">*</span>
        <span className="error-msg text-medium ml-1">Champs obligatoires</span>
      </div>
      <div className="row centered-content margin-top-2">
        <button
          className="btn rectangular-btn close-btn mr-2"
          onClick={(): void => {
            props.onCancel();
          }}
        >
          Annuler
        </button>
        <button
          className="btn rectangular-btn neuro-btn ml-2"
          onClick={() => {
            onSubmit();
          }}
        >
          <span className={styles.displayNoneLong}>
            {" "}
            {props.saveShortString}{" "}
          </span>
          <span className={styles.displayNoneMobile}>
            {props.saveString ?? props.saveShortString}
          </span>
        </button>
      </div>
    </>
  );
};
