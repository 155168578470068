import RequestPrimaryType from "../../domain/Subject/RequestPrimaryType.enum";
import { PatientLocation, PatientLocationForm } from "./PatientLocationForm";
import React, { useContext, useEffect, useState } from "react";
import "./FormOpinionRequest.css";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { useParams } from "react-router-dom";
import OpinionRequest, {
  canUserEditOpinionRequest,
  isEmergencyRequest,
  OpinionRequestFormPage,
  Patient,
  SharePermission,
} from "../../domain/OpinionRequest";
import { Consent, PatientConsent } from "./PatientConsent";
import { EmergencyCheck } from "./EmergencyCheck";
import { EmergencyLevel } from "../../domain/EmergencyLevel";
import { Controller, useForm } from "react-hook-form";
import {
  deleteOpinionRequest,
  getOpinionRequest,
  getOpinionRequestMissingFields,
  saveOpinionRequest,
  setOpinionRequestExpertsAndOrganizations,
  submitOpinionRequest,
} from "../../services/OpinionRequestService";
import { errorToast, successToast } from "../../utils/Toast";
import { DiseasesForm } from "./DiseasesForm";
import { ReferralDoctorForm } from "./ReferralDoctorForm";
import { TreatmentsForm } from "./TreatmentsForm";
import { SubjectForm } from "./Subject/SubjectForm";
import { RequestMotive } from "./RequestMotive";
import { DmpAttachments } from "./DmpAttachments";
import { SavingStatus } from "../../utils/SavingStatus";
import { UploadDischargeSummary } from "./UploadDischargeSummary";
import { PatientDetailsForm } from "./PatientDetailsForm";
import { FormOpinionRequestBurgerMenu } from "./FormOpinionRequestBurgerMenu";
import { AddParticipants } from "./Participants/AddParticipants";
import { OpinionChat } from "../Chat/OpinionChat";
import history from "../../utils/history";
import { AppContext } from "../../utils/state";
import { RequestExpertForm } from "./RequestExpertForm";
import { OpinionRequestFooter } from "./OpinionRequestFooter";
import { UserRoles } from "../../domain/UserRoles";
import { ParamedicalHealthPathwayForm } from "./ParamedicalHealthPathwayForm";
import { SecondaryDiseaseForm } from "./SecondaryDiseaseForm";
import { InsFinder } from "./InsFinder";
import { InsiStatus } from "../../domain/InsiStatus";
import { IdentityVerification } from "./IdentityVerification";
import config from "../../utils/config";
import { InsCheck } from "./InsCheck";
import { trackCustomPage, trackEvent } from "../../utils/plausible";
import {
  buildMandatoryFieldsMissing,
  OpinionRequestMandatoryFieldsStatus,
} from "../../utils/OpinionRequestMandatoryFieldsStatus";
import { ErrorResponse } from "../../domain/ErrorManagement";
import { ModalMissingMandatoryFields } from "./ModalMissingMandatoryFields";
import { Spinner } from "../Shared/Spinner";
import { AttachmentSection } from "./AttachmentSection";
import {
  DisplayType,
  ImagingDocumentUpload,
} from "../Request/RequestDetails/ImagingDocumentUpload/ImagingDocumentUpload";
import { useQueryParam } from "../../utils/useQueryParam.hook";
import { Organization } from "../../domain/Organization";
import { EEGForm } from "./Subject/EEGForm";

interface ParamTypes {
  id: string;
}

function getDefaultValues(): OpinionRequest {
  return {
    closedDate: "",
    consent: Consent.NONE,
    dispatcherAlert: false,
    emergencyLevel: EmergencyLevel.NONE,
    hasUpdate: false,
    lastUpdateDate: "",
    patient: {
      birthdate: { day: "", month: "", year: "" },
      facilityId: "",
      location: PatientLocation.NONE,
    },
    requesterFacilityId: "",
    submitDate: "",
    treatments: [],
  };
}

const optionalPages = [
  OpinionRequestFormPage.patientDisease,
  OpinionRequestFormPage.healthPathwayDoctorsTeam,
  OpinionRequestFormPage.healthPathwayParamedicalTeam,
];

export const FormOpinionRequest: React.FC<any> = () => {
  const currentUser = useContext(AppContext).state.user;
  const [currentRequest, setCurrentRequest] = useState<OpinionRequest>();
  const [savingStatus, setSavingStatus] = useState<SavingStatus>(
    SavingStatus.IDLE
  );
  const [isAddingParticipant, setIsAddingParticipant] = useState<boolean>(
    false
  );
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [isChatting, setIsChatting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showMissingFieldsModal, setShowMissingFieldsModal] = useState<boolean>(
    false
  );
  const [missingFields, setMissingFields] = useState<
    OpinionRequestMandatoryFieldsStatus
  >({
    patientConsent: false,
    emergencyLevel: false,
    patientBirthday: false,
    primaryDiseases: false,
    motive: false,
    healthPathwayContacts: false,
  });
  const [completePercentage, setCompletePercentage] = useState<number>(0);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [hasPermissionEdit, sethasPermissionEdit] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>();
  const [isORUpdating, setIsORUpdating] = useState<boolean>(false);

  const [isVerifiedIdentity, setIsVerifiedIdentity] = useState<boolean>(false);
  const [isVerifiedIns, setIsVerifiedIns] = useState<boolean>(false);

  const [isEEGOnly, setIsEEGOnly] = useState<boolean>(false);

  const hasExpertSelectionRight = (): boolean => {
    return (
      currentRequest?.subject?.requestType !==
      RequestPrimaryType.PATIENT_FILE_CREATION
    );
  };

  const getTitle = (pageNumber: number): string => {
    switch (pageNumber) {
      case OpinionRequestFormPage.emergencyAndConsent:
        return "Degré d'urgence et consentement";
      case OpinionRequestFormPage.patientInformation:
        return "Identification du patient";
      case OpinionRequestFormPage.healthPathwayDoctorsTeam:
        return "Médecin(s) du parcours de santé";
      case OpinionRequestFormPage.healthPathwayParamedicalTeam:
        return "Equipe paramédicale du parcours de santé";
      case OpinionRequestFormPage.patientDisease:
      case OpinionRequestFormPage.patientCaseHistory:
        return "Pathologie principale";
      case OpinionRequestFormPage.requestObject:
        return "Orientation de votre demande";
      case OpinionRequestFormPage.requestMotive:
        return "Quelle est votre demande ?";
      case OpinionRequestFormPage.requestAddExpert:
        if (!hasExpertSelectionRight()) {
          return "Soumission de la demande";
        } else {
          return "Nommer un expert";
        }
      default:
        return "";
    }
  };
  const { id } = useParams<ParamTypes>();
  const searchParam = useQueryParam();
  const page = searchParam.get("page");
  const [index, setIndex] = useState<number>(
    page ? Number(page) : OpinionRequestFormPage.emergencyAndConsent
  );
  const [title, setTitle] = useState<string>(getTitle(index));
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    id ? LoadingStatus.LOADING : LoadingStatus.SUCCESS
  );

  const numberOfPages = Object.keys(OpinionRequestFormPage).length / 2;

  const defaultValues = getDefaultValues();
  const { register, control, reset, setValue, getValues } = useForm<any>({
    shouldUnregister: false,
    shouldUseNativeValidation: true,
    defaultValues: defaultValues,
  });

  const fetchOpinionRequest = async (): Promise<any> => {
    await getOpinionRequest(id).then((res) => {
      if (res instanceof Error) {
        errorToast("Impossible de récupérer la demande");
        setLoadingStatus(LoadingStatus.ERROR);
      } else {
        reset(res);
        setCurrentRequest(res);
        setRequestId(res.id);
        if (canUserEditOpinionRequest(currentUser, res)) {
          setReadOnly(false);
        }
        setLoadingStatus(LoadingStatus.SUCCESS);
      }
    });
  };

  const submit = async (requesterFacilityId: string): Promise<void> => {
    if (!currentRequest) {
      return;
    }
    const requestToSubmit = { ...currentRequest, ...getValues() };
    requestToSubmit.requesterFacilityId = requesterFacilityId;
    const submittedRequest = await submitOpinionRequest(requestToSubmit);
    setIsSubmitting(false);
    if (submittedRequest instanceof ErrorResponse) {
      if (
        submittedRequest.code === "MISSING_MANDATORY_FIELDS" &&
        currentRequest.id
      ) {
        const missingFieldsList = await getOpinionRequestMissingFields(
          currentRequest.id
        );
        if (missingFieldsList instanceof Error) {
          errorToast(
            "Erreur lors de la récupération des champs obligatoires manquants"
          );
        } else {
          if (missingFieldsList.length > 0) {
            setMissingFields(buildMandatoryFieldsMissing(missingFieldsList));
            setShowMissingFieldsModal(true);
          }
        }
        setSavingStatus(SavingStatus.IDLE);
      }
    } else if (submittedRequest instanceof Error) {
      setSavingStatus(SavingStatus.ERROR);
      errorToast("Erreur lors de la soumission de la demande");
    } else {
      setSavingStatus(SavingStatus.SUCCESS);
      successToast("Demande soumise");
      setCurrentRequest(submittedRequest);
      trackEvent("Submit request");
      history.push(`/requests/${submittedRequest.id}/view`);
    }
  };

  const getInsiStatusValues = (): void => {
    if (currentRequest && currentRequest.patient.insiStatus) {
      const insiStatusValue: string = currentRequest.patient.insiStatus;
      if (insiStatusValue === InsiStatus.QUALIFIED_IDENTITY) {
        setIsVerifiedIdentity(true);
        setIsVerifiedIns(true);
      } else if (insiStatusValue === InsiStatus.FETCHED_IDENTITY) {
        setIsVerifiedIdentity(false);
        setIsVerifiedIns(true);
      } else if (insiStatusValue === InsiStatus.VALIDATED_IDENTITY) {
        setIsVerifiedIdentity(true);
        setIsVerifiedIns(false);
      }
    } else {
      setIsVerifiedIdentity(false);
      setIsVerifiedIns(false);
    }
  };

  /**
   * TODO currently completing request state with getValues,
   * but should do the opposite as getValue (ReactHookForm state) should be the golden source
   */
  const updateOpinionRequest = async (): Promise<OpinionRequest> => {
    setIsORUpdating(true);

    const updatedRequest = { ...currentRequest, ...getValues() };

    if (
      updatedRequest?.subject?.requestType ===
      RequestPrimaryType.EEG_INTERPRETATION_ONLY
    ) {
      updatedRequest.primaryDisease = "N/A";
    }

    if (
      updatedRequest?.subject?.requestType ===
      RequestPrimaryType.EEG_INTERPRETATION_ONLY
    ) {
      setIsEEGOnly(true);
    } else {
      setIsEEGOnly(false);
    }

    const res = await saveOpinionRequest(updatedRequest);
    if (!(res instanceof Error) && res.id) {
      const resGetOR = await getOpinionRequest(res.id);
      if (!(resGetOR instanceof Error)) {
        setCurrentRequest(resGetOR);
        reset(resGetOR);

        if (index === 1) {
          history.push(`/requests/${res.id}`);
        }
        setIsORUpdating(false);
        return Promise.resolve(resGetOR);
      }
    }
    errorToast("Erreur lors de la sauvegarde de votre demande");
    setIsORUpdating(false);
    return Promise.reject();
  };

  const birthdayYearInvalid = (): boolean => {
    const today = new Date();
    const birthdate = getValues().patient.birthdate;
    return (
      Number(birthdate.year) < today.getFullYear() - 120 ||
      Number(birthdate.year) > today.getFullYear()
    );
  };

  const formIsInvalid = (): boolean => {
    return (
      getValues("emergencyLevel") === EmergencyLevel.NONE ||
      (index === OpinionRequestFormPage.emergencyAndConsent &&
        getValues("consent") === Consent.NONE) ||
      (index === OpinionRequestFormPage.patientInformation &&
        getValues("patient.birthdate.year") === "") ||
      (index === OpinionRequestFormPage.patientInformation &&
        birthdayYearInvalid()) ||
      (index === OpinionRequestFormPage.patientDisease &&
        getValues("primaryDisease") === "") ||
      (index === OpinionRequestFormPage.requestMotive &&
        getValues("motive") === "")
    );
  };

  const shouldDisplayPage = (
    request: OpinionRequest | undefined,
    requestedPage: OpinionRequestFormPage
  ): boolean => {
    if (request === undefined) {
      return true;
    }
    return !(
      optionalPages.includes(requestedPage) && isEmergencyRequest(request)
    );
  };

  const getPageNumber = (
    request: OpinionRequest | undefined,
    requestedPage: OpinionRequestFormPage
  ): number => {
    if (request === undefined) {
      return requestedPage;
    }
    let pageNumber = 0;
    for (let i = 1; i <= requestedPage; i++) {
      if (shouldDisplayPage(request, i)) {
        pageNumber++;
      }
    }
    return pageNumber;
  };

  /** Update page index and route, use this instead of setIndex */
  const setNewPageIndex = (pageNumber: number): void => {
    history.push({
      search: `?page=${pageNumber}`,
    });
    setIndex(pageNumber);
  };

  const goToNextPage = (): void => {
    if (formIsInvalid() && !readOnly) {
      console.info("form is invalid", getValues("patient"));
      setIsRequired(true);
    } else {
      setIsRequired(false);
      if (!readOnly) {
        updateOpinionRequest().then((res) => {
          let newIndex = index + 1;
          while (!shouldDisplayPage(res, newIndex)) {
            newIndex++;
          }
          if (
            res.subject?.requestType ===
              RequestPrimaryType.EEG_INTERPRETATION_ONLY &&
            newIndex === 4
          ) {
            newIndex++;
          }
          setNewPageIndex(newIndex);
        });
      }
    }
  };

  const goToLastPage = (): void => {
    if (formIsInvalid() && !readOnly) {
      console.info("form is invalid", getValues("patient"));
      setIsRequired(true);
    } else {
      setIsRequired(false);
      if (!readOnly) {
        updateOpinionRequest().then((): void =>
          setNewPageIndex(OpinionRequestFormPage.requestAddExpert)
        );
      }
    }
  };

  const goToPreviousPage = (): void => {
    if (formIsInvalid() && !readOnly) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      if (!readOnly) {
        updateOpinionRequest().then((res) => {
          let newIndex = index - 1;
          while (!shouldDisplayPage(res, newIndex)) {
            newIndex--;
          }
          if (
            res.subject?.requestType ===
              RequestPrimaryType.EEG_INTERPRETATION_ONLY &&
            newIndex === 4
          ) {
            newIndex--;
          }
          setNewPageIndex(newIndex);
        });
      }
    }
  };

  const saveAndQuit = async (): Promise<void> => {
    if (!readOnly) {
      await updateOpinionRequest();
    }
    if (currentRequest?.id) {
      history.push(`/requests/${currentRequest.id}/view`);
    } else {
      history.push("/");
    }
  };

  const deleteOR = async (): Promise<void> => {
    if (requestId) {
      const res = await deleteOpinionRequest(requestId);
      if (res instanceof Error) {
        errorToast("La demande n'a pas pu être supprimée");
      } else {
        successToast("La demande a été supprimée");
        history.push("/");
      }
    }
  };

  const setAssignedOrg = async (assignedOrgs: Organization[]) => {
    await setOpinionRequestExpertsAndOrganizations(
      currentRequest?.experts ?? [],
      assignedOrgs,
      requestId ?? ""
    );
  };

  useEffect(() => {
    if (id && id !== "") {
      fetchOpinionRequest();
    } else {
      setReadOnly(false);
    }
  }, [id]);

  useEffect((): any => {
    setTitle(getTitle(index));
    const percent = Math.floor((index / numberOfPages) * 100);
    setCompletePercentage(percent);
    trackCustomPage("page-" + index);
  }, [index]);

  useEffect(() => {
    const pageNumber = searchParam.get("page");
    if (page) {
      setIndex(
        page ? Number(pageNumber) : OpinionRequestFormPage.emergencyAndConsent
      );
    }
  }, [searchParam]);

  useEffect((): any => {
    if (isVerifiedIdentity && isVerifiedIns) {
      setValue("patient.insiStatus", InsiStatus.QUALIFIED_IDENTITY);
    } else if (!isVerifiedIdentity && isVerifiedIns) {
      setValue("patient.insiStatus", InsiStatus.FETCHED_IDENTITY);
    } else if (isVerifiedIdentity && !isVerifiedIns) {
      setValue("patient.insiStatus", InsiStatus.VALIDATED_IDENTITY);
    } else {
      setValue("patient.insiStatus", InsiStatus.PROVISIONAL_IDENTITY);
    }
  }, [isVerifiedIdentity, isVerifiedIns]);

  useEffect((): void => {
    getInsiStatusValues();
  }, [currentRequest]);

  useEffect((): any => {
    if (!currentRequest) {
      return;
    }
    const userHasEditPermission = currentRequest.participants?.find(
      (participant) => participant.userId === currentUser.id
    );
    if (userHasEditPermission?.permission === SharePermission.EDIT) {
      sethasPermissionEdit(true);
    }
  }, [currentUser, currentRequest]);

  function getFormPage() {
    return (
      <>
        {index === OpinionRequestFormPage.emergencyAndConsent && (
          <>
            <Controller
              control={control}
              name="emergencyLevel"
              shouldUnregister={true}
              defaultValue={EmergencyLevel.NONE}
              render={({ field: { onChange, value } }) => (
                <EmergencyCheck
                  emergency={value}
                  changed={onChange}
                  readOnly={readOnly}
                  isRequired={isRequired}
                />
              )}
            />
            <Controller
              control={control}
              name="consent"
              defaultValue={Consent.NONE}
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <PatientConsent
                  consent={value}
                  changed={onChange}
                  readOnly={readOnly}
                  isRequired={isRequired}
                />
              )}
            />
          </>
        )}
        {index === OpinionRequestFormPage.patientInformation && (
          <>
            {config.crhFeature && (
              <UploadDischargeSummary
                requestId={requestId}
                onDocumentLoaded={fetchOpinionRequest}
              />
            )}
            <PatientDetailsForm
              register={register}
              control={control}
              readOnly={readOnly}
              isRequired={isRequired}
              patientInfos={() => getValues("patient")}
            />
            {config.insiFeature && (
              <>
                <InsFinder
                  getPatientInfo={() => getValues("patient")}
                  onSuccess={(n: string) => {
                    setValue("patient.socialSecurityNumber", n);
                    setIsVerifiedIns(true);
                  }}
                />
                <InsCheck
                  getPatientInfo={() => getValues("patient")}
                  onSuccess={(p: Patient) => setValue("patient", p)}
                />
                <IdentityVerification
                  onChange={setIsVerifiedIdentity}
                  value={isVerifiedIdentity}
                />
              </>
            )}
            <PatientLocationForm control={control} readOnly={readOnly} />
          </>
        )}
        {index === OpinionRequestFormPage.healthPathwayDoctorsTeam && (
          <Controller
            control={control}
            name="healthPathwayContacts"
            render={({ field: { onChange, value } }) => (
              <ReferralDoctorForm
                readOnly={readOnly}
                healthPathwayContacts={value}
                onChange={onChange}
              />
            )}
          />
        )}
        {index === OpinionRequestFormPage.healthPathwayParamedicalTeam && (
          <Controller
            control={control}
            name="healthPathwayContacts"
            render={({ field: { onChange, value } }) => (
              <ParamedicalHealthPathwayForm
                readOnly={readOnly}
                healthPathwayContacts={value}
                onChange={onChange}
              />
            )}
          />
        )}
        {index === OpinionRequestFormPage.patientDisease && (
          <DiseasesForm
            register={register}
            readOnly={readOnly}
            isRequired={isRequired}
          />
        )}
        {index === OpinionRequestFormPage.patientCaseHistory && (
          <>
            <SecondaryDiseaseForm
              register={register("secondaryDiseases", {})}
              readOnly={readOnly}
            />
            <Controller
              control={control}
              name="treatments"
              render={({ field: { onChange, value } }) => (
                <TreatmentsForm
                  treatments={value}
                  onChange={onChange}
                  readOnly={readOnly}
                />
              )}
            />
            {isEEGOnly && (
              <Controller
                control={control}
                name="EEGReason"
                render={({ field: { onChange, value } }) => (
                  <EEGForm selectedEEGReason={value} onChange={onChange} />
                )}
              />
            )}
          </>
        )}
        {index === OpinionRequestFormPage.requestObject && (
          <Controller
            control={control}
            name="subject"
            shouldUnregister={true}
            render={({ field: { onChange, value } }) => (
              <SubjectForm
                selectedSubject={value}
                hasPatientFacilityId={!!currentRequest?.patient.facilityId}
                onChange={onChange}
                readOnly={readOnly}
              />
            )}
          />
        )}
        {index === OpinionRequestFormPage.requestMotive && currentRequest && (
          <>
            <Controller
              control={control}
              name="motive"
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <RequestMotive
                  readOnly={readOnly}
                  motive={value}
                  changed={onChange}
                  isRequired={isRequired}
                />
              )}
            />
            <ImagingDocumentUpload
              updateRequest={fetchOpinionRequest}
              request={currentRequest}
              displayType={DisplayType.LINE_DISPLAY}
            />
            <AttachmentSection
              id="input-electroencephalogramme"
              mainTitle="Électroencéphalogramme"
              subTitle="Ajouter un EEG (au format .edf)"
              fileTypeFilter=".edf"
              attachments={currentRequest?.attachments?.filter(
                (attachments) => attachments.Filename.slice(-4) === ".edf"
              )}
              request={currentRequest}
              updateRequest={fetchOpinionRequest}
            />
            <AttachmentSection
              id="input-documentation"
              mainTitle="Documentation"
              subTitle="Ajouter un document depuis votre ordinateur."
              request={currentRequest}
              attachments={currentRequest?.attachments?.filter(
                (attachments) => attachments.Filename.slice(-4) !== ".edf"
              )}
              updateRequest={fetchOpinionRequest}
            />
            <DmpAttachments patient={currentRequest?.patient} />
            <div className="margin-top-2 text-right w-90 mb-2">
              <span className="error-msg text-large">*</span>
              <span className="error-msg text-medium ml-1">
                Champ obligatoire
              </span>
            </div>
          </>
        )}
        {index === OpinionRequestFormPage.requestAddExpert && (
          <div>
            {hasExpertSelectionRight() && (
              <Controller
                control={control}
                name="requestedExpertId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <RequestExpertForm
                      setValue={(name, value) => {
                        setAssignedOrg(value);
                        setValue(name, value);
                      }}
                      expertId={value}
                      assignedOrganization={
                        currentRequest?.assignedOrganizations
                      }
                      onExpertSelection={onChange}
                      readOnly={readOnly}
                    />
                  );
                }}
              />
            )}

            {(!readOnly ||
              currentUser.roles.includes(UserRoles.GLOBAL_DISPATCHER)) && (
              <OpinionRequestFooter
                requestId={requestId || ""}
                requesterId={currentRequest?.requesterUser?.id}
                readOnly={readOnly}
                status={getValues().status}
                experts={currentRequest?.experts}
                loading={savingStatus === SavingStatus.SAVING}
                disabledSubmit={!id}
                onSubmit={(requesterFacilityId: string): any => {
                  setIsSubmitting(true);
                  submit(requesterFacilityId);
                }}
                isSubmitting={isSubmitting}
                saveAndQuit={saveAndQuit}
              />
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="container">
        {loadingStatus === LoadingStatus.SUCCESS && (
          <div className="form-step-container">
            <div className="vertical-line" />
            <div className="row mt-2">
              <div className="step-number col-2">
                {getPageNumber(currentRequest, index)}
              </div>
              <h4 className="title text-left col-8">{title}</h4>
              {requestId !== undefined && (
                <FormOpinionRequestBurgerMenu
                  readOnly={readOnly}
                  request={currentRequest}
                  addParticipant={(): any => setIsAddingParticipant(true)}
                  openChat={(): any => setIsChatting(true)}
                  saveAndQuit={saveAndQuit}
                  delete={deleteOR}
                />
              )}
            </div>
            <div className="form-container" id="form-container">
              {getFormPage()}
            </div>
            <div className="row footer-container">
              <div
                className={`step-number footer-step ${
                  index < numberOfPages ||
                  (index >= 6 && isEmergencyRequest(currentRequest))
                    ? ""
                    : "invisible-element"
                }`}
              >
                {getPageNumber(currentRequest, index) + 1}
              </div>
              <div className="flex-col next-info-container">
                <h5
                  className={`next-title ${
                    index < numberOfPages ? "" : "invisible-element"
                  }`}
                >
                  {getTitle(index + 1)}
                </h5>
                <div className="text-lowercase complete-percentage">
                  {completePercentage}% du formulaire complétés
                </div>
                <div className="complete-bar">
                  <div
                    className="complete-fill-bar"
                    style={{ width: completePercentage + "%" }}
                  />
                </div>
              </div>
              <div className="next-btn-container">
                <button
                  disabled={index <= 1 || isORUpdating}
                  onClick={goToPreviousPage}
                  className="btn btn-sm neuro-icons-btn arrow-btn-left"
                >
                  <i className="icon icon-arrow-left" id="form-arrow-left" />
                  <span className="arrow-btn-text-left"> Précédent </span>
                </button>
                <button
                  disabled={index >= numberOfPages || isORUpdating}
                  onClick={goToNextPage}
                  className="btn btn-sm neuro-icons-btn arrow-btn-right"
                >
                  <span className="arrow-btn-text-right"> Suivant </span>
                  <i className="icon icon-arrow-right" id="form-arrow-right" />
                </button>
                {requestId !== undefined &&
                  index !== OpinionRequestFormPage.requestAddExpert && (
                    <button
                      disabled={index >= numberOfPages || isORUpdating}
                      onClick={goToLastPage}
                      className="btn btn-sm neuro-icons-btn arrow-btn-left last-page-button tooltip"
                    >
                      <i className="icon icon-arrow-right first-arrow" />
                      <i className="icon icon-arrow-right second-arrow" />
                      <span className="tooltiptext">
                        Aller à la dernière page
                      </span>
                    </button>
                  )}
              </div>
            </div>
          </div>
        )}
        {loadingStatus === LoadingStatus.LOADING && (
          <div className="window-height-80 d-flex aligned-centered centered-content">
            <Spinner size={"xl-spinner"} margingTop={"mt-auto"} />
          </div>
        )}
      </div>
      {showMissingFieldsModal && (
        <ModalMissingMandatoryFields
          requestId={requestId ? requestId : ""}
          isEmergencyRequest={isEmergencyRequest(currentRequest)}
          mandatoryFieldsStatus={missingFields}
          key={"mandatory_fields_missing_" + requestId}
          goToPage={(requestedPage) => {
            setIndex(requestedPage);
            setShowMissingFieldsModal(false);
          }}
          onClose={(): any => {
            setShowMissingFieldsModal(false);
          }}
        />
      )}
      {isAddingParticipant &&
        currentRequest?.participants &&
        requestId !== undefined && (
          <AddParticipants
            requestId={requestId}
            participants={currentRequest.participants}
            hasPermissionEdit={hasPermissionEdit}
            onClose={(): any => setIsAddingParticipant(false)}
            onAdd={(): any => fetchOpinionRequest()}
          />
        )}
      {isChatting && (
        <OpinionChat
          requestId={requestId}
          onClose={(): any => setIsChatting(false)}
        />
      )}
    </>
  );
};
