import React from "react";
import "./OpinionChat.css";
import { getDate } from "../../utils/time";
import styles from "../Chat/MessageBubble.module.css";
import { Figure } from "../Shared/Figure";

export const MessageBubble: React.FC<any> = ({
  message,
  direction = "left",
}: any) => {
  const senderNameNoTitle = message.senderName
    .replace("Dr ", "")
    .replace("Pr ", "");
  const splittedName = senderNameNoTitle.split(" ");
  const firstName = splittedName[0];
  const lastName = splittedName[1];
  return (
    <div className={`box ${direction}`}>
      <div className={styles.figure}>
        <Figure
          firstName={firstName}
          lastName={lastName}
          fontSize={"text-medium"}
        />
      </div>
      <div className="message-container">
        <div className={`row ${direction}`}>
          <div className="message-sender">{message.senderName}</div>
          <div className="dot-separator mr-2 ml-2" />
          <div className="hour">{getDate(message.createdAt, "HH:mm")}</div>
        </div>
        <div className={`message-text ${direction}-text`}>
          {message.message}
        </div>
      </div>
    </div>
  );
};
