import React from "react";
import OpinionRequest from "../../../domain/OpinionRequest";

interface Props {
  request: OpinionRequest;
}

export const DiseaseDescription: React.FC<any> = (props: Props) => {
  return (
    <div>
      <div className="text-primary-blue">Pathologie</div>
      <div className="flex-row pl-2">
        <div className="line-figure">
          <div className="green-point" />
        </div>
        <div className="motive-line text-primary-blue">
          {props.request?.primaryDisease
            ? props.request?.primaryDisease
            : "N/A"}
        </div>
      </div>
      <div className="text-primary-blue mt-2">Evolution de la maladie</div>
      <div className="flex-row pl-2">
        <div className="line-figure">
          <div className="green-point" />
        </div>
        <div className="motive-line text-primary-blue">
          {props.request?.diseaseStage ? props.request.diseaseStage : "N/A"}
        </div>
      </div>
    </div>
  );
};
