export interface OpinionRequestMandatoryFieldsStatus {
  emergencyLevel: boolean;
  patientConsent: boolean;
  patientBirthday: boolean;
  primaryDiseases: boolean;
  motive: boolean;
  healthPathwayContacts: boolean;
}

export function buildMandatoryFieldsMissing(
  list: string[]
): OpinionRequestMandatoryFieldsStatus {
  return {
    emergencyLevel: list.includes("emergencyLevel"),
    motive: list.includes("motive"),
    patientBirthday: list.includes("patientBirthday"),
    patientConsent: list.includes("patientConsent"),
    primaryDiseases: list.includes("primaryDiseases"),
    healthPathwayContacts: list.includes("healthPathwayContacts"),
  };
}
