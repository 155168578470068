import React from "react";
import styles from "./InvoiceHistoryCard.module.css";
import { Invoice } from "../../domain/Invoice";
import { viewPdfInvoice } from "../../services/InvoicingService";

interface Props {
  invoice: Invoice;
}

export const InvoiceHistoryCard: React.FC<Props> = (props: Props) => {
  const invoice = props.invoice;

  const viewInvoice = (): void => {
    invoice.id && window.open(viewPdfInvoice(invoice.id));
  };

  return (
    <>
      <div className={`card ${styles.card}`}>
        <div className="flex-row space-between-content">
          <div className={`flex-col text-left m-auto`}>
            <span className={`text-bold ${styles.categoryTitle}`}>
              Numéro de facture
            </span>
            {invoice.identificationNumber}
          </div>
          <div className={styles.verticalLine} />
          <div className={`flex-col text-left m-auto`}>
            <span className={`text-bold ${styles.categoryTitle}`}>
              Référence accord
            </span>
            {invoice.acts[0]?.purchaseOrder}
          </div>
          <div className={styles.verticalLine} />
          <div className={"flex-col text-left m-auto"}>
            <span className={`text-bold ${styles.categoryTitle}`}>
              Requérant
            </span>
            {invoice.client.name
              ? invoice.client.name
              : invoice.client.companyName}
          </div>
          <div
            className={`flex-col text-right w-30 m-auto ${styles.alignRight}`}
          >
            {invoice.client.email}
            <div className={`row mt-2 ${styles.alignRight}`}>
              {/*<button TODO do not delete this comment for now
                className={`btn mr-1 ${styles.downloadInvoiceButton}`}
                onClick={downloadInvoice}
              >
                <i
                  className={`icon icon-downward neuro-primary ${styles.icon}`}
                />
              </button>*/}
              <button
                className={`btn neuro-btn ml-1 ${styles.visualizeInvoiceButton}`}
                onClick={viewInvoice}
              >
                Visualiser la facture
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
