import React from "react";
import { useToggleClickOutside } from "../utils/useToggleClickOutside.hook";
import styles from "./LanguageSelector.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  rightMargin?: boolean;
}

export const LanguageMenu: React.FC<Props> = () => {
  const [showDropDown, toggleDropdown] = useToggleClickOutside(false);
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={styles.dropdown}>
      <button
        className={styles.languageBtn}
        id="language-flag-btn"
        onClick={toggleDropdown}
      >
        {t("language_button")}
      </button>
      {/*De preference ne pas traduire les noms des langues pour les retrouver + facilement*/}
      {/*We should not translate language names as it will be easier for the user to find them*/}
      {showDropDown && (
        <div className={`${styles.dropdownContent} ${styles.btnContainer}`}>
          <button
            className={styles.menuItem}
            id="english-language-btn"
            onClick={() => {
              changeLanguage("en");
            }}
          >
            English
          </button>
          <button
            className={styles.menuItem}
            id="french-language-btn"
            onClick={() => {
              changeLanguage("fr");
            }}
          >
            Français
          </button>
        </div>
      )}
    </div>
  );
};
