import React from "react";

interface Props {
  treatments: string[];
  onChange: () => any;
  readOnly: boolean;
}

export const TreatmentsForm: React.FC<Props> = (props: any) => {
  const readOnly = props.readOnly;
  const treatments = props.treatments;

  let lastElement: HTMLElement;

  return (
    <div>
      <div className="row space-between-content">
        <h4 className="form-title">Traitement(s) actuel(s) du patient</h4>
        {!readOnly && (
          <button
            type="button"
            id="add-treatment-btn"
            className="btn btn-sm neuro-icons-btn ml-2"
            onClick={(): void => {
              props.onChange([...treatments, ""]);
              lastElement.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }}
          >
            <i className="icon icon-plus" />
          </button>
        )}
      </div>
      <div>
        <label className="row w-100 text-small">
          Veuillez également indiquer la posologie.
        </label>
      </div>
      {treatments.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="row"
            style={{ alignItems: "end", marginLeft: "5%", paddingTop: "3%" }}
          >
            <input
              type="text"
              className="form-input w-90"
              id={"new-treatment-input" + index}
              placeholder="Traitements"
              onChange={(event: any): any => {
                const newTreatments = [...treatments];
                newTreatments[index] = event.target.value;
                props.onChange(newTreatments);
              }}
              value={item}
              readOnly={readOnly}
            />
            {!readOnly && (
              <button
                style={{ backgroundColor: "transparent" }}
                id={"delete-treatment-btn" + index}
                className="btn btn-sm neuro-icons-btn mx-2"
                onClick={(): void => {
                  const newTreatments = treatments.filter(
                    (_: string, i: number) => i !== index
                  );
                  props.onChange(newTreatments);
                }}
              >
                <i className="icon icon-cross" />
              </button>
            )}
          </div>
        );
      })}
      <div
        ref={(el): void => {
          if (el != null) {
            lastElement = el;
          } else {
            return;
          }
        }}
      />
    </div>
  );
};
