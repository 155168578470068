import React from "react";
import styles from "./Label.module.css";

interface Props {
  color: string;
  type: string;
  children: any;
}

export const Label: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div
        className={`label text-centered ml-auto ${styles.label} ${
          styles[props.color]
        } ${styles[props.type]}`}
      >
        {props.children}
      </div>
    </>
  );
};
