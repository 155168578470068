import React, { useState } from "react";
import { Act } from "../../../domain/Invoice";
import styles from "../InvoicingForm.module.css";
import { errorContainer, ErrorMessage, FormValidation } from "../InvoicingForm";
import { MinimizedActCard } from "./MinimizedActCard";

interface Props {
  reset: any;
  setValue: any;
  acts: Act[];
  updateActs: (newActs: Act[]) => any;
  formValidation: FormValidation[];
  setFormValidation: (newFormValidation: FormValidation[]) => any;
  errorInAct: (isError: boolean) => any;
}

export const InvoicingFormPage2: React.FC<Props> = (props: Props) => {
  const [bigCardIndex, setBigCardIndex] = useState<number>(
    props.acts.length - 1
  );

  const checkFieldValidation = (
    index: number,
    field: string,
    input?: string
  ): void => {
    const actValidation = props.formValidation;
    if (!input || input === "") {
      actValidation[index][`${field}` as keyof FormValidation] =
        ErrorMessage.REQUIRED_FIELD;
      props.setFormValidation(actValidation);
      checkFormValidation();
    } else {
      switch (field) {
        case "doctorName":
          props.formValidation[index].doctorName = ErrorMessage.NONE;
          break;
        case "purchaseOrder":
          props.formValidation[index].purchaseOrder = ErrorMessage.NONE;
          break;
        case "price":
          {
            const priceRegex = new RegExp(/^\d+([,\\.]\d{1,2})?$/);
            if (priceRegex.test(input)) {
              actValidation[index].price = ErrorMessage.NONE;
            } else {
              actValidation[index].price = ErrorMessage.PRICE_ERROR;
            }
          }
          break;
        case "vatPercent":
          {
            const vatPercentRegex = new RegExp(/^[1-9][0-9]?$|^100$/);
            if (vatPercentRegex.test(input)) {
              actValidation[index].vatPercent = ErrorMessage.NONE;
            } else {
              actValidation[index].vatPercent = ErrorMessage.VAT_PERCENT_ERROR;
            }
          }
          break;
        case "serviceDescription":
          actValidation[index].serviceDescription = ErrorMessage.NONE;
          break;
        default:
          break;
      }
      props.setFormValidation(actValidation);
      checkFormValidation();
    }
  };

  const checkFormValidation = () => {
    const notValidatedActs = props.formValidation.filter(
      (actValidation) =>
        actValidation.doctorName !== ErrorMessage.NONE ||
        actValidation.purchaseOrder !== ErrorMessage.NONE ||
        actValidation.price !== ErrorMessage.NONE ||
        actValidation.vatPercent !== ErrorMessage.NONE ||
        actValidation.serviceDescription !== ErrorMessage.NONE
    );

    if (notValidatedActs.length === 0) {
      props.errorInAct(false);
    } else {
      props.errorInAct(true);
    }
  };

  const inputCheck = (object: any): any => {
    if (object.target.value !== "") {
      if (isNaN(Number(object.target.value[object.target.value.length - 1]))) {
        object.target.value = object.target.value.slice(0, -1);
      }
    }
  };

  return (
    <>
      <h5 className="mb-0">Actes à facturer</h5>
      <div className="text-left">
        <button
          className="btn btn-sm neuro-icons-btn mb-2 mt-2"
          onClick={(): void => {
            props.updateActs([
              ...props.acts,
              {
                doctorName: "",
                purchaseOrder: "",
                price: 0,
                serviceDescription: "",
                vatPercent: 0,
              },
            ]);
            props.setFormValidation([
              ...props.formValidation,
              {
                doctorName: ErrorMessage.REQUIRED_FIELD,
                purchaseOrder: ErrorMessage.REQUIRED_FIELD,
                price: ErrorMessage.REQUIRED_FIELD,
                vatPercent: ErrorMessage.REQUIRED_FIELD,
                serviceDescription: ErrorMessage.REQUIRED_FIELD,
              },
            ]);
            props.errorInAct(true);
            setBigCardIndex(props.acts.length);
          }}
        >
          <i className="icon icon-plus" /> Ajouter un acte
        </button>
        {props.acts.length > 0 &&
          props.acts.map((act: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {bigCardIndex !== index ? (
                  <MinimizedActCard
                    index={index}
                    reset={props.reset}
                    setValue={props.setValue}
                    acts={props.acts}
                    updateActs={(filteredActs): void =>
                      props.updateActs(filteredActs)
                    }
                    formValidation={props.formValidation}
                    setFormValidation={(newFormValidation): void =>
                      props.setFormValidation(newFormValidation)
                    }
                    bigCardIndex={bigCardIndex}
                    setBigCardIndex={(newBigCardIndex): void =>
                      setBigCardIndex(newBigCardIndex)
                    }
                  />
                ) : (
                  <div className={`card ${styles.actCard}`}>
                    <button
                      className={styles.removeCardButton}
                      onClick={(): void => {
                        const filteredActs = props.acts.filter(
                          (_: Act, i: number) => i !== index
                        );
                        props.updateActs(filteredActs);
                        props.reset(filteredActs);
                        props.setFormValidation(
                          props.formValidation.filter(
                            (_, item) => item !== index
                          )
                        );
                        if (filteredActs.length === 0) {
                          props.errorInAct(true);
                        } else {
                          setBigCardIndex(filteredActs.length - 1);
                        }
                        checkFormValidation();
                      }}
                    >
                      ×
                    </button>
                    <div className={`text-bold ${styles.actNumberTitle}`}>
                      Acte {index + 1}
                    </div>
                    <div className="form-group mb-0">
                      <label className="form-label">Médecin</label>
                      <input
                        id={"doctor-name-input-" + index}
                        className="form-input w-100"
                        type="text"
                        placeholder="Entrer le nom du médecin qui a demandé l'acte"
                        onChange={(event: any): any => {
                          const newActs = [...props.acts];
                          newActs[index].doctorName = event.target.value;
                          props.updateActs(newActs);
                          props.setValue("acts", newActs);
                          checkFieldValidation(
                            index,
                            "doctorName",
                            event.target.value
                          );
                        }}
                        value={act.doctorName}
                      />
                      {errorContainer(props.formValidation[index].doctorName)}
                    </div>
                    <div className="form-group mb-0">
                      <label className="form-label">
                        Référence de la commande
                      </label>
                      <input
                        id={"purchase-order-input-" + index}
                        className="form-input w-100"
                        type="text"
                        placeholder="Entrer le bon de commande"
                        onChange={(event: any): any => {
                          const newActs = [...props.acts];
                          newActs[index].purchaseOrder = event.target.value;
                          props.updateActs(newActs);
                          props.setValue("acts", newActs);
                          checkFieldValidation(
                            index,
                            "purchaseOrder",
                            event.target.value
                          );
                        }}
                        value={act.purchaseOrder}
                      />
                      {errorContainer(
                        props.formValidation[index].purchaseOrder
                      )}
                    </div>
                    <div className="form-group mb-0 row">
                      <div className="col-6">
                        <label className="form-label">
                          Prix de vente HT (en €)
                        </label>
                        <input
                          id={"price-input-" + index}
                          className="form-input w-70"
                          type="text"
                          placeholder="Prix en €"
                          onChange={(event: any): any => {
                            const newActs = [...props.acts];
                            newActs[index].price = event.target.value;
                            props.updateActs(newActs);
                            props.setValue("acts", newActs);
                            checkFieldValidation(
                              index,
                              "price",
                              event.target.value
                            );
                          }}
                          value={act.price}
                        />
                        {errorContainer(props.formValidation[index].price)}
                      </div>
                      <div className="col-6">
                        <label className="form-label">TVA (en %)</label>
                        <input
                          id={"vat-percent-input-" + index}
                          className="form-input w-70"
                          type="text"
                          placeholder="Entrez le % de la TVA"
                          onInput={inputCheck}
                          maxLength={2}
                          onChange={(event: any): any => {
                            const newActs = [...props.acts];
                            newActs[index].vatPercent = event.target.value;
                            props.updateActs(newActs);
                            props.setValue("acts", newActs);
                            checkFieldValidation(
                              index,
                              "vatPercent",
                              event.target.value
                            );
                          }}
                          value={act.vatPercent}
                        />
                        {errorContainer(props.formValidation[index].vatPercent)}
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <label className="form-label">
                        Description de la prestation
                      </label>
                      <textarea
                        id={"service-description-input-" + index}
                        className="form-input w-100"
                        placeholder="Décrivez la prestation effectuée"
                        onChange={(event: any): any => {
                          const newActs = [...props.acts];
                          newActs[index].serviceDescription =
                            event.target.value;
                          props.updateActs(newActs);
                          props.setValue("acts", newActs);
                          checkFieldValidation(
                            index,
                            "serviceDescription",
                            event.target.value
                          );
                        }}
                        value={act.serviceDescription}
                      />
                      {errorContainer(
                        props.formValidation[index].serviceDescription
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};
