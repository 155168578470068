import { CivilTitle, HealthProfessionalTitle, User } from "../domain/User";
import React from "react";
import { UserStatus } from "../domain/UserStatus";

export interface AppState {
  user: User;
  authenticated: boolean;
}

export const AppInitialState: AppState = {
  user: {
    professionalTitle: HealthProfessionalTitle.NONE,
    civilTitle: CivilTitle.NONE,
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    id: "",
    roles: [],
    organizations: [],
    specialty: "",
    status: UserStatus.CREATED,
    facilities: [],
  },
  authenticated: false,
};

const AppContext = React.createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
}>({
  state: AppInitialState,
  dispatch: () => null,
});

export enum ActionTypes {
  UserConnected = "USER_CONNECTED",
  UserDisconnected = "USER_DISCONNECTED",
  UserUpdated = "UPDATE_USER",
}

type Action =
  | { type: ActionTypes.UserConnected; payload: User }
  | { type: ActionTypes.UserDisconnected }
  | { type: ActionTypes.UserUpdated; payload: User };

export const AppContextReducer: React.Reducer<AppState, Action> = (
  state: AppState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.UserConnected:
    case ActionTypes.UserUpdated:
      return {
        ...state,
        user: action.payload,
        authenticated: true,
      };
    case ActionTypes.UserDisconnected:
      return {
        ...state,
        user: AppInitialState.user,
        authenticated: false,
      };
    default:
      return state;
  }
};

export { AppContext };
