import { get, send } from "./ApiClient";
import { Invoice } from "../domain/Invoice";
import config from "../utils/config";
import { InvoiceClient } from "../domain/InvoiceClient";

export async function getAllInvoicesByMonth(
  month: number,
  year: number
): Promise<Invoice[] | Error> {
  try {
    let invoices = await get(`/invoices?month=${month}&year=${year}`);

    invoices = await Promise.all(
      invoices.map(async (invoice: Invoice) => {
        invoice.client = await get(`/clients?clientId=${invoice.clientId}`);
        return invoice;
      })
    );

    return invoices;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function searchClients(
  query: string
): Promise<InvoiceClient[] | Error> {
  try {
    return await get(`/clients/search?query=${query}`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function saveInvoice(invoice: Invoice): Promise<any | Error> {
  try {
    return await send(`/invoices`, "POST", invoice);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export function viewPdfInvoice(invoiceId: string): string {
  return `${config.apiUrl}/invoices/pdf?invoiceId=${invoiceId}`;
}
