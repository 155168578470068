import React from "react";
import styles from "./GeneralTermsOfUseText.module.css";

export const GeneralTermsOfUseText: React.FC<any> = () => {
  return (
    <>
      <div className={`mb-2 text-bold text-uppercase ${styles.subTitle}`}>
        1. Définitions
      </div>
      <div className="justify">
        Dans les présentes conditions générales d’utilisation, les termes
        suivants avec une majuscule en première lettre ont, sauf précision
        contraire, le sens qui leur est donné au présent Article, qu’ils soient
        au singulier ou au pluriel.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        2. Objet et périmètre d&apos;application
      </div>
      <div className="justify">
        <div className="text-bold mt-2 mb-2 margin-left-2">2.1 Généralités</div>
        Les Conditions Générales d’Utilisation s’appliquent, sans restriction ni
        réserve à <span className="text-italic">(i)</span> l’utilisation de la
        Plateforme et du Service par l’Utilisateur et{" "}
        <span className="text-italic">(ii)</span> l’utilisation du Site Internet
        par un Navigateur.
        <br />
        <br />
        L’Utilisateur et le Navigateur sont tenus de prendre connaissance des
        présentes Conditions Générales d’Utilisation avant toute utilisation du
        Site Internet, de la Plateforme et du Service. Les Conditions Générales
        d’Utilisation sont accessibles par lien hypertexte sur le Site Internet.
        L’Utilisateur et le Navigateur sont invités à télécharger les CGU et/ou
        à les imprimer et à en conserver une copie. L’Utilisateur et le
        Navigateur sont informés que les présentes Conditions Générales
        d’Utilisation peuvent être modifiées à tout moment et sans préavis ; la
        dernière version en vigueur étant celle accessible sur le Site Internet.
        <br />
        <br />
        L’utilisation du Site Internet, de la Plateforme et du Service implique
        l’acceptation sans restriction ni réserve des présentes Conditions
        Générales d’Utilisation par l’Utilisateur et le Navigateur, lesquels
        sont réputés en avoir une parfaite connaissance. Les Conditions
        Générales d’Utilisation prévaudront, le cas échéant, sur toute autre
        version ou tout autre document contradictoire. Ces Conditions Générales
        d’Utilisation s’appliquent à l’exclusion de toutes autres conditions qui
        seront inopposables à AiiNTENSE, même si elle en a eu connaissance.
        <br />
        <br />
        AiiNTENSE se réserve le droit d’annuler ou de limiter l’accès et
        l’utilisation du Site Internet, de la Plateforme et du Service en cas de
        violation des présentes Conditions Générales d’Utilisation, ou s’il
        existe un quelconque litige antérieur ou en cours avec l’Utilisateur ou
        le Navigateur.
        <div className="text-bold mt-2 mb-2 margin-left-2">
          2.2 Qualité de l&apos;utilisateur
        </div>
        L’accès à la Plateforme et au Service est strictement réservé à
        l’utilisateur remplissant cumulativement l’ensemble des critères
        suivants (l’ &laquo;{" "}
        <span className="text-extra-bold">Utilisateur</span> &raquo;) :
        <br />
        <ul>
          <li>
            il doit être une personne physique ayant soit{" "}
            <span className="text-italic">(i)</span> la qualité de médecin
            inscrit à l’Ordre national des médecins et titulaire d’un numéro
            RRPS en cours de validité, soit{" "}
            <span className="text-italic">(ii)</span> la qualité de personnel
            soignant sous réserve que celle-ci soit supervisée par une personne
            physique ayant la qualité de médecin inscrit à l’Ordre national des
            médecins et titulaire d’un numéro RRPS en cours de validité dont
            l’identité devra également être renseignée sur la Plateforme ;
          </li>
          <li>
            il doit être un professionnel au sens de l’Article liminaire du Code
            de la consommation, à savoir une personne physique qui agit à des
            fins entrant dans le cadre de son activité commerciale,
            industrielle, artisanale, libérale ou agricole, y compris
            lorsqu’elle agit au nom et pour le compte d’un autre professionnel.
          </li>
        </ul>
        Ces critères constituent une condition essentielle de l’accès à la
        Plateforme et au Service. AiiNTENSE se réserve la possibilité de refuser
        ou de limiter l’accès et l’utilisation de la Plateforme et du Service à
        tout utilisateur qui ne satisferait pas à ces critères, en totalité ou
        partiellement. Dans le cadre de l’exploitation du Service, l’Utilisateur
        peut être consécutivement Requérant et Expert.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        3. Plateforme et service
      </div>
      <div className="justify">
        <div className="text-bold mt-2 mb-2 margin-left-2">
          3.1 Accessibilité à la Plateforme
        </div>
        La Plateforme est accessible via un ordinateur, une tablette ou un
        smartphone, sous réserve que l’outil soit connecté à Internet.
        <br />
        <br />
        Compte tenu du Service fourni, l’accès à la Plateforme est exclusivement
        réservé à l’Utilisateur. Afin de s’en assurer, la connexion à la
        Plateforme est soumise à une forte identification de l’Utilisateur.
        <br />
        <br />
        L’Utilisateur doit d’abord adresser une demande d’inscription à
        AiiNTENSE. Pour ce faire, l’Utilisateur se rend sur le Site Internet, et
        complète un formulaire d’inscription, soit :
        <ul>
          <li>
            <span className="text-italic">(i)</span> via un espace dédié à cet
            effet sur le Site Internet. L’Utilisateur est invité à renseigner
            son courriel ainsi qu’un mot de passe composé d’un nombre de
            caractères imposé. L’Utilisateur reçoit un lien de connexion par
            courriel, à l’adresse électronique indiquée par lui. En cliquant sur
            ce lien de connexion, l’Utilisateur devra renseigner ses nom, prénom
            et numéro RPPS ;
          </li>
          <li>
            <span className="text-italic">(ii)</span> en passant par la
            plateforme &laquo; Pro Santé Connect &raquo; en cliquant sur
            l’encart correspondant sur le Site Internet. L’Utilisateur peut se
            connecter à ce service en utilisant sa carte CPS ou l’application
            e-CPS. Le cas échéant, il est invité à mettre à jour les
            informations préremplies (nom, prénom et courriel).
          </li>
        </ul>
        L’Utilisateur doit ensuite finaliser son inscription en trois étapes :{" "}
        <span className="text-italic">(i)</span> l’Utilisateur renseigne à
        nouveau plusieurs informations (numéro RRPS, profession, spécialité,
        lieu d’exercice, numéro de téléphone portable, …) avant d’être invité à
        accepter les présentes CGU en cochant la case prévue à cet effet,{" "}
        <span className="text-italic">(ii)</span> l’Utilisateur entre un code à
        plusieurs chiffres reçus sur le numéro de téléphone portable
        préalablement renseigné et <span className="text-italic">(iii)</span>{" "}
        l’Utilisateur se prend en photo en présentant sa carte CPS à la main.
        <br />
        <br />
        Lorsque l’inscription est finalisée, une notification de demande
        d’inscription est envoyée à AiiNTENSE laquelle s’engage à faire ses
        meilleurs efforts aux fins de valider ou de rejeter la demande
        d’inscription dans les douze (12) heures de sa réception (du lundi au
        vendredi de 9h à 19h). En cas d’incohérence entre le formulaire transmis
        et les informations ou pièces communiquées par l’Utilisateur, AiiNTENSE
        se réserve la possibilité de solliciter des informations
        complémentaires. Le cas échéant, si les informations ou pièces
        transmises sont à nouveau jugées inexactes et/ou incomplètes par
        AiiNTENSE, l’inscription à la Plateforme sera rejetée.
        <br />
        <br />
        Lorsque la demande d’inscription est acceptée, l’Utilisateur reçoit une
        notification par courriel. Il pourra accéder à la Plateforme en se
        connectant à son espace dédié sur le Site Internet (&laquo;{" "}
        <span className="text-extra-bold">Compte Utilisateur</span> &raquo;).
        Pour ce faire, l’Utilisateur renseigne son courriel et le mot de passe
        qu’il aura créé dans le cadre de sa demande d’inscription, lesquels
        constituent ses codes d’accès (&laquo;{" "}
        <span className="text-extra-bold">Codes d’Accès</span> &raquo;). Les
        Codes d’Accès sont strictement personnels et confidentiels.
        <br />
        <br />
        La conservation et l’utilisation de ces Codes d’Accès sont sous
        l’entière et exclusive responsabilité de l’Utilisateur. L’Utilisateur
        s’engage à ne pas les divulguer à qui que ce soit, sous quelque forme
        que ce soit. Toutes actions effectuées sur son Compte Personnel avec ses
        Codes d’Accès seront réputées avoir été exécutées par l’Utilisateur qui
        a créé et/ou reçus ces Codes d’Accès. Il est de la responsabilité de
        l’Utilisateur de gérer la sécurité de son Compte Personnel.
        <br />
        <br />
        L’Utilisateur s’engage à informer sans délai AiiNTENSE en cas de perte,
        de vol, de détournement ou de toute utilisation non autorisée de ses
        Codes d’Accès, dès lors qu’il en aurait eu connaissance pour en demander
        le blocage à AiiNTENSE. Dès réception, AiiNTENSE bloquera tout accès au
        Compte Personnel via les Codes d’Accès de l’Utilisateur. Une
        confirmation écrite du blocage des Codes d’Accès sera adressée par
        courrier électronique à l’Utilisateur. En cas de perte, de vol ou
        d’utilisation frauduleuse de l’un de ses Codes d’Accès, l’Utilisateur
        sera seul responsable de toute conséquence dommageable, à l’exclusion de
        toute responsabilité d’AiiNTENSE. L’Utilisateur reconnait qu’il ne
        pourra être titulaire que d’un seul Compte Personnel lui permettant
        d’accéder au Service. L’utilisation du Service et l’accès au Compte
        Personnel est strictement personnel à l’Utilisateur. L’Utilisateur
        s’interdit d’accorder l’accès à son Compte Personnel à tout autre tiers.
        A défaut, aucun cas la responsabilité d’AiiNTENSE pourra être recherchée
        dans ce cadre.
        <br />
        <br />
        AiiNTENSE se réserve le droit de suspendre, restreindre l’accès ou
        bloquer les Codes d’Accès de l’Utilisateur si AiiNTENSE est informée de
        tout usage anormal ou si AiiNTENSE a des raisons légitimes de penser que
        les Codes d’Accès ont été piratés ou que l’utilisation du Compte
        Personnel est frauduleuse, ou plus généralement que l’utilisation de ces
        Codes d’Accès ou du Compte Personnel est contraire aux dispositions des
        présentes Conditions Générales d’Utilisation.
        <div className="text-bold mt-2 mb-2 margin-left-2">
          3.2 Fonctionnement du Service
        </div>
        La Plateforme est un outil permettant à tous les Utilisateurs y
        accédant, de bénéficier d’un service de base permettant de{" "}
        <span className="text-italic">(i)</span> solliciter les avis médicaux
        d’autres Utilisateurs par le biais de demandes d’avis,{" "}
        <span className="text-italic">(ii)</span>
        de répondre aux demandes d’avis et{" "}
        <span className="text-italic">(iii)</span> d’échanger des contenus et
        des données en matière médicale au soutien des demandes d’avis et
        réponses à ces avis ; la finalité de ce service de base étant
        d’améliorer le traitement des patients et de leur diagnostic (le &laquo;{" "}
        <span className="text-extra-bold">Service</span> &raquo;).
        <br />
        <br />
        Tout Utilisateur souhaitant recueillir l’avis d’un autre Utilisateur sur
        une question d’ordre médicale (le &laquo;
        <span className="text-extra-bold">Requérant</span> &raquo;) peut
        partager une requête via la Plateforme. Pour ce faire, le Requérant se
        rend dans l’onglet dédié sous la Plateforme et est invité à compléter un
        formulaire informatisé, au sein duquel le Requérant doit répondre à
        plusieurs questions successives. Le Requérant peut joindre différents
        documents à ce formulaire (compte rendu d’imagerie, compte rendu
        d’hospitalisation, etc.).
        <br />
        <br />
        Lorsque le formulaire est dûment complété et envoyé, la demande du
        Requérant est validée par le régulateur intégrant la Plateforme et
        celui-ci désigne un autre Utilisateur compétent pour répondre à la
        demande du Requérant (les ou l’&laquo;{" "}
        <span className="text-extra-bold">Expert(s)</span> &raquo;). L’Expert
        réceptionne alors le formulaire du Requérant contenant sa requête.
        L’Expert peut accepter et répondre à la demande du Requérant, sous
        l’onglet dédié au sein de la Plateforme. L’Expert est alors invité à
        rédiger son compte rendu médical sous la Plateforme, avant de l’envoyer
        et de clôturer la demande du Requérant. Si l’Expert ne souhaite pas
        répondre à la requête du Requérant, alors celui-ci peut refuser la
        demande. Le régulateur intégrant la Plateforme est alors chargé de
        confier la requête à un autre Expert.
        <br />
        <br />
        Plusieurs Experts peuvent être sollicités successivement pour une même
        demande. Le cas échéant, le compte rendu médical final devra être rédigé
        par le dernier Expert intervenant sur la demande, lequel sera chargé de
        l’envoyer au Requérant et de clôturer la demande.
        <br />
        <br />A chacune des étapes des échanges entre Utilisateurs intéressés,
        ceux-ci sont tenus informés de l’avancement de la requête du Requérant
        via le moyen qu’ils auront sélectionné (e-mail, SMS, etc.).
        <div className="text-bold mt-2 mb-2 margin-left-2">
          3.3 Conditions de réalisation du Service
        </div>
        Il est rappelé à l’Utilisateur que le Service ne prend pas en charge les
        situations synchrones. L’Utilisateur reconnaît que le Service ne
        constitue ni directement, ni indirectement une consultation médicale
        remplaçant une prise en charge globale et personnalisée du patient par
        l’Utilisateur dans le cadre d’un examen clinique physique. Le Service
        permet de mettre en œuvre des télé-expertises médicales, lesquelles
        constituent une demande d’avis à distance, entre confrères, au sujet
        d’un patient. Les informations relatives aux patients objet de la
        téléexpertise ne peuvent être partagées avec d’autres professionnels de
        santé qui ne serait pas Utilisateur de la Plateforme.
        <br />
        <br />
        L’Utilisateur est seul responsable de l’exactitude, de la cohérence et
        l’exhaustivité des données et pièces médicales communiquées via la
        Plateforme. Préalablement à la rédaction de son compte rendu, l’Expert
        est tenu de s’assurer qu’il dispose de l’expertise et de l’ensemble des
        données nécessaires aux fins de répondre à la demande du Requérant. En
        aucun cas AiiNTENSE n’est en mesure d’effectuer de telles vérifications
        à la place de l’Utilisateur, Requérant ou Expert, et ne peut voir sa
        responsabilité engagée à ce titre. De manière générale, il est rappelé
        que toute demande réalisée par le Requérant via la Plateforme et toute
        réponse formulée par l’Expert via la Plateforme sont réalisées sous
        l’entière et unique responsabilité du Requérant et de l’Expert, à
        l’exclusion de toute responsabilité d’AiiNTENSE laquelle ne peut être
        engagée à ce titre.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        4. Déontologie et réglementation
      </div>
      <div className="justify">
        L’Utilisateur s’engage, dans le cadre de l’utilisation de la Plateforme
        et du Service, à respecter l’intégralité des règles déontologiques
        présidant à l’exercice de sa profession telles qu’elles sont définies
        dans le Code de déontologie et interprétées par le Conseil National de
        l’Ordre des Médecins, ainsi que toute dispositions légales ou
        réglementaires applicable à l’Utilisateur en sa qualité de médecin. A ce
        titre, l’Utilisateur s’engage notamment à respecter :{" "}
        <span className="text-italic">(i)</span>
        les obligations d’information dont il est redevable à l’égard de chacun
        de ses patients dont les données sont partagées via la Plateforme,
        conformément aux dispositions du Code de la santé publique ;{" "}
        <span className="text-italic">(ii)</span> le secret le plus absolu sur
        les informations de santé et relatives à la vie privée des patients dont
        les données sont partagées via la Plateforme, et ce conformément à ses
        obligations légales, réglementaires et déontologiques, lesquelles
        incluent le secret médical ; <span className="text-italic">(iii)</span>{" "}
        le principe d’interdiction de tout procédé direct ou indirect de
        publicité, notamment en faveur d’un professionnel de santé ;{" "}
        <span className="text-italic">(iv)</span> la réglementation applicable
        en matière de partage du dossier médical de patients entre
        professionnels de santé et de téléexpertise telle que celle-ci résulte
        notamment des dispositions du Code de la santé publique.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        5. Assurance
      </div>
      <div className="justify">
        L’Utilisateur déclare avoir souscrit auprès d’une compagnie d’assurance
        notoirement solvable, une police d’assurance destinée à couvrir sa
        responsabilité civile professionnelle, et notamment les dommages
        résultant de son utilisation du Service et qui pourraient être causés à
        tout autre Utilisateur ou aux patients objets des échanges entre les
        Utilisateurs via la Plateforme.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        6. Conditions financières
      </div>
      <div className="justify">
        Le Site Internet et la Plateforme sont non marchand, si bien qu’aucun
        paiement ne peut être recueilli via ces outils.
        <br />
        <br />
        L’accès à la Plateforme et au Service est consenti à titre gratuit à
        l’Utilisateur. AiiNTENSE se réserve toutefois la possibilité de facturer
        à l’Utilisateur toute prestation ou tout outil complémentaire ou annexe
        au Service, dont la fourniture sera régie par un accord distinct des
        présentes.
        <br />
        <br />
        Il est toutefois précisé à l’Utilisateur que l’utilisation par lui du
        Service est rémunéré en France par l’assurance maladie. En effet,
        lorsqu’il est Requérant et partage une demande sur la Plateforme ou
        lorsqu’il est Expert et répond à une demande sur la Plateforme,
        l’Utilisateur perçoit une certaine rémunération fixée par l’assurance
        maladie. AiiNTENSE communiquera aux Utilisateurs concernés, avant le 20
        du mois M+1 au titre d’un mois écoulé M, les informations nécessaires
        aux fins pour les Utilisateurs concernés de percevoir cette
        rémunération. Toutefois, l’Utilisateur fera son affaire personnelle de
        la réalisation des diligences nécessaires à sa rémunération en qualité
        de Requérant et/ou d’Expert, auprès de l’assurance maladie.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        7. Propriété intellectuelle
      </div>
      <div className="justify">
        AiiNTENSE demeure le seul et l’unique titulaire de l’ensemble des droits
        de propriété intellectuelle portant notamment sur le Site Internet et la
        Plateforme, en ce compris tous droits de propriété industrielle (marque,
        brevet, dessins et modèles, etc.), droits d’auteur, nom de domaine,
        droits sur les logiciels, droits des producteurs de bases de données,
        invention, idée, amélioration, savoir-faire de fabrication, technologie,
        secret de fabrication et tous autres droits de propriété intellectuelle
        (&laquo;{" "}
        <span className="text-extra-bold">
          Droit(s) de Propriété Intellectuelle
        </span>{" "}
        &raquo;). A ce titre, l’Utilisateur et le Navigateur ne bénéficient et
        ne peuvent prétendre à aucune prérogative en matière de propriété
        intellectuelle au titre des présentes. Aucun droit de propriété de
        quelque sorte que ce soit et notamment relatif au Site Internet et à la
        Plateforme n’est cédé à l’Utilisateur ou le Navigateur dans le cadre des
        présentes.
        <br />
        <br />
        Sans préjudice des dispositions prévues au présent Article, AiiNTENSE
        confère à l’Utilisateur un droit d’accès et d’utilisation du Site
        Internet, de la Plateforme et de son Compte Personnel. Ce droit d’accès
        et d’utilisation est strictement personnel et réservé à l’Utilisateur
        exclusivement.
        <br />
        <br />
        AiiNTENSE conserve également la propriété de tous les développements
        relatifs au Site Internet et à la Plateforme, et peut librement
        réutiliser les idées, concepts, méthodes, savoir-faire, ou techniques se
        rapportant à la programmation ou au traitement des données qui est ou
        sera découvert ou mis au point à l’occasion de l’exécution de quelque
        service que ce soit dans le cadre des présentes.
        <br />
        <br />
        AiiNTENSE se réserve l’ensemble de ses droits afin d’agir contre
        l’Utilisateur, le Navigateur ou tout tiers en cas de violation des
        Droits de Propriété Intellectuelle dont il est titulaire.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        8. Données personnelles
      </div>
      <div className="justify">
        Chaque Partie s’engage à respecter la réglementation applicable en
        matière de protection des données personnelles, et notamment les
        dispositions de la loi n°78-17 du 6 janvier 1978 relative à
        l’informatique aux fichiers et aux libertés, telle que modifiée par le
        Règlement (UE) n°2016/679 du Parlement européen et du Conseil du 27
        avril 2016 relatif à la protection des personnes physiques à l’égard du
        traitement des données à caractère personnel et à la libre circulation
        de ces données (&laquo; RGPD &raquo;). Dans leurs relations, chaque
        Partie est seule responsable du dommage causé par tout manquement aux
        obligations qui lui incombent en vertu de la réglementation en matière
        de protection des données personnelles.
        <br />
        <br />
        Les autres éléments relatifs au traitement des données personnelles et
        de cookies au titre des présentes sont décrits et régis par les
        dispositions de la politique de confidentialité que l’Utilisateur et le
        Navigateur à consulter en cliquant sur le lien suivant :{" "}
        <a href="/personal-data-protection" className={styles.link}>
          https://d-open-clinics.aiintense.eu/personal-data-protection
        </a>
        .<br />
        <br />
        Dans le cadre de l’utilisation du Service, il est rappelé que{" "}
        <span className="text-italic">(i)</span> l’Utilisateur s’engage à
        s’assurer que le traitement des données personnelles réalisés via la
        Plateforme est conforme aux dispositions du RGPD notamment en matière de
        recueil du consentement des personnes concernées (patients),{" "}
        <span className="text-italic">(ii)</span> AiiNTENSE assure l’hébergement
        du Site Internet et de la Plateforme via OVH et Clever Cloud, hébergeur
        de données de santé (HDS).
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        9. Exclusion de garantie
      </div>
      <div className="justify">
        Sous réserve des dispositions du présent Article et des dispositions
        légales impératives applicables, AiiNTENSE décline expressément toute
        garantie expresse, tacite ou réglementaire portant sur tout aspect de la
        Plateforme et du Service, notamment et non limitativement, toute
        garantie de qualité marchande ou d’adéquation à usage particulier.
        L’Utilisateur reconnait que la fourniture du Service par AiiNTENSE et
        notamment l’accès et l’utilisation du Site Internet, de la Plateforme et
        de son Compte Personnel dépendent notamment du réseau internet dont le
        mauvais fonctionnement peut entraîner une discontinuité dans la
        fourniture du Service, indépendamment de la volonté d’AiiNTENSE.
        AiiNTENSE exclut toute responsabilité en cas de survenance de telles
        discontinuités dans la fourniture du Service et de l’accès à la
        Plateforme. AiiNTENSE exclut également toute responsabilité{" "}
        <span className="text-italic">(i)</span> en cas de panne ou
        dysfonctionnement du Site Internet, de la Plateforme et/ou du Compte
        Personnel trouvant son origine dans la mauvaise manipulation ou
        utilisation de l’Utilisateur ou du Navigateur,{" "}
        <span className="text-italic">(ii)</span> en cas de panne provoquée par
        l’intervention de l’Utilisateur, du Navigateur ou d’un tiers sur le Site
        Internet, la Plateforme ou Compte Personnel,{" "}
        <span className="text-italic">(iii)</span> en cas de dysfonctionnement
        du réseau téléphonique, <span className="text-italic">(iv)</span> en cas
        de mauvaise sauvegarde des données de l’Utilisateur ou du Navigateur,{" "}
        <span className="text-italic">(v)</span> en cas de non-respect des
        obligations de l’Utilisateur ou du Navigateur au titre des présentes.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        10. Limitation de responsabilité
      </div>
      <div className="justify">
        En tout état de cause, l’Utilisateur reconnait et accepte qu’AiiNTENSE
        est tenue, en application des présentes, à une obligation de moyens et
        s’engage à faire ses meilleurs efforts afin de respecter les engagements
        pris au titre des présentes et notamment afin d’assurer la fourniture et
        la continuité du Service, ainsi que l’accessibilité et le fonctionnement
        de la Plateforme et du Compte Personnel de l’Utilisateur.
        <br />
        <br />
        Il est rappelé à l’Utilisateur qu’AiiNTENSE dispose d’un simple rôle
        d’intermédiaire au titre des présentes, et que ses obligations se
        limitent à la mise à disposition d’un outil informatique (la Plateforme)
        permettant aux Utilisateurs d’échanger des données. Aussi, l’accès à la
        Plateforme et son utilisation, ainsi que la jouissance du Service, sont
        réalisés sous l’entière et exclusive responsabilité de l’Utilisateur. A
        ce titre, l’Utilisateur reconnait qu’il demeure seul et exclusivement
        responsable du contenu échangé via la Plateforme (notamment du contenu
        des demandes partagées par les Requérants et de tout élément de réponse
        partagé par les Experts lesquels formulent leurs comptes rendus sous
        leur seul responsabilité), et du respect de toutes dispositions{" "}
        <span className="text-italic">(i)</span> légales ou réglementaires
        applicables, notamment en matière de télémedecine, téléexpertise,
        partage de dossier médical et de toute données personnelles en ce
        compris les données de santé ou toute autre données sensibles et{" "}
        <span className="text-italic">(ii)</span> déontologique. AiiNTENSE ne
        pourra en aucun cas être tenue responsable dans ces conditions.
        <br />
        <br />
        L’Utilisateur reconnait et accepte qu’AiiNTENSE puisse restreindre,
        limiter ou suspendre l’accès à la Plateforme et le Service en suite
        d’une décision émanant d’une autorité administrative, arbitrale ou
        judiciaire, ou en raison de l’évolution de la réglementation applicable
        à l’activité d’AiiNTENSE ou de l’Utilisateur. AiiNTENSE ne pourra en
        aucun cas être tenue responsable dans ces conditions.
        <br />
        <br />
        L’Utilisateur reconnait et convient que{" "}
        <span className="text-italic">(i)</span> les virus, vers informatiques,
        chevaux de Troie ou autres données ou logiciels indésirables, ou{" "}
        <span className="text-italic">(ii)</span> les utilisateurs non autorisés
        (ex. : pirates informatiques) peuvent tenter d’accéder et endommager les
        données, ordinateurs ou réseaux de l’Utilisateur. AiiNTENSE ne pourra en
        aucun cas être tenue responsable de ces activités.
        <br />
        <br />
        AiiNTENSE ne peut en aucun cas engager sa responsabilité pour tout
        dommage spécial, indirect, ou conséquent incluant sans limitation la
        perte de chance de conclure tout contrat ou d’activité, la perte de
        profit ou de patientèle, la perte de données, la perte d’image encourus
        par l’Utilisateur au titre des présentes. Sauf préjudice corporel, faute
        intentionnelle ou grave, l’indemnité totale due par AiiNTENSE à
        l’Utilisateur en réparation de l’ensemble des préjudices subis au titre
        la fourniture du Service ne pourra dépasser la somme la plus élevée
        entre <span className="text-italic">(i)</span> l’ensemble des
        éventuelles sommes reçues par AiiNTENSE au titre des présentes, ou{" "}
        <span className="text-italic">(ii)</span> la somme totale de dix-mille
        euros (10.000 €).
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        11. Résiliation de l&apos;accès au service
      </div>
      <div className="justify">
        En cas de violation par l&apos;une ou l&apos;autre des Parties de
        l&apos;une quelconque de ses obligations prévues par les présentes
        Conditions Générales d’Utilisation, celles-ci peuvent être résiliées
        vingt (20) jours calendaires après une mise en demeure adressée par
        lettre recommandée avec accusé de réception par la Partie concernée, et
        mentionnant la déclaration de cette dernière, de son intention de se
        prévaloir du bénéfice de la présente clause.
        <br />
        <br />
        Conformément aux articles 1224 et 1225 du Code civil, les manquements
        suivants doivent notamment être considérés comme des manquements
        susceptibles d’entraîner la résiliation du Contrat : perte de la qualité
        d’Utilisateur telle que celle-ci est définie à l’Article 2.2 (incluant
        la perte, l’omission ou la suspension même temporaire de la qualité de
        médecin), le non-respect des conditions d’accessibilité (Article 3.1),
        le non-respect de l’utilisation du Compte Personnel (Article 3.2), la
        violation par l’Utilisateur des dispositions relatives au fonctionnement
        du Service (Article 3.2), le non-respect des conditions de réalisation
        du Service (Article 3.3), le non-respect de l’Article 4 et de toute
        réglementation applicable à l’Utilisateur dans le cadre de son activité
        professionnelle ou de l’utilisation du Service (les règles
        déontologiques, dispositions légales ou réglementaires applicables,
        notamment les dispositions du Code de la Santé Public), le manquement à
        l’obligation d’assurance (Article 5), la violation des Droits de
        Propriété Intellectuelle d’AiiNTENSE (Article 7), la violation par
        l’Utilisateur de la réglementation applicable en matière de données
        personnelles dans le cadre de l’utilisation du Service ou de la
        politique de confidentialité d’AiiNTENSE (Article 8), la violation des
        conditions d’utilisation du Site Internet par l’Utilisateur (Article 12)
        et plus généralement, un manquement dans l’exécution loyale des
        présentes.
        <br />
        <br />
        Le cas échéant, l’Utilisateur ne pourra plus bénéficier de l’accès à la
        Plateforme et du Service. Aussi, en cas de résiliation des présentes
        Conditions Générales d’Utilisation, AiiNTENSE retirera à l’Utilisateur
        l’ensemble des moyens d’accès à la Plateforme et au Service.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        12. Utilisation du site internet
      </div>
      <div className="justify">
        Tout utilisateur du Site Internet, qu’il soit Utilisateur ou simple
        tiers (le &laquo; Navigateur &raquo;), s’engage à utiliser le Site
        Internet dans le respect des présentes, de la bonne foi et de la
        législation en vigueur. Le Navigateur garantit être majeur et en mesure
        de comprendre les présentes Conditions Générales d’Utilisation. Toute
        utilisation frauduleuse, abusive, illicite ou contraire à la bonne foi
        du Site Internet de la part du Navigateur est strictement interdite.
        <br />
        <br />
        Le Navigateur s’interdit notamment{" "}
        <span className="text-italic">(i)</span> de reproduire, diffuser,
        communiquer publiquement, transformer ou modifier le Site Internet ou
        son contenus, sans l’autorisation préalable et écrite d’AiiNTENSE,{" "}
        <span className="text-italic">(ii)</span> de se livrer à toute activité
        illicite, frauduleuse, contraire au présente CGU, à la réglementation en
        vigueur ou à la bonne foi, ou susceptible d’empêcher ou d’entraver la
        correcte utilisation du Site Internet,{" "}
        <span className="text-italic">(iii)</span>
        d’insérer dans des pages internet, des plateformes, des forums, des
        chats, des blogs ou tous réseaux sociaux tiers, tout lien permettant de
        relier ou de reproduire le Site Internet ou son contenu, sauf si le lien
        renvoie exclusivement et directement vers la page principale du Site
        Internet, sans le reproduire de quelque façon que ce soit,{" "}
        <span className="text-italic">(iv)</span> de relier ou de reproduire le
        Site Internet ou son contenu à partir de sites internet, plateformes,
        forums, chats, blogs ou tous réseaux sociaux, qui seraient contraires à
        la loi, offensifs ou porteraient atteinte à la dignité humaine, aux
        droits fondamentaux, à AiiNTENSE et notamment à son image ou sa
        réputation, ou susceptibles d’être considérés comme constitutifs d’un
        délit.
        <br />
        <br />
        Les sites internet, plateformes, forums, chats, blogs ou réseaux sociaux
        tiers présentant des liens vers le Site Internet devront informer
        clairement du fait qu&apos;ils ne détiennent pas l&apos;autorisation
        d’AiiNTENSE et qu&apos;ils ne se trouvent pas sous la supervision de
        celui-ci, ainsi que du fait que le lien concerné ne comporte, ne suppose
        ou n&apos;implique quelque association que ce se soit entre AiiNTENSE et
        les sites internet, les plateformes, les forums, les chats, les blogs ou
        les réseaux sociaux tiers présentant de tels liens vers le Site
        Internet. En tout état de cause, tout lien visant le Site Internet devra
        déboucher directement sur la page d&apos;accueil ou de démarrage du Site
        Internet, par le biais d&apos;un clic sur l&apos;adresse URL de ce
        dernier et englober la totalité de l&apos;écran de la page
        d&apos;accueil ou de démarrage du Site Internet.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        13. Force majeure
      </div>
      <div className="justify">
        Pour les besoins de cet Article, &laquo; Evènement de Force Majeure
        &raquo; a le sens qui lui est donné à l’Article 1218 du Code civil. En
        outre, seront notamment considérés comme un Evènement de Force Majeure :
        les catastrophes naturelles, guerres, explosions, incendies,
        inondations, tempêtes, tremblements de terre, insurrections, actes
        terroristes, émeutes, troubles civils, rébellions, grèves, lock-out ou
        conflits sociaux autres que grève, toute mesure de confinement tiré d’un
        état d’urgence sanitaire, impliquant AiiNTENSE.
        <br />
        <br />
        Si un Evènement de Force Majeure rendant impossible l’exécution de ses
        obligations par AiiNTENSE à l’égard de l’Utilisateur survient, AiiNTENSE
        ne sera pas responsable à l’égard de l’Utilisateur et devra être
        déchargée de ses obligations dans la mesure où sa capacité à remplir ses
        obligations a été affectée par un Evènement de Force Majeure.
        <br />
        <br />
        Si les conséquences de l’Evènement de Force Majeure sur AiiNTENSE
        rendent impossible pour elle l’exécution d’une part substantielle de ses
        obligations au titre des présentes Conditions Générales d’Utilisation
        pour une période d’au moins soixante (60) jours consécutifs, chacune des
        Parties pourra, par notification écrite, mettre fin à l’accès à la
        Plateforme et au Service, et à l’application des présentes Conditions
        Générales d’Utilisation soit pour partie ou en totalité, avec effet
        immédiat et sans responsabilité à l’égard de la Partie Affectée.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        14. Sous-traitance
      </div>
      <div className="justify">
        L’Utilisateur reconnait et accepte que tout ou partie du Service objet
        des présentes Conditions Générales d’Utilisation, pourra être
        sous-traité par AiiNTENSE à tout prestataire de son choix.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        15. Intégralité
      </div>
      <div className="justify">
        Sauf disposition contraire expresse, les présentes Conditions Générales
        d’Utilisation constituent la totalité de l’accord entre les Parties et
        remplacent tous les contrats précédents entre les Parties relatifs à la
        Plateforme et au Service. Aucune disposition de cet Article ne devra
        être comprise comme exclusive ou limitative de responsabilité en cas de
        fraude.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        16. Loi applicable et juridiction
      </div>
      <div className="justify">
        Les présentes Conditions Générales d’Utilisation et les opérations qui
        en découlent entre les Parties ou impliquant le Navigateur, sont régies
        par et soumises au droit français.
        <br />
        <br />
        Tous les litiges auxquels les présentes Conditions Générales
        d’Utilisation pourraient donner lieu, concernant tant leur validité,
        leur interprétation, leur exécution, leur résolution, leurs conséquences
        et leurs suites et qui n’auraient pas pu être résolus à l’amiable entre
        les Parties, seront soumis aux tribunaux compétents d’Evry,
        exclusivement compétent.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        17. Capacité des parties
      </div>
      <div className="justify">
        Les Parties déclarent qu’elles ont la capacité, le droit, le pouvoir et
        l’autorité nécessaire et ont pris toutes les mesures nécessaires pour
        utiliser la Plateforme, bénéficier du Service et jouir des droits et
        exécuter leurs obligations respectives au titre des présentes.
        L’Utilisateur atteste expressément être une personne capable au sens des
        Articles 1145 et suivants du Code civil. Chaque Partie déclare à l’autre
        que ni la signature, ni l’exécution des présentes dispositions ne
        contrevient ou ne contreviendra à aucune obligation ou contrat qu’elle
        pourrait avoir avec une tierce partie.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        18. Absence de renonciation
      </div>
      <div className="justify">
        L’absence ou le retard dans l’exercice par AiiNTENSE de l’un de ses
        droits ou recours au titre des présentes ne constitue pas une
        renonciation au droit ou recours considéré, pas plus qu’il ne pourra
        empêcher ou restreindre l’exercice ultérieur de celui-ci ou de tout
        autre droit ou recours. L’exercice unique ou partiel d’un tel droit ou
        recours ne pourra faire obstacle ou limiter l’exercice ultérieur de ce
        droit ou recours.
      </div>
      <div
        className={`margin-top-2 mb-2 text-bold text-uppercase ${styles.subTitle}`}
      >
        19. Notifications
      </div>
      <div className="justify">
        Toute correspondance à l’attention d’AiiNTENSE (y compris en cas de
        réclamations) devra être transmise par courriel à l’adresse électronique
        suivante : [support@aiintense.eu], et/ou par lettre recommandée avec
        accusé de réception à l’adresse postale suivante : Pépinière Genopole
        Entreprises - Genopole Campus 3 - 4, rue Pierre Fontaine - 91058 EVRY
        CEDEX - France.
      </div>
    </>
  );
};
