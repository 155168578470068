export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
): any => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, waitFor);
  };
};

export const debouncePromise = <T extends (...args: any[]) => any>(
  callBack: T,
  waitFor: number
): any => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<T>) =>
    new Promise<T>((resolve) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        resolve(callBack(...args));
      }, waitFor);
    });
};
