import React, { useEffect, useState } from "react";
import { HealthProfessionalUser } from "../../../domain/User";
import Modal from "../../Modal";
import { errorToast } from "../../../utils/Toast";
import { EditNewUser } from "./EditNewUser";
import { ModalFooter } from "./ModalFooter";

interface Props {
  show: boolean;
  userToAdd: HealthProfessionalUser | undefined;
  onClose: () => any;
  onAddUser: (g: HealthProfessionalUser) => any;
  filterUsersRpps?: string[];
}

export const AddNewUser: React.FC<Props> = (props: Props) => {
  const [newUser, setNewUser] = useState<HealthProfessionalUser | undefined>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const addNewUser = (): void => {
    if (newUser) {
      if (!isEmailValid) {
        errorToast("L'adresse mail saisie n'est pas valide");
        return;
      }
      props.onAddUser(newUser);
      closeAndResetGuest();
    }
  };

  useEffect(() => {
    setNewUser(props.userToAdd);
  }, [props.userToAdd]);

  const closeAndResetGuest = (): any => {
    setNewUser(undefined);
    props.onClose();
  };

  return (
    <Modal
      show={props.show}
      onClose={closeAndResetGuest}
      title={"Ajouter un professionnel de santé depuis l’annuaire"}
      containerStyle={{ maxWidth: "1000px" }}
      footer={
        <>
          <div className="footer-line" />
          <ModalFooter
            titleButtonRight={"Valider les modifications"}
            onClickButtonRight={addNewUser}
            titleButtonLeft={"Annuler"}
            onClickButtonLeft={closeAndResetGuest}
          />
        </>
      }
    >
      <div className="container">
        {newUser && (
          <EditNewUser
            newUser={newUser}
            setNewUser={setNewUser}
            setIsEmailValid={setIsEmailValid}
          />
        )}
      </div>
    </Modal>
  );
};
