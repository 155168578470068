import React, { useState } from "react";
import "./Admin.css";
import history from "../../utils/history";
import { AdminViewOrganizationDetailsTab } from "./AdminViewOrganizationDetailsTab";
import { AdminViewOrganizationTab } from "./AdminViewOrganizationTab";
import { BackButton } from "../Shared/BackButton";

enum OrganizationTab {
  GENERAL = "general",
  DETAIL = "detail",
}

export const AdminViewOrganizations: React.FC<any> = () => {
  const [selectedTab, setSelectedTab] = useState<OrganizationTab>(
    history.location.pathname.split("/").pop() === OrganizationTab.DETAIL
      ? OrganizationTab.DETAIL
      : OrganizationTab.GENERAL
  );

  return (
    <>
      <div className={"row text-centered"}>
        <BackButton redirectsTo={`/admin/organizations`} />
        <div className="m-auto page-title">{"Organisation"}</div>
      </div>
      <div className="row space-content">
        <button
          className={
            "request-menu " +
            (selectedTab === OrganizationTab.GENERAL
              ? "request-menu-active"
              : "")
          }
          onClick={(): any => {
            setSelectedTab(OrganizationTab.GENERAL);
          }}
        >
          Générales
        </button>
        <button
          className={
            "request-menu " +
            (selectedTab === OrganizationTab.DETAIL
              ? "request-menu-active"
              : "")
          }
          onClick={(): any => {
            setSelectedTab(OrganizationTab.DETAIL);
          }}
        >
          Détails
        </button>
      </div>
      {selectedTab === OrganizationTab.GENERAL && (
        <div className="bg-light-blue-1 flex-1">
          <AdminViewOrganizationTab />
        </div>
      )}
      {selectedTab === OrganizationTab.DETAIL && (
        <div className="bg-light-blue-1 flex-1">
          <AdminViewOrganizationDetailsTab />
        </div>
      )}
    </>
  );
};
