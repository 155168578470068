import React, { useContext, useEffect, useRef, useState } from "react";
import { User } from "../../../domain/User";
import { useForm } from "react-hook-form";
import { Profile } from "../../../domain/Profile";
import { AvailabilityLine } from "./AvailabilityLine";
import { Availability, dayToString } from "../../../domain/Availability";
import { ActionTypes, AppContext } from "../../../utils/state";
import { errorToast, successToast } from "../../../utils/Toast";
import {
  createUserAvailabilities,
  getProfile,
  getUser,
} from "../../../services/UserService";
import useAvailabilitiesCheck from "./useAvailabilitiesCheck";
import { Facility } from "../../../domain/Facility";
import { PageHeaderWithTitle } from "../../Shared/PageHeaderWithTitle";

export const ProfileAvailability: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const [facilities, setFacilities] = useState<Facility[]>();
  const { handleSubmit } = useForm<Profile>();
  const days = useRef<number[]>([0, 1, 2, 3, 4, 5, 6]);
  const [availabilities, setAvailailities] = useState<
    Map<number, Availability[]>
  >(
    (appContext.state.user.calendar?.availabilities ?? []).reduce(
      (accumulator, target): any => {
        const a = accumulator.get(target.day);
        const y = [...(a ?? []), target];
        accumulator.set(target.day, y);
        return accumulator;
      },
      new Map<number, Availability[]>()
    )
  );
  const errors = useAvailabilitiesCheck(days.current, availabilities);

  useEffect(() => {
    getUser(appContext.state.user.id).then((res) => {
      if (res instanceof Error) {
        console.error(res);
        return;
      }
      setFacilities(res.facilities);
    });
  }, [appContext.state.user.id]);

  const onAddAvailability = (dayNumber: number): any => {
    const oldAvailability = availabilities.get(dayNumber) ?? [];
    const lastAvailibilityLine = [...oldAvailability].pop();
    const crypto = window.crypto;
    const id = new Uint32Array(1);
    const newAvailability: Availability = {
      id: crypto.getRandomValues(id).toString(),
      startTime: lastAvailibilityLine?.endTime
        ? lastAvailibilityLine.endTime <= 23
          ? lastAvailibilityLine.endTime
          : 0
        : 8,
      endTime: lastAvailibilityLine?.endTime
        ? lastAvailibilityLine.endTime + 1 <= 24
          ? lastAvailibilityLine.endTime + 1
          : 1
        : 18,
      day: dayNumber,
    };
    availabilities.set(
      dayNumber,
      [...oldAvailability, newAvailability].sort(
        (a, b) => a.startTime - b.startTime
      )
    );
    setAvailailities(new Map(availabilities));
  };

  const onTimeChange = (updatedAvailability: Availability): any => {
    const y = availabilities.get(updatedAvailability.day) ?? [];
    const indexOldAvailability = availabilities
      .get(updatedAvailability.day)
      ?.findIndex((availability) => availability.id === updatedAvailability.id);
    if (indexOldAvailability !== undefined) {
      y[indexOldAvailability] = updatedAvailability;
      y.sort((a, b) => a.startTime - b.startTime);
      availabilities.set(updatedAvailability.day, y);
      setAvailailities(new Map(availabilities));
    }
  };

  const onTimeDelete = (deletedAvailability: Availability): any => {
    if (deletedAvailability.id !== undefined) {
      const filtered = availabilities
        .get(deletedAvailability.day)
        ?.filter((a) => a.id !== deletedAvailability.id);
      if (filtered) {
        availabilities.set(deletedAvailability.day, filtered);
        setAvailailities(new Map(availabilities));
      }
    }
  };

  const onSubmit = async (): Promise<void> => {
    const userUpdated: User = {
      ...appContext.state.user,
      calendar: {
        availabilities: Array.from(availabilities.values()).flat(),
      },
    };
    const res = await createUserAvailabilities(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition des disponibilités");
    } else {
      successToast("Disponibilités mises à jour");
      getProfile().then((updatedUser: User | Error) => {
        if (updatedUser instanceof Error) {
          errorToast("Erreur lors de la récupération des disponibilités");
        } else {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
        }
      });
    }
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Disponibilités"}
        backButtonRedirectsTo={`/profile`}
      />
      <div className="page-container">
        <div className="mr-auto ml-auto responsive-container profile-white-container text-left">
          <label className="form-label mr-2" htmlFor="patient-name-input">
            Indiquez ici vos disponibilités récurrentes. Si vous le souhaitez
            vous pouvez aussi choisir des créneaux pour des dates spécifiques.
          </label>
          <button className="specific-btn w-100 tooltip" disabled={true}>
            Choisir une semaine spécifique
            <i className="icon icon-arrow-right" />
            <span className="tooltiptext">Fonctionnalité à venir</span>
          </button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              {days.current.map((dayNumber) => (
                <React.Fragment key={dayNumber}>
                  <div className="w-100 mt-2">
                    <span className="text-bold">{dayToString(dayNumber)}</span>
                  </div>
                  {availabilities.get(dayNumber)?.map((availability) => (
                    <div className="w-100" key={availability.id}>
                      <AvailabilityLine
                        error={errors.get(dayNumber)?.get(availability.id)}
                        availability={availability}
                        facilities={facilities}
                        onChange={onTimeChange}
                        onDelete={(): any => {
                          onTimeDelete(availability);
                        }}
                      />
                    </div>
                  ))}
                  {(availabilities.get(dayNumber) === undefined ||
                    (availabilities.get(dayNumber)?.length as number) < 24) && (
                    <button
                      type="button"
                      className="add-availability-btn"
                      onClick={(): any => onAddAvailability(dayNumber)}
                    >
                      + Ajouter un créneau
                    </button>
                  )}
                </React.Fragment>
              ))}
              <div className="row mt-2 ml-auto mr-auto w-70">
                <button
                  className="style-btn neuro-btn w-100"
                  type="submit"
                  disabled={errors.size > 0}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
