import React, { useEffect, useState } from "react";
import {
  createFacilityInvoiceInfo,
  sendInvoicingInfoByEmail,
  updateFacilityEmail,
} from "../../services/FacilityService";
import styles from "./Invoicing.module.css";
import hopitalIcon from "../../assets/profile-icon/icon-hopital.svg";
import { Label } from "../Shared/Label";
import { SendingStatus } from "../../utils/SendingStatus";
import { errorToast, successToast } from "../../utils/Toast";
import EditIcon from "../../assets/edit-icon.svg";
import { InvoicingInfo } from "../../domain/InvoicingInfo";
import { getCurrentYear } from "../../utils/time";
import { getCurrentMonth } from "../../domain/Month";

interface Props {
  invoicingInfo: InvoicingInfo;
  selectedMonth: number;
  selectedYear: number;
  isChecked: boolean;
  addOrRemoveInvoiceToSelect: (InvoicingInfo: InvoicingInfo) => void;
  updateInvoicingEmail: (email: string, invoicingInfo: string) => void;
  updateSendedStatus: (invoicingInfo: string) => void;
}

export const InvoiceInfoCard: React.FC<Props> = (props: Props) => {
  const { invoicingInfo, selectedMonth, selectedYear } = props;
  const [status, setStatus] = useState<SendingStatus>(SendingStatus.WAITING);
  const [inputEmail, setInputEmail] = useState<string | undefined>(
    invoicingInfo.email
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [disabledButton, setDisabledSendButton] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const getLabel = (newStatus: SendingStatus): string => {
    if (newStatus === SendingStatus.SENT) {
      return "Eléments de facturation envoyés";
    } else {
      return "En attente de facturation";
    }
  };

  useEffect(() => {
    setChecked(props.isChecked);
    if (invoicingInfo.invoiceInfoSent) {
      setStatus(SendingStatus.SENT);
    } else {
      setStatus(SendingStatus.WAITING);
    }
  }, [invoicingInfo.invoiceInfoSent, props.isChecked]);

  useEffect(() => {
    setDisabledSendButton(false);
    if (
      (selectedMonth >= getCurrentMonth() &&
        selectedYear === getCurrentYear()) ||
      selectedYear > getCurrentYear() ||
      inputEmail === "" ||
      editMode
    ) {
      setDisabledSendButton(true);
    }
  }, [
    invoicingInfo,
    invoicingInfo.email,
    inputEmail,
    editMode,
    selectedMonth,
    selectedYear,
  ]);

  const updateEmail = async (): Promise<void> => {
    if (inputEmail && inputEmail !== "") {
      let res;
      if (!invoicingInfo.id) {
        res = await createFacilityInvoiceInfo(
          invoicingInfo.facility.id,
          selectedMonth,
          selectedYear,
          inputEmail
        );
        if (!(res instanceof Error)) {
          invoicingInfo.id = res;
        }
      } else {
        res = await updateFacilityEmail(invoicingInfo.id, inputEmail);
      }
      if (res instanceof Error) {
        errorToast("Erreur lors de l'édition de l'email");
      } else {
        props.updateInvoicingEmail(inputEmail, invoicingInfo.id);
      }
    } else {
      errorToast("Email vide");
    }
  };

  const sendEmail = async (): Promise<void> => {
    setStatus(SendingStatus.SENT);
    await sendInvoicingInfoByEmail(
      invoicingInfo.id,
      invoicingInfo.facility.id,
      selectedMonth,
      selectedYear
    ).then((res: any | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur lors de l'envoi de l'email");
      } else {
        props.updateSendedStatus(invoicingInfo.id);
        successToast(
          "Vous avez bien envoyé les informations de facturation à " +
            invoicingInfo.facility.name
        );
      }
    });
  };

  return (
    <>
      <div className={"card " + styles.card} key={checked.toString()}>
        <div className="flex-row">
          <label className={"form-checkbox c-hand " + styles.centerVertically}>
            <input
              key={invoicingInfo.id}
              value={invoicingInfo.id}
              checked={checked}
              type="checkbox"
              onClick={(): void => {
                props.addOrRemoveInvoiceToSelect(invoicingInfo);
              }}
              disabled={props.invoicingInfo.email === ""}
            />
            <i className={"form-icon " + styles.checkBox} />
          </label>
          <img
            className={styles.facilityIcon}
            src={hopitalIcon}
            alt="icon hopital"
          />
          <div className={styles.facilityName}>
            <div className={styles.secondaryGrey}>
              {invoicingInfo.facility.facilityKind &&
              invoicingInfo.facility.facilityKind?.length > 40
                ? invoicingInfo.facility.facilityKind.slice(0, 40) + "..."
                : invoicingInfo.facility.facilityKind}
            </div>
            <div className="text-bold">
              {invoicingInfo.facility.name &&
              invoicingInfo.facility.name?.length > 30
                ? invoicingInfo.facility.name.slice(0, 30) + "..."
                : invoicingInfo.facility.name}
            </div>
          </div>
          <div className={"column " + styles.centerVertically}>
            <Label color={status} type={""}>
              {getLabel(status)}
            </Label>
          </div>
          <div className={styles.verticalLine} />
          <div className={"column " + styles.centerVertically}>
            <div className={"mr-1 " + styles.detailsButton}>
              <span className={"text-black text-bold"}>
                {invoicingInfo.requestNumber} demande
                {invoicingInfo.requestNumber > 1 && "s"}
              </span>
              <br />
              {/* <a className={styles.detailsLink} href="/invoicing">
                Voir le détail
              </a> */}
            </div>
          </div>
          <div className={"column " + styles.centerVertically}>
            <div className={"row mb-1"}>
              <input
                name="invoice-email"
                className="form-input "
                type="text"
                onChange={(e): any => {
                  e.preventDefault();
                  setInputEmail(e.target.value);
                  invoicingInfo.email = e.target.value;
                }}
                value={invoicingInfo.email}
                placeholder="Veuillez entrer un email"
                disabled={!editMode}
              />
              <button
                className={styles.editIcon}
                onClick={(): void => {
                  setEditMode(!editMode);
                  editMode && updateEmail();
                }}
              >
                {editMode ? (
                  <i className={"fas fa-save " + styles.primaryColor} />
                ) : (
                  <img alt="icon-edit" src={EditIcon} />
                )}
              </button>
            </div>
            <button
              className={"btn neuro-btn ml-1 " + styles.sendButton}
              onClick={sendEmail}
              disabled={disabledButton}
            >
              Envoyer les éléments de facturation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
