import React, { useCallback, useContext, useEffect, useState } from "react";
import "./CondensedOpinionRequest.css";
import {
  acceptRequestByExpert,
  getOpinionRequestMissingFields,
  declineRequestByExpert,
  getOpinionRequest,
  setLastViewedDate,
  setOpinionRequestExpertsAndOrganizations,
  setOpinionRequestReply,
  submitOpinionRequest,
  validateOpinionRequest,
  setDraftOpinionRequestReply,
} from "../../services/OpinionRequestService";
import OpinionRequest, {
  isEmergencyRequest,
  SharePermission,
} from "../../domain/OpinionRequest";
import { useParams } from "react-router-dom";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { errorToast, successToast } from "../../utils/Toast";
import { AppContext } from "../../utils/state";
import { User } from "../../domain/User";
import { ExpertReply } from "../../domain/ExpertReply";
import { DeclineMotive } from "./ExpertDeclineOpinionRequest";
import history from "../../utils/history";
import { CondensedOpinionRequest } from "./CondensedOpinionRequest";
import WaitingIllustration from "../../assets/illustrations/waiting_validation.svg";
import { RequestStatus } from "../../domain/RequestStatus";
import { ErrorResponse } from "../../domain/ErrorManagement";
import {
  buildMandatoryFieldsMissing,
  OpinionRequestMandatoryFieldsStatus,
} from "../../utils/OpinionRequestMandatoryFieldsStatus";
import { ModalMissingMandatoryFields } from "../OpinionRequestForm/ModalMissingMandatoryFields";
import { SelectFacilityLinkedToRequest } from "./SelectFacilityLinkedToRequest";
import { Organization } from "../../domain/Organization";

export const CondensedOpinionRequestContainer: React.FC<any> = () => {
  const currentUser = useContext(AppContext).state.user;
  const { id } = useParams<any>();
  const requestId = id;
  const [readOnly, setReadOnly] = useState<boolean>();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    id ? LoadingStatus.LOADING : LoadingStatus.SUCCESS
  );
  const [request, setRequest] = useState<OpinionRequest>();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [showMandatoryParamsModal, setShowMandatoryParamsModal] = useState<
    boolean
  >(false);
  const [showSelectFacilityModal, setShowSelectFacilityModal] = useState<
    boolean
  >(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<
    OpinionRequestMandatoryFieldsStatus
  >({
    patientConsent: false,
    emergencyLevel: false,
    patientBirthday: false,
    primaryDiseases: false,
    motive: false,
    healthPathwayContacts: false,
  });
  const isReadOnly = (opinionRequest: OpinionRequest, user: User): boolean => {
    return !(
      opinionRequest.status !== RequestStatus.CLOSED &&
      opinionRequest.participants &&
      opinionRequest.participants.some(
        (p) =>
          p.userId === user.id &&
          (p.permission === SharePermission.EDIT ||
            p.permission === SharePermission.ADMIN)
      )
    );
  };

  const refreshOpinionRequest = useCallback(async () => {
    const res = await getOpinionRequest(requestId);
    setReadOnly(true);
    if (res instanceof Error) {
      setLoadingStatus(LoadingStatus.ERROR);
      if (res.message !== "User is not validated") {
        setIsValidated(true);
        errorToast("Échec du chargement");
      }
    } else {
      setIsValidated(true);
      setReadOnly(isReadOnly(res, currentUser));
      setLoadingStatus(LoadingStatus.SUCCESS);
      setRequest(res);
    }
  }, [currentUser, requestId]);

  useEffect(() => {
    if (!requestId) {
      setReadOnly(false);
      return;
    }
    refreshOpinionRequest();
  }, [requestId, currentUser.id, refreshOpinionRequest]);

  useEffect(() => {
    if (requestId && currentUser) {
      setLastViewedDate(requestId);
    }
  }, [requestId, currentUser]);

  useEffect(() => {
    if (isSubmitting) {
      submitRequest();
    }
  }, [isSubmitting]);

  const submitRequest = async (): Promise<void> => {
    if (!request) {
      return;
    }
    const submittedRequest = await submitOpinionRequest(request);
    if (
      submittedRequest instanceof ErrorResponse &&
      submittedRequest.code === "MISSING_MANDATORY_FIELDS"
    ) {
      const missingFieldsList = await getOpinionRequestMissingFields(requestId);
      if (missingFieldsList instanceof Error) {
        errorToast(
          "Erreur lors de la récupération des champs obligatoires manquants"
        );
      } else {
        if (missingFieldsList.length > 0) {
          setMissingFields(buildMandatoryFieldsMissing(missingFieldsList));
          setShowMandatoryParamsModal(true);
        }
      }
    } else if (
      submittedRequest instanceof ErrorResponse &&
      submittedRequest.message === "requester facility id is missing"
    ) {
      setShowSelectFacilityModal(true);
      return;
    } else if (submittedRequest instanceof ErrorResponse) {
      errorToast("Erreur lors de la soumission de la demande");
    } else {
      await refreshOpinionRequest();
      successToast("Demande soumise");
    }
  };

  const validateRequest = async (): Promise<void> => {
    const validatedRequest = await validateOpinionRequest(requestId);
    if (validatedRequest instanceof Error) {
      errorToast("Erreur lors de la validation de la demande");
    } else {
      await refreshOpinionRequest();
      successToast("Demande validée");
    }
  };

  const setAssignedExpertsAndOrganizations = async (
    experts: User[],
    organizations: Organization[]
  ): Promise<boolean> => {
    const expertRequest = await setOpinionRequestExpertsAndOrganizations(
      experts,
      organizations,
      requestId
    );
    if (expertRequest instanceof Error) {
      if (
        expertRequest.message ===
        "ERROR_CANNOT_DELETE_EXPERT_WHO_HAS_ALREADY_ACCEPTED_REQUEST"
      ) {
        errorToast(
          "Vous ne pouvez pas supprimer un expert ayant accepté de répondre à la demande",
          { autoClose: 5000 }
        );
      } else {
        errorToast(
          "Erreur lors de l'affectation des experts et/ou organisations"
        );
      }
      return false;
    } else {
      await refreshOpinionRequest();
      successToast("Liste des experts/organisations mise à jour");
      return true;
    }
  };

  const setExpertReply = async (reply: ExpertReply): Promise<void> => {
    const replyRequest = await setOpinionRequestReply(reply, requestId);
    if (replyRequest instanceof Error) {
      errorToast("Erreur lors de la sauvegarde de la réponse");
    } else {
      await refreshOpinionRequest();
      successToast("Réponse enregistrée, demande clôturée");
    }
  };

  const setDraftExpertReply = async (
    draftReply: ExpertReply
  ): Promise<void> => {
    const draftReplyRequest = await setDraftOpinionRequestReply(
      draftReply,
      requestId
    );
    if (draftReplyRequest instanceof Error) {
      errorToast("Erreur lors de la sauvegarde du brouilon de la réponse");
    } else {
      await refreshOpinionRequest();
      successToast("Brouillon de réponse enregistrée");
    }
  };

  const acceptRequest = async (billingfacilityId: string): Promise<void> => {
    const res = await acceptRequestByExpert(requestId, billingfacilityId);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'acceptation de la demande");
    } else {
      await refreshOpinionRequest();
      successToast("Vous avez accepté cette demande.");
    }
  };

  const declineRequest = async (
    comment: string,
    motive: DeclineMotive
  ): Promise<void> => {
    const res = await declineRequestByExpert(requestId, comment, motive);
    if (res instanceof Error) {
      errorToast("Erreur lors du refus de la demande");
    } else {
      history.push("/");
      successToast("Vous avez refusé cette demande");
    }
  };

  return (
    <>
      {!isValidated && loadingStatus === LoadingStatus.ERROR ? (
        <div className="row flex-col centered-content">
          <img
            src={WaitingIllustration}
            className="w-60"
            alt="illustration waiting"
          />
          <span className="mt-2 text-dark-grey">
            Votre compte est en cours de validation par un administrateur
          </span>
        </div>
      ) : loadingStatus === LoadingStatus.SUCCESS ? (
        <>
          <CondensedOpinionRequest
            request={request}
            readOnly={readOnly}
            refresh={(): any => refreshOpinionRequest()}
            isSubmitting={isSubmitting}
            submitRequest={(): any => submitRequest()}
            validateRequest={(): any => validateRequest()}
            setAssignedExpertsAndOrganizations={(
              experts: User[],
              organizations: Organization[]
            ): any =>
              setAssignedExpertsAndOrganizations(experts, organizations)
            }
            setExpertReply={(reply: ExpertReply): any => setExpertReply(reply)}
            setDraftExpertReply={(draftReply: ExpertReply): any =>
              setDraftExpertReply(draftReply)
            }
            acceptRequest={(facilityId: string): any =>
              acceptRequest(facilityId)
            }
            declineRequest={(comment: string, motive: DeclineMotive): any =>
              declineRequest(comment, motive)
            }
            updateRequest={refreshOpinionRequest}
          />
          {showMandatoryParamsModal && (
            <ModalMissingMandatoryFields
              requestId={requestId ? requestId : ""}
              isEmergencyRequest={isEmergencyRequest(request)}
              mandatoryFieldsStatus={missingFields}
              key={"mandatory_fields_missing_" + requestId}
              goToPage={(page) => {
                history.push(`/requests/${requestId}?page=${page}`);
              }}
              onClose={(): any => {
                setShowMandatoryParamsModal(false);
              }}
            />
          )}
          {showSelectFacilityModal && (
            <SelectFacilityLinkedToRequest
              modalType={"SUBMIT"}
              onClose={(): any => setShowSelectFacilityModal(false)}
              onSubmit={(requesterFacilityId: string): any => {
                if (request) {
                  setRequest({
                    ...request,
                    requesterFacilityId: requesterFacilityId,
                  });
                  setIsSubmitting(true);
                }
              }}
            />
          )}
        </>
      ) : (
        <div>Chargement...</div>
      )}
    </>
  );
};
