import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./Invoicing.module.css";
import { InvoicingForm } from "./InvoicingForm";
import { InvoiceHistoryCard } from "./InvoiceHistoryCard";
import { getCurrentYear } from "../../utils/time";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import history from "../../utils/history";
import { Invoice } from "../../domain/Invoice";
import { toast } from "react-toastify";
import { AppContext } from "../../utils/state";
import {
  getAllInvoicesByMonth,
  viewPdfInvoice,
} from "../../services/InvoicingService";
import { ModalInfo } from "../Shared/ModalInfo";
import { UserStatus } from "../../domain/UserStatus";
import { Month, getMonthName, getCurrentMonth } from "../../domain/Month";

export const Invoicing: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const [createInvoicing, setCreateInvoicing] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<Month>(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState<number>(getCurrentYear);
  const [allInvoices, setAllInvoices] = useState<Invoice[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const updateInvoiceList = useCallback(() => {
    const getInvoicesByMonth = async (): Promise<any> => {
      await getAllInvoicesByMonth(selectedMonth, selectedYear).then(
        (res: Invoice[] | Error) => {
          if (res instanceof Error) {
            toast.error("Erreur durant le chargement des factures");
          } else {
            setAllInvoices(res);
          }
        }
      );
    };
    getInvoicesByMonth();
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (!hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
      history.push("/forbidden");
    } else {
      updateInvoiceList();
      setSearchValue("");
    }
  }, [
    appContext.state.user.roles,
    selectedMonth,
    selectedYear,
    updateInvoiceList,
  ]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredInvoices(allInvoices);
    } else {
      setFilteredInvoices(
        allInvoices.filter(
          (invoice) =>
            invoice.client.name
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            invoice.client.companyName
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, allInvoices]);

  const previousMonth = (): void => {
    // if current month is january, selectedMonth = 1
    if (selectedMonth == 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const nextMonth = (): void => {
    // if current month is december, selectedMonth = 12
    if (selectedMonth == 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const previousYear = (): void => {
    setSelectedYear(selectedYear - 1);
  };

  const nextYear = (): void => {
    setSelectedYear(selectedYear + 1);
  };

  return (
    <div className="text-centered">
      <button
        className="btn neuro-btn rounded-btn btn-big"
        onClick={(): any => setCreateInvoicing(true)}
        disabled={appContext.state.user.status !== UserStatus.VALIDATED}
      >
        Créer une nouvelle facture
      </button>
      {createInvoicing && (
        <InvoicingForm
          onClose={(): any => setCreateInvoicing(false)}
          showWarningModal={(): any => setShowWarningModal(true)}
          updateInvoicesList={(): any => {
            setSelectedMonth(getCurrentMonth());
            setSelectedYear(getCurrentYear());
            updateInvoiceList();
          }}
        />
      )}
      <div
        className={`col-10 col-sm-auto col-mx-auto w-70 ${styles.invoicingPageContainer}`}
      >
        <div className={`text-left ${styles.marginBottom10}`}>
          <span className={styles.pageTitle}>Historique des factures</span>
        </div>
        <div className="row">
          <div className={`flex-col w-20 ${styles.datesContainer}`}>
            <div className="row">
              <div className="flex-col">
                <button
                  onClick={nextMonth}
                  id="next-month"
                  className={styles.arrowButton}
                >
                  <i className="icon icon-arrow-up neuro-primary" />
                </button>
                <div className={styles.month}>
                  {getMonthName(selectedMonth).toUpperCase()}
                </div>
                <button
                  onClick={previousMonth}
                  id="previous-month"
                  className={styles.arrowButton}
                >
                  <i className="icon icon-arrow-down neuro-primary" />
                </button>
              </div>
              <div className="flex-col">
                <button
                  onClick={nextYear}
                  id="next-year"
                  className={styles.arrowButton}
                >
                  <i className="icon icon-arrow-up neuro-primary" />
                </button>
                <div className={styles.year}>{selectedYear}</div>
                <button
                  onClick={previousYear}
                  id="previous-year"
                  className={styles.arrowButton}
                >
                  <i className="icon icon-arrow-down neuro-primary" />
                </button>
              </div>
            </div>
          </div>
          <div className={`flex-col w-80 ${styles.searchBarContainer}`}>
            <input
              type="text"
              value={searchValue}
              onChange={(e): any => setSearchValue(e.target.value)}
              className={styles.searchBar}
              placeholder="Rechercher par client ou raison sociale..."
            />
          </div>
        </div>
        {filteredInvoices.length > 0 ? (
          filteredInvoices.map((invoice) => {
            return <InvoiceHistoryCard key={invoice.id} invoice={invoice} />;
          })
        ) : (
          <div className={styles.emptyCard}>
            <span className="h5 text-black">Aucune facture trouvée</span>
          </div>
        )}
      </div>
      {showWarningModal && (
        <ModalInfo
          title={"Echec de l'envoi"}
          onClose={(): any => setShowWarningModal(false)}
          rightButtonAction={(): any => {
            allInvoices[0].id && window.open(viewPdfInvoice(allInvoices[0].id));
          }}
          rightButtonText={"Télécharger la facture"}
          leftButtonText={"Fermer"}
        >
          <>
            La facture a bien été créée mais l&apos;email n&apos;a pas pu être
            envoyé. <br />
            Vous pouvez télécharger la facture et l&apos;envoyer depuis votre
            boîte mail.
          </>
        </ModalInfo>
      )}
    </div>
  );
};
