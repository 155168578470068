import React, { useState } from "react";
import { getAttachmentUrl } from "../../../services/OpinionRequestService";
import "../ParticipantDetails/ParticipantCards/Cards.css";
import { Attachment } from "../../../domain/OpinionRequest";
import styles from "./DocumentCard.module.css";
import docIcon from "../../../assets/docFileType.svg";
import jpgIcon from "../../../assets/jpgFileType.svg";
import downloadIcon from "../../../assets/download.svg";
import trashIcon from "../../../assets/trash.svg";
import { ModalInfo } from "../../Shared/ModalInfo";

interface Props {
  requestId: string;
  document: Attachment;
  onDeleteDocument: (fileId: string) => any;
}

export const DocumentCard: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const docNameArray = props.document.Filename.split(".");
  const getIcon = (): string => {
    return docNameArray[docNameArray.length - 1] === "jpg" ? jpgIcon : docIcon;
  };

  const deleteFile = (): void => {
    props.onDeleteDocument(props.document.Id);
  };

  return (
    <div className={`document-card ${styles.documentCard}`}>
      <img
        className={`${styles.icon} ${styles.margin10}`}
        alt=""
        src={getIcon()}
      />
      <div className="text-centered text-ellipsis">
        {props.document.Filename}
      </div>
      <div className="w-100 flex-row centered-content">
        <a
          className={`${styles.downloadFileContainer} circle`}
          href={getAttachmentUrl(props.requestId, props.document.Id)}
        >
          <img
            className={`${styles.icon} ${styles.width20}`}
            alt="Download"
            src={downloadIcon}
          />
        </a>
        <button
          className={`${styles.deleteFileContainer} circle`}
          onClick={(): any => setShowModal(true)}
        >
          <img className={styles.icon} alt="Supprimer" src={trashIcon} />
        </button>
      </div>
      {showModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowModal(false)}
          rightButtonAction={deleteFile}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer ce document ?</>
        </ModalInfo>
      )}
    </div>
  );
};
