import React, { useState, useEffect, useRef } from "react";
import { UserFacility } from "../../../domain/User";
import styles from "./ProfileFacilitiesDetails.module.css";
import hopitalIcon from "../../../assets/profile-icon/icon-hopital.svg";
import EditIcon from "../../../assets/edit-icon.svg";
import { Facility } from "../../../domain/Facility";
interface Props {
  isAddService: boolean;
  facility: Facility;
  handleRemove?: (id: string) => any;
  showRemoveButton: boolean;
  handleService?: (userfacility: UserFacility) => any;
}

export const FacilityCard: React.FC<Props> = (props: Props) => {
  const { handleRemove, facility, handleService } = props;
  const [addNewService, setAddNewService] = useState<boolean>(false);
  const [saveNewService, setSaveNewService] = useState<string>(
    facility.service ?? ""
  );
  const [isReadOnly, setIsReadOnly] = useState<boolean>(!!facility.service);
  const inputRef = useRef<any>();

  const saveService = (): any => {
    setIsReadOnly(true);
    if (saveNewService === "") {
      setAddNewService(false);
    }
  };

  const deleteService = (): any => {
    setSaveNewService("");
    setIsReadOnly(true);
    setAddNewService(false);
  };

  const handleKeyDown = (event: any): any => {
    if (event.key === "Enter") {
      saveService();
    }
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    handleService &&
      handleService({ facilityId: facility.finess, service: saveNewService });
    // eslint-disable-next-line
  }, [isReadOnly]);

  return (
    <div className={styles.facilityCard} id="facility-card">
      {props.showRemoveButton && (
        <button
          className={styles.facilityCross}
          onClick={(): any => handleRemove && handleRemove(facility.finess)}
        >
          ×
        </button>
      )}
      <div className="flex-row">
        <img
          className={styles.facilityIcon}
          src={hopitalIcon}
          alt="icon hopital"
        />
        <div className={styles.facilityName}>
          <div className={styles.facilityKind}>{facility.facilityKind}</div>
          <div className="text-bold">{facility.name}</div>
        </div>
      </div>
      <div className={styles.facilityCardSection}>
        <span>Adresse</span>
        {facility.addressRoutingNumber} {facility.addressRoutingWay}{" "}
        {facility.addressRoutingWayName} <br /> {facility.addressRoutingLabel}
      </div>
      {props.isAddService && (
        <>
          {!addNewService && facility.service === "" ? (
            <button
              type="button"
              className={`row ${styles.addNewService}`}
              onClick={(): any => {
                setAddNewService(true);
                setIsReadOnly(false);
              }}
            >
              <div className={styles.circle}>
                <i className="icon icon-plus" />
              </div>
              Ajouter un nouveau service
            </button>
          ) : (
            <>
              <div className={styles.facilityCardSection}>
                <span>Service</span>
                <div className="row">
                  <input
                    ref={inputRef}
                    name="lastName"
                    className="form-input"
                    type="text"
                    placeholder="Saisir le nom du service..."
                    onChange={(e): any => setSaveNewService(e.target.value)}
                    disabled={isReadOnly}
                    value={saveNewService}
                    onKeyDown={handleKeyDown}
                    autoFocus={true}
                  />
                  {!isReadOnly ? (
                    <>
                      <button
                        type="button"
                        className="btn-modify"
                        onClick={saveService}
                      >
                        <i className="icon icon-check neuro-primary" />
                      </button>
                      <button
                        type="button"
                        className="btn-modify"
                        onClick={deleteService}
                      >
                        <i className="icon icon-cross neuro-primary" />
                      </button>
                    </>
                  ) : (
                    <button
                      className="edit-btn-small-screen"
                      onClick={(): any => setIsReadOnly(false)}
                    >
                      <img
                        className="icon-disabled mobile-view-icon"
                        alt="icon-edit"
                        src={EditIcon}
                      />
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
