/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../utils/state";
import { initLogout } from "../utils/auth";
import { ProfileMenu } from "./ProfileMenu";
import { LanguageMenu } from "./LanguageSelector";
import history from "../utils/history";
import styles from "./Navbar.module.css";
import config from "../utils/config";

export const Navbar: React.FC = () => {
  const appContext = useContext(AppContext);
  const brand = config.brand;

  return (
    <nav className={`navbar w-100 ${styles.paddingNavbar}`}>
      <section className="navbar-section">
        <Link to="/" className={`btn-link + ${styles.homeLink}`}>
          <img
            className={styles.logoSize}
            alt="logo-app"
            src={
              require(brand === "handineuro"
                ? `../assets/logo/logo-handineuro.svg`
                : brand === "teleghuparis"
                ? `../assets/logo/logo-teleghuparis.svg`
                : `../assets/logo/logo-dopenclinics.png`).default
            }
          />
        </Link>
        {config.environment !== "prod" && (
          <span className="text-coral-medium text-bold">
            {config.environment}
          </span>
        )}
      </section>
      <section className={`navbar-section ${styles.profilBtnContainer}`}>
        <LanguageMenu />

        {appContext.state.authenticated && (
          <ProfileMenu
            accessProfile={(): any => history.push("/profile/homepage")}
            accessAdminPanel={(): any => history.push("/admin/users/waiting")}
            accessInvoicingPanel={(): any => history.push("/invoicing")}
            accessImportPanel={(): any => history.push("/import")}
            accessContactPanel={(): any => history.push("/contact")}
            accessGeneralTermsOfUsePanel={(): any =>
              history.push("/general-terms-of-use")
            }
            accessDataProtectionPanel={(): any =>
              history.push("/personal-data-protection")
            }
            accessHelpPanel={(): any => history.push("/help")}
            disconnect={initLogout}
          />
        )}
      </section>
    </nav>
  );
};
