import React from "react";
import styles from "./UserGuides.module.css";
import ProfileExpert from "../../assets/user-guides/profile-expert.png";
import SelectRoleExpert from "../../assets/user-guides/select-role-expert.png";
import ExpertAcceptsRefuses from "../../assets/user-guides/expert-accepts-refuses.png";
import Chat from "../../assets/user-guides/chat.png";
import ExpertAnswerPopUp from "../../assets/user-guides/expert-answer.png";
import OpenOrResponse from "../../assets/user-guides/open-or-response-pdf.png";
import ProSanteConnectECps from "../../assets/user-guides/pro-sante-connect-e-cps.png";
import ProSanteConnectCps from "../../assets/user-guides/pro-sante-connect-cps.png";
import UpdateKeycloakInfo from "../../assets/user-guides/update-profile-info.png";
import SignUp from "../../assets/user-guides/sign-up-form.png";
import RppsForm from "../../assets/user-guides/rpps-form.png";
import ProSanteConnectPinCode from "../../assets/user-guides/pro-sante-connect-pin-code.png";
import EmailVerification from "../../assets/user-guides/email-verification.png";
import TwoFactorAuthRequest from "../../assets/user-guides/2fa-request.png";
import TwoFactorAuthValidate from "../../assets/user-guides/2fa-validate.png";
import VerifPicture from "../../assets/user-guides/verif-picture.png";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";

export const ExpertGuide: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle
        title={"Guide de l'expert"}
        backButtonRedirectsTo={`/help`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <div className={`mb-2 text-bold ${styles.subTitle}`}>
            Rôle de l&apos;expert
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-70">
                L&apos;expert a accès au contenu des demandes d&apos;avis sur
                lesquelles il est affecté. Son rôle est d&apos;apporter son
                expertise en répondant à une demande d&apos;avis.
                <br />
                Pour accéder aux fonctionnalités qui lui sont disponibles, et
                notamment accéder aux demandes d&apos;avis, l&apos;expert doit
                sélectionner l&apos;onglet &quot;Expert&quot;.
              </div>
              <div className="w-30">
                <img
                  alt="select-role"
                  src={SelectRoleExpert}
                  className={styles.selectRoleImage}
                />
              </div>
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Création de compte
          </div>
          <div className="justify">
            Tous les professionnels de santé référencés par un numéro RPPS
            peuvent créer un compte sur le site. La validité du numéro RPPS
            indiqué lors de l&apos;inscription est contrôlée par comparaison
            avec la base de données RPPS disponible{" "}
            <a
              className={styles.link}
              href=" https://mos.esante.gouv.fr/NOS/TRE_R11-CiviliteExercice/"
            >
              ici{" "}
            </a>
            et mise à jour régulièrement.
            <br />
            <br />
            Depuis la page d&apos;inscription, l&apos;utilisateur peut créer son
            compte de deux manières différentes :
            <div className="text-bold mt-2 mb-2 ml-4">
              1. Depuis Pro Santé Connect
            </div>
            L&apos;utilisateur peut créer son compte en utilisant les outils
            d&apos;authentification de l&apos;ANS (CPS ou eCPS), permettant de
            pré-remplir certains champs du processus d&apos;inscription.
            <br />
            Pour cela, l&apos;utilisateur doit cliquer sur le bouton Pro Santé
            Connect depuis la page d&apos;accueil et renseigner le numéro de sa
            carte CPS (1) ou bien connecter sa carte CPS à l&apos;aide d&apos;un
            lecteur de carte (2).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectECps}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectCps}
                className="w-30 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (1) </span>
              <span className="mr-auto ml-auto"> (2) </span>
            </div>
            Ensuite, l&apos;utilisateur doit saisir le code pin de sa carte CPS
            pour valider son identification (3) et mettre à jour ses
            informations de profil (4).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={ProSanteConnectPinCode}
                className="w-40 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={UpdateKeycloakInfo}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2">
              <span className="mr-auto ml-auto"> (3) </span>
              <span className="mr-auto ml-auto"> (4) </span>
            </div>
            <div className="text-bold mt-2 mb-2 ml-4">
              2. Depuis l&apos;application
            </div>
            Pour créer son compte depuis l&apos;application, l&apos;utilisateur
            doit renseigner son adresse e-mail ainsi qu&apos;un mot de passe
            pour créer son compte (5). Il devra confirmer la création de son
            compte en cliquant sur le lien présent dans l&apos;email reçu (6).
            <div className="row space-between-content margin-top-2">
              <img
                alt="connect-with-pro-sante-connect"
                src={SignUp}
                className="w-30 m-auto"
              />
              <img
                alt="connect-with-pro-sante-connect"
                src={EmailVerification}
                className="w-40 m-auto"
              />
            </div>
            <div className="row mt-2 mb-2">
              <span className="mr-auto ml-auto"> (5) </span>
              <span className="mr-auto ml-auto"> (6) </span>
            </div>
            <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
              Finalisation de l&apos;inscription
            </div>
            <div className="justify">
              <div className="row mb-2">
                <div className="w-60">
                  Une fois son compte créé, l&apos;utilisateur devra renseigner
                  son numéro RPPS. Dans le cas où il a créé son compte avec Pro
                  Santé Connect, le numéro RPPS sera pré-rempli. Dans le cas
                  contraire, la complétion du numéro RPPS permettra
                  d&apos;effectuer une recherche dans le référentiel RPPS et de
                  remplir certains champs.
                </div>
                <div className="w-40">
                  <img
                    alt="rpps-form"
                    src={RppsForm}
                    className={styles.selectRoleImage}
                  />
                </div>
              </div>
              Ensuite, l&apos;utilisateur doit remplir le formulaire
              d&apos;inscription, puis suivre la procédure de double
              authentification par SMS (7) et (8).
              <div className="row space-between-content margin-top-2">
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthRequest}
                  className="w-40 m-auto"
                />
                <img
                  alt="connect-with-pro-sante-connect"
                  src={TwoFactorAuthValidate}
                  className="w-40 m-auto"
                />
              </div>
              <div className="row mt-2 mb-2">
                <span className="mr-auto ml-auto"> (7) </span>
                <span className="mr-auto ml-auto"> (8) </span>
              </div>
              Une fois la double authentification effectuée, l&apos;utilisateur
              doit se prendre en photo avec sa carte CPS afin de prouver son
              identité.
              <div className="row centered-content margin-top-2 margin-bottom-2">
                <img alt="select-role" src={VerifPicture} className="w-40" />
              </div>
              La validation du compte par un administrateur intervient dans les
              12 heures suivant la soumission (du lundi au vendredi de 9h à
              19h).
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Modification du profil
          </div>
          <div className="justify">
            Depuis le menu en haut à droite de l&apos;écran, l&apos;expert peut
            accéder à son profil et modifier ses informations personnelles,
            professionnelles, ses lieux d&apos;exercice, disponibilités et ses
            préférences de notifications.
            <div className="row centered-content margin-top-2">
              <img alt="select-role" src={ProfileExpert} className="w-30" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Acceptation / refus du traitement d&apos;une demande d&apos;avis
          </div>
          <div className="justify">
            <div className="row">
              <div className="w-50">
                Lorsqu&apos;un expert est ajouté à une demande d&apos;avis par
                le régulateur, il reçoit un e-mail l&apos;invitant à consulter
                la demande.
                <br />
                Il peut alors consulter la demande d&apos;avis et décider de
                l&apos;accepter ou bien de refuser d&apos;y répondre.
                <br />
                Si l&apos;expert accepte de répondre à la demande d&apos;avis,
                il doit choisir le centre de soins auquel il est rattaché pour y
                répondre.
                <br />
                S&apos;il refuse la demande, il doit indiquer la raison de son
                refus (manque de disponibilité, pas la bonne spécialité, ...)
              </div>
              <div className="w-50">
                <img
                  alt="select-role"
                  src={ExpertAcceptsRefuses}
                  className={styles.selectRoleImage}
                />
              </div>
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Messagerie
          </div>
          <div className="justify">
            L&apos;expert peut communiquer avec le requérant, le régulateur et
            les intervenants de la demande d&apos;avis via la discussion. Cette
            discussion est disponible depuis le pied de page du détail de la
            demande d&apos;avis.
            <div className="row centered-content margin-top-2 margin-bottom-2">
              <img alt="select-role" src={Chat} className="w-40 m-auto" />
            </div>
          </div>
          <div className={`margin-top-2 mb-2 text-bold ${styles.subTitle}`}>
            Réponse à la demande d&apos;avis
          </div>
          <div className="justify">
            Une fois que l&apos;expert est prêt a apporter une réponse à la
            demande d&apos;avis, il peut cliquer sur le bouton &quot;Répondre à
            la demande&quot; en bas de la page de détail de la demande. La
            fenêtre suivante s&apos;ouvre alors :
            <div className="row centered-content margin-bottom-2">
              <img
                alt="select-role"
                src={ExpertAnswerPopUp}
                className="w-50 m-auto"
              />
            </div>
            Une fois que l&apos;expert a apporté sa réponse à la demande
            d&apos;avis, le compte rendu au format PDF peut être téléchargé
            depuis le bas de la page de détail de la demande d&apos;avis.
            <div className="row space-between-content">
              <img
                alt="select-role"
                src={OpenOrResponse}
                className="w-40 m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
