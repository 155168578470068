import React, { useState } from "react";
import "../../ParticipantDetails/ParticipantCards/Cards.css";
import styles from "../DocumentCard.module.css";
import trashIcon from "../../../../assets/trash.svg";
import { ModalInfo } from "../../../Shared/ModalInfo";
import config from "../../../../utils/config";
import { ImagingStudy } from "../../../../domain/OpinionRequest";

interface Props {
  requestId: string;
  imagingStudy: ImagingStudy;
  onDeleteImagingStudy: (fileId: string) => any;
}

export const ImagingCard: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const deleteImagingStudy = (): void => {
    props.onDeleteImagingStudy(props.imagingStudy.StudyInstanceId);
  };

  return (
    <div className={`document-card ${styles.imagingCard}`}>
      <div className="text-centered text-ellipsis">
        {props.imagingStudy.StudyDescription}
      </div>
      <div className="text-centered text-ellipsis">
        {props.imagingStudy.PatientName}
      </div>
      <div className="text-centered text-ellipsis">
        {props.imagingStudy.StudyDate}
      </div>
      <div className="w-100 flex-row centered-content">
        <a
          className={`${styles.downloadFileContainer} circle`}
          href={`${config.orthancUrl}/stone-webviewer/index.html?patient=${props.imagingStudy.PatientId}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Voir le fichier"
        >
          <i className="far fa-eye text-primary-blue" />
        </a>
        <button
          className={`${styles.deleteFileContainer} circle`}
          onClick={(): any => setShowModal(true)}
        >
          <img className={styles.icon} alt="Supprimer" src={trashIcon} />
        </button>
      </div>
      {showModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowModal(false)}
          rightButtonAction={deleteImagingStudy}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer cette image ?</>
        </ModalInfo>
      )}
    </div>
  );
};
