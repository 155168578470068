import React, { useCallback, useEffect, useState } from "react";
import OpinionRequest from "../../../domain/OpinionRequest";
import { User } from "../../../domain/User";
import { getUser } from "../../../services/UserService";
import { errorToast } from "../../../utils/Toast";
import styles from "./RequestingPhysician.module.css";

interface Props {
  request: OpinionRequest;
}

export const RequestingPhysician: React.FC<any> = (props: Props) => {
  const [physician, setPhysician] = useState<User>();

  const getPhysicianInfo = useCallback(async (): Promise<any> => {
    if (props.request && props.request.requesterUser) {
      const res = await getUser(props.request.requesterUser?.id);
      if (res instanceof Error) {
        setPhysician(undefined);
        errorToast("Erreur lors de la récupération du médecin requérant");
      } else {
        setPhysician(res);
      }
    }
  }, [props.request]);

  useEffect(() => {
    getPhysicianInfo();
  }, [getPhysicianInfo]);

  return (
    <div>
      {props.request && (
        <>
          <div className="row space-between-content mb-2">
            <div className={styles.physicianName}>
              {props.request.requesterUser?.firstName}{" "}
              {props.request.requesterUser?.lastName}
            </div>
            {physician && (
              <div className={styles.physicianRpps}>
                RPPS: {physician?.rpps}
              </div>
            )}
          </div>
          <div className={"row space-between-content " + styles.physicianWrap}>
            {physician && (
              <>
                <div>
                  <div className={`text-bold ${styles.smallLabel}`}>
                    Profession
                  </div>
                  <div className={`text-bold ${styles.physicianInfo}`}>
                    {physician.profession}
                  </div>
                </div>
                <div>
                  <div className={`text-bold ${styles.smallLabel}`}>
                    Spécialité
                  </div>
                  <div className={`text-bold ${styles.physicianInfo}`}>
                    {physician.specialty}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
