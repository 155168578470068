import React from "react";
import {
  DmpDocument,
  DmpDocumentAuthors,
  formatDateWithSlash,
} from "../../../services/DmpService";
import styles from "./DmpDocumentAbstract.module.css";

interface Props {
  documentInfos: DmpDocument;
}

export const DmpDocumentAbstract: React.FC<Props> = (props: Props) => {
  return (
    <table className={`${styles.borderTable} mb-4`}>
      <tbody>
        <tr>
          <th>Titre</th>
          <td>{props.documentInfos.title}</td>
        </tr>
        <tr>
          <th>Auteurs(s)</th>
          <td>
            {props.documentInfos.authors.map(
              (author: DmpDocumentAuthors, index) => {
                return (
                  <div key={"author_" + index}>
                    {author.name +
                      " " +
                      author.given +
                      " (" +
                      author.professionDescription +
                      " - " +
                      author.specialityDescription +
                      ")"}
                  </div>
                );
              }
            )}
          </td>
        </tr>
        <tr>
          <th>Créé le</th>
          <td>{formatDateWithSlash(props.documentInfos.creationDate)}</td>
        </tr>
        <tr>
          <th>Ajouté le</th>
          <td>{formatDateWithSlash(props.documentInfos.submissionDate)}</td>
        </tr>
        <tr>
          <th>Commentaire</th>
          <td>{props.documentInfos.description}</td>
        </tr>
      </tbody>
    </table>
  );
};
