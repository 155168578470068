import OpinionRequest, {
  Attachment,
  canUserAddDocumentToOpinionRequest,
} from "../../domain/OpinionRequest";
import React, { useContext, useEffect, useState } from "react";
import {
  uploadAttachment,
  deleteAttachment,
} from "../../services/OpinionRequestService";
import { errorToast, successToast } from "../../utils/Toast";
import { CardDisplay } from "../Request/RequestDetails/ImagingDocumentUpload/CardDisplay";
import { LineDisplay } from "../Request/RequestDetails/ImagingDocumentUpload/LineDisplay";
import { RequestStatus } from "../../domain/RequestStatus";
import { AppContext } from "../../utils/state";
import { UploadProgress } from "../Request/RequestDetails/ImagingDocumentUpload/ImagingDocumentUpload";

interface Props {
  request: OpinionRequest;
  attachments?: Attachment[];
  mainTitle: string;
  subTitle?: string;
  fileTypeFilter?: string;
  displayType?: DisplayType;
  updateRequest: () => any;
  id: string;
}

export enum DisplayType {
  LINE_DISPLAY,
  CARD_DISPLAY,
}

export const AttachmentSection: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);

  const defaultFileTypeFilter = props.fileTypeFilter ?? "any";
  const defaultAttachments = props.attachments ? props.attachments : [];
  const [attachments, setAttachments] = useState<Attachment[]>(
    defaultAttachments
  );
  const [uploadProgress, setUploadProgress] = useState<
    Map<string, UploadProgress>
  >(new Map<string, UploadProgress>());

  const [readOnly, setReadOnly] = useState<boolean>(true);

  useEffect(() => {
    setReadOnly(
      !(
        props.request?.status !== RequestStatus.CLOSED &&
        canUserAddDocumentToOpinionRequest(
          appContext.state.user,
          props.request ?? undefined
        )
      )
    );
  }, [props.request, appContext.state.user]);

  async function onChangeFileHandler(event: any): Promise<void> {
    const progressId = Date.now().toString();

    if (!props.request.id) {
      return;
    }
    const data = new FormData();
    const file = event.target.files[0];

    const extension = file.name.slice(-4);
    if (
      defaultFileTypeFilter !== "any" &&
      extension !== defaultFileTypeFilter
    ) {
      errorToast(`Le format autorisé est ${defaultFileTypeFilter}`);
      return;
    }
    event.target.value = null;
    data.append("file", file);

    setUploadProgress((prevState) => {
      prevState.set(progressId, { cpt: 0, total: 1 });
      return new Map(prevState);
    });

    const res = await uploadAttachment(props.request.id, data);
    if (res instanceof Error) {
      errorToast("Echec de l'envoi du fichier");
      setUploadProgress((prevState) => {
        prevState.delete(progressId);
        return new Map(prevState);
      });
      return;
    }
    setUploadProgress((prevState) => {
      prevState.set(progressId, { cpt: 1, total: 1 });
      return new Map(prevState);
    });
    setTimeout(() => {
      setUploadProgress((prevState) => {
        prevState.delete(progressId);
        return new Map(prevState);
      });
    }, 300);
    successToast("Fichier sauvegardé");
    const newAttachments: Attachment[] = [...attachments, res];
    setAttachments(newAttachments);
    props.updateRequest();
  }

  async function deleteFile(fileId: string): Promise<void> {
    if (!props.request.id) {
      return;
    }

    const res = await deleteAttachment(props.request.id, fileId);
    if (res instanceof Error) {
      errorToast("Echec de la suppression du fichier");
      return;
    }
    successToast("Fichier supprimé");
    setAttachments(
      attachments.filter((attachment) => attachment.Id !== fileId)
    );
    props.updateRequest();
  }

  if (!props.request.id) {
    return (
      <div>
        <h4 className="form-title">{props.mainTitle}</h4>
        <div>
          Vous devez enregistrer la demande avant de pouvoir ajouter des pièces
          jointes
        </div>
      </div>
    );
  }

  return props.displayType === DisplayType.CARD_DISPLAY ? (
    <CardDisplay
      id={props.id}
      documents={props.attachments ?? []}
      request={props.request}
      onDelete={deleteFile}
      onChangeFileHandler={onChangeFileHandler}
      disabledButton={readOnly}
      sectionTitle={props.mainTitle}
      fileInputLabel={props.subTitle ? <>{props.subTitle}</> : undefined}
      inputAccept={props.fileTypeFilter}
      uploadProgress={uploadProgress}
    />
  ) : (
    <LineDisplay
      id={props.id}
      documents={props.attachments ?? []}
      requestId={props.request.id}
      onDelete={deleteFile}
      onChangeFileHandler={onChangeFileHandler}
      disabledButton={readOnly}
      mainTitle={props.mainTitle}
      subTitle={props.subTitle ?? ""}
      inputAccept={props.fileTypeFilter}
      uploadProgress={uploadProgress}
    />
  );
};
