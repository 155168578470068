import React from "react";
import {
  getMinimizedTitleLabel,
  HealthProfessionalTitle,
} from "../../domain/User";
import { Chip } from "./Chip";

interface Props {
  professionalTitle?: HealthProfessionalTitle;
  firstName: string;
  lastName: string;
  onClick?: (event: any) => any;
  onDelete?: (event: any) => any;
  isSmall?: boolean;
  readOnly?: boolean;
}

export const UserChip: React.FC<Props> = (props: Props) => {
  return (
    <Chip
      figureTitle={props.firstName.charAt(0) + props.lastName.charAt(0)}
      onDelete={props.onDelete}
      onClick={props.onClick}
      readOnly={props.readOnly}
      isSmall={props.isSmall}
      title={
        getMinimizedTitleLabel(props.professionalTitle) +
        props.firstName +
        " " +
        props.lastName
      }
    />
  );
};
