import React, { useEffect, useState } from "react";
import "./Admin.css";
import styles from "./AdminOrganizationTab.module.css";
import { Organization } from "../../domain/Organization";
import history from "../../utils/history";
import EditIcon from "../../assets/edit-icon.svg";
import { getFacility } from "../../services/FacilityService";
import { Facility } from "../../domain/Facility";
import { OrganizationDetailCard } from "../Dispatch/OrganizationDetailCard";

interface Props {
  organization: Organization;
  readOnly: boolean;
  showCardLegend: boolean;
  showRemoveButton: boolean;
  handleRemove?: () => void;
  breadcrumb?: JSX.Element[];
}

export const OrganizationCard: React.FC<Props> = (props: Props) => {
  const [facility, setFacility] = useState<Facility>();

  const getFacilityById = async (): Promise<void> => {
    if (props.organization.facilityId) {
      const res = await getFacility(props.organization.facilityId);
      if (res instanceof Error || res == null) {
        setFacility(undefined);
      } else {
        setFacility(res);
      }
    } else {
      setFacility(undefined);
    }
  };

  useEffect(() => {
    getFacilityById();
  }, []);

  const dispatchersNb = props.organization.dispatchers
    ? props.organization.dispatchers.length
    : 0;

  const expertsNb = props.organization.experts
    ? props.organization.experts.length
    : 0;

  return (
    <div className={styles.organizationCard}>
      {props.showCardLegend && (
        <div className={styles.legend}>ORGANISATION</div>
      )}
      <div className={`${styles.cardPadding} w-100 p-relative`}>
        <div className="flex-row space-between-content">
          <div className={`text-large ${styles.extraBoldTitle}`}>
            {props.organization.name}
          </div>
          {!props.readOnly && (
            <button
              id={"edit-organization-btn-" + props.organization.id}
              className={styles.editOrDeleteButton}
              onClick={(): void => {
                history.push(
                  `/admin/organizations/${props.organization.id}/edit`
                );
              }}
            >
              <img alt="icon-edit" src={EditIcon} />
            </button>
          )}
          {props.showRemoveButton && (
            <button
              className={styles.removeOrganizationButton}
              onClick={(): any => props.handleRemove && props.handleRemove()}
            >
              ×
            </button>
          )}
        </div>
        {props.breadcrumb && (
          <div className="mb-2 text-small">
            {props.breadcrumb.map((value, index) => {
              if (index === 0) {
                return value;
              } else {
                return <span>/{value}</span>;
              }
            })}
          </div>
        )}
        <div className="mb-2">
          <span className="text-bold mr-1">Localisation :</span>
          {facility ? (
            <span className="text-secondary-grey">
              {facility.name} - {facility.addressRoutingLabel}
            </span>
          ) : (
            <span className="text-secondary-grey">Inconnue</span>
          )}
        </div>
        <div>
          <details className={styles.detailsOrganization}>
            <summary className={styles.summaryDetailsOrganization}>
              <i className={`fa fa-info-circle mr-2`} />
            </summary>
            <OrganizationDetailCard organization={props.organization} />
          </details>
        </div>
        {!props.readOnly && (
          <>
            <div className="flex-row mb-2">
              <span
                className={`circle text-bold centered-content ${styles.UserNbCircle}`}
              >
                {dispatchersNb}
              </span>
              <span className="pl-2 pt-1">
                {" "}
                {dispatchersNb > 1
                  ? "Régulateurs d'organisations"
                  : "Régulateur d'organisation"}{" "}
              </span>
            </div>
            <div className="flex-row mb-2">
              <span
                className={`circle text-bold centered-content ${styles.UserNbCircle}`}
              >
                {expertsNb}
              </span>
              <span className="pl-2 pt-1">
                {" "}
                {expertsNb > 1 ? "Experts locaux" : "Expert local"}{" "}
              </span>
            </div>
            <div className="mt-auto text-centered">
              <button
                id={`organization-see-more-${props.organization.id}`}
                className="w-50 btn secondary-btn"
                onClick={(): void => {
                  history.push(
                    `/admin/organizations/${props.organization.id}/view`
                  );
                }}
              >
                Voir plus
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
