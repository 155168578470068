import React, { useContext, useState } from "react";
import { ActionTypes, AppContext } from "../../utils/state";
import "./ProfileDetails.css";
import EditIcon from "../../assets/edit-icon.svg";
import history from "../../utils/history";
import {
  getProfessionalTitleLabel,
  User,
  getCivilTitleLabel,
} from "../../domain/User";
import { useForm } from "react-hook-form";
import { getProfile, updateCurrentUser } from "../../services/UserService";
import { errorToast, successToast } from "../../utils/Toast";
import { Profile } from "../../domain/Profile";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const ProfilePersonalDetails: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const { handleSubmit, reset } = useForm<Profile>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    appContext.state.user.phone
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");

  function goToProfile(): void {
    history.push(`/profile`);
  }

  const startEdit = (): any => {
    setIsReadOnly(!isReadOnly);
  };

  const cancelEdit = (): any => {
    reset(appContext.state.user);
    setPhoneNumber(appContext.state.user.phone);
    setIsReadOnly(!isReadOnly);
  };

  const onSubmit = async (): Promise<void> => {
    if (phoneNumberError !== "") {
      return;
    }
    const userUpdated: User = {
      ...appContext.state.user,
      phone: phoneNumber,
    };
    const res = await updateCurrentUser(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition de l'utilisateur");
    } else {
      successToast("Utilisateur mis à jour");
      getProfile().then((updatedUser: User | Error) => {
        if (updatedUser instanceof Error) {
          errorToast("Erreur lors de l'édition de l'utilisateur");
        } else {
          updatedUser.roles = updatedUser.roles || [];
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
        }
      });
      goToProfile();
    }
  };

  const handlePhoneNumberChange = (input: string): void => {
    if (input === "") {
      setPhoneNumber("+");
    } else {
      setPhoneNumber(input);
    }
    if (input.length < 11) {
      setPhoneNumberError("Numéro de téléphone incorrect");
    } else {
      setPhoneNumberError("");
    }
  };

  return (
    <>
      <PageHeaderWithTitle
        title={"Informations personnelles"}
        backButtonRedirectsTo={`/profile`}
      />
      <div className="page-container">
        <div className="profile-white-container text-left">
          <div className="row space-between-content">
            <i className="informative-text">
              Vous pouvez modifier certaines informations si vous le souhaitez.
            </i>
            {isReadOnly && (
              <button
                id="edit-profile-icon"
                className="edit-btn-small-screen"
                onClick={startEdit}
              >
                <img
                  className="icon-disabled mobile-view-icon"
                  alt="icon-edit"
                  src={EditIcon}
                />
              </button>
            )}
          </div>
          <div className="form-horizontal pb-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-civilTitle">
                  Civilité
                </label>
                <input
                  id="profile-civilTitle"
                  name="civilTitle"
                  className="form-input"
                  type="text"
                  placeholder="Titre inconnu"
                  defaultValue={getCivilTitleLabel(
                    appContext.state.user?.civilTitle
                  )}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label
                  className="form-label mr-2"
                  htmlFor="profile-professionalTitle"
                >
                  Titre
                </label>
                <input
                  id="profile-professionalTitle"
                  name="professionalTitle"
                  className="form-input"
                  type="text"
                  placeholder="Titre inconnu"
                  defaultValue={getProfessionalTitleLabel(
                    appContext.state.user?.professionalTitle
                  )}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-firstName">
                  Prénom
                </label>
                <input
                  id="profile-firstName"
                  className="form-input"
                  type="text"
                  placeholder="Ex: Pierre"
                  defaultValue={appContext.state.user?.firstName}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-lastName">
                  Nom
                </label>
                <input
                  id="profile-lastName"
                  className="form-input"
                  type="text"
                  placeholder="Ex: Dupond"
                  defaultValue={appContext.state.user?.lastName}
                  disabled={true}
                />
              </div>
              <div className="form-group mb-0">
                <label className="form-label mr-2" htmlFor="profile-phone">
                  Téléphone
                </label>
                {!isReadOnly && (
                  <span className="error-msg text-large mt-1">*</span>
                )}
              </div>
              <div className="form-group">
                {isReadOnly ? (
                  <div id="profile-phone-label">
                    +{appContext.state.user.phone}
                  </div>
                ) : (
                  <div id="profile-phone">
                    <PhoneInput
                      country={"fr"}
                      preferredCountries={["fr"]}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                )}
                {!isReadOnly && (
                  <div className="text-left error-msg">{phoneNumberError}</div>
                )}
              </div>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-email">
                  Email
                </label>
                <input
                  id="profile-email"
                  name="email"
                  className="form-input"
                  type="text"
                  placeholder="Ex: pierre.dupond@gmail.com"
                  defaultValue={appContext.state.user?.email}
                  disabled={true}
                />
              </div>
              <div className="form-group">
                <label className="form-label mr-2" htmlFor="profile-rpps">
                  Numéro RPPS
                </label>
                <input
                  id="profile-rpps"
                  name="rpps"
                  className="form-input"
                  type="text"
                  placeholder="Ex: 10982769010910"
                  defaultValue={appContext.state.user?.rpps}
                  disabled={true}
                />
              </div>
              {!isReadOnly && (
                <div className="text-right mr-4 mb-2">
                  <span className="error-msg text-large">*</span>
                  <span className="error-msg text-medium ml-1">
                    Champ obligatoire
                  </span>
                </div>
              )}
              <div className="row margin-top-2">
                <button
                  type="button"
                  className="style-btn close-btn w-50"
                  onClick={cancelEdit}
                  hidden={isReadOnly}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="style-btn neuro-btn w-50"
                  hidden={isReadOnly}
                >
                  Sauvegarder les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
