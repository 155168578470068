import React, { useEffect, useState } from "react";

export enum Consent {
  NONE = "NONE",
  PATIENT_CONSENT = "PATIENT_CONSENT",
  TRUST_PATIENT_CONSENT = "TRUST_PATIENT_CONSENT",
  NO_PATIENT_CONSENT = "NO_PATIENT_CONSENT",
}

export const PatientConsent: React.FC<any> = (props: {
  consent: Consent;
  changed: (newState: Consent) => any;
  readOnly?: any;
  isRequired: boolean;
}) => {
  const consent = props.consent;
  const readOnly = props.readOnly;
  const [radioButtonError, setRadioButtonError] = useState<string>("");

  useEffect((): any => {
    props.isRequired && consent === Consent.NONE
      ? setRadioButtonError("form-icon-error")
      : setRadioButtonError("");
  }, [props.isRequired]);

  return (
    <div className="mt-4">
      <div className="form-title mb-0">
        Assurez vous d&apos;avoir le consentement de votre patient ou de la
        personne de confiance avant de continuer dans le formulaire.
        <span className="error-msg text-large ml-2">*</span>
      </div>
      <div className="form-group mt-4">
        <label className="form-radio">
          <input
            type="radio"
            value={Consent.PATIENT_CONSENT}
            checked={consent === Consent.PATIENT_CONSENT}
            onChange={(): any => {
              props.changed(Consent.PATIENT_CONSENT);
              setRadioButtonError("");
            }}
            disabled={readOnly}
            required={props.isRequired}
          />
          <i className={`form-icon ${radioButtonError}`} />{" "}
          <span className="ml-1 c-hand">Accord du patient</span>
        </label>
        <label className="form-radio mt-2">
          <input
            type="radio"
            value={Consent.TRUST_PATIENT_CONSENT}
            checked={consent === Consent.TRUST_PATIENT_CONSENT}
            onChange={(): any => {
              props.changed(Consent.TRUST_PATIENT_CONSENT);
              setRadioButtonError("");
            }}
            disabled={readOnly}
          />
          <i className={`form-icon ${radioButtonError}`} />{" "}
          <span className="ml-1 c-hand">
            Accord de la personne de confiance
          </span>
        </label>
        <label className="form-radio mt-2">
          <input
            type="radio"
            value={Consent.NO_PATIENT_CONSENT}
            checked={consent === Consent.NO_PATIENT_CONSENT}
            onChange={(): any => {
              props.changed(Consent.NO_PATIENT_CONSENT);
              setRadioButtonError("");
            }}
            disabled={readOnly}
          />
          <i className={`form-icon ${radioButtonError}`} />{" "}
          <span className="ml-1 c-hand">Impossible de le recueillir</span>
        </label>
      </div>
      {props.isRequired && consent === Consent.NONE && (
        <div className="error-msg">
          Veuillez choisir une option avant de continuer.
        </div>
      )}
      <div className="margin-top-2 text-right">
        <span className="error-msg text-large">*</span>
        <span className="error-msg text-medium ml-1">Champs obligatoires</span>
      </div>
    </div>
  );
};
