import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/state";
import {
  HealthPathwayContact,
  HealthPathwayContactType,
} from "../../domain/OpinionRequest";
import styles from "./ReferralDoctorForm.module.css";
import { HealthPathwayContactCard } from "./HealthPathwayContactCard";
import {
  ProfessionCategory,
  HealthProfessionalUser,
  HealthProfessionalTitle,
} from "../../domain/User";
import { SearchHealthProfessional } from "./SearchHealthProfessional";
import useHealthPathwayContact from "./useHealthPathwayContact";
import { AddNewUser } from "./Participants/AddNewUser";

interface Props {
  healthPathwayContacts: HealthPathwayContact[];
  onChange: (newState?: HealthPathwayContact[]) => any;
  readOnly: boolean;
}

export const ReferralDoctorForm: React.FC<Props> = (props: Props) => {
  function getCurrentUser() {
    return hpContacts.find((user) => user.userId === appContext.state.user.id);
  }

  const isChecked = () => {
    return getCurrentUser()?.category === HealthPathwayContactType.DOCTOR;
  };

  const isDisabled = () => {
    return getCurrentUser()?.category === HealthPathwayContactType.PARAMEDICAL;
  };

  const appContext = useContext(AppContext);
  const hpContacts = props.healthPathwayContacts || [];
  const [checked, setChecked] = useState<boolean>(isChecked());
  const [disabled] = useState<boolean>(isDisabled());
  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();

  const {
    addUserFromDirectory,
    addToHealthPathway,
    removeFromHealthPathway,
    setSearchInDirectory,
    searchInDirectory,
  } = useHealthPathwayContact(
    HealthPathwayContactType.DOCTOR,
    hpContacts,
    props.onChange
  );

  const handleCheck = (e: any): void => {
    const hPUser: HealthProfessionalUser = {
      ...appContext.state.user,
      userId: appContext.state.user.id,
      rpps: appContext.state.user.rpps || "",
      profession: appContext.state.user.profession || "",
    };
    if (e.target.checked) {
      addToHealthPathway(hPUser);
    } else {
      removeFromHealthPathway(hPUser);
    }
    setChecked(!checked);
  };

  return (
    <>
      <p className={styles.description}>
        Vous pouvez indiquer les médecins faisant partie du parcours de santé du
        patient.
        {appContext.state.user.professionalTitle !==
          HealthProfessionalTitle.DOCTOR && (
          <span className="error-msg text-large ml-2">*</span>
        )}
      </p>
      {!props.readOnly && (
        <>
          <label id="referral-doctor-checkbox" className="form-checkbox c-hand">
            <input
              onChange={handleCheck}
              type="checkbox"
              checked={isChecked()}
              disabled={isDisabled()}
            />
            <i className={"form-icon " + styles.checkBox} />
            <div className="row">
              <div>Vous faites partie des médecins</div>
            </div>
            {disabled && (
              <i className="text-primary-blue">
                Vous êtes déjà ajouté à l&apos;équipe paramédicale.
              </i>
            )}
          </label>

          <SearchHealthProfessional
            onSelected={(newDoctor?: HealthProfessionalUser): any => {
              if (newDoctor?.userId === "") {
                setHpToAdd(newDoctor);
                setSearchInDirectory(true);
              } else {
                addToHealthPathway(newDoctor);
              }
            }}
            healthPathwayContacts={hpContacts}
            professionFilter={ProfessionCategory.DOCTOR}
          />
          <AddNewUser
            userToAdd={hpToAdd}
            show={searchInDirectory}
            onClose={(): any => setSearchInDirectory(false)}
            onAddUser={addUserFromDirectory}
          />
        </>
      )}

      <div id="referral-doctors-list">
        {hpContacts
          .filter((hp) => hp.category === HealthPathwayContactType.DOCTOR)
          .map((referralDoctor) => (
            <HealthPathwayContactCard
              key={referralDoctor.userId}
              doctor={referralDoctor}
              handleRemove={removeFromHealthPathway}
              readOnly={props.readOnly}
              setDoctor={(hpContact): any => {
                const newHpContacts = hpContacts.map((v) => {
                  if (v.userId === hpContact.userId) {
                    return hpContact;
                  }
                  return v;
                });
                props.onChange(newHpContacts);
              }}
            />
          ))}
      </div>
      {appContext.state.user.professionalTitle !==
        HealthProfessionalTitle.DOCTOR && (
        <div className="margin-top-2 text-right mr-4 mb-2">
          <span className="error-msg text-large">*</span>
          <span className="error-msg text-medium ml-1">Champ obligatoire</span>
        </div>
      )}
    </>
  );
};
