import React from "react";
import emptyList from "../../assets/illustrations/empty_request_list.svg";

interface Props {
  text: string;
}

export const EmptyRequestListMessage: React.FC<Props> = (props: Props) => (
  <div className="empty">
    <img
      className="margin-bottom-2 fit-content"
      alt="Aucune demande"
      src={emptyList}
    />
    <div className="empty-text">{props.text}</div>
  </div>
);
