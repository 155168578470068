import Plausible from "plausible-tracker";
import config from "./config";

const plausible = Plausible({
  domain: window.location.hostname,
  apiHost: config.plausibleApiUrl,
});

function getRedactedUrl() {
  const url = window.origin + location.pathname;
  const regexExp = /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/gi;
  return url.replace(regexExp, "_ID_");
}

export const trackCustomPage = (label?: string) => {
  let url;
  if (!label) {
    url = getRedactedUrl();
  } else {
    url = getRedactedUrl() + "/" + label;
  }
  plausible.trackPageview({
    url,
  });
};

export const trackEvent = (eventName: string) => {
  plausible.trackEvent(eventName);
};
