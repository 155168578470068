import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../utils/state";
import OpinionRequest, {
  SharePermission,
} from "../../../domain/OpinionRequest";
import styles from "./FooterBar.module.css";
import { hasRole } from "../../../utils/authorization";
import { UserRoles } from "../../../domain/UserRoles";
import {
  DeclineMotive,
  ExpertDeclineOpinionRequest,
} from "../ExpertDeclineOpinionRequest";
import { SelectFacilityLinkedToRequest } from "../SelectFacilityLinkedToRequest";
import { getResponsePdfUrl } from "../../../services/OpinionRequestService";
import { RequestStatus } from "../../../domain/RequestStatus";
import { ExpertStatus } from "../../../domain/ExpertStatus";
import { Spinner } from "../../Shared/Spinner";
import { isOrganizationDispatcherOnOpinionRequest } from "../../../domain/User";

interface Props {
  request: OpinionRequest;
  submitRequest: () => void;
  validateRequest: () => void;
  isAffecting: () => void;
  isReplying: () => void;
  isSubmitting: boolean;
  expertAccept: (facilityId: string) => void;
  expertDecline: (comment: string, motive: DeclineMotive) => void;
}

export const FooterBar: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState<boolean>(
    false
  );
  const [isValidateButtonVisible, setIsValidateButtonVisible] = useState<
    boolean
  >(false);
  const [isAcceptDeclineMenuVisible, setIsAcceptDeclineMenuVisible] = useState<
    boolean
  >(false);
  const [isAffectExpertVisible, setIsAffectExpertVisible] = useState<boolean>(
    false
  );
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
  const [isAnswerButtonVisible, setIsAnswerButtonVisible] = useState<boolean>(
    false
  );
  const [isDraftButtonVisible, setIsDraftButtonVisible] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (
      props.request.status === RequestStatus.DRAFT &&
      props.request.participants?.some(
        (p) =>
          p.userId === appContext.state.user.id &&
          p.permission === SharePermission.ADMIN
      )
    ) {
      setIsSubmitButtonVisible(true);
    } else {
      setIsSubmitButtonVisible(false);
    }
    if (
      props.request.status === RequestStatus.SUBMITTED &&
      (hasRole(appContext.state.user.roles, UserRoles.GLOBAL_DISPATCHER) ||
        isOrganizationDispatcherOnOpinionRequest(
          appContext.state.user,
          props.request
        ))
    ) {
      setIsValidateButtonVisible(true);
    } else {
      setIsValidateButtonVisible(false);
    }
    if (
      props.request.status === RequestStatus.VALIDATED &&
      (hasRole(appContext.state.user.roles, UserRoles.GLOBAL_DISPATCHER) ||
        isOrganizationDispatcherOnOpinionRequest(
          appContext.state.user,
          props.request
        ))
    ) {
      setIsAffectExpertVisible(true);
    } else {
      setIsAffectExpertVisible(false);
    }
    if (
      props.request.status === RequestStatus.VALIDATED &&
      props.request.assignedExperts?.some(
        (expert) =>
          expert.status === ExpertStatus.WAITING &&
          expert.userId === appContext.state.user.id
      )
    ) {
      setIsAcceptDeclineMenuVisible(true);
    } else {
      setIsAcceptDeclineMenuVisible(false);
    }
    if (
      props.request.status === RequestStatus.VALIDATED &&
      props.request.assignedExperts?.some(
        (expert) =>
          expert.userId === appContext.state.user.id &&
          expert.status === ExpertStatus.ACCEPTED
      ) &&
      props.request.expertReply?.responseStatus !== "DRAFT"
    ) {
      setIsAnswerButtonVisible(true);
    } else {
      setIsAnswerButtonVisible(false);
    }
    if (
      props.request.status === RequestStatus.VALIDATED &&
      props.request.assignedExperts?.some(
        (expert) =>
          expert.userId === appContext.state.user.id &&
          expert.status === ExpertStatus.ACCEPTED
      ) &&
      props.request.expertReply?.responseStatus === "DRAFT"
    ) {
      setIsDraftButtonVisible(true);
    } else {
      setIsDraftButtonVisible(false);
    }
  }, [props.request, appContext.state.user]);

  return (
    <div className={`row w-100 ${styles.footerBarContainer}`}>
      {isSubmitButtonVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={`text-dark-grey ${styles.text}`}>
            Veuillez soumettre la demande après avoir verifié qu&apos;elle est
            complète et prête à recevoir une réponse
          </span>
          {!props.isSubmitting ? (
            <button
              className={`btn btn-block neuro-btn ${styles.btn}`}
              id="submit-opinion-request-btn"
              onClick={(): any => props.submitRequest()}
              disabled={
                appContext.state.user.id !== props.request.requesterUser?.id ||
                !props.request.participants?.some(
                  (p) =>
                    p.userId === appContext.state.user.id &&
                    p.permission === SharePermission.ADMIN
                )
              }
            >
              Soumettre la demande
            </button>
          ) : (
            <div className="row">
              <Spinner size={"big-spinner"} />
              <i className="ml-2">Enregistrement en cours...</i>
            </div>
          )}
        </div>
      )}
      {isValidateButtonVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={`text-dark-grey ${styles.text}`}>
            Veuillez valider la demande après avoir verifié qu&apos;elle est
            complète et prête à recevoir une réponse
          </span>
          <button
            className={`btn btn-block neuro-btn ${styles.btn}`}
            id="validate-opinion-request-btn"
            onClick={(): any => props.validateRequest()}
            disabled={
              !hasRole(
                appContext.state.user.roles,
                UserRoles.GLOBAL_DISPATCHER
              ) &&
              !hasRole(
                appContext.state.user.roles,
                UserRoles.ORGANIZATION_DISPATCHER
              )
            }
          >
            Valider la demande
          </button>
        </div>
      )}
      {isAffectExpertVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={styles.text}>
            Vous pouvez affecter un/plusieurs experts ou une organisation à
            cette demande.
          </span>
          <button
            className={`btn btn-block neuro-btn ${styles.btn}`}
            id="affect-expert-to-opinion-request-btn"
            onClick={(): any => props.isAffecting()}
          >
            Affecter la demande
          </button>
        </div>
      )}
      {isAcceptDeclineMenuVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={styles.text}>
            Cette demande vous a été assignée, acceptez-vous d&apos;y répondre ?
            <br />
            Vous apparaitrez alors en tant que membre du groupe d&apos;experts
          </span>
          <div className={`row ${styles.buttonContainer}`}>
            <button
              className={`btn btn-block btn-error mb-2 ${styles.acceptDenyBtn}`}
              id="refuse-answer-opinion-request-btn"
              onClick={(): any => setShowDeclineModal(true)}
            >
              Refuser
            </button>
            <button
              className={`btn btn-block btn-success mb-2 ${styles.acceptDenyBtn}`}
              id="accept-answer-opinion-request-btn"
              onClick={(): any => setShowAcceptModal(true)}
            >
              Accepter
            </button>
          </div>
        </div>
      )}
      {isAnswerButtonVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={styles.text}>
            Vous pouvez répondre à la demande lorsque vous considérer être en
            mesure de donner votre avis sur celle-ci.
          </span>
          <button
            className={`btn btn-block neuro-btn ${styles.btn}`}
            id="answer-to-opinion-request-btn"
            onClick={(): any => props.isReplying()}
          >
            Répondre à la demande
          </button>
        </div>
      )}
      {isDraftButtonVisible && (
        <div className={`flex-col ${styles.footerContentContainer}`}>
          <span className={styles.text}>
            Vous pouvez confirmer votre réponse ou la modifier lorsque vous
            considérez être en mesure de donner votre avis sur celle-ci.
          </span>
          <button
            className={`btn btn-block neuro-btn ${styles.btn}`}
            id="answer-to-opinion-request-btn"
            onClick={(): any => props.isReplying()}
          >
            Modifier la réponse
          </button>
        </div>
      )}
      {showDeclineModal && (
        <ExpertDeclineOpinionRequest
          onClose={(): any => setShowDeclineModal(false)}
          onDecline={(comment: string, motive: DeclineMotive): any =>
            props.expertDecline(comment, motive)
          }
        />
      )}
      {showAcceptModal && (
        <SelectFacilityLinkedToRequest
          modalType={"ACCEPT"}
          onClose={(): any => setShowAcceptModal(false)}
          onSubmit={(facilityId: string): any => {
            props.expertAccept(facilityId);
          }}
        />
      )}
      {props.request?.status === RequestStatus.CLOSED && (
        <div className="flex-col">
          <span className={styles.text}>
            La demande d&apos;avis a reçu une réponse, vous pouvez la
            télécharger ici :
          </span>
          <a
            className={`btn btn-block neuro-btn ${styles.btn}`}
            href={getResponsePdfUrl(props.request.id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Télécharger la réponse
          </a>
        </div>
      )}
    </div>
  );
};
