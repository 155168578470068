import React from "react";
import { PageHeaderWithTitle } from "../Shared/PageHeaderWithTitle";
import styles from "./UserGuides.module.css";

export const RegistrationGuide: React.FC = () => {
  return (
    <>
      <PageHeaderWithTitle
        title={"Guide d'inscription"}
        backButtonRedirectsTo={`/help`}
      />
      <div className="page-container">
        <div className="col-10 col-sm-auto col-mx-auto">
          <i className={`mb-2 text-bold ${styles.subTitle}`}>
            Guide d&apos;inscription en cours de rédaction...
          </i>
        </div>
      </div>
    </>
  );
};
