import { InsiError, InsiInfo, InsiService, InsiSex } from "../InsiService";
import { DmpSession, IcanopeeService } from "./IcanopeeService";
import { Patient } from "../../domain/OpinionRequest";
import { PatientLocation } from "../../components/OpinionRequestForm/PatientLocationForm";

export class IcanopeeInsiService extends IcanopeeService
  implements InsiService {
  private lpsInfo = {
    s_idam: "NSC17HE20GM0",
    s_numAM: "04",
    s_version: "1",
    s_instance: "550e8400-e29b-41d4-a716-446655440000",
    s_name: "INSICONNECT",
    s_billingNumber: "2001022508",
  };

  async checkInsi(patientInfo: InsiInfo, pinCode: string): Promise<boolean> {
    const session = await this.getSession(pinCode);
    return this.checkInsIdentity(session, patientInfo);
  }

  async getPatientInfoIns(
    patientInfo: InsiInfo,
    pinCode: string
  ): Promise<Patient | string> {
    const session = await this.getSession(pinCode);
    return new Promise<any>((resolve, reject) => {
      try {
        session._errorHandler = (e: any) => {
          const insiError: InsiError = IcanopeeInsiService.parseInsiError(e);
          return reject(insiError);
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        session.hl_getInsFromIdentityInformation(
          this.lpsInfo,
          patientInfo.name,
          patientInfo.given,
          IcanopeeInsiService.getSexNumber(patientInfo.sex),
          patientInfo.date,
          patientInfo.place || "",
          function (e: any) {
            if (e.error) {
              return reject(e);
            } else {
              const birthDate = e.Identity.s_birthDate;
              const parsedBirthDate = birthDate.split("-");
              const patient: Patient = {
                location: PatientLocation.NONE,
                birthPlace: e.Identity.s_birthPlace,
                birthdate: {
                  day: parsedBirthDate[2],
                  month: parsedBirthDate[1],
                  year: parsedBirthDate[0],
                },
                firstName: e.Identity.s_birthGiven,
                lastName: e.Identity.s_birthName,
                sex: IcanopeeInsiService.getPatientSex(e.Identity.i_sex),
                socialSecurityNumber:
                  e.Identity.Ins.s_value + e.Identity.Ins.s_key,
              };
              return resolve(patient);
            }
          }
        );
      } catch (e) {
        console.error("Failed to get patient information", e);
        throw e;
      }
    });
  }

  /**
   * Test values:
   * name = "ADRUN";
   * given = "ZOE";
   * sex = 3;
   * date = "1975-12-31";
   * ins = "2751263220749";
   * key = "74";
   * oid = "1.2.250.1.213.1.4.8";
   */
  private checkInsIdentity(
    dmpConnectInstance: DmpSession,
    identity: InsiInfo
  ): Promise<boolean> {
    const oid = identity.oid || "1.2.250.1.213.1.4.8";

    return new Promise<boolean>((resolve, reject) => {
      try {
        dmpConnectInstance._errorHandler = (e: any) => {
          const insiError: InsiError = IcanopeeInsiService.parseInsiError(e);
          if (insiError === InsiError.UNEXPECTED_ERROR) {
            if (e.i_apiErrorCode === 3 && e.i_apiErrorType === 1) {
              if (e.s_apiErrorExtendedInformations.includes("KO INS")) {
                // When the validation fails, the lib sends an error
                return resolve(false);
              }
            }
          }
          return reject(insiError);
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dmpConnectInstance.hl_checkInsIdentity(
          this.lpsInfo,
          identity.ins,
          identity.key,
          oid,
          identity.name.toUpperCase(),
          identity.given.toUpperCase(),
          IcanopeeInsiService.getSexNumber(identity.sex),
          identity.date,
          identity.place || "",
          function (e: any) {
            if (e.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          }
        );
      } catch (e) {
        console.error("Failed to check INSI identity", e);
        resolve(false);
      }
    });
  }

  private static getSexNumber(sex: InsiSex) {
    let res = 1; // UNKNOWN
    if (sex === InsiSex.MALE) {
      res = 2;
    } else if (sex === InsiSex.FEMALE) {
      res = 3;
    }
    return res;
  }

  private static getPatientSex(sexNb: number): "MALE" | "FEMALE" | undefined {
    if (sexNb === 2) {
      return "MALE";
    } else if (sexNb === 3) {
      return "FEMALE";
    }
    return undefined;
  }

  private static parseInsiError(e: any): InsiError {
    console.error("getting error from", e);
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 9) {
      // CPX_MISSING error: No CPS found in the smartcard reader.
      return InsiError.CARD_ERROR;
    }
    if (
      e.s_apiErrorDescription.includes(
        "At least one of the function parameters"
      )
    ) {
      return InsiError.WRONG_PARAMETERS;
    } else if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 2) {
      return InsiError.SERVER_ERROR;
    }
    if (e.i_apiErrorType === 1 && e.i_apiErrorCode === 3) {
      if (e.s_apiErrorExtendedInformations.includes("siram_60")) {
        return InsiError.CARD_ERROR;
      }
      if (e.s_apiErrorExtendedInformations.includes("siram_40")) {
        return InsiError.SERVICE_UNAVAILABLE;
      }
      if (e.s_apiErrorExtendedInformations.includes("siram_20")) {
        return InsiError.MISSING_RIGHTS;
      }
    }
    return InsiError.UNEXPECTED_ERROR;
  }
}
