import { get, send } from "./ApiClient";
import { InvoicingInfo } from "../domain/InvoicingInfo";
import { Facility } from "../domain/Facility";

export async function searchFacility(
  name: string,
  area: string,
  page: number
): Promise<Facility[] | Error> {
  try {
    return await get(
      `/facilities/search?name=${name}&area=${area}&page=${page}`
    );
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getFacility(id: string): Promise<Facility | Error> {
  try {
    const facility = await get("/facility/" + id);
    if (facility.ghtId != "") {
      facility.ght = await get("/ght/" + facility.ghtId);
    }
    return facility;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getFacilityByFiness(
  finess: string
): Promise<Facility | Error> {
  try {
    return await get(`/facilities/search/finess?query=${finess}`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function listFacilitiesToInvoiceByMonth(
  month: number,
  year: number
): Promise<InvoicingInfo[] | Error> {
  try {
    return await get(`/invoices-info?month=${month}&year=${year}`);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function sendInvoicingInfoByEmail(
  invoicingInfoId: string,
  facilityId: string,
  month: number,
  year: number
): Promise<any | Error> {
  try {
    return await get(
      `/invoices-info/send-email?invoicingInfoId=${invoicingInfoId}&facilityId=${facilityId}&month=${month}&year=${year}`
    );
  } catch (err) {
    console.error(err);
    return err;
  }
}

export function updateFacilityEmail(
  invoicingInfoId: string,
  email: string
): Promise<any | Error> {
  return send(
    `/invoices-info/update-email?invoicingInfoId=${invoicingInfoId}&email=${email}`,
    "PUT"
  );
}

export async function createFacilityInvoiceInfo(
  facilityId: string,
  month: number,
  year: number,
  email: string
): Promise<string | Error> {
  const res = await send<InvoicingInfo>(`/invoices-info`, "POST", {
    email,
    month,
    year,
    facilityId,
  });
  if (res instanceof Error) {
    return res;
  } else {
    return res.id;
  }
}
