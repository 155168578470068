import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import RequestPrimaryType, {
  requestPrimaryTypeTranslate,
} from "../../../domain/Subject/RequestPrimaryType.enum";
import { enumAndTranslationToItemElement, LabelValue } from "./SubjectForm";
import styles from "./SubjectForm.module.css";

const animatedComponents = makeAnimated();

interface Props {
  value: RequestPrimaryType | null;
  onChange: (value: RequestPrimaryType | null) => any;
}

export const RequestTypeSelect: React.FC<Props> = (props: Props) => {
  const { control } = useForm<any>();
  const requestTypeOptions: LabelValue<
    RequestPrimaryType
  >[] = enumAndTranslationToItemElement(
    RequestPrimaryType,
    requestPrimaryTypeTranslate
  );

  const [selectedOption, setSelectedOption] = useState<LabelValue<
    RequestPrimaryType
  > | null>(() => {
    if (props.value) {
      return {
        value: props.value,
        label: requestPrimaryTypeTranslate[props.value],
      };
    }
    return null;
  });

  useEffect(() => {
    if (selectedOption) {
      props.onChange(selectedOption.value);
    } else {
      props.onChange(null);
    }
  }, [selectedOption]);

  return (
    <Controller
      name="requestOptions"
      control={control}
      render={(): any => (
        <Select<LabelValue<RequestPrimaryType>>
          id="select-request-options"
          instanceId="request-type"
          name="requestOptions"
          options={requestTypeOptions}
          value={selectedOption}
          onChange={setSelectedOption}
          isClearable={true}
          components={animatedComponents}
          className={`w-90 clickable ${styles.selectOptions}`}
          closeMenuOnSelect={true}
          isSearchable={false}
          placeholder="Sélectionner le motif de l'EEG"
          styles={{
            control: (): Record<string, any> => ({
              borderRadius: "6px",
              border: "solid 2px var(--primary-blue)",
              boxShadow: "var(--primary-blue)",
              display: "flex",
            }),
            option: (provided, state): Record<string, any> => ({
              ...provided,
              backgroundColor: state.isFocused
                ? "var(--secondary-blue)"
                : "white",
              cursor: state.isDisabled ? "not-allowed!important" : "",
              ":hover": {
                backgroundColor: state.isDisabled
                  ? ""
                  : "var(--secondary-blue)",
              },
              color: state.isDisabled ? "var(--placeholder-grey)" : "black",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }),
            input: (): Record<string, any> => ({
              position: "absolute",
            }),
          }}
        />
      )}
    />
  );
};
