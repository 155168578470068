import React from "react";
import styles from "./ToggleSwitch.module.css";

interface Props {
  key: number;
  toggled: boolean;
  onToggleChange: () => any;
}

export const ToggleSwitch: React.FC<Props> = (props: Props) => {
  const { key, toggled, onToggleChange } = props;

  let isNotToggled: string;
  let isToggled: string;
  let isButtonDisabled = "";
  let isDisabled = "";
  if (toggled) {
    isNotToggled = "hidden";
    isToggled = "";
  } else {
    isNotToggled = "";
    isToggled = "hidden";
    isButtonDisabled = "notToggledSwitch";
    isDisabled = "notToggledSwitchContainer";
  }

  return (
    <div key={key}>
      <button
        className={`row space-between-content ${styles.switchContainer} ${styles[isButtonDisabled]}`}
        onClick={onToggleChange}
      >
        <div
          className={`col ${styles.insideSwitch} ${styles[isNotToggled]} ${styles[isDisabled]}`}
        />
        <div className={`col ${styles.insideSwitch} ${styles[isToggled]}`} />
      </button>
    </div>
  );
};
