import PathologyEnum from "./Pathology.enum";
import SpecialtyEnum from "./Specialty.enum";
import SubSpecialtyEnum from "./SubSpecialty.enum";

export interface PathologyTree {
  name: string;
  subTrees?: PathologyTree[];
  pathologies?: PathologyEnum[];
}

const flattenTree = (tree: PathologyTree): PathologyEnum[] => {
  let list = tree.pathologies || [];
  tree.subTrees?.forEach((subTree) => {
    list = [...list, ...flattenTree(subTree)];
  });
  return list;
};

export const neuroDiseaseTrees: PathologyTree[] = [
  {
    name: "Neurovasculaire",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_ACCIDENT_ISCHEMIQUE_CEREBRAL,
      PathologyEnum.NEURO_DISEASE_ACCIDENT_HEMORRAGIQUE_CEREBRAL,
      PathologyEnum.NEURO_DISEASE_HEMORRAGIE_SOUS_ARACHNOIDIENNE,
      PathologyEnum.NEURO_DISEASE_ISCHEMIE_MEDULLAIRE,
      PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_NEUROVASCULAIRE,
    ],
  },
  {
    name: "Neurodégénérative",
    subTrees: [
      {
        name: "Démence",
        pathologies: [
          PathologyEnum.NEURO_DISEASE_MALADIE_ALZHEIMER,
          PathologyEnum.NEURO_DISEASE_DEMENCE_ACORPS_DE_LEWY,
          PathologyEnum.NEURO_DISEASE_DEMENCE_VASCULAIRE,
          PathologyEnum.NEURO_DISEASE_AUTRE_DEMENCE,
        ],
      },
      {
        name: "Maladie Extrapyramidale",
        pathologies: [
          PathologyEnum.NEURO_DISEASE_MALADIE_DE_PARKINSON,
          PathologyEnum.NEURO_DISEASE_ATROPHIE_MULTI_SYSTEMATISEE,
          PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_EXTRAPYRAMIDALE,
        ],
      },
    ],
  },
  {
    name: "Neuro-inflammatoire",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_SCLEROSE_EN_PLAQUE,
      PathologyEnum.NEURO_DISEASE_ENCEPHALITE_AUTO_IMMUNE,
      PathologyEnum.NEURO_DISEASE_MYELITE,
      PathologyEnum.NEURO_DISEASE_ADEM,
      PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_NEURO_INFLAMATOIRE,
    ],
  },
  {
    name: "Neuro-infectiologie",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_MENINGITE_BACTERIENNE,
      PathologyEnum.NEURO_DISEASE_MENINGO_ENCEPHALITE_VIRALE,
      PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_INFECTIOLOGIE,
    ],
  },
  {
    name: "Epilepsie",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_ETAT_DE_MAL_EPILEPTIQUE,
      PathologyEnum.NEURO_DISEASE_MALADIE_EPILEPTIQUE,
      PathologyEnum.NEURO_DISEASE_CRISE_INAUGURALE,
    ],
  },
  {
    name: "Neuro-oncologie",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_TUMEUR_PRIMITIVE_CEREBRALE,
      PathologyEnum.NEURO_DISEASE_METASTASE,
      PathologyEnum.NEURO_DISEASE_MENINGITE_CARCINOMATEUSE,
      PathologyEnum.NEURO_DISEASE_ENCEPHALITE_PARANEOPLASIQUE,
      PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_DE_NEURO_ONCOLOGIE,
    ],
  },
  {
    name: "Neurotraumatologie",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_TRAUMATISME_CRANIEN,
      PathologyEnum.NEURO_DISEASE_HYPERTENSION_INTRACRANIENNE,
    ],
  },
  {
    name: "Neuro-métabolique",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_ENCEPHALOPATHIE_CARENTIELLE,
      PathologyEnum.NEURO_DISEASE_ENCEPHALOPATHIE_TOXIQUE,
      PathologyEnum.NEURO_DISEASE_TROUBLE_INNE_DU_METABOLISME,
      PathologyEnum.NEURO_DISEASE_ENCEPHALOPATHIE_HEPATIQUE,
    ],
  },
  {
    name: "Neuromusculaire",
    pathologies: [
      PathologyEnum.NEURO_DISEASE_SCLEROSE_LATERALE_AMYOTROPHIQUE,
      PathologyEnum.NEURO_DISEASE_POLYRADICULONEUROPATHIE,
      PathologyEnum.NEURO_DISEASE_MYASTHENIE_AUTO_IMMUNE,
      PathologyEnum.NEURO_DISEASE_MYOSITE,
      PathologyEnum.NEURO_DISEASE_MYOPATHIE,
      PathologyEnum.NEURO_DISEASE_AUTRE_MALADIE_NEUROMUSCULAIRE,
    ],
  },
];
export const neuroSymptomTrees: PathologyTree[] = [
  {
    name: "Trouble de la conscience",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_COMA,
      PathologyEnum.NEURO_SYMPTOM_ETAT_VEGETATIF_OU_CONSCIENCE_MINIMALE,
      PathologyEnum.NEURO_SYMPTOM_CONFUSION,
      PathologyEnum.NEURO_SYMPTOM_ABSENCE,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_TROUBLE_CONSCIENCE,
    ],
  },
  {
    name: "Trouble moteur",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_DEFICIT_FAIBLESS_OU_FATIGUABILITE,
      PathologyEnum.NEURO_SYMPTOM_MYALGIE,
      PathologyEnum.NEURO_SYMPTOM_SYNDROME_PYRAMIDAL,
      PathologyEnum.NEURO_SYMPTOM_SYNDROME_EXTRAPYRAMIDAL,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_MARCHE_OU_EQUILIBRE,
      PathologyEnum.NEURO_SYMPTOM_ATAXIE,
      PathologyEnum.NEURO_SYMPTOM_AKINESIE,
      PathologyEnum.NEURO_SYMPTOM_HYPERTONIE_RIGIDITE,
      PathologyEnum.NEURO_SYMPTOM_MYOCLONIE,
      PathologyEnum.NEURO_SYMPTOM_TREMBLEMENTS,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_TROUBLE_MOTEUR,
    ],
  },
  {
    name: "Trouble de la sensibilité",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_DOULEUR_NEUROPATHIQUE,
      PathologyEnum.NEURO_SYMPTOM_RADICULALGIE,
      PathologyEnum.NEURO_SYMPTOM_PARESTHESIE,
      PathologyEnum.NEURO_SYMPTOM_ATAXIE_PROPRIOCEPTIVE,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_TROUBLE_SENSIBILITE,
    ],
  },
  {
    name: "Trouble cognitif",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_MNESIQUE,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_PHASIQUE,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_PRAXIQUE,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_TROUBLE_COGNITIF,
    ],
  },
  {
    name: "Atteinte des nerfs crâniens",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_PARALYSIE_FACIALE,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_DEGLUTITION,
      PathologyEnum.NEURO_SYMPTOM_DYSARTHRIE,
      PathologyEnum.NEURO_SYMPTOM_NEVRALGIE,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_ATTEINTE_DES_NERFS_CRANIENS,
    ],
  },
  {
    name: "Divers",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_DYSTONIE,
      PathologyEnum.NEURO_SYMPTOM_DYSPHONIE,
      PathologyEnum.NEURO_SYMPTOM_DYSPNEE,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_URINAIRE,
      PathologyEnum.NEURO_SYMPTOM_TROUBLE_HUMEUR,
      PathologyEnum.NEURO_SYMPTOM_HALLUCINATIONS,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_SYMPTOMES_NEUROLOGIQUES,
    ],
  },
  {
    name: "Douleur",
    pathologies: [
      PathologyEnum.NEURO_SYMPTOM_DOULEUR_MYALGIE,
      PathologyEnum.NEURO_SYMPTOM_DOULEUR_NEVRALGIE,
      PathologyEnum.NEURO_SYMPTOM_DOULEUR_RADICULALGIE,
      PathologyEnum.NEURO_SYMPTOM_DOULEUR_PARESTHESIE,
      PathologyEnum.NEURO_SYMPTOM_AUTRE_DOULEUR,
    ],
  },
];

export const mprDiseaseTrees: PathologyTree[] = [
  {
    name: "Neurologique",
    pathologies: [
      PathologyEnum.MPR_DISEASE_NEURO_STROKE,
      PathologyEnum.MPR_DISEASE_NEURO_MUSCULAR_MYOPATHY,
      PathologyEnum.MPR_DISEASE_NEURO_PERIF_NEURO_POLY_RADICULO_NEURITIS,
      PathologyEnum.MPR_DISEASE_NEURO_HEAD_TRAUMA,
      PathologyEnum.MPR_DISEASE_NEURO_SUBARACH_HEMORRHAGE,
      PathologyEnum.MPR_DISEASE_NEURO_PARKINSON_NEURO_DEGENERATIVE,
      PathologyEnum.MPR_DISEASE_NEURO_DYSTONIA,
      PathologyEnum.MPR_DISEASE_NEURO_AMYOTROPHIC_LATERAL_SCLEROSIS,
      PathologyEnum.MPR_DISEASE_NEURO_MUTIPLE_SCLEROSIS,
      PathologyEnum.MPR_DISEASE_NEURO_CEREBRAL_PALSY_MULTICHILDHOOD_DISEASE,
      PathologyEnum.MPR_DISEASE_NEURO_BRRAIN_TUMOUR,
    ],
  },
  {
    name: "Locomoteur",
    pathologies: [
      PathologyEnum.MPR_DISEASE_LOCOMOTIVE_NEURALGIA_STRONG,
      PathologyEnum.MPR_DISEASE_LOCOMOTIVE_NEURALGIA_CHRONIC,
      PathologyEnum.MPR_DISEASE_LOCOMOTIVE_ARTHROSIS,
      PathologyEnum.MPR_DISEASE_LOCOMOTIVE_RHEUMATOID_ARTHRITIS,
      PathologyEnum.MPR_DISEASE_LOCOMOTIVE_SPONDYLITIS,
    ],
  },
];
export const mprSymptomTrees: PathologyTree[] = [
  {
    name: "",
    pathologies: [
      PathologyEnum.MPR_SYMPTOM_SPINAL_CORD_INJURY,
      PathologyEnum.MPR_SYMPTOM_FITTINGS,
      PathologyEnum.MPR_SYMPTOM_CAUDA_EQUINA_SYNDROME,
      PathologyEnum.MPR_SYMPTOM_BEDSORES,
      PathologyEnum.MPR_SYMPTOM_GAIT_BALANCE_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_URINATION_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_URINARY_FUNCTIONAL_SIGNS,
      PathologyEnum.MPR_SYMPTOM_SPINAL_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_COMPLEX_REGIONAL_PAIN_SYNDROME,
      PathologyEnum.MPR_SYMPTOM_SHOULDER_HAND_SYNDROME,
      PathologyEnum.MPR_SYMPTOM_MOTOR_DISORDERS_SPASTICITY,
      PathologyEnum.MPR_SYMPTOM_ORTHOPEDIC_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_SWALLOWING_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_COGNITIVE_BEHAVIORAL_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_TRACHEOTOMY,
      PathologyEnum.MPR_SYMPTOM_SLEEPING_DISORDERS,
      PathologyEnum.MPR_SYMPTOM_CHRONIC_PAIN,
      PathologyEnum.MPR_SYMPTOM_PATIENT_UNDER_MECHANICAL_VENTILATION,
      PathologyEnum.MPR_SYMPTOM_SLEEP_APNEA,
    ],
  },
];

export const pathologiesBySpecialty: Partial<Record<SpecialtyEnum, any[]>> = {
  [SpecialtyEnum.SPECIALTY_NEURO]: [
    ...neuroDiseaseTrees.flatMap(flattenTree),
    ...neuroSymptomTrees.flatMap(flattenTree),
  ],
  [SpecialtyEnum.SPECIALTY_MPR]: [
    ...mprDiseaseTrees.flatMap(flattenTree),
    ...mprSymptomTrees.flatMap(flattenTree),
  ],
};

export const pathologyTreesBySubSpecialty: Partial<Record<
  SubSpecialtyEnum,
  PathologyTree[]
>> = {
  [SubSpecialtyEnum.NEURO_DISEASES]: neuroDiseaseTrees,
  [SubSpecialtyEnum.NEURO_SYMPTOMS]: neuroSymptomTrees,
  [SubSpecialtyEnum.MPR_DISEASES]: mprDiseaseTrees,
  [SubSpecialtyEnum.MPR_SYMPTOMS]: mprSymptomTrees,
};
