import { getMinimizedTitleLabel, User } from "../../../../domain/User";
import React from "react";
import { Figure } from "../../../Shared/Figure";
import { expertiseLevelToString } from "../../../../domain/ExpertiseLevels";
import "./Cards.css";
import { Expert } from "../../../../domain/OpinionRequest";
import { Label } from "../../../Shared/Label";
import { ExpertStatus } from "../../../../domain/ExpertStatus";

interface Props {
  expert: User;
  assignedExpert?: Expert[];
  suggestedExpert?: boolean;
}

/*Expert card : two different display, one for the dispatcher with all experts and
one for the requester and admin only with experts who accepted the request*/
export const ParticipantExpertCard: React.FC<any> = (props: Props) => {
  return (
    <div
      className="p-relative w-100 mb-2 partipant-card"
      onClick={(event): void => {
        event.preventDefault();
      }}
    >
      <div
        className={`expert-legend ${
          props.suggestedExpert ? "suggested-expert-legend" : ""
        }`}
      >
        EXPERT {props.suggestedExpert ? "REQUIS" : ""}
      </div>
      <div className="card-body participant-card-body">
        <div className="container">
          <div className="flex-row mb-1 mt-2 ">
            <div className="figure-size">
              <Figure
                firstName={props.expert.firstName}
                lastName={props.expert.lastName}
                fontSize={"text-medium"}
              />
            </div>
            <div className="participant-header">
              <div className="card-patient">
                {`${getMinimizedTitleLabel(props.expert.professionalTitle)} ${
                  props.expert.firstName
                } ${props.expert.lastName}`}
              </div>
              <div className="text-bold profession">
                {props.expert.profession ? props.expert.profession : "N/A"}
                {props.expert.rpps ? " - RPPS : " + props.expert.rpps : ""}
              </div>
            </div>
            {props.suggestedExpert &&
              !props.assignedExpert?.some(
                (e) => e.userId === props.expert.id
              ) && (
                <div className="ml-auto">
                  <Label color="suggestedExpert" type="">
                    Expert requis
                  </Label>
                </div>
              )}
            {props.assignedExpert !== undefined && (
              <div className="ml-auto">
                {props.assignedExpert.find(
                  (expert) => expert.userId === props.expert.id
                )?.status === ExpertStatus.ACCEPTED ? (
                  <Label color="" type="">
                    Accepté
                  </Label>
                ) : props.assignedExpert.find(
                    (expert) => expert.userId === props.expert.id
                  )?.status === ExpertStatus.REFUSED ? (
                  <Label color="alert" type="">
                    Refusé
                  </Label>
                ) : (
                  <Label color="waitingExpert" type="">
                    En attente
                  </Label>
                )}
              </div>
            )}
          </div>
          <div className="expert-grid-container">
            <div className="specialty">
              <label className="text-bold mr-2">Spécialité</label>
              {props.expert.specialty ? (
                <div>{props.expert.specialty}</div>
              ) : (
                <div>Non renseignée</div>
              )}
            </div>
            <div className="sub-specialty">
              <label className="text-bold mr-2">Sous-spécialités</label>
              {props.expert.subSpecialties?.length === 0 && (
                <div>Non renseignées</div>
              )}
              {props.expert.subSpecialties?.map((subSpeciality, index) => {
                return (
                  <div className="row w-100" key={index}>
                    <div className="sub-spe">
                      {subSpeciality.name}
                      {" - "}
                      {expertiseLevelToString(
                        subSpeciality.expertiseLevel
                      ).toLocaleUpperCase()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
