import React, { useEffect, useState } from "react";
import { LoadingStatus } from "../utils/LoadingStatus";
import { listUsers } from "../services/UserService";
import { User } from "../domain/User";
import { toast } from "react-toastify";
import Select from "react-select";

interface Props {
  onChange: (selectedUser: User) => any;
  idsToRemove: string[];
}

export const SearchUser: React.FC<any> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [users, setUsers] = useState<User[]>([]);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    listUsers().then((res: User[] | Error) => {
      if (res instanceof Error) {
        setStatus(LoadingStatus.ERROR);
        toast.error("Erreur durant le chargement des utilisateurs");
      } else {
        if (props.idsToRemove.length > 0) {
          setUsers(res.filter((u: User) => !props.idsToRemove.includes(u.id)));
        } else {
          setUsers(res);
        }
        setStatus(LoadingStatus.SUCCESS);
      }
    });
  }, [props.idsToRemove]);

  useEffect(() => {
    if (users.length > 0) {
      setOptions(
        users.map((item) => {
          return { value: item, label: item.firstName + " " + item.lastName };
        })
      );
    }
  }, [users]);

  const handleChange = (selectedOption: any): void => {
    props.onChange(selectedOption.value);
  };

  return (
    <div>
      {status !== LoadingStatus.ERROR && (
        <Select
          options={options}
          isSearchable={true}
          placeholder="Rechercher un utilisateur"
          onChange={handleChange}
        />
      )}
    </div>
  );
};

/* TODO delete ?
SearchUser.defaultProps = {
  idsToRemove: [],
};*/
