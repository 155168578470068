import React, { useEffect, useState } from "react";
import { EmergencyLevel } from "../../domain/EmergencyLevel";

export const EmergencyCheck: React.FC<any> = (props: {
  emergency: EmergencyLevel;
  changed: (newState: EmergencyLevel) => any;
  readOnly?: any;
  isRequired: boolean;
}) => {
  const readOnly = props.readOnly;
  const [radioButtonError, setRadioButtonError] = useState<string>("");

  useEffect((): any => {
    props.isRequired && props.emergency === EmergencyLevel.NONE
      ? setRadioButtonError("form-icon-error")
      : setRadioButtonError("");
  }, [props.isRequired]);

  return (
    <div className="padding-bottom-4">
      <div className="form-title mb-0">
        Indiquez le degré d&apos;urgence de votre demande.
        <span className="error-msg text-large ml-2">*</span>
      </div>
      <div className="mt-4 primary-blue-text">
        Pour une urgence immédiate {"->"}
        <span className="text-bold ml-1">Contactez le 15</span>
      </div>
      <div className="form-group margin-top-2">
        <label className="form-radio">
          <input
            type="radio"
            value={EmergencyLevel.DELAYED_EMERGENCY}
            checked={props.emergency === EmergencyLevel.DELAYED_EMERGENCY}
            onChange={(): any => {
              props.changed(EmergencyLevel.DELAYED_EMERGENCY);
              setRadioButtonError("");
            }}
            disabled={readOnly}
          />
          <i className={`form-icon ${radioButtonError}`} />
          <span className="text-bold ml-1 c-hand">Urgence différée</span>
          <span className="text-small c-hand ml-1">(moins de 24h)</span>
        </label>
        <label className="form-radio mt-2">
          <input
            type="radio"
            value={EmergencyLevel.NOT_URGENT}
            checked={props.emergency === EmergencyLevel.NOT_URGENT}
            onChange={(): any => {
              props.changed(EmergencyLevel.NOT_URGENT);
              setRadioButtonError("");
            }}
            disabled={readOnly}
          />
          <i className={`form-icon ${radioButtonError}`} />
          <span className="text-bold ml-1 c-hand">Non urgent</span>
        </label>
      </div>
      {props.isRequired && props.emergency === EmergencyLevel.NONE && (
        <div className="error-msg">
          Merci de renseigner le degré d&apos;urgence
        </div>
      )}
    </div>
  );
};
