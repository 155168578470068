import React, { useContext, useEffect, useState } from "react";
import OpinionRequest, {
  canUserAddDocumentToOpinionRequest,
} from "../../../../domain/OpinionRequest";
import { RequestStatus } from "../../../../domain/RequestStatus";
import { AppContext } from "../../../../utils/state";
import {
  deleteImagingStudy,
  uploadDicomImage,
} from "../../../../services/OpinionRequestService";
import { errorToast, infoToast, successToast } from "../../../../utils/Toast";
import { CardDisplay } from "./CardDisplay";
import { LineDisplay } from "./LineDisplay";
import { Prompt } from "react-router-dom";

export enum DisplayType {
  LINE_DISPLAY,
  CARD_DISPLAY,
}

export interface UploadProgress {
  cpt: number;
  total: number;
}

interface Props {
  request: OpinionRequest;
  updateRequest: () => any;
  displayType?: DisplayType;
}

export const ImagingDocumentUpload: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [uploadProgress, setUploadProgress] = useState<
    Map<string, UploadProgress>
  >(new Map<string, UploadProgress>());

  const [readOnly, setReadOnly] = useState<boolean>(true);

  useEffect(() => {
    setReadOnly(
      !(
        props.request &&
        props.request?.status !== RequestStatus.CLOSED &&
        canUserAddDocumentToOpinionRequest(
          appContext.state.user,
          props.request ?? undefined
        )
      )
    );
  }, [props.request, appContext.state.user]);

  // corresponding to magic number of DICOM files (DICM)
  const validDicomSignature = "4449434d";

  // corresponding to mime type of DICOM files (DICM)
  const dicomMime = "application/dicom";

  const isDicomFile = async (f: File): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      // do not import dicomdir file
      if (f.name && f.name.toUpperCase().includes("DICOMDIR")) {
        return resolve(false);
      }
      if (f.type && f.type === dicomMime) {
        return resolve(true);
      }
      //else use file reader to read
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const arr = new Uint8Array(e.target.result);
        let header = "";
        // Magic number at offset 128
        // i.e : https://en.wikipedia.org/wiki/List_of_file_signatures
        if (arr.length > 132) {
          for (let j = 128; j < 132; j++) {
            header += arr[j] && arr[j].toString(16);
          }
        }
        return resolve(header === validDicomSignature);
      };
      fileReader.readAsArrayBuffer(f);
    });
  };

  function mapAsync<T, U>(
    array: T[],
    callbackfn: (value: T, index: number, array: T[]) => Promise<U>
  ): Promise<U[]> {
    return Promise.all(array.map(callbackfn));
  }

  async function filterAsync<T>(
    array: T[],
    callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>
  ): Promise<T[]> {
    const filterMap = await mapAsync(array, callbackfn);
    return array.filter((value, index) => filterMap[index]);
  }

  async function onChangeFileHandler(event: any): Promise<void> {
    const progressId = Date.now().toString();
    event.persist();
    if (!props.request?.id) {
      return;
    }

    const files: File[] = await filterAsync(
      [...event.target.files],
      isDicomFile
    );
    if (files.length === 0) {
      errorToast(
        event.target.files.length === 1
          ? "Seul un fichier DICOM peut-être sélectionné"
          : "Aucun fichier DICOM trouvé dans le dossier selectionné"
      );
      return;
    } else if (files.length < event.target.files.length) {
      infoToast(
        "Attention seuls les fichiers d'imagerie médicale (DICOM) sont pris en compte lors de l'import d'un dossier"
      );
    }
    event.target.value = null;
    let uploadIsSuccess = true;
    let cpt = 0;
    const total = files.length;
    startingUploadImaging(progressId, total);
    for (const f of files) {
      const data = new FormData();
      data.append("file", f);
      const res = await uploadDicomImage(props.request.id, data);
      if (res instanceof Error) {
        uploadIsSuccess = false;
        errorToast("Echec de l'envoi du fichier");
        break;
      }
      cpt++;
      setUploadProgress((prevState) => {
        prevState.set(progressId, { cpt, total });
        return new Map(prevState);
      });
    }

    if (uploadIsSuccess) {
      successToast("Fichier(s) sauvegardé(s)");
    }
    setTimeout(() => {
      finishUploadImaging(progressId);
    }, 300);
  }

  function runOnExitWebappInProgress(event: any): string {
    const confirmationMessage = "Il y a des images en cours d'upload.";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  function startingUploadImaging(proressId: string, total: number): void {
    setUploadProgress((prevState) => {
      prevState.set(proressId, { cpt: 0, total });
      return new Map(prevState);
    });
    window.addEventListener("beforeunload", runOnExitWebappInProgress);
  }

  function finishUploadImaging(progressId: string): void {
    setUploadProgress((prevState) => {
      prevState.delete(progressId);
      return new Map(prevState);
    });
    window.removeEventListener("beforeunload", runOnExitWebappInProgress);
    props.updateRequest();
  }

  async function onDeleteImagingStudy(studyInstanceId: string): Promise<void> {
    if (!props.request?.id) {
      return;
    }

    const res = await deleteImagingStudy(
      props.request?.id ?? "",
      studyInstanceId
    );
    if (res instanceof Error) {
      errorToast("Echec de la suppression du fichier");
      return;
    }
    successToast("Fichier supprimé");
    props.updateRequest();
  }

  const imagingStudies = props.request?.imagingStudies;

  const uploadInProgress = uploadProgress.size > 0;

  if (!props.request?.id) {
    return (
      <div>
        <h4 className="form-title">Imagerie</h4>
        <div>
          Vous devez enregistrer la demande avant de pouvoir ajouter des pièces
          jointes
        </div>
      </div>
    );
  }

  return (
    <>
      <Prompt
        when={uploadInProgress}
        message="Il y a des images en cours d'upload, celui-ci se poursuivra tant que vous resterez sur l'application"
      />
      {props.displayType === DisplayType.CARD_DISPLAY ? (
        <CardDisplay
          id="input-imaging"
          documents={imagingStudies ?? []}
          request={props.request}
          onDelete={onDeleteImagingStudy}
          onChangeFileHandler={onChangeFileHandler}
          disabledButton={readOnly}
          uploadProgress={uploadProgress}
          sectionTitle={"Imagerie"}
          hasFolderInput={true}
          fileInputLabel={
            <div>
              Ajouter une <span className="bold-bigger-text">image</span>{" "}
              médicale
            </div>
          }
          folderInputLabel={
            <>
              Ajouter un <span className="bold-bigger-text">dossier</span>{" "}
              d&apos;imagerie
            </>
          }
          inputAccept={"*/dicom, .dcm, image/dcm, */dcm, .dicom"}
        />
      ) : (
        <LineDisplay
          id="input-imaging"
          documents={imagingStudies ?? []}
          requestId={props.request?.id ?? ""}
          onDelete={onDeleteImagingStudy}
          onChangeFileHandler={onChangeFileHandler}
          disabledButton={readOnly}
          mainTitle={"Imagerie"}
          subTitle={"Ajouter une image médicale depuis votre ordinateur."}
          hasFolderInput={true}
          uploadProgress={uploadProgress}
          inputAccept={"*/dicom, .dcm, image/dcm, */dcm, .dicom"}
        />
      )}
    </>
  );
};
