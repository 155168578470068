import { Brands, BrandUri } from "./Brands";

export interface WebappConfig {
  apiUrl: string;
  version: string;
  brand: Brands;
  environment: string;
  orthancUrl: string;
  icanopeeLicense: string;
  dmpFeature: boolean;
  crhFeature: boolean;
  insiFeature: boolean;
  plausibleApiUrl: string;
}

// eslint-disable-next-line no-undef
const version = process.env.REACT_APP_VERSION || "unknown";

console.info("Aiintense Neuromonitor frontend version " + version);

const config: WebappConfig = {
  // eslint-disable-next-line no-undef
  apiUrl: process.env.REACT_APP_API_URL || (window as any).env.apiUrl,
  version: version,
  brand: getBrand() || (window as any).env.brand,
  environment:
    process.env.REACT_APP_ENVIRONMENT || (window as any).env.environment,
  icanopeeLicense:
    process.env.REACT_APP_ICANOPEE_LICENSE ||
    (window as any).env.icanopeeLicense,
  orthancUrl:
    process.env.REACT_APP_ORTHANC_URL || (window as any).env.orthancUrl,
  dmpFeature: getBooleanFromEnvVariable(
    process.env.REACT_APP_DMP_FEATURE || (window as any).env.dmpFeature
  ),
  crhFeature: getBooleanFromEnvVariable(
    process.env.REACT_APP_CRH_FEATURE || (window as any).env.crhFeature
  ),
  insiFeature: getBooleanFromEnvVariable(
    process.env.REACT_APP_INSI_FEATURE || (window as any).env.insiFeature
  ),
  plausibleApiUrl:
    process.env.REACT_APP_PLAUSIBLE_API_URL || "https://plausible.io",
};

function getBooleanFromEnvVariable(value: any): boolean {
  switch (value) {
    case true:
    case "true":
      return true;
    default:
      return false;
  }
}

// This function is used to disable features on prod and preprod
export const isNotProd = (): boolean => {
  const notProdEnvs = ["local", "dev"];
  return notProdEnvs.includes(config.environment);
};

function getBrand(): Brands {
  const url = window.location.origin;
  if (url.includes(BrandUri.TELE_GHU_PARIS)) {
    return Brands.TELE_GHU_PARIS;
  } else if (url.includes(BrandUri.HANDI_NEURO)) {
    return Brands.HANDI_NEURO;
  } else {
    return Brands.D_OPEN_CLNICS;
  }
}

export default config;
