import React, { useState } from "react";
import {
  detectMimeType,
  DmpDocument,
  DmpInfo,
  formatDateWithSlash,
  showDmpError,
} from "../../../services/DmpService";
import { IcanopeeDmpService } from "../../../services/icanopee/IcanopeeDmpService";
import { isStringEmpty } from "../../../utils/stringTools";
import { ModalInfo } from "../../Shared/ModalInfo";
import { DmpDocumentAbstract } from "./DmpDocumentAbstract";
import styles from "./DmpDocumentView.module.css";
import { successToast } from "../../../utils/Toast";

interface Props {
  document: DmpDocument;
  patient: DmpInfo;
  onDelete: () => void;
}

export const DmpDocumentView: React.FC<any> = (props: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [cpsPinCode, setCpsPinCode] = useState<number>(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);

  async function getDmpDocumentByUniqueId(uniqueID: string) {
    const icanopeeDmpService = new IcanopeeDmpService();

    try {
      const dmpDocumentContent = await icanopeeDmpService.getDocumentByUniqueId(
        props.patient,
        uniqueID,
        String(cpsPinCode)
      );
      //TODO: decide if we keep it or not after Icanopee meeting
      /*downloadBase64File(
        getFormatFileLabel(dmpDocumentContent.i_documentFormat),
        dmpDocumentContent.s_fileContentInBase64,
        props.document.s_title
      );*/
      downloadBase64File(
        detectMimeType(dmpDocumentContent.fileContentInBase64),
        dmpDocumentContent.fileContentInBase64,
        props.document.title
      );
    } catch (e) {
      showDmpError(e);
    }
  }

  async function deleteDmpDocument(uniqueID: string) {
    const icanopeeDmpService = new IcanopeeDmpService();

    try {
      await icanopeeDmpService.deleteDocumentByUniqueId(
        props.patient,
        uniqueID,
        String(cpsPinCode)
      );
      props.onDelete();
      successToast("Suppression du document réussie");
    } catch (e) {
      showDmpError(e);
    }
  }

  function downloadBase64File(
    contentType: string,
    base64Data: string,
    fileName: string
  ) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div>
      <div className="row space-between-content flex-1">
        <div>
          <label className="text-primary-blue text-bold">
            {props.document.title}
          </label>
          <label> - </label>
          {!isStringEmpty(props.document.description) &&
            props.document.description + " - "}
          <i>Publié le {formatDateWithSlash(props.document.creationDate)} </i>
        </div>
        <div className="mr-4 row">
          <button
            className="btn neuro-btn rounded-btn"
            onClick={() => getDmpDocumentByUniqueId(props.document.uniqueId)}
          >
            Télécharger
          </button>
          <button
            className={`btn ml-2 ${styles.dmpDeleteBtn}`}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <i className={`far fa-trash-alt ${styles.deleteDocumentIcon}`} />
          </button>
          {showDeleteModal && (
            <ModalInfo
              title={"Confirmation de suppression"}
              onClose={(): any => setShowDeleteModal(false)}
              rightButtonAction={() =>
                deleteDmpDocument(props.document.uniqueId)
              }
              rightButtonText={"Confirmer"}
              leftButtonText={"Annuler"}
            >
              <div className="flex-col">
                <label className="text-bold mb-2">Résumé du document : </label>
                <DmpDocumentAbstract documentInfos={props.document} />
                Une fois supprimé, le document ne sera plus accessible, ni pour
                vous, ni par le patient, ni pour les autres professionnels de
                santé. Il sera exclu des téléchargements en cours. Le document
                sera cependant conservé dans une zone spécifique de sauvegarde.
                <i className="mt-4">
                  Vous êtes sur le point de supprimer un document du DMP.
                </i>
                <div className="mt-2 text-bold">
                  Êtes-vous sûr de vouloir supprimer le document ?
                </div>
              </div>
            </ModalInfo>
          )}
        </div>
      </div>
      {showPinCodeModal && (
        <ModalInfo
          title={"Confirmation de votre identité"}
          onClose={(): any => setShowPinCodeModal(false)}
          rightButtonAction={() => deleteDmpDocument(props.document.uniqueId)}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <div className="flex-col p-2">
            <p>
              Veuillez entrer ci-dessous le code correspondant à votre profil de
              professionnel de la santé:
            </p>
            <input
              type="number"
              className={styles.codeInput}
              defaultValue={""}
              onChange={(event) => setCpsPinCode(parseInt(event.target.value))}
            />
          </div>
        </ModalInfo>
      )}
    </div>
  );
};
