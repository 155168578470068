import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/state";
import { InvoicingAssistance } from "./InvoicingAssistance";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import history from "../../utils/history";
import { Invoicing } from "./Invoicing";
import { BackButton } from "../Shared/BackButton";

enum Tab {
  invoicingAssistanceTab,
  invoicingTab,
}

export const HomeInvoicing: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState<Tab>(
    Tab.invoicingAssistanceTab
  );

  useEffect(() => {
    if (!hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
      history.push("/forbidden");
    }
  }, [appContext.state.user]);

  return (
    <>
      <div className="text-left">
        <BackButton />
      </div>
      <div className="row space-content pt-2">
        <button
          className={
            "request-menu " +
            (selectedTab === Tab.invoicingAssistanceTab
              ? "request-menu-active"
              : "")
          }
          onClick={(): any => setSelectedTab(Tab.invoicingAssistanceTab)}
        >
          Aide à la facturation - AM
        </button>
        <button
          id="billing-tab"
          className={
            "request-menu " +
            (selectedTab === Tab.invoicingTab ? "request-menu-active" : "")
          }
          onClick={(): any => setSelectedTab(Tab.invoicingTab)}
        >
          Facturation
        </button>
      </div>
      {selectedTab === Tab.invoicingAssistanceTab && (
        <div className="bg-light-blue-1 flex-1">
          <InvoicingAssistance />
        </div>
      )}
      {selectedTab === Tab.invoicingTab && (
        <div className="bg-light-blue-1 flex-1">
          <Invoicing />
        </div>
      )}
    </>
  );
};
