import { get, send } from "./ApiClient";
import { Organization } from "../domain/Organization";
import { errorToast, infoToast } from "../utils/Toast";

export async function createOrganization(
  organization: Organization
): Promise<any | Error> {
  try {
    return await send(`/organizations`, "POST", organization);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getAllOrganizations(): Promise<Organization[] | Error> {
  try {
    return await get(`/organizations`, "GET");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function searchOrganizationByName(
  organizationName: string
): Promise<Organization[] | Error> {
  try {
    return await get(`/organizations?name=${organizationName}`, "GET");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function getOrganizationById(
  organizationId: string
): Promise<Organization | Error> {
  try {
    return await get(`/organizations/${organizationId}`, "GET");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function updateOrganization(
  organizationId: string,
  organization: Organization
): Promise<any | Error> {
  try {
    return await send(`/organizations/${organizationId}`, "PUT", organization);
  } catch (err) {
    console.error(err);
    return err;
  }
}

export async function deleteOrganization(
  organizationId: string
): Promise<any | Error> {
  try {
    return await send(`/organizations/${organizationId}`, "DELETE");
  } catch (err) {
    console.error(err);
    return err;
  }
}

export function displayErrorDeletingOrganization(resMessage: string): void {
  if (
    resMessage === "could not delete organization linked to opinion request(s)"
  ) {
    infoToast(
      "Cette organisation est liée à une ou plusieurs demande(s) d'avis. Vous ne pouvez pas la supprimer"
    );
  } else if (
    resMessage === "could not delete organization linked to children"
  ) {
    infoToast(
      "Cette organisation est parente d'une autre organisation. Veuillez supprimer la ou les organisations enfant(s) et recommencer."
    );
  } else {
    errorToast("Erreur lors de la suppression de l'organisation");
  }
}
