import React, { MutableRefObject, useContext, useRef, useState } from "react";
import {
  ProfessionCategory,
  HealthProfessionalUser,
} from "../../../domain/User";
import {
  getPermissionLabel,
  Participant,
} from "../../../domain/OpinionRequest";
import TextareaAutosize from "react-textarea-autosize";
import Modal from "../../Modal";
import { AddNewUser } from "./AddNewUser";
import "./AddParticipants.css";
import { AddCancelButtons } from "./AddCancelButtons";
import { PermissionParticipant } from "./PermissionParticipant";
import { SearchHealthProfessional } from "../SearchHealthProfessional";
import { AppContext } from "../../../utils/state";
import { BadgeUserParticipant } from "./BadgeUserParticipant";

interface Props {
  requestId: string;
  participants: Participant[];
  onClose: () => void;
  onAdd: () => any;
  hasPermissionEdit: boolean;
}

export const AddParticipants: React.FC<Props> = (props: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<HealthProfessionalUser[]>(
    []
  );
  const [shareMessage, setShareMessage] = useState<string>("");
  const [areNewUsers, setAreNewUsers] = useState<boolean[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedPermission, setSelectedPermission] = useState<string[]>([]);
  const currentUser = useContext(AppContext).state.user;
  const user: MutableRefObject<Participant | null> = useRef<Participant | null>(
    props.participants.find((u) => u.userId === currentUser.id) ?? null
  );
  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();

  const closeModal = (): any => {
    setSelectedUsers([]);
    setShareMessage("");
    setSelectedPermission([]);
    setAreNewUsers([]);
    props.onClose();
    return;
  };

  const removeSelectedUser = (userId: string): void => {
    const userToRemove = selectedUsers.find(
      (hpU: HealthProfessionalUser) => hpU.userId === userId
    );
    if (userToRemove) {
      setSelectedUsers(
        selectedUsers.filter(
          (hpU: HealthProfessionalUser) => hpU.userId !== userId
        )
      );
    }
  };

  return (
    <div className="full-screen-modal">
      <Modal
        show
        onClose={closeModal}
        title={"Ajouter un intervenant"}
        footer={
          <>
            {user.current && (
              <BadgeUserParticipant
                user={currentUser}
                permission={getPermissionLabel(user.current?.permission)}
              />
            )}
            <AddCancelButtons
              requestId={props.requestId}
              selectedUsers={selectedUsers}
              shareMessage={shareMessage}
              selectedPermission={selectedPermission}
              areNewUsers={areNewUsers}
              onAdd={(): any => props.onAdd()}
              closeModal={closeModal}
            />
          </>
        }
      >
        <div className="content">
          <div>
            <SearchHealthProfessional
              onSelected={(selectedUser): any => {
                if (selectedUser?.userId === "") {
                  setHpToAdd(selectedUser);
                  setShowModal(true);
                } else {
                  setSelectedUsers((u) => [...u, selectedUser]);
                  setAreNewUsers((prevState) => [...prevState, false]);
                }
              }}
              isMenuRelative={true}
              usersListToFilter={selectedUsers}
              participants={props.participants}
              professionFilter={ProfessionCategory.ALL}
            />
            {selectedUsers?.map((hpUser, index) => {
              return (
                <PermissionParticipant
                  user={hpUser}
                  onClickRemoveParticipant={(userId): any =>
                    removeSelectedUser(userId)
                  }
                  selectedPermission={selectedPermission}
                  hasPermissionEdit={props.hasPermissionEdit}
                  setSelectedPermission={setSelectedPermission}
                  key={index}
                />
              );
            })}
            <AddNewUser
              userToAdd={hpToAdd}
              show={showModal}
              onClose={(): any => setShowModal(false)}
              filterUsersRpps={selectedUsers.map((u): any => u.userId)}
              onAddUser={(g: HealthProfessionalUser): any => {
                setShowModal(false);
                setSelectedUsers((selectedUser) => [...selectedUser, g]);
                setAreNewUsers((prevState) => [...prevState, true]);
              }}
            />

            {selectedUsers.length > 0 && (
              <>
                <div className="participant-divider" />
                <TextareaAutosize
                  className="participant-textarea form-input"
                  name="message"
                  placeholder="Ajouter un message"
                  onChange={(e): void => setShareMessage(e.target.value)}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
