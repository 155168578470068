import React, { useState } from "react";
import Modal from "../Modal";
import styles from "./InvoicingForm.module.css";
import { InvoicingFormPage1 } from "./InvoicingForm/InvoicingFormPage1";
import { InvoicingFormPage2 } from "./InvoicingForm/InvoicingFormPage2";
import { useForm } from "react-hook-form";
import { saveInvoice } from "../../services/InvoicingService";
import { errorToast, successToast } from "../../utils/Toast";
import { Act, Invoice } from "../../domain/Invoice";
import { InvoiceClient } from "../../domain/InvoiceClient";

export type ClientFormInputs = {
  name: string;
  companyName: string;
  email: string;
  addressStreet: string;
  addressPostCode: string;
  addressCity: string;
  vatNumber: string;
};

export interface FormValidation {
  doctorName: ErrorMessage;
  purchaseOrder: ErrorMessage;
  price: ErrorMessage;
  vatPercent: ErrorMessage;
  serviceDescription: ErrorMessage;
}

export enum ErrorMessage {
  NONE = "",
  REQUIRED_FIELD = "Veuillez renseigner ce champ",
  PRICE_ERROR = "Prix incorrect",
  VAT_PERCENT_ERROR = "Pourcentage incorrect",
  EMAIL_ERROR = "Addresse email incorrecte",
  VAT_NUMBER_ERROR = "Numéro de TVA incorrect",
  POSTAL_CODE_ERROR = "Code postal incorrect",
}

export const errorContainer = (message: string): any => (
  <div className="error-msg">{message}</div>
);

interface Props {
  onClose: () => void;
  showWarningModal: () => void;
  updateInvoicesList: () => any;
}

export const InvoicingForm: React.FC<Props> = (props: Props) => {
  const nbrPages = 2;
  const [selectedClient, setSelectedClient] = useState<InvoiceClient>();
  const [savedInvoicingClientForm, setSavedInvoicingClientForm] = useState<
    ClientFormInputs
  >({
    name: "",
    companyName: "",
    email: "",
    addressStreet: "",
    addressPostCode: "",
    addressCity: "",
    vatNumber: "",
  });
  const [index, setIndex] = useState<number>(1);
  const [completePercentage, setCompletePercentage] = useState<number>(50);
  const [onCreate, setOnCreate] = useState<boolean>(false);

  // maybe use this https://react-hook-form.com/api/usefieldarray to handle acts (append and remove) in this form
  const [acts, setActs] = useState<Act[]>([]);
  const [isErrorInActs, setIsErrorInActs] = useState<boolean>(true);
  const [formValidationAct, setFormValidationAct] = useState<FormValidation[]>(
    []
  );

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    reset,
    formState: { errors },
  } = useForm<ClientFormInputs>({
    mode: "onBlur",
  });

  const closeModal = (): void => {
    props.onClose();
  };

  const onSave = handleSubmit((data: ClientFormInputs) => {
    setSavedInvoicingClientForm({ ...savedInvoicingClientForm, ...data });
    setSelectedClient({
      id: selectedClient?.id ? selectedClient?.id : "",
      code: selectedClient?.code ? selectedClient?.code : "",
      name: selectedClient?.name ? selectedClient?.name : data.name,
      companyName: selectedClient?.companyName
        ? selectedClient?.companyName
        : data.companyName,
      email: data.email,
      addressStreet: data.addressStreet,
      addressPostCode: data.addressPostCode,
      addressCity: data.addressCity,
      vatNumber: selectedClient?.vatNumber
        ? selectedClient?.vatNumber
        : data.vatNumber,
    });
  });

  const onSubmit = handleSubmit(
    async (): Promise<any> => {
      if (!isErrorsInPage()) {
        const parsedActs = acts.map(
          (act: any): Act => {
            return {
              doctorName: act.doctorName,
              purchaseOrder: act.purchaseOrder,
              price: parseFloat(act.price) * 100,
              vatPercent: parseInt(act.vatPercent),
              serviceDescription: act.serviceDescription,
            };
          }
        );

        const invoiceClient: InvoiceClient = {
          id: selectedClient?.id,
          addressCity: selectedClient ? selectedClient.addressCity : "",
          addressPostCode: selectedClient ? selectedClient.addressPostCode : "",
          addressStreet: selectedClient ? selectedClient.addressStreet : "",
          email: selectedClient ? selectedClient.email : "",
          name: selectedClient ? selectedClient.name : "",
          companyName: selectedClient ? selectedClient.companyName : "",
          vatNumber: selectedClient ? selectedClient.vatNumber : "",
        };

        const invoice: Invoice = {
          client: invoiceClient,
          acts: parsedActs,
        };

        const res = await saveInvoice(invoice);
        if (res instanceof Error) {
          errorToast("Erreur lors de la sauvegarde de la facture");
        } else {
          props.updateInvoicesList();
          closeModal();
          if (res?.message === "Could not send email") {
            props.showWarningModal();
          } else {
            successToast("Facture sauvegardée");
          }
        }
      }
    }
  );

  const isErrorsInPage = (): boolean => {
    switch (index) {
      case 1:
        return !!(
          errors.name ||
          errors.companyName ||
          errors.email ||
          errors.addressStreet ||
          errors.addressPostCode ||
          errors.addressCity ||
          errors.vatNumber
        );
      case 2:
        return isErrorInActs;
      default:
        return true;
    }
  };

  const goToNextPage = (): void => {
    if (!isErrorsInPage()) {
      setIndex(index + 1);
      setCompletePercentage(completePercentage + 100 / nbrPages);
    }
  };

  const goToPreviousPage = (): void => {
    setIndex(index - 1);
    setCompletePercentage(completePercentage - 100 / nbrPages);
  };

  const updateClientValues = (existingClient?: InvoiceClient): void => {
    if (existingClient !== undefined) {
      setSavedInvoicingClientForm({
        name: existingClient.name ? existingClient.name : "",
        companyName: existingClient.companyName
          ? existingClient.companyName
          : "",
        email: existingClient.email,
        addressStreet: existingClient.addressStreet,
        addressPostCode: existingClient.addressPostCode,
        addressCity: existingClient.addressCity,
        vatNumber: existingClient.vatNumber,
      });
      setSelectedClient(existingClient);
    } else {
      setSavedInvoicingClientForm({
        name: "",
        companyName: "",
        email: "",
        addressStreet: "",
        addressPostCode: "",
        addressCity: "",
        vatNumber: "",
      });
      setSelectedClient(undefined);
    }
  };

  const updateActsList = (newActs: Act[]): void => {
    setActs(newActs);
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Nouvelle facture"}
      header={
        <>
          <div className={`row w-100 centered-content ${styles.margin}`}>
            <div
              className={`circle ${styles.circle} +
            ${index === 1 ? styles.circleActive : styles.circleNotActive}`}
            >
              1
            </div>
            <div className={styles.line} />
            <div
              className={`circle ${styles.circle} +
            ${index === 2 ? styles.circleActive : styles.circleNotActive}`}
            >
              2
            </div>
          </div>
        </>
      }
      footer={
        <>
          <div className="footer-line" />
          <div className="row space-between-content ml-4 mr-4">
            {index !== nbrPages ? (
              <div>
                <div className="text-lowercase complete-percentage">
                  {completePercentage}% du formulaire complétés
                </div>
                <div className="complete-bar">
                  <div
                    className="complete-fill-bar"
                    style={{ width: completePercentage + "%" }}
                  />
                </div>
              </div>
            ) : (
              <button
                className="btn rectangular-btn neuro-btn ml-2"
                onClick={onSubmit}
                disabled={isErrorInActs}
              >
                Envoyer la facture
              </button>
            )}
            <div className="text-right w-50">
              <span className="error-msg text-large">*</span>
              <span className="error-msg text-medium ml-1">
                Champs obligatoires
              </span>
            </div>
            <div>
              <button
                disabled={index === 1}
                onClick={async (): Promise<any> => {
                  await trigger();
                  await onSave();
                  goToPreviousPage();
                }}
                className="btn btn-sm neuro-icons-btn arrow-btn-left"
              >
                <i className="icon icon-arrow-left" />
              </button>
              <button
                disabled={index === nbrPages}
                onClick={async (): Promise<any> => {
                  await trigger();
                  await onSave();
                  goToNextPage();
                }}
                className="btn btn-sm neuro-icons-btn arrow-btn-right"
              >
                <i className="icon icon-arrow-right" />
              </button>
            </div>
          </div>
        </>
      }
    >
      <div className="centered-content">
        {index === 1 && (
          <InvoicingFormPage1
            register={register}
            errors={errors}
            control={control}
            reset={reset}
            savedInvoicingClientForm={savedInvoicingClientForm}
            selectedClient={selectedClient}
            onSelectClient={(existingClient?: InvoiceClient): any => {
              updateClientValues(existingClient);
            }}
            onCreate={onCreate}
            setOnCreate={(newOnCreate: boolean): any => {
              setOnCreate(newOnCreate);
            }}
          />
        )}
        {index === 2 && (
          <InvoicingFormPage2
            reset={reset}
            setValue={setValue}
            acts={acts}
            updateActs={(newActs: Act[]): any => {
              updateActsList(newActs);
            }}
            formValidation={formValidationAct}
            setFormValidation={(newFormValidation: FormValidation[]): any =>
              setFormValidationAct(newFormValidation)
            }
            errorInAct={(isError: boolean): any => setIsErrorInActs(isError)}
          />
        )}
      </div>
    </Modal>
  );
};
