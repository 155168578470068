import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionTypes, AppContext } from "../../utils/state";
import Modal from "../Modal";
import { ModalFooter } from "../OpinionRequestForm/Participants/ModalFooter";
import { SearchFacilities } from "../SearchFacilities";
import { Facility } from "../../domain/Facility";
import { User, UserFacility } from "../../domain/User";
import { getProfile, updateCurrentUser } from "../../services/UserService";
import { errorToast } from "../../utils/Toast";
import "./SelectFacilityLinkedToRequest.css";

interface Props {
  modalType: string;
  onClose: () => any;
  onSubmit: (facilityId: string) => any;
}

export const SelectFacilityLinkedToRequest: React.FC<Props> = (
  props: Props
) => {
  const appContext = useContext(AppContext);
  const [facilityId, setFacilityId] = useState<any>(
    appContext.state.user.facilities && appContext.state.user.facilities[0].id
  );
  const [facilities, setFacilities] = useState<Map<string, Facility>>(
    new Map()
  );
  const onSelect: any = (facility: Facility) => {
    facilities.set(facility.finess, facility);
    setFacilities(new Map(facilities));
  };

  const disableValidateButton = () => {
    const userFacilities = appContext.state.user.facilities;
    return !userFacilities || userFacilities.length < 1;
  };

  const closeModal = (): void => {
    props.onClose();
  };
  const getTitle = (): string => {
    return props.modalType === "SUBMIT"
      ? "Soumission de la demande"
      : "Acceptation de la demande";
  };

  const addFacility = useCallback(async () => {
    const facilitiesId: Facility[] = Array.from(facilities.values());
    const userUpdated: User = {
      ...appContext.state.user,
      facilities: facilitiesId,
      userFacilities: facilitiesId.map(
        (d): UserFacility => ({
          facilityId: d.finess,
          service: d.service,
        })
      ),
    };
    const res = await updateCurrentUser(userUpdated);
    if (res instanceof Error) {
      errorToast("Erreur lors de l'édition de l'utilisateur");
    } else {
      setFacilityId(facilitiesId[0].id);
      getProfile().then((updatedUser: User | Error) => {
        if (updatedUser instanceof Error) {
          errorToast("Erreur lors de la récupération de l'utilisateur");
        } else {
          appContext.dispatch({
            type: ActionTypes.UserUpdated,
            payload: updatedUser,
          });
        }
      });
    }
  }, [facilities]);

  useEffect(() => {
    if (facilities.size > 0) {
      addFacility();
    }
  }, [facilities]);

  return (
    <div className="full-screen-modal">
      <Modal
        show
        onClose={closeModal}
        title={getTitle()}
        footer={
          <>
            <ModalFooter
              titleButtonRight={"Valider"}
              onClickButtonRight={async (event: any): Promise<void> => {
                event.preventDefault();
                props.onSubmit(facilityId);
                closeModal();
              }}
              titleButtonLeft={"Annuler"}
              onClickButtonLeft={closeModal}
              disableButtonRight={disableValidateButton()}
            />
          </>
        }
        containerStyle={{ height: "40vh" }}
      >
        <div className="h-100 row centered-content p-2">
          <div className={"text-left modal-label"}>
            {appContext.state.user.facilities ? (
              <>
                <span className="mb-2">
                  Lieu d&apos;exercice pour effectuer la
                  {props.modalType === "SUBMIT" ? " demande" : " réponse"}
                </span>
                <select
                  className={"form-select mt-2 w-100 text-dark-grey"}
                  id="facility-selector"
                  onChange={(e): any => {
                    setFacilityId(e.target.value);
                  }}
                >
                  {appContext.state.user.facilities?.map((facility) => {
                    return (
                      <option key={facility.id} value={facility.id}>
                        {facility.name}
                      </option>
                    );
                  })}
                </select>
                <div className="w-100 h-100 text-centered link-to-config-container">
                  <Link
                    to="/profile/facilities"
                    className="text-primary-blue text-bold"
                  >
                    Gérer mes lieux d&apos;exercice
                  </Link>
                </div>
              </>
            ) : (
              <div className="flex-col">
                <p>
                  Vous devez enregistrer au moins un lieu d&apos;exercice pour
                  pouvoir l&apos;associer à la demande et soumettre celle-ci.
                </p>
                <div className="search-facility-input-container">
                  <div className="search-facility-input">
                    <SearchFacilities onSelect={onSelect} autoFocus={true} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
