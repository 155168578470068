/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { initLoginWithPsConnect, initRegister } from "../utils/auth";
import { HowItWorks } from "./Shared/HowItWorks";
import styles from "./Disconnected.module.css";
import { Link } from "react-router-dom";
import config from "../utils/config";
import PSConnectIcon from "../assets/ProSanteConnect_sidentifier_COULEURS.svg";
import { useTranslation } from "react-i18next";
import { LanguageMenu } from "./LanguageSelector";
import { HomeFooter } from "./HomeFooter";
import { getWebsiteTitle } from "../utils/Brands";
import history from "../utils/history";

export const Disconnected: React.FC = () => {
  function goToRegister(): void {
    initRegister();
  }
  const { t } = useTranslation();
  const brand = config.brand;

  const getRegistrationInfo = (): void => {
    history.push(`/register-guide`);
  };

  return (
    <>
      <nav className={`navbar w-100  + ${styles.paddingNavbar}`}>
        <section className="navbar-section">
          <Link to="/" className={`btn-link + ${styles.homeLink}`}>
            <img
              className={styles.logoSize}
              alt="logo-app"
              src={
                require(brand === "handineuro"
                  ? `../assets/logo/logo-handineuro.svg`
                  : brand === "teleghuparis"
                  ? `../assets/logo/logo-teleghuparis.svg`
                  : `../assets/logo/logo-dopenclinics.png`).default
              }
            />
          </Link>
          {config.environment !== "prod" && (
            <span className="text-coral-medium text-bold">
              {config.environment}
            </span>
          )}
        </section>
        <section className={`${styles.loginBtnContainer} navbar-section`}>
          <LanguageMenu rightMargin={true} />
          <Link
            to="/login"
            className="btn rectangular-btn neuro-btn w-40"
            id="login-btn"
          >
            {t("login_button")}
          </Link>
          <div className="tooltip">
            <img
              alt="pro santé connect"
              src={PSConnectIcon}
              className={styles.psconnectButton}
              onClick={() => initLoginWithPsConnect()}
            />
            <span className="tooltiptext tooltiptext-ps-connect">
              L&apos;identification avec e-CPS ne fonctionne pas encore à ce
              jour
            </span>
          </div>
        </section>
      </nav>
      <div className={styles.disconnectedContainer}>
        <h1 className={styles.disconnectedTitle}>
          Une plateforme de{" "}
          <span className="text-primary-blue">téléexpertise</span> et de{" "}
          <span className="text-primary-blue">télé-RCP</span> en Neurosciences
        </h1>
        <div className={`text-justify ${styles.disconnectedText}`}>
          <b>{getWebsiteTitle(config.brand)}</b> permet aux spécialistes
          hospitaliers neurologues, réanimateurs, psychiatres et MPR de répondre
          aux demandes d’avis de tous les professionnels de santé et d’améliorer
          la prise de décision collégiale en hôpital en situation d’incertitude.
          Les experts d’autres spécialités peuvent être impliquées dans les
          demandes d’avis nécessitant une perspective transversale.
        </div>
        <HowItWorks />
        <div className={"row w-100 " + styles.button}>
          <button
            className="btn rectangular-btn neuro-btn w-70"
            id="register-btn"
            onClick={goToRegister}
          >
            {t("register_button")}
          </button>
        </div>
        <span
          className="mt-4 ml-auto mr-auto clickable-link"
          onClick={getRegistrationInfo}
        >
          Consultez le guide d&apos;inscription pour créer votre compte.
        </span>
      </div>
      <HomeFooter />
    </>
  );
};
