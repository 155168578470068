import React, { useState } from "react";
import { Patient } from "../../domain/OpinionRequest";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { InsiSex, showInsiError } from "../../services/InsiService";
import { IcanopeeInsiService } from "../../services/icanopee/IcanopeeInsiService";
import { ModalDocumentationCps } from "./DocumentationCPS/ModalDocumentationCps";
import { IcanopeeError } from "../../services/icanopee/IcanopeeService";
import { ModalInfo } from "../Shared/ModalInfo";
import styles from "../Request/RequestDetails/DmpDocuments.module.css";
import { errorToast } from "../../utils/Toast";

interface Props {
  getPatientInfo: () => Patient;
  onSuccess: (insNumber: string) => void;
}

export const InsFinder: React.FC<Props> = (props: Props) => {
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.IDLE);
  const [showDocumentationCps, setShowDocumentationCps] = useState<boolean>(
    false
  );
  const [pinCode, setPinCode] = useState<string>("");
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);

  const renderResult = () => {
    switch (status) {
      case LoadingStatus.IDLE:
        return "";
      case LoadingStatus.LOADING:
        return (
          <span className="text-grey">Recherche du numéro INS en cours...</span>
        );
      case LoadingStatus.ERROR:
        return <span className="red-text">Erreur lors de la recherche</span>;
      case LoadingStatus.SUCCESS:
        return <span className="green-text">Numéro INS trouvé</span>;
      default:
        return "En attente";
    }
  };

  async function findInsNumber(patient: Patient, cpsPinCode: string) {
    setStatus(LoadingStatus.LOADING);

    let patientSex = InsiSex.UNKNOWN;
    switch (patient.sex) {
      case "MALE":
        patientSex = InsiSex.MALE;
        break;
      case "FEMALE":
        patientSex = InsiSex.FEMALE;
        break;
    }

    const icanopeeInsiService = new IcanopeeInsiService();

    try {
      const patientInfoIns = await icanopeeInsiService.getPatientInfoIns(
        {
          date: `${patient.birthdate.year}-${patient.birthdate.month}-${patient.birthdate.day}`,
          given: patient.firstName?.toUpperCase() || "",
          name: patient.lastName?.toUpperCase() || "",
          place: patient.birthPlace || "",
          sex: patientSex,
        },
        cpsPinCode
      );

      if (
        typeof patientInfoIns !== "string" &&
        patientInfoIns.socialSecurityNumber
      ) {
        props.onSuccess(patientInfoIns.socialSecurityNumber);
        setStatus(LoadingStatus.SUCCESS);
      } else {
        setStatus(LoadingStatus.ERROR);
      }
    } catch (e) {
      if (e === IcanopeeError.ERROR_FUNCTIONAL) {
        setShowDocumentationCps(true);
        setStatus(LoadingStatus.IDLE);
      } else if (e === IcanopeeError.CARD_ERROR) {
        setStatus(LoadingStatus.ERROR);
      } else if (e === IcanopeeError.TOO_MANY_PIN_TRIES_FAILED) {
        setStatus(LoadingStatus.ERROR);
        errorToast("Carte CPS bloquée : trop de tentatives erronées");
      } else if (
        e === IcanopeeError.UNEXPECTED_ERROR ||
        e === IcanopeeError.COMPONENT_INITIAL_CODE
      ) {
        setShowPinCodeModal(true);
        setStatus(LoadingStatus.IDLE);
      } else {
        setStatus(LoadingStatus.ERROR);
        setPinCode("");
        showInsiError(e);
      }
    }
  }

  return (
    <>
      <span>
        Recherche du numéro INS à partir des données d&apos;identité :{" "}
      </span>
      <button
        className="btn btn-sm neuro-icons-btn ml-2"
        onClick={() => findInsNumber(props.getPatientInfo(), pinCode)}
      >
        <i className="icon icon-search mr-2" />
        Rechercher
      </button>
      <div className="row ">
        <div>{renderResult()}</div>
      </div>
      {showDocumentationCps && (
        <ModalDocumentationCps
          onClose={(): void => setShowDocumentationCps(false)}
        />
      )}
      {showPinCodeModal && (
        <ModalInfo
          title={"Confirmation de votre identité"}
          onClose={(): any => setShowPinCodeModal(false)}
          rightButtonAction={() =>
            findInsNumber(props.getPatientInfo(), pinCode)
          }
          isRightButtonDisabled={pinCode.length !== 4}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <div className="flex-col p-2">
            <p>
              Veuillez entrer ci-dessous le code correspondant à votre profil de
              professionnel de la santé:
            </p>
            <input
              type="number"
              className={styles.codeInput}
              defaultValue={""}
              min={0}
              onChange={(event) => setPinCode(event.target.value)}
            />
          </div>
        </ModalInfo>
      )}
    </>
  );
};
