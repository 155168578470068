import React, { useContext, useEffect, useState } from "react";
import OpinionRequest, {
  Expert,
  OpinionRequestFormPage,
  Participant,
  SharePermission,
} from "../../../domain/OpinionRequest";
import { AddParticipants } from "../../OpinionRequestForm/Participants/AddParticipants";
import { EditParticipant } from "../../OpinionRequestForm/Participants/EditParticipant";
import { hasRole, hasStatus } from "../../../utils/authorization";
import { UserStatus } from "../../../domain/UserStatus";
import { UserRoles } from "../../../domain/UserRoles";
import { AppContext } from "../../../utils/state";
import "../../Shared/UserChip.module.css";
import "./ParticipantCards/Cards.css";
import { RequestStatus } from "../../../domain/RequestStatus";
import {
  isOrganizationDispatcherOnOpinionRequest,
  User,
} from "../../../domain/User";
import {
  deleteParticipant,
  getOpinionRequestExperts,
} from "../../../services/OpinionRequestService";
import { errorToast, successToast } from "../../../utils/Toast";
import { ParticipantExpertCard } from "./ParticipantCards/ParticipantExpertCard";
import { ParticipantNotExpertCard } from "./ParticipantCards/ParticipantNotExpertCard";
import { EmptyCard } from "./ParticipantCards/EmptyCard";
import { ExpertStatus } from "../../../domain/ExpertStatus";
import history from "../../../utils/history";
import { Organization } from "../../../domain/Organization";
import { OrganizationCard } from "../../Admin/OrganizationCard";

interface Props {
  request: OpinionRequest;
  currentUserId: string;
  onAdd: () => void;
  onEdit: () => void;
  onDelete: () => void;
  isAffecting: () => void;
}

export const ParticipantDetails: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const requester = props.request?.participants?.find(
    (p) => p.userId === props.request.requesterUser?.id
  );
  const [edit, setEditing] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canEditPermission, setCanEditPermission] = useState<boolean>(false);
  const [hasPermissionEdit, sethasPermissionEdit] = useState<boolean>(false);
  const [allExperts, setAllExperts] = useState<User[]>([]);
  const [participant, setParticipant] = useState<Participant>({
    userId: "",
    permission: SharePermission.VIEW,
  });

  useEffect(() => {
    const currentParticipant = props.request?.participants?.find(
      (p) => p.userId === props.currentUserId
    );
    const isWaitingExpert = props.request.assignedExperts?.some(
      (expert: Expert) =>
        expert.userId === currentParticipant?.userId &&
        expert.status === ExpertStatus.WAITING
    );
    if (props.request?.status !== RequestStatus.CLOSED && !isWaitingExpert) {
      if (
        hasStatus(appContext.state.user.status, UserStatus.VALIDATED) &&
        (currentParticipant?.permission === SharePermission.ADMIN ||
          hasRole(appContext.state.user.roles, UserRoles.GLOBAL_DISPATCHER) ||
          isOrganizationDispatcherOnOpinionRequest(
            appContext.state.user,
            props.request
          ))
      ) {
        setCanEdit(true);
        setCanEditPermission(true);
      } else if (currentParticipant?.permission === SharePermission.EDIT) {
        setCanEdit(true);
      }
    }
  }, [props.request, props.currentUserId, appContext]);

  async function sortExpertList(list: User[]): Promise<void> {
    list?.sort((item1: User, item2: User) => {
      if (item1.lastName === item2.lastName) {
        return item1.firstName > item2.firstName ? 1 : -1;
      }
      return item1.lastName > item2.lastName ? 1 : -1;
    });
  }

  useEffect(() => {
    // TODO récupérer la liste depuis le composant parent?
    async function getAllAssignedExperts(): Promise<User[]> {
      if (props.request?.id) {
        const res = await getOpinionRequestExperts(props.request.id);
        if (res instanceof Error) {
          errorToast("Erreur lors de la récupération des experts");
        } else {
          await sortExpertList(res);
          const result = res || [];
          setAllExperts(result);
          return result;
        }
      }
      return [];
    }
    getAllAssignedExperts();
  }, [props.request]);

  useEffect((): any => {
    const userHasEditPermission = props.request?.participants?.find(
      (p) => p.userId === appContext.state.user.id
    );
    if (userHasEditPermission?.permission === SharePermission.EDIT) {
      sethasPermissionEdit(true);
    }
  }, [appContext.state.user.id, props.request]);

  const removeParticipant = async (p: Participant): Promise<void> => {
    if (!p.userId || !props.request.id) {
      return;
    } else {
      const res = await deleteParticipant(props.request.id, p.userId);
      if (res instanceof Error) {
        errorToast("Erreur lors de la suppression du participant");
      } else {
        successToast("Participant supprimé");
        props.onDelete();
      }
    }
  };

  const isAbleToSeeSuggestedExpert = (): boolean => {
    return (
      props.request.suggestedExpert?.id !== "" &&
      (props.request.status === RequestStatus.DRAFT ||
        props.request.status === RequestStatus.SUBMITTED) &&
      (props.request.requesterUser?.id === appContext.state.user.id ||
        appContext.state.user.roles.includes(UserRoles.GLOBAL_DISPATCHER))
    );
  };

  const showExpertsList = (): JSX.Element[] => {
    let expertsToShow: User[] = [];
    // dispatcher and an expert who accepted an opinion request should be able to see all the experts
    if (
      appContext.state.user.roles.includes(UserRoles.GLOBAL_DISPATCHER) ||
      props.request.assignedExperts?.some(
        (e) =>
          e.userId === appContext.state.user.id &&
          e.status === ExpertStatus.ACCEPTED
      )
    ) {
      expertsToShow = allExperts;
    } else if (
      props.request.assignedExperts?.some(
        (e) =>
          e.userId === appContext.state.user.id &&
          e.status === ExpertStatus.WAITING
      )
    ) {
      expertsToShow = getAcceptedExperts();
      expertsToShow.push(appContext.state.user);
    } else if (appContext.state.user.id === props.request.requesterUser?.id) {
      expertsToShow = allExperts.filter(
        (e) =>
          e.id === props.request.requestedExpertId ||
          getAcceptedExperts().some((accepted) => accepted.id === e.id)
      );
    } else {
      expertsToShow = getAcceptedExperts();
    }
    return expertsToShow.map((e: User, index: number) => {
      return (
        <ParticipantExpertCard
          expert={e}
          assignedExpert={props.request.assignedExperts}
          key={index}
        />
      );
    });
  };

  const showOrganizationsList = (): JSX.Element[] | JSX.Element => {
    if (props.request.assignedOrganizations) {
      return props.request.assignedOrganizations.map(
        (o: Organization, index: number) => {
          return (
            <OrganizationCard
              key={index}
              organization={o}
              readOnly={true}
              showCardLegend={true}
              showRemoveButton={false}
            />
          );
        }
      );
    } else {
      return <></>;
    }
  };

  const getAcceptedExperts = (): User[] => {
    return allExperts.filter((e) =>
      props.request.assignedExperts?.some(
        (assignedExpert) =>
          assignedExpert.userId === e.id &&
          assignedExpert.status === ExpertStatus.ACCEPTED
      )
    );
  };

  const isOpinionRequestCreator = (): boolean => {
    return appContext.state.user.id === props.request.requesterUser?.id;
  };

  const isOpinionRequestExpert = (): boolean => {
    return !!props.request.assignedExperts?.some(
      (e) => e.userId === appContext.state.user.id
    );
  };

  const hasAcceptedOpinionRequest = (): boolean => {
    if (props.request && props.request.assignedExperts) {
      return props.request.assignedExperts.some(
        (expert) =>
          expert.userId === appContext.state.user.id &&
          expert.status === ExpertStatus.ACCEPTED
      );
    }
    return false;
  };

  const canRequestExpert =
    isOpinionRequestCreator() &&
    !props.request.requestedExpertId &&
    props.request.status !== (RequestStatus.VALIDATED || RequestStatus.CLOSED);

  const getEmptyCard = () => {
    return (
      <EmptyCard content={"Aucun expert n'est associé à cette demande."}>
        {canRequestExpert && ( // The AddExpert button can be seen only if the requester is also a dispatcher
          <button
            className="btn neuro-btn rounded-btn mb-0"
            onClick={(): void => {
              history.push(
                `/requests/${props.request.id}?page=${OpinionRequestFormPage.requestAddExpert}`
              );
            }}
          >
            Nommer un expert
          </button>
        )}
      </EmptyCard>
    );
  };

  const shouldShowEmptyCard = () => {
    if (appContext.state.user.roles.includes(UserRoles.GLOBAL_DISPATCHER)) {
      return allExperts.length === 0;
    }
    if (isOpinionRequestExpert()) {
      return false;
    }
    console.info("EX:", getAcceptedExperts());
    return getAcceptedExperts().length === 0;
  };

  return (
    <div className="text-left participant-container pt-2">
      <div className="grid-container-cards">
        {shouldShowEmptyCard() && getEmptyCard()}
        {isAbleToSeeSuggestedExpert() && (
          <ParticipantExpertCard
            expert={props.request.suggestedExpert}
            suggestedExpert={true}
          />
        )}
        {showExpertsList()}
        {showOrganizationsList()}
      </div>
      <div className="divider-line-no-mb" />
      {hasAcceptedOpinionRequest() && (
        <button
          className="btn neuro-btn rounded-btn"
          onClick={(e: any): void => {
            e.preventDefault();
            props.isAffecting();
          }}
        >
          Ajouter d&apos;autres experts
        </button>
      )}
      <button
        className="btn neuro-btn rounded-btn"
        onClick={(e: any): void => {
          e.preventDefault();
          setAdd(true);
        }}
        disabled={!canEdit}
      >
        Ajouter un intervenant
      </button>
      <div className="grid-container-cards">
        {requester && <ParticipantNotExpertCard participant={requester} />}
        {props.request?.participants
          ?.filter(
            (p) =>
              p.userId !== props.request.requesterUser?.id &&
              !props.request.assignedExperts
                ?.map((expert) => expert.userId)
                .includes(p.userId)
          ) //remove requester and not accepted expert
          .map((p: Participant, index: number) => {
            return (
              <React.Fragment key={index}>
                <ParticipantNotExpertCard
                  participant={p}
                  onClick={(): any => {
                    if (
                      p.userId !== props.request?.requesterUser?.id &&
                      p.userId !== props.currentUserId &&
                      canEdit
                    ) {
                      setEditing(true);
                      setParticipant(p);
                    }
                  }}
                  canEditPermission={canEditPermission}
                  removeParticipant={(participantToDelete: Participant): any =>
                    removeParticipant(participantToDelete)
                  }
                />
              </React.Fragment>
            );
          })}
      </div>
      {add && props.request?.participants && props.request.id !== undefined && (
        <AddParticipants
          requestId={props.request.id}
          participants={props.request.participants}
          hasPermissionEdit={hasPermissionEdit}
          onClose={(): any => setAdd(false)}
          onAdd={(): any => props.onAdd()}
        />
      )}
      {edit && props.request.id !== undefined && participant !== undefined && (
        <EditParticipant
          requestId={props.request.id}
          participant={participant}
          hasPermissionEdit={hasPermissionEdit}
          onClose={(): any => setEditing(false)}
          onEdit={(): any => props.onEdit()}
          onDelete={(): any => props.onDelete()}
        />
      )}
    </div>
  );
};
