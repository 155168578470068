import { createUser } from "../../../services/UserService";
import { errorToast, successToast } from "../../../utils/Toast";
import { addParticipant } from "../../../services/OpinionRequestService";
import React from "react";
import { HealthProfessionalUser } from "../../../domain/User";
import { ModalFooter } from "./ModalFooter";
import { SharePermission } from "../../../domain/OpinionRequest";
import { ErrorResponse, ErrorType } from "../../../domain/ErrorManagement";

interface Props {
  requestId: string;
  selectedUsers: HealthProfessionalUser[];
  shareMessage: string;
  selectedPermission: string[];
  areNewUsers: boolean[];
  onAdd: () => any;
  closeModal: () => any;
}

export const AddCancelButtons: React.FC<Props> = (props: Props) => {
  const sendInvite = async (event: any): Promise<void> => {
    event.preventDefault();
    if (!props.selectedUsers) {
      return;
    }

    for (const user of props.selectedUsers) {
      const index = props.selectedUsers.indexOf(user);
      let userId = user.userId;

      if (props.areNewUsers[index]) {
        const creationUserResponse = await createUser(user);
        if (creationUserResponse instanceof ErrorResponse) {
          if (creationUserResponse.payload) {
            userId = creationUserResponse.payload;
          } else {
            errorToast(
              `Erreur lors de la création de l'utilisateur, le participant ${user.firstName} ${user.lastName} n'a pas été ajouté`
            );
            continue;
          }
        } else if (creationUserResponse instanceof Error) {
          errorToast(
            `Erreur lors de la création de l'utilisateur, le participant ${user.firstName} ${user.lastName} n'a pas été ajouté`
          );
          continue;
        } else {
          userId = creationUserResponse.message;
        }
      }
      const res = await addParticipant(props.requestId, {
        userId: userId,
        permission: props.selectedPermission[index] || SharePermission.VIEW,
        message: props.shareMessage,
      });
      if (res instanceof ErrorResponse) {
        if (res.code === ErrorType.PARTICIPANT_ALREADY_ADDED) {
          errorToast("Erreur le participant a déjà été ajouté");
        } else {
          errorToast("Erreur lors de l'ajout du participant");
        }
      } else {
        successToast("Participant ajouté");
        props.onAdd();
      }
    }
    props.closeModal();
  };

  return (
    <ModalFooter
      titleButtonRight={"Envoyer l'invitation"}
      onClickButtonRight={sendInvite}
      titleButtonLeft={"Annuler"}
      onClickButtonLeft={props.closeModal}
      disableButtonRight={!props.selectedUsers.length}
    />
  );
};
