import React from "react";
import styles from "./Spinner.module.css";

interface Props {
  size: string;
  marginTop?: string;
}

export const Spinner: React.FC<any> = (props: Props) => {
  return (
    <div className={`${props.marginTop} ${styles.ldsRing}`}>
      <div className={props.size}></div>
      <div className={props.size}></div>
      <div className={props.size}></div>
      <div className={props.size}></div>
    </div>
  );
};
