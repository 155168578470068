import React from "react";
import styles from "./Chip.module.css";

interface Props {
  title: string;
  subtitle?: string;
  figureTitle: string;
  figureClassName?: string;
  onClick?: (event: any) => any;
  onDelete?: (event: any) => any;
  isSmall?: boolean;
  readOnly?: boolean;
  isFlat?: boolean;
}

export const Chip: React.FC<Props> = (props: Props) => {
  const isFlat = props.isFlat ?? false;

  const userChip = (
    <div className="row">
      <div className={styles.figure}>
        <figure
          className={"avatar text-medium " + props.figureClassName}
          data-initial={props.figureTitle}
        />
      </div>
      <div className={styles.flexCol}>
        <div className="flex-1">
          <div className="ml-2 pl-2">{props.title}</div>
        </div>
        {props.subtitle && (
          <div className={`flex-1 ${styles.hpData}`}>
            <div className="ml-2 pl-2">{props.subtitle}</div>
          </div>
        )}
      </div>
      {props.onDelete && !props.readOnly && (
        <div
          className="remove-btn pl-2"
          onClick={(e): any => {
            e.stopPropagation();
            if (props.onDelete) {
              props.onDelete(e);
            }
          }}
        >
          <i className={"icon icon-cross pl-2 " + styles.marginBottom} />
        </div>
      )}
    </div>
  );

  const buttonClasses = isFlat
    ? `row btn-add-participant mt-1 ${styles.btn}`
    : styles.editBtnParticipant;
  const smallUserChipClass = isFlat ? "" : styles.selectedUserSmall;
  const userChipClass = isFlat ? "" : "selected-user";
  const getUserChip = props.isSmall ? (
    <div className={smallUserChipClass}>{userChip}</div>
  ) : (
    <div className={userChipClass}>{userChip}</div>
  );

  return (
    <>
      <div className={"row mb-2"}>
        {props.onClick ? (
          <button className={buttonClasses} onClick={props.onClick}>
            {userChip}
          </button>
        ) : (
          <>{getUserChip}</>
        )}
      </div>
    </>
  );
};
