import React, { useEffect, useState } from "react";
import { Organization } from "../../domain/Organization";
import { Facility } from "../../domain/Facility";
import { getFacility } from "../../services/FacilityService";
import styles from "./OrganizationDetailCard.module.css";
import {
  getAllOrganizations,
  getOrganizationById,
} from "../../services/OrganizationService";
import { errorToast } from "../../utils/Toast";
import CameraDisabledIcon from "../../assets/camera-disabled-icon.svg";
import history from "../../utils/history";

interface Props {
  organization: Organization;
}

export const OrganizationDetailCard: React.FC<Props> = (props: Props) => {
  const [organization, setOrganization] = useState<Organization>();
  const [facility, setFacility] = useState<Facility>();
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [allOrganizationsIds, setAllOrganizationsIds] = useState<string[]>([]);

  const getOrganization = async (): Promise<any> => {
    if (props.organization.id) {
      await getOrganizationById(props.organization.id).then(
        (res: Organization | Error) => {
          if (res instanceof Error) {
            errorToast("Erreur durant le chargement d'une organisation");
          } else {
            setOrganization(res);
            res.facilityId && getFacilityById(res.facilityId);
          }
        }
      );
    }
  };

  const getFacilityById = async (facilityId: string): Promise<void> => {
    if (facilityId) {
      const res = await getFacility(facilityId);
      if (res instanceof Error || res == null) {
        errorToast(
          "Erreur durant le chargement des details organisationnelles"
        );
      } else {
        setFacility(res);
      }
    }
  };

  const getParentOrganization = (organisation: Organization) => {
    const organizationBreadcrumb: Organization[] = [];
    organizationBreadcrumb.push(organisation);

    let parentId = organisation.parentOrganizationId;
    let parentIndex;
    let parent;
    let count = 0;
    while (parentId && parentId !== "" && count < 1000) {
      count++;
      parentIndex = allOrganizationsIds.indexOf(parentId);
      if (parentIndex !== -1) {
        parent = allOrganizations[parentIndex];
        organizationBreadcrumb.push(parent);
        parentId = parent.parentOrganizationId;
      } else {
        parentId = "";
      }
    }

    return organizationBreadcrumb.reverse();
  };

  const getOrganizations = async (): Promise<any> => {
    await getAllOrganizations().then((res: Organization[] | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur durant le chargement des organisations");
      } else {
        setAllOrganizations(res);
        setAllOrganizationsIds(res.map((value) => value.id ?? ""));
      }
    });
  };

  const getChildrenOrganization = (organisation: Organization) => {
    const organizationBreadcrumb: Organization[] = [];
    organizationBreadcrumb.push(organisation);

    const organizationChildren: Organization[] = allOrganizations.filter(
      (org) => org.parentOrganizationId === organisation.id
    );
    organizationChildren.forEach((orgChilds) =>
      organizationBreadcrumb.push(orgChilds)
    );

    return organizationBreadcrumb.reverse();
  };

  const expertsListExist = (): boolean => {
    return (organization?.experts && organization?.experts.length > 0) || false;
  };

  const orgAdminExist = (): boolean => {
    return (organization?.admins && organization?.admins.length > 0) || false;
  };

  const orgChildrenExist = (): boolean => {
    return (
      (getChildrenOrganization(props.organization) &&
        getChildrenOrganization(props.organization).length > 0) ||
      false
    );
  };

  const orgParentExist = (): boolean => {
    return (
      (getParentOrganization(props.organization) &&
        getParentOrganization(props.organization).length > 0) ||
      false
    );
  };

  useEffect(() => {
    getOrganization();
    getOrganizations();
  }, []);

  return (
    <div className="p-relative w-100 mb-2 mt-2 organization-card">
      <div className="card-body organization-card-body">
        <div className="container">
          {props.organization && (
            <>
              <div className="flex-row mb-4 mt-2 ">
                <div className="figure-size mr-4">
                  {props.organization.logo != "" ? (
                    <img
                      className="h-100"
                      src={props.organization.logo}
                      alt="logo organisation"
                    />
                  ) : (
                    <img
                      className="h100 w-100"
                      src={CameraDisabledIcon}
                      alt="Logo par défaut"
                    />
                  )}
                </div>
                <div className="organization-header">
                  <div className={styles.cardOrganization}>
                    {props.organization.name}
                  </div>
                  {facility ? (
                    <div className="text-bold">
                      {facility?.name} -{" "}
                      {facility?.addressRoutingNumber +
                        " " +
                        facility?.addressRoutingWay +
                        " " +
                        facility?.addressRoutingWayName +
                        ", " +
                        facility?.addressRoutingLabel}
                    </div>
                  ) : (
                    <span className="text-secondary-greys">
                      Adresse inconnue
                    </span>
                  )}
                </div>
              </div>
              <div className="organization-top-grid-container">
                <div className="org-admin">
                  <label className="text-bold mr-2">
                    Chef d&apos;organisation
                  </label>
                  {orgAdminExist() ? (
                    organization?.admins.map((admin) => (
                      <div key={admin.id}>
                        {admin.title} {admin.firstName} {admin.lastName}
                      </div>
                    ))
                  ) : (
                    <div>Inconnu(e)</div>
                  )}
                </div>
                <div className="finess">
                  <label className="text-bold mr-2">Finess</label>
                  {facility?.finess ? (
                    <div>{facility?.finess}</div>
                  ) : (
                    <div className="text-secondary-greys">Inconnu</div>
                  )}
                </div>
              </div>
              <div className="mb-4 mt-4">
                <label className="text-bold mr-2">Description</label>
                {props.organization.details ? (
                  <div className={styles.organizationDetails}>
                    {props.organization.details}
                  </div>
                ) : (
                  <div className="text-secondary-greys">Aucune</div>
                )}
              </div>
              <div className="organization-bottom-grid-container">
                <div className="org-parent">
                  <label className="text-bold mr-2">Organisation parente</label>
                  {orgParentExist() ? (
                    getParentOrganization(props.organization)?.map((parent) => (
                      <div key={parent.id}>{parent.name}</div>
                    ))
                  ) : (
                    <div>Aucune</div>
                  )}
                </div>
                <div className="org-child">
                  <label className="text-bold mr-2">
                    Organisations enfants
                  </label>
                  <div>
                    {orgChildrenExist() ? (
                      getChildrenOrganization(
                        props.organization
                      )?.map((children) => (
                        <div key={children.id}>{children.name}</div>
                      ))
                    ) : (
                      <div>Aucune</div>
                    )}
                  </div>
                </div>
                <div className="health-professionnals">
                  <label className="text-bold mr-2">
                    Professionnels de santé
                  </label>
                  {expertsListExist() ? (
                    organization?.experts.map((expert) => (
                      <div key={expert.id}>
                        {expert.title} {expert.firstName} {expert.lastName}
                      </div>
                    ))
                  ) : (
                    <div>Aucun(e)</div>
                  )}
                </div>
                <div className="specialty">
                  <label className="text-bold mr-2">Spécialités</label>
                  {expertsListExist() ? (
                    organization?.experts.map((expert) => (
                      <div key={expert.id}>{expert.specialty}</div>
                    ))
                  ) : (
                    <div>Aucune</div>
                  )}
                </div>
              </div>
              <div className="row centered-content mt-4">
                <button
                  className="btn rectangular-btn neuro-btn ml-2"
                  onClick={(): void => {
                    history.push(
                      `/admin/organization-details/${props.organization.id}/edit`
                    );
                  }}
                >
                  Mettre à jour
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
