import React, { useContext, useEffect, useState } from "react";
import { Organization } from "../../domain/Organization";
import {
  createOrganization,
  getAllOrganizations,
} from "../../services/OrganizationService";
import { errorToast, successToast } from "../../utils/Toast";
import { AppContext } from "../../utils/state";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import "./Admin.css";
import styles from "./AdminOrganizationTab.module.css";
import { OrganizationCard } from "./OrganizationCard";
import Modal from "../Modal";
import { EditOrganization } from "./EditOrganization";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { Spinner } from "../Shared/Spinner";

export const AdminOrganizationTab: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [allOrganizationsIds, setAllOrganizationsIds] = useState<string[]>([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    Organization[]
  >([]);
  const numberOfOrganizations = appContext.state.user.organizations?.filter(
    (org) => org.isAdmin
  ).length;
  const [
    showCreateOrganizationPopUp,
    setShowCreateOrganizationPopUp,
  ] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.IDLE
  );

  const getBreadCrumbs = (organisation: Organization) => {
    const organizationBreadcrumb: Organization[] = [];
    organizationBreadcrumb.push(organisation);

    if (hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
      let parentId = organisation.parentOrganizationId;
      let parentIndex;
      let parent;
      let count = 0;
      while (parentId && parentId !== "" && count < 1000) {
        count++;
        parentIndex = allOrganizationsIds.indexOf(parentId);
        if (parentIndex !== -1) {
          parent = allOrganizations[parentIndex];
          organizationBreadcrumb.push(parent);
          parentId = parent.parentOrganizationId;
        } else {
          parentId = "";
        }
      }
    } else {
      const organizationChildren: Organization[] = allOrganizations.filter(
        (org) => org.parentOrganizationId === organisation.id
      );
      organizationChildren.forEach((orgChilds) =>
        organizationBreadcrumb.push(orgChilds)
      );
    }

    return organizationBreadcrumb.reverse();
  };

  const getOrganizations = async (): Promise<any> => {
    setLoadingStatus(LoadingStatus.LOADING);
    await getAllOrganizations().then((res: Organization[] | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur durant le chargement des organisations");
        setLoadingStatus(LoadingStatus.ERROR);
      } else {
        setAllOrganizations(res);
        setAllOrganizationsIds(res.map((value) => value.id ?? ""));
        setLoadingStatus(LoadingStatus.SUCCESS);
        if (hasRole(appContext.state.user.roles, UserRoles.ADMIN)) {
          setFilteredOrganizations(res);
          return;
        }
        const organizationAdminIds: string[] = appContext.state.user.organizations
          .filter((org) => org.isAdmin)
          .map((org) => org.organizationId);
        setFilteredOrganizations(
          res.filter((org) => organizationAdminIds.includes(org.id ?? ""))
        );
      }
    });
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  if (loadingStatus === LoadingStatus.LOADING) {
    return (
      <div className="text-centered">
        <Spinner size={"xl-spinner"} marginTop={"mt-7"} />
      </div>
    );
  }

  const onSubmit = (orgToCreate: Organization) => {
    createOrganization(orgToCreate).then((res: any | Error) => {
      if (res instanceof Error) {
        errorToast("Erreur lors de la création de l'organisation");
      } else {
        successToast("Organisation créée !");
        getOrganizations();
      }
    });
    setShowCreateOrganizationPopUp(false);
  };

  return (
    <>
      {hasRole(appContext.state.user.roles, UserRoles.ADMIN) && (
        <div className="text-centered">
          <button
            className="btn neuro-btn rounded-btn "
            onClick={() =>
              setShowCreateOrganizationPopUp(
                hasRole(appContext.state.user.roles, UserRoles.ADMIN)
              )
            }
          >
            Ajouter une organisation
          </button>
        </div>
      )}
      <div
        className={`row ${styles.gridContainer} +  ${
          numberOfOrganizations > 0 ? "mt-4" : ""
        }`}
      >
        {filteredOrganizations.map((org, index) => {
          return (
            <OrganizationCard
              key={index}
              organization={org}
              readOnly={false}
              showCardLegend={false}
              showRemoveButton={false}
              breadcrumb={getBreadCrumbs(org).map((org1) => (
                <a
                  key={org1.id}
                  className={`${styles.breadcrumbLink}`}
                  style={{ color: "" }}
                  href={"/admin/organizations/" + org1.id + "/view"}
                >
                  {org1.name}
                </a>
              ))}
            />
          );
        })}
      </div>
      {showCreateOrganizationPopUp && (
        <div className="full-screen-modal">
          <Modal
            show
            onClose={() => {
              setShowCreateOrganizationPopUp(false);
            }}
            title={"Nouvelle organisation"}
          >
            <>
              <EditOrganization
                onEditOrganization={(org) => onSubmit(org)}
                onCancel={() => {
                  setShowCreateOrganizationPopUp(false);
                }}
                saveShortString={"Valider"}
              />
            </>
          </Modal>
        </div>
      )}
    </>
  );
};
