import { UserStatus } from "./UserStatus";
import { UserSubSpecialties } from "./UserSubSpecialties";
import { Calendar } from "./Calendar";
import { Facility } from "./Facility";
import OpinionRequest from "./OpinionRequest";
import { UserRoles } from "./UserRoles";

export interface UserFacility {
  facilityId: string;
  service?: string;
}

export interface UserOrganization {
  organizationId: string;
  isDispatcher: boolean;
  isAdmin: boolean;
  isExpert: boolean;
}

export interface User {
  id: string;
  email: string;
  ipdId?: string;
  professionalTitle: HealthProfessionalTitle;
  civilTitle: CivilTitle;
  lastName: string;
  firstName: string;
  phone: string;
  facilityId?: string;
  roles: string[];
  organizations: UserOrganization[];
  specialty: string;
  subSpecialties?: UserSubSpecialties[];
  profession?: string;
  selfie?: string;
  rpps?: string;
  status: UserStatus;
  calendar?: Calendar;
  facilities?: Facility[];
  userFacilities?: UserFacility[];
  allowNotifications?: boolean;
  allowSmsNotifications?: boolean;
  allowEmailNotifications?: boolean;
  allowExpertNotifications?: boolean;
  termsOfUseAgreement?: boolean;
  stillValid2FA?: boolean;
}

export interface HealthProfessionalUser {
  userId: string;
  rpps?: string;
  professionalTitle: HealthProfessionalTitle;
  civilTitle?: CivilTitle;
  firstName: string;
  lastName: string;
  profession?: string;
  specialty?: string;
  email?: string;
  phone?: string;
  facilityId?: string;
}

export enum HealthProfessionalTitle {
  NONE = "NONE",
  DOCTOR = "DOCTOR",
  PROFESSOR = "PROFESSOR",
}

export enum ProfessionCategory {
  PARAMEDICAL = "PARAMEDICAL",
  DOCTOR = "DOCTOR",
  ALL = "ALL",
}

export enum CivilTitle {
  NONE = "NONE",
  MISTER = "M",
  MADAM = "MME",
  OTHER = "OTHER",
  MISS = "MLLE",
}

export const getCivilTitleLabel = (title?: CivilTitle): string => {
  switch (title) {
    case CivilTitle.MISTER:
      return "Monsieur";
    case CivilTitle.MADAM:
      return "Madame";
    case CivilTitle.MISS:
      return "Mademoiselle";
    case CivilTitle.OTHER:
      return "Autre";
    default:
      return "";
  }
};

export const getProfessionalTitleLabel = (
  title?: HealthProfessionalTitle
): string => {
  switch (title) {
    case HealthProfessionalTitle.DOCTOR:
      return "Docteur";
    case HealthProfessionalTitle.PROFESSOR:
      return "Professeur";
    default:
      return "";
  }
};

export const getMinimizedTitleLabel = (
  title?: HealthProfessionalTitle
): string => {
  switch (title) {
    case HealthProfessionalTitle.DOCTOR:
      return "Dr ";
    case HealthProfessionalTitle.PROFESSOR:
      return "Pr ";
    default:
      return "";
  }
};

export function isOrganizationDispatcherOnOpinionRequest(
  user: User,
  request: OpinionRequest
): boolean {
  if (!request.assignedOrganizations) {
    return false;
  }
  if (!user.roles.includes(UserRoles.ORGANIZATION_DISPATCHER)) {
    return false;
  }
  for (const org of request?.assignedOrganizations) {
    if (user.organizations.some((o) => o.organizationId === org.id)) {
      return true;
    }
  }
  return false;
}
