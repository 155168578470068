export enum Brands {
  D_OPEN_CLNICS = "dopenclinics",
  TELE_GHU_PARIS = "teleghuparis",
  HANDI_NEURO = "handineuro",
}

export enum BrandUri {
  D_OPEN_CLNICS = "d-open-clinics",
  TELE_GHU_PARIS = "tele-ghu-paris",
  HANDI_NEURO = "handineuro",
}

export function getWebsiteTitle(brand: string): string {
  switch (brand) {
    case Brands.TELE_GHU_PARIS:
      return "TéléGHU Paris";
    case Brands.HANDI_NEURO:
      return "HandiNeuro";
    default:
      return "D'Open Clinics";
  }
}
