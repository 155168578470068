import React, { useContext, useEffect, useState } from "react";
import "./CondensedOpinionRequest.css";
import OpinionRequest, { SharePermission } from "../../domain/OpinionRequest";
import { AppContext } from "../../utils/state";
import { ModalSelectSummoningContainer } from "../Dispatch/ModalSelectSummoningContainer";
import {
  isOrganizationDispatcherOnOpinionRequest,
  User,
} from "../../domain/User";
import { ExpertReply } from "../../domain/ExpertReply";
import { Reply } from "../OpinionRequestForm/Reply";
import { DiseaseDetails } from "./DiseaseDetails/DiseaseDetails";
import { RequestDetails } from "./RequestDetails/RequestDetails";
import { ParticipantDetails } from "./ParticipantDetails/ParticipantDetails";
import { CondensedHeader } from "./Header/CondensedHeader";
import { DeclineMotive } from "./ExpertDeclineOpinionRequest";
import { FooterBar } from "./Footer/FooterBar";
import { hasRole } from "../../utils/authorization";
import { UserRoles } from "../../domain/UserRoles";
import { RequestStatus } from "../../domain/RequestStatus";
import history from "../../utils/history";
import { ModalInfo } from "../Shared/ModalInfo";
import { deleteOpinionRequest } from "../../services/OpinionRequestService";
import { errorToast, successToast } from "../../utils/Toast";
import { OpinionChatTab } from "../Chat/OpinionChatTab";
import { LabelEmergency } from "../Shared/LabelEmergency";
import { EmergencyLevel } from "../../domain/EmergencyLevel";
import { UploadToDmp } from "../OpinionRequestForm/UploadToDmp";
import { isNotProd } from "../../utils/config";
import { Organization } from "../../domain/Organization";
import { BackButton } from "../Shared/BackButton";

interface Props {
  request: OpinionRequest;
  readOnly: boolean;
  refresh: () => void;
  isSubmitting: boolean;
  submitRequest: () => void;
  validateRequest: () => void;
  setAssignedExpertsAndOrganizations: (
    experts: User[],
    organizations: Organization[]
  ) => boolean;
  setExpertReply: (reply: ExpertReply) => void;
  setDraftExpertReply: (reply: ExpertReply) => void;
  acceptRequest: () => void;
  declineRequest: (comment: string, motive: DeclineMotive) => void;
  updateRequest: () => void;
}

enum Tab {
  diseaseTab,
  requestTab,
  participantTab,
}

export const CondensedOpinionRequest: React.FC<any> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);
  const [isAffecting, setIsAffecting] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isDispatcherOfOrganization, setIsDispatcherOfOrganization] = useState<
    boolean
  >(false);
  const [
    showConfirmationUploadToDmpModal,
    setShowConfirmationUploadToDmpModal,
  ] = useState<boolean>(false);
  const [showUploadToDmpModal, setShowUploadToDmpModal] = useState<boolean>(
    false
  );
  const currentUser = useContext(AppContext).state.user;

  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.diseaseTab);

  useEffect(() => {
    if (
      (hasRole(currentUser.roles, UserRoles.GLOBAL_DISPATCHER) ||
        hasRole(currentUser.roles, UserRoles.ORGANIZATION_DISPATCHER)) &&
      props.request &&
      (props.request.status === RequestStatus.SUBMITTED ||
        RequestStatus.VALIDATED)
    ) {
      setIsFooterVisible(true);
    } else if (
      props.request &&
      props.request.status === RequestStatus.VALIDATED &&
      props.request.assignedExperts?.some(
        (expert) => expert.userId === currentUser.id
      )
    ) {
      setIsFooterVisible(true);
    } else if (props.request && props.request.status === RequestStatus.CLOSED) {
      setIsFooterVisible(true);
    } else if (
      props.request &&
      props.request.status === RequestStatus.DRAFT &&
      props.request.participants?.some(
        (p) =>
          p.userId === currentUser.id && p.permission === SharePermission.ADMIN
      )
    ) {
      setIsFooterVisible(true);
    } else {
      setIsFooterVisible(false);
    }
    if (props.request?.assignedOrganizations) {
      if (
        hasRole(appContext.state.user.roles, UserRoles.GLOBAL_DISPATCHER) ||
        isOrganizationDispatcherOnOpinionRequest(
          appContext.state.user,
          props.request
        )
      ) {
        setIsDispatcherOfOrganization(true);
      }
    }
  }, [props.request, currentUser]);

  const goToRequests = (): void => {
    history.push(`/`);
  };

  const onDelete = async (): Promise<void> => {
    if (props.request.id) {
      const res = await deleteOpinionRequest(props.request.id);
      if (res instanceof Error) {
        errorToast("La demande n'a pas pu être supprimée");
      } else {
        successToast("La demande a été supprimée");
        goToRequests();
      }
    }
  };

  function isPropsValid(): boolean {
    const patientBirthdate = `${props.request.patient.birthdate.year}-${props.request.patient.birthdate.month}-${props.request.patient.birthdate.day}`;
    return !!(
      props.request.patient.birthdate &&
      patientBirthdate.length == 10 &&
      props.request.patient.socialSecurityNumber
    );
  }

  return (
    <>
      <div className="text-left">
        <BackButton />
      </div>
      <div className="demand-navbar-container">
        <CondensedHeader
          request={props.request}
          readOnly={props.readOnly}
          affectExpert={(): any => setIsAffecting(true)}
          refreshRequest={props.refresh}
        />
        {props.request?.emergencyLevel === EmergencyLevel.DELAYED_EMERGENCY && (
          <div className="m-auto">
            <LabelEmergency />
          </div>
        )}
        <div className="row demand-btn-container">
          <button
            id="disease"
            className={
              "request-menu " +
              (selectedTab === Tab.diseaseTab && "request-menu-active")
            }
            onClick={(): any => setSelectedTab(Tab.diseaseTab)}
          >
            Pathologie principale
          </button>
          <button
            id="request"
            className={
              "request-menu " +
              (selectedTab === Tab.requestTab && "request-menu-active")
            }
            onClick={(): any => setSelectedTab(Tab.requestTab)}
          >
            Demande
          </button>
          <button
            id="participants"
            className={
              "request-menu " +
              (selectedTab === Tab.participantTab && "request-menu-active")
            }
            onClick={(): any => setSelectedTab(Tab.participantTab)}
          >
            Intervenants
          </button>
        </div>
      </div>
      <div
        className={
          "bg-light-blue-1 flex-1" + isFooterVisible ? " footer-padding" : ""
        }
      >
        {selectedTab === Tab.diseaseTab && (
          <DiseaseDetails request={props.request} />
        )}
        {selectedTab === Tab.requestTab && (
          <RequestDetails
            request={props.request}
            updateRequest={(): any => props.refresh()}
          />
        )}
        {selectedTab === Tab.participantTab && (
          <ParticipantDetails
            request={props.request}
            currentUserId={currentUser.id}
            onAdd={(): any => props.refresh()}
            onEdit={(): any => props.refresh()}
            onDelete={(): any => props.refresh()}
            isAffecting={(): any => setIsAffecting(true)}
          />
        )}
      </div>
      {props.request && isFooterVisible && (
        <FooterBar
          request={props.request}
          submitRequest={(): any => {
            props.submitRequest();
          }}
          validateRequest={(): any => {
            props.validateRequest();
          }}
          isSubmitting={props.isSubmitting}
          isAffecting={(): any => {
            setIsAffecting(true);
          }}
          isReplying={(): any => {
            setIsReplying(true);
          }}
          expertDecline={(comment: string, motive: DeclineMotive): any =>
            props.declineRequest(comment, motive)
          }
          expertAccept={props.acceptRequest}
        />
      )}
      {isDispatcherOfOrganization && (
        <div className="chat-btn-container">
          {showChat && (
            <div className="flex-col">
              <button
                className="expand-collapse-chat-btn"
                onClick={(): void => {
                  setShowChat(false);
                }}
              >
                <span className="fa fa-angle-double-down text-white" />
              </button>
              <OpinionChatTab
                requestId={props.request?.id}
                requestClosed={props.request.status === RequestStatus.CLOSED}
                onClose={(): any => setShowChat(false)}
                updateRequest={props.updateRequest}
              />
            </div>
          )}
          {!showChat && (
            <div className="flex-col">
              <button
                className="expand-collapse-chat-btn"
                onClick={(): void => {
                  setShowChat(true);
                }}
              >
                <span className="fa fa-angle-double-up text-white" />
              </button>
              <span className="chat-title text-bold">
                <i className="fa fa-commenting mr-4" />
                Discussion
              </span>
            </div>
          )}
        </div>
      )}
      {isAffecting && props.request && props.request.id && (
        <ModalSelectSummoningContainer
          requestId={props.request.id}
          key={"aff_organization_" + props.request.id}
          requesterId={
            props.request.requesterUser?.id
              ? props.request.requesterUser.id
              : ""
          }
          assignedExperts={props.request.assignedExperts}
          assignedOrganizations={
            props.request.assignedOrganizations
              ? props.request.assignedOrganizations
              : []
          }
          onAddExpertsAndOrgaizations={(
            experts: User[],
            organizations: Organization[]
          ): any => {
            const requestCompleted: boolean = props.setAssignedExpertsAndOrganizations(
              experts,
              organizations
            );
            if (requestCompleted) {
              setIsAffecting(false);
            }
          }}
          onClose={(): any => setIsAffecting(false)}
        />
      )}
      {isReplying && props.request && props.request.id && (
        <Reply
          isSendableToDmp={isPropsValid()}
          onDraft={(draftReply: ExpertReply): any => {
            setIsReplying(false);
            props.setDraftExpertReply(draftReply);
            props.refresh();
            setSelectedTab(Tab.diseaseTab);
          }}
          onReply={(reply: ExpertReply): any => {
            setIsReplying(false);
            props.setExpertReply(reply);
            setShowConfirmationUploadToDmpModal(true);
            setSelectedTab(Tab.diseaseTab);
          }}
          onClose={(): any => setIsReplying(false)}
          draftResponse={props.request.expertReply}
        />
      )}
      {showDeleteModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowDeleteModal(false)}
          rightButtonAction={onDelete}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer cette demande ?</>
        </ModalInfo>
      )}
      {showConfirmationUploadToDmpModal && isNotProd() && (
        <ModalInfo
          title={"Alimenter le DMP"}
          onClose={(): any => setShowConfirmationUploadToDmpModal(false)}
          rightButtonAction={(): any => setShowUploadToDmpModal(true)}
          rightButtonText={"Oui"}
          leftButtonText={"Plus tard"}
          isRightButtonDisabled={!isPropsValid()}
        >
          <>
            <div className="flex-col">
              Souhaitez-vous alimenter le DMP avec le compte-rendu de
              téléexpertise ?
              <i className="text-small mt-2">
                Si vous cliquez sur &quot;Plus tard&quot;, vous pourrez toujours
                envoyer le compte rendu <br /> depuis le menu de la demande par
                la suite.
              </i>
              {!isPropsValid() && (
                <div className="text-small text-error mt-4">
                  La date de naissance complète et l&apos;INSi (Identifiant
                  National de Santé intégré) du patient doivent être renseignés
                  pour accéder à cette fonctionnalité.
                </div>
              )}
            </div>
          </>
        </ModalInfo>
      )}
      {showUploadToDmpModal && (
        <UploadToDmp
          isSendableToDmp={isPropsValid()}
          onClose={(isSuccess): any => {
            setShowUploadToDmpModal(false);
            if (isSuccess) {
              props.request.uploadDmpDate = Date.now().toString();
            }
          }}
          request={props.request}
        />
      )}
    </>
  );
};
