import React, { useState } from "react";
import { ModalEegRate } from "./ModalEegRate";
import styles from "./SubjectForm.module.css";

export const EegInterpretationForm: React.FC = () => {
  const [showEegRateModal, setShowEegRateModal] = useState<boolean>(false);

  return (
    <div>
      <div className="mt-4">
        <div className="row">
          <i className={`fa fa-info-circle ${styles.infoIcon} mr-2`} />
          <span className="text-primary-blue">
            Attention, l&apos;interprétation d&apos;EEG est un service payant.
          </span>
        </div>
        <div
          className="text-grey mb-2 mt-4"
          onClick={() => setShowEegRateModal(true)}
        >
          <span className={styles.seeRateEegTests}>
            Tarifs de lecture d&apos;EEG par établissement
          </span>
        </div>
      </div>
      {showEegRateModal && (
        <ModalEegRate onClose={() => setShowEegRateModal(false)} />
      )}
    </div>
  );
};
