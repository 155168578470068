import React, { useState } from "react";
import { getMinimizedTitleLabel, User } from "../../domain/User";
import {
  validateUserByAdmin,
  deleteUserByAdmin,
  askSelfieUpdateByAdmin,
} from "../../services/UserService";
import { errorToast, successToast } from "../../utils/Toast";
import styles from "./ToValidateUser.module.css";
import DefaultProfilePicture from "../../assets/illustrations/defaultProfilePicture.svg";
import { ModalInfo } from "../Shared/ModalInfo";
import history from "../../utils/history";
import { UserStatus } from "../../domain/UserStatus";
import {
  ErrorResponse,
  showNewSelfieAskedErrorMessage,
} from "../../domain/ErrorManagement";

interface Props {
  user: User;
  refreshUsers: () => any;
}

export const RppsImage: React.FC<any> = (props: {
  image: string;
  rpps: string;
  onClose: () => void;
}) => {
  return (
    <div className={`modal active`} id="modal-id">
      <div
        className="modal-overlay"
        aria-label="Close"
        onClick={(): any => props.onClose()}
      />
      <div className="modal-container">
        <div className="modal-header">
          <div
            className="btn btn-clear float-right"
            aria-label="Close"
            onClick={(): any => props.onClose()}
          />
          <div className={styles.rppsTitle}>
            Numéro RPPS:{" "}
            <span className={"text-bold " + styles.rppsNumber}>
              {props.rpps}
            </span>
          </div>
        </div>
        <div className="modal-body h-100">
          <img alt="Vérification Rpps" src={props.image} />
        </div>
      </div>
    </div>
  );
};

export const ToValidateUser: React.FC<Props> = (props: Props) => {
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const validateUser = async (): Promise<void> => {
    const res = await validateUserByAdmin(props.user);
    if (res instanceof Error) {
      errorToast("Erreur lors de la validation de l'utilisateur");
    } else {
      successToast("Utilisateur validé");
      props.refreshUsers();
    }
  };

  const deleteUser = async (): Promise<void> => {
    const res = await deleteUserByAdmin(props.user.id);
    if (res instanceof Error) {
      errorToast("Erreur lors de la suppression de l'utilisateur");
    } else {
      successToast("Utilisateur supprimé");
      props.refreshUsers();
    }
  };

  const askPictureChange = async (): Promise<void> => {
    if (props.user) {
      const res = await askSelfieUpdateByAdmin(props.user.id);

      if (res instanceof ErrorResponse || res instanceof Error) {
        showNewSelfieAskedErrorMessage(res);
      } else {
        successToast(
          "Votre demande de reprise de photo a été envoyée à l'adresse email de l'utilisateur"
        );
      }
      props.refreshUsers();
    }
  };

  return (
    <>
      <div
        className={"columns " + styles.userCard}
        onClick={(): void => {
          if (props.user.status !== UserStatus.DELETED) {
            history.push(`/admin/users/${props.user.id}/view`);
          }
        }}
      >
        <div className="col-4 col-sm-12">
          <div className={styles.imageContainer}>
            {props.user.selfie !== "" ? (
              <img
                alt="Vérification Rpps"
                src={props.user.selfie}
                className={styles.selfie}
              />
            ) : (
              <img
                className={styles.selfieAlternative}
                src={DefaultProfilePicture}
                alt="Profil par défaut"
              />
            )}
            {props.user.selfie !== "" && (
              <button
                className="btn image-btn"
                onClick={(event): any => {
                  event.stopPropagation();
                  setOpenImage(true);
                }}
              >
                <i className="icon icon-search" />
              </button>
            )}
          </div>
          <button
            className={"btn neuro-btn " + styles.validateButton}
            onClick={(event): void => {
              event.stopPropagation();
              askPictureChange();
            }}
          >
            Demander une nouvelle photo
          </button>
        </div>
        <div className={"col-8 col-sm-12 " + styles.userContainer}>
          <div className="user-card-name text-left mt-2">
            {getMinimizedTitleLabel(props.user.professionalTitle)}{" "}
            {props.user.firstName} {props.user.lastName}
          </div>
          <div className="user-profession text-left">
            {props.user.profession} {props.user.specialty}
          </div>
          <div className="row mt-2 space-between-content">
            <div className={"text-bold " + styles.categoryTitle}>RPPS</div>
            <div className={"text-bold " + styles.categoryInfo}>
              {props.user.rpps}
            </div>
          </div>
          <div className="row mt-1 space-between-content">
            <div className={"text-bold " + styles.categoryTitle}>Téléphone</div>
            <div className={"text-bold " + styles.categoryInfo}>
              {props.user.phone}
            </div>
          </div>
          <div className="row mt-1 space-between-content">
            <div className={"text-bold " + styles.categoryTitle}>Mail</div>
            <div className={"text-bold " + styles.categoryInfo}>
              {props.user.email}
            </div>
          </div>
          <div className={"row " + styles.containerBottom}>
            <button
              className="btn delete-btn"
              onClick={(event): any => {
                event.stopPropagation();
                setShowModal(true);
              }}
            >
              Supprimer
            </button>
            <button
              className={"btn neuro-btn " + styles.validateButton}
              onClick={(event): void => {
                event.stopPropagation();
                validateUser();
              }}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
      {openImage && (
        <RppsImage
          onClose={(): any => setOpenImage(false)}
          rpps={props.user.rpps}
          image={props.user.selfie}
        />
      )}
      {showModal && (
        <ModalInfo
          title={"Confirmation de suppression"}
          onClose={(): any => setShowModal(false)}
          rightButtonAction={deleteUser}
          rightButtonText={"Valider"}
          leftButtonText={"Annuler"}
        >
          <>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</>
        </ModalInfo>
      )}
    </>
  );
};
