import React from "react";

interface Props {
  onClose: () => void;
}

export const NotValidatedUser: React.FC<any> = (props: Props) => {
  return (
    <div className={`modal active`} id="modal-id">
      <div
        className="modal-overlay"
        aria-label="Close"
        onClick={(): any => props.onClose()}
      />
      <div className="modal-container">
        <div className="modal-header">
          <div
            className="btn btn-clear float-right"
            aria-label="Close"
            onClick={(): any => props.onClose()}
          />
          <div className="modal-title h5">Accès interdit</div>
        </div>
        <div className="modal-body h-100">
          <div className="text-modal">
            Votre compte n&apos;est pas encore validé, par conséquent vous
            n&apos;avez pas le droit de modifier cette demande.
          </div>
          <div className="contact-admin">
            Veuillez compléter votre inscription puis attendre qu&apos;un
            administrateur valide votre compte.
          </div>
        </div>
      </div>
    </div>
  );
};
