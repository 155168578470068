import React, { useState } from "react";
import { SearchUser } from "./SearchUser";
import { User } from "../domain/User";

const Components: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User>();

  return (
    <div>
      <SearchUser onChange={setSelectedUser} />
      <div>Selected user: {selectedUser?.firstName}</div>
    </div>
  );
};

export { Components };
