import React from "react";

interface Props {
  motive: string;
  readOnly?: boolean;
  changed: (newState: string) => any;
  isRequired: boolean;
}

export const RequestMotive: React.FC<Props> = (props: Props) => (
  <div>
    <div className="form-title mb-0">
      Motif de la demande<span className="error-msg text-large ml-2">*</span>
    </div>
    <textarea
      className="form-input w-90 margin-top-2"
      id="request-motive-input"
      placeholder="Entrez ici votre demande, veillez à bien expliciter votre question."
      rows={7}
      readOnly={props.readOnly}
      defaultValue={props.motive}
      onChange={(event): any => props.changed(event.target.value)}
      required={props.isRequired}
    />
    {props.isRequired && props.motive === "" && (
      <div className="error-msg mb-2 ml-1 mt-1">Merci de remplir ce champ.</div>
    )}
  </div>
);
