import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getMinimizedTitleLabel,
  HealthProfessionalUser,
  ProfessionCategory,
  User,
} from "../../domain/User";
import Modal from "../Modal";
import OpinionRequest, { Expert } from "../../domain/OpinionRequest";
import { ModalFooter } from "../OpinionRequestForm/Participants/ModalFooter";
import { SummonsType } from "../../domain/SummonsType";
import { ChoiceSwitcher } from "../Shared/ChoiceSwitcher";
import {
  getOpinionRequest,
  getOpinionRequestExperts,
} from "../../services/OpinionRequestService";
import { Organization } from "../../domain/Organization";
import { errorToast } from "../../utils/Toast";
import {
  createUser,
  getUser,
  searchHealthProfessionalsAndUsers,
} from "../../services/UserService";
import { debounce } from "../../utils/debounce";
import styles from "./ModalSelectSummoningContainer.module.css";
import { ExpertStatus } from "../../domain/ExpertStatus";
import { Figure } from "../Shared/Figure";
import { CheckBox } from "../Shared/Checkbox";
import { ExpertCard } from "./ExpertCard";
import { OrganizationCard } from "../Admin/OrganizationCard";
import { searchOrganizationByName } from "../../services/OrganizationService";
import { AddNewUser } from "../OpinionRequestForm/Participants/AddNewUser";
import { AppContext } from "../../utils/state";

interface Props {
  requestId: string;
  requesterId: string;
  assignedExperts?: Expert[];
  assignedOrganizations?: Organization[];
  onClose: () => void;
  onAddExpertsAndOrgaizations: (
    assignExperts: User[],
    assignedOrganizations: Organization[]
  ) => void;
}

interface Filter {
  label: string;
  checked: boolean;
}

type AbstractUser = HealthProfessionalUser | User;

/* TODO do not remove the following comment, it will be used later to filter experts according to different criteria*/
/*enum FacilityType {
  HOSPITAL = "HOSPITAL",
  CITY = "CITY",
}*/

export const ModalSelectSummoningContainer: React.FC<Props> = (
  props: Props
) => {
  const appContext = useContext(AppContext);
  const [currentSummoningType, setCurrentSummoningType] = useState<SummonsType>(
    SummonsType.EXPERT
  );
  const [opinionRequest, setOpinionRequest] = useState<OpinionRequest>();
  const [assignedExperts, setAssignedExperts] = useState<User[]>([]);
  const [isExpertAssigned, setIsExpertAssigned] = useState<boolean>(false);
  const [assignedOrganizations, setAssignedOrganizations] = useState<
    Organization[]
  >([]);
  const [allExperts, setAllExperts] = useState<HealthProfessionalUser[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const [searchNameValue, setSearchNameValue] = useState<string>("");
  const [searchSpecialtyValue, setSearchSpecialtyValue] = useState<string>("");
  const [searchSubSpecialtyValue, setSearchSubSpecialtyValue] = useState<
    string
  >("");
  const [informationMessage, setInformationMessage] = useState(
    "Commencez votre recherche en renseignant un filtre."
  );
  const [filters, setFilters] = useState<Filter[]>([
    { label: "Nom", checked: true },
    { label: "Spécialité", checked: false },
    { label: "Sous-spécialité", checked: false },
  ]);

  const [selectedFacilityType] = useState<any>();
  const isMounted = useRef(false);

  const [hpToAdd, setHpToAdd] = useState<HealthProfessionalUser>();
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<HealthProfessionalUser[]>(
    []
  );

  async function sortExpertList(list: AbstractUser[]): Promise<void> {
    list?.sort((item1: AbstractUser, item2: AbstractUser) => {
      if (item1.lastName === item2.lastName) {
        return item1.firstName > item2.firstName ? 1 : -1;
      }
      return item1.lastName > item2.lastName ? 1 : -1;
    });
  }

  const retrieveUsersAndCreateNew = async (): Promise<void> => {
    for (const user of selectedUsers) {
      if (user.userId === "") {
        const resp = await createUser(user);
        if (resp instanceof Error) {
          errorToast(
            `Erreur lors de la création de l'utilisateur, l'expert ${user.firstName} ${user.lastName} n'a pas été ajouté`
          );
          continue;
        }
        if (resp.message !== "") {
          const res = await getUser(resp.message);
          if (res instanceof Error) {
            errorToast(
              `Erreur lors de la récupération de l'utilisateur, l'expert ${user.firstName} ${user.lastName} n'a pas été trouvé`
            );
            continue;
          } else {
            assignedExperts.push(res);
          }
        }
      } else if (!assignedExperts.find((e) => e.id === user.userId)) {
        const res = await getUser(user.userId);
        if (res instanceof Error) {
          errorToast(
            `Erreur lors de la récupération de l'utilisateur, l'expert ${user.firstName} ${user.lastName} n'a pas été trouvé`
          );
          continue;
        }
        assignedExperts.push(res);
      }
    }
  };

  const onCloseModal = async (): Promise<void> => {
    if (currentSummoningType === SummonsType.EXPERT) {
      await retrieveUsersAndCreateNew();
    }
    props.onAddExpertsAndOrgaizations(assignedExperts, assignedOrganizations);
    close();
  };

  const usersToHpUsers = (users: User[]): HealthProfessionalUser[] => {
    const hpUsers: HealthProfessionalUser[] = [];
    if (!users) {
      return hpUsers;
    }
    for (const user of users) {
      const hpUser = {
        userId: user.id,
        rpps: user.rpps,
        professionalTitle: user.professionalTitle,
        civilTitle: user.civilTitle,
        firstName: user.firstName,
        lastName: user.lastName,
        profession: user.profession,
        specialty: user.specialty,
        email: user.email,
        phone: user.phone,
        facilityId: user.facilityId,
      };
      hpUsers.push(hpUser);
    }
    return hpUsers;
  };

  const handleCheckUserElement = (
    event: any,
    expert: HealthProfessionalUser
  ): void => {
    if (event.target.checked) {
      selectExpert(expert);
    } else {
      deselectExpert(expert);
    }
  };

  const selectExpert = (expert: HealthProfessionalUser): void => {
    if (expert.userId === "") {
      setHpToAdd(expert);
      setShowAddUserModal(true);
    } else if (!selectedUsers.some((e) => e.userId === expert.userId)) {
      selectedUsers.push(expert);
      setSelectedUsers([...selectedUsers]);
    }
  };

  const deselectExpert = (expert: HealthProfessionalUser): void => {
    if (expert.userId === "") {
      // remove from selected users
      const index = selectedUsers.map((item) => item.rpps).indexOf(expert.rpps);
      if (index > -1) {
        selectedUsers.splice(index, 1);
        setSelectedUsers([...selectedUsers]);
      }
    } else {
      // remove from selected users
      const index = selectedUsers
        .map((item) => item.userId)
        .indexOf(expert.userId);
      if (index > -1) {
        selectedUsers.splice(index, 1);
        setSelectedUsers([...selectedUsers]);
      }
      // remove from assigned expert
      const wasAssigned = assignedExperts.find(
        (item) => item.id == expert.userId
      );
      if (wasAssigned) {
        const i = assignedExperts.map((item) => item.id).indexOf(expert.userId);
        if (i > -1) {
          assignedExperts.splice(i, 1);
          setAssignedExperts([...assignedExperts]);
        }
      }
    }
  };

  const handleCheckOrganizationElement = (
    event: any,
    organization: Organization
  ): void => {
    if (event.target.checked) {
      if (!assignedOrganizations.some((o) => o.id === organization.id)) {
        assignedOrganizations.push(organization);
        setAssignedOrganizations([...assignedOrganizations]);
      }
    } else {
      const index = assignedOrganizations
        .map((item) => item.id)
        .indexOf(organization.id);
      if (index > -1) {
        assignedOrganizations.splice(index, 1);
        setAssignedOrganizations([...assignedOrganizations]);
      }
    }
  };

  const handleCheckFilter = (event: any): void => {
    filters.forEach((filter) => {
      if (filter.label === event.target.value)
        filter.checked = event.target.checked;

      if (event.target.checked === false) {
        switch (event.target.value) {
          case "Nom":
            setSearchNameValue("");
            break;
          case "Spécialité":
            setSearchSpecialtyValue("");
            break;
          case "Sous-spécialité":
            setSearchSubSpecialtyValue("");
            break;
          default:
            break;
        }
      }
    });
    setFilters([...filters]);
  };

  const close = (): void => {
    props.onClose();
  };

  useEffect(() => {
    if (currentSummoningType === SummonsType.EXPERT) {
      setSearchNameValue("");
      setInformationMessage(
        "Commencez votre recherche en renseignant un filtre."
      );
      setAllOrganizations([]);
    } else {
      setSearchNameValue("");
      setSearchSpecialtyValue("");
      setSearchSubSpecialtyValue("");
      setFilters([
        { label: "Nom", checked: true },
        { label: "Spécialité", checked: false },
        { label: "Sous-spécialité", checked: false },
      ]);
      setInformationMessage("");
      setAllExperts([]);
    }
  }, [currentSummoningType]);

  useEffect(() => {
    async function getAllAssignedExperts(): Promise<User[]> {
      const res = await getOpinionRequestExperts(props.requestId);
      if (res instanceof Error) {
        errorToast("Erreur lors de la récupération des experts");
      } else {
        await sortExpertList(res);
        const result = res || [];
        setAssignedExperts(result);
        if (result.some((e) => e.id === appContext.state.user.id)) {
          setIsExpertAssigned(true);
        }
        const hpUsers = usersToHpUsers(res);
        setSelectedUsers(hpUsers);
        return result;
      }
      return [];
    }
    async function getCurrentOpinionRequest(): Promise<any> {
      const res = await getOpinionRequest(props.requestId);
      if (!(res instanceof Error)) {
        if (
          res.assignedOrganizations &&
          res.assignedOrganizations?.length > 0
        ) {
          setAssignedOrganizations(res.assignedOrganizations);
        } else {
          setAssignedOrganizations([]);
        }
        setOpinionRequest(res);
      }
    }

    getAllAssignedExperts();
    getCurrentOpinionRequest();
  }, [props.requestId]);

  const search = async (
    inputName: string,
    inputSpeciality: string,
    inputSubSpeciality: string,
    inputSummoningType: SummonsType
  ): Promise<any> => {
    if (
      inputName === "" &&
      inputSpeciality === "" &&
      inputSubSpeciality === "" &&
      inputSummoningType === SummonsType.EXPERT
    ) {
      setAllExperts([]);
      return;
    }
    if (inputName === "" && SummonsType.ORGANIZATION) {
      setAllOrganizations([]);
      return;
    }
    const selectedFacilityTypeValue = "";
    // TODO do not remove the following comment, it will be used later to filter experts according to different criteria
    /*if (selectedFacilityType) {
      selectedFacilityTypeValue = selectedFacilityType.value;
    }*/
    if (inputSummoningType === SummonsType.EXPERT) {
      const res = await searchHealthProfessionalsAndUsers(
        inputName,
        ProfessionCategory.DOCTOR,
        inputSpeciality,
        inputSubSpeciality,
        selectedFacilityTypeValue
      );
      if (res instanceof Error) {
        setAllExperts([]);
        setInformationMessage(res.message);
      } else if (res !== null) {
        const list = res.filter(
          (user: HealthProfessionalUser) => user.userId !== props.requesterId
        );
        await sortExpertList(list);
        if (list.some((expert) => expert.userId == appContext.state.user.id)) {
          setAllExperts(
            list.filter((expert) => expert.userId !== appContext.state.user.id)
          );
        } else {
          setAllExperts(list);
        }
        if (list.length === 0) {
          setInformationMessage("Pas de résultat pour cette recherche.");
        }
      }
    } else {
      const res = await searchOrganizationByName(inputName);
      if (res instanceof Error) {
        setAllOrganizations([]);
        setInformationMessage(res.message);
      } else {
        setAllOrganizations(res);
        if (res.length === 0) {
          setInformationMessage("Pas de résultat pour cette recherche.");
        }
      }
    }
  };

  const debouncedSearch = useMemo(() => debounce(search, 300), []);

  useEffect(() => {
    if (isMounted.current) {
      debouncedSearch(
        searchNameValue,
        searchSpecialtyValue,
        searchSubSpecialtyValue,
        currentSummoningType
      );
    } else {
      isMounted.current = true;
    }
  }, [
    searchNameValue,
    searchSpecialtyValue,
    searchSubSpecialtyValue,
    selectedFacilityType,
    props.requesterId,
  ]);

  return (
    <div className="full-screen-modal">
      <Modal
        show
        onClose={close}
        title="Assigner la demande"
        footer={
          <>
            <div className="footer-line" />
            <ModalFooter
              titleButtonRight={"Valider les modifications"}
              onClickButtonRight={onCloseModal}
              titleButtonLeft={"Annuler"}
              onClickButtonLeft={close}
            />
          </>
        }
      >
        <div className="content">
          <div className="row centered-content mb-2">
            {!isExpertAssigned && (
              <ChoiceSwitcher
                onClick={(choice: any) =>
                  choice.target.value === SummonsType.EXPERT
                    ? setCurrentSummoningType(SummonsType.EXPERT)
                    : setCurrentSummoningType(SummonsType.ORGANIZATION)
                }
                currentChoice={currentSummoningType}
                choiceName={
                  opinionRequest?.assignedOrganizations
                    ? SummonsType.ORGANIZATION
                    : SummonsType.EXPERT
                }
                firstChoice={SummonsType.EXPERT}
                firstChoiceName={"Expert"}
                secondChoice={SummonsType.ORGANIZATION}
                secondChoiceName={"Organisation"}
                disabled={false}
              />
            )}
          </div>
          {currentSummoningType === SummonsType.EXPERT ? (
            <div>
              <div className={styles.assignExpertsContainer}>
                {selectedUsers
                  .filter(
                    (expert) =>
                      (props.assignedExperts &&
                        props.assignedExperts?.find(
                          (assignExpert: Expert) =>
                            assignExpert.userId === expert.userId
                        )?.status === ExpertStatus.WAITING) ||
                      props.assignedExperts?.find(
                        (assignExpert: Expert) =>
                          assignExpert.userId === expert.userId
                      ) === undefined
                  )
                  .map((expert, index) => {
                    return (
                      <div key={"assign_" + expert.rpps}>
                        {index === 0 && (
                          <div className="text-left mb-1 modal-label">
                            Experts affectés à cette demande n&apos;ayant pas
                            encore répondu
                          </div>
                        )}
                        <div
                          className={`selected-user mb-2 ${styles.maxWidth}`}
                          id="expert-assigned-no-answer-yet--card"
                        >
                          <div className="row">
                            <div className="mr-2">
                              <Figure
                                firstName={expert.firstName}
                                lastName={expert.lastName}
                                fontSize={"text-xsmall"}
                              />
                            </div>
                            <div>
                              {getMinimizedTitleLabel(
                                expert?.professionalTitle
                              )}{" "}
                              {expert?.firstName} {expert?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.assignExpertsContainer}>
                {selectedUsers
                  .filter(
                    (expert) =>
                      props.assignedExperts &&
                      props.assignedExperts?.find(
                        (assignExpert: Expert) =>
                          assignExpert.userId === expert.userId
                      )?.status === ExpertStatus.ACCEPTED
                  )
                  .map((expert, index) => {
                    return (
                      <div key={"assign_" + expert.rpps}>
                        {index === 0 && (
                          <div className="text-left mb-1 modal-label">
                            Experts ayant accepté cette demande
                          </div>
                        )}
                        <div
                          className={`selected-user mb-2 ${styles.maxWidth}`}
                          id="expert-assigned-accepted-card"
                        >
                          <div className="row">
                            <div className="mr-2">
                              <Figure
                                firstName={expert.firstName}
                                lastName={expert.lastName}
                                fontSize={"text-xsmall"}
                              />
                            </div>
                            <div>
                              {getMinimizedTitleLabel(
                                expert?.professionalTitle
                              )}{" "}
                              {expert?.firstName} {expert?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.assignExpertsContainer}>
                {selectedUsers
                  .filter(
                    (expert) =>
                      props.assignedExperts &&
                      props.assignedExperts?.find(
                        (assignExpert: Expert) =>
                          assignExpert.userId === expert.userId
                      )?.status === ExpertStatus.REFUSED
                  )
                  .map((expert, index) => {
                    return (
                      <div key={"assign_" + expert.rpps}>
                        {index === 0 && (
                          <div className="text-left mb-1 modal-label">
                            Experts ayant refusé cette demande
                          </div>
                        )}
                        <div
                          className={`selected-user mb-2 ${styles.maxWidth}`}
                          id="expert-assigned-refused-card"
                        >
                          <div className="row">
                            <div className="mr-2">
                              <Figure
                                firstName={expert.firstName}
                                lastName={expert.lastName}
                                fontSize={"text-xsmall"}
                              />
                            </div>
                            <div>
                              {getMinimizedTitleLabel(
                                expert?.professionalTitle
                              )}{" "}
                              {expert?.firstName} {expert?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="row w-100">
                <div className="no-shrink mr-2">
                  Saississez vos critères de recherche :
                </div>
              </div>
              {/* TODO do not remove the following comment, it will be used later to filter experts according to different criteria*/}
              {/*<div className="mt-2 mb-2">
              <Select
                options={facilityType}
                value={selectedFacilityType}
                placeholder="Type de médecin"
                components={{ Control }}
                onChange={(selectedValue: any) =>
                  setSelectedFacilityType(selectedValue)
                }
                styles={{
                  menu: (): Record<string, any> => ({
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    width: "100%",
                  }),
                  control: (provided): Record<string, any> => ({
                    ...provided,
                    backgroundColor: "var(--primary-blue)",
                    boxShadow: "none",
                    ":hover": {
                      borderColor: "var(--primary-blue)",
                    },
                  }),
                  container: (): Record<string, any> => ({
                    width: "100%",
                  }),
                  placeholder: (provided): Record<string, any> => ({
                    ...provided,
                    color: "white",
                  }),
                  indicatorSeparator: (): Record<string, any> => ({
                    display: "none",
                  }),
                  clearIndicator: (): Record<string, any> => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided): Record<string, any> => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      color: "white",
                    },
                  }),
                  singleValue: (): Record<string, any> => ({
                    color: "white",
                  }),
                  option: (provided, state): Record<string, any> => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? "var(--secondary-blue)"
                      : "white",
                    ":hover": {
                      backgroundColor: "var(--secondary-blue)",
                    },
                    color: "var(--dark-grey)",
                    whiteSpace: "pre-wrap",
                    textAlign: "left",
                  }),
                  input: (): Record<string, any> => ({
                    color: "transparent",
                  }),
                }}
              />
            </div>*/}
              <div className="row w-100">
                <div className="no-shrink mr-2">Trier par :</div>
                {filters.map((filter, index) => {
                  return (
                    <React.Fragment key={index}>
                      <CheckBox
                        id={filter.label}
                        checkboxId={filter.label}
                        value={filter.label}
                        handleCheckElement={handleCheckFilter}
                        isChecked={filter.checked}
                      />
                      <label className="mr-2 ml-2">{filter.label}</label>
                    </React.Fragment>
                  );
                })}
              </div>
              {filters.find((filter) => filter.label === "Nom")?.checked && (
                <div className="row w-100">
                  <div className="no-shrink mr-2">Nom :</div>
                  <input
                    id="search-name-user"
                    type="text"
                    value={searchNameValue}
                    onChange={(event): any => {
                      setSearchNameValue(event.target.value);
                    }}
                    className="form-input"
                    placeholder="Rechercher un expert par nom"
                  />
                </div>
              )}
              {filters.find((filter) => filter.label === "Spécialité")
                ?.checked && (
                <div className="row w-100">
                  <div className="no-shrink mr-2">Spécialité :</div>
                  <input
                    id="search-specialty-Users"
                    type="text"
                    value={searchSpecialtyValue}
                    onChange={(event): any => {
                      setSearchSpecialtyValue(event.target.value);
                    }}
                    className="form-input"
                    placeholder="Rechercher un expert par spécialité"
                  />
                </div>
              )}
              {filters.find((filter) => filter.label === "Sous-spécialité")
                ?.checked && (
                <div className="row w-100">
                  <div className="no-shrink mr-2">Sous-spécialité :</div>
                  <input
                    id="search-subSpecialty-Users"
                    type="text"
                    value={searchSubSpecialtyValue}
                    onChange={(event): any => {
                      setSearchSubSpecialtyValue(event.target.value);
                    }}
                    className="form-input"
                    placeholder="Rechercher un expert par sous-spécialité"
                  />
                </div>
              )}
              <div className="text-left mb-1 modal-label">
                Liste des experts
              </div>
              {allExperts.length === 0 && (
                <div className="mt-2 text-italic">{informationMessage}</div>
              )}
              {allExperts &&
                allExperts.map((expert) => (
                  <div key={expert.rpps}>
                    <label className="form-checkbox c-hand expert-card-container">
                      <CheckBox
                        id={expert.userId ?? expert.rpps}
                        checkboxId={expert.userId ?? expert.rpps}
                        value={expert.rpps}
                        isChecked={selectedUsers
                          .concat()
                          .map((i) => i.rpps)
                          .includes(expert.rpps)}
                        handleCheckElement={(event: ChangeEvent): void =>
                          handleCheckUserElement(event, expert)
                        }
                      />
                      <i className={`form-icon ${styles.checkBox}`} />
                      <div className="row">
                        <ExpertCard expert={expert} />
                      </div>
                    </label>
                  </div>
                ))}
              <AddNewUser
                userToAdd={hpToAdd}
                show={showAddUserModal}
                onClose={(): any => {
                  if (hpToAdd) {
                    deselectExpert(hpToAdd);
                  }
                  setShowAddUserModal(false);
                  setHpToAdd(undefined);
                }}
                filterUsersRpps={selectedUsers.map((u): any => u.userId)}
                onAddUser={(hpUser: HealthProfessionalUser): any => {
                  setShowAddUserModal(false);
                  setSelectedUsers((prevState) => [...prevState, hpUser]);
                }}
              />
            </div>
          ) : (
            <div className="flex-col">
              {assignedOrganizations && assignedOrganizations.length > 0 ? (
                <div className="flex-col">
                  <span className="ml-1 mb-2 mr-auto modal-label">
                    Organisations assignées à la demande :
                  </span>
                  <div className="ml-1">
                    {assignedOrganizations.map((org, i) => {
                      return (
                        <div
                          className={`selected-organization mb-2 ${styles.maxWidth}`}
                          id="organization-assigned-card"
                          key={i}
                        >
                          <div className="row">{org.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="row w-100">
                <div className="no-shrink mr-2">
                  Recherchez une organisation par son nom :
                </div>
              </div>
              <div className="row w-100 mb-2">
                <input
                  id="search-name-organization"
                  type="text"
                  value={searchNameValue}
                  onChange={(event): any => {
                    setSearchNameValue(event.target.value);
                  }}
                  className="form-input"
                  placeholder="Saisissez le nom ici"
                />
              </div>
              {allOrganizations.length === 0 ? (
                <div className="mt-2 text-italic">{informationMessage}</div>
              ) : (
                <div className="text-left mb-1 modal-label">
                  Liste des organisations
                </div>
              )}
              {allOrganizations &&
                allOrganizations.map((org) => (
                  <div key={org.id}>
                    <div key={org.name} className="d-flex flex-centered">
                      <label className="form-checkbox c-hand expert-card-container w-100">
                        <CheckBox
                          id={org.name}
                          checkboxId={org.name}
                          value={org.id}
                          isChecked={assignedOrganizations.some(
                            (organization) => organization.id === org.id
                          )}
                          handleCheckElement={(event: ChangeEvent): void =>
                            handleCheckOrganizationElement(event, org)
                          }
                        />
                        <i className={`form-icon ${styles.checkBox}`} />
                        <div className="centered-content">
                          <OrganizationCard
                            organization={org}
                            readOnly={true}
                            showCardLegend={true}
                            showRemoveButton={false}
                          />
                        </div>
                      </label>
                    </div>
                    <div></div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
