import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { errorToast } from "../../utils/Toast";
import Modal from "../Modal";
import { ModalFooter } from "../OpinionRequestForm/Participants/ModalFooter";

interface Props {
  onClose: () => any;
  onDecline: (comment: string, motive: DeclineMotive) => any;
}

export enum DeclineMotive {
  NOT_MY_EXPERTISE,
  COMPLEMENTARY_ADVICE,
  REDIRECT_OVERLOAD,
  OTHER_REASONS,
}

export const ExpertDeclineOpinionRequest: React.FC<Props> = (props: Props) => {
  const [comment, setComment] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<DeclineMotive>();
  const radioValues = [
    {
      value: DeclineMotive.NOT_MY_EXPERTISE,
      label: "Ce n’est pas mon expertise",
    },
    {
      value: DeclineMotive.COMPLEMENTARY_ADVICE,
      label: "Réorienter pour avis complémentaire",
    },
    {
      value: DeclineMotive.REDIRECT_OVERLOAD,
      label: "Réorienter pour surcharge",
    },
    {
      value: DeclineMotive.OTHER_REASONS,
      label: "Autre raison",
    },
  ];

  const closeModal = (): void => {
    props.onClose();
    return;
  };

  return (
    <Modal
      show
      onClose={closeModal}
      title={"Refuser la demande"}
      footer={
        <>
          <div className="footer-line" />
          <ModalFooter
            titleButtonRight={"Confirmer le refus"}
            onClickButtonRight={async (event): Promise<void> => {
              event.preventDefault();
              if (selectedValue !== undefined) {
                if (
                  selectedValue === DeclineMotive.OTHER_REASONS &&
                  comment === ""
                ) {
                  errorToast(
                    "L'ajout de commentaire est obligatoire lorsque vous sélectionnez 'Autre raison'"
                  );
                  return;
                }
              } else {
                errorToast("Vous devez sélectionner un motif de refus");
                return;
              }
              props.onDecline(comment, selectedValue);
              closeModal();
            }}
            titleButtonLeft={"Annuler"}
            onClickButtonLeft={closeModal}
          />
        </>
      }
      containerStyle={{ height: "45vh" }}
    >
      <div className="ml-2 content">
        <div className="text-left modal-label">
          Motif du refus de la demande :
        </div>
        <form>
          {radioValues.map((data: any, index: number) => {
            return (
              <label className="form-radio mb-2 c-hand" key={index}>
                <input
                  type="radio"
                  value={data.value}
                  onChange={(): any => {
                    setSelectedValue(data.value);
                  }}
                  checked={data.value === selectedValue}
                />
                <i className="form-icon neuro-radio" />
                <div className="text-left ml-2 text-black">{data.label}</div>
              </label>
            );
          })}
        </form>
        <TextareaAutosize
          onChange={(e): void => setComment(e.target.value)}
          name="comment"
          className="participant-textarea form-input margin-top-2"
          placeholder="Saisir mon commentaire…"
        />
        <div
          className="divider"
          style={{ borderTop: ".1rem solid var(--light-grey)" }}
        />
        <div className="text-left text-dark-grey text-medium mt-0">
          Vous pouvez taper votre message ici
        </div>
      </div>
    </Modal>
  );
};
