import React, { useContext, useEffect, useState } from "react";
import "./Admin.css";
import styles from "./AdminViewUpdateOrganization.module.css";
import { Organization } from "../../domain/Organization";
import { useParams } from "react-router-dom";
import { getOrganizationById } from "../../services/OrganizationService";
import history from "../../utils/history";
import { AppContext } from "../../utils/state";
import { errorToast } from "../../utils/Toast";
import { OrganizationDetailCard } from "../Dispatch/OrganizationDetailCard";

interface ParamTypes {
  id: string;
}

export const AdminViewOrganizationDetailsTab: React.FC<any> = () => {
  const appContext = useContext(AppContext);
  const { id } = useParams<ParamTypes>();
  const [organization, setOrganization] = useState<Organization>();

  function goBack(): void {
    if (appContext.state.user.email !== "") {
      history.push(`/admin/organizations`);
    } else {
      history.push(`/home`);
    }
  }

  const showErrorRetrievingOrganization = (): any => {
    errorToast("Erreur durant la récupération de l'organisation");
    goBack();
  };

  const getOrganization = async (): Promise<any> => {
    await getOrganizationById(id).then((res: Organization | Error) => {
      if (res instanceof Error) {
        showErrorRetrievingOrganization();
      } else {
        setOrganization(res);
      }
    });
  };

  useEffect(() => {
    getOrganization();
  }, []);

  return (
    <>
      <div className="page-container">
        <div
          className={`col-8 col-sm-auto col-mx-auto ${styles.whiteBackground}`}
        >
          {organization && (
            <OrganizationDetailCard organization={organization} />
          )}
        </div>
      </div>
    </>
  );
};
