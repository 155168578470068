import React from "react";
import config from "../../utils/config";
import { Brands } from "../../utils/Brands";

interface Props {
  register: any;
  readOnly?: boolean;
  isRequired: boolean;
}

export const DiseasesForm: React.FC<Props> = (props: Props) => {
  const registerPrimaryDisease = props.register("primaryDisease");

  const getDiseaseQuestion = (): string => {
    if (config.brand === Brands.HANDI_NEURO) {
      return "Quelle est la principale pathologie du patient responsable de son handicap ?";
    } else {
      return "Quel est le motif d’admission ?";
    }
  };

  return (
    <div>
      <div className="form-title mb-0">
        {getDiseaseQuestion()}
        <span className="error-msg text-large ml-2">*</span>
      </div>
      <div className="form-group mt-4">
        <textarea
          rows={5}
          className="form-input w-90"
          id="primary-disease"
          {...registerPrimaryDisease}
          readOnly={props.readOnly}
          required={props.isRequired}
        />
        {props.isRequired && (
          <div className="error-msg mb-2 ml-1 mt-1">
            Merci de remplir ce champ.
          </div>
        )}
        {config.brand == Brands.HANDI_NEURO && (
          <>
            <h4 className="form-title mb-0">Degré de sévérité</h4>
            <label className="text-small">
              Par exemple : avancé, stade 2, ...
            </label>
            <input
              className="form-input w-90"
              id="disease-stage"
              placeholder="Veuillez renseigner le degré de sévérité"
              {...props.register("diseaseStage")}
              readOnly={props.readOnly}
            />
          </>
        )}
      </div>
      <div className="margin-top-2 text-right w-90 mb-2">
        <span className="error-msg text-large">*</span>
        <span className="error-msg text-medium ml-1">Champ obligatoire</span>
      </div>
    </div>
  );
};
