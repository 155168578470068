import React, { useContext, useState } from "react";
import { RequestStatus } from "../../domain/RequestStatus";
import { AppContext } from "../../utils/state";
import { User } from "../../domain/User";
import { SelectFacilityLinkedToRequest } from "../Request/SelectFacilityLinkedToRequest";
import { Spinner } from "../Shared/Spinner";

interface Props {
  requestId: string;
  requesterId?: string;
  readOnly?: boolean;
  status?: RequestStatus;
  experts?: User[];
  loading: boolean;
  disabledSubmit: boolean;
  onSubmit: (requesterFacilityId: string) => void;
  saveAndQuit: () => void;
  isSubmitting: boolean;
}

export const OpinionRequestFooter: React.FC<Props> = (props: Props) => {
  const appContext = useContext(AppContext);
  const [showSelectFacility, setShowSelectFacility] = useState<boolean>(false);

  return (
    <div className="row btn-footer-container">
      <div className="col-8 flex-col flex-centered">
        {!props.readOnly && props.status != RequestStatus.VALIDATED && (
          <button
            className={
              "btn rectangular-btn neuro-btn mt-2 w-100 " +
              (props.loading ? "loading" : "")
            }
            id="update-opinion-request-btn"
            onClick={(): any => props.saveAndQuit()}
          >
            Enregistrer et quitter
          </button>
        )}
        {props.requesterId === appContext.state.user.id &&
          (!props.status || props.status === RequestStatus.DRAFT) && (
            <div className="centered-content flex-col w-100">
              <button
                type="submit"
                className={
                  "btn rectangular-btn neuro-btn mt-2 " +
                  (props.loading ? "loading" : "")
                }
                id="submit-opinion-request-btn"
                onClick={async (): Promise<void> => {
                  setShowSelectFacility(true);
                }}
                disabled={props.disabledSubmit || props.isSubmitting}
              >
                Soumettre la demande
              </button>
              {showSelectFacility && (
                <SelectFacilityLinkedToRequest
                  modalType={"SUBMIT"}
                  onClose={(): any => setShowSelectFacility(false)}
                  onSubmit={(requesterFacilityId: string): any => {
                    props.onSubmit(requesterFacilityId);
                  }}
                />
              )}
              {props.isSubmitting && (
                <div className="d-flex aligned-centered m-auto mt-2">
                  <Spinner size={"big-spinner"} />
                  <i className="ml-2">Enregistrement en cours...</i>
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  );
};
