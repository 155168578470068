import React, { useContext, useEffect, useState } from "react";
import {
  createTreeFromOpinionRequestOrganizations,
  OpinionRequestSummary,
  OrganizationNode,
} from "../../domain/OpinionRequest";
import { RequestCard } from "../Shared/RequestCard";
import { UserRoles } from "../../domain/UserRoles";
import history from "../../utils/history";
import { EmptyRequestListMessage } from "./EmptyRequestListMessage";
import { LoadingStatus } from "../../utils/LoadingStatus";
import { AppContext } from "../../utils/state";

interface Props {
  opinionRequests: OpinionRequestSummary[];
  validatedNeeded: boolean;
  registerNeeded: boolean;
  emptyText: string;
  status: LoadingStatus;
  selectedRole: string;
}

export const OrderedOpinionRequestsList: React.FC<Props> = (props: Props) => {
  const [opinionRequestsElem, setOpinionRequestsElem] = useState<JSX.Element[]>(
    []
  );
  const currentUser = useContext(AppContext).state.user;
  const goToRequest = (request: any): void => {
    if (!request.id) {
      return;
    }
    history.push(`/requests/${request.id}/view`);
  };

  useEffect(() => {
    showOpinionRequestsByOrganization();
  }, [props.opinionRequests]);

  const showOpinionRequestsByOrganization = async () => {
    const divList: JSX.Element[] = [];
    const organizationNodesRoot = await createTreeFromOpinionRequestOrganizations(
      props.opinionRequests
    );
    for (const oneOrgRoot of organizationNodesRoot) {
      divList.push(
        ...createOrgaDivFromOrgWithOpinionRequest(oneOrgRoot, [oneOrgRoot.name])
      );
    }
    setOpinionRequestsElem(divList);
  };

  const createOrgaDivFromOrgWithOpinionRequest = (
    orgWithOr: OrganizationNode,
    orgName: string[]
  ): JSX.Element[] => {
    const localAcc: JSX.Element[] = [];

    if (
      orgWithOr.opinionRequests.length > 0 &&
      orgWithOr.id &&
      (props.selectedRole !== UserRoles.ORGANIZATION_DISPATCHER ||
        currentUser.organizations
          .filter((org) => org.isDispatcher)
          .map((org) => org.organizationId)
          .indexOf(orgWithOr.id) !== -1)
    ) {
      localAcc.push(
        <div className={"margin-top-2 margin-bottom-2"} key={orgWithOr.id}>
          <div className={"text-left h5"}>
            {orgName.slice(0, orgName.length - 1).join("/")}
            {orgName.length > 1 && "/"}
            <span className={"text-primary-blue text-extra-bold"}>
              {orgName[orgName.length - 1]}
            </span>
          </div>
          <div className="request-grid-container">
            {orgWithOr.opinionRequests.map(
              (r: OpinionRequestSummary, index: React.Key | undefined) => (
                <RequestCard
                  key={index}
                  r={r}
                  chosenRole={UserRoles.GLOBAL_DISPATCHER}
                  onClick={(): any => goToRequest(r)}
                  disabled={props.validatedNeeded || props.registerNeeded}
                />
              )
            )}
          </div>
        </div>
      );
    }

    for (const child of orgWithOr.children) {
      localAcc.push(
        ...createOrgaDivFromOrgWithOpinionRequest(child, [
          ...orgName,
          child.name,
        ])
      );
    }

    return localAcc;
  };

  return (
    <>
      {props.opinionRequests.length > 0 && status !== LoadingStatus.ERROR ? (
        opinionRequestsElem
      ) : (
        <EmptyRequestListMessage text={props.emptyText} />
      )}
    </>
  );
};
